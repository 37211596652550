import angular from "angular";

/**
 * @description Сервис для рекурсивной типизации нетипизированных объектов.
 * Если у целевого типа есть статичное свойство castMap - оно будет использовано для приведения вложенных объектов.
 * Входящий аргумент мутабится
 * @example
 *  class SomeTypeA {
 *      method2() {
 *          return this.x + this.y;
 *      }
 *  }
 *  class SomeTypeB {
 *      method3() {
 *          return this.i * this.j;
 *      }
 *  }
 *  class Castable {
 *      method1() {
 *          return 2;
 *      }
 *      static get castMap() {
 *          return {
 *              somea: SomeTypeA,
 *              someb: [SomeTypeB]
 *          }
 *      }
 *  }
 *  const untypedObject = {
 *      somea: {
 *          x: 4,
 *          y: 5
 *      },
 *      someb: [{
 *          i: 3,
 *          j: 8
 *      }]
 *  };
 *  untypedObject.method1();    //  TypeError: untypedObject.method1 is not a function
 *  untypedObject.somea.method2();  //  TypeError: untypedObject.somea.method2 is not a function
 *  cast(untypedObject, Castable);
 *  untypedObject.method1();    //  2
 *  untypedObject.somea.method2();  //  9
 *  untypedObject.someb[0].method3();   //  24
 *
 * @param {Object} source - оъект типа Object полученый от Backend
 * @param {Class} T - целевой тип к которому необходимо привести
 * @returns {Object} возвращает ссылку на source
 */

type constructable = new(...args: any) => {};
export interface ICastable extends constructable {
    castMap?: ICastMap;
}
export interface ICastMap {
    [key: string]: ICastable | ICastable[];
}
export function cast(source: {}, T: ICastable) {
    const { castMap } = T;
    angular.forEach(castMap, (mapValue, mapProp) => {
        const value = source[mapProp];
        if (angular.isArray(mapValue)) {
            const type = mapValue[0];
            if (angular.isArray(value)) {
                angular.forEach(value, (element) => {
                    cast(element, type);
                });
            }
        } else if (angular.isObject(value)) {
            const type = mapValue;
            cast(value, type);
        }
    });
    const currentType = Object.getPrototypeOf(source) as constructable;
    if (currentType !== T.prototype) {
        Object.setPrototypeOf(source, T.prototype);
    }
    return source;
}
