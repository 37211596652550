import { Contract } from "domain/classes/contract.class";
import { ContractorProxy } from "domain/proxies/insurantPersonProxy.class";
import IDTO from "infrastructure/interfaces/IDTO";
import { IFactPaymentDTO } from "infrastructure/interfaces/WebApi/IFactPaymentDTO";
import PaymentDTO from "infrastructure/interfaces/WebApi/PaymentDTO";
import { PhoneNumberDTO } from "infrastructure/interfaces/WebApi/PhoneNumberDTO";
import { TravelingAbroadContractDTO } from "infrastructure/interfaces/WebApi/TravelingAbroadContractDTO";
import { TravelingAbroadInsuredPersonDTO } from "infrastructure/interfaces/WebApi/TravelingAbroadInsuredPersonDTO";
import Repository, { IRepositoryItem } from "infrastructure/Repository.class";
import { DateTimeOffset, Day, Decimal, Guid, Int } from "infrastructure/types";
import { Uuid } from "lib/uuid";
import moment from "moment";
import IPVSContractorDTO from "infrastructure/interfaces/IPVSContractorDTO";
import UgskPhone from "domain/classes/ugsk-phone.class";

export class TravelingAbroadRepository extends Repository {
    public InsuranceProgram: IRepositoryItem<IInsuranceProgram>;
    public DiscountRestrictions: IRepositoryItem<{
        DiscountRestrictions: string;
        DiscountRestrictionsId: Int;
    }>;
    public DiscountRestriction: IRepositoryItem<{
        Id: Int;
        MaxValue: Int;
        MinValue: Int;
    }>;
    public MultipleTours: IRepositoryItem<{
        IsProgramWithMultipleTours: boolean;
        ProgramWithMultipleToursName: string;
    }>;
    public MedicalExpensesProductRiskAmount: IRepositoryItem<{
        MedicalExpensesProductRiskAmountValue: Int;
    }>;
    public Currency: IRepositoryItem<ICurrency>;
    public TourCancellationProductRisk: IRepositoryItem<{
        TourCancellationProductRiskId: Int;
        TourCancellationProductRiskName: string;
    }>;
    public InsuredContractorType: IRepositoryItem<IInsuredContractorType>;
    public TravelingAbroadDestination: IRepositoryItem<ITravelingAbroadDestination>;
}

export class TravelingAbroadContract extends Contract implements TravelingAbroadContractDTO {
    public InsuredPersons: TravelingAbroadInsuredPersonDTO[] = [];
    public InsuredAddressWithoutHomeNumber = false;
    public IsVisaObtainingBasis: boolean = false;
    public TravelingAbroadDestination: Int[] = [];
    public MedicalExpensesProductRiskAmountId: Int = undefined;
    public AccidentProductRiskAmountId: Int = undefined;
    public BaggageLossProductRiskAmountId: Int = undefined;
    public PersonalCivilLiabilityProductRiskAmountId: Int = undefined;
    public TourCancellationProductRisk: Decimal = undefined;
    public FranchiseAmountForTourCancellationId: Int = undefined;
    public TravelingAbroadInsuranceProgramId: Int = undefined;
    public FranchiseAmountForMedicalExpensesId: Int = undefined;
    public UnderwriterCoefficient: Decimal = 1;
    public Discount: Int = 0; //    or Decimal
    /*
     * Параметры договора
     */
    public InsurancePremium: Decimal = undefined;
    public Id: Int = undefined;
    public ContractStatusId: Int = 0;
    public PolicyPrefix: string = undefined;
    public PolicySerialId: Int = undefined;
    public PolicyNumber: string = "";
    public SigningDate: DateTimeOffset = moment().format("YYYY-MM-DD");
    public ContractFrom: DateTimeOffset = moment().add(1, "d").format("YYYY-MM-DD");
    public ContractTo: DateTimeOffset = undefined;
    /*
     * Страхователь
     */
    public InsuredContractorTypeId: Int = undefined;
    public InsuredDocTypeId: Int = undefined;
    public InsuredDocSerial: string = undefined;
    public InsuredDocNumber: string = undefined;
    public InsuredDocDateGiven: string = undefined;
    public InsuredDocWhomGiven: string = undefined;
    public InsuredLastName: string = undefined;
    public InsuredLastNameInInternationalDocument: string = undefined;
    public InsuredFirstName: string = undefined;
    public InsuredFirstNameInInternationalDocument: string = undefined;
    public InsuredMiddleName: string = undefined;
    public InsuredMiddleNameInInternationalDocument: string = undefined;
    public InsuredBirthday: Day = undefined;
    public InsuredAddressUserDefinedAddress: string = undefined;
    public InsuredAddressFiasId: string = undefined;
    public InsuredAddressKladrId: string = undefined;
    public InsuredCommonEmail: string = undefined;
    public SellerComment: string = undefined;
    public InsuredPhones: PhoneNumberDTO[] = [];
    public InsuredPhoneVerificationId: Guid = undefined; //  never used
    public InsuredId: Int = undefined;
    public InsuredEmail: string = undefined;
    /*
     * Юрлицо
     */
    public InsuredOrgName: string = undefined;
    public InsuredOrgNameInInternationalDocument: string = undefined;
    // Юридический адрес
    public InsuredOrgAddressUserDefinedAddress: string = undefined;
    public InsuredOrgAddressFiasId: string = undefined;
    public InsuredOrgAddressKladrId: string = undefined;
    // Почтовый адрес
    public InsuredPostalAddressUserDefinedAddress: string = undefined;
    public InsuredOrgINN: string = undefined;
    public InsuredOrgRepresentativeJobPosition: string = undefined; //  never used
    public InsuredOrgRepresentativeDoc: string = undefined;
    /*
     * Параметры договора, специфичные для ВЗР
     */
    public CurrencyId: Int = undefined;
    public TravelDateFrom: DateTimeOffset = undefined;
    public TravelDateTo: DateTimeOffset = undefined;
    public TravelDatesAreNotKnown: boolean = undefined;
    public InsuranceTermDays: Int = undefined;
    public IsProgramWithMultipleTours: boolean = undefined;
    // Цель поездки
    public TravelingAbroadPurposeId: Int = undefined;
    // Срок страхования в дняx
    public ContractValidDays: Int = undefined;

    public InsuranceProgramId: Int;
    public Guid: Guid;
    public ContractStatusName: string;
    public InsuredDocOrgCode: string;
    public InsuredBirthPlace: string;
    public InsuredIsNotResident: boolean;
    public PaymentKindId: Int;
    public PaymentDocumentDatePay: Day;
    public IsReceiptRequired: boolean;
    public IsNumberRequired: boolean;
    public FactPayments: IFactPaymentDTO[];
    public Payments: PaymentDTO[];

    constructor(prototype: IDTO) {
        super(prototype);
    }
    public getInsurantFields() {
        if (this.isInsurantANaturalPerson()) {
            return [
                "InsuredLastName",
                "InsuredFirstName",
                "InsuredMiddleName",
            ];
        } else if (this.isInsurantALegalEntity()) {
            return ["InsuredOrgName"];
        }
        throw new Error("unknown insurant type");
    }

    public getRepository(): TravelingAbroadRepository {
        return (super.getRepository() as TravelingAbroadRepository);
    }

    /**
     * @override
     */
    public toPVSDTO(): IPVSContractorDTO {
        const toPVSDTO = {
            ContractorId: this.InsuredId,
            ContractorTypeCode: this.InsuredContractorTypeId,
            FirstName: this.InsuredFirstName,
            LastName: this.InsuredLastName,
            MiddleName: this.InsuredMiddleName,
            ContractorBirthday: this.InsuredBirthday,
            ContractorPhone: UgskPhone.phoneArrayToPhoneString(this.InsuredPhones),
            PhoneVerificationId: this.InsuredPhoneVerificationId,
        };
        for (const propName in toPVSDTO) {
            if (toPVSDTO[propName] === null) {
                delete toPVSDTO[propName];
            }
        }

        return toPVSDTO;
    }
}

export interface IRestrictions {
    Id: Int;
    MaxValueYears: Int;
    MaxValueDays: Int;
    MinValueDays: Int;
    MinValueYears: Int;
    ValueDayForPresentation: string;
}

export interface IInsuranceProgram {
    InsuranceProgramAdditionalControlVisible: boolean;
    InsuranceProgramId: Int;
    InsuranceProgramInsurancePeriodLengthRestrictions: IRestrictions[];
    InsuranceProgramInsuranceTermRestrictions: IRestrictions[];
    InsuranceProgramMultipleInsuredPersonsDenied: boolean;
    InsuranceProgramName: string;
    State: Int;
}

export interface ICurrency {
    CurrencyActualDate: string;
    CurrencyCost: Int;
    CurrencyId: Int;
    CurrencyName: string;
    State: Int;
}

export interface IInsuredContractorType {
    InsuredContractorType: string;
    InsuredContractorTypeCode: string;
    InsuredContractorTypeId: Int;
}

export interface ITravelingAbroadDestination {
    State: Int;
    TravelingAbroadDestinationId: Int;
    TravelingAbroadDestinationIsSchengen: boolean;
    TravelingAbroadDestinationIsWholeCoverageTerritory: boolean;
    TravelingAbroadDestinationName: string;
}

export class TravelingAbroadPerson implements TravelingAbroadInsuredPersonDTO{
    public InsuredPersonGuid: Guid;
    public InsuredPersonLastName: string;
    public InsuredPersonFirstName: string;
    public InsuredPersonBirthday: string;
    constructor() {
        this.InsuredPersonGuid = Uuid.raw(); // Guid для списка застрахованых лиц
        this.InsuredPersonLastName = undefined; // Фамилия
        this.InsuredPersonFirstName = undefined; // Имя
        this.InsuredPersonBirthday = undefined; // Дата рождения
    }
}

export class TravelingAbroadInsurantProxy extends ContractorProxy {
    constructor(source: TravelingAbroadContract) {
        super(source);
    }

    static get proxyRules() {
        return {
            addressUserDefinedAddress: "InsuredAddressUserDefinedAddress",
            birthPlace: "InsuredBirthPlace",
            birthday: "InsuredBirthday",
            contractorType: "InsuredContractorTypeId",
            docDateGiven: "InsuredDocDateGiven",
            docNumber: "InsuredDocNumber",
            docOrgCode: "InsuredDocOrgCode",
            docSerial: "InsuredDocSerial",
            docWhomGiven: "InsuredDocWhomGiven",
            documentType: "InsuredDocTypeId",
            email: "InsuredCommonEmail",
            fiasId: "InsuredAddressFiasId",
            firstName: "InsuredFirstName",
            firstNameInInternationalDocument: "InsuredFirstNameInInternationalDocument",
            isNotResident: "InsuredIsNotResident",
            kladrId: "InsuredAddressKladrId",
            lastName: "InsuredLastName",
            lastNameInInternationalDocument: "InsuredLastNameInInternationalDocument",
            middleName: "InsuredMiddleName",
            middleNameInInternationalDocument: "InsuredMiddleNameInInternationalDocument",
            orgAddressFiasId: "InsuredOrgAddressFiasId",
            orgAddressKladrId: "InsuredOrgAddressKladrId",
            orgAddressUserDefinedAddress: "InsuredOrgAddressUserDefinedAddress",
            orgInn: "InsuredOrgINN",
            orgName: "InsuredOrgName",
            orgNameInInternationalDocument: "InsuredOrgNameInInternationalDocument",
            phones: "InsuredPhones",
        };
    }
}
