import { IModalService, IModalServiceInstance } from "angular-ui-bootstrap";
import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import {
    CommonErrorsErrorTypeEnum,
    OsagoAsyncResponseCommonError,
} from "infrastructure/interfaces/WebApi/OsagoAsyncResponseDTO";
import { RSAMessages } from "infrastructure/services/rsaMessages";
import { OsagoToolbarController } from "../osago-toolbar.controller";
import rsaMessagesTemplate from "./rsaMessages.html";

class RsaMessagesController {
    public infoMessages: OsagoAsyncResponseCommonError[];
    public errorMessages: OsagoAsyncResponseCommonError[];

    static get $inject() {
        return ["$uibModalInstance", "messages"];
    }

    constructor(
        private $uibModalInstance: IModalServiceInstance,
        public messages: OsagoAsyncResponseCommonError[],
    ) {
        this.infoMessages = this.messages.filter(
            (msg) => msg.ErrorType === CommonErrorsErrorTypeEnum.Info
                || msg.ErrorType === CommonErrorsErrorTypeEnum.Warning,
        );
        this.errorMessages = this.messages.filter(
            (msg) => msg.ErrorType === CommonErrorsErrorTypeEnum.Critical
                || msg.ErrorType === CommonErrorsErrorTypeEnum.Error,
        );
    }

    public close(reason?: any) {
        this.$uibModalInstance.close(reason);
    }
}

export default {
    action(context: OsagoToolbarController) {
        const $uibModal = context.di<IModalService>("$uibModal");
        const rsaMessagesService = context.di<RSAMessages>("rsaMessagesService");
        $uibModal.open({
            controller: RsaMessagesController,
            controllerAs: "vm",
            resolve: {
                messages: () => rsaMessagesService.getMessages(),
            },
            template: rsaMessagesTemplate,
        }).result.then(() => { /* */ }).catch(() => { /* */ });
    },
    disabled(context: OsagoToolbarController) {
        const rsaMessagesService = context.di<RSAMessages>("rsaMessagesService");
        const messages = rsaMessagesService.getMessages();
        return messages.length === 0;
    },
    icon: "fa-exclamation",
    title: "Ошибки РСА",
    view: "form",
} as IExtraOptionPrototype;
