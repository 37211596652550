import * as angular from "angular";
import jQuery from "jquery";
import criticalErrorTemplate from "./criticalErrorModal.html";
import { Logger } from "./logToServer";

class ErrorHandler {
    private criticalErrorIsShown: boolean = false;
    constructor(public logger: Logger, private allowAlerting: boolean = true) {
    }
    public emitError(
        messageOrEvent: any = {},
        source: string = "",
        lineno: number = 0,
        colno: number = 0,
        err: Error = new Error(),
    ) {
        console.error(err);
        this.logger.fatal({
            Message: err.message,
            messageOrEvent,
            position: lineno + ":" + colno,
            source,
        }, {error_stack: err.stack});
        if (!this.criticalErrorIsShown && this.allowAlerting) {
            this.criticalErrorIsShown = true;
            const element = jQuery<HTMLElement>(criticalErrorTemplate);
            const modalInstance = element.modal();
            modalInstance.on("hide.bs.modal", () => {
                this.criticalErrorIsShown = false;
            });
            modalInstance.on("shown.bs.modal", () => {
                modalInstance.find("#close").click(() => {
                    element.modal("hide");
                });
            });
        }
    }
}

export default angular.module("error-handler", [])
    .provider("errorHandler", ["$httpProvider", function ($httpProvider) {
        let errorHandler: ErrorHandler = null;
        return {
            $get: () => errorHandler,
            handleWindowOnError: () => {
                window.onerror = function(...args) { errorHandler.emitError(...args); };
            },
            interceptHttp: () => {
                $httpProvider.interceptors.push(["$q", function ($q) {
                    return {
                        request: (config) => {
                            config.msBeforeAjaxCall = new Date().getTime();
                            return config;
                        },
                        response: (response) => {
                            if (response.config.warningAfter) {
                                const msAfterAjaxCall = new Date().getTime();
                                const timeTakenInMs = msAfterAjaxCall - response.config.msBeforeAjaxCall;
                                if (timeTakenInMs > response.config.warningAfter) {
                                    errorHandler.logger.warn({
                                        config: response.config,
                                        data: response.data,
                                        timeTakenInMs,
                                    });
                                }
                            }
                            return response;
                        },
                        responseError: (rejection) => {
                            let errorMessage = "timeout";
                            if (rejection) {
                                if (rejection.status && rejection.data) {
                                    errorMessage = rejection.data.ExceptionMessage;
                                }

                                const { config } = rejection;
                                if (config && config.nolog) {
                                    config.data = "*nolog*";
                                }
                            }

                            errorHandler.logger.error({
                                config: rejection.config,
                                Message: errorMessage,
                                status: rejection.status,
                                typePrefix: "backend",
                            }, rejection.data);
                            return $q.reject(rejection);
                        },
                    };
                }]);
            },
            setLogger: (logger: Logger, allowAlerting: boolean) => {
                errorHandler = new ErrorHandler(logger, allowAlerting);
            },
        };
    }])
    .factory("$exceptionHandler", ["errorHandler", function (errorHandler: ErrorHandler) {
        return (exception) => errorHandler.emitError("$exceptionHandler", "", 0, 0, exception);
    }])
    .name;
