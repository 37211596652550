import moment from "moment";
import { NgControllerBase } from "infrastructure/NgController";
import { IModalServiceInstance } from "angular-ui-bootstrap";

import "./trustPayment.css";

export class TrustPaymentController extends NgControllerBase {
    public documentNumber: string;
    public documentDate: string = moment().format("YYYY-MM-DDTHH:mm:ssZ");
    public maxDocumentDate = moment().endOf("day");

    static get $inject(): string[] {
        return ["$injector", "$uibModalInstance"];
    }

    public constructor($injector, private $uibModalInstance: IModalServiceInstance) {
        super($injector);
    }

    public onReject(): void {
        this.$uibModalInstance.dismiss();
    }

    public onAccept(): void {
        this.$uibModalInstance.close({
            documentDate: moment(this.documentDate).format("YYYY-MM-DDT00:00:00+0000"),
            documentNumber: this.documentNumber,
        });
    }

    public canBeAccepted(): boolean {
        return typeof this.documentNumber === "string" && this.documentNumber.length > 0
            && moment(this.documentDate).isValid();
    }
}
