import moment from "moment";
import { NgComponentController } from "infrastructure/NgController";
import ugskContractorModule from "application/components/ugsk-contractor/ugskContractor.component";
import template from "./ugskInsurant.component.html";

export default angular.module("ugsk.components.insurant", [
    ugskContractorModule,
]).component("ugskInsurant", {
    bindings: {
        minBirthDay: "<?",
        insurantContractorTypes: "<", //  TODO: fix
        insurantDocumentTypes: "<",
        insurantCountries: "<", //  TODO: fix
        insurant: "<",
        prefix: "<",
        ngDisabled: "<",
        onUpdateFieldRepository: "&",
        locked: "<",
        maxlength: "<",
        minlength: "<",
        maxlengthOrgName: "<",
        minlengthOrgName: "<",
        maxlengthOrgInn: "<",
        isNotResidentDisable: "<",
        isNotResidentCheckboxOnly: "<",
    },
    template: require("./ugskInsurant.component.html"),
    transclude: true,
    controller: class extends NgComponentController {
        onInit() {
            this.maxBirthDay = moment().subtract(18, "years").format();
            this.$watch(() => this.insurant.documentType, () => {
                if (!this.isPersonFieldsAvailable()) {
                    this.insurant.docOrgCode = null;
                    this.insurant.birthPlace = null;
                }
            });
        }
        isPersonFieldsAvailable() {
            return (this.insurant.isIndividual() || this.insurant.isSoleProprietor()) && (this.insurant.documentType === "Паспорт гражданина РФ" || this.insurant.documentType === 12);
        }
    },
    controllerAs: "vm",
}).name;
