import { Transition } from "@uirouter/core";
import { TravelingAbroadToolbarController } from "../travelingAbroad-toolbar.controller";

export class TravelingAbroadJournalToolbarController extends TravelingAbroadToolbarController {
    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
    ) {
        super($injector, $transition, {
            viewType: "journal",
            printOptions: [],
        });
    }
}
