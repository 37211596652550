import { NgComponentController } from "infrastructure/NgController";

import template from "./ugskPayments.component.html";

const getProductCodeFromContractCode = insuranceProductCode => {
    if (!insuranceProductCode) return undefined;
    //  "ConstructorHomeContract" -> "ConstructorHome"
    let productCode = insuranceProductCode.slice(0, -8);
    productCode = productCode[0].toLowerCase() + productCode.slice(1);
    return productCode;
};

export const ugskPaymentsComponent = {
    bindings: {
        multiselect: "<",
        payments: "=",
        selection: "=",
    },
    controller: class extends NgComponentController {
        constructor($injector, $scope, $element) {
            super($injector, $scope, $element);
            [
                this.statusService,
            ] = this.di([
                "statusService",
            ]);
            const [
                productsService,
                $filter,
                $state,
            ] = this.di([
                "productsService",
                "$filter",
                "$state",
            ]);

            const dateFilter = $filter("date");
            this.paymentKinds = [];
            this.columns = [{
                name: "Number",
                title: "Номер документа",
            }, {
                renderer: payment => {
                    const contractProductCode = getProductCodeFromContractCode(payment.InsuranceProductCode);
                    const product = productsService.getByCode(contractProductCode);
                    const contractStatusId = payment.ContractStatusId;
                    const contractStatusName = this.statusService.getStatusTitleById(contractStatusId, contractProductCode);
                    return `
                        <span>${product.productName}</span>
                        <br/>
                        <span>${payment.PolicyPrefix || ""}</span>
                        -
                        <span>${payment.PolicySerialValue || ""}</span>
                        -
                        <span>${payment.PolicyNumber || ""}</span>
                        <br/>
                        от
                        <span>${dateFilter(payment.ContractSigningDate, "dd.MM.yyyy HH:mm", "+0500")}</span>
                        <br/>
                        <span class="${this.statusService.getStatusLabelClass(payment.ContractStatusId)}">${contractStatusName}</span>
                        <a href="${$state.href(`app.${product.code}.index`, { id: payment.ContractId })}">
                        <i class="fa fa-link"></i>
                        </a>
                        `;
                },
                title: "Договор",
            }, {
                template: `
                    <span ng-bind="item.Employee"></span>
                `,
                title: "Продавец",
            }, {
                name: "PaymentKindId",
                renderer: item => {
                    const KindId = item.PaymentKindId;
                    const kind = this.paymentKinds.find(kindItem => kindItem.Id === KindId);
                    if (kind) return kind.Name;
                    return "";
                },
                title: "Способ оплаты",
            }, {
                name: "Amount",
                template: "<span ng-bind='item.Amount | thousand'></span> <i class='fa fa-rub'></i>",
                title: "Сумма",
            }, {
                name: "DatePay",
                ngFilter: "dateNoTz: 'DD.MM.YYYY'",
                title: "Дата оплаты",
            }, {
                name: "PaymentKindUniqueData",
                title: "Подтверждение оплаты",
            }, {
                name: "Serial",
                title: "Серия А7",
            }, {
                name: "PaymentRegistryId",
                template: `
                    <a ng-if="item.PaymentRegistryId" ui-sref="app.paymentsRegistries.item({id: {{item.PaymentRegistryId}}})">
                        Реестр #{{item.PaymentRegistryId}} <i class="fa fa-external-link"></i>
                    </a>
                    <span ng-if="!item.PaymentRegistryId">
                        Не учтен
                    </span>
                `,
                title: "Номер реестра взносов",
            }];
        }
        onInit() {
            const [
                dictionariesService,
                $q,
            ] = this.di([
                "dictionariesService",
                "$q",
            ]);
            return $q.all([
                dictionariesService.get("PaymentKinds"),
                this.statusService.get(),
            ]).then(([
                paymentKinds,
            ]) => {
                this.paymentKinds = paymentKinds;
            });
        }
    },
    controllerAs: "vm",
    require: {
        parent: "?^ugskPaymentsRegistry",
    },
    template,
};
