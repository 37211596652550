import angular from "angular";
import { ICalculation } from "application/osago/interfaces";
import { Contract } from "domain/classes/contract.class";
import { UGSKDriver } from "domain/classes/ugsk-driver.class";
import UgskPhone from "domain/classes/ugsk-phone.class";
import withInspectionConclusion from "domain/mixes/inspectionConclusion.mix";
import { ContractorProxy } from "domain/proxies/insurantPersonProxy.class";
import { IContractResource } from "infrastructure/interfaces/IContractResource";
import IProlongableContract from "infrastructure/interfaces/IProlongableContract";
import { IFactPaymentDTO } from "infrastructure/interfaces/WebApi/IFactPaymentDTO";
import PaymentDTO from "infrastructure/interfaces/WebApi/PaymentDTO";
import { VehicleDocumentDTO } from "infrastructure/interfaces/WebApi/VehicleDocumentDTO";
import { DateTimeOffset, GetNames, Guid, Int } from "infrastructure/types";
import { Uuid } from "lib/uuid";
import moment from "moment";
import { UAutoRepository } from "./uAuto.repository";

interface IOptionalEquipment {
    Amount: Int;
    Count: Int;
    Id: Int;
    Name: string;
    getAllAmount: () => number;
}

export interface IPreviousContract {
    ContractInfo: string;
    Id: Int;
    Insured: string;
    InsuredObject: string;
    Period: string;
    SignDate: string;
    ContractFrom: string;
    ContractTo: string;
    PolicySerial: string;
    PolicyNumber: string;
}

export interface IMultiCalculationOffer {
    CalculationId: Int;
    ContractId: Int;
    Description: string;
    FranchiseAmount: string;
    FranchiseType: string;
    InsuranceAmount: Int;
    InsurancePremium: Int;
    InsuranceProgram: string;
    Name: string;
    PaymentForm: string;
}

/**
 * @see UGSK.K3.Service.DTO/UAuto/GenderAgeExperienceWrapDTO.cs
 */
export class GenderAgeExperiences extends UGSKDriver {
    public gender: string = "M";
    public licenseDocumentType: string = "Водительское удостоверение РФ";
    public kbm: string = "Не установлен";
    public licenseIssueDate: DateTimeOffset = null;
    public hasPreviousDriverLicense = false;
    public previousLicenseIssueDate: DateTimeOffset = null;
    public previousLicenseLastName: string = null;
    public previousLicenseNumber: string = null;
    public previousLicenseSerial: string = null;

    constructor() {
        super();
    }
}

export class ProlongationDenialInfo {}

export class OptionalEquipment {
    public Id: Int = 0;
    public Count: Int = 0;
    public Amount: Int = 0;
    public Name: string = "";
    public getAllAmount: () => Int;

    constructor() {
        this.getAllAmount = () => this.Count * this.Amount;
    }
}

export type UAutoRepositoryKeys = GetNames<UAutoContract>;

export class UAutoContract extends withInspectionConclusion(Contract) implements IProlongableContract {
    // this.@odata.context = "http://devpro.ugsk.ru/eap9/api/api/$metadata#UGSK.K3.Service.DTO.UAuto.ContractDTO";
    // this.@odata.type = "#UGSK.K3.Service.DTO.UAuto.ContractDTO";
    public UserId: Int = 0;
    public BlockedFields: string[] = [];
    public Id: Int = null;
    // this.Guid = "615677b2-6482-4fca-854c-c53b29b236bf";
    public Guid: Guid = Uuid.raw();
    public Calculation: ICalculation[] = [];
    public CalculatedAcquisition: Int = 0;
    public CalculatedPremium: Int = 0;
    public CalculatedDiscount: Int = 0;
    public CalculatedPremiumWithoutDiscount: Int = 0;
    // todo type Day
    public CalculatedFrozenDateTill: string = null;
    public ContractStatusId: Int = 1;
    public ContractStatusName: string = "Проект";
    public ContractKbm: number | string = "Не установлен";
    public ContractNum: string = null;
    public ContractDate: string = null;
    public UnionPay: number = null;
    public UseMaxKbmTimeout = false;
    public VehicleModelSpelt: string = null;
    public VehicleModel: Int = 0;
    public VehicleId: Int = 0;
    public VehicleType: string = null;
    public UserDefinedMarkModel: string = null;
    public VehicleModification: string = null;
    public VehicleYearMade: Int = undefined;
    public EngineTypeId: number = null; // Тип двигателя
    public InsuranceAmount: Int = null;
    public IsNew: boolean = false;
    public IsParkDiscountEnabled: boolean = false;
    public VehicleIsCrossedOfTheRegister: boolean = false;
    public AppliesUnderinsurance: boolean = false;
    public UnderinsuranceAmount: Int = 0;
    public VehicleLicensePlate: string = null;
    public VehicleVIN: string = null;
    public VehicleDocuments: VehicleDocumentDTO[];
    public VehicleDocChassisNumber: string = null;
    public VehicleDocBodyNumber: string = null;
    public VehicleEngineVolume: Int = null;
    public VehicleEnginePowerHP: Int = null;
    public TrailerIndicator  = false; // Транспортное средство может быть использовано с прицепом
    public TrailerLicensePlate: string = null; // Государственный регистрационный номер прицепа
    public HandicappedCar: boolean = false; // ТС принадлежит инвалиду
    public AtsEAS: string = "Не выбрано";
    public AtsImmo: string = "Не выбрано";
    public AtsMBDHood: string = "Не выбрано";
    public AtsMBDWheel: string = "Не выбрано";
    public AtsMBDTransmission: string = "Не выбрано";
    public AtsSatellite: string = "Не выбрано";
    public AtsRadio: string = "Не выбрано";
    public ATSAnother: string = null;
    public ATSRequirement: string = "ДА";
    public VehicleAccessories: string[] = [];
    public PaymentCanBeChange = false;
    public VehicleTVCount: Int = null;
    public VehicleDocCountPassengerSeat: Int = null;
    public InspectionConclusionInspectionIsNotRequired: boolean = false;
    public InspectionConclusionAbsenceReasonName: string = null;
    public IsTowardInspection: boolean = false;
    public HasInspectionId: boolean = false;
    public InspectionId: string = null;
    public InspectionDate: string = null;
    public HasPhoto: boolean = false;
    public Mileage: Int = 0;
    public SpecialNote: string = null;
    public InsuranceProgram: string = "«Классик»";
    public InsuranceProgramPrevious: string = null;
    public InsuranceProgramMarketingName: string = "«Классик»";
    /* tslint:disable-next-line:max-line-length */
    public PaymentForm: string = "Ремонт на СТОА дилера по направлению Страховщика, за исключением случаев тотального повреждения ТС.  Без учета износа.";
    public KeyExist: string = "Хищение без утраты ключей и документов";
    public PaymentFormNumber: string = "1";
    public InsuranceAmountType: string = "неагрегатная";
    public Gap: string = "изменяющаяся";
    public InsuranceAmountNumber: string = "1";
    public BonusMalus: string = "Новый полис";
    public FranchiseType: string = "Безусловная, применяемая по виновнику";
    public FranchiseAmount: string = "19900";
    public PolicyNumber: string = "";
    public PolicySerial: string = null;
    public PolicyPrefix: string = "37/18";
    public SigningDate: string = moment().add(1, "minute").format();
    public ContractFrom: string = moment().format();
    public ContractTo: string = moment().add(1, "year").format();
    public InsuranceTerm: string = "1 год";
    public LapsedPayment: string = "Единовременно";
    public PlannedPayments: PaymentDTO[] = [];
    public FactPayments: IFactPaymentDTO[] = [];
    public PaymentVariantId: Int = null;
    public UnderwriterFactor: Int = 0;
    public AgreedPremium: Int = 0;
    public AppliesRemoteLossAdjustment: boolean = false;
    public DiscountIsNumeric: boolean = false;
    public Discount: Int = 0;
    public SpecialCondition: string = null;
    public UserDefinedSpecialCondition: string = null;
    public Risk: string = "Частичное КАСКО + Хищение";
    public PreviousContractId: number = null;
    public PreviousContractNumber: string = null;
    public PreviousPolicySerial: string = null;
    public SellerComment: string = null;
    public WithoutCertificate: boolean = false;
    public DriversRestrictionApproachName: string = "Предварительный расчёт";
    public Sex: string = null;
    public AgeName: string = null;
    public ExperienceName: string = null;
    public GenderAgeExperiences: GenderAgeExperiences[] = [new GenderAgeExperiences()];
    public InsuredContractorType: string = "физическое лицо";
    public InsuredDocumentType: string = "Паспорт гражданина РФ";
    public InsuredPersonDocSerial: string = null;
    public InsuredPersonDocNumber: string = null;
    public InsuredPersonDocWhomGiven: string = null;
    public InsuredPersonDocDateGiven: string = null;
    public InsuredPersonLastName: string = null;
    public InsuredPersonFirstName: string = null;
    public InsuredPersonMiddleName: string = null;
    public InsuredPersonBirthday: string = null;
    public InsuredCommonRealAddress: string = null;
    public InsuredCommonRealAddressFiasId: string = null;
    public InsuredCommonRealAddressKladrId: string = null;
    public InsuredId: Int = 0;
    public CuratorId: Int = null;
    public EmployeeName: string = "";
    public InsuredIsNotResident = false;
    public InsuredOrgINN: string = null;
    public InsuredOrgKPP: string = null;
    public InsuredOrgName: string = null;
    public InsurantPhones: UgskPhone[] = [];
    public InsuredPersonSnils: string = null;
    public InsuredVID: any = null;
    public InsuredKBM: number | string = "Не установлен";
    public InsuredCommonEmail: string = null;
    public InsuredGuid: string = null;
    public InsuredPersonDocOrgCode: string = null;
    public InsuredPersonBirthPlace: string = null;
    public BaseContractNumber: string = null;
    public BaseContractFrom: string = null;
    public BaseContractTo: string = null;
    public BaseContractInsuranceCompany: string = null;
    public BaseContractInsuranceAmount: string = null;
    public BeneficiaryId: Int = null;
    public BeneficiaryContractorType: string = "физическое лицо";
    public BeneficiaryDocumentType: string = "Паспорт гражданина РФ";
    public BeneficiaryPersonDocSerial: string = null;
    public BeneficiaryPersonDocNumber: string = null;
    public BeneficiaryPersonDocWhomGiven: string = null;
    public BeneficiaryPersonDocDateGiven: string = null;
    public BeneficiaryPersonLastName: string = null;
    public BeneficiaryPersonFirstName: string = null;
    public BeneficiaryPersonMiddleName: string = null;
    public BeneficiaryPersonBirthday: string = null;
    public BeneficiaryCommonRealAddress: string = null;
    public BeneficiaryCommonRealAddressFiasId: string = null;
    public BeneficiaryCommonRealAddressKladrId: string = null;
    public BeneficiaryOrgINN: string = null;
    public BeneficiaryOrgKPP: string = null;
    public BeneficiaryOrgName: string = null;
    public BeneficiaryPhones: UgskPhone[] = [];
    public BeneficiaryPersonSnils: string = null;
    public BeneficiaryCommonEmail: string = null;
    public BeneficiaryGuid: Guid = null;
    public CreditorId: Int = null;
    public CreditorContractProps: string = null;
    public CreditorAddress: string = null;
    public TechnicalAssistanceRisk: string = "Не выбрано";
    public TechnicalAssistanceAmount: Int = 0;
    public TechnicalAssistancePremium: Int = 0;
    public AdditionalExpensesRisk: string = "«Надежность»";
    public AdditionalExpensesAmount: Int = 0;
    public AdditionalExpensesPremium: Int = 0;
    public AccidentAmount: string = "без НС";
    public AccidentPremium: Int = 0;
    public CivilLiabilityAmount: string = "без ДАГО";
    public CivilLiabilityRiskPremium: Int = 0;
    public InsuranceCoverage: string = "Российская Федерация";
    public OptionalEquipmentPremium: Int = 0;
    public OptionalEquipmentAmount: Int = 0;
    public OptionalEquipmentRisk: string = "Без ДО";
    public OptionalEquipments: IOptionalEquipment[] = [];
    public MaxPercAcquisitionAgent: Int = 25;
    public Territory: string = null;
    public TerritoryGuid: string = null;
    public Currency: string = null;
    public SaleChannel: string = null;
    public BusinessSource: string = null;
    public PartnerFactor: Int = 0;
    public LossAdjustmentFactor: Int = 0;
    public FreezingDate: string = null;
    public ProlongationDenialInfos: any[] = [];
    public MultiCalculationOffers: IMultiCalculationOffer[] = [];
    public ApproveRequestAdditionalInfo: string = null;
    public RequestedPremium: Int = 0;
    public ApproveRequestReason: boolean = null;
    public ApproveRequestFranchiseType: string = null;
    public ApproveRequestFranchiseAmount: string = null;
    public IsPayed = false;
    public IsSafeOnInflation = false;
    public isFrozen: () => boolean;
    public getResourceProvider: () => IContractResource;
    public isBlank: () => boolean;
    public isApproved: () => boolean;
    public isDraft: () => boolean;

    constructor() {
        super();
    }

    public $applyArtificialCalculation(calculationId: Int): angular.IPromise<any> {
        return this.getResourceProvider().applyArtificialCalculation({
            Id: this.Id,
            calculationId,
            contractId: this.Id,
        }).$promise;
    }

    /**
     * Специфичное для КАСКО поведение по сбросу полей
     *
     * @param {Array} data
     * @memberof UAutoContract
     * @override
     */
    public setFromRepository(data, flags) {
        // костыль по задаче http://jr.ugsk.ru/jr/browse/UPRO-1982
        const vehicleYearMade = this.VehicleYearMade;
        super.setFromRepository(data, flags);
        const notNullableFields = ["VehicleModel", "VehicleModelSpelt"];

        data.forEach((element) => {
            if (element.Value === null && !notNullableFields.includes(element.FieldName)) {
                this[element.FieldName] = element.Value;
            }
        });
        // todo убрать когда будет доработка бэка. дата выпуска тс не будет приходить или будет null
        if (this.isBlank()) {
            this.VehicleYearMade = vehicleYearMade;
        }
        if (
            this.DriversRestrictionApproachName === "Предварительный расчёт" &&
            this.GenderAgeExperiences.length === 0
        ) {
            this.GenderAgeExperiences.push(new GenderAgeExperiences());
        }
        if (!this.PolicyPrefix) {
            // todo когда с бэка в договоре приходит null берем значение по умолчанию из репозитория.
            this.PolicyPrefix = this.getRepository().PolicyPrefix.Value[0].Value;
        }
    }

    public $validateForApproveRequest() {
        return this.getResourceProvider().preApproveRequestValidate(this).$promise;
    }

    /**
     * @override
     * Специфичное поведение в КАСКО,
     * в базовом `contract` null значения игнорируются, тут нет
     */
    public init(data) {
        angular.extend(this, data);
    }

    //  Временный костыль чтобы сохранялся кросс
    public toDTO() {
        // this.ContractFrom = "2018-07-05T00:00:00+05:00";
        // this.ContractTo = "2019-07-04T23:59:59+05:00";
        if (!this.ContractFrom) {
            this.ContractFrom = moment().format();
        }
        if (!this.ContractTo) {
            this.ContractTo = moment()
                .add(1, "year")
                .format();
        }
        if (
            this.InspectionConclusionInspectionIsNotRequired === false &&
            this.InspectionConclusionAbsenceReasonName === null
        ) {
            // this.InspectionConclusionAbsenceReasonName = "Непрерывная пролонгация";
        }

        return super.toDTO();
    }

    // @override
    public $returnToRevision(): angular.IPromise<void> {
        return this.getResourceProvider().returnForRevision({
            Id: this.Id,
        }).$promise.then((response) => { // TODO Надо бы типизировать
            if (angular.isDefined(response.PaymentCanBeChange)) {
                this.PaymentCanBeChange = response.PaymentCanBeChange;
            }
            const contract = UAutoContract.fromDTO(response);
            this.init(contract);
            this.setStatus(4);
        });
    }

    public canAddMoreDrivers() {
        return this.GenderAgeExperiences.length < 4;
    }

    public $freeze() {
        return this.getResourceProvider().freezeContract(this).$promise;
    }

    public $resetCacheKBM() {
        return this.getResourceProvider().resetCacheKBM(this).$promise;
    }
    
    public $preSign(): angular.IPromise<any> {
        return this.getResourceProvider().preSignContract({ Id: this.Id }).$promise;
    }

    public $unFreeze() {
        return this.getResourceProvider().unFreezeContract(this).$promise;
    }

    public isInsurantANaturalPerson() {
        return this.InsuredContractorType === "физическое лицо";
    }

    public isAitoSeries(): boolean {
        return this.InsuranceProgram === "«SERES AITO»";
    }

    public hasScoringRate() {
        return this.getResourceProvider().hasScoringRate({ id: this.Id }).$promise;
    }

    public getInsurantFields() {
        if (this.isInsurantANaturalPerson()) {
            return ["InsuredPersonLastName", "InsuredPersonFirstName", "InsuredPersonMiddleName", "InsuredPersonBirthday"];
        } else if (this.isInsurantALegalEntity()) {
            return ["InsuredOrgINN", "InsuredOrgName"];
        }
        throw new Error("unknown insurant type");
    }

    //  @override
    public getPremium() {
        return this.CalculatedPremium;
    }

    /**
     * Получение строки с датой для замороженного контракта
     */
    public getFrozenToDateAsString(): string {
        if (this.isFrozen()) {
            const data = moment(this.CalculatedFrozenDateTill).format("DD.MM.YYYY");
            return `до ${data}`;
        }
        return "";
    }

    //  @override
    public isInsurantALegalEntity() {
        return String(this.InsuredContractorType)
            .toLowerCase()
            .startsWith("юрид");
    }

    //  @override
    public getInsurantPhones(): UgskPhone[] {
        return this.InsurantPhones;
    }

    //  @override
    public setInsurantPhones(value: UgskPhone[]) {
        this.InsurantPhones = value;
    }

    /**
     * @override
     */
    public getPolicySerial(): string {
        return this.PolicySerial || "";
    }

    // @override
    public getRepository(): UAutoRepository {
        return super.getRepository() as UAutoRepository;
    }

    public removePreviousContract(): void {
        this.PreviousContractId = null;
        this.PreviousContractNumber = null;
        this.PreviousPolicySerial = null;
    }

    private hasPreviousDriverLicense(genderAgeExperiences: GenderAgeExperiences[]): GenderAgeExperiences[] {
        genderAgeExperiences.forEach((driver: GenderAgeExperiences) => {
            if (
                driver.previousLicenseIssueDate
                && driver.previousLicenseLastName
                && driver.previousLicenseNumber
                && driver.previousLicenseSerial
            ) {
                driver.hasPreviousDriverLicense = true;
            } else {
                driver.hasPreviousDriverLicense = false;
            }
        });
        return genderAgeExperiences;
    }
}

export class UautoContractorProxy extends ContractorProxy {
    //  @override
    public contractorType: string;
    // for @typing Не подхватывается из базового класса: либо из-за mix, либо - js
    public copyFrom: (source: any, excludes?: any[]) => void;
    public guid: Guid;

    constructor(source: UAutoContract) {
        super(source);
    }

    public isIndividual() {
        return this.contractorType === "физическое лицо";
    }

    public isLegalEntity() {
        return String(this.contractorType)
            .toLowerCase()
            .startsWith("юрид");
    }
}

export class UautoInsurantProxy extends UautoContractorProxy {
    static get proxyRules() {
        return {
            birthPlace: "InsuredPersonBirthPlace",
            birthday: "InsuredPersonBirthday",
            commonRealAddress: "InsuredCommonRealAddress",
            contractorType: "InsuredContractorType",
            docDateGiven: "InsuredPersonDocDateGiven",
            docNumber: "InsuredPersonDocNumber",
            docOrgCode: "InsuredPersonDocOrgCode",
            docSerial: "InsuredPersonDocSerial",
            docWhomGiven: "InsuredPersonDocWhomGiven",
            documentType: "InsuredDocumentType",
            email: "InsuredCommonEmail",
            fiasId: "InsuredCommonRealAddressFiasId",
            firstName: "InsuredPersonFirstName",
            guid: "InsuredGuid",
            isNotResident: "InsuredIsNotResident",
            kladrId: "InsuredCommonRealAddressKladrId",
            lastName: "InsuredPersonLastName",
            middleName: "InsuredPersonMiddleName",
            orgInn: "InsuredOrgINN",
            orgKpp: "InsuredOrgKPP",
            orgName: "InsuredOrgName",
            phones: "InsurantPhones",
            snils: "InsuredPersonSnils",
        };
    }
}

export class UautoBeneficiaryProxy extends UautoContractorProxy {
    static get proxyRules() {
        return {
            birthday: "BeneficiaryPersonBirthday",
            commonRealAddress: "BeneficiaryCommonRealAddress",
            contractorType: "BeneficiaryContractorType",
            docDateGiven: "BeneficiaryPersonDocDateGiven",
            docNumber: "BeneficiaryPersonDocNumber",
            docSerial: "BeneficiaryPersonDocSerial",
            docWhomGiven: "BeneficiaryPersonDocWhomGiven",
            documentType: "BeneficiaryDocumentType",
            email: "BeneficiaryCommonEmail",
            fiasId: "BeneficiaryCommonRealAddressFiasId",
            firstName: "BeneficiaryPersonFirstName",
            guid: "BeneficiaryGuid",
            kladrId: "BeneficiaryCommonRealAddressKladrId",
            lastName: "BeneficiaryPersonLastName",
            middleName: "BeneficiaryPersonMiddleName",
            orgInn: "BeneficiaryOrgINN",
            orgKpp: "BeneficiaryOrgKPP",
            orgName: "BeneficiaryOrgName",
            phones: "BeneficiaryPhones",
            snils: "BeneficiaryPersonSnils",
        };
    }
}
