import { Transition } from "@uirouter/core";
import { BaseToolbarController } from "application/baseProduct.toolbar.controller";
import IBaseViewParams from "infrastructure/interfaces/IBaseViewParams";

export class ExpressToolbarController extends BaseToolbarController {
    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
        {
            viewType = "form",
        }: IBaseViewParams,
    ) {
        super($injector, $transition, {
            viewType,
            printOptions: [
                "policy",
                "policyWithoutA7",
                "worksheet",
                "paymentAccountAll",
                "freeFormReceipt",
            ],
            crossOptions: [
                "snugHome",
                "express",
                "myChoice",
                "constructorHome",
                "assuranceApartment",
                "assuranceHome",
            ],
            extraOptions: [
                "manual",
                "integrationInfo",
                "annul",
            ],
        });
    }
}
