
export default angular.module("app.errorHandler", []).run(($stateRegistry) => {
    $stateRegistry.register({
        name: "error",
        url: "/error?error?error_description",
        views: {
            "topbar@": {},
            "body@": {
                template: `
                        <h1>
                            Произошла ошибка
                        </h1>
                        <h3 class="text-muted" ng-bind="vm.error"></h3>
                        <pre ng-bind="vm.error_description"></pre>
                        <span>
                            Попробуйте перейти на главную страницу и повторить действие
                        </span>
                        <h2>
                            <a ng-click="vm.goToIndex()">
                                <i class="fa fa-home"></i>
                                На главную
                            </a>
                        </h2>
                    `,
                // Не трогать function
                controller: function ($stateParams, $state, helpers) {
                    "ngInject";

                    this.error = $stateParams.error;
                    this.error_description = $stateParams.error_description;

                    this.goToIndex = () => {
                        if (helpers.isEmbedMode()) {
                            window.parent.postMessage({
                                type: "GOTOINDEX",
                            }, "*");
                        } else {
                            $state.go("app.index");
                        }
                    }
                },
                controllerAs: "vm",
            },
        },
        data: {
            anonymous: true,
        },
    });
}).name;
