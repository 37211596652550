import automapper from "infrastructure/services/automapper";
import { Payment } from "domain/classes/payment.class";
import UgskPhone from "domain/classes/ugsk-phone.class";
import VehicleDocument from "domain/classes/vehicleDocument.class";
import { InspectionConclusion } from "domain/classes/inspectionConclusion.class";
import { DatePeriod } from "domain/classes/datePeriod";
import moment from "moment";
import {
    OsagoContract,
    OsagoDriver,
    ProlongationDenialInfo,
} from "./osago.factory";
import { ReliableDriveContract } from "application/osago/classes/ReliableDriveContract";

const arrayMapperRule = (sourceType, targetType, filterFn = () => true) =>
    //  @warning Ни в коем случае не переделывать в стрелочную функцию - падает автомаппер при юнит тестах
    function func1(opts) {
        const prop = opts.sourceObject[opts.sourcePropertyName];
        if (prop === null || prop === undefined) {
            return [];
        }
        return automapper.map(sourceType, targetType, prop.filter(filterFn));
    };
const convert = (fn, prop) => opts => {
    const sourceValue = opts.sourceObject[prop || opts.sourcePropertyName];
    if (sourceValue === null) return null;
    //  @todo remove it after 11.4.0
    if (sourceValue === "") return null;
    return fn(sourceValue);
};

const nullifyEmptyString = val => val || null;

const dateTimeToDate = str => `${str.slice(0, 10)}T00:00:00`;
const dateToLocalDateTime = str => `${str.slice(0, 10)}T00:00:00${moment().format("Z")}`;
const formatSnils = rawValue => {
    if (typeof rawValue === "string") {
        return rawValue.replace(/\D/g, "");
    }
    return rawValue;
};

automapper.createMap(Object, DatePeriod);
automapper.createMap(Object, VehicleDocument);
automapper.createMap(Object, InspectionConclusion);
automapper.createMap(Object, Payment);
automapper.createMap(Object, UgskPhone);
automapper.createMap(Object, ProlongationDenialInfo);
/**
 * @from  IOsagoDriverDTO
 * @to  OsagoDriver
 */
automapper.createMap(Object, OsagoDriver)
    .forMember("Id", opts => opts.mapFrom("Id"))
    .forMember("guid", opts => opts.mapFrom("Guid"))
    .forMember("isNotResident", opts => opts.mapFrom("PersonIsNotResident"))
    .forMember("lastName", opts => opts.mapFrom("ContractorPersonLastName"))
    .forMember("firstName", opts => opts.mapFrom("ContractorPersonFirstName"))
    .forMember("middleName", opts => opts.mapFrom("ContractorPersonMiddleName"))
    .forMember("birthday", convert(dateToLocalDateTime, "ContractorPersonBirthday"))
    .forMember("country", opts => opts.mapFrom("ContractorPersonCountry"))
    .forMember("licenseSerial", opts => opts.mapFrom("LicenseSerial"))
    .forMember("licenseNumber", opts => opts.mapFrom("LicenseNumber"))
    .forMember("licenseIssueDate", opts => opts.mapFrom("LicenseIssueDate"))
    .forMember("drivingExperience", opts => opts.mapFrom("DrivingExperienceStartDate"))
    .forMember("KBMClass", opts => opts.mapFrom("KBMClass"))
    .forMember("RSAKBMValue", opts => opts.mapFrom("RSAKBMValue"))
    .forMember("payoutCount", opts => opts.mapFrom("PayoutCount"))
    .forMember("KBMErrors", opts => opts.mapFrom("KBMErrors"))
    .forMember("hasPreviousDriverLicense", opts => opts.mapFrom("HasPreviousDriverLicense"))
    .forMember("previousLicenseIssueDate", opts => opts.mapFrom("PreviousLicenseIssueDate"))
    .forMember("previousLicenseLastName", opts => opts.mapFrom("PreviousLicenseLastName"))
    .forMember("previousLicenseNumber", opts => opts.mapFrom("PreviousLicenseNumber"))
    .forMember("previousLicenseSerial", opts => opts.mapFrom("PreviousLicenseSerial"))
    .forMember("DriverLicenseDocTypeId", opts => opts.mapFrom("DriverLicenseDocTypeId"))
    .forMember("DriverLicenseIssueCountryId", opts => opts.mapFrom("DriverLicenseIssueCountryId"))
    //  @todo array mapping
    .forMember("KBMErrors", opts => opts.mapFrom("KBMErrors"));

automapper.createMap(OsagoDriver, "DTO")
    .forMember("Id", opts => opts.mapFrom("Id"))
    .forMember("Guid", opts => opts.mapFrom("guid"))
    .forMember("PersonIsNotResident", opts => opts.mapFrom("isNotResident"))
    .forMember("ContractorPersonLastName", opts => opts.mapFrom("lastName"))
    .forMember("ContractorPersonFirstName", opts => opts.mapFrom("firstName"))
    .forMember("ContractorPersonMiddleName", convert(nullifyEmptyString, "middleName"))
    .forMember("ContractorPersonBirthday", convert(dateTimeToDate, "birthday"))
    .forMember("ContractorPersonCountry", opts => opts.mapFrom("country"))
    .forMember("LicenseSerial", opts => {
        if (typeof opts.sourceObject.licenseSerial === "string" && opts.sourceObject.licenseSerial.length === 0) {
            return null;
        }

        return opts.sourceObject.licenseSerial;
    })
    .forMember("LicenseNumber", opts => opts.mapFrom("licenseNumber"))
    .forMember("LicenseIssueDate", opts => opts.mapFrom("licenseIssueDate"))
    .forMember("DrivingExperienceStartDate", opts => opts.mapFrom("drivingExperience"))
    .forMember("KBMClass", opts => opts.mapFrom("KBMClass"))
    .forMember("RSAKBMValue", opts => opts.mapFrom("RSAKBMValue"))
    .forMember("PayoutCount", opts => opts.mapFrom("payoutCount"))
    .forMember("KBMErrors", opts => opts.mapFrom("KBMErrors"))
    .forMember("HasPreviousDriverLicense", opts => opts.mapFrom("hasPreviousDriverLicense"))
    .forMember("PreviousLicenseIssueDate", opts => {
        if (typeof opts.sourceObject.previousLicenseIssueDate === "string") {
            return `${opts.sourceObject.previousLicenseIssueDate.slice(0, 10)}T00:00:00+00:00`;
        }
        return opts.sourceObject.previousLicenseIssueDate;
    })
    .forMember("PreviousLicenseLastName", opts => opts.mapFrom("previousLicenseLastName"))
    .forMember("PreviousLicenseNumber", opts => opts.mapFrom("previousLicenseNumber"))
    .forMember("PreviousLicenseSerial", opts => opts.mapFrom("previousLicenseSerial"))
    .forMember("DriverLicenseDocTypeId", opts => opts.mapFrom("DriverLicenseDocTypeId"))
    .forMember("DriverLicenseIssueCountryId", opts => opts.mapFrom("DriverLicenseIssueCountryId"))
    .ignoreAllNonExisting();

automapper.createMap(Object, OsagoContract)
    //  @see: http://jr.ugsk.ru/jr/browse/UPF-490
    .forMember("UsagePeriods", arrayMapperRule(Object, DatePeriod))
    .forMember("PreviousContractInfo", opts => opts.mapFrom("PreviousContractInfo"))
    .forMember("VehicleDocuments", arrayMapperRule(Object, VehicleDocument))
    .forMember("InspectionConclusions", arrayMapperRule(Object, InspectionConclusion))
    .forMember("FactPayments", arrayMapperRule(Object, Payment))
    .forMember("InsuredPhones", arrayMapperRule(Object, UgskPhone))
    .forMember("OwnerPhones", arrayMapperRule(Object, UgskPhone))
    .forMember("Drivers", arrayMapperRule(Object, OsagoDriver))
    .forMember("InsuredPersonBirthday", convert(dateToLocalDateTime))
    .forMember("OwnerPersonBirthday", convert(dateToLocalDateTime))
    .forMember("ProlongationDenialInfos", arrayMapperRule(Object, ProlongationDenialInfo))
    .forMember("ContractDate", convert(dateToLocalDateTime))
    .forMember("VehicleIsNotInTransdekra", (opts) => {
        const source = opts.sourceObject;

        if (source.VehicleIsNotInTransdekra === true
            && [source.VehicleMarkRSAId, source.VehicleModelRSAId, source.WrittenMark, source.WrittenModel].every(val => val === null)
        ) {
            return false;
        }

        return opts.sourceObject.vehicleIsNotInTransdekra;
    });
;

automapper.createMap(VehicleDocument, "DTO");
automapper.createMap(OsagoContract, "DTO")
    .forMember("VehicleDocuments", arrayMapperRule(VehicleDocument, "DTO", (document) => Boolean(document.VehicleDocumentType)))
    .forMember("VehicleLicensePlate", convert(nullifyEmptyString))
    .forMember("InsuredPersonBirthday", convert(dateTimeToDate))
    .forMember("OwnerPersonBirthday", convert(dateTimeToDate))
    .forMember("Drivers", arrayMapperRule(OsagoDriver, "DTO"))
    .forMember("ContractDate", convert(dateTimeToDate))
    .forMember("InsuredPersonSnils", (opts) => {
        return formatSnils(opts.sourceObject.InsuredPersonSnils);
    })
    .forMember("OwnerPersonSnils", (opts) => {
        return formatSnils(opts.sourceObject.OwnerPersonSnils);
    });

automapper.createMap(ReliableDriveContract, "DTO");
automapper.createMap(Object, ReliableDriveContract);
