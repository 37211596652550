import moment from "moment";

export function isPeriodsOverlapped(periods: Array<{ From: string, To: string }>) {
    if (!Array.isArray(periods) || periods.length === 1) {
        return false;
    }

    periods.sort((aPeriod, bPeriod) => moment(aPeriod.From).diff(moment(bPeriod.From), "seconds"));

    for (let i = 0; i < periods.length - 1; i++) {
        const currentPeriodEndTime = moment(periods[i].To);
        const nextPeriodStartTime = moment(periods[i + 1].From);

        if (!currentPeriodEndTime.isValid() || !nextPeriodStartTime.isValid()) {
            continue;
        }

        if (nextPeriodStartTime.isSameOrBefore(currentPeriodEndTime)) {
            return true;
        }
    }

    return false;
}
