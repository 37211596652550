import { NgControllerBase } from "infrastructure/NgController";

import template from "./ugskJournalFilter.component.html";
import "./ugskJournalFilter.component.css";
import { UgskODataService } from "infrastructure/services/ugskOData.service";

export const ugskJournalFilterComponent = {
    bindings: {
        filtersColumns: "<",
        order: "=",
    },
    controller: class extends NgControllerBase {
        $onInit() {
            this.orderDirections = [{
                title: "По убыванию",
                value: "desc",
            }, {
                title: "По возрастанию",
                value: "asc",
            }];
        }
        addFilter() {
            const filterToAdd = this.filtersColumns.find(filter => filter.name === this.selectedFilterName);
            if (filterToAdd) {
                filterToAdd.visible = true;
            }
            this.selectedFilterName = undefined;
        }
        removeFilter(item) {
            item.visible = false; // eslint-disable-line
            item.values = [null, null]; // eslint-disable-line
            //  @TODO: необъявленный метод applyFilter
            if (!this.visibleFilters.length) this.applyFilter();
        }
        getComparablesForFilter(filter) {
            const optionId = filter.option;
            const options = this.getOptionsForType(filter.type);
            if (!options[optionId]) {
                return 1;
            }// Если не выбран критерий
            const operation = options[optionId].operation; // eslint-disable-line
            if (angular.isArray(operation)) return operation.length;
            return 1;
        }
        getOperationType(filter, index) {
            const optionId = filter.option;
            const options = this.getOptionsForType(filter.type);
            if (!options[optionId]) {
                return ''
            };
            
            if (optionId === 'bw') {
                const operation = options[optionId].operation;
                return operation[index];
            } 
            return optionId;
        }
        getOptionsForType(type) {
            return UgskODataService.getType(type).options;
        }
        get visibleFilters() {
            return this.filtersColumns.filter(column => column.visible);
        }
        get nonInternalFilters() {
            return this.filtersColumns.filter(column => column.internal !== true && column.name);
        }
    },
    controllerAs: "vm",
    template,
};
