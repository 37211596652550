import { IModalInstanceService } from "angular-ui-bootstrap";
import { IPreviousContract } from "application/uAuto/uAuto.factory";
import { Int } from "infrastructure/types";

export class UautoPreviousContractsController {
    public selectedContract: IPreviousContract;

    constructor(
        private $uibModalInstance: IModalInstanceService,
        public previousContracts: IPreviousContract[],
    ) { }

    static get $inject() {
        return ["$uibModalInstance", "previousContracts"];
    }

    public close(): void {
        this.$uibModalInstance.dismiss();
    }

    public setClickedRow(item: IPreviousContract): void {
        this.selectedContract = item;
    }

    public isSelected(id: Int): boolean {
        return (this.selectedContract && this.selectedContract.Id === id);
    }

    public copy(): void {
        this.$uibModalInstance.close(this.selectedContract);
    }
}
