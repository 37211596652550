import { Transition } from "@uirouter/angularjs";
import angular from "angular";
import { Contract as ContractClass } from "domain/classes/contract.class";
import EngineeringEquipment from "domain/classes/engineeringEquipment.class";
import MovableProperty from "domain/classes/movableProperty.class";
import { Product } from "domain/classes/product.class";
import PropertyPart from "domain/classes/propertyPart.class";
import IBlockUI from "infrastructure/interfaces/IBlockUI";
import IPageSharedData from "infrastructure/interfaces/IPageSharedData";
import IPreviousContractRecord from "infrastructure/interfaces/IPreviousContractRecord";
import { NgController } from "infrastructure/NgController";
import { AbstractEmployee } from "infrastructure/services/employee.service";
import { NotifyService } from "infrastructure/services/notifyService";
import { ValidationService } from "infrastructure/services/validation.service";
import jQuery from "jquery";
import { SALE_CHANNELS } from "./constants";
import { PAYMENT_KINDS } from "./constants";
import { PhoneValidationService } from "infrastructure/services/phoneValidation.service";
import IScope from "infrastructure/interfaces/IScope";
import { IUGSKLocalStorage } from "infrastructure/interfaces";

class ValidationServiceWithForm extends ValidationService {
    public Form: angular.IFormController;
}

export class BaseProductBodyController extends NgController {
    public cashboxOperationsAvailable: boolean;
    public paymentKindsBlacklist: Array<string | number> = [];
    protected Contract: ContractClass;
    protected ngModelOptions: angular.INgModelOptions;
    protected Validation: ValidationServiceWithForm;
    protected blockUI: IBlockUI;
    protected pageSharedData: IPageSharedData;
    protected currentProduct: Product;
    protected Product: string;
    protected phoneValidationService: PhoneValidationService;
    protected Form: angular.IFormController;
    protected originalEmployee: AbstractEmployee;
    protected stateCrossFrom: string;
    protected isProductLocked = false;

    constructor(
        $injector: angular.auto.IInjectorService,
        $transition$: Transition,
        params: any,
        $scope: IScope,
    ) {
        super($injector, $transition$, params, $scope);
        this.Contract = null;
        this.ngModelOptions = this.di<angular.INgModelOptions>("ngModelOptions");
        this.blockUI = this.di<IBlockUI>("blockUI");
        this.Validation = this.di<ValidationServiceWithForm>("validationService");
        this.Contract = this.resolve<ContractClass>("Contract");
        this.pageSharedData = this.resolve<IPageSharedData>("pageSharedData");
        this.currentProduct = this.resolve<Product>("currentProduct");
        this.Product = this.currentProduct.code;
        this.stateCrossFrom = $transition$.params().crossFrom;
        this.isProductLocked = this.resolve<boolean>("isProductLocked");
    }
    protected get Repository() {
        return this.Contract.getRepository();
    }
    public setForm(form: angular.IFormController): void {
        this.Form = form;
        this.pageSharedData.Form = form;
        //  @compat
        //  in assuranceApartment
        this.Validation.Form = form;
    }
    public isFormLocked(): boolean {
        return this.Contract.isLocked() || this.isProductLocked;
    }
    public isFormModified(): boolean {
        return this.Form.modified;
    }
    public getLapsedPaymentCount(): number {
        if (this.Repository.LapsedPayment) {
            /* tslint:disable-next-line:max-line-length */
            const item = this.Repository.LapsedPayment.Collection.find((elem) => elem.LapsedPaymentId === this.Contract.LapsedPaymentId);
            if (item) {
                return item.LapsedPaymentCount;
            }
        }
        return 0;
    }

    public getFieldRepository(fieldName: string): angular.IPromise<void> {
        this.blockUI.start("Обновляются справочные данные");
        return this.Repository.update(fieldName, this.Contract.toDTO(), true).then((data) => {
            if (this.Contract.isLocked()) {
                return;
            }
            this.Contract.setFromRepository(data);
        }).finally(() => {
            this.blockUI.stop();
        });
    }
    public updateFieldRepository(fieldName: string): angular.IPromise<void> {
        return this.getFieldRepository(fieldName);
    }

    /**
     * Функция импорта данных предыдущего договора в текущий
     * @param {Object} previousContractRecord
     */
    /* tslint:disable-next-line:max-line-length */
    public importPreviousContract(previousContractRecord: IPreviousContractRecord, customMapRules: object, syncRepositoryCallback = angular.noop): angular.IPromise<void> {
        const notifyService = this.di<NotifyService>("notifyService");
        const $q = this.di<angular.IQService>("$q");
        const prolongableContract = previousContractRecord.Contract;
        const goodKeys = Object.keys(prolongableContract).filter((key) => {
            const value = prolongableContract[key];
            return !(value === null || (value === 0 && key.endsWith("Id")));
        });
        const globalMapRules = {
            EngineeringEquipments: (value, object) => {
                /* tslint:disable-next-line:max-line-length */
                object.EngineeringEquipments = value.map((element) => angular.extend(new EngineeringEquipment(), element));
            },
            Id: (value, object) => {
                const code = previousContractRecord.InfoSystemCode;
                if (code === 1) {
                    object.PreviousContractId = value;
                } else if (code === 2) {
                    object.CSIAPreviousContractId = value;
                }
            },
            MovableProperties: (value, object) => {
                /* tslint:disable-next-line:max-line-length */
                object.MovableProperties = value.map((element) => angular.extend(new MovableProperty(element.MovablePropertyTypeId), element));
            },
            PropertyConstructionYear: (value, object) => {
                object.PropertyConstructionYear = value;
            },
            PropertyInteriorRepairYear: (value, object) => {
                object.PropertyInteriorRepairYear = value;
            },
            PropertyParts: (value, object) => {
                /* tslint:disable-next-line:max-line-length */
                object.PropertyParts = value.map((element) => angular.extend(new PropertyPart(element.PropertyPartTypeId), element));
            },

        };
        const mapRules = angular.extend({}, globalMapRules, customMapRules);
        const contractPrototype: any = {};
        return $q.all(goodKeys.map((key) => {
            if (key in mapRules) {
                const value = prolongableContract[key];
                const result = mapRules[key](value, contractPrototype);
                if (result) {
                    return result;
                }
                return $q.resolve();
            }
            contractPrototype[key] = prolongableContract[key];
            return $q.resolve();
        })).then(() => {
            const promises = [];
            contractPrototype.ContractFrom = null;
            contractPrototype.ContractTo = null;
            contractPrototype.PreviousContractNumber = previousContractRecord.PolicyNumber;
            contractPrototype.PreviousPolicySerial = previousContractRecord.PolicySerial;
            if (contractPrototype.AdditionalConstructions) {
                promises.push(syncRepositoryCallback(contractPrototype, "AdditionalConstructions"));
            }
            if (contractPrototype.PropertyParts) {
                promises.push(syncRepositoryCallback(contractPrototype, "PropertyParts"));
            }
            return $q.all(promises);
        }).then(() => {
            this.blockUI.start("Копирование данных предыдущего договора");
            this.Contract.init(contractPrototype);
            this.Repository.load(this.Contract).then(() => {
                /* tslint:disable-next-line:max-line-length */
                notifyService.successMessage("Копирование данных", "Данные успешно скопированы из предыдущего договора.");
            }).catch(() => {
                notifyService.errorMessage("Копирование данных", "При копировании данных возникла ошибка.");
            }).finally(() => {
                this.blockUI.stop();
            });
        });
    }
    public $onInit(): void {
        [
            this.phoneValidationService,
        ] = this.resolve([
            "phoneValidationService",
        ]);

        this.originalEmployee = this.resolve<AbstractEmployee>("originalEmployee");
        const employee  = this.resolve<AbstractEmployee>("employee");
        const $localStorage  = this.di<IUGSKLocalStorage>("$localStorage");
        this.cashboxOperationsAvailable = (employee.info.SaleChannel !== SALE_CHANNELS.PARTNER);

        const navHeaderElement = jQuery("#navHeader");
        let scrollTopOld = 0;
        jQuery(document).bind("scroll", () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop > 100 && scrollTop > scrollTopOld) { // scroll to down
                navHeaderElement.addClass("collapseHead");
                navHeaderElement.find("li.dropdown").removeClass("open");
            } else { // scroll to up
                navHeaderElement.removeClass("collapseHead");
            }
            scrollTopOld = scrollTop;
        });

        const cashPaymentKindEnabled = this.resolve<boolean>("cashPaymentKindEnabled");
        if (!cashPaymentKindEnabled) {
            this.paymentKindsBlacklist = [
                "Наличные", PAYMENT_KINDS.CASH,
            ];
        }
        if (this.stateCrossFrom && $localStorage.CrossDto) {
            this.Contract.init($localStorage.CrossDto);
        }

        if (this.isProductLocked) {
            this.Contract.isLocked = () => true; // :(
            const notifyService = this.di<NotifyService>("notifyService");
            notifyService.infoMessage("Внимание", "Продукт отключен. Договор доступен только для просмотра.");
        }
    }
}
