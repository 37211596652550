import { StateService } from "@uirouter/angularjs";
import { IModalService } from "angular-ui-bootstrap";
import IAvailableProduct from "infrastructure/interfaces/IAvailableProduct";
import { NgController } from "infrastructure/NgController";
import { AuthenticationService } from "infrastructure/services/authentication.service";
import { SignalRService } from "infrastructure/services/signalR.service";
import { Employee } from "infrastructure/services/employee.service";
import { Helpers } from "infrastructure/app.helpers";
import { EnvService } from "infrastructure/services/env.service";
import { DesktopNotificationsListController } from "./modalDialog/desktopNotificationsList.controller";
import templateDesktopNotificationsList from "./modalDialog/desktopNotificationsList.html";
import { ShowUsersController } from "./modalDialog/showUsers.controller";
import templateShowUsers from "./modalDialog/showUsers.html";
import paymentsManualPdf from "./paymentsRegistries/manual_payments.pdf";
import "./topbar.css";

export class TopbarController extends NgController {
    public employee: Employee = null;
    public visibleProducts: IAvailableProduct[];
    public paymentsManual = paymentsManualPdf;
    public dashboardFrontEnabled: boolean;
    public dashboardFrontLink: string;
    private authenticationService: AuthenticationService;
    private signalRService: SignalRService;
    private $state: StateService;
    private helpers: Helpers;

    public $onInit() {
        this.employee = this.resolve<Employee>("employee");
        this.visibleProducts = this.resolve<IAvailableProduct[]>("availableProducts");
        this.authenticationService = this.di<AuthenticationService>("authenticationService");
        this.helpers = this.di<Helpers>("helpers");
        this.signalRService = this.di<SignalRService>("signalRService");
        this.$state = this.di<StateService>("$state");
        const envService = this.di<EnvService>("envService");
        this.dashboardFrontEnabled = envService.read("dashboardFrontEnabled");
        this.dashboardFrontLink = envService.read("dashboardFrontLink");
    }

    public openUsersList() {
        const $uibModal = this.di<IModalService>("$uibModal");
        $uibModal.open({
            controller: ShowUsersController,
            controllerAs: "vm",
            size: "lg",
            template: templateShowUsers,
        });
    }

    public deimpersonateUser() {
        this.employee.deImpersonate();
        this.refreshUser();
    }

    public refreshUser() {
        const notifyService = this.di<any>("notifyService");
        this.employee.deleteEmployeeInfo();
        this.signalRService.disable();

        // update - искуственный параметр, чтобы ui-router понял, что что-то изменилось
        return this.$state.go(this.$state.current, { update: Date.now() }, { reload: true }).then(() => {
            notifyService.successMessage("Данные пользователя обновлены");
        }).catch((err) => {
            notifyService.errorMessage("Ошибка обновления данных", String(err));
        });
    }

    public logout() {
        this.authenticationService.logout();
        this.$state.go("login");
    }

    // TODO: Костылик для отображения переключателя темы для осаго и каско,
    // после того как переключатель тем будет доступен во всех продукта - выпилить!
    public isThemeSwitcherVisible() {
        const productCode = (this.$state.current.data && this.$state.current.data.productCode) || "";
        return (["osago", "uAuto"].includes(productCode));
    }

    public isNotificationsEnabled() {
        return this.signalRService.isEnabled();
    }

    public isEmbed(): boolean {
        return this.helpers.isEmbedMode();
    }

    public getNotificationsCount() {
        return this.signalRService.getUnreadNotificationsCount();
    }

    public openDesktopNotificationsList() {
        const $uibModal = this.di<IModalService>("$uibModal");
        $uibModal.open({
            controller: DesktopNotificationsListController,
            controllerAs: "vm",
            template: templateDesktopNotificationsList,
        });
    }

    public isProductPageDisabled(code: string): boolean {
        return this.helpers.isProductDisabled(code);
    }
}
