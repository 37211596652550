/**
 * ugskOsagoUsagePeriod - компонента для работы с "Периодом использования ТС"
 */
import angular, { ITimeoutService } from "angular";
import { DatePeriod } from "domain/classes/datePeriod";
import { declinationOfNumerals } from "infrastructure/app.helpers";
import { NgComponentController } from "infrastructure/NgController";
import { Day, Int } from "infrastructure/types";
import moment, { min } from "moment";
import { VEHICLE_REGISTRATIONS } from "application/constants";
import "./ugskOsagoUsagePeriod.component.css";
import { ValidationService } from "infrastructure/services/validation.service";
import { isPeriodsOverlapped } from "application/osago/helpers";

class UgskOsagoUsagePeriod extends NgComponentController {
    public usagePeriods: DatePeriod[];
    public minDate: Day;
    public middleDate?: Int;
    public maxDate: Day;
    public onChange: () => void;
    public isFormLocked: boolean;
    public vehicleRegistration: string;
    public insuranceTerm: string;
    private $timeout: ITimeoutService;
    private validationService: ValidationService;
    /**
     * @description Добавление нового периода. Максимальное количество периодов = 3
     */
    public addUsagePeriod() {
        if (this.usagePeriods.length < 3) {
            if (this.middleDate) {
                this.usagePeriods.push(new DatePeriod(this.getMaxMinDate(), this.maxDate));
            } else {
                this.usagePeriods.push(new DatePeriod(this.minDate, this.maxDate));
            }

            this.onChange();
        }
    }
    /**
     * @description Удаление периода
     * @param {number} index $index периода для удаления
     */
    public removeUsagePeriod(period: DatePeriod): void {
        this.usagePeriods = this.usagePeriods.filter((p) => p !== period);
        this.onChange();
    }

    public getLastPeriod(): DatePeriod {
        const { length, [length - 1]: last } = this.usagePeriods;
        return last;
    }

    public getMaxMinDate(): Day {
        const { To } = this.getLastPeriod() || { To: null };
        return ( To && moment(To).add(1, "s").format() ) || this.minDate;
    }

    public getMonthSuffix(period: DatePeriod): string {
        if (!period) {
            return "";
        }
        const monthNum = period.inMonthes;
        return (monthNum <= 0)
            ? "Меньше месяца"
            : `${monthNum} ${declinationOfNumerals(["месяц", "месяца", "месяцев"], monthNum)}`;
    }

    public reflectOnMinMaxDateChange(): void {
        if (this.isFormLocked) {
            return;
        }
        let minDate = this.minDate;
        for (const period of this.usagePeriods) {
            if (minDate) {
                if (minDate === this.minDate) {
                    minDate = moment(minDate).subtract(1, "day").endOf("day").format();
                }
                if (this.middleDate) {
                    period.toMinDate = DatePeriod.getAddDate({
                        date: minDate, count: this.middleDate, unitOfTime: "month",
                    }).startOf("m").format();
                    const dateToMinFrom = DatePeriod.getAddDate({
                        date: period.From, count: this.middleDate, unitOfTime: "month", endOf: "day",
                    }).subtract(1, "d");
                    if (typeof period.toMinDate === "string" && dateToMinFrom.isAfter(moment(period.toMinDate), "s")) {
                        period.toMinDate = dateToMinFrom.startOf("m").format();
                    }
                } else {
                    period.toMinDate = DatePeriod.getAddDate({ date: minDate, count: 1, unitOfTime: "day" }).format();
                }
                if (minDate !== this.minDate) {
                    minDate = moment(minDate).add(1, "m").startOf("m").format();
                }
                period.fromMinDate = minDate;
                minDate = period.To;
            }
            if (this.maxDate) {
                if (this.middleDate) {
                    period.fromMaxDate = moment(this.maxDate).subtract(this.middleDate, "month").add(2, "second").format();
                } else {
                    period.fromMaxDate = moment(this.maxDate).subtract(1, "day").format();
                }
                if (period.fromMaxDate < this.minDate) {
                    period.fromMaxDate = this.minDate;
                }
            }
        }
        if (this.middleDate) {
            this.usagePeriods = this.usagePeriods.filter(
                (p) => p.From === p.To || p.fromMonthes(this.maxDate, false) >= this.middleDate
            );
        }
        let maxPeriod;
        this.usagePeriods = this.usagePeriods.filter((p) => {
            if (!maxPeriod) {
                maxPeriod = p.To;
                return true;
            } else if (typeof p.fromMinDate === "string") {
                if (typeof p.fromMaxDate === "string" && moment(p.fromMaxDate).isBefore(p.fromMinDate)) {
                    return false;
                }
                if (moment(maxPeriod).isBefore(p.fromMinDate)) {
                    maxPeriod = p.To;
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        });

    }
    public getPeriodToMaxDate(period: DatePeriod): Day | boolean {
        if (!period.From) {
            return false;
        }
        return this.maxDate;
    }
    public canAddPeriod(): boolean {
        if (!this.getLastPeriod()) {
            return true;
        }
        return this.getLastPeriod().toMonthes(this.maxDate, false) >= this.middleDate && this.usagePeriods.length < 3;
    }
    public setPeriodToEnd(period: DatePeriod) {
        if (period && period.To !== period.From) {
            period.setLastDay(period.To, "day");
        }
    }
    public onInit(): void {
        this.validationService = this.di("validationService");
        if (this.usagePeriods.length === 0) {
            this.addUsagePeriod();
        }

        this.$timeout = this.di("$timeout");
        this.$watch(() => this.minDate, (newVal, oldVal) => {
            if (newVal !== oldVal) {
                this.resetUsagePeriods();
            }
        });
        this.$watch(() => this.middleDate, (newVal, oldVal) => {

            if (newVal !== oldVal) {
                this.resetUsagePeriods();
            }
        });
        this.$watch(() => this.maxDate, (newVal, oldVal) => {
            if (newVal !== oldVal) {
                this.resetUsagePeriods();
            }
        });
        this.$watch(() => this.usagePeriods.length, () => this.reflectOnMinMaxDateChange());
        for (const i of [0, 1, 2]) {
            this.$watch(() => this.usagePeriods[i] && this.usagePeriods[i].From, (date) => {
                if (date) {
                    this.reflectOnMinMaxDateChange();
                    this.setPeriodToEnd(this.usagePeriods[i]);
                }
            });
            this.$watch(() => this.usagePeriods[i] && this.usagePeriods[i].To, (date) => {
                if (date) {
                    this.reflectOnMinMaxDateChange();
                    this.setPeriodToEnd(this.usagePeriods[i]);
                }
            });
        }
        this.$watch(() => this.usagePeriods.map((period) => `${period.From}>${period.To}`).join("|"),
            (newValue, oldValue) => {
                if (this.isFormLocked) {
                    this.toggleUsagePeriodsOverlappingMessage(false);
                    return;
                }

                if (newValue !== oldValue) {
                    const isOverlapped = isPeriodsOverlapped(this.usagePeriods);
                    this.toggleUsagePeriodsOverlappingMessage(isOverlapped);
                }
            },
        );
    }

    public isControlsVisible(): boolean {
        if (this.vehicleRegistration === VEHICLE_REGISTRATIONS.RF) {
            return this.insuranceTerm === "1 год";
        }
        return false;
    }

    public getInfoMessage(): string {
        if (this.vehicleRegistration === VEHICLE_REGISTRATIONS.RF) {
            return "Если ТС зарегистрировано в РФ, то первый Период использования ТС автоматически заполняется по "
                + "сроку действия договора ОСАГО. Для изменения Периода использования ТС и/или добавления других "
                + "периодов выберите нужные даты и/или используйте кнопку «Добавить»";
        } else {
            return "Если ТС не зарегистрировано в РФ, то Период использования ТС устанавливается равным сроку "
                + "действия договора ОСАГО";
        }
    }

    private resetUsagePeriods(): void {
        if (this.isFormLocked) {
            return;
        }

        this.usagePeriods.length = 0;

        this.$timeout(() => {
            this.addUsagePeriod();
        });
    }

    private toggleUsagePeriodsOverlappingMessage(isOverlapped = false): void {
        const message = "Периоды использования ТС не должны пересекаться";
        if (isOverlapped === true) {
            this.validationService.addError("UsagePeriods", [message]);
        } else {
            const messages = this.validationService.getMessages("UsagePeriods");
            if (!angular.isArray(messages)) {
                return;
            }
            const messageIndex = messages.indexOf(message);
            if (messageIndex >= 0) {
                messages.splice(messageIndex, 1);
                this.validationService.removeError("UsagePeriods");
                this.validationService.addError("UsagePeriods", messages);
            }
        }
    }
}

export default angular.module("app.osago.components.usage-period", []).component("ugskOsagoUsagePeriod", {
    bindings: {
        insuranceTerm: "<",
        isFormLocked: "<",
        maxDate: "<",
        middleDate: "<",
        minDate: "<",
        onChange: "&",
        usagePeriods: "=",
        vehicleRegistration: "<",
    },
    controller: UgskOsagoUsagePeriod,
    controllerAs: "vm",
    template: require("./ugskOsagoUsagePeriod.component.html"),
})
.name;
