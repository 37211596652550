/**
 * @description Валидация СНИЛС
 * @see https://goo.gl/9TqmHO
 */

interface ISnilsScope extends ng.IScope {
    model: string;
    name: string;
    maxLength: number;
    snilsValidationDisabled: boolean;
    onSnilsValidationError: (e: {
        error: {
            errors: string[],
            name: string,
        }
    }) => void;
}

export function SnilsValidationDirective(): ng.IDirective {
    "ngInject";

    return {
        link: (
            scope: ISnilsScope,
        ) => {
            if (!scope.snilsValidationDisabled) {
                const maxCountNumbers = 11;
                scope.$watch("model", () => {
                    const snils = (scope.model || "").replace(/[^0-9]/g, "");

                    const isValid = (input: string): boolean => {
                        let sum = 0;
                        let checkDigit = 0;
                        for (let i = 0; i < 9; i++) {
                            sum += Number.parseInt(input[i], 10) * (9 - i);
                        }
                        if (sum < 100) {
                            checkDigit = sum;
                        } else if (sum > 101) {
                            checkDigit = Number.parseInt(String(sum % 101), 10);
                            if (checkDigit === 100) {
                                checkDigit = 0;
                            }
                        }
                        return checkDigit === Number.parseInt(snils.slice(-2), 10);
                    };

                    // Количество цифр в СНИЛС должно быть не более 11 символов
                    if (scope.model && snils && snils.length > maxCountNumbers) {
                        const error = {
                            errors: [
                                `Количество цифр должно быть не больше ${Number(maxCountNumbers)} символов`,
                            ],
                            name: scope.name,
                        };
                        scope.onSnilsValidationError({ error });
                    }

                    if (scope.model && snils && snils.length === maxCountNumbers) {
                        if (!isValid(snils)) {
                            const error = {
                                errors: [
                                    "Неверное значение контрольного числа. Проверьте корректность введенного номера СНИЛС.",
                                ],
                                name: scope.name,
                            };
                            scope.onSnilsValidationError({ error });
                        }
                    }
                });
            }
        },
        require: "ngModel",
        restrict: "A",
        scope: {
            maxLength: "@maxlength",
            model: "=ngModel",
            name: "@ugskValidation",
            onSnilsValidationError: "&",
            snilsValidationDisabled: "<",
        },
    };
}
