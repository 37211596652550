import angular from "angular";
import ugskDialogModule from "application/components/ugsk-dialog/ugskDialog.component";
import { UAutoContract } from "application/uAuto/uAuto.factory";
import { UAutoRepository } from "application/uAuto/uAuto.repository";
import { NgComponentController } from "infrastructure//NgController";

class UautoApprovalRequest extends NgComponentController {
    public contract: UAutoContract;
    public Repository: UAutoRepository;

    public onInit(): void {
        this.Repository = this.contract.getRepository();
    }
}

export default angular.module("app.uauto.components.approval-request", [
    ugskDialogModule,
]).component("ugskUautoApprovalRequest", {
    bindings: {
        contract: "<",
    },
    controller: UautoApprovalRequest,
    controllerAs: "vm",
    template: require("./ugskUautoApprovalRequest.component.html"),
}).name;
