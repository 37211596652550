import angular, { IPromise } from "angular";
import "angular-odata-resources";
import { IBoxVHIProductDTO, IPaymentKind, ITravelingAbroadInsuranceProgram } from "infrastructure/interfaces";
import { IODataResourceClass } from "infrastructure/interfaces/OData/IODataResource";
import IOdataResourceService from "infrastructure/interfaces/OData/IODataResourceService";
import Filial from "infrastructure/interfaces/WebApi/Filial";
import { NgControllerBase } from "infrastructure/NgController";
import { EnvService } from "./env.service";

/**
 * Сервис справочников
 * FR replacement
 */
const validDictionaries = [
    "BoxVHIContractJournalItems/K3.GetBoxVHIProducts",
    "TravelingAbroadContractJournalItems/K3.GetTravelingAbroadInsurancePrograms",
    "A7DocumentSerials",
    "PaymentKinds",
    "Banks",
    "ContractStatuses",
    "Filials",
    "PolicySerials",
    "EngineType",
];

export class DictionariesService extends NgControllerBase {
    private storage: {
        [key: string]: IPromise<any>,
    } = {};
    private resources: {
        [key: string]: IODataResourceClass<any>,
    } = {};
    public get(dictionaryName: "BoxVHIContractJournalItems/K3.GetBoxVHIProducts"): IPromise<IBoxVHIProductDTO[]>;
    // tslint:disable-next-line: max-line-length
    public get(dictionaryName: "TravelingAbroadContractJournalItems/K3.GetTravelingAbroadInsurancePrograms"): IPromise<ITravelingAbroadInsuranceProgram[]>;
    public get(dictionaryName: "PaymentKinds"): IPromise<IPaymentKind[]>;
    public get(dictionaryName: "Filials"): IPromise<Filial[]>;
    public get(dictionaryName: "A7DocumentSerials"): IPromise<any[]>;
    public get(dictionaryName: "PolicySerials"): IPromise<any[]>;
    public get(dictionaryName: "EngineType"): IPromise<any[]>;
    public get<T>(dictionaryName: string) {
        const envService = this.di<EnvService>("envService");
        const $odataresource = this.di<IOdataResourceService>("$odataresource");
        if (!validDictionaries.includes(dictionaryName)) {
            throw new Error("Unknown dictionaryName");
        }
        if (!this.resources[dictionaryName]) {
            const apiUrl: string = envService.read("apiUrl");
            let baseUrl = `${apiUrl}api/${dictionaryName}`;
            if (["PolicySerials", "EngineType"].includes(dictionaryName)) {
                baseUrl = `${apiUrl}/${dictionaryName}`;
            }
            this.resources[dictionaryName] = $odataresource<T>(baseUrl, {}, {}, {
                isodatav4: true,
                odatakey: "id",
            });
        }
        if (!this.storage[dictionaryName]) {
            this.storage[dictionaryName] = this.resources[dictionaryName]
                .odata()
                .query()
                .$promise;
        }
        return this.storage[dictionaryName];
    }
}

export default angular
    .module("app.services.dictionaries", ["ODataResources"])
    .service("dictionariesService", DictionariesService)
    .name;
