import { IFactPaymentsResource } from "infrastructure/app.resource";
import { NgControllerBase } from "infrastructure/NgController";

export class FactPaymentsService extends NgControllerBase {
    public factPaymentsResource: IFactPaymentsResource;
    constructor($injector: angular.auto.IInjectorService) {
        super($injector);
        this.factPaymentsResource = this.di<IFactPaymentsResource>("factPaymentsResource");
    }

    public odata() {
        return this.factPaymentsResource.odata();
    }
}
