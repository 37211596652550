import template from "./ugskNotification.component.html";
import "./ugskNotification.component.css";

export default angular.module("ugsk.components.notification", []).component("ugskNotification", {
    bindings: {},
    controller: class {
        constructor(notifyStaticProvider) {
            this.messages = notifyStaticProvider.notifications;
        }
        static get $inject() {
            return ["notifyStaticProvider"];
        }
    },
    controllerAs: "vm",
    template,
}).name;
