/**
 * ugskOsagoSpecialNote - компонента "Особые отметки"
 */
import angular from "angular";
import { NgComponentController } from "infrastructure/NgController";

export default angular.module("app.osago.components.special-note", [])
.component("ugskOsagoSpecialNote", {
    bindings: {
        isFormLocked: "<",
        othersInformation: "=",
        sellerComment: "=",
        specialNote: "=",
        violationsExist: "=",
    },
    controller: NgComponentController,
    controllerAs: "vm",
    template: require("./ugskOsagoSpecialNote.component.html"),
})
.name;
