import angular, { IPromise } from "angular";
import { Contract } from "domain/classes/contract.class";
import InspectionConclusionDTO from "infrastructure/interfaces/WebApi/InspectionConclusionDTO";

const $q = angular.injector(["ng"]).get("$q");

export default function <T extends new(...args: any[]) => Contract>(constructor: T) {
    return class extends constructor {
        public InspectionConclusions: InspectionConclusionDTO[] = [];
        /**
         * @description В ответе на сохранении договора всегда возвращается пустой массив с ПСО
         * @description ПСО. Всегда один, хотя в массиве
         * @override
         * @see http://bitbucket.ugsk.ru/projects/UPRO/repos/general/browse/UGSK.K3.Front/Controllers/KASKOController.cs?at=refs%2Ftags%2Fv2.34.8h2#485
         * @param {any} params
         * @returns
         */
        public $save(params) {
            const storedInspectionConclusions = this.InspectionConclusions;
            return super.$save(params).then(() => {
                this.InspectionConclusions = storedInspectionConclusions;
            });
        }
        public $saveInspectionConclusion() {
            if (!this.Id) {
                throw new Error("Invalid contract");
            }
            if (!this.InspectionConclusions.length) {
                return $q.resolve();
            }
            let promise: IPromise<InspectionConclusionDTO> = null;
            const conclusion = this.InspectionConclusions[0];

            conclusion.ContractId = this.Id;

            const resource = this.getResourceProvider();
            if (conclusion.Id === 0) {
                promise = resource.saveInspectionConclusion({ }, conclusion).$promise;
            } else {
                promise = resource.updateInspectionConclusion({ id: conclusion.Id }, conclusion).$promise;
            }
            return promise.then((data) => {
                this.InspectionConclusions = [data];
            });
        }
        public $deleteInspectionConclusion() {
            const conclusionToDelete = this.InspectionConclusions[0];
            if (!conclusionToDelete) {
                return $q.resolve();
            }
            return $q((resolve, reject) => {
                if (!conclusionToDelete.Id) {
                    return resolve();
                }
                return this.getResourceProvider().deleteInspectionConclusion({
                    id: conclusionToDelete.Id,
                }).$promise.then(resolve, reject);
            }).then(() => {
                this.InspectionConclusions.splice(0, 1);
            });
        }
    };
}
