// @todo Компонент ипользуется только в продукте "Страхование путешественников"
// @todo Возможно переделать компонент для редактирования застрахованного лица, который будет использоваться в большинстве продуктов
import moment from "moment";
import { NgComponentController } from "infrastructure/NgController";
import template from "./ugskInsuredPerson.component.html";

export default angular.module("ugsk.components.insured-person", []).component("ugskInsuredPerson", {
    bindings: {
        person: "=ngModel",
        removalAvailable: "<",
        requestRemoval: "&onRemovalRequested",
    },
    controller: class extends NgComponentController {
        onInit() {
            this.insuredPersonBirthdateConfig = {
                endDate: moment().format("YYYY-MM-DD"),
            };
        }
        /**
         * Компоратор для сравнения значений в input'ах
         * @param {*} model текущее заначение
         * @param {*} master сохранённое значение
         * @return {*} Возвращает совпадают ли значения
         */
        stringComparator(model, master) {
            return String(model).toUpperCase() === String(master).toUpperCase();
        }
    },
    controllerAs: "vm",
    template,
}).name;
