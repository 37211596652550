import angular from "angular";
import { declinationOfNumerals, Helpers } from "infrastructure/app.helpers";
import { IODataParams, IODataResourceResult } from "infrastructure/interfaces";
import Filial from "infrastructure/interfaces/WebApi/Filial";
import { NgComponentController } from "infrastructure/NgController";
import { NotifyService } from "infrastructure/services/notifyService";
import { Day, Guid, Int } from "infrastructure/types";
import { NgTableParams as NgTableParamsType } from "ng-table";
import "./ugskSaleLimitsTable.component.css";
import template from "./ugskSaleLimitsTable.component.html";

interface ISaleLimit {
    AmountOfDaysSinceLastSoldKasko: Int;
    FilialGuid: Guid;
    Id: Int;
    MaxSalesCount: Int;
    OnDate: Day;
    SalesCount: Int;
}

interface ISaleLimitsService {
    get: (params: IODataParams) => IODataResourceResult<ISaleLimit>;
    save: (params: {
        AmountOfDaysSinceLastSoldKasko: Int;
        Id: Int;
        MaxSalesCount: Int;
    }) => IODataResourceResult<void>;
    saveFilialsKaskoDays: (params: { amount: Int }) => IODataResourceResult<void>;
}

class SaleLimitsTableController extends NgComponentController {
    public tableParams: NgTableParamsType<ISaleLimit>;
    public saleLimitsService: ISaleLimitsService;
    public savingInProgress: boolean;
    public allFilialsKaskoDaysValue: Int;
    public filials: Filial[];
    public canSetLimits: boolean;
    public selectedFilialGuid: Guid;
    public selectedKaskoDaysFilialGuid: Guid;
    public revertValue: Int;
    public revertKaskoDaysValue: Int;

    public onInit(): void {
        const NgTableParamsClass = this.di<typeof NgTableParamsType>("NgTableParams");
        this.saleLimitsService = this.di<ISaleLimitsService>("saleLimitsService");
        this.revertValue = null;
        this.selectedFilialGuid = null;
        this.savingInProgress = false;
        this.selectedKaskoDaysFilialGuid = null;
        this.revertKaskoDaysValue = null;
        this.allFilialsKaskoDaysValue = null;
        this.tableParams = new NgTableParamsClass({
            count: 15,
            page: 1,
        }, {
            counts: [],
            getData: (params: NgTableParamsType<ISaleLimit>) => {
                const odataParams = {
                    $count: true,
                    $orderby: this.getOrderingParams(params),
                    $skip: (params.page() - 1) * params.count(),
                    $top: params.count(),
                };
                this.mask(" ");
                return this.saleLimitsService.get(odataParams).$promise.then((data) => {
                    params.total(data["@odata.count"]);
                    return data.value;
                }).finally(() => {
                    this.unmask();
                });
            },
            total: 0,
        });
    }

    public getOrderingParams(params: NgTableParamsType<ISaleLimit>): string {
        const sortingParams = params.sorting();
        const sortingKeys = Object.keys(sortingParams);

        if (sortingKeys.length > 0) {
            const filterKey = sortingKeys[0];
            return `${filterKey} ${sortingParams[filterKey]}`;
        }
    }

    public getFilialNameByGuid(guid: Guid): string {
        const filial = this.filials.find((item) => item.Guid === guid);
        return (filial) ? filial.Name : "";
    }

    public edit(limit: ISaleLimit): void {
        if (this.canSetLimits) {
            this.selectedFilialGuid = limit.FilialGuid;
            this.revertValue = limit.MaxSalesCount;
        }
    }

    public save(limit: ISaleLimit): void {
        if (this.isLimitInEditMode(limit)) {
            this.savingInProgress = true;

            this.saleLimitsService.save({
                AmountOfDaysSinceLastSoldKasko: limit.AmountOfDaysSinceLastSoldKasko,
                Id: limit.Id,
                MaxSalesCount: limit.MaxSalesCount,
            }).$promise.then(() => {
                const notifyService = this.di<NotifyService>("notifyService");
                notifyService.successMessage("Лимиты продаж ОСАГО", "Данные успешно сохранены");
                this.selectedFilialGuid = null;
                this.revertValue = null;
            }).finally(() => {
                this.savingInProgress = false;
            });
        }
    }

    public revert(limit: ISaleLimit): void {
        if (this.isLimitInEditMode(limit)) {
            limit.MaxSalesCount = this.revertValue;
            this.revertValue = null;
            this.selectedFilialGuid = null;
        }
    }

    public isLimitInEditMode(limit: ISaleLimit): boolean {
        return limit.FilialGuid === this.selectedFilialGuid;
    }

    public isRowEditable(): boolean {
        return this.canSetLimits && !this.selectedFilialGuid && !this.selectedKaskoDaysFilialGuid;
    }

    public onLimitChanged(limit: ISaleLimit): void {
        if (!limit.MaxSalesCount) {
            limit.MaxSalesCount = 0;
            return;
        }
        limit.MaxSalesCount = Number(String(limit.MaxSalesCount).replace(/[^\d]/g, ""));
    }

    public editKaskoDays(row: ISaleLimit): void {
        if (this.canSetLimits) {
            this.selectedKaskoDaysFilialGuid = row.FilialGuid;
            this.revertKaskoDaysValue = row.AmountOfDaysSinceLastSoldKasko;
        }
    }

    public isKaskoDaysInEditMode(row: ISaleLimit): boolean {
        return row.FilialGuid === this.selectedKaskoDaysFilialGuid;
    }

    public revertKaskoDays(row: ISaleLimit): void {
        if (this.isKaskoDaysInEditMode(row)) {
            row.AmountOfDaysSinceLastSoldKasko = this.revertKaskoDaysValue;
            this.selectedKaskoDaysFilialGuid = null;
            this.revertKaskoDaysValue = null;
        }
    }

    public saveKaskoDays(row: ISaleLimit): void {
        if (this.isKaskoDaysInEditMode(row)) {
            this.savingInProgress = true;

            this.saleLimitsService.save({
                AmountOfDaysSinceLastSoldKasko: row.AmountOfDaysSinceLastSoldKasko,
                Id: row.Id,
                MaxSalesCount: row.MaxSalesCount,
            }).$promise.then(() => {
                const notifyService = this.di<NotifyService>("notifyService");
                const daysString = declinationOfNumerals(["день", "дня", "дней"], row.AmountOfDaysSinceLastSoldKasko);
                notifyService.successMessage(
                    `Наличие КАСКО за ${row.AmountOfDaysSinceLastSoldKasko} ${daysString}`,
                    "Данные успешно сохранены",
                );
                this.selectedKaskoDaysFilialGuid = null;
                this.revertKaskoDaysValue = null;
            }).finally(() => {
                this.savingInProgress = false;
            });
        }
    }

    public saveFilialsKaskoDaysValue(): void {
        if (this.allFilialsKaskoDaysValue === null) {
            this.allFilialsKaskoDaysValue = 0;
        }

        const notifyService = this.di<NotifyService>("notifyService");
        const helpers = this.di<Helpers>("helpers");
        const daysString = declinationOfNumerals(["день", "дня", "дней"], this.allFilialsKaskoDaysValue);
        const msgText = `Установить проверку наличия КАСКО за  <strong>${this.allFilialsKaskoDaysValue}</strong> ${daysString} для всех филиалов`;

        helpers.confirm({ text: msgText }).then(() => {
            notifyService.progressStart("Сохранение данных");
            this.saleLimitsService.saveFilialsKaskoDays({
                amount: this.allFilialsKaskoDaysValue,
            }).$promise.then(() => {
                notifyService.successMessage(
                    `Наличие КАСКО за ${this.allFilialsKaskoDaysValue} ${daysString}`,
                    "Данные успешно сохранены",
                );
            }).finally(() => {
                notifyService.progressStop();
                this.tableParams.page(1).reload();
            });
        });
    }
}

export default angular.module("app.dashboard.sale-limits", [])
.component("ugskSaleLimitsTable", {
    bindings: {
        canGetLimits: "<",
        canSetLimits: "<",
        filials: "<",
    },
    controller: SaleLimitsTableController,
    controllerAs: "vm",
    template,
}).name;
