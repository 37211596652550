import { AutoMapper, AutoMapperBase, AutoMapperHelper } from "automapper-ts";

/**
 * Monkey patch
 */
const automapperClasses = [];
AutoMapperBase.prototype.getKey = keyStringOrType => {
    if (typeof keyStringOrType === "string") return keyStringOrType;
    const name = AutoMapperHelper.getClassName(keyStringOrType);
    let classIndex = automapperClasses.indexOf(keyStringOrType);
    if (classIndex === -1) {
        automapperClasses.push(keyStringOrType);
        classIndex = automapperClasses.length - 1;
    }
    return `__uniq_${name}_${classIndex}`;
};

export default new AutoMapper();
