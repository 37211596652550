import { IModalInstanceService, IModalService } from "angular-ui-bootstrap";
import { Contract } from "domain/classes/contract.class";
import IDTO from "infrastructure/interfaces/IDTO";
import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";

export default {
    title: "raw",
    icon: "fa-code",
    action(context) {
        const $uibModal = context.di<IModalService>("$uibModal");
        $uibModal.open({
            controller: class {
                public value: string = JSON.stringify(context.Contract.toDTO(), null, 4);
                static get $inject() {
                    return ["$uibModalInstance"];
                }
                constructor(private $uibModalInstance: IModalInstanceService) {
                }
                public applyPatch() {
                    const patch: IDTO = JSON.parse(this.value);
                    const domainContract = (
                        context.Contract.constructor as typeof Contract
                    ).fromDTO(patch);
                    delete domainContract.ContractStatusId;
                    delete domainContract.Id;
                    delete domainContract.Guid;
                    context.Contract.init(domainContract);
                    this.$uibModalInstance.close();
                }
                public dismiss() {
                    this.$uibModalInstance.dismiss();
                }
            },
            controllerAs: "vm",
            size: "lg",
            template: `
                <ugsk-dialog
                    title="RAW Contract editor"
                    on-accept="vm.applyPatch()"
                    on-reject="vm.dismiss()">
                    <textarea
                        rows="20"
                        class="form-control"
                        ng-model="vm.value">
                    </textarea>
                </ugsk-dialog>
            `,
        });
    },
} as IExtraOptionPrototype;
