import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { UAutoContract } from "../uAuto.factory";
import { UAutoToolbarController } from "../uAutoToolbar.controller";

export default {
    icon: "fa fa-snowflake-o",
    title: "Заморозить расчет",
    action(context: UAutoToolbarController) {
        return context.Contract.$freeze().then(() => {
            // contract: null - чтобы договор подгрузился заново
            context.$state.go(context.$state.current, { contract: null }, { reload: true });
        });
    },
    disabled(context) {
        return (context.Contract.isBlank() || !context.Contract.isDraft());
    },
} as IExtraOptionPrototype;
