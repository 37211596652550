import {
  DictionaryEnum,
  getIboxQRCode,
  getQRCodeByString,
  IAvailablePaymentKind,
  IBCPayment,
  IBillCreateRequest,
  ICashBoxPayment,
  IFastPaymentRequest,
  IFixTrustPaymentRequest,
  IIAPayment,
  IInvoice,
  IInvoicePosition,
  IInvoiceStatus,
  IPayment,
  IPaymentKind,
  IPaymentStatus,
  ISendPublicLinkRequest,
  PaymentKindsEnum,
  Payments,
  PaymentStatusesEnum,
} from "@ugsk/payments";
import angular, { IPromise, IQService, ITimeoutService } from "angular";
import { IModalService } from "angular-ui-bootstrap";
import { ICashboxResource } from "infrastructure/interfaces";
import { NgComponentController } from "infrastructure/NgController";
import { EnvService } from "infrastructure/services/env.service";
import { NotifyService } from "infrastructure/services/notifyService";
import { Guid } from "infrastructure/types";
import moment from "moment";
import { forkJoin, from, Observable, of } from "rxjs";
import { catchError, finalize, map, tap } from "rxjs/operators";
import { ConfirmController } from "./confirm/confirmController";

import cashConfirmTemplate from "./confirm/cashConfirm.html";
import billConfirmTemplate from "./confirm/billConfirm.html";
import { ShowLinkController } from "./show-link/showLink.controller";
import showLinkTemplate from "./show-link/showLink.html";
import { TicketLinkController } from "./ticket-link/ticketLink.controller";
import TicketLinkTemplate from "./ticket-link/ticketLink.html";
import "./ugskPayments.component.css";
import template from "./ugskPayments.component.html";
import trustPaymentTemplate from "./trust-payment/trustPayment.html";
import changeSellerConfirmTemplate from "./changeSellerConfirm/changeSellerConfirm.html";
import { ValidationService } from "infrastructure/services/validation.service";
import { FISCALIZATION_STATUSES_ENUM } from "./types";
import {
  TrustPaymentController
} from "application/components/ugsk-payments-service/trust-payment/trustPayment.controller";
import {
  ChangeSellerConfirmController
} from "application/components/ugsk-payments-service/changeSellerConfirm/changeSellerConfirm";
import IUGSKUserContext from "infrastructure/interfaces/IUGSKUserContext";
import { AuthenticationService } from "infrastructure/services/authentication.service";

type PageModeType =
  | "NONE"
  | "INVOICES"
  | "INVOICE_POSITIONS"
  | "INVOICE_PAYMENTS"
  | "FIX_PAYMENT";

const INVOICE_STATUS_PAYED = 3;

const PAYMENT_SOURCE = "c1eeffa6-cbd3-4e94-a1c9-22edf8938381";

class PaymentsComponentController extends NgComponentController {
  public pageMode: PageModeType = "NONE";
  public invoices: IInvoice[] = [];
  public payments: IPayment[] = [];
  public positions: IInvoicePosition[] = [];
  public currentInvoice: IInvoice;
  public payAmount: number;
  public selectedPaymentKindId: Guid;
  public qrCodeBase64: string;
  public cashboxList: Array<{ title: string; value: Guid }> = [];
  public insuredPhone: string;
  public insuredEmail: string;
  public selectedCashboxGuid: Guid;
  public linkDeliveryFlag: number = 0;
  public linkDeliveryOptions: Array<{ value: number; title: string }>;
  public payBlockTitle: string;
  public payDocumentSerial: string;
  public payDocumentNumber: string;
  public disabledPaymentKinds?: string;
  public isPaymentDisabled = false;
  public canCreateInvoice = false;
  public paymentDate = moment();
  public maxPaymentDate = moment().endOf("day");
  public minPaymentDate: moment.Moment = null;
  public readonly paymentLinkKindId = "payment-link";
  public primaryPaymentKinds: IAvailablePaymentKind[] = [];
  public secondaryPaymentKinds: IAvailablePaymentKind[] = [];
  public secondaryPaymentKindsSelected = false;
  // tslint:disable-next-line: no-any
  public uiOptions: any;
  private isOrderedPayment = false;
  private insuredFullName: string;
  private insuredDocSerial: string;
  private insuredDocNumber: string;
  private insuredInn: string;
  private policySerial: string;
  private policyNumber: string;
  private productName: string;
  private paymentsApi: Payments;
  private contractGuid: Guid;
  private contractSignDate: string;
  private invoiceStatuses: IInvoiceStatus[] = [];
  private paymentKinds: IPaymentKind[] = [];
  private availablePaymentKinds: IAvailablePaymentKind[] = [];
  private paymentStatuses: IPaymentStatus[] = [];
  private notifyService: NotifyService;
  private $q: IQService;
  private $timeout: ITimeoutService;
  private validationService: ValidationService;
  private onPrintReceipt: (params: { invoiceId: string }) => IPromise<void>;
  private employeeInfo: IUGSKUserContext;
  private onPrintFreeFormReceipt: (params: {
    invoiceId: string;
    paymentId: string;
  }) => IPromise<void>;
  private onCreateInvoice: () => IPromise<void>;
  private updateEvent: Observable<void>;
  private fiscalizationStatuses: IPaymentStatus[] = [];

  public onInit(): IPromise<void> {
    const envService = this.di<EnvService>("envService");
    this.notifyService = this.di("notifyService");
    this.$q = this.di("$q");
    this.$timeout = this.di("$timeout");
    this.validationService = this.di("validationService");

    const cashboxResource: ICashboxResource = this.di("cashboxResource");

    const payUrl = envService.read("payUrl");

    this.paymentsApi = new Payments(payUrl);

    this.linkDeliveryOptions = [
      {
        title: "На телефон",
        value: 2,
      },
      {
        title: "На Email",
        value: 1,
      },
      {
        title: "Обоими способами",
        value: 3,
      },
      {
        title: "Не отправлять",
        value: 0,
      },
    ];

    this.uiOptions = {
      maskDefinitions: {
        "?": /\d/,
        // tslint:disable-next-line: object-literal-key-quotes
        9: undefined,
      },
    };

    this.$scope.$watch(
      () => this.selectedPaymentKindId,
      (currentValue, oldValue) => {
        if (currentValue !== oldValue) {
          if (
            ([
              PaymentKindsEnum.BILL,
              PaymentKindsEnum.PAYMENT_ORDER,
            ] as string[]).includes(currentValue) &&
            this.currentInvoice
          ) {
            this.payAmount = this.getMaxAmount();
          }

          this.payDocumentSerial = "";
          this.payDocumentNumber = "";
        }
      }
    );

    this.$watch(() => this.paymentDate, (paymentDate) => {
      if (!this.isPaymentOrderPayment()) {
        return;
      }

      if (!paymentDate) {
        this.$timeout().then(() => {
          this.validationService.addError("PaymentOrderDatePay", ["Поле обязательно для заполнения"]);
        });
      } else if (this.validationService.hasError("PaymentOrderDatePay")) {
        this.validationService.removeError("PaymentOrderDatePay");
      }
    });

    const authenticationService = this.di<AuthenticationService>("authenticationService");
    this.paymentsApi.setAccessToken(authenticationService.getAccessToken(), this.employeeInfo.IsImpersonated ? this.employeeInfo.Login : null);

    if (this.updateEvent) {
      this.updateEvent.subscribe(() => {
        this.showInvoicesList();
      });
    }

    return this.$q((resolve, reject) => {
      forkJoin([
        this.paymentsApi.dictionaries.get<IInvoiceStatus>(DictionaryEnum.INVOICE_STATUSES),
        this.paymentsApi.dictionaries.get<IPaymentKind>(DictionaryEnum.PAYMENT_KINDS),
        this.paymentsApi.dictionaries.get<IPaymentStatus>(DictionaryEnum.PAYMENT_STATUSES),
        this.paymentsApi.dictionaries.get<IPaymentStatus>(DictionaryEnum.FISCALIZATION_STATUSES),
        from(cashboxResource.getCashboxList().$promise).pipe(
          map((cashboxList) => {
            return cashboxList.map((item) => ({
              title: `${item.Name}, ${item.SetupAddress}`,
              value: item.Guid,
            }));
          }),
          catchError(() => {
            return of([]);
          })
        ),
        from(cashboxResource.getEmployeeCashbox().$promise).pipe(
          catchError(() => {
            return of(null);
          })
        ),
      ])
        .pipe(
          tap(
            ([
              invoiceStatuses,
              paymentKinds,
              paymentStatuses,
              fiscalizationStatuses,
              cashboxList,
              defaultCashbox,
            ]) => {
              this.invoiceStatuses = invoiceStatuses;
              this.paymentKinds = paymentKinds;
              this.paymentStatuses = paymentStatuses;
              this.fiscalizationStatuses = fiscalizationStatuses;
              this.cashboxList = cashboxList;

              if (
                defaultCashbox &&
                cashboxList.find(
                  (item) => item.value === defaultCashbox.cashboxGuid
                )
              ) {
                this.selectedCashboxGuid = defaultCashbox.cashboxGuid;
              }
            }
          ),
          finalize(() => {
            if (this.contractGuid) {
              this.showInvoicesList();
            }
          }),
          map(() => {
            return undefined;
          })
        )
        .toPromise()
        .then(() => {
          resolve();
        })
        .then(angular.noop, () => {
          this.notifyService.errorMessage(
            "Оплата",
            "Ошибка получения данных для оплаты"
          );
          reject();
        });
    });
  }

  public showInvoicesList(): void {
    this.currentInvoice = null;
    this.blockInterface();
    this.$q((resolve) => {
      this.paymentsApi
        .getInvoicesByContractId(this.contractGuid)
        .subscribe({
          next: (data) => {
            this.invoices = data.sort((a, b) =>
              moment(a.date, "YYYY-MM-DD").diff(moment(b.date, "YYYY-MM-DD"))
            );
          },
          error: (e) => {
            this.notifyService.errorMessage("Ошибка", e.error && e.error.value || e.message || "Неизвестная ошибка");
          },
        })
        .add(() => {
          this.pageMode = "INVOICES";
          this.payBlockTitle = "Счета на оплату";
          this.ublockInterface();
          resolve();
        });
    }).then(angular.noop);
  }

  public showPaymentsList(invoice: IInvoice): void {
    this.currentInvoice = invoice;
    this.blockInterface();
    this.$q((resolve) => {
      this.paymentsApi
        .getPaymentsByInvoiceId(invoice.id)
        .subscribe((data) => {
          this.payments = data;
        })
        .add(() => {
          this.pageMode = "INVOICE_PAYMENTS";
          this.payBlockTitle = `Платежи для счета № ${invoice.incNum}`;
          this.ublockInterface();
          resolve();
        });
    }).then(angular.noop);
  }

  public showPositions(invoice: IInvoice): void {
    this.currentInvoice = invoice;
    this.blockInterface();
    this.$q((resolve) => {
      this.paymentsApi
        .getPositionsByInvoiceId(invoice.id)
        .subscribe((data) => {
          this.positions = data;
        })
        .add(() => {
          this.pageMode = "INVOICE_POSITIONS";
          this.payBlockTitle = "";
          resolve();
        });
    })
      .then(angular.noop)
      .finally(() => {
        this.ublockInterface();
      });
  }

  public showFixPayment(invoice: IInvoice): void {
    this.payBlockTitle = `Зафиксировать оплату по счету № ${invoice.number}`;
    this.availablePaymentKinds = [];
    this.currentInvoice = invoice;
    this.minPaymentDate = moment(invoice.date).startOf("day");
    this.payAmount = this.getMaxAmount();

    this.blockInterface();
    this.$q((resolve) => {
      this.paymentsApi
        .getAvailablePaymentKinds(invoice.id)
        .subscribe((result) => {
          this.availablePaymentKinds = result;

          if (
              angular.isString(this.disabledPaymentKinds) &&
              this.disabledPaymentKinds.length > 0
          ) {
            this.availablePaymentKinds = this.availablePaymentKinds.filter(
                (kind) => !this.disabledPaymentKinds.split("|").includes(kind.id),
            );
          }

          const fastPaymentKindIndex = this.availablePaymentKinds.findIndex(
              (kind) => kind.id === PaymentKindsEnum.FAST_PAYMENT,
          );

          if (fastPaymentKindIndex >= 0) {
            const kind = this.availablePaymentKinds.splice(fastPaymentKindIndex, 1);
            this.availablePaymentKinds.splice(0, undefined, kind[0]);
          }

          const cashPaymentIndex = this.availablePaymentKinds.findIndex(
              (kind) => kind.id === PaymentKindsEnum.CASH,
          );

          if (cashPaymentIndex >= 0) {
            const kind = this.availablePaymentKinds.splice(cashPaymentIndex, 1);
            if (fastPaymentKindIndex >= 0) {
              this.availablePaymentKinds.splice(1, undefined, kind[0]);
            } else {
              this.availablePaymentKinds.splice(0, undefined, kind[0]);
            }
          }

          const bcPaymentKindIndex = this.availablePaymentKinds.findIndex(
              (kind) => kind.id === PaymentKindsEnum.BUSINESS_CARD,
          );

          if (bcPaymentKindIndex >= 0) {
            const kind = this.availablePaymentKinds.splice(bcPaymentKindIndex, 1);
            this.availablePaymentKinds.push(kind[0]);
          }

          const iaPaymentKindIndex = this.availablePaymentKinds.findIndex(
              (kind) => kind.id === PaymentKindsEnum.INTERNET_ACQUIRING,
          );

          if (iaPaymentKindIndex >= 0) {
            const kind = this.availablePaymentKinds.splice(iaPaymentKindIndex, 1);
            this.availablePaymentKinds.push(kind[0]);
          }

          this.primaryPaymentKinds = this.availablePaymentKinds.filter(
              (kind) => !kind.isDisabled && ([PaymentKindsEnum.FAST_PAYMENT, PaymentKindsEnum.CASH] as string[]).includes(kind.id),
          );

          this.secondaryPaymentKinds = this.availablePaymentKinds.filter(
              (kind) => !kind.isDisabled && !([PaymentKindsEnum.FAST_PAYMENT, PaymentKindsEnum.CASH] as string[]).includes(kind.id),
          );

          if (this.primaryPaymentKinds.length > 0) {
            this.onPayMethodChanged(this.primaryPaymentKinds[0].id);
          } else if (this.secondaryPaymentKinds.length > 0) {
            this.onPayMethodChanged(this.secondaryPaymentKinds[0].id)
          }

        })
        .add(() => {
          this.pageMode = "FIX_PAYMENT";
          resolve();
        });
    }).then(angular.noop)
    .finally(() => {
      this.ublockInterface();
    });
  }

  public invoiceStatusName(id: number): string {
    if (Array.isArray(this.invoiceStatuses)) {
      const status = this.invoiceStatuses.find((item) => item.id === id);
      if (status) {
        return status.description;
      }
    }
    return "unknown";
  }

  public getExpirationDateContent(date: string): string {
    if (date) {
      const momentDate = moment(date);
      return momentDate.isValid() ? momentDate.format("DD.MM.YYYY HH:mm") : date;
    }

    return "Не ограничено";
  }

  public isPaymentExpired(invoice: IInvoice): boolean {
    if (!invoice) {
      return false;
    }
    const expirationDateMomentObject = moment(invoice.expirationDate);
    if (!invoice.expirationDate || !expirationDateMomentObject.isValid()) {
      return false;
    }
    return invoice.status !== INVOICE_STATUS_PAYED && expirationDateMomentObject.isBefore(moment());
  }

  public paymentKindName(id: string): string {
    if (Array.isArray(this.paymentKinds)) {
      const kind = this.paymentKinds.find((item) => item.id === id);
      if (kind) {
        return kind.name;
      }
    }
    return "unknown";
  }

  public paymentStatusName(id: string): string {
    if (Array.isArray(this.paymentStatuses)) {
      const status = this.paymentStatuses.find((item) => item.id === id);
      if (status) {
        return status.name;
      }
    }
    return "unknown";
  }

  public formatDateString(date: string, time = true): string {
    let format = "DD.MM.YYYY";

    if (time) {
      format += " HH:mm";
    }

    if (date) {
      date = date.replace(/\[.*\]$/, "");
      const momentDate = moment(date);

      return momentDate.isValid() ? momentDate.format(format) : date;
    }

    return "---";
  }

  public canDeclinePayment(payment: IPayment): boolean {
    return !([
      PaymentStatusesEnum.DECLINED,
      PaymentStatusesEnum.PAYED,
    ] as string[]).includes(payment.paymentStatusId);
  }

  public openPaymentModal(paymentUrl: string, qrString: string = null): void {
    const $uibModal = this.di<IModalService>("$uibModal");

    $uibModal
      .open({
        controller: ShowLinkController,
        controllerAs: "vm",
        resolve: {
          url: () => paymentUrl,
          qr: () => qrString ?
            getQRCodeByString(qrString, { errorCorrectionLevel: "H" })
              .then((data) => data)
          : null,
        },
        size: "lg",
        template: showLinkTemplate,
      })
      .result.then(angular.noop);
  }

  public declinePayment(payment: IPayment) {
    return this.$q((resolve) => {
      this.blockInterface();
      this.paymentsApi
        .declinePayment(payment.id)
        .subscribe(
          () => {
            this.notifyService.successMessage(
              "Отмена платежа",
              "Платеж успшено отменен"
            );
            this.showInvoicesList();
          },
          (e) => {
            let message = "Неизвестная ошибка";
            switch (e.status) {
              case 400:
                message = `Нельзя отменить платёж с данным статусом или способом оплаты`;
                break;
              case 401:
                message = `Счёт этого платежа принадлежит другой системе`;
                break;
              case 404:
                message = `Не найден платёж с Id = ${payment.id}`;
                break;
              case 500:
                message = e.error || e.message || "Неизвестная ошибка";
                break;
              default:
                message = e.error || e.message || "Неизвестная ошибка";
            }
            this.notifyService.errorMessage(
              "Отмена платежа",
              e.error.value || e.message || message
            );
          }
        )
        .add(() => {
          this.ublockInterface();
          resolve();
        });
    }).then(angular.noop);
  }

  public onFixPay(): void {
    const fixPayment = () => {
      switch (this.selectedPaymentKindId) {
        case PaymentKindsEnum.CASH:
          this.payCash();
          break;
        case PaymentKindsEnum.BANK_CARD:
          this.payWithPrecheck();
          break;
        case PaymentKindsEnum.AWARD_OFFSET:
          if (this.payDocumentNumber) {
            this.payWithoutIntegration(this.selectedPaymentKindId, {
              number: this.payDocumentNumber,
            });
          }
          break;
        case PaymentKindsEnum.PAYMENT_ORDER:
          this.payByPaymentOrder();
          break;
        case PaymentKindsEnum.BILL:
          this.payByBill();
          break;
        case PaymentKindsEnum.INTERNET_ACQUIRING:
          this.acquiringCreateLink();
          break;
        case PaymentKindsEnum.FAST_PAYMENT:
          this.fastPaymentCreateLink();
          break;
        case PaymentKindsEnum.BUSINESS_CARD:
          this.businessCardCreateLink();
          break;
        case PaymentKindsEnum.IBOX:
          this.payByIbox();
          break;
        case this.paymentLinkKindId:
          this.sendPublicLink();
          break;
      }
    };

    if (String(this.currentInvoice.signerId).toLowerCase() !== String(this.getSellerId()).toLowerCase()) {
      const $uibModal = this.di<IModalService>("$uibModal");
      $uibModal
          .open({
            controller: ChangeSellerConfirmController,
            controllerAs: "vm",
            resolve: {
              sellerName: () => this.employeeInfo.Name,
            },
            size: "lg",
            template: changeSellerConfirmTemplate,
          }).result
          .then(() => {
            fixPayment();
          }, () => {
            this.notifyService.message("Оплата", "Отменена пользователем", {
              provider: "toaster",
              timeout: 5000,
              type: "warning",
            });
          });
    } else {
      fixPayment();
    }
  }

  // ----- Типы платежей
  public isCashPayment(): boolean {
    return this.selectedPaymentKindId === PaymentKindsEnum.CASH;
  }

  public isIBoxPayment(): boolean {
    return this.selectedPaymentKindId === PaymentKindsEnum.IBOX;
  }

  public isBankCardPayment(): boolean {
    return this.selectedPaymentKindId === PaymentKindsEnum.BANK_CARD;
  }
  public isAwardOffsetPayment(): boolean {
    return this.selectedPaymentKindId === PaymentKindsEnum.AWARD_OFFSET;
  }

  public isBillPayment(): boolean {
    return this.selectedPaymentKindId === PaymentKindsEnum.BILL;
  }

  public isInternetAcquiringPayment(): boolean {
    return this.selectedPaymentKindId === PaymentKindsEnum.INTERNET_ACQUIRING;
  }

  public isBusinessCardPayment(): boolean {
    return this.selectedPaymentKindId === PaymentKindsEnum.BUSINESS_CARD;
  }

  public isFastPayment(): boolean {
    return this.selectedPaymentKindId === PaymentKindsEnum.FAST_PAYMENT;
  }

  public isPaymentLink(): boolean {
    return this.selectedPaymentKindId === this.paymentLinkKindId;
  }

  public isTrustPaymentAvailable(paymentKindId: string, paymentStatusId: string): boolean {
    return paymentKindId === PaymentKindsEnum.BILL
        && paymentStatusId === PaymentStatusesEnum.TO_PAY;
  }

  public isPayButtonVisible(payment: IPayment): boolean {
    return payment.paymentStatusId === PaymentStatusesEnum.TO_PAY && !!payment.paymentUrl;
  }

  public isPaymentOrderPayment(): boolean {
    return this.selectedPaymentKindId === PaymentKindsEnum.PAYMENT_ORDER;
  }

  public isPayAmountDisabled(): boolean {
    if (this.isAwardOffsetPayment()) {
      return false;
    }
    return true;
  }

  // -----

  public payByIbox(): void {
    if (!this.currentInvoice) {
      return;
    }

    this.blockInterface();
    this.$q((resolve, reject) => {
      this.paymentsApi.addIboxPayment({
        amount: String(this.payAmount),
        invoice: this.currentInvoice.id,
      }).subscribe(
          () => {
            this.notifyService.successMessage(
              "Оплата iBox",
              "Платеж успешно зафиксирован",
            );
            getIboxQRCode(
                "Прочие",
                Number(this.payAmount),
                this.insuredFullName,
                `${this.insuredDocSerial} ${this.insuredDocNumber}`,
                `${this.policySerial} ${this.policyNumber}`,
                this.currentInvoice.id,
                1,
                this.getSellerId(),
            ).then((data) => {
              this.qrCodeBase64 = data;
            });
            resolve();
          },
          (e) => {
            let message = e.message;
            if (e.error && e.error.value) {
              message = e.error.value;
            }
            this.notifyService.errorMessage(
                "Оплата iBox",
                message,
            );
            reject();
          },
      ).add(() => {
        this.ublockInterface();
        resolve();
      });
    }).then(angular.noop);
  }

  public onFixTrustPaymentClicked(paymentId: string): void {
    const $uibModal = this.di<IModalService>("$uibModal");
    $uibModal
        .open({
          controller: TrustPaymentController,
          controllerAs: "vm",
          size: "lg",
          template: trustPaymentTemplate,
        })
        .result.then(({ documentNumber, documentDate }) => {
          const payload: IFixTrustPaymentRequest = {
            documentDate,
            documentNumber,
            paymentId,
          };
          this.blockInterface();
          this.$q((resolve, reject) => {
            this.paymentsApi.fixTrustPayment(payload)
                .subscribe(
                    () => {
                      this.showPaymentsList(this.currentInvoice);
                      this.notifyService.successMessage(
                          "Доверительная оплата",
                          "Платеж успешно зафиксирован",
                      );
                      resolve();
                    },
                    (e) => {
                      let message = e.message;
                      if (e.error && e.error.value) {
                        message = e.error.value;
                      }
                      this.notifyService.errorMessage(
                          "Доверительная оплата",
                          message
                      );
                      reject();
                    },
                ).add(() => {
                  this.ublockInterface();
                  resolve();
                });
          }).then(angular.noop);
        });
  }

  public onSecondaryPaymentKindsClick() {
    this.onPayMethodChanged(null);
    this.secondaryPaymentKindsSelected = true;
  }

  public onPayMethodChanged(paymentKindId: Guid): void {
    this.selectedPaymentKindId = paymentKindId;
    this.secondaryPaymentKindsSelected = this.secondaryPaymentKinds.some((kind) => kind.id === this.selectedPaymentKindId);
    this.payAmount = this.getMaxAmount();

    if (this.qrCodeBase64 && this.isIBoxPayment()) {
      this.qrCodeBase64 = null;
    }
  }

  public thousands(amount: string): string {
    amount = String(amount);
    const filter: angular.IFilterService = this.di("thousandFilter");
    amount = filter(amount);
    if (!amount.split(".")[1]) {
      amount = `${amount}.00`;
    }
    return amount;
  }

  public isPrintReceiptAvailable(): boolean {
    return Boolean(
      this.onPrintReceipt && angular.isFunction(this.onPrintReceipt)
    );
  }

  public isPrintFreeFormReceiptAvailable(): boolean {
    return Boolean(
      this.onPrintFreeFormReceipt &&
        angular.isFunction(this.onPrintFreeFormReceipt)
    );
  }

  public createInvoice(): void {
    this.$q((resolve) => {
      if (this.onCreateInvoice && angular.isFunction(this.onCreateInvoice)) {
        this.onCreateInvoice().then((result) => {
          this.showInvoicesList();
        }).finally(() => {
          resolve();
        }).catch(angular.noop);
      }
      resolve();
    }).then(angular.noop);
  }

  public printReceipt(invoiceId: string): void {
    this.$q((resolve) => {
      if (this.isPrintReceiptAvailable()) {
        this.onPrintReceipt({ invoiceId }).finally(() => {
          resolve();
        });
      }
    }).then(angular.noop);
  }

  public printFreeFormReceipt(invoiceId: string, paymentId: string): void {
    this.$q((resolve) => {
      if (this.isPrintFreeFormReceiptAvailable()) {
        this.onPrintFreeFormReceipt({ invoiceId, paymentId }).finally(() => {
          resolve();
        });
      }
    }).then(angular.noop);
  }

  public canPrintReceipt(payment: IPayment): boolean {
    return (
      !this.isPaymentDeclined(payment) && // Оплата не отклонена
      payment.paymentKindId === PaymentKindsEnum.BILL
    ); // Тип оплаты = Счет
  }

  /**
   * @description используется только для "Платежное поручение"
   */
  public canPrintInvoice(payment: IPayment): boolean {
    return (
      payment.paymentStatusId !== PaymentStatusesEnum.DECLINED && // Оплата не отклонена
      payment.paymentKindId === PaymentKindsEnum.PAYMENT_ORDER
    ); // Тип оплаты = "Платежное поручение"
  }

  public isPaymentDeclined(payment: IPayment): boolean {
    return payment.paymentStatusId === PaymentStatusesEnum.DECLINED;
  }

  public isFiscalizationInfoAvailable(payment: IPayment): boolean {
    const fiscalizationStatusesWhiteList =
        [FISCALIZATION_STATUSES_ENUM.RECEIPT, FISCALIZATION_STATUSES_ENUM.REFUND] as string[];
    return payment.isFiscalizationInfoAvailable
        && fiscalizationStatusesWhiteList.includes(payment.fiscalizationStatusId);
  }

  public isPaymentButtonVisible(invoice: IInvoice): boolean {
    return invoice && !invoice.isAnnulled
        && this.getSellerId() !== "00000000-0000-0000-0000-000000000000"
        && !this.isPaymentExpired(invoice)
        && invoice.status !== INVOICE_STATUS_PAYED;
  }

  public isPaymentButtonDisabled(invoice: IInvoice): boolean {
    if (!invoice) {
      return true;
    }

    if (this.isPaymentDisabled) {
      return true;
    }

    let paymentRestriction = false;
    const notAnnulledInvoices = this.invoices.filter((invoiceItem) => !invoiceItem.isAnnulled);
    if (notAnnulledInvoices.length > 0 && this.isOrderedPayment) {
      const invoiceIndex = notAnnulledInvoices.findIndex((invoiceItem) => invoiceItem.id === invoice.id);
      if (invoiceIndex > 0) {
        paymentRestriction = notAnnulledInvoices[invoiceIndex - 1].status !== INVOICE_STATUS_PAYED;
      }
    }

    return paymentRestriction || invoice.payedAmount >= invoice.amount;
  }

  public isLinkDeliveryOptionDisabled(option): boolean {
    switch (option.value) {
      case 0:
        return false;
      case 1:
        return !this.insuredEmail;
      case 2:
        return !this.insuredPhone;
      case 3:
        return !this.insuredEmail || !this.insuredPhone;
      default:
        return false;
    }
  }

  public getMaxAmount(): number {
    if (this.currentInvoice) {
      return (
          (100 * this.currentInvoice.amount - 100 * this.currentInvoice.payedAmount) / 100
      );
    }

    return 0;
  }

  private blockInterface(): void {
    this.blockUIInstance.start("Получение данных");
  }

  private ublockInterface(): void {
    this.blockUIInstance.stop();
  }

  private payCash(): void {
    const $uibModal = this.di<IModalService>("$uibModal");
    $uibModal
      .open({
        controller: ConfirmController,
        controllerAs: "vm",
        resolve: {
          amount: () => this.payAmount,
          userName: () => this.employeeInfo.Name,
        },
        size: "lg",
        template: cashConfirmTemplate,
      })
      .result.then((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.payWithoutIntegration(this.selectedPaymentKindId);
        }
      });
  }

  private normalizePhone(phone: string): string {
    if (phone) {
      return String(phone).replace(/[\s\-\(\)]+/g, "");
    }

    return null;
  }

  // tslint:disable-next-line: no-any
  private payWithoutIntegration(
    paymentKindId: string,
    additionalParams: any = {}
  ): Promise<void> {
    return new Promise<void>((masterResolve, masterReject) => {
      if (this.paymentsApi) {
        const defParams = {
          amount: Number(this.payAmount),
          datePay: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
          insuredEmail: this.insuredEmail ? this.insuredEmail : null,
          insuredPhone: this.insuredPhone
            ? this.normalizePhone(this.insuredPhone)
            : null,
          invoices: [this.currentInvoice.id],
          paymentKindId,
          seller: this.getSellerId(),
          source: PAYMENT_SOURCE,
        };
        const payload = Object.assign({}, defParams, additionalParams);

        this.blockInterface();
        this.$q((resolve) => {
          this.paymentsApi
            .addCashPayment(payload)
            .subscribe(
              () => {
                this.showInvoicesList();
                this.notifyService.successMessage(
                  "Регистрация платежа",
                  "Платеж зарегистрирован"
                );
                this.payDocumentNumber = null;
                this.payDocumentSerial = null;
                masterResolve();
              },
              (e) => {
                let message = e.message;
                if (e.error && e.error.value) {
                  message = e.error.value;
                }
                this.notifyService.errorMessage("Регистрация платежа", message);
                masterReject(message);
              }
            )
            .add(() => {
              this.ublockInterface();
              resolve();
            });
        }).then(angular.noop);
      }
    });
  }

  private payWithPrecheck(): void {
    const payload: ICashBoxPayment = {
      cashBoxGuid: this.selectedCashboxGuid,
      code: `${this.policySerial}-${this.policyNumber}`,
      datePay: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
      insuredDocumentNumber: String(this.insuredDocNumber),
      insuredDocumentSerial: String(this.insuredDocSerial),
      insuredEmail: this.insuredEmail ? this.insuredEmail : null,
      insuredPhone: this.insuredPhone
        ? this.normalizePhone(this.insuredPhone)
        : null,
      invoices: [this.currentInvoice.id],
      nomenclature: this.contractGuid,
      payedSum: Number(this.payAmount),
      price: this.currentInvoice.amount,
      productName: `Договор страхования ${this.productName} ${
        this.policySerial
      }-${this.policyNumber}`,
      seller: this.getSellerId(),
      source: PAYMENT_SOURCE,
    };

    this.blockInterface();
    this.$q((resolve) => {
      this.paymentsApi
        .addCashBoxPayment(payload)
        .subscribe(
          () => {
            this.showInvoicesList();
            this.notifyService.successMessage(
              "Регистрация платежа",
              "Платеж зарегистрирован"
            );
          },
          (e) => {
            let message = e.message;
            if (e.error && e.error.value) {
              message = e.error.value;
            }
            this.notifyService.errorMessage("Регистрация платежа", message);
          }
        )
        .add(() => {
          this.ublockInterface();
          resolve();
        });
    }).then(angular.noop);
  }

  private payByBill(): void {
    const payload: IBillCreateRequest = {
      amount: Number(this.payAmount),
      invoices: [this.currentInvoice.id],
      seller: this.getSellerId(),
      source: PAYMENT_SOURCE,
    };

    const invoiceId = this.currentInvoice.id;

    this.blockInterface();
    this.$q((resolve) => {
      this.paymentsApi
        .billCreate(payload)
        .subscribe(
          () => {
            this.notifyService.successMessage(
              "Регистрация счета",
              "Счет зарегистрирован"
            );
            this.showInvoicesList();
            this.printReceipt(invoiceId);

            const $uibModal = this.di<IModalService>("$uibModal");
            $uibModal
              .open({
                controller: ConfirmController,
                controllerAs: "vm",
                size: "lg",
                template: billConfirmTemplate,
              })
              .result.then(angular.noop);
          },
          (e) => {
            let message = e.message;
            if (e.error && e.error.value) {
              message = e.error.value;
            }
            this.notifyService.errorMessage("Регистрация счета", message);
          }
        )
        .add(() => {
          this.ublockInterface();
          resolve();
        });
    }).then(angular.noop);
  }

  private payByPaymentOrder(): void {
    const currentInvoiceId = this.currentInvoice.id;
    if (this.payDocumentNumber) {
      this.payWithoutIntegration(this.selectedPaymentKindId, {
        datePay: moment(this.paymentDate).format("YYYY-MM-DDT00:00:00+00:00"),
        number: this.payDocumentNumber,
        serial: this.payDocumentSerial,
      }).then(() => {
        this.printReceipt(currentInvoiceId);
      });
    }
  }

  private acquiringCreateLink(): void {
    const envService = this.di<EnvService>("envService");
    const paymentPageUrl = envService.read("paymentPageUrl");
    const policyFullNumber = [this.policySerial, this.policyNumber]
      .filter((item) => item)
      .join("-");
    const policyDate = moment(this.contractSignDate).format("DD.MM.YYYY");

    const amount = Number(this.payAmount);
    const description = `Оплата договора ${policyFullNumber} от ${policyDate} на сумму ${amount}руб., без НДС`;
    const desc = `по договору ${policyFullNumber}`;
    const successCallback = `${paymentPageUrl}success-payment.html?desc=${encodeURI(
      desc
    )}&id=`;
    const failCallback = `${paymentPageUrl}fail-payment.html?desc=${encodeURI(
      desc
    )}&id=`;
    // tslint:disable-next-line: max-line-length
    const emailText = `Ссылка на оплату договора ${policyFullNumber} от ${policyDate} на сумму ${amount}руб., без НДС`;

    const payload: IIAPayment = {
      acquiringBank: "SB",
      amount,
      description,
      emailText,
      expirationDate: moment()
        .add(24, "hours")
        .format("YYYY-MM-DDTHH:mm:ss"),
      failCallback,
      invoices: [this.currentInvoice.id],
      linkDeliveryFlags: Number(this.linkDeliveryFlag),
      seller: this.getSellerId(),
      smsText: emailText,
      sourceId: "c1eeffa6-cbd3-4e94-a1c9-22edf8938381",
      successCallback,
    };

    this.$q((resolve) => {
      this.blockInterface();

      this.paymentsApi
        .addAcquiringPayment(payload)
        .subscribe(
          (response) => {
            this.showInvoicesList();
            const sendMsg =
              payload.linkDeliveryFlags === 0
                ? "ссылка НЕ отправлена клиенту"
                : "ссылка отправлена клиенту";
            this.notifyService.successMessage(
              "Платеж зарегистрирован",
              sendMsg
            );
            this.openPaymentModal(response.formUrl);
          },
          (e) => {
            let message = e.message;
            if (e.error && e.error.value) {
              message = e.error.value;
            }
            this.notifyService.errorMessage("Регистрация счета", message);
          }
        )
        .add(() => {
          this.ublockInterface();
          resolve();
        });
    }).then(angular.noop);
  }

  private businessCardCreateLink(): void {
    const envService = this.di<EnvService>("envService");
    const paymentPageUrl = envService.read("paymentPageUrl");

    const amount = Number(this.payAmount);
    const policyFullNumber = [this.policySerial, this.policyNumber]
        .filter((item) => item)
        .join("-");
    const policyDate = moment(this.contractSignDate).format("DD.MM.YYYY");
    const callback = `${paymentPageUrl}success-payment.html?desc=${encodeURI(
        `по договору ${policyFullNumber}`
    )}&id=`;
    const emailText = `Ссылка на оплату договора ${policyFullNumber} от ${policyDate} на сумму ${amount}руб., без НДС`;

    const payload: IBCPayment = {
      amount,
      callback,
      description: `Оплата договора ${policyFullNumber} от ${policyDate} на сумму ${amount}руб., без НДС`,
      emailText,
      invoice: this.currentInvoice.id,
      linkDeliveryFlags: Number(this.linkDeliveryFlag),
      seller: this.getSellerId(),
      sessionTimeoutSecs: 86400,
      smsText: emailText,
      sourceId: "c1eeffa6-cbd3-4e94-a1c9-22edf8938381",
    };

    this.$q((resolve) => {
      this.blockInterface();
      this.paymentsApi
          .addBusinessCardPayment(payload)
          .subscribe(
              (response) => {
                this.showInvoicesList();
                const sendMsg =
                    payload.linkDeliveryFlags === 0
                        ? "ссылка НЕ отправлена клиенту"
                        : "ссылка отправлена клиенту";
                this.notifyService.successMessage(
                    "Платеж зарегистрирован",
                    sendMsg,
                );
                this.openPaymentModal(response.formUrl);
              },
              (e) => {
                let message = e.message;
                if (e.error && e.error.value) {
                  message = e.error.value;
                }
                this.notifyService.errorMessage("Регистрация счета", message);
              },
          )
          .add(() => {
            this.ublockInterface();
            resolve();
          });
    }).then(angular.noop);
  }

  private fastPaymentCreateLink(): void {
    const policyFullNumber = [this.policySerial, this.policyNumber]
      .filter((item) => item)
      .join("-");
    const policyDate = moment(this.contractSignDate).format("DD.MM.YYYY");

    const amount = Number(this.payAmount);
    const description = `Оплата договора ${policyFullNumber} от ${policyDate} на сумму ${amount}руб., без НДС`;
    // tslint:disable-next-line: max-line-length
    const emailText = `Ссылка на оплату договора ${policyFullNumber} от ${policyDate} на сумму ${amount}руб., без НДС`;

    const payload: IFastPaymentRequest = {
      amount,
      description,
      emailText,
      expirationDate: moment()
        .add(24, "hours")
        .format("YYYY-MM-DDTHH:mm:ss"),
      invoice: this.currentInvoice.id,
      linkDeliveryFlags: Number(this.linkDeliveryFlag),
      seller: this.getSellerId(),
      smsText: emailText,
    };

    this.$q((resolve) => {
      this.blockInterface();

      this.paymentsApi
        .fastPaymentCreateLink(payload)
        .subscribe(
          (response) => {
            this.showInvoicesList();
            const sendMsg =
              payload.linkDeliveryFlags === 0
                ? "ссылка НЕ отправлена клиенту"
                : "ссылка отправлена клиенту";
            this.notifyService.successMessage(
              "Платеж зарегистрирован",
              sendMsg
            );
            this.openPaymentModal(response.qr.fastInvoice, response.qr.fastInvoice);
          },
          (e) => {
            let message = e.message;
            if (e.error && e.error.value) {
              message = e.error.value;
            }
            this.notifyService.errorMessage("Регистрация счета", message);
          }
        )
        .add(() => {
          this.ublockInterface();
          resolve();
        });
    }).then(angular.noop);
  }

  private sendPublicLink(): void {
    const amount = Number(this.payAmount);
    const policyFullNumber = [this.policySerial, this.policyNumber]
      .filter((item) => item)
      .join("-");
    const policyDate = moment(this.contractSignDate).format("DD.MM.YYYY");
    const emailText = `Ссылка на оплату договора ${policyFullNumber} от ${policyDate} на сумму ${amount}руб., без НДС`;

    const payload: ISendPublicLinkRequest = {
      description: this.currentInvoice.description,
      emailText,
      invoiceId: this.currentInvoice.id,
      linkDeliveryFlags: Number(this.linkDeliveryFlag),
      smsText: emailText,
    };

    this.$q((resolve) => {
      this.blockInterface();
      this.paymentsApi
        .sendPublicLink(payload)
        .subscribe(
          ({ value }) => {
            this.openPaymentModal(value);
            if (this.linkDeliveryFlag !== 0) {
              this.notifyService.successMessage("Ссылка отправлена клиенту");
            }
          },
          (e) => {
            let message = e.message;
            if (e.error && e.error.value) {
              message = e.error.value;
            }
            this.notifyService.errorMessage("Ошибка при отправке ссылки", message);
          },
        )
        .add(() => {
          this.ublockInterface();
          resolve();
        });
    }).then(angular.noop);
  }

  public getFiscalizationInfo(id: string, fiscalizationStatusId: string) {
    this.blockInterface();
    this.$q((resolve) => {
        this.paymentsApi.fiscalizationInfo(id)
        .subscribe({
            next: (data) => {
                if (data.ofdPayload.ofd_receipt_url) {
                    window.open(data.ofdPayload.ofd_receipt_url);
                } else {
                    const $uibModal = this.di<IModalService>("$uibModal");
                    $uibModal.open({
                        controller: TicketLinkController,
                        controllerAs: "vm",
                        resolve: {
                            data: () => data,
                            fiscalizationStatusId: () => fiscalizationStatusId,
                        },
                        size: "md",
                        template: TicketLinkTemplate,
                    });
                }
            },
            error: (e) => {
                this.notifyService.errorMessage("Ошибка", e.error.value || e.message || "Неизвестная ошибка");
            }
        }).add(() => {
            this.ublockInterface();
            resolve();
        });
    }).then(angular.noop);
  }

  public get isInvoicesPayed(): boolean {
    return angular.isArray(this.invoices) && this.invoices.every((invoice) => invoice.status === 3);
  }

  public set isInvoicesPayed(newVal: boolean) { }

  public isPaymentLinkEnabled(): boolean {
    const envService = this.di<EnvService>("envService");
    return envService.read("isPaymentLinkEnabled");
  }

  private getSellerId(): string | null {
    if (this.employeeInfo && this.employeeInfo.UUID) {
      return this.employeeInfo.UUID;
    }

    return null;
  }
}


export default angular
  .module("ugsk.components.payments-service", [])
  .component("ugskPaymentsService", {
    bindings: {
      canCreateInvoice: "<?",
      contractGuid: "<",
      contractSignDate: "<",
      disabledPaymentKinds: "<?",
      employeeInfo: "<",
      insuredDocNumber: "<",
      insuredDocSerial: "<",
      insuredEmail: "<",
      insuredFullName: "<",
      insuredInn: "<",
      insuredPhone: "<",
      isInvoicesPayed: "=?",
      isOrderedPayment: "<?",
      isPaymentDisabled: "<?",
      onCreateInvoice: "&?",
      onPrintFreeFormReceipt: "&?",
      onPrintReceipt: "&?",
      policyNumber: "<",
      policySerial: "<",
      productName: "@",
      updateEvent: "<?"
    },
    controller: PaymentsComponentController,
    controllerAs: "vm",
    template,
    transclude: true,
  }).name;
