import angular, { IHttpService, IQService, IWindowService  } from "angular";
import { IModalInstanceService, IModalService  } from "angular-ui-bootstrap";
import { InsurantPersonProxy } from "domain/proxies/insurantPersonProxy.class";
import moment from "moment";

import { BaseProductBodyController } from "../baseProduct.body.controller";
import { BoxAccidentPersonBase } from "./boxAccident.factory";

import IExcelInsuredPerson from "application/components/ugsk-upload-insured-person-from-excel/IExcelInsuredPerson";
import { capitalizeFirstLetter } from "infrastructure/app.helpers";
import { NotifyService } from "infrastructure/services/notifyService";
import adultAccidentXLS from "./adultAccident/AdultAccidentTemplate.xlsx";
import childAccidentXLS from "./childAccident/ChildAccidentTemplate.xlsx";
import familyAccidentXLS from "./familyAccident/FamilyAccidentTemplate.xlsx";
import shortTermAccidentXLS from "./shortTermAccident/ShortTermAccidentTemplate.xlsx";
import templateUploadExcel from "./templateUploadExcel.html";

import { DOC_TYPES } from "application/constants";
import { IDatePickerOptions } from "infrastructure/interfaces/IDatePickerOptions";
import { AddressDTO } from "infrastructure/interfaces/WebApi/AddressDTO";
import { EnvService } from "infrastructure/services/env.service";
import { DateTimeOffset, Int } from "infrastructure/types";
import { BoxAccidentRepository } from "./boxAccident.factory-ts";

interface IRow {
    InsuredPersonAddressUserDefinedAddress: string;
    InsuredPersonAddressFiasId: string;
    InsuredPersonAddressKladrId: string;
    InsuredPersonDocDateGiven: DateTimeOffset;
    InsuredPersonBirthday: DateTimeOffset;
    InsuredPersonDocTypeId: Int;
}

type ObjectPatcher<T> = (val: string, row: T) => void;
type objectPatcherOrString = ObjectPatcher<IRow> | string;
interface IImportedFieldsAnnotation {
    Email: objectPatcherOrString;
    "Адрес застрахованного": objectPatcherOrString;
    "Дата выдачи": objectPatcherOrString;
    "Дата рождения": objectPatcherOrString;
    "Имя": objectPatcherOrString;
    "Кем выдан": objectPatcherOrString;
    "Номер документа": objectPatcherOrString;
    "Отчество": objectPatcherOrString;
    "Серия документа": objectPatcherOrString;
    "Тип документа": objectPatcherOrString;
    "Фамилия": objectPatcherOrString;
}

export interface IDiscountListItem {
    Id: Int;
    Value: string;
}

export abstract class BoxAccidentBodyControllerBase extends BaseProductBodyController {
    public registrationDateConfig: Partial<IDatePickerOptions>;
    public contractMinDate: DateTimeOffset;
    public contractMaxDate: DateTimeOffset;
    public discountCollection: IDiscountListItem[];
    public isUnderwriterCoefficientVisible = true;
    public isDiscountVisible = true;

    protected maxCountInsuredPersons: number;
    protected personsNumber: number;
    protected insuredPersonIsInsured: boolean;
    protected underwriterCoefficientTouchSpin: any;
    protected insurant: InsurantPersonProxy;
    protected importedFieldsAnnotation: IImportedFieldsAnnotation;
    protected insuredPersonDocNumbersLength: number[];
    protected defaultNumberOfInsuredPersons: number;
    protected getUpdateFieldOperation: angular.IPromise<void>;
    protected insuredWatcher: any;

    public $onInit() {
        super.$onInit();
        this.phoneValidationService.setContractRequiredFields({
            individual: [
                { description: "Фамилия страхователя", field: "InsuredLastName" },
                { description: "Имя страхователя", field: "InsuredFirstName" },
                { description: "Дата рождения страхователя", field: "InsuredBirthday" },
            ],
            legalEntity: [
                { description: "Наименование организации страхователя", field: "InsuredOrgName" },
                { description: "ИНН организации страхователя", field: "InsuredOrgINN" },
            ],
        });

        this.maxCountInsuredPersons = this.Repository.InsuredPersonCountRestriction.Collection[0].MaxValue;
        this.personsNumber = 1;
        this.insuredPersonIsInsured = this.isInsuredPersonIsInsured();
        this.getUpdateFieldOperation = null;
        this.insuredWatcher = null;

        /* eslint-disable no-param-reassign */
        this.importedFieldsAnnotation = {
            "Email": "InsuredPersonEmail",
            "Адрес застрахованного": (value, row) => {
                if (value) {
                    this.loadSourсe(value).then(($data) => {
                        if ($data.data.length > 0) {
                            row.InsuredPersonAddressUserDefinedAddress = $data.data[0].Address;
                            row.InsuredPersonAddressFiasId = $data.data[0].Aoguid;
                            row.InsuredPersonAddressKladrId = $data.data[0].CodeKLADR;
                        } else {
                            row.InsuredPersonAddressUserDefinedAddress = null;
                            row.InsuredPersonAddressFiasId = null;
                            row.InsuredPersonAddressKladrId = null;
                        }
                    });
                } else {
                    row.InsuredPersonAddressUserDefinedAddress = null;
                    row.InsuredPersonAddressFiasId = null;
                    row.InsuredPersonAddressKladrId = null;
                }
            },
            "Дата выдачи": (value, row) => {
                if (value) {
                    row.InsuredPersonDocDateGiven = value;
                } else {
                    row.InsuredPersonDocDateGiven = null;
                }
            },
            "Дата рождения": (val, row) => {
                const value = moment(val);
                if (value.isValid()) {
                    row.InsuredPersonBirthday = value.format("YYYY-MM-DDT00:00:00Z");
                } else {
                    row.InsuredPersonBirthday = null;
                }
            },
            "Имя": "InsuredPersonFirstName",
            "Кем выдан": "InsuredPersonDocWhomGiven",
            "Номер документа": "InsuredPersonDocNumber",
            "Отчество": "InsuredPersonMiddleName",
            "Серия документа": "InsuredPersonDocSerial",
            "Тип документа": (value, row) => {
                value = value.toLowerCase();
                if (value === "свидетельство о рождении") {
                    row.InsuredPersonDocTypeId = DOC_TYPES.BIRTH_CERTIFICATE;
                    return;
                }
                if (value === "паспорт гражданина рф") {
                    row.InsuredPersonDocTypeId = 12;
                    return;
                }
                if (value.includes("иностр")) {
                    row.InsuredPersonDocTypeId = 7;
                }
            },
            "Фамилия": "InsuredPersonLastName",
        };
        /* eslint-enable no-param-reassign */

        this.insurant = new InsurantPersonProxy(this.Contract);
        if (!this.Contract.ContractSatusId) {
            this.insuredPersonDocNumbersLength = [];
        }
        if (
            this.Contract.ContractStatusId === 1 &&
            moment(this.Contract.SigningDate, "YYYY-MM-DD").isBefore(moment().format("YYYY-MM-DD"), "day")
        ) {
            const notifyService = this.di<NotifyService>("notifyService");
            notifyService.warningMessage(
                "Внимание",
                "По данному договору устарела дата заключения договора. Рассчитайте договор заново для обновления даты заключения договора."
            );
        }

        this.underwriterCoefficientTouchSpin = {
            boostat: 5,
            buttondown_class: "btn btn-white",
            buttonup_class: "btn btn-white",
            decimals: 2,
            delimiter: ",",
            forcestepdivisibility: "round",
            max: this.Repository.UnderwriterCoefficientRestriction.Collection[0].MaxValue,
            maxboostedstep: 10,
            min: this.Repository.UnderwriterCoefficientRestriction.Collection[0].MinValue,
            step: 0.01,
        };

        if (this.isInsuredPersonIsInsured() && !this.insuredWatcher) {
            this.watchForInsuredPerson();
        }

        // todo необходимость в данном watch'е обноснована тем, что функция передающая значение
        // - при одностароннем биндинге value<"someDoFunc()" будет вызываться постоянно на каждом digest
        // цикле каждый раз запускать алгоритм обработки данных, что не приемлемо.
        // - вариант с get set не подходит, так как при смене значения типа страхователя в set приходит значение равное false
        // и это нарушает лоигку обработки, обработку нужно совершать до того как занчение признака застрахованного станет false,
        // но в тот момент когда тип страхователя стал = Юридическое лицо
        // вариант с watch'е приемлем тем, что логика обработки данных запустится лишь единожды, только при смене типа страхователя.

        /* eslint-disable no-underscore-dangle */
        this._$scope.$watch(() => this.Contract.InsuredContractorTypeId, () => {
            const item = this.Repository.InsuredContractorType.Collection.find((value) => angular.equals(this.Contract.InsuredContractorTypeId, value.InsuredContractorTypeId));

            if (!item) { return; }

            if (angular.equals(item.InsuredContractorType, "юридическое лицо") && this.isInsuredPersonIsInsured()) {
                this.removeInsuredPerson(0);
                if (this.Contract.InsuredPersons.length < this.defaultNumberOfInsuredPersons) {
                    const toValue = this.defaultNumberOfInsuredPersons - this.Contract.InsuredPersons.length;
                    for (let i = 0; i < toValue; i++) {
                        this.addPerson();
                    }
                }
            }
        });
        /* eslint-enable no-underscore-dangle */
    }
    public isDisabledInsuredPersonIsInsured() {
        const item = this.Repository.InsuredContractorType.Collection.find((value) => angular.equals(this.Contract.InsuredContractorTypeId, value.InsuredContractorTypeId));

        if (!item) { return undefined; }

        if (angular.equals(item.InsuredContractorType, "юридическое лицо")) {
            this.insuredPersonIsInsured = false;
            if (this.Contract.InsuredPersons[0]) {
                if (!this.Contract.InsuredPersons[0].InsuredDocTypeId) {
                    this.Contract.InsuredPersons[0].InsuredDocTypeId = 12;
                }
            }
        }

        return angular.equals(item.InsuredContractorType, "юридическое лицо");
    }
    public isInsuredPersonIsInsured() {
        // todo если InsuredPersons будет undefined то будет ошибка
        // нужно предусмотреть когда-нибудь
        if (this.Contract.InsuredPersons.length > 0) {
            return angular.equals(this.Contract.InsuredPersons[0].Guid, this.Contract.InsuredGuid);
        }

        return false;
    }
    public watchForInsuredPerson() {
        /* eslint-disable no-underscore-dangle */
        this.insuredWatcher = this._$scope.$watch(() => {
            let hash = "";
            this.insuredPersonIsInsured = this.isInsuredPersonIsInsured();
            Object.keys(this.Contract.InsuredPersons[0]).forEach((prop) => {
                if (prop !== "Guid") {
                    hash += this.Contract[prop.replace("Person", "")];
                }
            });

            return hash;
        }, () => {
            if (this.Contract.InsuredPersons[0].Guid !== this.Contract.InsuredGuid) {
                this.insuredWatcher();
                return;
            }

            Object.keys(this.Contract.InsuredPersons[0]).forEach((prop) => {
                if (!["Guid", "SpecialInsuredNote"].includes(prop)) {
                    const targetProp = prop.replace("Person", "");

                    if (this.Contract.hasOwnProperty(targetProp)) {
                        this.Contract.InsuredPersons[0][prop] = this.Contract[targetProp];
                    }
                }
            });
        });
        /* eslint-enable no-underscore-dangle */
    }
    public getPersonsRange() {
        if (this.maxCountInsuredPersons - this.Contract.InsuredPersons.length > 0) {
            return Array(this.maxCountInsuredPersons - this.Contract.InsuredPersons.length).fill(undefined).map((v, index) => index + 1);
        }
        return null;
    }
    public isDisableAddPerson() {
        return angular.equals(this.Contract.InsuredPersons.length, this.maxCountInsuredPersons) || this.Contract.isSigned();
    }
    public removeInsuredPerson(index: number) {
        this.Contract.InsuredPersons.splice(index, 1);
        if (!this.isDisableAddPerson()) {
            this.personsNumber = 1;
        }
        this.pageSharedData.Form.modified = true;
    }
    public isEmptyPerson(person) {
        return !person.InsuredPersonLastName && !person.InsuredPersonFirstName && !person.InsuredPersonMiddleName;
    }
    /**
     * @override
     */
    public updateFieldRepository(fieldName: string) {
        const [$timeout] = this.di(["$timeout"]);
        if (!this.getUpdateFieldOperation) {
            this.blockUI.start("Обновляются справочные данные");
            this.getUpdateFieldOperation = $timeout().then(() => this.Repository.update(fieldName, this.Contract, false)).finally(() => {
                this.blockUI.stop();
                this.getUpdateFieldOperation = null;
            });
        }
        return this.getUpdateFieldOperation;
    }
    public isDisabledAddInsuredPerson() {
        return (this.Contract.InsuredPersons.length >= this.maxCountInsuredPersons && !this.insuredPersonIsInsured) || this.Contract.isSigned();
    }
    public updateInsuredPersonDocTypeField(index: number) {
        this.Contract.InsuredPersons[index].InsuredPersonDocSerial = undefined;
        this.Contract.InsuredPersons[index].InsuredPersonDocNumber = undefined;
    }
    public isDocWhomGivenVisible(person) {
        const item = this.Repository.InsuredPersonDocType.Collection.find((doc) => angular.equals(doc.InsuredPersonDocTypeId, person.InsuredPersonDocTypeId));

        if (!item) { return undefined; }

        return angular.equals(item.InsuredPersonDocType, "Паспорт гражданина РФ") || angular.equals(item.InsuredPersonDocType, "Свидетельство о рождении");
    }
    public isDisabledDocSerial(person) {
        const item = this.Repository.InsuredPersonDocType.Collection.find((doc) => angular.equals(doc.InsuredPersonDocTypeId, person.InsuredPersonDocTypeId));

        if (!item) { return undefined; }

        if (angular.equals(item.InsuredPersonDocType, "Иностранный паспорт")) {
            person.InsuredPersonDocSerial = undefined;
        }

        return angular.equals(item.InsuredPersonDocType, "Иностранный паспорт");
    }
    public isInsuredPersonLocked(person) {
        return person.Guid === this.Contract.InsuredGuid;
    }
    public insurantContractorTypeChanged() {
        this.updateFieldRepository("InsuredContractorType").then(() => {
            // необхдимость в
            // установлении признака модифицированной формы, обоснована тем, что при смене типа страхователя
            // каждый раз подключается новая view = новый контрол для типа страхователя, и следовательно angularInputModified
            // идентифицирует его как новый не модифицированный контролл и следовательно признак модифицированной формы form.modified
            // всегда false при смене типа страхователя и поэтому конопка продажи после положительного расчета всегда активна,
            // даже если меняется тип страхователя. Данную особенность с динамическим подключеним view's следует учитывать при проектировании
            // компонент в дальнейшем, что бы избежать подобных моментов.
            this.pageSharedData.Form.modified = true;
        });
    }
    public uploadExcelFile() {
        const notifyService = this.di<NotifyService>("notifyService");
        const $uibModal = this.di<IModalService>("$uibModal");
        $uibModal.open({
            /* eslint-disable object-shorthand */
            controller: class {
                public importedInsuredPersons: IExcelInsuredPerson[];
                constructor(private $uibModalInstance: IModalInstanceService) {
                    "ngInject";
                }
                public dismiss(message: string) {
                    this.$uibModalInstance.dismiss({ $errorMessage: message });
                }
                public importInsuredPersons() {
                    const isValidRow = (item: IExcelInsuredPerson) => item.Фамилия && item.Имя && item.Отчество;
                    if (!isValidRow(this.importedInsuredPersons[0])) {
                        return this.dismiss("Неверная структура файла");
                    }
                    this.$uibModalInstance.close({ $data: this.importedInsuredPersons });
                    return undefined;
                }
            },
            /* eslint-enable object-shorthand */
            controllerAs: "vm",
            size: "sm",
            template: templateUploadExcel,
        }).result
            .then((data) => this.parseInsuredPersons(data.$data))
            .then((insuredPersons) => {
                this.Contract.InsuredPersons = insuredPersons;
                if (this.Contract.InsuredPersons.length >= 1) {
                    notifyService.successMessage("Импорт списка застрахованных", `Список успешно импортирован (${this.Contract.InsuredPersons.length})`);
                }
            }).catch((message) => {
                if (message.$errorMessage) {
                    notifyService.errorMessage("Импорт списка застрахованных", message.$errorMessage);
                }
            });
    }
    public getExcelTemplateFile() {
        const templates = {
            AdultAccident: adultAccidentXLS,
            ChildAccident: childAccidentXLS,
            FamilyAccident: familyAccidentXLS,
            ShortTermAccident: shortTermAccidentXLS,
        };
        const url = templates[capitalizeFirstLetter(this.Product)];
        const $window = this.di<IWindowService>("$window");
        $window.open(url, "_blank");
    }
    public parseInsuredPersons(personsImportList: IExcelInsuredPerson[]) {
        const $q = this.di<IQService>("$q");
        return $q.all(personsImportList.slice(0, this.maxCountInsuredPersons).map((importedInsuredPerson) => {
            const insuredPerson = new BoxAccidentPersonBase();
            return $q.all(Object.keys(this.importedFieldsAnnotation).map((key) => {
                const value = this.importedFieldsAnnotation[key];
                if (angular.isString(value)) {
                    insuredPerson[value] = importedInsuredPerson[key];
                    return $q.resolve();
                } else if (angular.isFunction(value)) {
                    const result = value(String(importedInsuredPerson[key]), insuredPerson);
                    if (result) {
                        return result;
                    }
                    return $q.resolve();
                }
                return $q.reject();
            })).then(() => $q.resolve(insuredPerson));
        }));
    }
    /**
    * функция задействует внешний сервис $http для выполнения
    * get запроса к справочнику ФИАС
    *
    * @param {string} val параметр введеный пользователем в контрол адреса для поиска
    */
    public loadSourсe(val: string) {
        const [$http, envService] = this.di(["$http", "envService"]) as [IHttpService, EnvService];
        return $http.get<AddressDTO[]>(`${envService.read("apiUrl")}Search/Address`, {
            params: {
                query: val,
            },
        });
    }
    protected abstract addPerson(): void;
    protected get Repository() {
        return (this.Contract.getRepository() as BoxAccidentRepository);
    }
}
