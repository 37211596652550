import { PhoneNumberDTO } from "infrastructure/interfaces/WebApi/PhoneNumberDTO";

export type PhoneStatuses = 0 | 1 | 2;

/**
 * @see UGSK.K3.Service.DTO/Shared/PhoneNumberDTO.cs
 */
export default class UgskPhone implements PhoneNumberDTO {

    public static phoneStringToPhoneArray(str: string): UgskPhone[] {
        const phones: string = str;
        if (!phones) {
            return [] as UgskPhone[];
        }
        return phones.split(",").map((item) => {
            const phone = new UgskPhone();
            const [num, status] = item.split("#");
            phone.Number = num;
            switch (status) {
            case "V":
                phone.Status = 1;
                break;
            case "X":
                phone.Status = 2;
                break;
            default:
                phone.Status = 0;
            }
            return phone;
        });
    }

    public static phoneArrayToPhoneString(array: UgskPhone[]): string {
        return array.map((phone) => {
            const postfix = ({
                0: "",
                1: "#V",
                2: "#X",
            });
            return `${phone.Number}${postfix[phone.Status]}`;
        }).join(",");
    }
    public Number: string;
    public Status: PhoneStatuses;

    public constructor(phone?: UgskPhone) {
        this.Number = phone && phone.Number ? phone.Number : "";
        this.Status = phone && phone.Status ? phone.Status : 0;
    }

}
