import angular from "angular";

import { Product } from "domain/classes/product.class";

export class SubLicensesController {
    private subLicenses: string[] = [];

    constructor(currentProduct: Product) {
        this.subLicenses = currentProduct.subLicenses;
    }

    public getSubLicensesList() {
        return this.subLicenses.join(", ");
    }

    static get $inject() {
        return ["currentProduct"];
    }
}

angular.module("SubLicensesController", []).controller("subLicensesController", SubLicensesController);
