import { Helpers } from "infrastructure/app.helpers";
import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { NotifyService } from "infrastructure/services/notifyService";

export default {
    action(context) {
        const helpers = context.di<Helpers>("helpers");
        helpers.confirm({
            text: "Вы уверены, что хотите аннулировать договор?"
        }).then(() => {
            const notifyService = context.di<NotifyService>("notifyService");
            notifyService.progressStart("Аннулирование договора ...");
            return context.Contract.$annul().then(() => {
                notifyService.successMessage("", "Договор успешно аннулирован");
            }).finally(() => {
                notifyService.progressStop();
            });
        }).catch(() => {/** */});
    },
    disabled(context) {
        return !context.Contract.canBeAnnulled() || !context.employee.hasPermission("Annul", context.product.code);
    },
    icon: "fa-times",
    title: "Аннулировать",
} as IExtraOptionPrototype;
