import * as angular from "angular";
import { EventEmitter } from "events";
interface IValidationError {
    code: string;
    // error: string;
    message: string;
}

abstract class Validable {
    static get validators(): IValidator[] {
        return [];
    }
    [key: string]: any
}
interface IValidator {
    description: string;
    message: string;
    code: string;
    isValid: (obj: any) => boolean;
}
//  @TODO: Переназвать интерфейс и класс
export class ValidationResult implements IValidationError {
    public message: string;
    public code: string;

    constructor({ message, code }) {
        this.message = message;
        this.code = code;
    }
}

export class ValidationService extends EventEmitter {
    private ErrorsKey: Map<string, string[]> = new Map();
    private validationKeys: Set<string> = new Set();

    constructor() {
        super();
    }
    public registerErrorKey(name: string) {
        this.validationKeys.add(name);
        this.emit("onRegisterErrorKey", name);
    }
    public unRegisterErrorKey(name: string) {
        this.validationKeys.delete(name);
    }
    public getValidationKeys() {
        return [...this.validationKeys];
    }
    public hasError(name: string) {
        return this.ErrorsKey.has(name);
    }
    public getMessages(name: string) {
        return this.ErrorsKey.get(name);
    }
    public getAllMessages() {
        const messages: string[][] = [];
        this.ErrorsKey.forEach((value) => {
            messages.push(value);
        });
        return messages.map((arrayValue) => {
            if (arrayValue.length > 1) {
                return arrayValue.join("<br/>");
            }
            return arrayValue.toString();
        }).join("<br/>");
    }
    public addError(name: string, value: string[]) {
        const uniqMessages: string[] = Array.from(new Set(value));
        this.ErrorsKey.set(name, uniqMessages);
    }
    public addObjectErrors(objectErrors: IValidationError[]) {
        objectErrors.forEach((error) => {
            this.addError(error.code, [error.message]);
        });
    }
    public addObjectsErrors(objectsErrors: IValidationError[][]) {
        objectsErrors.forEach((item) => {
            this.addObjectErrors(item);
        });
    }
    public removeError(name: string) {
        this.ErrorsKey.delete(name);
    }
    public clear() {
        this.ErrorsKey.clear();
    }
    public count() {
        return this.ErrorsKey.size;
    }
    public validateObjects(objectsArray: Validable[]) {
        return objectsArray.map((object) => this.validateObject(object));
    }
    /**
     * @description Метод для проведения валидации
     * @param {Object} objectToValidate Типизированный объект, содержащий правила валидации
     * @param {Boolean} recurseValidation Флаг отвечающий за рекурсивную валидацию объекта
     */
    public validateObject(objectToValidate: Validable, recurseValidation = true): ValidationResult[] {
        const childObjectsValidationResults: ValidationResult[] = [];
        if (recurseValidation) {
            angular.forEach(objectToValidate, (value) => {
                if (angular.isArray(value)) {
                    angular.forEach(value, (object) => {
                        const validationResult = this.validateObject(object);
                        if (angular.isArray(validationResult)) {
                            childObjectsValidationResults.push(...validationResult);
                        }
                    });
                }
            });
        }

        const objectConstructor: typeof Validable = Object.getPrototypeOf(objectToValidate).constructor;
        const validators = objectConstructor.validators;
        const selfValidationResults: ValidationResult[] = [];

        if (angular.isArray(validators)) {
            angular.forEach(validators, (validator) => {
                if (validator.isValid(objectToValidate)) {
                    return;
                }
                let validatorCode: string = validator.code;
                if (objectToValidate.Guid) {
                    validatorCode += objectToValidate.Guid;
                }
                selfValidationResults.push(new ValidationResult({
                    code: validatorCode,
                    message: validator.message,
                }));
            });
        }
        return [...selfValidationResults, ...childObjectsValidationResults];
    }
}
