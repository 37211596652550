import angular from "angular";
import { Payment } from "domain/classes/payment.class";

/**
 * Сервис преобразования различных экземпляров классов
 * в DTO
 */

//  Преобразователи типов данных
const Integer = (intVal) => parseInt(intVal, 10);
const Guid = String;
const ISODate = (date: string) => {
    const parsedDate = new Date(date);
    parsedDate.toString = Date.prototype.toISOString.call(parsedDate);
    return parsedDate;
};

export class DTO {
    constructor(object, dtoMap) {
        angular.forEach(dtoMap, (typeConstructor, propertyName) => {
            const val = object[propertyName];
            if (val === null || angular.isUndefined(val)) {
                this[propertyName] = val;
            } else {
                this[propertyName] = typeConstructor(object[propertyName]);
            }
        });
    }
}

//  Payment -> PaymentDTO
export class PaymentDTO extends DTO {
    constructor(object: Payment) {
        //  http://bitbucket.ugsk.ru/projects/UPRO/repos/general/browse/UGSK.K3.Service.DTO/Shared/FactPaymentDTO.cs?at=feature/rm-21548
        super(object, {
            Id: Integer,
            ContractId: Integer,
            Serial: String,
            Number: String,
            PaymentRegistryId: Integer,
            PaymentKindId: Integer,
            PaymentKindUniqueData: String,
            DatePay: ISODate,
            Amount: Number,
            Guid: Guid,
            PaymentStatusId: Integer
        });
    }
}
