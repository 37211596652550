import { IPromise } from "angular";
import { IModalServiceInstance } from "angular-ui-bootstrap";
import { IFilialResource } from "infrastructure/app.resource";
import { IContractOwner, IEmployeeResource, IODataParams } from "infrastructure/interfaces";
import Filial from "infrastructure/interfaces/WebApi/Filial";
import { NgTableParams as NgTableParamsType } from "ng-table";

export class ChangeOwnerController {
    public tableParams: NgTableParamsType<IContractOwner>;
    public selectedEmployee: IContractOwner = null;
    public filials: Filial[];
    static get $inject() {
        return ["$uibModalInstance", "employeeResource", "NgTableParams", "filialService"];
    }
    constructor(
        private $uibModalInstance: IModalServiceInstance,
        employeeResource: IEmployeeResource,
        NgTableParams: typeof NgTableParamsType,
        private filialService: IFilialResource,
    ) {
        "ngInject";

        this.tableParams = new NgTableParams({
            count: 7,
            page: 1,
        }, {
            filterOptions: { filterDelay: 2500 },
            getData: (params: NgTableParamsType<IContractOwner>) => {
                const odataParams: IODataParams = {
                    $skip: (params.page() - 1) * params.count(),
                    $top: params.count(),
                };

                odataParams.$filter = this.getFilterParams(params);
                odataParams.$count = true;
                odataParams.$expand = [
                    "Employee($select=Name)",
                    "Employee($select=Id)",
                    "Employee($expand=Filial($select=Name))",
                    "Employee($expand=IntermediateSeller($select=Name))",
                    "Employee($expand=PointOfSale($select=Name))",
                ].join(",");
                odataParams.$select = "Login";
                odataParams.$orderby = "Employee/Filial/Name asc";
                return employeeResource.getContractOwners<IContractOwner>(odataParams).$promise.then((data) => {
                    this.selectedEmployee = null;
                    params.total(data["@odata.count"]);
                    return data.value;
                });
            },
            paginationMaxBlocks: 5,
            paginationMinBlocks: 2,
        });

        this.initFilials();
    }
    public dismiss(): void {
        this.$uibModalInstance.dismiss();
    }
    public choose(): void {
        if (this.selectedEmployee) {
            this.$uibModalInstance.close({ selectedEmployee: this.selectedEmployee });
        } else {
            this.dismiss();
        }
    }
    public setSelectedEmployee(employee: IContractOwner): void {
        this.selectedEmployee = employee;
    }
    public isSelected(employee: IContractOwner): boolean {
        return this.selectedEmployee === employee;
    }
    public getFilterParams(params: NgTableParamsType<IContractOwner>): string {
        let filter = "";

        if (params.filter().employeeName) {
            filter += (filter.length !== 0 ? " and " : "") + "(indexof(tolower(Employee/Name), tolower('" + params.filter().employeeName + "')) gt -1)";
        }

        if (params.filter().pointOfSaleName) {
            filter += (filter.length !== 0 ? " and " : "") + "(indexof(tolower(Employee/PointOfSale/Name), tolower('" + params.filter().pointOfSaleName + "')) gt -1)";
        }

        if (params.filter().intermediateSellerName) {
            filter += (filter.length !== 0 ? " and " : "") + "(indexof(tolower(Employee/IntermediateSeller/Name), tolower('" + params.filter().intermediateSellerName + "')) gt -1)";
        }

        if (params.filter().filialId) {
            filter += (filter.length !== 0 ? " and " : "") + "Employee/Filial/Id eq " + params.filter().filialId;
        }

        return filter.length > 0 ? "(" + filter + ")" : null;
    }
    public initFilials(): IPromise<Filial[]> {
        const odataParams: IODataParams = {
            $skip: 0,
        };
        odataParams.$count = null;
        odataParams.$filter = null;
        odataParams.$orderby = "Name asc";
        odataParams.$format = "json";
        return this.filialService.query(odataParams).$promise.then((data) => {
            this.filials = data.value;
            return data.value;
        });
    }
}
