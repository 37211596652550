import angular from "angular";
import { PropertyPartsRepository } from "application/assuranceHome/assuranceHome.factory";
import { IWallMaterial } from "application/assuranceHome/interfaces/IWallMaterial";
import interiorTypesTemplate from "application/modalDialog/showInteriorType.html";
import PropertyPart from "domain/classes/propertyPart.class";
import { capitalizeFirstLetter, Helpers } from "infrastructure/app.helpers";
import { IOptionsPopover } from "infrastructure/interfaces/IOptionsPopover";
import { ISliderConfig } from "infrastructure/interfaces/ISliderConfig";
import { NgComponentController } from "infrastructure/NgController";
import template from "./ugskAssuranceHomePropertyPart.component.html";

class AssuranceHomePropertyPartComponent extends NgComponentController {
    public structuralElementTitle: string;
    public disabled: boolean;
    public propertyPart: PropertyPart;
    public repository: PropertyPartsRepository;
    public ngDisabled: boolean;
    public removePropertyPart: () => void;
    public needToInsureStructuralElement: boolean;
    public propertyIsFinished: boolean;
    public needToInsureInterior: boolean;
    public constructionYear: number;
    public interiorRepairYear: number;
    public onDependentFieldsPropertyPartsChanged: (params: {
        fieldName: string;
        propertyPart: PropertyPart;
    }) => void;
    public ngModelOptionsDeclaredAmount: ng.INgModelOptions;
    public tooltipWallMaterialOptionsPopover: IOptionsPopover;
    public declaredAmountSliderConfig: ISliderConfig;

    public onInit() {
        this.repository = this.propertyPart.getRepository();
        const popoverMessageWallMaterialConstant = this.di<string>("popoverMessageWallMaterialConstant");
        this.ngModelOptionsDeclaredAmount = {
            updateOn: "blur",
        };

        this.tooltipWallMaterialOptionsPopover = {
            popoverEvent: "'mouseenter'",
            popoverMessage: popoverMessageWallMaterialConstant,
            popoverPlaceShow: "top",
        };

        this.declaredAmountSliderConfig = {
            grid: true,
            hide_from_to: false,
            hide_min_max: true,
            prettify: false,
            step: 1000,
            type: "single",
        };

        this.propertyPart.NeedToInsureStructuralElement = this.needToInsureStructuralElement;
        this.propertyPart.NeedToInsureInterior = this.needToInsureInterior;
        this.propertyPart.ConstructionYear = this.constructionYear;
        this.propertyPart.PropertyIsFinished = this.propertyIsFinished;
        this.propertyPart.InteriorRepairYear = this.interiorRepairYear;
        this.ngModelOptions = this.di<ng.INgModelOptions>("ngModelOptions");

        this.$scope.$watch(
            () => this.propertyPart.NeedToInsureInteriorOfPropertyPart,
            (newValue) => {
                if (newValue === false) {
                    this.propertyPart.InteriorDeclaredAmount = 0;
                }
                this.changeNeedToInsureInteriorOfPropertyPart();
            },
        );
    }

    /**
     * Условие для проверки наличия обновления зависимых полей
     * @param changesObj объект содержащий информацию о том какие биндинги были изменены
     */
    public getContractFieldsToReflect(changesObj: ng.IOnChangesObject): string[] {
        return [
            "needToInsureInterior",
            "needToInsureStructuralElement",
            "interiorRepairYear",
            "propertyIsFinished",
            "constructionYear",
        ].filter((prop) => (changesObj[prop] && !changesObj[prop].isFirstChange()));
    }

    public onChanges(changesObj: ng.IOnChangesObject): void {
        const changedKeys = this.getContractFieldsToReflect(changesObj);
        changedKeys.forEach((fieldName) => {
            this.propertyPart[capitalizeFirstLetter(fieldName)] = this[fieldName];
            if (fieldName !== "propertyIsFinished") {
                this.updatePropertyPartRepository(this.propertyPart, capitalizeFirstLetter(fieldName));
            }
        });
    }

    /*
        TODO deprecated ???
        public clearStuffAmountSum(property: object, fieldName: string): void {
            property[fieldName] = null;
        }
    */

    public changeNeedToInsureInteriorOfPropertyPart(): void {
        this.onDependentFieldsPropertyPartsChanged({
            fieldName: "NeedToInsureInteriorOfPropertyPart",
            propertyPart: this.propertyPart,
        });
    }
    /**
     * Функция проверки необхадимости отображения слайдера для КЭ
     */
    public isActiveStructuralElementDeclaredAmount(): boolean {
        return (this.propertyPart.StructuralElementSquare
            && this.propertyPart.ConstructionYear
            && this.repository.StructuralElementCostRestriction.Collection[0]
            && this.needToInsureStructuralElement);
    }

    /**
     * Функция проверки необходимости отображения слайдера для ВО
     */
    public isActiveInteriorDeclaredAmount(): boolean {
        return (this.propertyPart.StructuralElementSquare
            && this.propertyPart.InteriorRepairYear
            && this.repository.InteriorCategoryCostRestriction.Collection[0]
            && this.propertyPart.NeedToInsureInteriorOfPropertyPart
            && this.needToInsureInterior);
    }

    /**
     * Функция обновления репозитория для части основного строения
     * @param propertyPart объект элемента строения
     * @param fieldName поле по которому необходимо отправить запрос
     */
    public updatePropertyPartRepository(propertyPart: PropertyPart, fieldName: string): void {
        this.onDependentFieldsPropertyPartsChanged({
            fieldName,
            propertyPart,
        });
    }

    /**
     * Функция проверяет доступность поля для ввода иного материала
     */
    public otherMaterialIsDisabled(
        contract: PropertyPart,
        collection: IWallMaterial[],
        propertyName: string,
        otherPropertyName: string,
    ): boolean {
        if (collection) {
            const item = collection.find((elem) => (elem[`${propertyName}Id`] === contract[`${propertyName}Id`]));
            if (item) {
                const value = !item[`${propertyName}IsOther`];
                if (value) {
                    contract[otherPropertyName] = null;
                }
                return value;
            }
            contract[otherPropertyName] = null;
            return true;
        }
        return true;
    }

    /**
     * Формула расчета внутренней площади строения
     */
    public areaCalculatingFormula(coefficient: number, value: number | string): number {
        return parseFloat(((100 * (coefficient * Number(value))) / 100).toFixed(2));
    }

    /**
     * @param {object} property : Элемент основного строения
     */
    public calculatePropertyPartInteriorSquare(property: PropertyPart): void {
        const InteriorSquareCoefficient = 0.847;
        if (!isNaN(parseFloat(String(property.StructuralElementSquare)))) {
            property.InteriorSquare = this.areaCalculatingFormula(
                InteriorSquareCoefficient,
                property.StructuralElementSquare,
            );
        } else {
            property.InteriorSquare = null;
        }
    }

    /**
     * Открываем модальное окно для показа информации о типах отделки
     */
    public showInteriorTypeModal(): void {
        const helpers = this.di<Helpers>("helpers");
        helpers.showInformationWindow(interiorTypesTemplate, "Типы отделки", "lg");
    }
}

export default angular.module("ugsk.assuranceHome.components.property.part", [])
.component("ugskAssuranceHomePropertyPartComponent", {
    bindings: {
        constructionYear: "<",
        disabled: "<ngDisabled",
        interiorRepairYear: "<",
        needToInsureInterior: "<",
        needToInsureStructuralElement: "<",
        ngDisabled: "<",
        onDependentFieldsPropertyPartsChanged: "&",
        propertyIsFinished: "<",
        propertyPart: "=",
        removePropertyPart: "&",
        structuralElementTitle: "<",
    },
    controller: AssuranceHomePropertyPartComponent,
    controllerAs: "vm",
    template,
}).name;
