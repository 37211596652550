import { NgControllerBase } from "infrastructure/NgController";
import { UgskSet } from "domain/classes/ugsk-set.class";

import template from "./ugskJournalTable.component.html";

export default angular.module("ugsk.components.journal-table", []).component("ugskJournalTable", {
    bindings: {
        columns: "<",
        filtersColumns: "=?",
        isCompact: "<",
        multiselect: "<",
        onDblClick: "&",
        onOrderChanged: "&",
        order: "=?",
        productCode: "<",
        records: "<",
        selection: "=",
        tableClasses: "<?",
        tableParams: "<?",
        trackBy: "<",
        onApplyFilterCalled: "&",
        onOrderChanged: "&"
    },
    controller: class extends NgControllerBase {
        $onInit() {
            [
                this.$state,
                this.statusService,
            ] = this.di([
                "$state",
                "statusService",
            ]);
            if (!this.trackBy) {
                this.trackBy = "Id";
            }
            if (!this.selection) {
                this.selection = new UgskSet();
            }
            if (!this.order) {
                this.order = {};
            }
            if (!this.filtersColumns) {
                this.filtersColumns = [];
            }

            const [
                $scope,
                ngTableEventsChannel,
            ] = this.di([
                "$rootScope",
                "ngTableEventsChannel",
            ]);

            this.reloadDataSubscription = ngTableEventsChannel.onAfterReloadData(() => {
                $scope.$broadcast("journalTable:onAfterReloadData");
            });
        }
        $onDestroy() {
            if (angular.isFunction(this.reloadDataSubscription)) {
                this.reloadDataSubscription();
            }
        }
        getSorterClassesRules(columnName) {
            return {
                "sort-asc": (this.order.by === columnName && this.order.direction === "asc"),
                "sort-desc": (this.order.by === columnName && this.order.direction === "desc"),
                sortable: columnName,
            };
        }
        setClickedRow(item) {
            if (this.multiselect) {
                if (this.selection.has(item)) {
                    this.selection.delete(item);
                } else {
                    this.selection.add(item);
                }
            } else {
                this.selection.clear();
                this.selection.add(item);
            }
        }
        isSelected(item) {
            return this.selection.has(item);
        }
        //  TODO: Убрать отсюда в body.controler.js журнала
        getContractStatusLabelClass(contract) {
            return this.statusService.getStatusLabelClass(contract.getStatus());
        }
        getFilterByColumnName(columnName) {
            return this.filtersColumns.find(fCol => fCol.name === columnName);
        }
        setOrder(columnName) {
            let direction = "desc";
            if (this.order.by === columnName) {
                if (this.order.direction === "desc") direction = "asc";
            }
            this.order = {
                by: columnName,
                direction,
            };
            this.onOrderChanged();
        }
        getColumnTitle(column) {
            return column.displayText || column.title || column.name;
        }
    },
    controllerAs: "vm",
    template,
}).name;
