import "jquery-lazyload";
import "angular-touch";
import "angular-carousel";
import "angular-ui-bootstrap/src/carousel";
import template from "./ugskBanners.component.html";
import "./ugskBanners.component.css";

/**
 * Для совместимости с AngularJS 1.7. Из коробки, angular-ui-bootstrap,
 * не совместим с последним AngularJS
 */
angular.module("ui.bootstrap.module.carousel").config(["$qProvider", function ($qProvider) {
    $qProvider.errorOnUnhandledRejections(false);
    }
]);

export default angular.module("ugsk.components.banners", [
    "angular-carousel",
    "ui.bootstrap.module.carousel",
])
.component("ugskBanners", {
    bindings: {
        bannersList: "<",
    },
    controller: class {
        $onInit() {
            this.interval = 5000;
            this.noWrapSlides = false;
            this.active = 0;
        }
        onChange(nextSlide) {
            const slide = angular.element(nextSlide.element);
            const width = angular.element(window).width();
            let searchClass = "";
            if (width > 1599) {
                searchClass = "xl";
            } else if (width > 1199) {
                searchClass = "lg";
            } else if (width > 991) {
                searchClass = "md";
            } else if (width > 767) {
                searchClass = "sm";
            } else {
                searchClass = "xs";
            }
            let img = slide.find(`img.visible-${searchClass}`).first();
            if (!img.length) {
                img = slide.find("img").first();
            }
            img.trigger("appear");
        }
    },
    controllerAs: "vm",
    template,
}).name;
