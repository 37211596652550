import angular from "angular";
import { AddressDTO } from "infrastructure/interfaces/WebApi/AddressDTO";
import { NgComponentController } from "infrastructure/NgController";
import { EnvService } from "infrastructure/services/env.service";
import { ValidationService } from "infrastructure/services/validation.service";
import { Guid } from "infrastructure/types";
import template from "./ugskAddressPicker.component.html";

class AddressPicker extends NgComponentController {
    public address: string;
    public disabled: boolean;
    public dontSearchInFias: boolean;
    public fiasId: Guid;
    public minlength: number;
    public maxlength: number;
    public kladrId: string;
    public onChange: () => void;
    public addressLoading: boolean;
    public maxlevel: number;

    /**
     * во время удаления фрагмента адреса из контрола функция осуществит присвоение значения null
     */
    public changeAddress(): void {
        this.fiasId = null;
        this.kladrId = null;
    }

    /**
     * функция выполняет отражение различных состояний поиска при помощи css классов подставляемых в шаблон в директивы
     */
    public getStateClasses(): string[] {
        if (this.address && this.dontSearchInFias) {
            return ["fa", "fa-check"];
        }
        if (!this.address && this.dontSearchInFias) {
            return ["fa", "fa-times"];
        }
        if (this.addressLoading) {
            return ["fa", "fa-spinner", "fa-spin"];
        }
        if ((this.address && this.fiasId && !this.addressLoading)) {
            return ["fa", "fa-check"];
        }
        if (this.fiasId === null && this.address) {
            return ["fa", "fa-times"];
        }
        return ["fa", "fa-search"];
    }

    /**
     * получение css классов success и error если параметры this.address, this.
     * fiasId, this.addressLoading удовлетворяют условиям
     */
    public getState(): string {
        if (this.dontSearchInFias && this.address) {
            return "success";
        }
        if (this.dontSearchInFias && !this.address) {
            return "error";
        }

        if (this.address && this.fiasId && !this.addressLoading) {
            return "success";
        }
        if (this.fiasId === null && this.address) {
            return "error";
        }

        return "";
    }
    /**
     * функция задействует внешний сервис $http для выполнения get запроса к справочнику ФИАС
     * @param {stirng} val параметр введеный пользователем в контрол адреса для поиска
     */
    public loadSourсe(val: string, opts = {}): angular.IHttpPromise<AddressDTO[]> {
        const $http = this.di<angular.IHttpService>("$http");
        const envService = this.di<EnvService>("envService");
        const apiUrl = envService.read("apiUrl");
        return $http.get(`${apiUrl}Search/Address`, {
            params: {
                query: val,
                ...opts,
            },
        });
    }

    /**
     * функция принимает значения введеные пользователем в контролл формы,
     * осуществляет вызов функции loadSourse инициализирующей запрос
     * данных и поиск данных на основе введеных пользоветелем значений
     * в контрол
     */
    public getAddressSource(val: string): angular.IPromise<AddressDTO[]> | null {
        const $filter = this.di<angular.IFilterService>("$filter");
        const validationService = this.di<ValidationService>("validationService");
        if (this.$attrs.ugskValidation) {
            validationService.removeError(this.$attrs.ugskValidation);
        }

        if (!this.dontSearchInFias) {
            return this.loadSourсe(val, { maxlevel: this.maxlevel }).then((response) => $filter("limitTo")(response.data, 50));
        }
        if (typeof (this.onChange) === "function") {
            this.onChange();
        }
        return null;
    }

    /**
     * Функция инициализирующаяся данными  fiasId, kladrId, address вбранного из предложенного списка адресов.
     * Сообщение валидации удаляется.
     *
     * @param {object} $item объект содержащий адрес и id адреса
     * ФИАС и id КЛАДР спровчника, соответствующего выбранному адресу из списка
     */
    public selectAddress($item: AddressDTO): void {
        this.address = $item.Address;
        this.fiasId = $item.Aoguid;
        this.kladrId = $item.CodeKLADR;

        if (typeof (this.onChange) === "function") {
            this.onChange();
        }
    }
}

export default angular.module("ugsk.components.address-picker", []).component("ugskAddressPicker", {
    bindings: {
        address: "=ngModel",
        disabled: "<ngDisabled",
        dontSearchInFias: "=",
        fiasId: "=",
        kladrId: "=",
        maxlength: "<",
        minlength: "<",
        onChange: "&",
        prefix: "<",
        maxlevel: "<",
    },
    controller: AddressPicker,
    controllerAs: "vm",
    template,
}).name;
