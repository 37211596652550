import angular from "angular";
import ugskDriverComponent from "application/components/ugsk-driver/ugskDriver.component";
import { IDatePickerOptions } from "infrastructure/interfaces/IDatePickerOptions";
import { NgComponentController } from "infrastructure/NgController";
import moment from "moment";
import template from "./ugskOsagoDriver.component.html";

class UgskOsagoDriver extends NgComponentController {
    public datepickerConfig: Partial<IDatePickerOptions>;
    public minBirthday: string;
    public onInit(): void {
        this.minBirthday = moment().subtract(120, "years").format("YYYY-MM-DD");
        this.datepickerConfig = {
            endDate: moment().format("YYYY-MM-DD"),
            outFormat: "YYYY-MM-DDT00:00:00",
            startDate: moment().subtract(100, "year").format("YYYY-MM-DD"),
        };
    }
}

export default angular.module("app.osago.components.driver", [
    ugskDriverComponent,
]).component("ugskOsagoDriver", {
    bindings: {
        isEditable: "<",
        ngDisabled: "<",
        ngModel: "<",
        ownerIsLegal: "<",
    },
    controller: UgskOsagoDriver,
    controllerAs: "vm",
    template,
}).name;
