import { Transition } from "@uirouter/core";
import angular, { IQService } from "angular";
import * as moment from "moment";
import { BaseToolbarController } from "application/baseProduct.toolbar.controller";
import { PAYMENT_KINDS, SALE_CHANNELS } from "application/constants.ts";
import IBaseViewParams from "infrastructure/interfaces/IBaseViewParams";
import IScope from "infrastructure/interfaces/IScope";
import { Employee } from "infrastructure/services/employee.service";
import { ExcelWriterService } from "infrastructure/services/excelWriter.service";
import { NotifyService } from "infrastructure/services/notifyService";
import { ValidationService } from "infrastructure/services/validation.service";
import { BoxVHIContract, BoxVHIRepository } from "./boxVHI.factory";

export class BoxVHIToolbarController extends BaseToolbarController {
    public Contract: BoxVHIContract;
    public $scope: IScope;
    public policyWithoutA7IsAvailable: boolean;
    public policyWithA7IsAvailable: boolean;
    public Repository: BoxVHIRepository;

    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
        {
            viewType = "form",
        }: IBaseViewParams,
        $scope,
    ) {
        super($injector, $transition, {
            crossOptions: ["boxVHI"],
            extraOptions: ["manual", "integrationInfo", "annul", {
                icon: "fa-download",
                title: "Сохранить список застрахованных",
                disabled: (context: BoxVHIToolbarController) => context.getCurrentSubProduct() !== "AntiMiteVHI",
                action: (context: BoxVHIToolbarController) => {
                    const writerService = context.di<ExcelWriterService>("excelWriterService");
                    const data = [
                        ["Фамилия", "Имя", "Отчество", "Дата рождения"],
                    ];

                    context.Contract.InsuredPersons.forEach(person => {
                        const birthDay = moment(person.InsuredPersonBirthday);
                        data.push([person.InsuredPersonLastName, person.InsuredPersonFirstName, person.InsuredPersonMiddleName, birthDay.isValid() ? birthDay.format("DD.MM.YYYY") : ""]);
                    })

                    writerService.write(data, "Список застрахованных").then(() => {
                        context.notifyService.successMessage(
                            "Список застрахованных",
                            "Список застрахованных успешно подготовлен",
                        );
                    }, () => {
                        context.notifyService.errorMessage(
                            "Список застрахованных",
                            "Ошибка формирования списка застрахованных",
                        );
                    });
                },
                view: "form",
            }],
            viewType,
        }, $scope);

        this.$scope = $scope;
        this.init();
    }

    public init() {
        const vm = this;
        this.policyWithoutA7IsAvailable = this.employee.hasPermission("GetFreeFormReceipt", this.product.code);
        this.policyWithA7IsAvailable = !this.employee.hasPermission("FrontGetPolicyWithoutA7", this.product.code);

        this.Repository = this.Contract.getRepository() as BoxVHIRepository;
        this.$scope.$watch(() => {
            return vm.Repository.AllowablePrintFormForProduct;
        }, () => {
            vm.printOptions = [];
            const policyPrintFormForProduct =  vm.Repository.AllowablePrintFormForProduct.Collection.filter((printForm) => {
                return ["PolicyAntiMite", "PolicyStudent", "PolicyWithoutA7AntiMite", "PolicyWithoutA7Student"].includes(printForm.PrintFormCode);
            });
            angular.forEach(policyPrintFormForProduct, (printForm) => {
                const textTitle: string = ((printForm.PrintFormCode === "PolicyAntiMite" || printForm.PrintFormCode === "PolicyStudent")) ? `${printForm.PrintFormName} с А7` : printForm.PrintFormName;
                vm.printOptions.push({
                    code: printForm.PrintFormCode,
                    icon: "fa-print",
                    title: textTitle,
                    handler() {
                        if (printForm.State === 0 && !vm.isDisabledPrint() && vm.printPolicyOnPermission(printForm.PrintFormCode)) {
                            return vm.openPdf({
                                id: vm.Contract.Id,
                                docType: printForm.PrintFormCode,
                                fileNamePrefix: "Заявление",
                            }).then(
                                () => {},
                                () => {
                                    if (printForm.PrintFormCode !== "PolicyWithoutA7AntiMite") {
                                        vm.showNoticeNeedPrintReceiptForm();
                                    }
                                },
                            );
                        }
                    },
                    isDisabled() {
                        return printForm.State === 1 || vm.isDisabledPrint() || !vm.printPolicyOnPermission(printForm.PrintFormCode);
                    },
                });
            });
            const additionalPrintFormForProduct = vm.Repository.AllowablePrintFormForProduct.Collection.filter((printForm) => {
                return !["PolicyAntiMite", "PolicyStudent", "PolicyWithoutA7AntiMite", "PolicyWithoutA7Student"].includes(printForm.PrintFormCode);
            });
            angular.forEach(additionalPrintFormForProduct, function(printForm) {
                const textTitle = printForm.PrintFormName;
                vm.printOptions.push({
                    icon: "fa-print",
                    title: textTitle,
                    handler() {
                        if (printForm.State === 0 && !vm.isDisabledPrint()) {
                            return vm.openPdf({
                                id: vm.Contract.Id,
                                docType: printForm.PrintFormCode,
                                fileNamePrefix: "Заявление",
                            }).then(() => {
                                if (printForm.PrintFormCode == "PolicyMigrantTypographicForm" || printForm.PrintFormCode == "PolicyMigrant") {
                                    vm.showMigrantNoticeNeedPrintReceiptForm();
                                }
                            });
                        }
                    },
                    isDisabled() {
                        return printForm.State === 1 || vm.isDisabledPrint();
                    },
                });
            });
            vm.printOptions.push({
                icon: "fa-print",
                title: "Счет на оплату (полный взнос)",
                handler() {
                    if (!vm.isDisabledPrint() && vm.isEnabledPrintInvoice()) {
                        return vm.openPdf({
                            id: vm.Contract.Id,
                            docType: "PaymentAccount",
                            fileNamePrefix: "Счет на оплату",
                        });
                    }
                },
                isDisabled() {
                    return vm.isDisabledPrint() || !vm.isEnabledPrintInvoice();
                },
            });
            vm.printOptions.push({
                code: "freeFormReceipt",
                icon: "fa-print",
                title: "Печать квитанции",
                handler: async function() {
                    if (vm.isDisabledPrintFreeFormReceipt()) {
                        const employee: Employee = vm.resolve("employee");
                        if (employee.info.SaleChannel === SALE_CHANNELS.PARTNER) {
                            const notifyService: NotifyService = vm.di("notifyService");
                            await notifyService.warningMessage("Внимание", "Внимание! В соответствии с законодательством РФ агенты-юридические лица и агенты-индивидуальные предприниматели при расчетах со страхователями должны применять свою контрольно-кассовую технику.", {
                                provider: "popup",
                            });
                        }
                        const apiUrl = vm.envService.read("apiUrl");
                        return vm.openPdf({
                            id: vm.Contract.Id,
                            url: (id) => `${apiUrl}FreeFormReceipt/getfilledform?id=${id}`,
                            docType: "FreeFormReceipt",
                            fileNamePrefix: "Печать квитанции предзаполненная",
                            headers: {
                                "X-ProductTitle": "boxVHI",
                            },
                        }).then((result) => result, angular.noop);
                    }
                },
                isDisabled() {
                    return !vm.isDisabledPrintFreeFormReceipt();
                }
            });
            vm.printOptions.forEach((printForm) => {
                printForm.isVisible = () => true;
                if (printForm.hasOwnProperty("code")) {
                    if (["PolicyAntiMite", "PolicyStudent"].includes(printForm.code)) {
                        printForm.isVisible = () => vm.policyWithA7IsAvailable;
                    } else if (printForm.code === "freeFormReceipt") {
                        printForm.isVisible = () => true;
                    }
                }
            });
        });
    }

    public isDisabledPrintFreeFormReceipt() {
        const employee = this.resolve<Employee>("employee");
        if (!(this.Contract && employee && employee.info)) {
            return true;
        }
        const canGetFreeFormReceipt = employee.hasPermission("GetFreeFormReceipt", this.product.code);
        const contractIsAllowable = !(this.Contract.isAnnuled() || this.Contract.isTerminated() || this.Contract.isFrozen());
        const paymentKindsAllowable = this.Contract.getFirstFactPaymentKindId() === PAYMENT_KINDS.CASH;
        const insurantANaturalPerson = this.Contract.isInsurantANaturalPerson();
        return !this.Contract.isBlank() && contractIsAllowable && paymentKindsAllowable && insurantANaturalPerson && canGetFreeFormReceipt;
    }

    public getCurrentSubProduct() {
        const vm = this;
        const item = vm.Repository.InsuranceProduct.Collection.find((product) => {
            return vm.Contract.InsuranceProductId === product.InsuranceProductId;
        });

        if (!item) { return; }

        return item.InsuranceProduct;
    }

    /**
     * @description Кастомная функция сохранения договора. Дополняет основной функционал обновлением
     * FR - это необходимо для получения параметров печати
     * @return {Promise}
     */
    public saveContract() {
        return super.saveContract().then(() => {
            this.Repository.update("InsuredPersonsCount", this.Contract);
        });
    }

    /**
     * @description Кастомная функция заключения договора. Кроме основного функционала - обновления FR
     * для получени параметров печати
     * @return {Promise}
     * @override
     */
    public signContractWithPhoneCheck() {
        const $q = this.di<IQService>("$q");
        const employee = this.resolve<Employee>("employee");
        const validationService = this.di<ValidationService>("validationService");
        const notifyService = this.di<NotifyService>("notifyService");

        if (this.getCurrentSubProduct() === "ImmigrantVHI" && [SALE_CHANNELS.OFFICE, SALE_CHANNELS.AGENT].includes(employee.info.SaleChannel)) {
            const insurantEmail = this.Contract.InsuredEmail;
            const insurantPhones = this.Contract.InsuredPhones;
            const insurantPhone = angular.isArray(insurantPhones) && angular.isObject(insurantPhones[0]) && this.Contract.InsuredPhones[0].Number;

            if (!insurantEmail && !insurantPhone) {
                validationService.addError("InsuredEmail", ["Не указан email или телефон"]);
                validationService.addError("InsuredPhones", ["Не указан email или телефон"]);

                notifyService.errorMessage("Одно из полей email или телефон страхователя должно быть заполнено");
                return $q.reject();
            }
        }

        const notShowToBeVerifiedText = (this.getCurrentSubProduct() === "ImmigrantVHI");
        return super.signContractWithPhoneCheck({ notShowToBeVerifiedText }).then(() => {
            this.Repository.update("InsuredPersonsCount", this.Contract);
        });
    }
    static get $inject() {
        return ["$injector", "$transition$", "EmptyObject", "$scope"];
    }
    public showMigrantNoticeNeedPrintReceiptForm() {
        const getFreeFormReceiptAvailable = this.employee.hasPermission("GetFreeFormReceipt", this.product.code);
        const validChannel = [SALE_CHANNELS.AGENT, SALE_CHANNELS.OFFICE].includes(this.employee.info.SaleChannel);
        const insurantANaturalPerson = this.Contract.isInsurantANaturalPerson();
        const validPaymentKind = this.Contract.getFirstFactPaymentKindId() === PAYMENT_KINDS.CASH; // вид оплаты = только Наличные;
        if (getFreeFormReceiptAvailable && validChannel && insurantANaturalPerson && validPaymentKind) {
            this.notifyService.message(undefined, "Не забудьте распечатать Печатную форму Квитанции", {
                provider: "popup",
                type: "warning",
            });
        }
    }
    public printPolicyOnPermission(code: string) {
        if (["PolicyAntiMite", "PolicyStudent"].includes(code)) {
            return this.policyWithA7IsAvailable;
        }
        if (["PolicyWithoutA7AntiMite", "PolicyWithoutA7Student"].includes(code)) {
            return this.policyWithoutA7IsAvailable;
        }
    }
}
