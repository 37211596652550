import angular, { ITimeoutService } from "angular";

import { NgComponentController } from "infrastructure/NgController";
import template from "./ugskSellerCurator.component.html";
import "./ugskSellerCurator.component.scss";
import { IODataParams } from "infrastructure/interfaces";
import IScope from "infrastructure/interfaces/IScope";
import { NotifyService } from "infrastructure/services/notifyService";
import { curatorServiceFactory } from "./ugskSellerCurator.service";
import { ICuratorsResponseItem } from "./ICuratorsResponseItem";
import { ICuratorService } from "application/components/ugsk-seller-curator/ICuratorService";

class UgskSellerCurator extends NgComponentController {
    public sellerName: string = "";
    public isCuratorSelected = false;
    public curatorId: number;
    public searchInProgress = false;
    public selectedCuratorName: string = "";
    public ngDisabled = false;
    private employeeId: number;
    private notifyService = this.di<NotifyService>("notifyService");

    static get $inject() {
        return ["$injector", "$scope", "$element", "$attrs", "curatorService"];
    }

    constructor(
        $injector: angular.auto.IInjectorService,
        $scope: IScope,
        $element: JQLite,
        $attrs: angular.IAttributes,
        private readonly curatorService: ICuratorService,
    ) {
        super($injector, $scope, $element, $attrs);
    }

    public $onInit(): void {
        super.$onInit();

        if (this.curatorId) {
            this.isCuratorSelected = true;

            this.searchInProgress = true;
            this.curatorService.query({
                $filter: `Id eq ${this.curatorId}`,
            }).$promise.then((result) => {
                if (result && result.value) {
                    this.selectedCuratorName = result.value[0].Name;
                    const $timeout = this.di<ITimeoutService>("$timeout");
                    $timeout(() => {
                        if (this.$scope.sellerCuratorForm.modified) {
                            this.$scope.sellerCuratorForm.$setPristine();
                        }
                    });
                } else {
                    this.curatorId = null;
                }
            }).catch(() => {
                this.notifyService.errorMessage("Ошибка", "Произошла ошибка при получении данных куратора");
                this.selectedCuratorName = "";
                this.curatorId = null;
                this.isCuratorSelected = false;
            }).finally(() => {
                this.searchInProgress = false;
            });
        }
    }

    public getEmployees(val: string): angular.IPromise<ICuratorsResponseItem[]> {
        const $expand = "Filial";
        const odataParams: IODataParams = {
            $count: true,
            $expand,
            $filter: `indexof(tolower(Name), '${val.replace(/'|"/g, "")}') gt -1 ` +
                `and Id ne ${this.employeeId}`,
            $format: "json",
            $orderby: "Name asc",
            $top: 10,
        };
        return this.curatorService.query(odataParams).$promise.then((data: any) => {
            return data.value;
        }).catch(() => {
            this.notifyService.errorMessage("Ошибка!", "Произошла ошибка при поиске куратора");
        });
    }

    public onCuratorSelect(selectedCurator: ICuratorsResponseItem): void {
        this.isCuratorSelected = true;
        this.curatorId = selectedCurator.Id;
        this.selectedCuratorName = selectedCurator.Name;
    }

    public getSelectionAddonClass() {
        return {
            "fa fa-check": this.isCuratorSelected,
            "fa fa-spinner fa-spin": this.searchInProgress,
            "fa fa-times": !this.isCuratorSelected,
        };
    }

    public clearSelectedCurator(): void {
        this.isCuratorSelected = false;
        this.curatorId = null;
        this.selectedCuratorName = "";
    }
}

export default angular
    .module("ugsk.components.seller-curator", [])
    .component("ugskSellerCurator", {
        bindings: {
            curatorId: "=",
            employeeId: "<",
            ngDisabled: "<",
            sellerName: "<",
        },
        controller: UgskSellerCurator,
        controllerAs: "vm",
        template,
    })
    .service("curatorService", curatorServiceFactory)
    .name;
