import template from "./ugskTopNavigation.component.html";
import "./ugskTopNavigation.component.css";

export default angular.module("ugsk-navigation", []).component("ugskTopNavigation", {
    bindings: {
        items: "<",
        withScrollBar: "<?"
    },
    template,
    controller: class {
        constructor($scope) {
            this.$scope = $scope;
        }
        $onInit() {
            this.selectorsCache = new Map();
            angular.element(window).bind("scroll", () => {
                this.$scope.$digest();
            });
        }
        getTargetPosition(target) {
            const targetNode = this.getElement(target);
            if (targetNode && targetNode.offset) {
                const offset = targetNode.offset();
                return offset ? Math.floor(offset.top) : -1;
            }
            return -1;
        }
        getElement(target) {
            let targetNode = this.selectorsCache.get(target);
            if (!targetNode) {
                targetNode = angular.element([
                    `[name='${target}']`,
                    `${target}`
                ].join(", "));
                if (targetNode.length) this.selectorsCache.set(target, targetNode);
            }
            return targetNode;
        }
        isElementActive(target) {
            const targetNode = this.getElement(target);
            const targetPosition = this.getTargetPosition(target);
            const currentScrollPosition = angular.element(window).scrollTop();
            const windowHeight = angular.element(window).height();
            const targetHeigth = targetNode.height();
            const appearPoint = currentScrollPosition + 0.66 * windowHeight;
            const targetIsUnderScreen = targetPosition > appearPoint;
            const targetIsOverScreen = targetPosition + targetHeigth < appearPoint;
            return !targetIsOverScreen && !targetIsUnderScreen;
        }

        //  scroll bar
        getNavigationBarStyle() {
            const header = this.getElement("header");
            const headerHeight = Math.floor(header.height());
            const footer1 = this.getElement("footer#bottomToolbar");
            const footerHeight = footer1.outerHeight(true);
            return {
                top: `${headerHeight}px`,
                bottom: `${footerHeight}px`
            };
        }
        getIndicationStyle() {
            const totalDocumentHeight = angular.element(document).height();
            const windowHeight = angular.element(window).height();
            const currentScrollPosition = angular.element(window).scrollTop();
            return {
                top: `${100 * currentScrollPosition / (totalDocumentHeight - windowHeight)}%`
            };
        }
        isElement(target) {
            return this.getElement(target).length > 0;
        }
    },
    controllerAs: "vm"
}).name;
