import angular, { INgModelOptions } from "angular";
import moment from "moment";

import { UAutoContract } from "application/uAuto/uAuto.factory";
import { UAutoRepository } from "application/uAuto/uAuto.repository";
import { IFactPaymentDTO } from "infrastructure/interfaces/WebApi/IFactPaymentDTO";
import { NgComponentController } from "infrastructure/NgController";
import template from "./ugskUautoPaymentParams.component.html";
import { DictionariesService } from "infrastructure/services/dictionaries.service";

enum PaymentVariants {
    Bill = 1,
    Other = 99,
}

class PaymentParamsController extends NgComponentController {
    public repository: UAutoRepository;
    public formLocked: boolean;
    public contract: UAutoContract;
    public ngModelOptions: INgModelOptions;
    private dictionariesService: DictionariesService;
    private autoGeneratedPolicySerials: string[] = [];
    private defaultPaymentVariant = "Иное";

    public onInit() {
        this.ngModelOptions = this.di("ngModelOptions");

        this.dictionariesService = this.di("dictionariesService");
        this.dictionariesService.get("PolicySerials").then((serials) => {
            if (serials && Array.isArray(serials)) {
                this.autoGeneratedPolicySerials = serials.filter((serial) => serial.IsAutoGenerated && serial.ProductId === 1).map((serial) => serial.Value); // uAuto productId = 1
            }
        });

        this.setInitialPaymentVariant();
    }

    private setInitialPaymentVariant() {
        if (this.defaultPaymentVariant && !this.contract.PaymentVariantId) {
            const variant = this.repository.PaymentVariant.Collection.find((item) => item.PaymentVariantName.trim().toLowerCase() === this.defaultPaymentVariant.trim().toLowerCase());
            if (variant) {
                this.contract.PaymentVariantId = variant.PaymentVariantId;
            }
        }
    }

    public thousands(amount: string): string {
        const filter: angular.IFilterService = this.di("thousandFilter");
        amount = filter(amount);
        if (!amount.split(".")[1]) {
            amount = `${amount}.00`;
        }
        return amount;
    }

    public getContractPaymentMinDate(index: number, previousPayment: IFactPaymentDTO): string {
        if (this.formLocked) {
            return;
        }
        if (index === 0) {
            return;
        }
        return moment(previousPayment.DatePay).add(1, "d").format("YYYY-MM-DD");
    }

    public getContractPaymentMaxDate(maxDate = 4): string {
        return moment(this.contract.SigningDate).add(maxDate, "M").format("YYYY-MM-DD");
    }

    public isInternetAcquiringAvailable(): boolean {
        return this.contract.isSigned()
            && !this.contract.IsPayed;
    }

    public isEPolicy() {
        return this.autoGeneratedPolicySerials.includes(this.contract.PolicySerial);
    }

    public isPublicPaymentAvailable(): boolean {
        if (this.contract.PaymentVariantId === PaymentVariants.Bill) {
            return false;
        }

        return !this.contract.IsPayed && this.isEPolicy();
    }
}

export default angular.module("ugsk-uauto-payment-params", []).component("ugskUautoPaymentParams", {
    bindings: {
        contract: "<",
        formLocked: "<",
        repository: "<",
    },
    controller: PaymentParamsController,
    controllerAs: "vm",
    template,
}).name;
