import { OsagoContract } from "../classes";
import { OsagoContractorProxy } from "./OsagoContractorProxy";

export class OsagoOwnerProxy extends OsagoContractorProxy {
    public contractorType: typeof OsagoContract.prototype.OwnerContractorType;
    static get proxyRules() {
        return {
            guid: "OwnerGuid",
            isNotResident: "OwnerIsNotResident",
            contractorType: "OwnerContractorType",
            documentType: "OwnerDocumentType",
            docSerial: "OwnerPersonDocSerial",
            docNumber: "OwnerPersonDocNumber",
            docWhomGiven: "OwnerPersonDocWhomGiven",
            docDateGiven: "OwnerPersonDocDateGiven",
            lastName: "OwnerPersonLastName",
            firstName: "OwnerPersonFirstName",
            middleName: "OwnerPersonMiddleName",
            birthday: "OwnerPersonBirthday",
            snils: "OwnerPersonSnils",
            orgName: "OwnerOrgName",
            orgInn: "OwnerOrgINN",
            orgKpp: "OwnerOrgKPP",
            Inn: "OwnerPersonINN",
            commonRealAddress: "OwnerCommonRealAddress",
            fiasId: "OwnerCommonRealAddressFiasId",
            kladrId: "OwnerCommonRealAddressKladrId",
            phones: "OwnerPhones",
            country: "OwnerCountry",
            accountingDate: "OwnerAccountingDate",
        };
    }
}
