import { Helpers } from "infrastructure/app.helpers";
import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";

export default {
    title: "Пролонгировать в 3.0",
    action(context) {
        window.parent.postMessage({
            type: "PROLONGATE",
            value: { product: context.product.code, contractGuid: context.Contract.Guid, contractId: context.Contract.Id },
        }, "*");
    },
    disabled(context) {
        const helpers = context.di<Helpers>("helpers");
        if (!helpers.isEmbedMode()) {
            return true;
        }
        return !(context.Contract && context.Contract.Id);
    },
    icon: "fa-thumbs-up",
} as IExtraOptionPrototype;
