export enum SALE_CHANNELS {
    AGENT = "Агентский",
    OFFICE = "Офисно-прямой",
    PARTNER = "Партнерский",
}

export enum PAYMENT_KINDS {
    BANK_CARD = 2,
    CASH = 7,
    CASH_ON_RECEIPT_A7 = 1,
    INTERNET_ACQUIRING = 6,
    OFFSET_AWARD = 4,
    PAYMENT_ORDER = 3,
    INVOICE = 5,
}

export enum PAYMENT_STATUSES {
    REJECTED = 1,
    UNPAID = 2,
    PAID = 3,
    NEW = 4,
}

export enum CONTRACT_STATUSES {
    DRAFT = 1,
    SIGNED = 2,
    APPROVED = 3,
    RETURNED_FOR_REVISION = 4,
    ANNULLED = 5,
    FROZEN = 6,
    SENT_TO_RSA = 7,
    ACCEPTED_BY_RSA = 8,
    SENT_TO_ACCOUNT = 9,
    ACCOUNTED = 10,
    PREPARED_FOR_SIGNING = 11,
    TERMINATED = 12,
    SOLD_IN_EXTERNAL_SERVICE = 13,
    CANCELED = 14,
}

export enum USER_ROLES {
    AGENT_BROKER = "Агент-Брокер",
    UNDERWRITER = "Андеррайтер",
    CURATOR = "Куратор",
    CURATOR_GO = "Куратор ГО",
    CURATOR_REGION = "Куратор региона",
    CURATOR_FILIAL = "Куратор филиала",
    CURATOR_INTEGRATION = "Оператор интеграции",
    PARTNER = "Партнер",
    DIAO = "Сотрудник ДИАО",
}

export enum CHECKED_RESULT_MESSAGES {
    Forbidden =	"Продажа договора запрещена",
    Recommendation = "Договор готов к заключению. Обратите внимание на текст рекомендаций",
    Warning = "Для продажи договора необходимо изменить условия в соответствии с оповещением",
    Information = "Договор готов к заключению. Обратите внимание на текст оповещения",
    Notification = "Договор готов к заключению. Обратите внимание на текст уведомления",
    Attention = "Ошибка проверки. Продажа договора возможна только оперативным андеррайтером",
    ForbiddenAsWarning = "Продажа договора запрещена",
    None = "Договор готов к заключению",
    Demand = "Для продажи договора необходимо изменить условия в соответствии с оповещением",
}

export enum VEHICLE_REGISTRATIONS {
    RF = "зарегистрированные в РФ",
    KZ = "зарегистрированные в Казахстане, временно используемые в РФ",
    FOREIGN = "зарегистрированные не в РФ и не в Казахстане, временно используемые в РФ",
    BOUGHT_IN_RF = "приобретенные в РФ, следующие к месту регистрации",
}

interface IEOsagoStageParams {
    title: string;
    isCritical?: boolean;
}

export const EOSAGO_STAGES_INFO = new Map<number, IEOsagoStageParams>([
    [18, { title: "Рассчитан в УЦС", isCritical: false }],
    [24, { title: "Верифицирован в РСА", isCritical: false }],
    [15, { title: "Обработан в УЦС", isCritical: false }],
    [21, { title: "Проверен РСА", isCritical: false }],
    [22, { title: "Ошибка РСА", isCritical: true }],
    [23, { title: "Документы проверены в УЦС", isCritical: false }],
    [16, { title: "Согласован в УЦС", isCritical: false }],
    [17, { title: "Ошибка УЦС", isCritical: true }],
    [26, { title: "Отправлен в РСА", isCritical: false }],
    [28, { title: "Переотправлен в РСА", isCritical: false }],
    [27, { title: "Отправлен в РСА запрос", isCritical: false }],
    [29, { title: "Отправлен в РСА ответ", isCritical: false }],
    [19, { title: "Оформлен в РСА", isCritical: false }],
    [20, { title: "Аннулирован", isCritical: false }],
    [38, { title: "Дубль договора Е-ОСАГО", isCritical: false }],
    [32, { title: "Проверка по ТС не пройдена", isCritical: false }],
    [33, { title: "Ожидание ссылки", isCritical: false }],
    [34, { title: "Подготовлена ссылка", isCritical: false }],
    [35, { title: "Ошибка получения ссылки", isCritical: false }],
]);

export enum DOC_TYPES {
    BIRTH_CERTIFICATE = 1,
}

// TODO ХХХ Это русские три буквы Ха
export const EOSAGO_POLICY_SERIAL = "ХХХ";
// Серия полиса ОСАГО, который оформляется через проект
export const OSAGO_DRAFT_SERIAL = "ТТТ";
// для бумажных договоров (кириллица)
export const OSGAO_PAPER_SERIALS = ["РРР", "МММ", "ККК", "ННН"];
// наименование програамм КАСКО "РУД"
export const UAUTO_PROGRAM_DEALER_REPAIR = "«Ремонт у дилера»";
export const UAUTO_PROGRAM_TOTAL_THEFT_PLUS = "«Тотал Угон Плюс для дилера»";

export const OSAGO_WORK_STAGES = new Map<number, string>([
    [1000, "В процессе постановки договора на расчет"],
    [2000, "Договор поставлен в очередь расчета"],
    [2500, "Договор рассчитывается"],
    [2750, "Проверка номера телефона"],
    [3000, "Запрос КБМ"],
    [4000, "Дополнительные проверки"],
    [5000, "Проверка корректности указанных данных"],
    [6000, "Расчет договора"],
    [6500, "Отправка черновика в РСА"],
    [6750, "Оформление в РСА"],
    [6875, "Отправка оформленного договора в РСА"],
    [7000, "Оформление договора, ДС и ТК"],
    [8000, "Смена статуса при аннулировании/отмены договора/ДС"],
    [500000, "Расчет завершен"],
]);

export enum OSAGO_DRIVER {
    DRIVER_LICENSE_RF = 20, // Тип ВУ: Водительское удостоверение РФ
    COUNTRY_RF = 643, // Страна выдачи ВУ: Россия
}

export enum USAGE_PURPOSES_ENUM {
    TAXI = "Такси",
}

export const RELIABLE_DRIVE_PRODUCT_NAME = 'КАСКО "Надежная поездка"';
