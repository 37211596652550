import { Transition } from "@uirouter/angularjs";
import angular from "angular";
import { RulesExecutor } from "infrastructure/RulesExecutor";
import IBlockUI from "./interfaces/IBlockUI";
import { ILogic } from "./interfaces/ILogic";
import IScope from "./interfaces/IScope";

/**
 * Базовый класс angular-контроллера
 */
class NgControllerBase implements angular.IController {
    constructor(private $injector: angular.auto.IInjectorService) {}
    /**
     * Подключение зависимостей через инжектор зависимостей
     * @param  {Array} dependencies Массив с именами инжектов
     * @return {Array}              Массив с инжектами
     */
    public di(dep: string[]): any[];
    public di<T>(dep: string): T;
    public di<T>(deps: string | string[]): T | any[] {
        if (angular.isArray(deps)) {
            return deps.map((dep) => this.$injector.get(dep));
        } else {
            return this.$injector.get(deps) as T;
        }
    }

    static get $inject() {
        return ["$injector"];
    }
}

class NgComponentController extends NgControllerBase {
    protected get logic(): ILogic {
        return null;
    }

    static get $inject() {
        return ["$injector", "$scope", "$element", "$attrs"];
    }
    protected blockUIInstance: IBlockUI;
    protected ngModelOptions: angular.INgModelOptions;
    protected inProgressText: string = "Загрузка";
    private watchers: Array<() => void> = [];
    constructor($injector: angular.auto.IInjectorService, protected $scope: IScope, protected $element: JQLite, protected $attrs: angular.IAttributes) {
        super($injector);
    }
    // @donotoverride
    public $onDestroy(): void {
        this.watchers.forEach((watcherStopper) => watcherStopper());
        this.onDestroy();
    }
    // @donotoverride
    public $onChanges(onChanges: angular.IOnChangesObject): void {
        this.onChanges(onChanges);
    }
    // @donotoverride
    public $doCheck(): void {
        if (this.doCheck) {
            this.doCheck();
        }
    }
    // @donotoverride
    public $onInit(): void {
        [this.ngModelOptions] = this.di(["ngModelOptions"]);

        const diResult = this.di(["blockUI", "$timeout", "$q"]);
        const blockUIService = <IBlockUI>diResult[0];
        const $timeout = <angular.ITimeoutService>diResult[1];
        const $q = <angular.IQService>diResult[2];

        this.blockUIInstance = blockUIService;

        const waitComponentIsReady = (): angular.IPromise<void> => {
            const blockUIWrapper = this.$element.find("[block-ui]");
            if (blockUIWrapper.length) {
                const blockUITag = blockUIWrapper.attr("block-ui");
                this.blockUIInstance = blockUIService.instances.get(blockUITag);
            }
            const onInitResult = this.onInit();
            if (onInitResult && angular.isFunction(onInitResult.then)) {
                this.mask(this.inProgressText);
                return onInitResult.finally(() => this.unmask());
            }
            return $q.resolve();
        };
        const initRulesExecutor = () => {
            if (!this.logic) {
                return;
            }
            const logicScope = this.$scope.$new(true) as IScope;
            logicScope.locals = this;
            const constants = this.logic.constants || {};
            angular.forEach(constants, (value: any, key: string) => {
                logicScope[key] = value;
            });
            const compiledRules = this.logic.rules.map((rule) => {
                angular.forEach(rule.properties, (property) => {
                    if (!logicScope.locals.hasOwnProperty(property) || logicScope.locals[property] === undefined) {
                        logicScope.locals[property] = null;
                    }
                });
                return RulesExecutor.compileRule(rule);
            });
            const condition = this.logic.condition || (angular.noop as () => void);
            /**
             * После отработки правил необходим разовый запуск
             * $digest цикла, чтобы результат попал во view
             * в этот цикл правила не должны применяться
             */
            let digestCalled = false;
            logicScope.$watch(() => {
                if (condition() === false) {
                    return;
                }
                if (digestCalled) {
                    return;
                }
                digestCalled = true;
                $timeout(
                    () => {
                        RulesExecutor.execute(logicScope, compiledRules);
                        this.$scope.$digest();
                        digestCalled = false;
                    },
                    0,
                    false
                );
            });
        };
        waitComponentIsReady().then(() => {
            initRulesExecutor();
        });
    }

    protected onDestroy(): void {
        /** */
    }
    protected onChanges(onChanges: angular.IOnChangesObject): void {
        /** */
    }
    protected onInit(): angular.IPromise<void> | void {
        /** */
    }
    protected doCheck(): void {
        /** */
    }
    /**
     * Блокировка компонента для пользователя
     * @param {string} text сообщение для отображения пользователю
     */
    protected mask(text: string): void {
        this.blockUIInstance.start(text);
    }
    /**
     * Разблокировка компонента для пользователя
     */
    protected unmask(): void {
        this.blockUIInstance.stop();
    }
    protected $watch<T>(expression: (scope: IScope) => T, listener: (newValue: T, oldValue: T, scope: IScope) => any, objectEquality?: boolean): void {
        this.watchers.push(this.$scope.$watch<T>(expression, listener, objectEquality));
    }
}

const NgControllerMix = <T extends new (...args: any[]) => NgControllerBase>(Base: T) =>
    class extends Base {
        protected _$scope: angular.IScope;
        protected _$transition$: Transition;
        constructor(...args: any[]) {
            super(...args);
            [, this._$transition$, , this._$scope] = args;
        }
        public resolve(dependencies) {
            /**
             * Подключение локально определенных в `resolve` инжектов текущего состояния
             * @param  {Array} dependencies Массив с именами инжектов
             * @return {Array}              Массив с инжектами
             */
            return dependencies.map((dependency) => {
                return this._$transition$.injector().get(dependency);
            });
        }
        static get $inject() {
            return ["$injector", "$transition$", "EmptyObject", "$scope"];
        }
    };

class NgController extends NgControllerBase {
    protected _$scope: angular.IScope & IUserProfilePassportData;
    protected _$transition$: Transition;
    constructor($injector: angular.auto.IInjectorService, $transition$: Transition, protected params: object, $scope: angular.IScope) {
        super($injector);
        this._$transition$ = $transition$;
        this._$scope = $scope;
    }

    public resolve(dep: string[]): any[];
    public resolve<T>(dep: string): T;
    public resolve<T>(deps: string | string[]): T | any[] {
        if (angular.isArray(deps)) {
            return deps.map((dep) => this._$transition$.injector().get(dep));
        } else {
            return this._$transition$.injector().get(deps) as T;
        }
    }

    static get $inject() {
        return ["$injector", "$transition$", "EmptyObject", "$scope"];
    }
}

interface IUserProfilePassportData {
    phoneForm?: any;
    codeForm?: any;
    model?: any;
    passportForm?: any;
    isSaveUserDataBtnDisabled?: boolean;
}

export { NgControllerBase, NgComponentController, NgControllerMix, NgController };
