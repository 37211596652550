import { StateParams, StateService, Transition } from "@uirouter/core";
import angular from "angular";
import { IModalService } from "angular-ui-bootstrap";
import { USER_ROLES } from "application/constants";
import UgskPhone from "domain/classes/ugsk-phone.class";
import { IUGSKLocalStorage } from "infrastructure/interfaces";
import IPreviousContractRecord from "infrastructure/interfaces/IPreviousContractRecord";
import IScope from "infrastructure/interfaces/IScope";
import { Employee } from "infrastructure/services/employee.service";
import { EnvService } from "infrastructure/services/env.service";
import { UgskFormFieldLocker } from "infrastructure/services/formFieldLocker.service";
import { NotifyService } from "infrastructure/services/notifyService";
import { Int } from "infrastructure/types";
import { BaseAutoBodyController } from "../baseAuto.body.controller";
import multiCalculationDetailsTemplate from "./multiCalculationDetails.html";
import premiumDetailsTemplate from "./premiumDetails.html";
import { IMultiCalculationOffer, UAutoContract } from "./uAuto.factory";
import { IBank, IInsuranceProgram, UAutoRepository } from "./uAuto.repository";
import { PaymentKindsEnum } from "@ugsk/payments";

export class UAutoController extends BaseAutoBodyController {
    public Contract: UAutoContract;
    public employee: Employee;
    public banks: IBank[];
    public userDefinedMarkModelPartialEditMode: boolean;
    public insurancePrograms: IInsuranceProgram[];

    protected envService: EnvService;

    constructor(
        $injector: angular.auto.IInjectorService,
        $transition$: Transition,
        params: object,
        $scope: IScope,
    ) {
        super($injector, $transition$, params, $scope);
        this.envService = this.di<EnvService>("envService");
        this.employee = this.resolve<Employee>("employee");
        this.banks = this.resolve<IBank[]>("banks");
    }

    public $onInit(): void {
        super.$onInit();

        const notifyService = this.di<NotifyService>("notifyService");
        const $localStorage  = this.di<IUGSKLocalStorage>("$localStorage");
        if (this.stateCrossFrom && $localStorage.CrossDto) {
            this.Contract.InsurantPhones = UgskPhone.phoneStringToPhoneArray(this.Contract.InsuredCommonPhone);
        }

        this.userDefinedMarkModelPartialEditMode = !(new Array<string>(USER_ROLES.UNDERWRITER, USER_ROLES.CURATOR_GO, USER_ROLES.CURATOR, USER_ROLES.CURATOR_REGION, USER_ROLES.CURATOR_FILIAL).includes(this.originalEmployee.info.Role));

        const ugskFormFieldLocker = this.di<UgskFormFieldLocker>("ugskFormFieldLocker");
        const statusFieldLockRules = ({
            5: () => {
                ugskFormFieldLocker.lockAll();
                ugskFormFieldLocker.lockExceptList(
                "vm.data.ofdPayload.fn_number", "vm.data.ofdPayload.fiscal_document_number", "vm.data.ofdPayload.fiscal_document_attribute",
                "vm.data.ofdPayload.fiscal_operation_type", "vm.data.ofdPayload.receipt_datetime", "vm.data.ofdPayload.total",
              );
            }, // Аннулирован
            4: () => { // Возвращен на корректировку
                /* tslint:disable:max-line-length */
                ugskFormFieldLocker.lock(
                    "vm.contract.OptionalEquipments", //    "OptionalEquipments", "btnAddOptionalEquipments"
                    "vm.contract.GenderAgeExperiences", // "btnAddGenderAgeExperiences"
                    "vm.insurant > vm.ngModel.commonRealAddress > $ctrl.address", // "InsuredCommonRealAddress" TODO Проверить!
                    "vm.insurant > vm.ngModel > vm.ngModel.birthday", // SetEnableSpecificControl("InsuredPersonBirthday", false);
                    "vm.owner > vm.ngModel.commonRealAddress > $ctrl.address", // "BeneficiaryCommonRealAddress" TODO Проверить!
                    "vm.beneficiary > vm.ngModel > vm.ngModel.birthday", // "BeneficiaryPersonBirthday"
                    "vm.contract.CreditorId", // "CreditorId"
                    "vm.contract.SigningDate", // SetEnableSpecificControl("SigningDate", false);
                    "vm.contract.UnderwriterFactor", // "UnderwriterFactor"
                    "vm.contract.PlannedPayments", // SetEnableSpecificControl("PlannedPayments", false);
                    "vm.insurerIsBenificiary", // chbxCopyInsurerToBenificiary.SetEnabled(false);
                    "vm.creditor", // chbxCreditor.SetEnabled(false);
                    "vm.contract.GenderAgeExperiences > vm.insurantIsDriver", // chbxCopyInsurerToDriver.SetEnabled(false);
                    "vm.contract.FactPayments[0] > vm.payment.Serial", // A7DocumentIdentifier.SetEnabled(false);
                    /*
                    ! PaymentKind =>
                    */
                    "vm.contract.FactPayments[0] > vm.payment.PaymentKindId",
                    /*
                    ! VehicleModelSpelt =>
                    */
                    "vm.contract.VehicleModelSpelt > vm.modelSpelt",
                    /*
                    */
                );
                /* tslint:enable:max-line-length */
            },
            6: () => { // Заморожен
                ugskFormFieldLocker.lock(
                    "vm.contract.VehicleModelSpelt > vm.modelSpelt",
                    "vm.contract.VehicleModification",
                    "vm.year",
                    "vm.contract.InsuranceAmount",
                    "vm.contract.ATSRequirement",
                    "vm.contract.AtsEAS",
                    "vm.contract.AtsImmo",
                    "vm.contract.AtsMBDHood",
                    "vm.contract.AtsMBDWheel",
                    "vm.contract.AtsMBDTransmission",
                    "vm.contract.AtsSatellite",
                    "vm.contract.AtsRadio",
                    "vm.contract.ATSAnother",
                    "vm.contract.AppliesUnderinsurance",
                    "vm.contract.VehicleVIN",
                    "vm.contract.VehicleDocChassisNumber",
                    "vm.contract.VehicleDocBodyNumber",
                    "vm.contract.PaymentForm",
                    "vm.contract.KeyExist",
                    "vm.insuranceAmountType",
                    "vm.contract.BonusMalus",
                    "vm.contract.FranchiseType",
                    "vm.contract.FranchiseAmount",
                    "vm.contract.InsuranceTerm",
                    "vm.contract.Risk",
                    "vm.contract.DiscountIsNumeric",
                    "vm.contract.Discount",
                    "vm.contract.DriversRestrictionApproachName",
                    "vm.insurantIsDriver",
                    "vm.ngModel.contractorType",
                    "vm.ngModel.lastName",
                    "vm.ngModel.firstName",
                    "vm.ngModel.middleName",
                    "vm.ngModel.birthday",
                    "vm.contract.TechnicalAssistanceRisk",
                    "vm.contract.AdditionalExpensesRisk",
                    "vm.contract.AccidentAmount",
                    "vm.contract.CivilLiabilityAmount",
                    "vm.contract.InsuranceCoverage",
                    "vm.ngModel.drivingExperience",
                    "vm.contract.OptionalEquipmentAmount",
                    "vm.contract.InsuranceProgram",
                    "vm.contract.LapsedPayment",
                    "vm.contract.AgreedPremium",
                );
            },
            3: () => { // Согласован
                ugskFormFieldLocker.lockExceptList(
                    "vm.contract.ContractFrom",
                    "vm.contract.PlannedPayments",
                    "payment.DatePay",

                    "vm.contract.PolicyNumber",
                    "vm.contract.PolicySerial > vm.contract.PolicyNumber",

                    "vm.contract.FactPayments[0]",
                    "vm.contract.FactPayments[0] > vm.payment.PaymentKindId",
                    "vm.payment.PaymentKindId",
                    "vm.contract.FactPayments[0] > vm.payment.Number",
                    "vm.payment.Number",
                    "Number",
                    "vm.contract.FactPayments[0] > vm.payment.PaymentKindUniqueData",
                    "vm.payment.PaymentKindUniqueData",
                    "UniqueData",

                    "vm.phone",
                    "vm.insurant.phones > vm.phone",
                    "vm.contract.InsuredCommonEmail",
                    "vm.contract.InsuredCommonEmail",
                    "InsuredEmail",
                    "vm.contract.CreditorContractProps",

                    "vm.ngModel",
                    "vm.beneficiary",
                    "vm.beneficiary > vm.ngModel.contractorType",
                    "vm.ngModel.contractorType",

                    "vm.ngModel.documentType",
                    "vm.ngModel.docSerial",
                    "vm.ngModel.docNumber",
                    "vm.ngModel.docWhomGiven",
                    "vm.ngModel.docDateGiven",
                    "vm.beneficiary > vm.ngModel > vm.ngModel.lastName",
                    "vm.ngModel.lastName",
                    "vm.beneficiary > vm.ngModel > vm.ngModel.firstName",
                    "vm.ngModel.firstName",
                    "vm.beneficiary > vm.ngModel > vm.ngModel.middleName",
                    "vm.ngModel.middleName",
                    "vm.beneficiary > vm.ngModel > vm.ngModel.birthday",
                    "vm.ngModel.birthday",

                    "vm.beneficiary.commonRealAddress",
                    "vm.beneficiary.commonRealAddress > vm.address",
                    "vm.address",
                    "vm.contract.BeneficiaryCommonEmail",
                    "BeneficiaryEmail",

                    "vm.notRequired",
                    "inspectionIsNotRequired",
                    "vm.notRequired > vm.ngModel",
                    "vm.contract[vm.absenceReasonFieldName]",
                    "conclusionAbsenceReason",
                    "vm.contract.IsTowardInspection",
                    "isTowardInspection",
                    "vm.contract.IsTowardInspection > vm.ngModel",
                );
            },
        });
        this._$scope.$watch(() => {
            const BlockedFieldsLength = (this.Contract.BlockedFields || []).length;
            return `${this.Contract.getStatus()}-${BlockedFieldsLength}`;
        }, () => {
            ugskFormFieldLocker.unlockAll();
            const action = statusFieldLockRules[this.Contract.getStatus()];
            if (angular.isFunction(action)) {
                action();
            }
            if (angular.isArray(this.Contract.BlockedFields) && this.Contract.BlockedFields.length) {
                this.Contract.BlockedFields
                    .map((item) => item.split(";"))
                    .filter(([, status]) => status === "Blocked")
                    .forEach(([field]) => {
                        ugskFormFieldLocker.lock(field);
                        ugskFormFieldLocker.lock(`vm.contract.${field}`);
                        ugskFormFieldLocker.lock(`vm.Contract.${field}`);
                    });
            }
        });
        const apiUrl: string = this.envService.read("apiUrl");
        const $http = this.di<angular.IHttpService>("$http");
        if (this.Contract.Id) {
            $http.get(`${apiUrl}UAuto/GetContractScoringState/${this.Contract.Id}`)
                .then((checkedResult) => {
                    this.Contract.checkedResult = checkedResult.data;
                })
                .catch(() => {
                    notifyService.errorMessage("Ошибка при обращении к БРМС");
                });
        }
    }

    public createInvoiceForFrozenContract() {
        if (!this.Contract.isFrozen()) {
            const notifyService = this.di<NotifyService>("notifyService");
            notifyService.warningMessage("Внимание!", "Для формирования счета, необходимо заморозить расчет");
            return new Promise((_, reject) => reject());
        }

        this.blockUI.start(" ");
        return this.Contract.$preSign().finally(() => {
            this.blockUI.stop();
        });
    }

    public openMultiCalculationOffersDetails(): angular.IPromise<void> {
        const $uibModal = this.di<IModalService>("$uibModal");

        return $uibModal.open({
            controller(offers, $uibModalInstance) {
                "ngInject";

                this.offers = offers;
                this.close = $uibModalInstance.close;
                this.dismiss = $uibModalInstance.dismiss;
            },
            controllerAs: "vm",
            resolve: {
                offers: () => this.Contract.MultiCalculationOffers,
            },
            size: "80p",
            template: multiCalculationDetailsTemplate,
        }).result.then((offer) => {
            this.applyOffer(offer);
        }).catch(() => {
            angular.noop();
        });
    }

    public applyOffer(offer: IMultiCalculationOffer) {
        const notifyService = this.di<NotifyService>("notifyService");
        const $state = this.di<StateService>("$state");

        notifyService.progressStart("Применение предложения");
        return this.Contract.$applyArtificialCalculation(offer.CalculationId).then(() => {
            $state.go($state.current.name, {
                contract: null,
                id: this.Contract.Id,
            }, {
                reload: true,
            });
        }).catch(() => {
            notifyService.errorMessage("Ошибка при применении предложения");
        }).finally(() => {
            notifyService.progressStop();
        });
    }
    //  @override
    public importPreviousContract(previousContractRecord: IPreviousContractRecord): angular.IPromise<void> {
        this.Contract.PreviousContractNumber = previousContractRecord.PolicyNumber;
        this.Contract.PreviousContractId = previousContractRecord.Id;
        this.Contract.PreviousPolicySerial = previousContractRecord.PolicySerial;
        return;
    }

    public showPremiumDetails(): void {
        const $uibModal = this.di<IModalService>("$uibModal");
        const vm = this;
        $uibModal.open({
            controller($uibModalInstance) {
                "ngInject";

                this.dismiss = $uibModalInstance.dismiss;
                /* tslint:disable-next-line:max-line-length */
                this.Calculation = vm.Contract.Calculation.filter((calculation) => calculation.IsPrintable).sort((a, b) => a.Role > b.Role ? 1 : -1);
                this.CalculatedPremium = vm.Contract.CalculatedPremium;
                this.CalculatedPremiumWithoutDiscount = vm.Contract.CalculatedPremiumWithoutDiscount;
                this.CalculatedAcquisition = vm.Contract.CalculatedAcquisition;
                this.CalculatedDiscount = vm.Contract.CalculatedDiscount;
            },
            controllerAs: "vm",
            template: premiumDetailsTemplate,
        });
    }

    //  @override
    protected get Repository(): UAutoRepository {
        return this.Contract.getRepository() as UAutoRepository;
    }

    public removePreviousContract(): void {
        this.Contract.removePreviousContract();
    }

    public calculatedProcentPremiumWithoutDiscount(): number | string {
        if (this.Contract.CalculatedPremiumWithoutDiscount && this.Contract.InsuranceAmount) {
            return ((this.Contract.CalculatedPremiumWithoutDiscount / this.Contract.InsuranceAmount) * 100).toFixed(2);
        }

        return 0;
    }
    public calculatedFinalProcentPremium(): number | string {
        if (this.Contract.CalculatedPremium && this.Contract.InsuranceAmount) {
            return ((this.Contract.CalculatedPremium / this.Contract.InsuranceAmount) * 100).toFixed(2);
        }

        return 0;
    }

    public getInsuredPhone(): string {
        if (this.Contract.InsurantPhones && this.Contract.InsurantPhones.length > 0) {
            return this.Contract.InsurantPhones[0].Number;
        }

        return "";
    }

    public disabledPaymenKindsList(): string {
        const disabled = [];

        if (!this.Contract.ContractNum) {
            disabled.push(PaymentKindsEnum.PAYMENT_ORDER);
        }

        return disabled.join("|");
    }

    public getInsurancePrograms(): IInsuranceProgram[] {
        return this.Repository.InsuranceProgram.Collection
            .filter((program) => !program.State)
            .map((program) => program.InsuranceProgram)
            .map((programName) => {
                const InsuranceProgramMarketingName = this.Repository.InsuranceProgramMarketingName.Collection
                    .find((collection) => collection.InsuranceProgram === programName);
                return InsuranceProgramMarketingName;
            });
    }
}
