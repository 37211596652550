// @todo Компонент ипользуется только в продукте "Уверенное решение (квартира)"
import { NgComponentController } from "infrastructure/NgController";
import template from "./ugskEngineeringEquipment.component.html";

export default angular.module("ugsk.components.engineering-equipment", []).component("ugskEngineeringEquipment", {
    bindings: {
        minYear: "<",
        model: "=engineeringEquipment",
        removeProperty: "&",
        tooltipOptionsPopover: "<",
    },
    controller: class extends NgComponentController {
        clearPropertyGroupValidation() {
            const guid = this.model.Guid;
            const isGroup = this.model.IsGroup;
            if (isGroup && guid) {
                const [validationService] = this.di(["validationService"]);
                const namePurchaseYear = `PurchaseYear${guid}`;
                const namePurchaseCost = `PurchaseCost${guid}`;
                validationService.removeError(namePurchaseYear);
                validationService.removeError(namePurchaseCost);
            }
            this.model.PurchaseCost = null;
            this.model.PurchaseYear = null;
        }
        onInit() {
            const [datePickerBaseYearOptions] = this.di(["datePickerBaseYearOptions"]);
            this.engineeringEquipmentYearOptions = angular.copy(datePickerBaseYearOptions);
            this.engineeringEquipmentYearOptions.startDate = this.minYear;
        }
    },
    controllerAs: "vm",
    template,
}).name;
