import { Transition } from "@uirouter/core";
import { BaseToolbarController } from "application/baseProduct.toolbar.controller";
import IBaseViewParams from "infrastructure/interfaces/IBaseViewParams";
const PersonalAccidentAdultId = 39;
const PersonalAccidentCreditId = 40;
const PrintOptions = [
    {
        title: "Памятка Страхователю (Застрахованному лицу)",
        action() {
            return this.openPdf({
                docType: "Notes",
                id: this.Contract.Id,
            });
        },
        disabled() {
            if (!this.Contract) { return true; }
            if (this.Contract.PolicyFullValue) {
                return !(/\ЛЗВ|\ЛЗК/.test(this.Contract.PolicyFullValue) && this.Contract.isSigned());
            }
            return !([PersonalAccidentAdultId, PersonalAccidentCreditId].includes(this.Contract.PolicySerialId) && this.Contract.isSigned());
        },
        icon: "fa-print",
    },
    "policy",
    "policyWithoutA7",
    "application",
    "paymentAccountAll",
    {
        title: "Медицинская анкета",
        action() {
            return this.printMedicalQuestionnaire();
        },
        disabled() {
            return this.isDisabledPrint();
        },
        icon: "fa-print",
    }, {
        title: "Финансовая анкета",
        action() {
            return this.printFinancialQuestionnaire();
        },
        disabled() {
            return this.isDisabledPrint();
        },
        icon: "fa-print",
    },
    "emptyApplication",
    {
        title: "Пустой бланк медицинской анкеты",
        action() {
            this.printEmptyMedicalQuestionnaire();
        },
        icon: "fa-download",
    }, {
        title: "Пустой бланк финансовой анкеты",
        action() {
            this.printEmptyFinancialQuestionnaire();
        },
        icon: "fa-download",
    },
    "freeFormReceipt",
];
export class PersonalAccidentToolbarController extends BaseToolbarController {
    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
        {
            viewType = "form",
        }: IBaseViewParams,
    ) {
        super($injector, $transition, {
            viewType,
            printOptions: PrintOptions,
            extraOptions: ["manual", "integrationInfo", "annul"],
            crossOptions: ["personalAccident"],
        });
    }
    public printFinancialQuestionnaire(id = this.Contract.Id) {
        return this.openPdf({
            id,
            docType: "FinancialQuestionnaire",
            fileNamePrefix: "Финансовая анкета",
        });
    }
    public printMedicalQuestionnaire(id = this.Contract.Id) {
        return this.openPdf({
            id,
            docType: "MedicalQuestionnaire",
            fileNamePrefix: "Медицинская анкета",
        });
    }
    public printEmptyFinancialQuestionnaire() {
        return this.printFinancialQuestionnaire(0);
    }
    public printEmptyMedicalQuestionnaire() {
        return this.printMedicalQuestionnaire(0);
    }
}
