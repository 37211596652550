import { HubConnection } from "@microsoft/signalr";
import angular, { IPromise, IWindowService, ITimeoutService } from "angular";
import { EventEmitter } from "events";
import { Helpers } from "infrastructure/app.helpers";
import { IUGSKLocalStorage } from "infrastructure/interfaces";
import { IToasterProviderPopParams } from "infrastructure/interfaces/INotificationProvider";
import ISignalRPayload from "infrastructure/interfaces/ISignalRPayload";
import { Logger } from "infrastructure/logToServer";
import { NgControllerBase } from "infrastructure/NgController";
import moment from "moment";
import notificationIcon from "presentation/assets/images/notification_logo.png";
import { EnvService } from "./env.service";
import { IDesktopProviderPopParams } from "./notifyDesktopProvider";
import { ProductsService } from "./products.service";

export class SignalRService extends NgControllerBase {
    public static get $inject() {
        return ["$injector", "$rootScope"];
    }
    private $log = this.di<Logger>("$log");
    private $localStorage = this.di<IUGSKLocalStorage>("$localStorage");
    private helpers = this.di<Helpers>("helpers");
    private $window = this.di<IWindowService>("$window");
    private emitter = new EventEmitter();
    private connection: HubConnection;

    constructor($injector: angular.auto.IInjectorService, private $scope: ng.IScope) {
        super($injector);

        if (!this.$localStorage.desktopNotifications) {
            this.$localStorage.desktopNotifications = [];
        }
    }
    public on(code: "notification", cb: (notification: IDesktopProviderPopParams) => void) {
        return this.emitter.on(code, cb);
    }

    public emit(code: "notification" | "error", data: IDesktopProviderPopParams & IToasterProviderPopParams) {
        this.emitter.emit(code, data);
    }

    public notify(payload: ISignalRPayload): void {
        const productsService = this.di<ProductsService>("productsService");

        const productCode = payload.InsuranceProductCode[0].toLowerCase() + payload.InsuranceProductCode.slice(1);
        const product = productsService.getByCode(productCode);
        let SellerIsB2B = "";
        let VehicleYearMade = "";
        let SupervisorName = "-";

        if (payload.SellerIsB2B) {
            SellerIsB2B = "B2B\n";
        }
        if (payload.VehicleYearMade) {
            VehicleYearMade = payload.VehicleYearMade + "г.";
        }
        if (payload.SupervisorName) {
            SupervisorName = payload.SupervisorName;
        }

        const message = `
            ${SellerIsB2B}Агент: ${payload.SellerName}\n
            Куратор: ${SupervisorName}\n
            ММ: ${payload.VehicleBrand} ${payload.VehicleModel}, ${VehicleYearMade}\n
            ${payload.InsuranceProgram}, ${payload.InsurancePremium} руб.\n
            КБМ: ${payload.BonusMalus}
        `;

        const openContract = () => {
            const notification = this.$localStorage.desktopNotifications.find((item: ISignalRPayload) => {
                return item.MessageId === payload.MessageId;
            });

            if (notification) {
                notification.IsActive = false;
            }

            const $timeout = this.di<ITimeoutService>("$timeout");
            $timeout().then(() => {
                const tab = this.$window.open(this.helpers.getContractUrl(payload.ContractId, productCode), "_blank");
                tab.focus();
            });
        };

        this.emit("notification", {
            body: message,
            icon: notificationIcon,
            //  toaster param
            clickHandler: (toast, buttonIsClose) => {
                if (!buttonIsClose) {
                    openContract();
                }
                return true;
            },
            //  Notification api
            onclick() {
                const $this: Notification = this;
                $this.close();
                openContract();
            },
            tag: Number(payload.ContractId).toString(),
            title: `Создан проект договора ${product.productName}`,
        });
    }

    public debug(message: string): void {
        this.$log.log(`Уведомления: ${message}`);
    }

    /**
     * @param {String} groupName Имя группы в хабе
     */

    public async connectToHub(groupName: string) {
        const envService = this.di<EnvService>("envService");
        const hubUrl = envService.read("signalrUrl");
        const signalR = await import(/* webpackChunkName: "signalr" */ "@microsoft/signalr");
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(hubUrl, { accessTokenFactory: () => "thisloginToken" })
            .configureLogging(signalR.LogLevel.Warning)
            .build();

        this.connection.on("showMessage", (message: ISignalRPayload | string) => {
            let payload: ISignalRPayload;
            if (angular.isString(message)) {
                try {
                    payload = angular.fromJson(message);
                } catch (e) {
                    this.$log.error(`Can not parse push messsage as json`);
                    this.emit("error", e);
                    return;
                }
            }
            if (moment(payload.MessageDate).isBefore(moment().subtract(1, "day"))) {
                this.debug("Устаревшее уведомление.");
                return;
            }
            const messageAlreadyReceived = Boolean(
                this.$localStorage.desktopNotifications.find(
                    (item: ISignalRPayload) => item.MessageId && item.MessageId.toLowerCase() === payload.MessageId.toLowerCase(),
                ),
            );
            if (messageAlreadyReceived) {
                this.debug("Уведомление было получено ранее.");
                return;
            }

            this.saveMessagePayload(payload);
            this.notify(payload);
        });
        await this.connection.start();
        await this.connection.invoke("joinToGroup", groupName);
        this.debug(`Пользователь ${this.connection.connectionId} присоединился к группе ${groupName}`);
    }
    public async disable() {
        if (this.connection) {
            return await this.connection.stop();
        }
    }

    public saveMessagePayload(payload: ISignalRPayload): void {
        payload.IsActive = true;
        this.$localStorage.desktopNotifications.unshift(payload);

        this.$scope.$digest();
    }

    public isEnabled(): boolean {
        return this.connection.state === "Connected";
    }

    public getUnreadNotificationsCount(): number {
        return this.$localStorage.desktopNotifications.filter((payload: ISignalRPayload) => payload.IsActive).length;
    }

    public removeOverdueNotifications(): void {
        this.$localStorage.desktopNotifications = this.$localStorage
            .desktopNotifications
            .filter(
                (notification: ISignalRPayload) => {
                    const messageDate = moment(notification.MessageDate);
                    if (messageDate.isValid()) {
                        return messageDate.isAfter(moment().subtract(1, "day"));
                    }
                    return true;
                },
            );
    }
}
