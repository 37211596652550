import angular, { ICompileService } from "angular";
import IScope from "infrastructure/interfaces/IScope";

export class UgskFormFieldLocker {
    private lockedFields: Set<string> = new Set();
    private lockedAllFields: boolean = false;
    private lockedAllFieldsExceptList: Set<string> = new Set();
    public isLocked(field: string): boolean {
        if (this.lockedAllFieldsExceptList.size > 0) {
            const ret = (
                this.lockedAllFieldsExceptList.has(field) ||
                this.lockedAllFieldsExceptList.has(`vm.contract.${field}`) ||
                this.lockedAllFieldsExceptList.has(`vm.Contract.${field}`)
            );
            return !ret;
        }
        if (this.lockedAllFields) {
            return true;
        }
        return (
            this.lockedFields.has(field) ||
            this.lockedFields.has(`vm.contract.${field}`) ||
            this.lockedFields.has(`vm.Contract.${field}`)
        );
    }
    public lock(...fields: string[]): void {
        fields.map((field) => this.lockedFields.add(field));
    }
    public unlock(field: string): void {
        this.lockedFields.delete(field);
    }
    public unlockAll(): void {
        this.lockedFields.clear();
        this.lockedAllFieldsExceptList.clear();
        this.lockedAllFields = false;
    }
    public lockAll(): void {
        this.lockedAllFields = true;
    }
    public lockExceptList(...fields: string[]): void {
        fields.map((field) => this.lockedAllFieldsExceptList.add(field));
    }
    public unlockExceptList(field: string): void {
        this.lockedAllFieldsExceptList.delete(field);
    }
}
export default angular.module("ugsk-form-field-locked", [])
.service("ugskFormFieldLocker", UgskFormFieldLocker)
.directive("ngModel", ($compile: ICompileService, ugskFormFieldLocker: UgskFormFieldLocker) => {
    return {
        priority: 201,
        replace: false,
        restrict: "A",
        terminal: true,
        // tslint:disable-next-line: object-literal-sort-keys
        compile: function compile(element, attrs) {
            const fieldAddress = element
                .parents("[ng-model]")
                .map((index, item) => item.getAttribute("ng-model"))
                .toArray()
                .reverse();

            const fieldSelfNgModel: string = attrs.ngModel;
            const fieldLocation = [...fieldAddress, fieldSelfNgModel].join(" > ");
            const fieldName: string = attrs.name;

            const newAttrValue: string[] = [];
            const prevAttrValue = element.attr("ng-disabled");
            const lockRuleByPath = `$isFieldLocked("${fieldLocation}")`;

            if (prevAttrValue) {
                newAttrValue.push(prevAttrValue);
            }
            newAttrValue.push(lockRuleByPath);
            if (fieldSelfNgModel) {
                newAttrValue.push(`$isFieldLocked("${fieldSelfNgModel}")`);
            }
            if (fieldName) {
                newAttrValue.push(`$isFieldLocked("${fieldName}")`);
            }
            element.attr("ng-disabled", newAttrValue.join(" || "));
            return {
                post: function postLink(scope, iElement) {
                    $compile(iElement, undefined, 200)(scope);
                },
                pre: function preLink(scope: IScope) {
                    scope.$isFieldLocked = (name) => ugskFormFieldLocker.isLocked(name);
                },
            };
        },
    };
}).name;
