import angular from "angular";
import { DOC_TYPES } from "application/constants";
import { IDatePickerOptions } from "infrastructure/interfaces/IDatePickerOptions";
import { ILogic } from "infrastructure/interfaces/ILogic";
import { NgComponentController } from "infrastructure/NgController";
import { Day } from "infrastructure/types";
import moment from "moment";
import template from "./ugskBoxAccidentInsuredPerson.component.html";

class BoxAccidentInsuredPerson extends NgComponentController {
    public isFormLocked: boolean;
    public birthdateDateConfig: Partial<IDatePickerOptions>;
    public maxAge: Day;
    public minAge: Day;

    get logic(): ILogic {
        const vm = this;
        /* tslint:disable object-literal-sort-keys */
        return {
            condition() {
                return !vm.isFormLocked;
            },
            constants: {
                BirthCertificateId: DOC_TYPES.BIRTH_CERTIFICATE,
                ForeignDocTypeId: 7,
            },
            rules: [{
                description: "Ограничение в 9 символов номера документа для иностранцев, 6 для остальных",
                properties: ["insuredPersonDocNumberLength"],
                when: "person.InsuredPersonDocTypeId eq ForeignDocTypeId",
                then: "insuredPersonDocNumberLength = 9",
                else: "insuredPersonDocNumberLength = 6",
            }, {
                description: "Ограничение в 6 символов серии документа для свидетельства о рождении, 4 для остальных",
                properties: ["insuredPersonDocSerialLength"],
                when: "person.InsuredPersonDocTypeId eq BirthCertificateId",
                then: "insuredPersonDocSerialLength = 6",
                else: "insuredPersonDocSerialLength = 4",
            }, {
                description: "Если стерли серию документа, то обнуляем - не оставляем пустым значение",
                properties: ["person.InsuredPersonDocSerial"],
                when: "person.InsuredPersonDocSerial eq ''",
                then: "person.InsuredPersonDocSerial = null",
            }, {
                description: "Если стерли номер документа, то обнуляем - не оставляем пустым значение",
                properties: ["person.InsuredPersonDocNumber"],
                when: "person.InsuredPersonDocNumber eq ''",
                then: "person.InsuredPersonDocNumber = null",
            }],
        };
        /* tslint:enable object-literal-sort-keys */
    }

    public onInit(): void {
        this.birthdateDateConfig = {
            endDate: moment().subtract(this.minAge, "years").format("YYYY-MM-DD"),
            startDate: moment().subtract(this.maxAge, "years").format("YYYY-MM-DD"),
        };
    }
}

export default angular.module("app.boxAccident.components.insured-person", [])
.component("ugskBoxAccidentInsuredPerson", {
    bindings: {
        contract: "=",
        insuredIndex: "<",
        insuredPersonDocTypes: "<",
        insuredPersonLocked: "<",
        isDisabledDeleteInsuredPerson: "<",
        isDisabledDocSerial: "<",
        isDocWhomGivenVisible: "<",
        isFormLocked: "<",
        maxAge: "<",
        minAge: "<",
        onDeleteButtonClicked: "&",
        onUpdateInsuredPersonDocTypeField: "&",
        person: "=",
        product: "<",
        registrationDateConfig: "<",
    },
    controller: BoxAccidentInsuredPerson,
    controllerAs: "vm",
    template,
}).name;
