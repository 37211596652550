import { IProductsVersion, IProductVersionResource } from "infrastructure/interfaces/index";
import { NgControllerBase } from "infrastructure/NgController";
import { EnvService } from "infrastructure/services/env.service";

export class VersionsInfoService extends NgControllerBase {
    public productsVersion: IProductsVersion;
    private getProductsOperation: ng.IPromise<IProductsVersion>;
    constructor($injector) {
        super($injector);
        this.productsVersion = null;
    }

    private get cachedVersions() {
        const versions = this.productsVersion;
        if (versions) {
            const envService = this.di<EnvService>("envService");
            versions.appVersion = envService.read("version").toString();
            const debug = envService.read("debug");
            if (debug) {
                const buildMetadata: string[] = [];
                const branch: string | object[] | object = envService.read("branch");
                const commit: string | object[] | object = envService.read("commit");
                const build: string | object[] | object = envService.read("build");
                if (branch) {
                    versions.appVersion += `-${branch.toString().replace(/\//g, "-")}`;
                }
                if (commit) {
                    buildMetadata.push(commit.toString());
                }
                if (build) {
                    buildMetadata.push(build.toString());
                }
                if (commit || build) {
                    versions.appVersion += `+${buildMetadata.join(".")}`;
                }
            }
        }
        return versions;
    }
    private set cachedVersions(versions: IProductsVersion) {
        this.productsVersion = versions;
    }

    public getVersions(): ng.IPromise<IProductsVersion> {
        if (!this.getProductsOperation) {
            if (this.cachedVersions) {
                const $q = this.di<ng.IQService>("$q");
                this.getProductsOperation = $q.resolve(this.cachedVersions);
            } else {
                const productsVersionResource = this.di<IProductVersionResource>("productsVersionResource");
                this.getProductsOperation = productsVersionResource.getProductsVersion().$promise.then((versions) => {
                    this.cachedVersions = versions;
                    return this.cachedVersions;
                });
            }
        }
        return this.getProductsOperation;
    }
}
