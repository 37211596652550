import { NgComponentController } from "infrastructure/NgController";
import moment from "moment";
import "./ugskPaymentOfContractView.component.css";
import template from "./ugskPaymentOfContractView.component.html";
import { PAYMENT_KINDS, PAYMENT_STATUSES } from "application/constants.ts";
import internetAcquiringRequest from "./internetAcquiringRequest.controller";
import { capitalizeFirstLetter } from "infrastructure/app.helpers";

export const ugskPaymentOfContractView = {
    bindings: {
        contract: "<",
        isPlannedPaymentLocked: "&",
        repository: "<",
        repositoryWithoutId: "<?",
        formLocked: "<",
        formModified: "<",
        paymentKindsBlacklist: "<",
        product: "<",
        secondPaymentMaxMonth: "<",
        suggestedPaymentNumber: "<",
        prechecksAllowed: "<",
    },
    controller: class extends NgComponentController {
        onInit() {
            [
                this.cashboxResource,
                this.paymentProcessResource,
                this.filialService,
                this.notifyService,
                this.envService,
            ] = this.di([
                "cashboxResource",
                "paymentProcessResource",
                "filialService",
                "notifyService",
                "envService",
            ]);
            if (this.prechecksAllowed) {
                this.getCashbox().then((cashbox) => {
                    this.cashbox = cashbox;
                });
            }
            this.PAYMENT_KINDS = PAYMENT_KINDS;
            this.precheckCreateDisabled = this.envService.read("precheckCreateDisabled");
            this.allowedPaymentKinds = this.repository.PaymentKind.Collection
                .map(paymentKind => paymentKind.PaymentKindId || paymentKind.PaymentKind) // OSAGO case
                .filter(paymentKind => !this.paymentKindsBlacklist.includes(paymentKind));
            this.calculatedPaymentKindId = this.contract.primaryPayment.PaymentKindId;
            this.isStatusRefreshed = false;
            this.refresh = false;
        }

        async getCashbox() {
            try {
                const employeeCashbox = await this.cashboxResource.getEmployeeCashbox().$promise;
                if (!employeeCashbox) return "";
                const cashboxList = await this.cashboxResource.getCashboxList().$promise;
                const cashbox = cashboxList.find((itemCashbox) => itemCashbox.Guid === employeeCashbox.cashboxGuid);
                if (!cashbox) return "";
                const filial = await this.filialService.getFilialByGuid({ Guid: cashbox.SetupFilialGuid }).$promise;
                const cashboxNames = [];
                cashboxNames.push(cashbox.Name);
                if (filial && angular.isArray(filial.value) && filial.value.length > 0) {
                    cashboxNames.push(filial.value.shift().Name);
                }
                cashboxNames.push(cashbox.SetupAddress);
                return cashboxNames.join(", ");
            } catch (e) {
                return "";
            }
        }

        // костыль потому что статус оплаты только в каско
        // со временем можно убрать, когда статус появится в др. продуктах
        isuAutoProduct() {
            return this.product && this.product.code === "uAuto";
        }

        isPaidPayment() {
            const [firstPayment] = this.contract.FactPayments;
            if (firstPayment) {
                return firstPayment.PaymentStatusId === PAYMENT_STATUSES.PAID;
            }
            return false;
        }

        isPaymentKindValid() {
            const [firstPayment] = this.contract.FactPayments;
            if (firstPayment) {
                const { PaymentKindId } = firstPayment;
                return [this.PAYMENT_KINDS.INTERNET_ACQUIRING, this.PAYMENT_KINDS.BANK_CARD].includes(PaymentKindId);
            }
            return false;
        }

        /**
         * Ожидание в 10 минут после подписания договора
         */
        isTenMinutesPassed() {
            let [signingDate, now] = [moment(this.contract.SigningDate), moment()];
            return (now.diff(signingDate, 'minutes') > 10);
        }

        /**
         * Обновление статуса оплаты контракта
         */
        refreshPaymentProcess() {
            this.refresh = true;
            this.paymentProcessResource.refreshStatus({ contractId: this.contract.Id }).$promise
                .then(result => {
                    this.notifyService.message(undefined, result.data, {
                        type: "success",
                        viewType: "popup",
                    });
                    this.isStatusRefreshed = true;
                }).catch(e => {
                    this.notifyService.message(undefined, e.data.Message, {
                        type: "error",
                        viewType: "popup",
                    });
                }).finally(() => {
                    this.refresh = false;
                });
        }


        getMessagePaid(isFirstItem) {
            const PaymentPaid = 3;
            if (isFirstItem) {
                const { PaymentStatusId } = this.contract.FactPayments[0];
                return PaymentStatusId === PaymentPaid ? "Оплачен" : "Не оплачено";
            }
            return "";
        }

        createPrecheck() {
            this.notifyService.progressStart("Формирование чека");
            this.cashboxResource.createPrecheck({ Id: this.contract.Id }).$promise.then((data) => {
                this.notifyService.message(undefined, "Чек успешно сформирован", {
                    type: "success",
                    viewType: "popup",
                });
            }).catch((error) => {
                this.notifyService.message(undefined, error.data.Message, {
                    type: "error",
                    viewType: "popup",
                });
            }).finally(() => {
                this.notifyService.progressStop();
            });
        }

        getContractPaymentMinDate(index, previousPayment) {
            if (this.formLocked) return;
            if (index === 0) {
                return;
            }
            return moment(previousPayment.DatePay).add(1, "d").format("YYYY-MM-DD");
        }

        getContractPaymentMaxDate(maxDate = 4) {
            return moment(this.contract.SigningDate).add(maxDate, "M").format("YYYY-MM-DD");
        }

        isInternetAcquiring() {
            const internetAcquiringPaymentKindId = 6;
            return (this.contract.primaryPayment.PaymentKindId === internetAcquiringPaymentKindId) && 
            (this.contract.primaryPayment.PaymentKindId === this.calculatedPaymentKindId);
        }

        isInternetAcquiringAvailable() {
            return (this.contract.isSigned()
                || (this.contract.isSentToAccount && this.contract.isSentToAccount()) // Osago
                || (this.contract.isAccounted && this.contract.isAccounted()) // Osago
                || this.contract.isReturnedForRevision())
                && this.contract.FactPayments[0].PaymentStatusId !== PAYMENT_STATUSES.PAID;
        }

        generateAcquiringUrl() {
            const [
                $uibModal,
                envService,
                $http,
            ] = this.di([
                "$uibModal",
                "envService",
                "$http",
            ]);

            const apiUrl = `${envService.read("apiUrl")}${capitalizeFirstLetter(this.product.code)}`;
            if (
                !angular.isArray(this.contract.getRepository().AcquiringBank.Collection)
                || this.contract.getRepository().AcquiringBank.Collection.length === 0
            ) {
                const notifyService = this.di("notifyService");
                notifyService.errorMessage("Сервис недоступен, справочник банков пуст");
                return;
            }

            return $uibModal.open({
                controller: internetAcquiringRequest,
                controllerAs: "vm",
                resolve: {
                    acquiringBanks: () => this.contract.getRepository().AcquiringBank.Collection,
                    apiUrl: () => apiUrl,
                    contract: () => this.contract,
                    productCode: () => this.product.code,
                    currentLink: () => $http({ _suppressErrors: true, url: `${apiUrl}/GetAcquiringLink/${this.contract.Id}` }).then(({ data }) => data).catch(() => {}),
                },
                size: "lg",
                template: require("./internetAcquiringRequestForm.html"),
            }).result.then(({
                url,
            }) => {
                alert(url);
            });
        }

        thousands(amount) {
            const filter = this.di("thousandFilter");
            amount = filter(amount);
            if (!amount.split(".")[1]) {
                amount = `${amount}.00`;
            }
            return amount;
        }
    },
    controllerAs: "vm",
    template,
    transclude: true,
};
