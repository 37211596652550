import { EngineeringEquipmentDTO } from "infrastructure/interfaces/WebApi/EngineeringEquipmentDTO";
import { Decimal, Int } from "infrastructure/types";
import { Uuid } from "lib/uuid";

export default class EngineeringEquipment implements EngineeringEquipmentDTO {
    public Id: number = null;
    public CalculatedAmount: number = null;
    public DeclaredAmount: number = null;
    public Description: string = null;
    public Guid: string = Uuid.raw();
    public Name: string = null;
    public PurchaseYear: Int = null;
    public PurchaseCost: Int = null;
    public IsGroup: boolean = false;
    public CalculatedMinAmount: Decimal;
    public CalculatedMaxAmount: Decimal;

    public getName(): string {
        return this.Name ? this.Name + (this.IsGroup ? " (группа) " : "") : "";
    }
}
