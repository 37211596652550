import { Transition } from "@uirouter/core";
import IBaseViewParams from "infrastructure/interfaces/IBaseViewParams";
import { BoxVHIToolbarController } from "../boxVHI-toolbar.controller";

export class BoxVHIJournalToolbarController extends BoxVHIToolbarController {
    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
        emptyObject: IBaseViewParams,
        $scope,
    ) {
        super($injector, $transition, {
            viewType: "journal",
        }, $scope);
    }
    public init() {}
}
