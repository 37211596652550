import angular, { ITemplateCacheService, IWindowService } from "angular";
import { IModalService } from "angular-ui-bootstrap";
import {
    AntiMiteVHIPerson, BoxVHIContract, BoxVHIRepository, ImmigrantVHIPerson, StudentVHIPerson,
} from "application/boxVHI/boxVHI.factory";
import ugskDateTimePicker from "application/components/ugsk-date-time-picker/ugskDateTimePicker.component";
import { TAnchorToElementFn } from "infrastructure/app.helpers";
import { IDatePickerOptions } from "infrastructure/interfaces/IDatePickerOptions";
import { NgComponentController } from "infrastructure/NgController";
import { NotifyService } from "infrastructure/services/notifyService";
import { ValidationService } from "infrastructure/services/validation.service";
import { Day } from "infrastructure/types";
import moment from "moment";
import AntiMiteVHITemplate from "../../AntiMiteVHITemplate.xlsx";
import StudentVHITemplate from "../../StudentVHITemplate.xlsx";
import template from "./insuredPerson.component.html";
import insuredPersonAntiMiteVHITemplate from "./insuredPersonAntiMiteVHI.component.html";
import insuredPersonImmigrantVHITemplate from "./insuredPersonImmigrantVHI.component.html";
import insuredPersonStudentVHITemplate from "./insuredPersonStudentVHI.component.html";
import { IExcelUploadData, InsuredPersonUploadComponentController } from "./insuredPersonUpload.component";
import insuredPersonUploadTemplate from "./insuredPersonUpload.component.html";

type IPerson = AntiMiteVHIPerson | StudentVHIPerson | ImmigrantVHIPerson;

class InsuredPersonComponentController extends NgComponentController {
    public numberOfEntries = 1;
    public contract: BoxVHIContract;
    public Repository: BoxVHIRepository;
    public datepickerOptions: Partial<IDatePickerOptions> = {
        outFormat: "YYYY-MM-DDT00:00:00Z",
    };
    public docDateGivenConfig: Partial<IDatePickerOptions> = {
        endDate: moment().format("YYYY-MM-DD"),
        startDate: moment().subtract(100, "years").format("YYYY-MM-DD"),
    };
    public persons: IPerson[];
    public insuredPersonIsInsured: boolean;
    public insuredPersonAddressEqualPropertyAddress: boolean;
    public insuredWatcher: () => void = null;
    public validationService: ValidationService;
    public uploadLoading = false;
    public excelTemplates = {
        AntiMiteVHI: AntiMiteVHITemplate,
        StudentVHI: StudentVHITemplate,
    };

    public onInit(): void {
        this.Repository = this.contract.getRepository();
        this.validationService = this.di<ValidationService>("validationService");
        this.insuredPersonIsInsured = this.isInsuredPersonIsInsured();

        if (!angular.isArray(this.persons)) {
            this.persons = [];
        }

        if (this.isInsuredPersonIsInsured() && !this.insuredWatcher) {
            this.watchForInsuredPerson();
        }

        // удаляем лишних застрахованных при смене продукта
        // (если новый продукт не предусматривает текущее кол-во застрахованных)
        this.$scope.$watch(() => this.getInsuredPersonsMaxCount(), () => {
            const maxCount = this.getInsuredPersonsMaxCount();
            if (this.persons.length > maxCount) {
                this.persons.splice(maxCount - 1, this.persons.length - maxCount);
            }
        });

        this.$scope.$watch(() => this.Repository.InsuredPersonIsInsured.State, (oldVal, newVal) => {
            if (newVal === 0 && oldVal === 1 && this.insuredPersonIsInsured) {
                if (this.insuredWatcher) {
                    this.insuredWatcher();
                }
                this.insuredPersonIsInsured = false;
                this.removeInsuredPerson(0);
            }
        });

        if (
            Array.isArray(this.contract.persons)
            && this.contract.persons.length > 0
            && this.getCurrentSubProduct() === "ImmigrantVHI"
        ) {
            const person = this.contract.persons[0];
            if (
                person.InsuredAddressFiasId !== this.contract.PropertyAddressFiasId
                || this.contract.PropertyAddressUserDefinedAddress !== person.InsuredAddressUserDefinedAddress
                || person.InsuredAddressFiasId === null
            ) {
                this.insuredPersonAddressEqualPropertyAddress = false;
            }
        }

        const $templateCache = this.di<ITemplateCacheService>("$templateCache");
        /* tslint:disable:max-line-length */
        const insuredTemplates = {
            "app/boxVHI/components/insured-person/insuredPersonAntiMiteVHI.component.html": insuredPersonAntiMiteVHITemplate,
            "app/boxVHI/components/insured-person/insuredPersonImmigrantVHI.component.html": insuredPersonImmigrantVHITemplate,
            "app/boxVHI/components/insured-person/insuredPersonStudentVHI.component.html": insuredPersonStudentVHITemplate,
        };
        /* tslint:enable:max-line-length */
        angular.forEach(insuredTemplates, (tpl, url) => {
            $templateCache.put(url, tpl);
        });
    }

    /**
     * @description Функция, которая запускает $watch для застрахованого, который является страхователем
     */
    public watchForInsuredPerson(): void {
        this.insuredWatcher = this.$scope.$watch(() => {
            let hash = "";
            for (const prop in this.persons[0]) {
                if (prop !== "Guid") {
                    hash = hash.concat(this.contract[prop.replace("Person", "")]);
                }
            }
            return hash;
        }, () => {
            if (this.persons.length && (this.persons[0].Guid !== this.contract.InsuredGuid)) {
                this.insuredWatcher();
                return;
            }

            for (const prop in this.persons[0]) {
                if (["Guid", "SpecialNote"].indexOf(prop) === -1) {
                    const targetProp = prop.replace("Person", "");
                    if (this.contract.hasOwnProperty(targetProp)) {
                        this.persons[0][prop] = this.contract[targetProp];
                    }
                }
            }
        });
    }

    public changeInsuredPersonAddressHasNotFiasInfo(): void {
        this.validationService.removeError("InsuredPersonAddressUserDefinedAddress");
    }

    public $doCheck(): void {
        if (this.persons.length === 0) {
            this.addInsuredPersons();
        }
    }

    public getBirthdateMinDate(): Day {
        return moment().subtract(this.Repository.InsuredPersonMaxAge.Value[0].Value, "years").format("YYYY-MM-DD");
    }

    public getBirthdateMaxDate(): Day {
        return moment().subtract(this.Repository.InsuredPersonMinAge.Value[0].Value, "years").format("YYYY-MM-DD");
    }

    /**
     * Возвращает последовательность с допустимыми значениями (количества) для добавления списка
     */
    public getAddRange(): number[] {
        return Array.apply(null, Array(this.getInsuredPersonsMaxCount() - this.persons.length)).map((v, i) => i + 1);
    }

    /**
     * @description Добавление новых "Застрахованных" в обший список.
     * Фокус устанавливается на первый элемент добовляемого списка.
     */
    public addInsuredPersons() {
        Array.prototype.push.apply(
            this.persons,
            Array.apply(this.persons, Array(this.numberOfEntries)).map(() => this.getInsuredPersonObject()),
        );

        if (this.persons.length > 1) {
            const anchorToElement = this.di<TAnchorToElementFn>("anchorToElement");
            anchorToElement("InsuredPersonLastName".concat(
                this.persons[this.persons.length - this.numberOfEntries].Guid,
            ));
        }

        if (this.canAddMore()) {
            this.numberOfEntries = 1;
        }
    }

    public removeInsuredPerson(index: number): void {
        this.persons.splice(index, 1);
        if (this.canAddMore()) {
            this.numberOfEntries = 1;
        }

        if (index === 0 && this.insuredPersonIsInsured) {
            this.insuredPersonIsInsured = false;
        }
    }

    public canAddMore(): boolean {
        return this.persons.length < this.getInsuredPersonsMaxCount();
    }

    public getInsuredPersonsMaxCount(): number {
        return this.Repository.InsuredPersonsMaxCount.Value[0].Value;
    }

    /**
     * @description Возвращает "код" текущего под-продукта
     */
    public getCurrentSubProduct(): string {
        const item = this.Repository.InsuranceProduct.Collection.find((product) => {
            return product.InsuranceProductId === this.contract.InsuranceProductId;
        });

        return (item) ? item.InsuranceProduct : undefined;
    }

    public isIndividualInsuranceCheckboxDisabled(): boolean {
        return !this.isInsuredPersonIsInsured()
            && (this.persons.length === this.getInsuredPersonsMaxCount() && this.getInsuredPersonsMaxCount() > 1);
    }

    public isInsuredPersonIsInsured(): boolean {
        return this.persons.length > 0 ? this.contract.InsuredGuid === this.persons[0].Guid : false;
    }

    public isEmptyPerson(person: IPerson): boolean {
        return !person.InsuredPersonLastName && !person.InsuredPersonFirstName && !person.InsuredPersonMiddleName;
    }

    public insuredPersonIsInsuredChanged(): void {
        if (this.isInsuredPersonIsInsured()) {
            if (this.insuredWatcher) {
                this.insuredWatcher();
            }
            this.removeInsuredPerson(0);
        } else {
            if (this.persons.length > 0 && this.persons[0].Guid !== this.contract.InsuredGuid) {
                const person = this.getInsuredPersonObject();
                person.Guid = this.contract.InsuredGuid;

                if (this.getInsuredPersonsMaxCount() > 1) {
                    if (this.persons.length === 1 && this.isEmptyPerson(this.persons[0])) {
                        this.persons = [person];
                    } else {
                        this.persons.unshift(person);
                    }
                } else {
                    this.persons = [person];
                }
            }
            this.watchForInsuredPerson();
        }
    }

    public getInsuredPersonObject(): IPerson {
        const birthDay = moment().subtract(30, "years").format("YYYY-MM-DD");
        const field = this.Repository.InsuredPersonDocType;
        const InsuredPersonDocTypeId = field.Value && field.Value.length > 0 ? field.Value[0].Value : null;
        switch (this.getCurrentSubProduct()) {
            case "ImmigrantVHI":
                return new ImmigrantVHIPerson(birthDay, null, InsuredPersonDocTypeId);
            case "StudentVHI":
                return new StudentVHIPerson(birthDay, null, InsuredPersonDocTypeId);
        }
        return new AntiMiteVHIPerson(birthDay);
    }

    public uploadExcelFile(): void {
        if (this.contract.isLocked()) {
            return;
        }
        const $uibModal = this.di<IModalService>("$uibModal");
        this.uploadLoading = true;
        $uibModal.open({
            controller: InsuredPersonUploadComponentController,
            controllerAs: "vm",
            size: "sm",
            template: insuredPersonUploadTemplate,
        }).result.then((data: {
            $data: IExcelUploadData[],
        }) => {
            const persons = [];
            /* tslint:disable */
            for (let i = 0; i < data.$data.length; i++) {
                const p = this.getInsuredPersonObject();
                p.InsuredPersonLastName = data.$data[i]["Фамилия"] || "";
                p.InsuredPersonFirstName = data.$data[i]["Имя"] || "";
                p.InsuredPersonMiddleName = data.$data[i]["Отчество"] || "-";
                p.SpecialNote = data.$data[i]["Особые отметки"] || "";
                p.InsuredPersonBirthday = data.$data[i]["Дата рождения"]
                    ? moment(data.$data[i]["Дата рождения"], "YYYY-MM-DD").format("YYYY-MM-DD")
                    : null;

                if (this.getCurrentSubProduct() === "StudentVHI") {
                    (p as StudentVHIPerson).InsuredPersonDocNumber = data.$data[i]["Номер паспорта иностр. гр-на"] || "";

                    (p as StudentVHIPerson).InsuredPersonDocDateGiven = data.$data[i]["Дата выдачи"]
                        ? moment(data.$data[i]["Дата выдачи"], "YYYY-MM-DD").format("YYYY-MM-DD")
                        : null;

                    const countryName = data.$data[i]["Гражданство"]
                        ? data.$data[i]["Гражданство"].toLowerCase()
                        : null;

                    if (countryName) {
                        const country = this.Repository.InsuredPersonCountry.Collection.find((item) => {
                            return item.Country.toLowerCase() === countryName;
                        });
                        if (country) {
                            (p as StudentVHIPerson).InsuredPersonCountryId = country.CountryId;
                        }
                    }
                }

                if (persons.length < this.getInsuredPersonsMaxCount()) {
                    persons.push(p);
                } else {
                    break;
                }
            }
            /* tslint:enable */
            this.persons = persons;
            const notifyService = this.di<NotifyService>("notifyService");
            notifyService.successMessage(
                "Импорт списка застрахованных",
                `"Список успешно импортирован (${persons.length})`,
            );
        }).finally(() => {
            this.uploadLoading = false;
        });
    }

    public setInsuredPersonGenderValue(person: ImmigrantVHIPerson, value: string): void {
        if (person && value) {
            person.InsuredPersonGender = value;
            const errorName = `InsuredPersonGender${person.Guid}`;
            if (this.validationService.hasError(errorName)) {
                this.validationService.removeError(errorName);
            }
        }
    }

    public getTemplateUrl(): string {
        const prefix = "app/boxVHI/components/insured-person/insuredPerson";
        const sufix = ".component.html";
        return prefix.concat(this.getCurrentSubProduct(), sufix);
    }

    public formatCountryLabel(CountryId: number): string {
        const item = this.Repository.InsuredPersonCountry.Collection.find((i) => {
            return CountryId === i.CountryId;
        });

        return item ? item.Country : "";
    }

    public getExcelTemplateFile(): void {
        const url = this.excelTemplates[this.getCurrentSubProduct()];
        const $window = this.di<IWindowService>("$window");
        $window.open(url, "_blank");
    }

}

export default angular.module("app.boxVHI.components.insuredPerson", [ugskDateTimePicker])
.component("insuredPerson", {
    bindings: {
        contract: "<",
        persons: "=ngModel",
    },
    controller: InsuredPersonComponentController,
    controllerAs: "vm",
    template,
}).name;
