import angular from "angular";

export function AutocompleteOffDirective(): ng.IDirective {
    "ngInject";

    return {
        link: (
            scope,
            element,
            attributes: IAttributes,
        ) => {
            const domElement = element[0];
            if (domElement.tagName === "INPUT") {
                if (attributes.autocomplete === "off") {
                    const autoFillCatcher = angular.element(`
                        <input type="${attributes.type}" name="${attributes.name}" style="display: none" />
                    `);
                    angular.element(element).parent().prepend(autoFillCatcher);
                }
            }
        },
        restrict: "A",
    };
}
