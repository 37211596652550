import { IModalService, IModalServiceInstance } from "angular-ui-bootstrap";
import INotificationProvider, { INotificationProviderPopParams } from "infrastructure/interfaces/INotificationProvider";
import "../popupNotification.css";
import popupNotificationTemplate from "../popupNotification.html";

export class NotifyModalProvider implements INotificationProvider {
    constructor(private $uibModal: IModalService) {
        "ngInject";
    }
    public pop({
        title,
        body,
        type,
    }: INotificationProviderPopParams) {
        return this.$uibModal.open({
            controller: class {
                constructor(private $uibModalInstance: IModalServiceInstance) {}
                public getBody(): string {
                    return body.replace(/\n/g, "<br />");
                }
                public getType(): string {
                    return (type || "info");
                }
                public close() {
                    this.$uibModalInstance.close();
                }
                static get $inject() {
                    return ["$uibModalInstance"];
                }
            },
            controllerAs: "vm",
            resolve: {
                body: () => body,
                title: () => title,
                type: () => type,
            },
            template: popupNotificationTemplate,
            windowClass: "modal-popup-notification",
        }).result;
    }
}
