import angular from "angular";
import "eonasdan-bootstrap-datetimepicker";
import IScope from "infrastructure/interfaces/IScope";
import { NgComponentController } from "infrastructure/NgController";
import $ from "jquery";
import moment from "moment";
import "moment/locale/ru";
import template from "./ugskDateTimePicker.component.html";

export class UgskDateTimePickerController extends NgComponentController {
    public displayTimeZone = false;
    public maxDate: string;
    public minDate: string;
    public ngChange: any;
    public ngDisabled: boolean;
    public onInvalidDate: any;
    public withTime: boolean;
    public inputMask = "00.00.0000 00:00";
    private inputElement: JQLite;
    private internalValue: moment.Moment;
    private instance: any;

    //  MomentDate -> String "2017-11-29T18:00:00+05:00"
    get ngModel() {
        if (this.internalValue) {
            return this.internalValue.format();
        }
        return null;
    }
    //  String "2017-11-29T18:00:00+05:00" -> Date
    set ngModel(newVal) {
        if (newVal) {
            const parsed = moment.parseZone(newVal);
            if (parsed.isValid()) {
                this.internalValue = parsed;
                return;
            }
        }
        this.internalValue = null;
    }

    public onInit() {
        let format: string;
        if (this.withTime === false) {
            format = "DD.MM.YYYY";
            this.inputMask = "00.00.0000";
        }

        this.inputElement = $(this.$element).find("input");

        this.$watch(() => this.ngModel, () => {
            this.$scope.$applyAsync((scope: IScope) => {
                scope.vm.instance.date(scope.vm.internalValue);
            });
        });

        this.$watch(() => this.maxDate, () => this.updateMaxMinDate());
        this.$watch(() => this.minDate, () => this.updateMaxMinDate());
        this.$watch(() => this.ngDisabled, () => this.updateMaxMinDate());

        (this.inputElement as any).datetimepicker({
            date: this.internalValue,
            format,
            keepInvalid: angular.isFunction(this.onInvalidDate),
            locale: "ru",
        }).on("dp.change", ({
            date: momentDate,
        }) => {
            if (angular.isFunction(this.onInvalidDate)) {
                momentDate = this.onInvalidDate({
                    value: momentDate,
                });
            }
            if (angular.isFunction(this.ngChange)) {
                this.ngChange({
                    newVal: moment.parseZone(momentDate),
                    oldVal: moment.parseZone(this.internalValue),
                });
            }
            this.$scope.$applyAsync((scope: IScope) => {
                scope.vm.internalValue = momentDate || null; // eslint-disable-line
            });
        })
        this.instance = this.inputElement.data("DateTimePicker");
    }

    public getTimeZone() {
        if (!this.internalValue) {
            return moment().format("Z");
        }
        return this.internalValue.format("Z");
    }

    private updateMaxMinDate() {
        const storedValue = this.internalValue;
        this.instance.maxDate(false);
        this.instance.minDate(false);
        if (this.ngDisabled) {
            return;
        }
        const newMaxDate = this.maxDate ? moment.parseZone(this.maxDate) : null;
        const newMinDate = this.minDate ? moment.parseZone(this.minDate) : null;
        if (newMaxDate && newMaxDate.isValid()) {
            this.instance.maxDate(newMaxDate.clone());
        }
        if (newMinDate && newMinDate.isValid()) {
            if (newMaxDate && newMaxDate.isValid()) {
                this.instance.minDate(newMinDate.isBefore(newMaxDate) ? newMinDate.clone() : false);
            } else {
                this.instance.minDate(newMinDate.clone());
            }
        }
        this.instance.date(storedValue);
    }
}

const ugskDateTimePicker = {
    bindings: {
        displayTimeZone: "<?",
        maxDate: "<",
        minDate: "<",
        ngChange: "&?",
        ngDisabled: "<",
        ngModel: "=",
        onInvalidDate: "&?",
        withTime: "<?",
    },
    controller: UgskDateTimePickerController,
    controllerAs: "vm",
    template,
};

export default angular.module("ugsk.components.date-time-picker", [])
    .component("ugskDateTimePicker", ugskDateTimePicker)
    .name;
