import { IODataResourceResult } from "infrastructure/interfaces";
import { Int } from "infrastructure/types";

export default class ODataResourceResult<T> extends Array<T> implements IODataResourceResult<T> {
    public "@odata.context": string = "";
    public "@odata.count": Int;
    constructor(
        public value: T[],
        public $promise: angular.IPromise<IODataResourceResult<T>>,
    ) {
        super();
        this["@odata.context"] = "";
        this["@odata.count"] = value.length;
    }
}
