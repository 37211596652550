import angular from "angular";
/**
 * @abstract
 * @description класс, реализующий логику обновления данных из FR
 */

const repositoryKey = Symbol("repositoryKey");
export class BaseRepository {
    public setFromRepository(data) {
        const $this = this;
        angular.forEach(data, (element) => {
            if (element.Value) {
                angular.forEach(element.Value, (collectionElement) => {
                    $this[collectionElement.FieldName] = collectionElement.Value;
                });
            } else if (element.Value === null) {
                /*
                * Для случая, когда сбрасываем значение в дефолтное состояние, которое
                */
                if ($this.hasOwnProperty(element.FieldName + "Id")) {
                    $this[element.FieldName + "Id"] = null;
                }
            }
        });
    }

    public setRepository(repository) {
        this[repositoryKey] = repository;
    }

    public getRepository() {
        return this[repositoryKey];
    }
};

export const getRepositoryDefaults = (repository) => {
    return repository.reduce((accumulator, fieldObject) => {
        const valueArray = fieldObject.Value;
        if (angular.isArray(valueArray)) {
            angular.forEach(valueArray, (item) => {
                accumulator[item.FieldName] = item.Value;
            });
        }
        return accumulator;
    }, {});
};
