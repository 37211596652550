import { StateParams, StateService } from "@uirouter/core";
import angular from "angular";
import { IUGSKLocalStorage, StateRegistryPatched } from "infrastructure/interfaces";
import { EnvService } from "infrastructure/services/env.service";
import { LoginController } from "./login.controller";

interface ILoginStateParams extends StateParams {
    error: string;
    access_token: string;
    expires_in: string;
    id_token: string;
    refresh_token: string;
    token_type: string;
}

export default angular.module("app.login", []).run(($stateRegistry: StateRegistryPatched) => {
    $stateRegistry.register({
        name: "login",
        url: "/login??returnTo??returnToParams??assistanceSAS",
        views: {
            "topbar@": {},
            "body@": {
                controller: LoginController,
                controllerAs: "vm",
                template: require("./login.html"),
            },
        },
        data: {
            anonymous: true,
        },
    });
    $stateRegistry.register({
        name: "login.callback",
        url: "/callback?id_token?access_token?refresh_token?token_type?expires_in?error",
        views: {
            "topbar@": {},
            "body@": {
                template: "<h1>Авторизация...</h1>",
                controller: function(
                    $stateParams: ILoginStateParams,
                    $localStorage: IUGSKLocalStorage,
                    $state: StateService,
                    envService: EnvService,
                ) {
                    "ngInject";

                    if ($stateParams.error) {
                        return $state.go("error", {
                            error: "auth-error",
                            error_description: $stateParams.error,
                        });
                    }
                    if (!$stateParams.access_token) {
                        return $state.go("error", {
                            error: "auth-error.no-token",
                            error_description: "Произошла ошибка аутентификации - не получен токен доступа"
                        });
                    }
                    $localStorage.tokenData = {
                        access_token: $stateParams.access_token,
                        expires_in: $stateParams.expires_in,
                        id_token: $stateParams.id_token,
                        receivingTokenDate: new Date(),
                        refresh_token: $stateParams.refresh_token,
                        token_type: $stateParams.token_type,
                    };

                    const forceDashboardLogin = envService.read("forceDashboardLogin");
                    const dashboardFrontLink = envService.read("dashboardFrontLink");
                    const dashboardFrontEnabled = envService.read("dashboardFrontEnabled");
                    if (forceDashboardLogin && dashboardFrontEnabled && dashboardFrontLink
                        && [undefined, "app.index"].includes($localStorage.redirectTo)) {
                        $state.go("ef", {});
                        return;
                    }

                    $state.go($localStorage.redirectTo, angular.fromJson($localStorage.redirectToParams)).catch(() => {
                        $state.go("app.index", {});
                    });
                },
            },
        },
        data: {
            anonymous: true,
        },
    });
}).name;
