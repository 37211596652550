import { StateService } from "@uirouter/core";
import { IPaymentRegistriesResource, IPaymentRegistry } from "application/paymentsRegistries/interfaces";
import { Helpers } from "infrastructure/app.helpers";
import { NgComponentController } from "infrastructure/NgController";
import { NotifyService } from "infrastructure/services/notifyService";
import { ValidationService } from "infrastructure/services/validation.service";
import template from "./ugskPaymentsRegistriesPageToolbar.component.html";

class PaymentsRegistriesPageToolbarController extends NgComponentController {
    public registry: IPaymentRegistry;

    public print(): void {
        const paymentRegistriesResource = this.di<IPaymentRegistriesResource>("paymentRegistriesResource");
        const helpers = this.di<Helpers>("helpers");
        paymentRegistriesResource.getPrintable(this.registry).$promise.then(helpers.downloadBlob);
    }

    public save() {
        if (!this.registry.FactPayments.length) {
            return;
        }
        const notifyService = this.di<NotifyService>("notifyService");
        const validationService = this.di<ValidationService>("validationService");
        const helpers = this.di<Helpers>("helpers");
        const $state = this.di<StateService>("$state");
        return helpers.confirm({
            text: `Вы действительно хотите сохранить реестр (всего взносов: ${this.registry.FactPayments.length}) ?`,
        }).then(() => {
            validationService.clear();
            this.mask("Сохранение реестра");
            return this.registry.$save().then((registry) => {
                notifyService.successMessage("Реестр создан");
                return $state.go("app.paymentsRegistries.item", {
                    id: registry.Id,
                });
            }).finally(() => {
                this.unmask();
            });
        }).catch(() => {/** */});
    }
}

export const ugskPaymentsRegistriesPageToolbarComponent = {
    bindings: {
        registry: "<ngModel",
    },
    controller: PaymentsRegistriesPageToolbarController,
    controllerAs: "vm",
    template,
};
