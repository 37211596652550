import { Transition } from "@uirouter/core";
import { MyChoiceToolbarController } from "../myChoice-toolbar.controller";

export class MyChoiceJournalToolbarController extends MyChoiceToolbarController {
    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
    ) {
        super($injector, $transition, {
            viewType: "journal",
        });
    }
}
