export function TrimValueDirective(): ng.IDirective {
    "ngInject";

    return {
        link: (
            scope: ng.IScope,
            element: ng.IAugmentedJQuery,
        ) => {
            element.bind("blur", () => {
                const value = String(element.val() || "");
                element.val(value.trim());
            });
        },
        require: "ngModel",
        restrict: "A",
    };
}
