import { StateService } from "@uirouter/core";
import { BaseJournalController } from "application/journal/baseJournal.controller";
import { JournalColumnType } from "application/journal/types";
import { IPaymentRegistriesResource, IPaymentRegistry } from "application/paymentsRegistries/interfaces";
import { UgskSet } from "domain/classes/ugsk-set.class";
import { Helpers } from "infrastructure/app.helpers";
import { IODataResourceResult, IPaymentKind } from "infrastructure/interfaces";
import IBlockUI from "infrastructure/interfaces/IBlockUI";
import { PaymentKind } from "infrastructure/interfaces/WebApi/PaymentKind";
import { DictionariesService } from "infrastructure/services/dictionaries.service";
import { Int } from "infrastructure/types";
import { NgTableParams as NgTableParamsType } from "ng-table";
import template from "./ugskPaymentsRegistriesPage.component.html";

type IPaymentRegistryOdata = IODataResourceResult<IPaymentRegistry>;

class PaymentsRegistriesPageController extends BaseJournalController {
    public tableParams: NgTableParamsType<IPaymentRegistry>;
    public selection: UgskSet<IPaymentRegistry>;
    public tableColumns: JournalColumnType[];
    public paymentRegistriesResource: IPaymentRegistriesResource;
    public registries: IPaymentRegistryOdata;
    public tableOrder: {
        direction: string;
        by: string;
    };

    public $onInit(): void {
        this.selection = new UgskSet<IPaymentRegistry>();
        let PaymentKinds: IPaymentKind[];

        const getFactPaymentsTotalAmount = (FactPayments) => {
            return FactPayments.reduce((total, payment) => total + payment.Amount, 0);
        };
        const getPaymentKindNameById = (id: Int): string => {
            const paymentKind = PaymentKinds.find((item) => item.Id === id);
            return (paymentKind) ? paymentKind.Name : "";
        };
        const [thousandFilter] = this.di(["thousandFilter"]);
        const dictionariesService = this.di<DictionariesService>("dictionariesService");
        dictionariesService.get("PaymentKinds").then((values) => {
            PaymentKinds = values;
        });

        this.tableColumns = [{
            name: "Id",
            title: "Порядковый номер",
        }, {
            name: "RegistryNumber",
            title: "Номер",
        }, {
            name: "PeriodFrom",
            ngFilter: "dateNoTz: 'DD.MM.YYYY'",
            title: "С",
        }, {
            name: "PeriodTo",
            ngFilter: "dateNoTz: 'DD.MM.YYYY'",
            title: "По",
        }, {
            name: "AgentCardNumber",
            title: "Номер карты агента",
        }, {
            template: "<span ng-bind='item.Employee.Name'></span>",
            title: "Создал",
        }, {
            template: "<span ng-bind='item.FactPayments.length'></span>",
            title: "Количество взносов",
        }, {
            renderer: (item): string => {
                const value = thousandFilter(getFactPaymentsTotalAmount(item.FactPayments));
                return `<span class="no-wrap">${value}&nbsp;<i class='fa fa-rub'></i></span>`;
            },
            title: "Сумма взносов",
        }, {
            renderer: (item) => {
                return `<span>${getPaymentKindNameById(item.FactPayments[0].PaymentKindId)}</span>`;
            },
            title: "Вид оплаты",
        }];

        this.paymentRegistriesResource = this.di<IPaymentRegistriesResource>("paymentRegistriesResource");
        const NgTableParamsClass = this.di<typeof NgTableParamsType>("NgTableParams");
        const blockUI = this.di<IBlockUI>("blockUI");
        const $q = this.di<angular.IQService>("$q");

        this.tableParams = new NgTableParamsClass<IPaymentRegistry>({
            count: 15,
            page: 1,
        }, {
            counts: [],
            getData: (params: NgTableParamsType<IPaymentRegistry>) => {
                const request = this.getRequest();
                if (this.tableOrder && this.tableOrder.by) {
                    request.orderBy(this.tableOrder.by, this.tableOrder.direction);
                }
                blockUI.start("Загрузка реестров");
                request
                    .take(params.count())
                    .skip((params.page() - 1) * params.count())
                    .withInlineCount();
                return $q<IPaymentRegistryOdata>((resolve) => {
                    request.query((registries: IPaymentRegistryOdata) => {
                        this.registries = registries;
                        resolve(registries);
                        params.total(registries["@odata.count"]);
                        blockUI.stop();
                    });
                });
            },
        });
    }

    get currentRegistry() {
        return this.selection.first();
    }

    public getRequest() {
        return this.paymentRegistriesResource.odata()
            .expandPredicate("Employee")
            .select("Name")
            .finish()
            .expandPredicate("FactPayments")
            .finish();
    }

    public getSelectedRegistryUrl(): string {
        if (!this.currentRegistry) {
            return "";
        }
        const $state = this.di<StateService>("$state");
        return $state.href("app.paymentsRegistries.item", {
            id: this.currentRegistry.Id,
        });
    }

    public openRegistry(): void {
        const $state = this.di<StateService>("$state");
        $state.go("app.paymentsRegistries.item", {
            id: this.currentRegistry.Id,
        });
    }

    public print(): void {
        const helpers = this.di<Helpers>("helpers");
        this.paymentRegistriesResource.getPrintable(this.currentRegistry).$promise.then(helpers.downloadBlob);
    }
}

export const ugskPaymentsRegistriesPageComponent = {
    controller: PaymentsRegistriesPageController,
    controllerAs: "vm",
    template,
};
