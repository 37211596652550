import angular from "angular";
import INotificationProvider, { INotificationProviderPopParams } from "infrastructure/interfaces/INotificationProvider";

export interface IStaticNotification extends INotificationProviderPopParams {
    displayCondition?: () => boolean;
}

export class NotifyStaticProvider implements INotificationProvider {
    private notifications: IStaticNotification[] = [];
    public getStaticNotifications() {
        return this.notifications;
    }
    public hasStaticNotifications() {
        return this.notifications.length > 0;
    }
    public clearStaticNotifications() {
        this.notifications.length = 0;
    }
    public pop(data: IStaticNotification) {
        if (data.type === "error") {
            data.type = "danger";
        }
        if (!angular.isFunction(data.displayCondition)) {
            data.displayCondition = () => true;
        }
        this.notifications.push(data);
    }
    public set(data: IStaticNotification) {
        this.clearStaticNotifications();
        this.notifications.push(data);
    }
}
