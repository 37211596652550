import angular from "angular";
import { IEdmOperation, UgskODataService } from "./ugskOData.service";

export class UgskTableFilterService {
    public static getFilterString(filters: any[], options?: object): string | undefined {
        options = options || {};
        const filterChunks = [];
        const validFalsyValues = [0, false];
        angular.forEach(filters, (filter) => {
            const type = UgskODataService.getType(filter.type);
            const option = type.options[filter.option];
            if (!option) {
                return;
            }
            const operation = option.operation;
            const values = filter.values;
            if (!values.length) {
                return;
            }
            const normalisedValues = values.map(type.normalisedValue);
            let ops: IEdmOperation[];
            if (!angular.isArray(operation)) {
                ops = [operation];
            } else {
                ops = operation;
            }
            ops.forEach((innerOperation: IEdmOperation, index: number) => {
                const value = normalisedValues[index];
                if (!value && !validFalsyValues.includes(value)) {
                    return;
                }
                if (angular.isFunction(innerOperation)) {
                    filterChunks.push(innerOperation(filter.name, value));
                } else if (angular.isString(innerOperation)) {
                    filterChunks.push([filter.name, innerOperation, value].join(" "));
                } else {
                    throw new Error("Unknown operation type");  //  или просто пропустить?
                }
            });
        });
        const filterStr = filterChunks.join(" and ");
        return filterStr ? filterStr : undefined;
    }
}
