import { StateService } from "@uirouter/angularjs";
import angular, { ITimeoutService, IWindowService } from "angular";
import { IModalService, IModalServiceInstance } from "angular-ui-bootstrap";
import UGSKBlob from "domain/classes/blob.class";
import { IUGSKLocalStorage } from "infrastructure/interfaces/IUGSKLocalStorage"
import jQuery from "jquery";
import { Uuid } from "lib/uuid";
import { EmployeeService } from "./services/employee.service";
import { EnvService } from "./services/env.service";
import openUrlInIframeTemplate from "./templates/openUrlInIframe.html";

export function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function activateParentTab(targetElement: JQLite) {
    return new Promise((resolve) => {
        const tabParents = targetElement.parents(".tab-pane");
        if (tabParents.length) {
            const index = tabParents.first().index() + 1;
            const li = jQuery(`#uib-tab-docking .nav.nav-pills li:nth-child(${index}) a`);
            if (li) {
                li.click();
            }
        }
        setTimeout(() => resolve());
    });
}

export type TAnchorToElementFn = (element: JQLite | string) => void;
anchorToElement.$inject = ["$timeout", "$window"];
function anchorToElement($timeout: ITimeoutService, $window: IWindowService): TAnchorToElementFn {
    "ngInject";

    return (element: JQLite | string) => {
        if (!element) {
            return;
        }
        if (!element.length) {
            return;
        }
        $timeout(() => {
            let actualElement: JQLite;
            if (angular.isString(element)) {
                const id = element;
                const element2 = $window.document.getElementById(id);
                actualElement = angular.element(element2);
                if (actualElement.length === 0) {
                    return;
                }
            } else {
                actualElement = element;
            }
            activateParentTab(actualElement).then(() => {
                angular.element("html, body")
                    .animate({ scrollTop: actualElement.offset().top - 200 }, "slow", () => actualElement.focus());
            });
        });
    };
}

focusToElement.$inject = ["$timeout", "$window"];
function focusToElement($timeout: ITimeoutService, $window: IWindowService) {
    "ngInject";
    return (id: string) => {
        $timeout(() => {
            const element = $window.document.getElementById(id);
            if (element) {
                element.focus();
            }
        });
    };
}
const isIE = window.navigator.msSaveBlob && window.navigator.msSaveOrOpenBlob;
let a: HTMLAnchorElement;
if (!isIE) {
    a = document.createElement<"a">("a");
    a.style.display = "none";
    document.body.appendChild(a);
}

export class Helpers {
    static get $inject() {
        return [
            "$uibModal", "$state", "employeeService", "$window",
            "envService", "$localStorage",
        ];
    }
    constructor(
        private $uibModal: IModalService,
        private $state: StateService,
        private employeeService: EmployeeService,
        private $window: angular.IWindowService,
        private envService: EnvService,
        private $localStorage: IUGSKLocalStorage) { }
    public confirm({
        text = "",
        title = "",
    } = {}) {
        return this.$uibModal.open({
            controller: class {
                public text: string;
                public title: string;
                constructor(private $uibModalInstance: IModalServiceInstance) {
                    "ngInject";
                    this.text = text;
                    this.title = (title) ? title : "Подтверждение";
                }
                public confirm() {
                    this.$uibModalInstance.close();
                }
                public cancel() {
                    this.$uibModalInstance.dismiss();
                }
            },
            controllerAs: "vm",
            template: `
                <div class="ibox">
                    <div class="ibox-title">
                        <h2 ng-bind="vm.title"></h2>
                    </div>
                    <div class="ibox-content"
                        ng-bind-html="vm.text"></div>
                    <div class="ibox-footer">
                        <div class="row">
                            <div class="col-xs-12">
                                    <div class="pull-right">
                                        <button class="btn btn-success"
                                            ng-click="vm.confirm()">Да</button>
                                        <button class="btn btn-danger"
                                            ng-click="vm.cancel()">Нет</button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            `,
        }).result;
    }
    public getUniqName() {
        return "prop" + Uuid.raw().replace(/-/g, "");
    }
    public stringFormat(template: string, ...values: string[]) {
        let result = template;
        for (let i = 0; i < values.length; i++) {
            // "gm" = RegEx options for Global search (more than one instance)
            // and for Multiline search
            const regEx = new RegExp("\\{" + i + "\\}", "gm");
            result = result.replace(regEx, values[i]);
        }

        return result;
    }

    public downloadBlob(blob: UGSKBlob) {
        if (isIE) {
            window.navigator.msSaveBlob(blob.blob, blob.filename);
        } else {
            a.href = blob.url;
            a.download = blob.filename;
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(blob.url);
            }, 1000);
        }
    }

    public changeUser(login: string): angular.IPromise<void> {
        return this.employeeService.getEmployee().then((employee) => {
            employee.impersonate(login);
        });
    }

    /**
     * @see https://angular-ui.github.io/bootstrap/#!#modal
     * @param {string} url Url
     * @param {string} title Modal title
     * @returns {angular.IPromise<any>} При закрытии модального окна только reject
     */
    public openUrlInIframe(url: string, title: string = "Печатная форма"): angular.IPromise<any> {
        return this.$uibModal.open({
            backdrop: "static",
            controller: class {
                public url: string = this.$sce.trustAsResourceUrl(url);
                public title: string = title;
                constructor(
                    private $uibModalInstance: IModalServiceInstance,
                    private $sce: ng.ISCEService,
                    private $window: ng.IWindowService) {}

                public get iframeStyle(): object {
                    return {
                        height: `${(this.$window.innerHeight - 250)}px`,
                    };
                }
                public close() {
                    this.$uibModalInstance.dismiss();
                }

                static get $inject() {
                    return ["$uibModalInstance", "$sce", "$window"];
                }
            },
            controllerAs: "vm",
            resolve: {
                url: () => url,
            },
            size: "80p",
            template: openUrlInIframeTemplate,
        }).result;
    }

    public openContract(contractId: number, productCode: string) {
        this.$state.go(`app.${productCode}.index`, {
            id: contractId,
        });
    }
    public getContractUrl(contractId: number, productCode: string) {
        return this.$state.href(`app.${productCode}.index`, {
            id: contractId,
        });
    }

    public showInformationWindow(body, title = null, size = "", data = null) {
        return this.$uibModal.open({
            controller: class {
                public title: string;
                public data: string;
                constructor(private $uibModalInstance: IModalServiceInstance) {
                    this.title = title;
                    this.data = data;
                }
                public close() {
                    this.$uibModalInstance.close();
                }
                static get $inject() {
                    return ["$uibModalInstance"];
                }
            },
            controllerAs: "vm",
            size,
            template: `
                <div class="inmodal">
                    <div class="modal-header" ng-if="vm.title">
                        <button type="button" class="close" ng-click="vm.close()">&times;</button>
                        <h4 class="modal-title" ng-bind="vm.title"></h4>
                    </div>
                    <div class="modal-body">${body}</div>
                    <div class="modal-footer">
                        <button class="btn btn-primary" type="button" ng-click="vm.close()">Закрыть</button>
                    </div>
                </div>
            `,
        }).result;
    }

    public isEmbedMode(): boolean {
        return this.$window.parent !== this.$window;
    }

    public getDashboardLink(auth = false): string {
        const dashboardFrontLink = this.envService.read("dashboardFrontLink");
        if (!auth) {
            return dashboardFrontLink;
        }

        return (dashboardFrontLink.endsWith("/") ? dashboardFrontLink : `${dashboardFrontLink}/`)
            + "uproFront/authorization.html#" + Object.keys(this.$localStorage.tokenData)
                .filter((key) => this.$localStorage.tokenData[key] !== undefined)
                .map((key) => `${key}=${encodeURI(this.$localStorage.tokenData[key])}`)
                .join("&");
    }

    public isProductDisabled(productCode: string): boolean {
        const disabledProducts: string[] = this.envService.read("disabledProducts");

        if (Array.isArray(disabledProducts) && typeof productCode === "string") {
            return disabledProducts.some((product) => product.toLowerCase() === productCode.toLowerCase());
        }

        return false;
    }
}

/**
 * Склонение числительных окончаний
 * @param string[] titles Список правильных слов с окончаниями для [1, 2, 5]
 * @param number amount Кол-во
 * Пример, (['Яблоко','Яблока','Яблок'], 0); -> Яблок
 */
export function declinationOfNumerals(titles: string[], amount: number) {
    const cases = [2, 0, 1, 1, 1, 2];
    amount = Math.abs(amount); // eslint-disable-line
    if (amount % 100 > 4 && amount % 100 < 20) {
        return titles[2];
    } else if (amount % 10 < 5) {
        return titles[cases[amount % 10]];
    }
    return titles[2];
}

export function unbreakWord(word: string): string {
    if (!word) {
        return "";
    }
    let ret = word;
    const replacements = [{
        re: /-/g,
        to: "&#8209;",
    }, {
        re: /\s/g,
        to: "&#160;",
    }];
    angular.forEach(replacements, (replacement) => {
        ret = ret.replace(replacement.re, replacement.to);
    });
    return ret;
}

export function toggleIBox(iBox: JQLite) {
    if (!iBox) {
        return;
    }
    const content = iBox.find("div.ibox-content");
    content.slideToggle();
    iBox.toggleClass("collapsed").toggleClass("border-bottom");
}

export function expandParentIBoxes(targetElement: JQLite) {
    return new Promise((resolve) => {
        let expanded = false;
        if (targetElement) {
            targetElement.parents("div.ibox").each((index, element) => {
                const $element = angular.element(element);
                if ($element.hasClass("collapsed")) {
                    toggleIBox($element);
                    expanded = true;
                }
            });
        }
        const time = (expanded) ? 350 : 0;
        setTimeout(() => resolve(), time);
    });
}

export function leaveOnlyNumbers(str: string) {
    return str.replace(/\D/g, "");
}

export default angular
    .module("ugsk.helpers", [])
    .service("helpers", Helpers)
    .factory("anchorToElement", anchorToElement)
    .factory("focusToElement", focusToElement)
    .name;
