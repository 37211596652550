import angular, { IRepeatScope } from "angular";
import moment from "moment";

import { BaseProductBodyController } from "../baseProduct.body.controller";
import { InsuredPersonUploadController } from "../modalDialog/insuredPersonUpload.controller";
import {
    ICurrency,
    IInsuranceProgram,
    IInsuredContractorType,
    IRestrictions,
    ITravelingAbroadDestination,
    TravelingAbroadContract,
    TravelingAbroadInsurantProxy,
    TravelingAbroadPerson,
} from "./travelingAbroad.factory";

import { IModalService } from "angular-ui-bootstrap";
import { Helpers } from "infrastructure/app.helpers";
import { ITouchSpinConfig } from "infrastructure/interfaces/ITouchSpinConfig";
import { NotifyService } from "infrastructure/services/notifyService";
import { Int } from "infrastructure/types";
import insuredPersonUploadModalTemplate from "../modalDialog/insuredPersonUpload.html";
import insuranceProgramFile from "./travelingAbroad_programs.pdf";
import XLSUploadTemplate from "./travelingAbroad_template.xlsx";

interface IDateOptions {
    startDate?: string;
    endDate?: string;
}

interface IMoneyIcon {
    "Доллар США"?: string;
    "Евро"?: string;
    "Российский рубль"?: string;
}

interface IDateSlider {
    from?: Int;
    grid?: boolean;
    hide_from_to?: boolean;
    hide_min_max?: boolean;
    prettify?: boolean;
    step?: Int;
    type?: string;
    max?: Int | string;
    min?: Int | string;
}

interface INgModelOptions extends angular.INgModelOptions {
    default?: Int;
}

interface IImportStruct {
    $data: TravelingAbroadPerson[];
}

export class TravelingAbroadController extends BaseProductBodyController {
    public tourCancellationProductRiskTimer: angular.IPromise<void> = null;
    public notifyService: NotifyService;
    public $interval: ng.IIntervalService;
    public insurant: TravelingAbroadInsurantProxy;
    public insuranceTermSliderGridNum: Int = 10;
    public maxInsuredPersons: Int = 100;
    public travelDateFromDateOptions: IDateOptions;
    public travelDateToDateOptions: IDateOptions;
    public insuredBirthdateConfig: IDateOptions;
    public registrationDateConfig: IDateOptions;
    public minBirthday: string;
    public maxBirthday: string;
    public minDateRegistration: string;
    public maxDateRegistration: string;
    public moneyIcon: IMoneyIcon;
    public dateSlider: IDateSlider;
    public sliderNgModelOptions: INgModelOptions;
    public coefficientTouchSpin: ITouchSpinConfig;
    public termDaysTouchSpin: ITouchSpinConfig;
    public Contract: TravelingAbroadContract;
    public isVisaObtainingBasisPopoverMessage: string;
    public currenctInsuranceProgram: IInsuranceProgram;
    // Параметр необходимый для сохранения выбранного элемента справочника
    // периода в контракт (для последующего расчета значений дат)
    public InsuranceProgramInsurancePeriodLengthRestrictions: IRestrictions;

    public $onInit() {

        super.$onInit();

        this.notifyService = this.di<NotifyService>("notifyService");
        this.$interval = this.di<ng.IIntervalService>("$interval");
        this.insurant = new TravelingAbroadInsurantProxy(this.Contract);
        this.Repository.DiscountRestrictions = { Collection: [] };
        this.Repository.MultipleTours = {
            Collection: [{
                IsProgramWithMultipleTours: true,
                ProgramWithMultipleToursName: "Многократный",
            }, {
                IsProgramWithMultipleTours: false,
                ProgramWithMultipleToursName: "Однократный",
            }],
        };

        this.travelDateFromDateOptions = {
            startDate: moment().add(1, "d").format("YYYY-MM-DD"),
        };

        this.travelDateToDateOptions = {
            startDate: moment().add(3, "d").format("YYYY-MM-DD"),
        };

        this.insuredBirthdateConfig = {
            endDate: moment().format("YYYY-MM-DD"),
        };

        this.registrationDateConfig = {
            endDate: moment().format("YYYY-MM-DD"),
        };

        this.minBirthday = moment().subtract(100, "years").format("YYYY-MM-DD");
        this.maxBirthday = moment().subtract(18, "years").format("YYYY-MM-DD");
        this.minDateRegistration = moment().subtract(100, "years").format("YYYY-MM-DD");
        this.maxDateRegistration = moment().format("YYYY-MM-DD");

        this.phoneValidationService.setContractRequiredFields({
            individual: [
                {
                    description: "Фамилия страхователя",
                    field: "InsuredLastName",
                }, {
                    description: "Имя страхователя",
                    field: "InsuredFirstName",
                }, {
                    description: "Дата рождения страхователя",
                    field: "InsuredBirthday",
                },
            ],
            legalEntity: [
                {
                    description: "Наименование организации страхователя",
                    field: "InsuredOrgName",
                },
                {
                    description: "ИНН организации страхователя",
                    field: "InsuredOrgINN",
                },
            ],
        });

        this.moneyIcon = {
            "Доллар США": "fa-usd",
            "Евро": "fa-eur",
            "Российский рубль": "fa-rub",
        };

        this.dateSlider = {
            from: this.Contract.InsuranceTermDays,
            grid: true,
            hide_from_to: false,
            hide_min_max: true,
            prettify: false,
            step: 1,
            type: "single",
        };

        this.sliderNgModelOptions = angular.copy(this.ngModelOptions);
        this.sliderNgModelOptions.debounce = { default: 1500, blur: 0 };

        this.coefficientTouchSpin = {
            boostat: 5,
            buttondown_class: "btn btn-white",
            buttonup_class: "btn btn-white",
            decimals: 2,
            delimiter: ",",
            forcestepdivisibility: "round",
            max: 9,
            maxboostedstep: 10,
            min: 1,
            step: 0.1,
        };

        this.getCurrentInsuranceProgram();

        if (this.Repository.InsuranceProgram && this.Contract.InsuranceProgramId) {
            this.termDaysTouchSpin = {
                boostat: 5,
                buttondown_class: "btn btn-success",
                buttonup_class: "btn btn-success",
                max: this.currenctInsuranceProgram.InsuranceProgramInsuranceTermRestrictions[0].MaxValueYears * 366
                    + this.currenctInsuranceProgram.InsuranceProgramInsuranceTermRestrictions[0].MaxValueDays,
                maxboostedstep: 10,
                min: this.currenctInsuranceProgram.InsuranceProgramInsuranceTermRestrictions[0].MinValueYears * 366
                    + this.currenctInsuranceProgram.InsuranceProgramInsuranceTermRestrictions[0].MinValueDays,
                step: 1,
            };
        }

        if (this.Repository.InsuranceProgram && this.Contract.InsuranceProgramId) {
            this.dateSlider.min =
                this.currenctInsuranceProgram.InsuranceProgramInsuranceTermRestrictions[0].MinValueYears * 366
                + this.currenctInsuranceProgram.InsuranceProgramInsuranceTermRestrictions[0].MinValueDays;
            this.dateSlider.max =
                this.currenctInsuranceProgram.InsuranceProgramInsuranceTermRestrictions[0].MaxValueYears * 366
                + this.currenctInsuranceProgram.InsuranceProgramInsuranceTermRestrictions[0].MaxValueDays;
        }

        const signingDate = moment(this.Contract.SigningDate, "YYYY-MM-DD").startOf("day");
        const current = moment().startOf("day");
        if (this.Contract.ContractStatusId === 1 && signingDate.isBefore(current)) {
            /* tslint:disable-next-line:max-line-length */
            this.notifyService.warningMessage("Внимание", "По данному договору устарела дата заключения. Рассчитайте договор заново для обновления даты заключения договора.");
        }

        this.insuredBirthdateConfig = {
            endDate: moment().add(-18, "y").format("YYYY-MM-DD"),
            startDate: moment().add(-100, "y").format("YYYY-MM-DD"),
        };

        if (!this.Contract.InsuredPersons || this.Contract.InsuredPersons.length === 0) {
            this.Contract.InsuredPersons = [];
            this.Contract.InsuredPersons.push(new TravelingAbroadPerson());
        }

        if (this.Repository && this.Repository.DiscountRestriction && this.Repository.DiscountRestriction.Collection) {
            let minDiscount = Number(this.Repository.DiscountRestriction.Collection[0].MinValue);
            const maxDiscount = Number(this.Repository.DiscountRestriction.Collection[0].MaxValue);
            for (minDiscount; minDiscount <= maxDiscount; minDiscount++) {
                if (minDiscount === 0) {
                    this.Repository.DiscountRestrictions.Collection.push({
                        DiscountRestrictions: "Без скидки",
                        DiscountRestrictionsId: minDiscount,
                    });
                } else {
                    this.Repository.DiscountRestrictions.Collection.push({
                        DiscountRestrictions: `${minDiscount} %`,
                        DiscountRestrictionsId: minDiscount,
                    });
                }
            }
        }

        /**
         * Наблюдение за коллекцией с "Медицинскими расходами". Пустая
         * коллекция - признак того, что текущее сочетание направления
         * и программы не страхуются.
         */
        /* eslint-disable no-underscore-dangle */
        this._$scope.$watch(
            /* tslint:disable-next-line:max-line-length */
            () => this.Repository.MedicalExpensesProductRiskAmount ? this.Repository.MedicalExpensesProductRiskAmount.Collection : undefined, // eslint-disable-line
            (newVal) => {
                /* tslint:disable-next-line:max-line-length */
                if (angular.isArray(this.Contract.TravelingAbroadDestination) && this.Contract.TravelingAbroadDestination.length > 0 && newVal.length === 0) {
                    /* tslint:disable-next-line:max-line-length */
                    this.notifyService.warningMessage("Внимание!", "Для выбранной комбинации программы и направления путешествия страхование не доступно");
                }
        });
    }

    protected get Repository() {
        return this.Contract.getRepository();
    }

    public programWithMultipleToursChanged() {
        return this.getFieldRepository("IsProgramWithMultipleTours").then(() => {
            this.Contract.TravelDatesAreNotKnown = this.Contract.IsProgramWithMultipleTours;
            if (this.Contract.IsProgramWithMultipleTours) {
                this.Contract.TravelDateFrom = null;
                this.Contract.TravelDateTo = null;
                /*
                 * TODO: Необходимо сделать доработки в рамках задачи 22474 (http://redmine.ugsk.ru/issues/22474)
                 * Решение задачи (22130) было перенесено на второй этап
                 */
                this.Contract.TourCancellationProductRisk = null;
                /* tslint:disable-next-line:max-line-length */
                if (this.currenctInsuranceProgram && this.currenctInsuranceProgram.InsuranceProgramInsurancePeriodLengthRestrictions) {
                    this.patchInsurancePeriodLengthRestrictionsDictionary();
                    this.changeContractToAndContractValidDays();
                }
            } else {
                this.Contract.ContractValidDays = null;
                this.Contract.ContractTo = null;
            }
        });
    }

    public isVisaObtainingBasisChanged() {
        if (this.Contract.TravelDateFrom) {
            /* tslint:disable-next-line:max-line-length */
            this.Contract.ContractFrom = this.Contract.IsVisaObtainingBasis ? this.getMinContractFrom() : this.Contract.TravelDateFrom;
        }
    }

    public isEnableIsVisaObtainingBasisPopover() {
        /* tslint:disable-next-line:max-line-length */
        let message = "Данная функция доступна, только если направление содержит одну или несколько стран Шенгенской зоны";

        if (this.Contract.InsuredPersons.length > 1) {
            /* tslint:disable-next-line:max-line-length */
            message = "Данная функция доступна для индивидуального страхования. Необходимо оставить одно застрахованное лицо";
        }

        if (!this.hasSchengenCountry() || this.Contract.InsuredPersons.length > 1) {
            this.isVisaObtainingBasisPopoverMessage = message;
            return true;
        }

        if (this.hasSchengenCountry() && this.Contract.InsuredPersons.length === 1) {
            this.isVisaObtainingBasisPopoverMessage = "Требуется Шенгенская виза";
            return true;
        }
        return false;
    }

    // tslint:disable-next-line:no-any
    public stopTimer(timer: angular.IPromise<any>) {
        if (angular.isDefined(timer)) {
            this.$interval.cancel(timer);
        }
    }

    public getCurrentInsuranceProgram() {

        if (this.Repository.InsuranceProgram) {
            /* tslint:disable-next-line:max-line-length */
            const item: IInsuranceProgram[] = this.Repository.InsuranceProgram.Collection.filter((value: IInsuranceProgram) => {
                return value.InsuranceProgramId === this.Contract.InsuranceProgramId;
            });

            if (item && item[0]) {
                [this.currenctInsuranceProgram] = item;

                if (this.currenctInsuranceProgram &&
                    this.currenctInsuranceProgram.InsuranceProgramInsurancePeriodLengthRestrictions) {
                    this.patchInsurancePeriodLengthRestrictionsDictionary();
                    this.getCurrentInsuranceProgramInsurancePeriodLengthRestrictions();
                }
                // расчет шага для сетки слайдера
                const maxValue =
                    this.currenctInsuranceProgram.InsuranceProgramInsuranceTermRestrictions[0].MaxValueYears * 366
                        + this.currenctInsuranceProgram.InsuranceProgramInsuranceTermRestrictions[0].MaxValueDays;
                const minValue =
                    this.currenctInsuranceProgram.InsuranceProgramInsuranceTermRestrictions[0].MinValueYears * 366
                        + this.currenctInsuranceProgram.InsuranceProgramInsuranceTermRestrictions[0].MinValueDays;
                this.insuranceTermSliderGridNum = Math.trunc((maxValue - minValue) / 7);
                return;
            }
        }

        this.currenctInsuranceProgram = null;
    }

    public getCurrentInsuranceProgramInsurancePeriodLengthRestrictions() {

        if (!this.InsuranceProgramInsurancePeriodLengthRestrictions
            || !this.currenctInsuranceProgram.InsuranceProgramInsurancePeriodLengthRestrictions
            .includes(this.InsuranceProgramInsurancePeriodLengthRestrictions)) {
            this.InsuranceProgramInsurancePeriodLengthRestrictions =
            this.currenctInsuranceProgram.InsuranceProgramInsurancePeriodLengthRestrictions.find((item) => {
                const contractTo
                = moment(this.Contract.ContractFrom).add(item.MaxValueYears, "y").add(item.MaxValueDays, "d");
                const contractValidDays = moment(contractTo).diff(this.Contract.ContractFrom, "d");

                const contractValidDaysMatch = this.Contract.ContractValidDays === contractValidDays;
                const insurancePeriodOneYear = this.Contract.ContractValidDays === 365 && item.MaxValueYears === 1;
                return contractValidDaysMatch || insurancePeriodOneYear;
            });
        }
        if (this.currenctInsuranceProgram
            && this.currenctInsuranceProgram.InsuranceProgramInsurancePeriodLengthRestrictions
            && !this.InsuranceProgramInsurancePeriodLengthRestrictions) {
            this.InsuranceProgramInsurancePeriodLengthRestrictions =
                this.currenctInsuranceProgram.InsuranceProgramInsurancePeriodLengthRestrictions
                [this.currenctInsuranceProgram.InsuranceProgramInsurancePeriodLengthRestrictions.length - 1];
        }
    }

    public getCurrentCurrency() {
        if (this.Repository.Currency) {
            const item: ICurrency[] = this.Repository.Currency.Collection.filter((value: ICurrency) => {
                return value.CurrencyId === this.Contract.CurrencyId;
            });

            if (item && item[0]) {
                return item[0];
            }
        }

        return null;
    }

    public getCurrencyRate() {
        const item = this.getCurrentCurrency();

        if (item) {
            return item.CurrencyCost;
        }
        return "";
    }

    public getCurrencyDate() {
        const item = this.getCurrentCurrency();

        if (item) {
            return `(на ${moment(item.CurrencyActualDate, "YYYY-MM-DD").format("DD.MM.YYYY")})`;
        }
        return "";
    }

    public getCurrencyIcon(): string {
        const item = this.getCurrentCurrency();
        if (item) {
            return this.moneyIcon[item.CurrencyName];
        }
        return "";
    }

    /**
     * @override
     */
    public getFieldRepository(fieldName: string) {
        return super.getFieldRepository(fieldName).finally(() => {
            this.getCurrentInsuranceProgram();
        });
    }

    public getMinContractFrom() {
        return moment(this.Contract.SigningDate, "YYYY-MM-DD").add(1, "d").format("YYYY-MM-DD");
    }

    public getMaxContractFrom() {
        if (this.Contract.TravelDatesAreNotKnown) {
            return moment(this.Contract.SigningDate, "YYYY-MM-DD").add(6, "M").add(-1, "d").format("YYYY-MM-DD");
        }
        return moment(this.Contract.TravelDateFrom, "YYYY-MM-DD").format("YYYY-MM-DD");
    }

    public getMinContractTo() {
        /* tslint:disable-next-line:max-line-length */
        const insuranceTermDays = parseInt((this.Contract.InsuranceTermDays as unknown as string), 10) + (this.hasSchengenCountry() ? 15 : 0) - 1;
        if (!this.Contract.TravelDatesAreNotKnown || this.Contract.IsProgramWithMultipleTours) {
            return null;
        }
        return moment(this.Contract.ContractFrom, "YYYY-MM-DD").add(insuranceTermDays, "d").format("YYYY-MM-DD");
    }

    public getMaxContractTo() {
        /* tslint:disable-next-line:max-line-length */
        if (!this.Contract.IsProgramWithMultipleTours && this.hasSchengenCountry() && this.Contract.TravelDatesAreNotKnown) {
            if (this.Contract.ContractFrom) {
                return moment(this.Contract.ContractFrom, "YYYY-MM-DD").add(1, "y").add(-1, "d").format("YYYY-MM-DD");
            }
            return null;
        }

        if (this.Contract.IsProgramWithMultipleTours) {
            return null;
        }

        if (!this.hasSchengenCountry() && this.Contract.TravelDatesAreNotKnown) {
            if (this.Contract.ContractFrom) {
                return moment(this.Contract.ContractFrom, "YYYY-MM-DD").add(1, "y").add(-1, "d").format("YYYY-MM-DD");
            }
        }
        return null;
    }

    public changeContractToAndContractValidDays() {
        this.getCurrentInsuranceProgramInsurancePeriodLengthRestrictions();
        this.Contract.ContractTo = moment(this.Contract.ContractFrom, "YYYY-MM-DD")
            .add(this.InsuranceProgramInsurancePeriodLengthRestrictions.MaxValueYears, "y")
            .add(this.InsuranceProgramInsurancePeriodLengthRestrictions.MaxValueDays - 1, "d").format("YYYY-MM-DD");
        this.Contract.ContractValidDays =
        moment(this.Contract.ContractTo).diff(moment(this.Contract.ContractFrom, "YYYY-MM-DD"), "d") + 1;
    }

    public changeContractFrom() {
        if (this.Contract.IsProgramWithMultipleTours) {
            this.changeContractToAndContractValidDays();
        }
    }

    public travelingAbroadDestinationChange() {
        this.calculateInsuranceTermDays();

        if (this.Contract.IsVisaObtainingBasis && !this.hasSchengenCountry()) {
            this.Contract.IsVisaObtainingBasis = false;
        }
        this.Repository.update("TravelingAbroadDestination", this.Contract);
    }

    public travelingTourCancellationProductRiskChange() {
        if (angular.isDefined(this.tourCancellationProductRiskTimer)) {
            this.stopTimer(this.tourCancellationProductRiskTimer);
        }

        this.tourCancellationProductRiskTimer = this.$interval(() => {
            this.blockUI.start("Обновляются справочные данные");
            this.Repository.update(this.Repository.TourCancellationProductRisk.FieldName, this.Contract).finally(() => {
                this.blockUI.stop();
            });
            this.stopTimer(this.tourCancellationProductRiskTimer);
        }, 3000);
    }

    public getTravelDateFromMaxDate() {
        if (!this.Contract.IsProgramWithMultipleTours) {
            return moment().startOf("day").add(6, "months").format("YYYY-MM-DD");
        }

        return null;
    }

    public getTravelDateToMinDate() {
        return moment(this.Contract.TravelDateFrom).add(1, "d").format("YYYY-MM-DD");
    }

    public destinationFilter(model: Int[], item: ITravelingAbroadDestination) {
        const collection = this.getTravelingAbroadDestinationInformation(model);

        if (collection.length) {
            const coverageTerritory = collection[0].TravelingAbroadDestinationIsWholeCoverageTerritory;
            return collection.filter((value: ITravelingAbroadDestination) =>
                (value.TravelingAbroadDestinationId === item.TravelingAbroadDestinationId) ||
                (value.TravelingAbroadDestinationId !== item.TravelingAbroadDestinationId &&
                    item.TravelingAbroadDestinationIsWholeCoverageTerritory !== coverageTerritory));
        }

        return undefined;
    }

    public isIndividual() {
        /* tslint:disable-next-line:max-line-length */
        const isIndividual: IInsuredContractorType[] = this.Repository.InsuredContractorType.Collection.filter((item: IInsuredContractorType) => {
            return item.InsuredContractorTypeId === this.Contract.InsuredContractorTypeId;
        });

        if (isIndividual && isIndividual[0]) {
            return isIndividual[0].InsuredContractorType === "физическое лицо";
        }

        return undefined;
    }

    public travelDatesAreNotKnownChanged() {
        if (this.Contract.TravelDatesAreNotKnown) {
            // todo так как на бэкенде валидными считаются только 365 дней, поэтому добавляется 364 дня 23ч 59м 59с
            this.Contract.ContractTo = moment(this.Contract.ContractFrom).add(364, "d").format("YYYY-MM-DDT23:59:59");
        }
    }

    public calculateInsuranceTermDays() {
        if (this.Validation.hasError("InsuranceTermDays")) {
            this.Validation.removeError("InsuranceTermDays");
        }

        if (Number.isNaN(this.Contract.InsuranceTermDays)) {
            /* tslint:disable-next-line:max-line-length */
            this.Contract.InsuranceTermDays = this.currenctInsuranceProgram.InsuranceProgramInsuranceTermRestrictions[0].MinValueYears * 366
                + this.currenctInsuranceProgram.InsuranceProgramInsuranceTermRestrictions[0].MinValueDays;
        }

        if (!this.Contract.TravelDatesAreNotKnown) {
            if (this.Contract.TravelDateTo) {
                this.setContractTo(this.Contract.TravelDateTo);
            }

            if (this.Contract.TravelDateFrom) {
                this.Contract.ContractFrom = this.Contract.TravelDateFrom;
            }

            if (this.Contract.TravelDateFrom && this.Contract.TravelDateTo) {
                const to = moment(this.Contract.TravelDateTo, "YYYY-MM-DD").startOf("day");
                const from = moment(this.Contract.TravelDateFrom, "YYYY-MM-DD").startOf("day");
                this.Contract.InsuranceTermDays = from.isBefore(to) ? to.diff(from, "d") + 1 : 0;
            } else {
                this.Contract.InsuranceTermDays = 0;
            }
        } else {
            this.Contract.TravelDateFrom = null;
            this.Contract.TravelDateTo = null;
        }
    }

    public uploadExcelFile() {
        if (this.Contract.isLocked()) {
            return;
        }
        const $uibModal = this.di<IModalService>("$uibModal");
        $uibModal.open({
            controller: InsuredPersonUploadController,
            controllerAs: "vm",
            resolve: {
                requiredFields: () => ["Фамилия (лат)", "Имя (лат)", "Дата рождения (ДД.ММ.ГГГГ)"],
            },
            size: "sm",
            template: insuredPersonUploadModalTemplate,
        }).result.then((data: IImportStruct) => {
            if (!data.$data) {
                return;
            }
            const persons: TravelingAbroadPerson[] = [];
            const length = data.$data.length < 100 ? data.$data.length : 100;
            for (let i = 0; i < length; i++) {
                const p = new TravelingAbroadPerson();
                p.InsuredPersonLastName = data.$data[i]["Фамилия (лат)"] || "";
                p.InsuredPersonFirstName = data.$data[i]["Имя (лат)"] || "";
                p.InsuredPersonBirthday = data.$data[i]["Дата рождения (ДД.ММ.ГГГГ)"] ?
                    moment(data.$data[i]["Дата рождения (ДД.ММ.ГГГГ)"], "YYYY-MM-DD").format("YYYY-MM-DD") :
                    null;
                persons.push(p);
            }
            this.Contract.InsuredPersons = persons;
            /* tslint:disable-next-line:max-line-length */
            this.notifyService.successMessage("Импорт списка застрахованных", `Список успешно импортирован (${persons.length})`);
        });
    }

    public getExcelTemplateFile() {
        const importTemplateUrl: string = XLSUploadTemplate;
        const $window = this.di<angular.IWindowService>("$window");
        $window.open(importTemplateUrl, "_blank");
    }

    public getInsuranceProgramFile() {
        const programsUrl: string = insuranceProgramFile;
        const helpers = this.di<Helpers>("helpers");
        helpers.openUrlInIframe(programsUrl);
    }

    public isInsuredPersonAdditionDisabled(): boolean {
    /* tslint:disable-next-line:max-line-length */
        return this.Contract.IsVisaObtainingBasis || this.currenctInsuranceProgram.InsuranceProgramMultipleInsuredPersonsDenied;
    }

    public isClearAllInsuredPersonsDisabled() {
        return this.Contract.InsuredPersons.length === 1 || this.Contract.isLocked();
    }

    public isCurrencyRateAvailable() {
        return this.Repository && this.Repository.Currency && this.Repository.Currency.Collection.length > 0;
    }

    public clearAllInsuredPersons() {
        this.Contract.InsuredPersons.splice(1, this.Contract.InsuredPersons.length);
    }

    public insuredContractorTypeChanged() {
        this.getFieldRepository(this.Repository.InsuredContractorType.FieldName);

        if (this.isIndividual()) {
            this.Contract.InsuredOrgName = null;
            this.Contract.InsuredOrgNameInInternationalDocument = null;
        } else {
            this.Contract.InsuredDocSerial = null;
            this.Contract.InsuredDocTypeId = null;
            this.Contract.InsuredDocNumber = null;
            this.Contract.InsuredLastName = null;
            this.Contract.InsuredFirstName = null;
            this.Contract.InsuredMiddleName = null;
            this.Contract.InsuredLastNameInInternationalDocument = null;
            this.Contract.InsuredFirstNameInInternationalDocument = null;
            this.Contract.InsuredMiddleNameInInternationalDocument = null;
            this.Contract.InsuredIsNotResident = false;
        }
    }

    public setContractTo(value: string) {
        if (this.hasSchengenCountry()) {
            this.Contract.ContractTo = moment(value, "YYYY-MM-DD").add(15, "d").format("YYYY-MM-DD");
        } else {
            this.Contract.ContractTo = value;
        }
    }

    public hasSchengenCountry(): boolean | ITravelingAbroadDestination {
        const collection = this.getTravelingAbroadDestinationInformation(this.Contract.TravelingAbroadDestination);
        const isShengen = collection.filter((item) => item.TravelingAbroadDestinationIsSchengen === true);
        return (isShengen && isShengen[0]);
    }

    public getTravelingAbroadDestinationInformation(model: Int[]): ITravelingAbroadDestination[] {
        const collection: ITravelingAbroadDestination[] = [];
        angular.forEach(model, (item) => {
            /* tslint:disable-next-line:max-line-length */
            const collectionItem: ITravelingAbroadDestination[] = this.Repository.TravelingAbroadDestination.Collection.filter((filterItem: ITravelingAbroadDestination) => {
                return filterItem.TravelingAbroadDestinationId === item;
            });

            if (collectionItem && collectionItem[0]) {
                collection.push(collectionItem[0]);
            }
        });
        return collection;
    }

    /**
     * Проверка на необходимость блокировки
     * @return {*} Блокировать или нет
     */
    public canAddInsuredPersons() {
        return this.Contract.InsuredPersons.length < this.maxInsuredPersons;
    }
    /**
     * Добавление застрахованного
     */
    public addInsuredPerson() {
        const newInsuredPerson = new TravelingAbroadPerson();
        this.Contract.InsuredPersons.push(newInsuredPerson);
        return newInsuredPerson;
    }
    public canRemoveInsuredPersons() {
        return this.Contract.InsuredPersons.length > 1;
    }
    /**
     * Удаление застрахованного
     * @param {*} index индекс удаляемого элемента
     */
    public removeInsuredPerson(insuredPerson: TravelingAbroadPerson) {
        if (!this.canRemoveInsuredPersons()) {
            return;
        }
        const insuredPersonIndex: Int = this.Contract.InsuredPersons.indexOf(insuredPerson);
        this.Contract.InsuredPersons.splice(insuredPersonIndex, 1);
    }

    private patchInsurancePeriodLengthRestrictionsDictionary() {
        for (const item of this.currenctInsuranceProgram.InsuranceProgramInsurancePeriodLengthRestrictions) {
            if (!item.ValueDayForPresentation) {
                item.ValueDayForPresentation = (item.MaxValueYears > 0 ? item.MaxValueYears + " год " : "")
                + (item.MaxValueDays > 0 ? item.MaxValueDays + " дней" : "");
            }
        }
    }

}
