/**
 * ugskOsagoAdmittedPersons - Лица допущенные к управлению ТС, КБМ
 */
import angular from "angular";
import { OsagoContract, OsagoRepository } from "application/osago/classes";
import { ILogic } from "infrastructure/interfaces/ILogic";
import { NgComponentController } from "infrastructure/NgController";
import { Guid, Int } from "infrastructure/types";
import { Uuid } from "lib/uuid";
import { OsagoDriver, OsagoInsurantProxy, OsagoOwnerProxy } from "../../osago.factory";
import ugskOsagoDriverComponent from "../ugsk-osago-driver/ugskOsagoDriver.component";
import { OSAGO_DRIVER } from "application/constants";
import { ValidationService } from "infrastructure/services/validation.service";

export interface IDictionaryCountryItem {
    DriverLicenseIssueCountryId: Int;
    DriverLicenseIssueCountryName: string;
}

class UgskOsagoAdmittedPersons extends NgComponentController {
    public formIsLocked: boolean;
    public insurant: OsagoInsurantProxy;
    public owner: OsagoOwnerProxy;
    public contract: OsagoContract;
    public insurantDriver: OsagoDriver;
    public ownerDriver: OsagoDriver;
    public ownerIsDriver: boolean;
    public insurantIsDriver: boolean;
    public repository: OsagoRepository;
    public validationService: ValidationService;
    // @callback
    public onAdmittedPersonsChanged: () => void;

    private cachedDictionaryCountry: IDictionaryCountryItem[];
    private defaultDriverDocumentTypeId: number;
    private defaultDriverLicenseIssueCountryId: number;
    private readonly foreignDriverDocumentTypeId = 22;
    private insurantDriverWatcher: () => void;
    private ownerDriverWatcher: () => void;

    get logic(): ILogic {
        const vm = this;
        /* tslint:disable object-literal-sort-keys */
        return {
            condition() {
                return !vm.formIsLocked;
            },
            rules: [{
                description: "Когда нажата галка 'Собственник является водителем'",
                properties: [
                    "ownerDriver",
                ],
                when: "ownerIsDriver and not(ownerDriver)",
                then: "ownerDriver = addDriver()",
            }, {
                description: "Когда отжимается галка 'Владелец является водителем'",
                when: "not(ownerIsDriver) and ownerDriver",
                then: "ownerDriver.guid = getNewGuid(); ownerDriver = undefined",
            }, {
                description: "Пока нажата галка 'Владелец является водителем'",
                when: "ownerIsDriver",
                then: "owner.copyTo(ownerDriver)",
            }, {
                description: `Нажать галку 'Владелец является водителем' нельзя если:
                    - список водителей заполнен и ни один из них не является владельцем`,
                properties: ["ownerIsDriverDisabled"],
                when: "not(canAddMoreDrivers()) and not(ownerIsDriver)",
                then: "ownerIsDriverDisabled = true",
                else: "ownerIsDriverDisabled = false",
            }, {
                description: "Когда нажимается галка 'Страхователь является водителем'",
                properties: [
                    "insurantDriver",
                ],
                when: "insurantIsDriver and not(insurantDriver)",
                then: "insurantDriver = addDriver()",
            }, {
                description: "Когда отжимается галка 'Страхователь является водителем'",
                when: "not(insurantIsDriver) and insurantDriver",
                then: "insurantDriver.guid = getNewGuid(); insurantDriver = undefined",
            }, {
                description: "Пока нажата галка 'Страхователь является водителем'",
                when: "insurantIsDriver",
                then: "insurant.copyTo(insurantDriver)",
            }, {
                description: `Нажать галку 'Страхователь является водителем' нельзя, если:
                    - страхователь - юр.лицо
                    - страхователь является собственником,
                    - список водителей заполнен и ни один из них не является страхователем`,
                properties: [
                    "insurantIsDriverDisabled",
                ],
                /* tslint:disable-next-line:max-line-length */
                when: "insurant.isLegalEntity() or ownerIsInsurant or (not(canAddMoreDrivers()) and not(insurantIsDriver))",
                then: "insurantIsDriverDisabled = true",
                else: "insurantIsDriverDisabled = false",
            }, {
                /* tslint:disable-next-line:max-line-length */
                description: "Когда нажимается галка 'Страхователь является собственником' при нажатой галке 'Страхователь является водителем '",
                properties: [
                    "insurantIsDriver",
                    "ownerIsDriver",
                ],
                when: "insurantIsDriver and ownerIsInsurant",
                /* tslint:disable-next-line:max-line-length */
                then: "ownerIsDriver = true; ownerDriver = insurantDriver; insurantIsDriver = false; insurantDriver = undefined",

            }, {
                description: "Когда список водителей ограничен",
                when: "not(isDriversListLimited())",
                then: "clearDrivers()",
            }, {
                description: "Когда собственник и/или страхователь является Юр. лицом",
                when: "ownerIsLegal",
                then: "clearDataWhenOwnerLegal()",
            }],
        };
        /* tslint:enable object-literal-sort-keys */
    }

    get ownerIsInsurant(): boolean {
        return this.owner.guid === this.insurant.guid;
    }

    get ownerIsLegal(): boolean {
        return this.owner.isLegalEntity();
    }

    get insurantIsLegal(): boolean {
        return this.insurant.isLegalEntity();
    }

    public clearDataWhenOwnerLegal() {
        this.ownerIsDriver = false;
        this.insurantIsDriver = false;
        this.contract.Drivers.forEach((driver) => {
            driver.hasPreviousDriverLicense = false;
            driver.previousLicenseLastName = null;
            driver.previousLicenseSerial = null;
            driver.previousLicenseNumber = null;
            driver.previousLicenseIssueDate = null;
        });
    }

    /**
     * Фильтруем список доступных типов ВУ только для водителя, который удовлетворяет следующим условиям:
     *  - Собственник/Страхователь
     *  - У Собственника/Страхователя выбран флаг "Не резидент"
     * В таком случае, доступно только ВУ иностранного государства (Id = 22)
     * @param {Int} docTypeId
     * @param {Guid} driverGuid
     */
    public docTypeDisabled(docTypeId: Int, driver: OsagoDriver) {
        if (driver.guid) {
            const driverIsOwner = driver.guid === this.owner.guid;
            const driverIsInsurant = driver.guid === this.insurant.guid;

            if ((driverIsOwner || driverIsInsurant) && driver.isNotResident) {
                return docTypeId !== this.foreignDriverDocumentTypeId;
            }
        }

        return false;
    }

    public driverCountryChanged(driver: OsagoDriver): void {
        if (!driver.isNotResident) {
            driver.country = "Россия";
            if (driver.DriverLicenseDocTypeId === OSAGO_DRIVER.DRIVER_LICENSE_RF) {
                driver.DriverLicenseIssueCountryId = OSAGO_DRIVER.COUNTRY_RF;
            }
        } else {
            driver.country = "";
        }
    }

    public driverDocumentTypeChange(driver: OsagoDriver) {
        if (driver.DriverLicenseDocTypeId === this.foreignDriverDocumentTypeId) {
            driver.DriverLicenseIssueCountryId = null;
            this.repository.DriverLicenseIssueCountry.Collection = this.cachedDictionaryCountry
                .filter((item) => item.DriverLicenseIssueCountryId !== OSAGO_DRIVER.COUNTRY_RF);
        } else {
            this.repository.DriverLicenseIssueCountry.Collection = this.cachedDictionaryCountry;
            driver.country = "Россия";
            driver.DriverLicenseIssueCountryId = OSAGO_DRIVER.COUNTRY_RF;
        }
    }

    public isInsurantOrOwner(driver: OsagoDriver): boolean {
        return driver === this.insurantDriver || driver === this.ownerDriver;
    }

    public isDisablePickDriverLicenseIssueCountry(docTypeId: number): boolean {
        return docTypeId === OSAGO_DRIVER.DRIVER_LICENSE_RF;
    }

    public isDriversListLimited(): boolean {
        return this.contract.isDriversListLimited();
    }

    public canAddMoreDrivers(): boolean {
        return this.contract.canAddMoreDrivers();
    }

    public addDriver(): OsagoDriver | undefined {
        if (!this.canAddMoreDrivers()) {
            return undefined;
        }
        const newDriver = new OsagoDriver();
        this.contract.Drivers.push(newDriver);
        //  @callback
        this.onAdmittedPersonsChanged();
        return newDriver;
    }

    public formatCountryLabel(model) {
        const country = this.repository.DriverLicenseIssueCountry
                        .Collection.find((item) => model === item.DriverLicenseIssueCountryId);

        if (!country) {
            return undefined;
        }

        return country.DriverLicenseIssueCountryName;
    }

    public removeDriver(driver: OsagoDriver): void {
        if (driver === this.ownerDriver) {
            this.ownerIsDriver = false;
        }
        if (driver === this.insurantDriver) {
            this.insurantIsDriver = false;
        }
        this.contract.Drivers = this.contract.Drivers.filter((contractDriver) => contractDriver !== driver);
        //  @callback
        this.onAdmittedPersonsChanged();
    }

    public clearDrivers(): void {
        this.contract.Drivers.forEach((driver) => {
            return this.removeDriver(driver);
        });
    }

    public onInit(): void {
        this.insurant = new OsagoInsurantProxy(this.contract);
        this.owner = new OsagoOwnerProxy(this.contract);
        this.cachedDictionaryCountry = angular.copy(this.repository.DriverLicenseIssueCountry.Collection);
        this.defaultDriverDocumentTypeId = this.repository.DriverDocumentType.DefaultValue;
        this.defaultDriverLicenseIssueCountryId = this.repository.DriverLicenseIssueCountry.DefaultValue;
        this.validationService = this.di("validationService");

        this.$watch(() => {
            return this.contract.Drivers;
        }, () => {
            this.insurantDriver = this.contract.Drivers.find((driver) => driver.guid === this.insurant.guid);
            this.ownerDriver = this.contract.Drivers.find((driver) => driver.guid === this.owner.guid);
            if (this.insurantDriver === this.ownerDriver) {
                this.insurantDriver = undefined;
            }
            if (this.ownerDriver) {
                this.ownerIsDriver = true;
            } else {
                this.ownerIsDriver = false;
            }
            this.$scope.osagoAdmittedPersons.OwnerPersonIsDriver.$setPristine();
            if (this.insurantDriver) {
                this.insurantIsDriver = true;
            } else {
                this.insurantIsDriver = false;
            }
            this.$scope.osagoAdmittedPersons.InsuredPersonIsDriver.$setPristine();
        });

        this.$watch(() => {
            return this.contract.Drivers;
        }, () => {
            if (this.validationService.hasError("Drivers")) {
                this.validationService.removeError("Drivers");
            }
        }, true);

        /**
         * Необходимо для выставления правильного типа документа ВУ, если водитель:
         *  - страхователь/собственник
         *  - не резидент
         */
        this.insurantDriverWatcher = this.$scope.$watch(
            () => this.insurantIsDriver && this.insurantDriver ? this.insurantDriver.isNotResident : undefined,
            (newVal, oldVal) => {
                if (newVal && !oldVal) {
                    this.insurantDriver.DriverLicenseDocTypeId = this.foreignDriverDocumentTypeId;
                } else if (!newVal && oldVal) {
                    this.insurantDriver.DriverLicenseDocTypeId = this.defaultDriverDocumentTypeId;
                    this.insurantDriver.DriverLicenseIssueCountryId = this.defaultDriverLicenseIssueCountryId;
                }
            },
        );

        this.ownerDriverWatcher = this.$scope.$watch(
            () => this.ownerIsDriver && this.ownerDriver ? this.ownerDriver.isNotResident : undefined,
            (newVal, oldVal) => {
                if (newVal && !oldVal) {
                    this.ownerDriver.DriverLicenseDocTypeId = this.foreignDriverDocumentTypeId;
                } else if (!newVal && oldVal) {
                    this.ownerDriver.DriverLicenseDocTypeId = this.defaultDriverDocumentTypeId;
                    this.ownerDriver.DriverLicenseIssueCountryId = this.defaultDriverLicenseIssueCountryId;
                }
            },
        );
    }

    public onDestroy() {
        if (this.ownerDriverWatcher) {
            this.ownerDriverWatcher();
        }

        if (this.insurantDriverWatcher) {
            this.insurantDriverWatcher();
        }
    }

    public getNewGuid(): Guid {
        return Uuid.raw();
    }
}

export default angular.module("app.osago.components.admitted-persons", [
    ugskOsagoDriverComponent,
]).component("ugskOsagoAdmittedPersons", {
    bindings: {
        contract: "<",
        formIsLocked: "<",
        onAdmittedPersonsChanged: "&",
        repository: "<",
    },
    controller: UgskOsagoAdmittedPersons,
    controllerAs: "vm",
    template: require("./ugskOsagoAdmittedPersons.component.html"),
})
.name;
