import angular from "angular";

export interface IProxyRules {
    [key: string]: string;
}
interface IProxiable extends Function {
    proxyRules: IProxyRules;
}
/**
 * Класс для создания унифицированных интерфейсов вокруг существующих объектов
 * разных типов
 */
export class Proxy {
    [key: string]: any;
    constructor(source) {
        angular.forEach(this.getRules(), (sourceProp, selfProp) => {
            Object.defineProperty(this, selfProp, {
                enumerable: true,
                get() {
                    return source[sourceProp];
                },
                set(value) {
                    source[sourceProp] = value;
                }
            });
        });
    }
    public getRules() {
        return (this.constructor as IProxiable).proxyRules;
    }
    static get proxyRules(): IProxyRules {
        throw new Error("Not implemented");
    }
    public getSnapShot() {
        return Object.keys(this.getRules()).reduce((accumulator, item) => {
            accumulator[item] = this[item];
            return accumulator;
        }, {});
    }
    public toString() {
        return Object.keys(this.getRules()).map((property) => this[property]).join(", ");
    }
    //  todo: transform to copyFrom
    public copyTo(target: Proxy, excludes: string[] = []) {
        Object.keys(this.getRules()).forEach((prop) => {
            if (excludes.includes(prop)) {
                return;
            }
            target[prop] = this[prop];
        });
    }
    //  copy source proxy properties to this proxy
    public copyFrom(source: Proxy, excludes: string[] = []) {
        const sourceRules = source.getRules();
        Object.keys(this.getRules()).forEach((prop) => {
            if (!(prop in sourceRules)) {
                return;
            }
            if (excludes.includes(prop)) {
                return;
            }
            this[prop] = source[prop];
        });
    }
}
