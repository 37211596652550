export function LabelTitleDirective(): ng.IDirective {
    "ngInject";

    return {
        link: (
            scope: ng.IScope,
            element: ng.IAugmentedJQuery,
        ) => {
            element.attr("title", element.text());
            element.addClass("no-wrap");
        },
        restrict: "A",
    };
}
