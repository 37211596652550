export class UgskPreviousContractInfoController {
    constructor($uibModalInstance, $state, previousContractData, productCode, productsService) {
        this.$uibModalInstance = $uibModalInstance;
        this.$state = $state;
        this.previousContract = previousContractData;
        this.productCode = productCode;
        this.productsService = productsService;
    }

    static get $inject() {
        return ["$uibModalInstance", "$state", "previousContractData", "productCode", "productsService"];
    }

    close() {
        this.$uibModalInstance.dismiss();
    }

    copy() {
        this.$uibModalInstance.close();
    }

    getContractUrl() {
        return this.$state.href(`app.${this.productCode}.index`, { id: this.previousContract.PreviousContractId }, { inherit: false });
    }

    getCurrentProductName() {
        return this.productsService.getByCode(this.productCode).productName;
    }
}
