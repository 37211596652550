import angular from "angular";
import moment from "moment";
import { IODataParams } from "infrastructure/interfaces";
import IBlockUI from "infrastructure/interfaces/IBlockUI";
import { Int } from "infrastructure/types";
import { IDashboardContractsTableParams, IDashboardResource, ILastUserContractDTO } from "./interfaces";
import { NgTableParams as NgTableParamsType } from "ng-table";

/**
 * DashboardContractsTable - Фабрика со своей необходимой обвязкой для таблиц-вкладок в ЛКП
 */

angular
    .module("app.dashboard.factory", [])
    .factory("DashboardContractsTableParams", dashboardContractsTableParams);

function dashboardContractsTableParams(
    NgTableParams: typeof NgTableParamsType,
    blockUI: IBlockUI,
    dashboardResource: IDashboardResource,
) {
    "ngInject";

    return class DashboardContractsTableParams
        extends NgTableParams<ILastUserContractDTO>
        implements IDashboardContractsTableParams {

        public static getData(params: DashboardContractsTableParams): angular.IPromise<ILastUserContractDTO[]> {
            const odataParams: IODataParams = {
                $count: true,
                $orderby: params.order || "ContractTo asc",
                $skip: (params.page() - 1) * params.count(),
                $top: params.count(),
            };

            const filter = params.getFilterParams();
            if (filter) {
                odataParams.$filter = filter;
            }

            const blockedArea = blockUI.instances.get(params.blockName);
            blockedArea.start(" ");
            return dashboardResource.query(odataParams).$promise.then((data) => {
                params.items.push(...data.value);
                params.totalItems = data["@odata.count"];
                return data.value;
            }).finally(() => {
                blockedArea.stop();
            });
        }

        public baseFilter: string;
        public blockName: string;
        public order: string;
        public totalItems: Int;
        public items: ILastUserContractDTO[];
        public selectedItem: ILastUserContractDTO;

        constructor(options: {
            baseFilter?: string,
            blockName: string,
            order: string,
        }) {
            super({
                count: 15,
                filter: { product: null },
                page: 1,
            }, {
                counts: [],
                getData: DashboardContractsTableParams.getData,
                total: 0,
            });
            this.totalItems = 0;
            this.items = [];
            this.selectedItem = null;

            if (angular.isObject(options)) {
                angular.extend(this, options);
            }
        }

        public getFilterParams(): string {
            const filter = [];

            if (this.baseFilter) {
                filter.push(this.baseFilter);
            }

            if (this.filter().product) {
                const codes = this.filter().product.map((product) => `ProductCode eq '${product}'`);
                if (codes.length > 0) {
                    filter.push(`(${codes.join(" or ")})`);
                }
            }

            if (this.filter().contractToFrom && this.filter().contractToTo) {
                const contractToFrom = moment(this.filter().contractToFrom).format("YYYY-MM-DDT00:00:00");
                const contractToTo = moment(this.filter().contractToTo).format("YYYY-MM-DDT23:59:59");
                filter.push(`(ContractTo ge ${contractToFrom}Z and ContractTo le ${contractToTo}Z)`);
            }

            return filter.join(" and ");
        }

        public clearItems(): void {
            this.items = [];
            this.totalItems = 0;
        }

        public hasMore(): boolean {
            return this.items.length < this.totalItems;
        }

        public getMore(): void {
            this.page(this.page() + 1);
        }
    };
}
