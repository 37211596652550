import template from "./ugskVehicleParams.component.html";

export default angular
.module("app.safeCarAccident.components.vehicle-params", [])
.component("ugskVehicleParams", {
    bindings: {
        /* параметры договора */
        brandVehicle: "=",
        modelVehicle: "=",
        vehicleVin: "=",
        stateLicensePlate: "=",
        isFormLocked: "<"

    },
    template,
    controller: ugskVehicleParamsComponentController,
    controllerAs: "vm"
})
.name;

ugskVehicleParamsComponentController.$inject = ["ngModelOptions"];

function ugskVehicleParamsComponentController(ngModelOptions) {
    var vm = this;

    vm.$onInit = function() {
        vm.ngModelOptions = ngModelOptions;
    };

    return vm;
}
