import { OsagoContractorProxy } from "./OsagoContractorProxy";

export class OsagoInsurantProxy extends OsagoContractorProxy {
    //  @override
    public copyFrom(source) {
        const excludes = [];
        const sourcePhones = source.phones.map((phone) => phone.Number).join(",");
        const targetPhones = this.phones.map((phone) => phone.Number).join(",");
        if (sourcePhones === targetPhones) {
            excludes.push("phones");
        }
        return super.copyFrom(source, excludes);
    }
    static get proxyRules() {
        return {
            guid: "InsuredGuid",
            isNotResident: "InsuredIsNotResident",
            contractorType: "InsuredContractorType",
            documentType: "InsuredDocumentType",
            docSerial: "InsuredPersonDocSerial",
            docNumber: "InsuredPersonDocNumber",
            docWhomGiven: "InsuredPersonDocWhomGiven",
            docDateGiven: "InsuredPersonDocDateGiven",
            lastName: "InsuredPersonLastName",
            firstName: "InsuredPersonFirstName",
            middleName: "InsuredPersonMiddleName",
            birthday: "InsuredPersonBirthday",
            snils: "InsuredPersonSnils",
            orgName: "InsuredOrgName",
            orgInn: "InsuredOrgINN",
            orgKpp: "InsuredOrgKPP",
            Inn: "InsuredPersonINN",
            commonRealAddress: "InsuredCommonRealAddress",
            fiasId: "InsuredCommonRealAddressFiasId",
            kladrId: "InsuredCommonRealAddressKladrId",
            phones: "InsuredPhones",
            country: "InsuredCountry",
            birthPlace: "InsuredPersonBirthPlace",
            docOrgCode: "InsuredPersonDocOrgCode",
            accountingDate: "InsuredAccountingDate",
        };
    }
}
