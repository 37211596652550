import { ProductsJournalController } from "./journal.controller";

export class IFLJournalController extends ProductsJournalController {
    public $onInit() {
        this.columns = [{
            defaultFilterOperation: "startswith",
            displayText: "Серия-номер полиса",
            inlineFilter: true,
            name: "PolicyNumber",
            pinned: true,
            renderer: (item) => item.PolicyFullValue,
            tooltipText: "Поиск только<br/>по номеру",
            type: "Edm.String",
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Страхователь",
            inlineFilter: true,
            name: "InsuredName",
            pinned: true,
            type: "Edm.String",
            width: 2,
        }, {
            displayText: "Адрес объекта страхования",
            inlineFilter: true,
            name: "AddressUserDefinedAddress",
            type: "Edm.String",
        }, {
            defaultFilterOperation: "eq",
            displayText: "Дата заключения договора",
            inlineFilter: true,
            name: "SigningDate",
            ngFilter: this.dateFormatWithoutTimeZone,
            pinned: true,
            type: "Edm.DateTimeOffset",
        }, {
            displayText: "Начало срока страхования",
            inlineFilter: true,
            name: "ContractFrom",
            ngFilter: this.dateFormatWithoutTimeZone,
            type: "Edm.DateTimeOffset",
        }, {
            displayText: "Окончание срока страхования",
            inlineFilter: true,
            name: "ContractTo",
            ngFilter: this.dateFormatWithoutTimeZone,
            type: "Edm.DateTimeOffset",
        }, {
            displayText: "Страховая премия",
            inlineFilter: true,
            name: "InsurancePremium",
            ngFilter: "thousand",
            type: "Edm.Int32",
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Филиал",
            inlineFilter: true,
            name: "EmployeeFilialName",
            pinned: true,
            type: "Edm.String",
        }, {
            displayText: "Пользователь",
            inlineFilter: true,
            name: "EmployeeName",
            type: "Edm.String",
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Канал продаж",
            inlineFilter: true,
            name: "SaleChannel",
            pinned: true,
            type: "Edm.String",
        }, {
            defaultFilterOperation: "eq",
            displayText: "Статус договора",
            getVariants: () => this.getStatusVariants(),
            inlineFilter: true,
            name: "StatusName",
            pinned: true,
            template: `
                <span
                    ng-bind="item.StatusName"
                    ng-class="vm.getContractStatusLabelClass(item)">
                </span>
            `,
            type: "Edm.String",
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Подразделение",
            inlineFilter: true,
            name: "EmployeeSubdivisionName",
            type: "Edm.String",
            visible: false,
        }, {
            displayText: "Вид оплаты",
            name: "PaymentKindName",
            type: "Edm.String",
            visible: false,
        }, {
            displayText: "Id-договора",
            name: "Id",
            type: "Edm.Int32",
            visible: false,
        }, {
            displayText: "Серия и номер полиса",
            name: "PolicyFullValue",
            type: "Edm.String",
            visible: false,
        }];
        super.$onInit();
    }
}
