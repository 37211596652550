import * as angular from "angular";
import { JL } from "jsnlog";
import { Uuid } from "lib/uuid";
/**
 * Переопределены методы логирования $log. При вызове методов, запрос отправляется на сервер.
 * Логируются все исключения, в том числе при вызове AJAX запросов.
 * Также имееется возможность логировать долгие запросы:
 * var config = { timeout: 5000, warningAfter: 2000 };
 * $http.get(url, config)
 * Если ответа будет между 2 и 5 секундами, отправится Watning, если больше 5 секунд - отправится Error
 */

export class Logger {
    private instanceGuid = Uuid.raw();
    private initialized: boolean = false;
    private jl;
    private DEFAULT_PROPS = ["Message", "Type", "Action", "Product", "Status"];
    constructor() {
        this.jl = JL("FrontNg");
    }
    public initialize(url: string): void {
        const appender = (JL as any).createAjaxAppender("identitySetting");
        appender.setOptions({
            beforeSend(xhr) {
                try {
                    const tokenData = JSON.parse(localStorage.getItem("ngStorage-tokenData"));
                    xhr.setRequestHeader("JWT-Access-Token", tokenData.access_token);
                } catch (e) {
                    // do nothing
                }
            },
            url: `${url}/Log/Index`,
        });
        this.jl.setOptions({
            appenders: [appender],
        });
        this.initialized = true;
    }
    public log(msg, data?): void {
        this.jl.trace(this.checkMsg(msg, data));
    }
    public debug(msg, data?): void {
        this.jl.debug(this.checkMsg(msg, data));
    }
    public info(msg, data?): void {
        this.jl.info(this.checkMsg(msg, data));
    }
    public warn(msg, data?): void {
        this.jl.warn(this.checkMsg(msg, data));
    }
    public error(msg, data?): void {
        this.jl.error(this.checkMsg(msg, data));
    }
    public fatal(msg, data): void {
        this.jl.fatalException(this.checkMsg(msg, data));
    }
    private checkMsg(msg, Data?) {
        const sendMsg = {
            Data: {},
            InstanceGuid: this.instanceGuid,
            Message: "Пустое сообщение",
            Type: "any",
            Url: undefined,
        };
        if (typeof(msg) === "function") {
            msg = msg();
        }
        if (typeof(msg) === "string") {
            sendMsg.Message = msg || sendMsg.Message;
            sendMsg.Data = Data || sendMsg.Data;
            sendMsg.Type = "string";
        }
        if (typeof(msg) === "object") {
            for (const prop in msg) {
                if (this.DEFAULT_PROPS.includes(prop)) {
                    sendMsg[prop] = msg[prop] || sendMsg[prop];
                } else {
                    sendMsg.Data[prop] = msg[prop];
                }
            }
            for (const propData in Data) {
                if (propData) {
                    sendMsg.Data[propData] = Data[propData];
                }
            }
            if (msg.hasOwnProperty("config") && msg.config && msg.config.hasOwnProperty("url") ) {
                sendMsg.Url = msg.config.url;
            }
            sendMsg.Type = this.switchType(msg, sendMsg.Type);
        }
        if (typeof(msg) === "undefined") {
            sendMsg.Message = "Нет сообщения";
            sendMsg.Data = Data || sendMsg.Data;
            sendMsg.Type = "undefined";
        }
        return sendMsg;
    }
    private switchType(data, defaultData?: string) {
        let status = "frontend";
        if ("typePrefix" in data) {
            status = data.typePrefix;
        }
        if ("status" in data) {
            switch (data.status) {
                case 400:
                    status += ".validation";
                    break;
                case 403:
                    status += ".forbidden";
                    break;
                case 404:
                    status += ".not_found";
                    break;
                case 500:
                    status += ".errors";
                    break;
                default:
                    status += ".default";
                    break;
            }
        }
        return status || defaultData;
    }
}

const logger = new Logger();

export default angular.module("logToServer", [])
    .service("$logToServer", function () { return logger; })
    .name;
