import { Product } from "domain/classes/product.class";
import { BoxAccidentToolbarController } from "../boxAccident-toolbar.controller";
import ugskBoxAccidentInsurant from "../components/ugsk-box-accident-insurant/ugskBoxAccidentInsurant.component";
import { BoxAccidentJournalController } from "../journal/boxAccidentJournal.controller";
import { BoxAccidentJournalToolbarController } from "../journal/boxAccidentJournalToolbar.controller";
import { ShortTermAccidentController } from "./shortTermAccident.controller";
import { ShortTermAccidentContractDTO } from "./shortTermAccident.factory";

import "../components/ugsk-box-accident-contract-params/ugskBoxAccidentContractParams.component";
import "../components/ugsk-box-accident-insurance-conditions/ugskBoxAccidentInsuranceConditions.component";
import "../components/ugsk-box-accident-insured-person/ugskBoxAccidentInsuredPerson.component";

import shortTermAccidentContractParamsComponent from "./components/ugsk-contract-params/ugskContractParams.component";

import angular from "angular";
import { ProductsService } from "infrastructure/services/products.service";
import template from "./body.html";
import manual from "./manual_shortTermAccident.pdf";

export class ShortTermAccident extends Product {
    constructor() {
        super();
        this.code = "shortTermAccident";
        this.groupName = "Страхование жизни и здоровья";
        this.productName = "Краткосрочный";
        this.description = "Добровольное страхование от несчастных случаев и болезней";
        this.insuranceRules = {
            base: "https://ugsk.ru/about/pravila/NS.pdf",
        };
        this.insurerCanBeLegalEntity = true;
        this.logo = "nav_personalAccident";
        this.manualUrl = manual;
        this.sort = 140;
        this.template = template;
        this.controller = ShortTermAccidentController;
        this.toolbarController = BoxAccidentToolbarController;
        this.journalController = BoxAccidentJournalController;
        this.journalToolbarController = BoxAccidentJournalToolbarController;
        this.extraViews = {
            "contractParams@app.shortTermAccident.index": {
                template: require("./view.contractParams.html"),
            },
            "insuranceConditions@app.shortTermAccident.index": {
                template: require("./view.insuranceConditions.html"),
            },
            "insurant@app.shortTermAccident.index": {
                template: require("../view.insurantBase.html"),
            },
            "insuredPerson@app.shortTermAccident.index": {
                template: require("./view.insuredPerson.html"),
            },
            "payment@app.shortTermAccident.index": {
                template: require("../view.paymentBase.html"),
            },
        };
    }
    get contractClass() {
        return ShortTermAccidentContractDTO;
    }
}

angular.module("app.shortTermAccident", [
    "touchSpin",
    "app.boxAccident.components.contract-params",
    "app.boxAccident.components.insurance-conditions",
    "app.boxAccident.components.insured-person",
    shortTermAccidentContractParamsComponent,
    ugskBoxAccidentInsurant,
]).run((productsService: ProductsService) => {
    "ngInject";

    productsService.register(new ShortTermAccident());
});
