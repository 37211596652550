import { Product } from "domain/classes/product.class";
import ugskThemeSwitcher from "../components/ugsk-theme-switcher/ugskThemeSwitcher.component";

import { OsagoToolbarController } from "./osago-toolbar.controller";
import { OsagoJournalToolbarController } from "./journal/osagoJournal-toolbar.controller";
import { OsagoJournalController } from "./journal/osagoJournal.controller";
import { OsagoController } from "./osago.controller";
import { OsagoContract } from "./osago.factory";
import "./osago.mapping";
import inspectionViewModule from "application/components/views/ugsk-inspection-of-contract-view/ugskInspectionOfContractView.component";
import osagoAdmitterPersonsModule from "./components/ugsk-osago-admitted-persons/ugskOsagoAdmittedPersons.component";
import osagoContractParamsModule from "./components/ugsk-osago-contract-params/ugskOsagoContractParams.component";
import osagoDriversModule from "./components/ugsk-osago-driver/ugskOsagoDriver.component";
import osagoInsurantModule from "./components/ugsk-osago-insurant/ugskOsagoInsurant.component";
import osagoOwnerModule from "./components/ugsk-osago-owner/ugskOsagoOwner.component";
import osagoParticipantsModule from "./components/ugsk-osago-participants/ugskOsagoParticipants.component";
import osagoPreviousContractModule from "./components/ugsk-osago-previous-contract/ugskOsagoPreviousContract.component";
import osagoSpecialNoteModule from "./components/ugsk-osago-special-note/ugskOsagoSpecialNote.component";
import osagoUAutoContractInfoModule from "./components/ugsk-osago-uauto-contract-info/ugskOsagoUAutoContractInfo.component";
import osagoUsagePeriodModule from "./components/ugsk-osago-usage-period/ugskOsagoUsagePeriod.component";
import osagoVehicleParamsModule from "./components/ugsk-osago-vehicle-params/ugskOsagoVehicleParams.component";
import osagoVehicleTransdekraPicker from "./components/ugsk-osago-vehicle-transdekra-picker/ugskOsagoVehicleTransdekraPicker.component";

import angular from "angular";
import { ProductsService } from "infrastructure/services/products.service";
import template from "./body.html";
import "./compact.sass";
import osagoServices from "./osago.service";
import { ReliableDriveContract } from "application/osago/classes/ReliableDriveContract";
import { StaticValidationRules } from "./static.validation.rules";
import { Helpers } from "infrastructure/app.helpers";
import { EmployeeService } from "infrastructure/services/employee.service";
import RelatedProductService from "./relatedProduct.service";
import { RELIABLE_DRIVE_PRODUCT_NAME } from "application/constants";

class Osago extends Product {
    public staticValidationRules: StaticValidationRules;
    constructor() {
        super();
        this.code = "osago";
        this.template = template;
        this.controller = OsagoController;
        this.toolbarController = OsagoToolbarController;
        this.groupName = "Автострахование";
        this.productName = "ОСАГО";
        this.description = "Страхование автомобилей по договору ОСАГО";
        this.insurerCanBeLegalEntity = true;
        this.subLicenses = ['ООО "Трансдекра"'];
        this.logo = "nav_osago";
        this.manualUrl = require("./manual_osago.pdf");
        this.sort = 20;
        this.journalController = OsagoJournalController;
        this.journalToolbarController = OsagoJournalToolbarController;
        this.staticValidationRules = new StaticValidationRules();
        this.extraViews = {
            "digest@app.osago.index": {
                template: require("./view.digest.html"),
            },
        };
        this.extraResolve = {
            reliableDriveContract: (reliableDriveService, Contract: OsagoContract, helpers: Helpers, employeeService: EmployeeService, relatedProductService: RelatedProductService) => {
                "ngInject";

                const resourceService = reliableDriveService();
                const reliableDriveContract = new ReliableDriveContract();
                reliableDriveContract.setResourceService(resourceService);
                const blankContract = new ReliableDriveContract();
                blankContract.setResourceService(resourceService);
                blankContract.OsagoContractGuid = Contract.Guid;
                relatedProductService.selectProduct(null);

                return employeeService.getEmployee().then(() => {
                    return reliableDriveContract.$loadByOsagoGuid(Contract.Guid).then(() => {
                        const isContractActive = reliableDriveContract.isActive() || reliableDriveContract.isUserChoice();
                        if (isContractActive && reliableDriveContract.Id) {
                            relatedProductService.selectProduct(RELIABLE_DRIVE_PRODUCT_NAME);
                        }
                        return reliableDriveContract;
                    });
                });
            },
        };
        this.hasInspectionConclusion = true;
    }
    get contractClass() {
        return OsagoContract;
    }
}

export default angular.module("app.osago", [
    osagoAdmitterPersonsModule,
    osagoContractParamsModule,
    inspectionViewModule,
    osagoInsurantModule,
    osagoOwnerModule,
    osagoParticipantsModule,
    osagoPreviousContractModule,
    osagoSpecialNoteModule,
    osagoUsagePeriodModule,
    osagoVehicleParamsModule,
    osagoVehicleTransdekraPicker,
    osagoVehicleTransdekraPicker,
    osagoServices,
    osagoDriversModule,
    ugskThemeSwitcher,
    osagoUAutoContractInfoModule,
]).run((productsService: ProductsService) => {
    "ngInject";

    productsService.register(new Osago());
}).service("relatedProductService", RelatedProductService).name;
