import angular from "angular";
import { ILogicRule } from "./interfaces/ILogic";

export class Rule implements ILogicRule {
    /**
     * Метод преобразования мета-языка в js-синтаксис
     */
    public static translate(str: string): string {
        if (!str) {
            return "";
        }
        [{
            re: /\seq\s/g,
            to: " === ",
        }, {
            re: /\sor\s/g,
            to: " || ",
        }, {
            re: /\sand\s/g,
            to: " && ",
        }, {
            re: /\sne\s/g,
            to: " !== ",
        }, {
            re: /\sgt\s/g,
            to: " > ",
        }, {
            re: /not\(([^)]{1,})\)/g,
            to: (original: string, argument: string) => `!(${argument})`,
        }, {
            re: /defined\(([^)]{1,})\)/g,
            to: (original: string, argument: string) => `(${argument} !== undefined)`,
        }].forEach((r) => {
            // TODO opened this issue on Mar 7, 2018 https://github.com/Microsoft/TypeScript/issues/22378
            if (typeof r.to === "string") {
                str = str.replace(r.re, r.to);
            } else {
                str = str.replace(r.re, r.to);
            }
        });
        return str;
    }
    public then: string;
    public when: string;
    public else: string;
    public properties: string[];
    public description: string;
    public once: boolean;
    /**
     * Creates an instance of Rule.
     * ruleObject содержит описание правила
     */
    constructor(ruleObject: ILogicRule) {
        this.when = Rule.translate(ruleObject.when);
        this.then = Rule.translate(ruleObject.then);
        this.else = Rule.translate(ruleObject.else);
        this.properties = ruleObject.properties;
        this.description = ruleObject.description;
        this.once = ruleObject.once;
    }
}

interface IRulesScope extends ng.IScope {
    locals?: object;
}
/**
 * Модуль для применения правил в указанном scope
 * В качестве выражений для выполнения правил используются `AngularJS expressions`
 * они компилируются из мета-языка описания правил
 *
 * @export
 * @class RulesExecutor
 */
export class RulesExecutor {
    public static execute(scope: IRulesScope, rules: Rule[]): void {
        angular.forEach(rules, (rule) => {
            if (rule.once) {
                if (rule[RulesExecutor.runOnceFlag]) {
                    return;
                }
                rule[RulesExecutor.runOnceFlag] = true;
            }
            const condition = scope.$eval(rule.when, scope.locals);
            if (condition) {
                scope.$eval(rule.then, scope.locals);
            } else {
                scope.$eval(rule.else, scope.locals);
            }
        });
    }
    public static compileRule(rule: ILogicRule | Rule): Rule {
        return (rule instanceof Rule) ? rule : new Rule(rule);
    }
    private static runOnceFlag = Symbol("runOnceFlag");
}
