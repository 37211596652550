import { Contract } from "domain/classes/contract.class";
import { IJournalContractDTO } from "./interfaces/IJournalContractDTO";
import { Int } from "./types";

export function journalContractClassFactory<T extends new(...args: any[]) => Contract>(parentClass: T) {
    return class extends parentClass implements IJournalContractDTO {
        public ProductCode: string;
        public InsuranceProductName: string;
        public InsuranceObject: string | {};
        public labelClass?: string;
        public ProductName?: string;
        public StatusId: Int;
        public StatusCode: Int;
        public ContractId: Int;
        public PaymentKindId: Int;
        //  @override
        public getStatus() {
            return this.StatusId;
        }

        //  @override
        public setStatus(newStatus: Int) {
            this.StatusId = newStatus;
        }

        //  @override
        public getFirstFactPaymentKindId() {
            return this.PaymentKindId;
        }

        //  @override
        public getPlannedPaymentsCount() {
            return this.PaymentCount; //  Journal
        }

        //  @override
        public $annul() {
            return super.$annul().then(() => {
                this.StatusName = "Аннулирован";
            });
        }

        //  @override
        public $unlock() {
            return super.$unlock().then(() => {
                this.StatusCode = 1;
            });
        }
    };
}
