import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";

export default {
    action(context) {
        return context.Contract.getResourceProvider().invalidateFactPayments({
            Id: context.Contract.Id,
        }).$promise.then(() => {
            context.Contract.PaymentCanBeChange = false;
        });
    },
    disabled(context) {
        return !context.Contract.PaymentCanBeChange;
    },
    icon: "fa-times-circle",
    title: "Есть изменения данных платежа",
} as IExtraOptionPrototype;
