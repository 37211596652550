import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { UAutoToolbarController } from "../uAutoToolbar.controller";
import { NotifyService } from "infrastructure/services/notifyService";

export default {
    icon: "fa fa-refresh",
    title: "Сбросить кэш КБМ",
    action(context: UAutoToolbarController) {
        const notifyService = context.di<NotifyService>("notifyService");
        return context.Contract.$resetCacheKBM().then(() => {
            notifyService.successMessage(
                null,
                "Кэш КБМ успешно сброшен",
            );
        }).catch(() => {
            notifyService.errorMessage(
                null,
                "Ошибка при попытке сброса кэша КБМ",
            );
        });
    },
} as IExtraOptionPrototype;
