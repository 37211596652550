import { IModalService, IModalServiceInstance } from "angular-ui-bootstrap";
import { CONTRACT_STATUSES } from "application/constants";
import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { AsyncContractSaleService } from "infrastructure/services/async.contract.sale.service";
import { NotifyService } from "infrastructure/services/notifyService";
import moment from "moment";
import cancelContractTemplate from "./cancelContract.html";

class CancelContractController {
    static get $inject() {
        return ["$uibModalInstance", "notifyService", "asyncContractSaleService", "context"];
    }

    constructor(
        private $uibModalInstance: IModalServiceInstance,
        private notifyService: NotifyService,
        private asyncContractSaleService: AsyncContractSaleService,
        private context: any,
    ) {}

    public ok() {
        this.notifyService.progressStart("Подождите, пожалуйста...");
        this.asyncContractSaleService.cancelOsago(this.context.Contract.Id).on("onResult", (result) => {
            if (result.status === 200) {
                this.notifyService.progressStop();
                this.$uibModalInstance.close({ result });
                if (result.response.Header.Complete && result.response.Header.ProcessingResultCode === 0) {
                    this.notifyService.successMessage(undefined, "Операция выполнена успешно.");
                    if (this.context.getViewType() !== "journal") {
                        this.context.pageSharedData.Form.$setPristine();
                        this.context.$state.go(this.context.$state.current.name, { id: this.context.Contract.Id, update: Date.now() }, { inherit: false, reload: true });
                    } else {
                        this.context.Contract.StatusCode = CONTRACT_STATUSES.CANCELED;
                        this.context.Contract.StatusId = CONTRACT_STATUSES.CANCELED;
                        this.context.Contract.StatusName = "Отменен";
                    }
                }
            } else if (result.status !== 500) {
                this.notifyService.progressStop();
                this.notifyService.errorMessage("Ошибка", result.message);
            }
        });
    }

    public close() {
        this.$uibModalInstance.close();
    }
}

export default {
    disabled(context): boolean {
        const contract: any = context.Contract;
        const isFreshContract = moment() <= moment(contract.SigningDate).add(20, "day");
        const userHasPermission = context.originalEmployee.hasPermission("cancel", "Osago");
        const whiteList = ["ААС", "ААМ", "ТТТ", "ААН"];
        const policySerial = contract.PolicySerial;
        const isPaid = context.getViewType() === "form" ? contract.IsPayed : contract.IsPaid;
        const statusesWhiteList = [
            CONTRACT_STATUSES.DRAFT,
            CONTRACT_STATUSES.PREPARED_FOR_SIGNING,
            CONTRACT_STATUSES.SIGNED,
        ];
        const hasSuitableSerial = context.getViewType() === "form"
            ? whiteList.includes(policySerial)
            : whiteList.some((serial) => contract.PolicyFullValue.indexOf(serial) >= 0);
        const hasSuitableContractStatus = context.getViewType() === "form"
            ? statusesWhiteList.includes(contract.ContractStatusId)
            : statusesWhiteList.includes(contract.StatusId);

        if (userHasPermission && hasSuitableContractStatus && !isPaid && isFreshContract && hasSuitableSerial) {
            return false;
        }
        return true;
    },
    action(context): void {
        const $uibModal = context.di<IModalService>("$uibModal");
        $uibModal
            .open({
                controller: CancelContractController,
                controllerAs: "vm",
                resolve: {
                    context,
                },
                template: cancelContractTemplate,
            })
            .result.then(({ result }) => {
                (context as any).showCommonErrorsNotifications(result.response.Errors.CommonErrors);
            });
    },
    icon: "fa-times",
    title: "Отменить договор",
    tooltip: "Доступно только для договоров в статусе &#34;Оформлен&#34;/&#34;Подготовлен к продаже&#34; и &#34;Не оплачен&#34;",
} as IExtraOptionPrototype;
