import ISelectController from "infrastructure/interfaces/ISelectController";
import "./selectpicker.directive.sass";

export function SelectpickerDirective(): ng.IDirective {
    "ngInject";

    return {
        link: {
            post: (
                scope: ng.IScope,
                element: ng.IAugmentedJQuery,
                attributes: ng.IAttributes,
                ctrl: ISelectController,
            ) => {
               ctrl.unknownOption.text("Ничего не выбрано");
            },
        },
        require: "select",
        restrict: "A",
    };
}
