import angular from "angular";
import { Product } from "domain/classes/product.class";
import { Helpers } from "./app.helpers";
import { VersionsInfoService } from "./services/versionsInfo.service";

export class ProductVersionInfoController {
    public productVersionInfo: string;

    constructor(currentProduct: Product) {
        this.productVersionInfo = currentProduct.productName;
        if (currentProduct.version) {
            if (currentProduct.code === "osago") {
                if (currentProduct.version.TariffVersion) {
                    this.productVersionInfo += `: тарифы обновлены ${currentProduct.version.TariffVersion}`;
                }
                if (currentProduct.version.TariffDate) {
                    this.productVersionInfo += `, действуют с ${currentProduct.version.TariffDate}`;
                }
            } else {
                if (currentProduct.version.TariffVersion) {
                    this.productVersionInfo += `: ${currentProduct.version.TariffVersion}`;
                }
                if (currentProduct.version.TariffDate) {
                    this.productVersionInfo += ` от ${currentProduct.version.TariffDate}`;
                }
            }
        }
    }

    static get $inject() {
        return ["currentProduct"];
    }
}

class VersionInfoController {
    public apiVersion: string;
    public appVersion: string;

    constructor(versionsInfoService: VersionsInfoService, helpers: Helpers) {
        if (!helpers.isEmbedMode()) {
            versionsInfoService.getVersions().then((version) => {
                this.apiVersion = version.AssemblyVersion;
                this.appVersion = version.appVersion;
            });
        }
    }
    static get $inject() {
        return ["versionsInfoService", "helpers"];
    }
}

angular.module("VersionInfoController", []).controller("versionInfoController", VersionInfoController);
