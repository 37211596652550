import { Contract } from "domain/classes/contract.class";
import { DictionariesService } from "infrastructure/services/dictionaries.service";
import { ProductsJournalController } from "../../journal/journal.controller";

export class BoxVHIJournalController extends ProductsJournalController {
    public $onInit(): void {
        /* tslint:disable: object-literal-sort-keys */
        this.columns = [{
            name: "InsuranceProductName",
            displayText: "Продукт",
            requires: ["InsuranceProductCode"],
            title: "Продукт",
            type: "Edm.String",
            class: (item: Contract) => {
                const code = item.InsuranceProductCode;
                if (code) {
                    return "label label-" + code.toLowerCase();
                }
                return "label label-plain";
            },
            getVariants: () => {
                const dictionariesService = this.di<DictionariesService>("dictionariesService");
                return dictionariesService.get("BoxVHIContractJournalItems/K3.GetBoxVHIProducts")
                    .then((products) => products.map((product) => ({
                        title: product.MarketingProductName,
                        value: product.MarketingProductName,
                    })));
            },
            inlineFilter: true,
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Серия-номер полиса",
            inlineFilter: true,
            name: "PolicyNumber",
            pinned: true,
            renderer: (item) => item.PolicyFullValue,
            tooltipText: "Поиск только<br/>по номеру",
            type: "Edm.String",
        }, {
            name: "InsuredName",
            displayText: "Страхователь",
            width: 2,
            pinned: true,
            defaultFilterOperation: "startswith",
            inlineFilter: true,
            type: "Edm.String",
        }, {
            name: "InsuredPersonsFullName",
            displayText: "Застрахованный",
            inlineFilter: true,
            type: "Edm.String",
            template: `
                <ugsk-collapsible
                    value="item.InsuredPersonsFullName"
                    separator=";">
                </ugsk-collapsible>
            `,
        }, {
            name: "SigningDate",
            displayText: "Дата заключения договора",
            ngFilter: this.dateFormatWithoutTimeZone,
            pinned: true,
            defaultFilterOperation: "eq",
            inlineFilter: true,
            type: "Edm.DateTimeOffset",
        }, {
            name: "ContractFrom",
            displayText: "Начало срока страхования",
            ngFilter: this.dateFormatWithoutTimeZone,
            inlineFilter: true,
            type: "Edm.DateTimeOffset",
        }, {
            name: "ContractTo",
            displayText: "Окончание срока страхования",
            ngFilter: this.dateFormatWithoutTimeZone,
            inlineFilter: true,
            type: "Edm.DateTimeOffset",
        }, {
            name: "InsurancePremium",
            displayText: "Страховая премия",
            ngFilter: "thousand",
            inlineFilter: true,
            type: "Edm.Int32",
        }, {
            name: "EmployeeFilialName",
            displayText: "Филиал",
            pinned: true,
            defaultFilterOperation: "startswith",
            inlineFilter: true,
            type: "Edm.String",
        }, {
            name: "EmployeeName",
            displayText: "Пользователь",
            inlineFilter: true,
            type: "Edm.String",
        }, {
            name: "SaleChannel",
            displayText: "Канал продаж",
            pinned: true,
            defaultFilterOperation: "startswith",
            inlineFilter: true,
            type: "Edm.String",
        }, {
            name: "StatusName",
            displayText: "Статус договора",
            getVariants: () => this.getStatusVariants(),
            pinned: true,
            defaultFilterOperation: "eq",
            inlineFilter: true,
            type: "Edm.String",
            template: `
                <span
                    ng-bind="item.StatusName"
                    ng-class="vm.getContractStatusLabelClass(item)">
                </span>
            `,
        }, {
            name: "EmployeeSubdivisionName",
            displayText: "Подразделение",
            visible: false,
            inlineFilter: true,
            defaultFilterOperation: "startswith",
            type: "Edm.String",
        }, {
            name: "AddressUserDefinedAddress",
            displayText: "Адрес объекта страхования",
            visible: false,
            type: "Edm.String",
        }, {
            name: "PaymentKindName",
            displayText: "Вид оплаты",
            visible: false,
            type: "Edm.String",
        }, {
            name: "Id",
            displayText: "Id-договора",
            visible: false,
            type: "Edm.Int32",
        }, {
            displayText: "Серия и номер полиса",
            name: "PolicyFullValue",
            type: "Edm.String",
            visible: false,
        }];
        super.$onInit();
        /* tslint:enable: object-literal-sort-keys */
    }
}
