import { Transition } from "@uirouter/core";
import { BaseAutoToolbarController } from "application/baseAuto.toolbar.controller";
import { UAUTO_PROGRAM_TOTAL_THEFT_PLUS, USER_ROLES } from "application/constants";
import prolongationDenial from "infrastructure/extraMenuOptionsPresets/prolongationDenial";
import IBaseViewParams from "infrastructure/interfaces/IBaseViewParams";
import IBlockUI from "infrastructure/interfaces/IBlockUI";
import { DictionariesService } from "infrastructure/services/dictionaries.service";
import { NotifyService } from "infrastructure/services/notifyService";
import approve from "./extraMenuOptionsPresets/approve";
import freeze from "./extraMenuOptionsPresets/freeze";
import requestApproval from "./extraMenuOptionsPresets/requestApproval";
import sendOffer from "./extraMenuOptionsPresets/sendOffer";
import sendOfferEds from "./extraMenuOptionsPresets/sendOfferEds";
import unfreeze from "./extraMenuOptionsPresets/unfreeze";
import { UAutoContract } from "./uAuto.factory";
import angular from "angular";
import UgskPhone from "domain/classes/ugsk-phone.class";
import ISignParams from "infrastructure/interfaces/ISignParams";
import resetCacheKBM from "./extraMenuOptionsPresets/resetCacheKBM";
import prolongate from "application/uAuto/extraMenuOptionsPresets/prolongate";

export class UAutoToolbarController extends BaseAutoToolbarController {
    public Contract: UAutoContract;
    private autoGeneratedPolicySerials: string[] = [];

    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
        {
            viewType = "form",
        }: IBaseViewParams,
    ) {
        super($injector, $transition, {
            viewType,
            printOptions: [
                {
                    title: "Печать полиса",
                    action() {
                        return this.openPdf({ id: this.Contract.Id }).then(
                            () => {},
                            () => {},
                        );
                    },
                    disabled() {
                        return this.isDisabledPrint();
                    },
                    icon: "fa-print",
                },
                {
                    title: "Печать заявления",
                    action(): angular.IPromise<void> {
                        return this.openPdf({
                            docType: "StatementTotalStealingPlus",
                            id: this.Contract.Id,
                        }).then(
                            null,
                            (reason) => {
                                let errorMessage = "В процессе формирования документа произошла ошибка";
                                if (reason && reason.data && reason.data.Message && typeof reason.data.Message === "string") {
                                    errorMessage = reason.data.Message;
                                }

                                this.notifyService.errorMessage("Печать заявления", errorMessage);
                            },
                        );
                    },
                    disabled(): boolean {
                        return this.isStatementTotalStealingPlusPrintDisabled();
                    },
                    icon: "fa-print",
                    isVisible(): boolean {
                        return this.isStatementTotalStealingPlusPrintVisible();
                    }
                },
                {
                    title: "Лист расчёта",
                    action() {
                        return this.openPdf({
                            id: this.Contract.Id,
                            docType: "CalculationSheet",
                        });
                    },
                    disabled() {
                        return this.isDisabledPrint();
                    },
                    icon: "fa-print",
                }, {
                    title: "Чек андеррайтера",
                    action() {
                        return this.openPdf({
                            id: this.Contract.Id,
                            docType: "UnderwriterCheck",
                        });
                    },
                    disabled() {
                        return this.isDisabledPrint();
                    },
                    icon: "fa-print",
                }, {
                    title: "Акт ПСО",
                    action() {
                        return this.openPdf({
                            id: this.Contract.Id,
                            docType: "InspectionConclusionReport",
                            _suppressErrors: true,
                        }).catch(() => {
                            this.notifyService.errorMessage("Ошибка", "Печать Акта ПСО для данного договора не доступна");
                        });
                    },
                    disabled() {
                        return this.isDisabledPrint();
                    },
                    icon: "fa-print",
                },
                "worksheet",
                {
                    title: "Форма запроса согласования",
                    action() {
                        return this.downloadRtf({
                            docType: "ApproveRequest",
                            fileNamePrefix: "Запрос согласования",
                            id: this.Contract.Id,
                        });
                    },
                    disabled() {
                        return this.isDisabledPrint();
                    },
                    icon: "fa-print",
                },
                "freeFormReceipt",
            ],
            crossOptions: ["uAuto", "osago", "snugHome", "express", "myChoice",
                "constructorHome", "assuranceHome", "assuranceApartment"],
            extraOptions: [
                "returnForRevision",
                "annul",
                "changeOwner",
                "manual",
                "integrationInfo",
                prolongate,
                prolongationDenial,
                requestApproval,
                approve,
                sendOffer,
                sendOfferEds,
                "unlockContract",
                freeze,
                unfreeze,
                resetCacheKBM,
            ],
        });

        this.notifyService = this.di<NotifyService>("notifyService");
    }

    public $onInit(): void {
        super.$onInit();

        const blockUI = this.di<IBlockUI>("blockUI")
        const dictionariesService = this.di<DictionariesService>("dictionariesService");
        blockUI.start("Получение справочных данных");
        dictionariesService.get("PolicySerials").then((serials) => {
            if (serials && Array.isArray(serials)) {
                this.autoGeneratedPolicySerials = serials.filter((serial) => serial.IsAutoGenerated && serial.ProductId === 1).map((serial) => serial.Value) // uAuto productId = 1
            }
        }).finally(() => {
            blockUI.stop();
        });
    }

    /**
     * @override
     */
    public hasInspectionPermission() {
        return true;
    }
    /**
     * В КАСКО/ОСАГО нет способа определить тип оплаты из журнала
     * А на странице договора этих кнопок нет совсем,
     * так что кнопки для печати счетов доступны всегда,
     * а бэк сам разберется
     * @override
     * @returns {Boolean}
     */
    /* eslint-disable class-methods-use-this */
    public isDisabledPrintPaymentAccount() {
        return false;
    }

    /**
     * @override
     * @return {Boolean}
     */
    public isDisabledSell(): boolean {
        return this.Contract.isFrozen()
            || this.Contract.isApproved() ? false : super.isDisabledSell();
    }

    public checkedResultDisabledCondition(): boolean {
        return this.pageSharedData.currentProduct === "uAuto"
            && (
                this.Contract.checkedResult === "Demand" && this.employee.info && this.employee.info.Role !== USER_ROLES.UNDERWRITER
                || ["Forbidden", "Warning", "Attention", "ForbiddenAsWarning", ""].includes(this.Contract.checkedResult)
            );
    }

    /**
     * Полис с ЭЦП
     */
    public isPolicyWithEds(): boolean {
        if (this.Contract) {
            if (this.viewType === "form") {
                return this.autoGeneratedPolicySerials.includes(this.Contract.PolicySerial);
            }

            // в журналах (ReadModel) отсутствует серия полиса, только полное значение `PolicyFullValue`
            return this.autoGeneratedPolicySerials.some(value => this.Contract.PolicyFullValue.indexOf(value) > 0);
        }

        return false;
    }

    public signContract() {
        return super.signContract().then(() => {
            this.focusToPayments();
        });
    }

    /**
     * @description Реализация продублирована из базового класса - добавлен метод проверки ScoringRate
     */
    public signContractWithPhoneCheck(signParamsObj: ISignParams = {
        notShowToBeVerifiedText: false,
    }) {
        // ==============
        this.notifyService.removeToasts();

        const [
            phoneValidationService,
        ] = this.resolve([
            "phoneValidationService",
        ]);
        const titleText = "Подтверждение продажи";
        let bodyText = "<p>Перевести договор в статус &laquo;Продан&raquo;?" +
            "<br>Проданные договоры нельзя редактировать.</p>" +
            '<p class="text-accent">Оплатить договор необходимо не&nbsp;позднее даты, указанной на&nbsp;вкладке ' +
            "&laquo;Оплата&raquo;, оплата после указанной даты невозможна.</p>";
        let phones: UgskPhone[] = null;
        let needConfirmPhone = false;

        if (!signParamsObj.notShowToBeVerifiedText) {
            phones = this.Contract.getInsurantPhones();
            const firstUnverifiedNumber = phoneValidationService.getFirstUnverifiedPhoneNumber(phones);
            if (firstUnverifiedNumber) {
                bodyText +=
                    `<br />На номер клиента (${firstUnverifiedNumber}) будет выслано СМС с кодом подтверждения.`;
                needConfirmPhone = true;
            }
        }
        return this.helpers.confirm({ text: bodyText, title: titleText }).then(() => this.checkScoringRate()).then(() => super.signContract()).then(() => {
            const formModified = Boolean(this.pageSharedData.Form.modified);
            if (needConfirmPhone && phones) {
                return phoneValidationService.checkAndConfirmPhones({
                    autoSendCode: true,
                    phones,
                }).then((confirmedPhones: UgskPhone[]) => {
                    this.Contract.setInsurantPhones(confirmedPhones);
                    this.pageSharedData.Form.modified = formModified;
                });
            }
            return true;
        }).then(() => {
            this.focusToPayments();
        });
    }

    public isStatementTotalStealingPlusPrintDisabled(): boolean {
        const hasPermission = this.employee.hasPermission("GetStatementTotalStealingPlus", this.pageSharedData.currentProduct);
        const isSuitableProgramSelected = this.Contract ? this.Contract.InsuranceProgram === UAUTO_PROGRAM_TOTAL_THEFT_PLUS : false;

        return !hasPermission || !isSuitableProgramSelected || this.isDisabledPrint();
    }

    public isStatementTotalStealingPlusPrintVisible(): boolean {
        return this.Contract ? this.Contract.InsuranceProgram === UAUTO_PROGRAM_TOTAL_THEFT_PLUS : false;
    }

    private checkScoringRate(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.Contract.hasScoringRate().then((response) => {
                if (response.result) {
                    return resolve();
                }

                const hasPermission = this.employee.hasPermission("CanSignWithoutScoringState", this.pageSharedData.currentProduct);

                if (!hasPermission) {
                    this.notifyService.errorMessage(
                        "Продажа договора невозможна",
                        // tslint:disable-next-line:max-line-length
                        "Ошибка проверки. Попробуйте рассчитать повторно через несколько минут. В случае, если ошибка повторяется - обратитесь к андеррайтеру.",
                    );

                    return reject();
                }

                this.notifyService.message("Ошибка проверки", "Требуется подтвердить продажу.", {
                    timeout: 5000,
                    type: "warning",
                });

                this.helpers.confirm({
                    text: "Требуется подтвердить продажу договора, не прошедшего проверку.",
                    title: "Ошибка проверки договора.",
                }).then(() => {
                    return resolve();
                }, () => {
                    return reject();
                });
            });
        });
    }
}
