import { NgComponentController } from "infrastructure/NgController";
import ugskTouchSpin from "../ugsk-touchspin/ugskTouchSpin.component";

import template from "./ugskSublimits.component.html";

export default angular.module("ugsk.components.sublimits", [ugskTouchSpin]).component("ugskSublimits", {
    bindings: {
        collections: "<",
        ngModel: "=",
    },
    controller: class extends NgComponentController {
        getSublimitsSum() {
            return this.ngModel.reduce((accumulator, current) => accumulator + current.Value, 0);
        }
        isPercentSumValid() {
            return this.getSublimitsSum() !== 100;
        }
    },
    controllerAs: "vm",
    template,
}).name;
