import { IPromise, IQService } from "angular";
import { IModalService, IModalServiceInstance } from "angular-ui-bootstrap";
import {
  IRepositoryResourceFactoryFn,
  TContractResourceFactoryFn,
} from "infrastructure/app.resource";
import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import ProlongationDenialInfoDTO from "infrastructure/interfaces/WebApi/ProlongationDenialInfoDTO";
import Repository from "infrastructure/Repository.class";
import { NotifyService } from "infrastructure/services/notifyService";
import prolongationDenialTemplate from "./prolongationDenial.html";

export default {
  action: (context) => {
    const $uibModal = context.di<IModalService>("$uibModal");
    const notifyService = context.di<NotifyService>("notifyService");
    const $q = context.di<IQService>("$q");

    const contractId = context.Contract.Id;

    //  @TODO: typeme
    const ContractResourceFactory = context.di<TContractResourceFactoryFn>(
      "contractResourceFactory",
    );
    const contractResource = ContractResourceFactory(context.product.code);

    const repositoryService = context.di<IRepositoryResourceFactoryFn>(
      "repositoryService",
    );
    const repositoryServiceInstance = repositoryService(context.product.code);
    const ContractRepository = new Repository(repositoryServiceInstance);
    notifyService.progressStart("Загрузка договора");

    const denailInfo =
        context.product.code === "osago"
        ? contractResource
            .getOsagoProlongationDenialInfo({
              _suppressErrors: true,
              contractId,
            })
            .$promise.catch(() => {
              return {
                Comment: null,
                ContractId: contractId,
                Id: null,
                ProlongationDenialReasonName: null,
              };
            })
        : contractResource
            .getProlongationDenialInfo({
              _suppressErrors: true,
              id: contractId,
            })
            .$promise.catch(() => {
              return {
                Comment: null,
                ContractId: contractId,
                Id: null,
                ProlongationDenialReasonName: null,
              };
            });
    $q.all([
      denailInfo,
      contractResource.editContract({ id: contractId }).$promise,
    ]).then(([prolongationDenialInfo, contractDTO]) => {
      ContractRepository.load(contractDTO).then(() => {
        notifyService.progressStop();
        $uibModal.open({
          backdrop: "static",
          controller: class {
            public repository: Repository = ContractRepository;
            public denial: ProlongationDenialInfoDTO = prolongationDenialInfo;
            static get $inject() {
              return ["$uibModalInstance"];
            }
            constructor(private $uibModalInstance: IModalServiceInstance) {
              "ngInject";
            }
            public close() {
              this.$uibModalInstance.dismiss();
            }
            public saveProlongationDenialInfo() {
              notifyService.progressStart("Сохранение");
              let save: IPromise<void>;
              if (context.product.code === "uAuto") {
                save = contractResource.saveProlongationDenialInfo(
                  {
                    id: this.denial.ContractId,
                  },
                  this.denial,
                ).$promise;
              }
              if (context.product.code === "osago") {
                save = contractResource.saveOsagoProlongationDenialInfo(
                  {},
                  this.denial,
                ).$promise;
              }
              save
                .then(() => {
                  notifyService.successMessage(
                    "Причина непролонгации сохранена",
                  );
                })
                .finally(() => notifyService.progressStop());
            }
            public isDisableComment() {
              return (
                context.Contract.isAnnuled() ||
                context.Contract.isTerminated() ||
                context.Contract.isSigned()
              );
            }
          },
          controllerAs: "vm",
          template: prolongationDenialTemplate,
        });
      });
    });
  },
  disabled(context) {
    return context.Contract.isBlank();
  },
  icon: "fa-ban",
  title: "Причина непролонгации",
} as IExtraOptionPrototype;
