import angular from "angular";
import { UAUTO_PROGRAM_DEALER_REPAIR } from "application/constants";
import { UAutoContract } from "application/uAuto/uAuto.factory";
import { UAutoRepository } from "application/uAuto/uAuto.repository";
import { ILogic } from "infrastructure/interfaces/ILogic";
import { NgComponentController } from "infrastructure/NgController";
import { NotifyService } from "infrastructure/services/notifyService";
import { Int } from "infrastructure/types";
import moment from "moment";
import template from "./ugskUautoVehicleParams.component.html";
import { DictionariesService } from "infrastructure/services/dictionaries.service";

class UAutoVehicleParamsController extends NgComponentController {
    public contract: UAutoContract;
    public repository: UAutoRepository;
    public vehicleTVCountDisabled: boolean;
    public userDefinedMarkModelPostfix: string | null;
    public userDefinedMarkModelBase: string;
    public userDefinedMarkModelPartialEditMode: boolean;
    public visibleAntiTheftSystems = true;
    public onRepositoryFieldChanged: (params: object) => angular.IPromise<void>;
    public isCrossed: boolean;
    public previousModelSpelt: string;
    public engineTypes: any[];
    private dictionariesService: DictionariesService;

    public onInit(): void {
        this.repository = this.contract.getRepository();
        this.vehicleTVCountDisabled = true;

        this.userDefinedMarkModelBase = this.getDefaultUserDefinedMarkModel();
        this.userDefinedMarkModelPostfix = "";

        if (
            this.isCrossed
            && this.contract.VehicleModelSpelt
            && !this.contract.VehicleModel
        ) {
            this.previousModelSpelt = this.contract.VehicleModelSpelt;
        }

        if (
            this.userDefinedMarkModelPartialEditMode
            && this.contract.UserDefinedMarkModel
            && this.userDefinedMarkModelBase
        ) {
            /* tslint:disable-next-line:max-line-length */
            this.userDefinedMarkModelPostfix = this.contract.UserDefinedMarkModel.substring(this.userDefinedMarkModelBase.length).trim();
            this.$scope.$watch(() => this.userDefinedMarkModelBase, (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    this.userDefinedMarkModelPostfix = null;
                }
            });
        }

        this.$scope.$watch(() => this.contract.InsuranceProgram, () => {
            this.visibleAntiTheftSystems = !(this.contract.InsuranceProgram === UAUTO_PROGRAM_DEALER_REPAIR);
        });

        this.dictionariesService = this.di("dictionariesService");
        this.dictionariesService.get("EngineType").then((engineTypes) => {
            if (Array.isArray(engineTypes)) {
                this.engineTypes = engineTypes;
            }
        });
    }

    get logic(): ILogic {
        const vm = this;
        return {
            condition() {
                return !vm.contract.isLocked();
            },
            /* tslint:disable object-literal-sort-keys */
            rules: [{
                description: "Когда в блоке 'Комплектация ТС' (установлен) признак телевизор",
                when: "contract.VehicleAccessories.includes('Телевизор')",
                then: "vehicleTVCountDisabled = false",
                else: "contract.VehicleTVCount = 0; vehicleTVCountDisabled = true",
            }, {
                description: "Отключение галки 'Новая ТС' если она заблокирована",
                when: "isIsNewDisabled()",
                then: "contract.IsNew = false",
            }],
            /* tslint:enable object-literal-sort-keys */
        };
    }

    public getYearsMadeArray(): Int[] {
        if (this.repository.VehicleYearMade && this.repository.VehicleYearMade.Collection) {
            return this.repository.VehicleYearMade.Collection.map((item) => Number(item.VehicleYearMade));
        }
        return [];
    }

    public getMinYear(): Int {
        const years = this.getYearsMadeArray();
        if (years.length) {
            return Math.min(...years);
        }
        return 1950;
    }

    public getMaxYear(): Int {
        const years = this.getYearsMadeArray();
        if (years.length) {
            return Math.max(...years);
        }
        return moment().year();
    }

    public onUnderinsuranceAmountChange(): void {
        if (!this.contract.AppliesUnderinsurance) {
            this.contract.UnderinsuranceAmount = null;
        }
    }

    public onVehicleYearMadeOutOfSet(): void {
        const notifyService = this.di<NotifyService>("notifyService");
        const yearsString = this.getYearsMadeArray().map((item) => String(item)).join(", ");
        notifyService.warningMessage(
            "Год выпуска ТС",
            `Допустимо к страхованию ТС с годом выпуска только из списка: [${yearsString}]. По вопросам страхования других ТС обращайтесь в УАА.`,
        );
    }

    public getDefaultUserDefinedMarkModel(): string {
        const model = this.contract.VehicleModelSpelt || "";
        const modification = this.contract.VehicleModification || "";
        return [model.trim(), modification.trim()].filter((item) => Boolean(item)).join(" ");
    }

    public onVehicleModelChanged(): void {
        this.previousModelSpelt = null;
        this.onRepositoryFieldChanged({ fieldName: "VehicleModel" }).then(() => {
            const markModel = this.getDefaultUserDefinedMarkModel();
            this.contract.UserDefinedMarkModel = markModel;
            this.userDefinedMarkModelBase = markModel;
            this.updateVehicleYearMade();
        });
    }

    public onVehicleModificationChanged(): void {
        this.onRepositoryFieldChanged({ fieldName: "VehicleModification" }).then(() => {
            const markModel = this.getDefaultUserDefinedMarkModel();
            this.contract.UserDefinedMarkModel = markModel;
            this.userDefinedMarkModelBase = markModel;
            this.updateVehicleYearMade();
            this.contract.VehicleEnginePowerHP = null;
        });
    }

    public onUserDefinedMarkModelPostfixChanged(): void {
        this.contract.UserDefinedMarkModel = `${this.userDefinedMarkModelBase} ${this.userDefinedMarkModelPostfix}`;
    }

    public isIsNewDisabled(): boolean {
        const repository = this.contract.getRepository();
        return (repository.IsNew) ? Boolean(repository.IsNew.State) : true;
    }

    public onEnginePowerChanged(): void {
        this.contract.VehicleModification = null;
        this.contract.UserDefinedMarkModel = null;
    }

    private updateVehicleYearMade(): void {
        const years = this.getYearsMadeArray();
        if (years.length) {
            if (!years.includes(this.contract.VehicleYearMade)) {
                this.contract.VehicleYearMade = Math.max(...years);
            }
        }
    }
}

export default angular.module("app.uauto.components.vehicle-params", []).component("ugskUautoVehicleParams", {
    bindings: {
        contract: "<",
        isCrossed: "<",
        onRepositoryFieldChanged: "&",
        userDefinedMarkModelPartialEditMode: "<",
    },
    controller: UAutoVehicleParamsController,
    controllerAs: "vm",
    template,
}).name;
