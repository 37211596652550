import "angular-sanitize";
import "angular-animate";

import "node_modules/npm-font-open-sans/open-sans.css";
import "node_modules/bootstrap/dist/css/bootstrap.css";
import "node_modules/font-awesome/css/font-awesome.css";
import "node_modules/angular-ui-bootstrap/dist/ui-bootstrap-csp.css";
import "node_modules/animate.css/animate.css";
import "node_modules/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css";
import "node_modules/ng-table/bundles/ng-table.css";
import "node_modules/angularjs-toaster/toaster.css";
import "node_modules/angular-block-ui/dist/angular-block-ui.css";
import "node_modules/ion-rangeslider/css/ion.rangeSlider.css";
import "node_modules/ion-rangeslider/css/ion.rangeSlider.skinHTML5.css";
import "node_modules/bootstrap-touchspin/dist/jquery.bootstrap-touchspin.css";
import "node_modules/angular-carousel/dist/angular-carousel.css";
import "node_modules/switchery-npm/index.css";
import "node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker3.css";
import "node_modules/bootstrap-select/dist/css/bootstrap-select.css";
import "node_modules/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css";
import "node_modules/ladda/dist/ladda-themeless.min.css";

import { OriginalEmployee } from "infrastructure/services/employee.service";
import "infrastructure/subLicensesController.ts";

import uiRouter from "lib/angular-ui-router-v1.0.0-rc.1";

import ugskValidationComponent from "application/components/ugsk-validation/ugskValidation.component";
import ugskBannersComponent from "application/components/ugsk-banners/ugskBanners.component";
import ugskAddressPickerComponent from "application/components/ugsk-address-picker/ugskAddressPicker.component";
import ugskPreviousContractComponent from "application/components/ugsk-previous-contract/ugskPreviousContract.component";
import ugskNotificationComponent from "application/components/ugsk-notification/ugskNotification.component";
import ugskPreviousContractFinderComponent from "application/components/ugsk-previous-contract-finder/ugskPreviousContractFinder.component";
import ugskTopNavigation from "application/components/ugsk-top-navigation/ugskTopNavigation.component";
import moment from "moment";
import moment_ru from "node_modules/moment/locale/ru";

import phoneValidationServiceFactory from "infrastructure/services/phoneValidation.service";

import errorHandlerModule from "infrastructure/error-handler.ts";
import logToServerModule from "infrastructure/logToServer.ts";

import "./dashboard/dashboard.module";

import "./assuranceApartment/assuranceApartment.init";
import "./assuranceHome/assuranceHome.init";
import "./snugHome/snugHome.init";
import express from "./express/express.init";
import "./myChoice/myChoice.init";
import "./constructorHome/constructorHome.init";
import "./boxVHI/boxVHI.init";
import "./personalAccident/personalAccident.init";
import "./boxAccident/adultAccident/adultAccident.init";
import "./boxAccident/childAccident/childAccident.init";
import "./boxAccident/familyAccident/familyAccident.init";
import "./boxAccident/safeCarAccident/safeCarAccident.init";
import "./boxAccident/shortTermAccident/shortTermAccident.init";
import "./travelingAbroad/travelingAbroad.init";
import osago from "./osago/osago.init";
import uAuto from "./uAuto/uAuto.init";

import "./paymentsRegistries/paymentsRegistries.init";
import "./userProfile/userProfile.config.route";
import "./selfRegistration/selfRegistration.init";
import errorPageModule from "./app.errorHandler";
import loginModule from "./login/login.module";
import imageRepositoryModule from "infrastructure/modules/imageRepository";
import snackbarModule from "infrastructure/modules/snackbar";

import { BodyController } from "./body.controller";
import { TopbarController } from "./topbar.controller.ts";

import * as Sentry from "@sentry/browser";
import { Angular as AngularIntegration } from "@sentry/integrations";
import pkg from "package.json";

//  TODO: Перенести зависимости модулей в те места, где они действительно нужны
const deps = [
    "ngResource",
    imageRepositoryModule,
    snackbarModule,
    loginModule,
    errorPageModule,
    errorHandlerModule,
    uiRouter,
    "ngSanitize",
    "ngAnimate",
    require("angular-ui-bootstrap/src/dropdown"),
    require("angular-ui-bootstrap/src/tabs"),
    require("angular-ui-bootstrap/src/modal"),
    require("angular-ui-bootstrap/src/popover"),
    require("angular-ui-bootstrap/src/typeahead"),
    require("angular-ui-bootstrap/src/tooltip"),
    "ngTable",
    "toaster",
    "ngAnimate",
    "angular.filter",
    "blockUI",
    "ngInputModified",
    "angular-carousel",
    logToServerModule,
    "ngStorage",
    "NgSwitchery",
    "mwl.confirm",
    "ngCsv",
    "infinite-scroll",
    "ugskPhones",
    "angular-ladda",
    "angularSpinner",
    "ODataResources",

    "ugsk.constants",
    "ugsk.resource",
    "ugsk.services",
    "ugsk.directives",
    "ugsk.filters",
    "ugsk.helpers",

    "ugsk.components",

    ugskBannersComponent,
    "ugsk.components.ugskSerial",
    ugskAddressPickerComponent,
    ugskPreviousContractComponent,
    "ugsk.components.icheck",

    ugskValidationComponent,
    ugskNotificationComponent,
    ugskPreviousContractFinderComponent,
    ugskTopNavigation,

    "app.dashboard",
    "app.payments.registries",
    "app.userProfile",
    "app.selfRegistration",
    "app.assuranceApartment",
    "app.assuranceHome",
    "app.constructorHome",
    express,
    "app.myChoice",
    "app.snugHome",
    "app.boxVHI",
    "app.personalAccident",
    "app.adultAccident",
    "app.childAccident",
    "app.familyAccident",
    "app.safeCarAccident",
    "app.shortTermAccident",
    "app.travelingAbroad",
    osago,
    // "app.kasko",
    uAuto,
    phoneValidationServiceFactory,
    "VersionInfoController",
    "SubLicensesController",
];

if (process.env.NODE_ENV === "production") {
    deps.push("ngSentry");
    Sentry.init({
        dist: "front.upro.20",
        dsn: "https://42cf542937d857306e52a29be02bc900@sentry.ugsk.ru/17",
        integrations: [
            new AngularIntegration(),
        ],
        release: pkg.version,
        replaysOnErrorSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        tracePropagationTargets: ["localhost"],
        tracesSampleRate: 1.0,
    });
}

angular.module("app", deps)
    .config(configStates)
    .config(configAnimate)
    .config(configBlockUI)
    .config(configLogger)
    .config(configDebugLog)
    .config(configHttpProvider)
    .config(configTooltipProvider)
    .config(configLadda)
    .config(configCompile)
    // TODO: Выпилить этот конфиг и решить проблемы вызываемые его отсутствием.
    .config(["$qProvider", function ($qProvider) {
      $qProvider.errorOnUnhandledRejections(false);
      }
    ])
    .run(registerStates)
    .run(run);

function configCompile($compileProvider, $locationProvider) {
  "ngInject";

  $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|mailto|tel|blob):/);
  $locationProvider.hashPrefix("");
}

function configStates($stateProvider, $urlRouterProvider, $compileProvider) {
    "ngInject";
    $compileProvider.debugInfoEnabled(false);
    $urlRouterProvider.otherwise("/");
}

function registerStates(
    $stateRegistry,
    notifyDesktopProvider,
    notifyStaticProvider,
    notifyService,
    employeeService,
    $state,
    envService,
    authenticationService,
    signalRService,
    $rootScope,
    $q,
    helpers,
) {
    "ngInject";

    $stateRegistry.register({
        name: "app",
        abstract: true,
        onEnter($localStorage) {
            "ngInject";

            signalRService.removeOverdueNotifications();

            employeeService.getEmployee().then(employee => {
                if (!helpers.isEmbedMode() && employee.info.NotificationGroupId) {
                    notifyDesktopProvider.requestPermission().then((result) => {
                        const signalRNotificationProviderCode = result === "granted" ? "desktop" : "toaster";
                        signalRService.connectToHub(employee.info.NotificationGroupId).then(() => {
                            signalRService.on("notification", (notification) => {
                                notifyService.infoMessage(notification.title, notification.body, {
                                    ...notification,
                                    provider: signalRNotificationProviderCode,
                                    timeout: 5000,
                                });
                                // запускаем digest для того, чтобы появилась иконка уведомлений. Важно, когда влкадка/окно неактивны
                                $rootScope.$digest();
                            });
                            signalRService.on("error", (error) => {
                                notifyService.errorMessage("Ошибка при обработке push сообщения", error.message);
                            });
                            $rootScope.$digest();
                        }).catch(() => {
                            notifyService.errorMessage(
                                "Сервис не доступен",
                                "Необходимо проверить доступность сервиса уведомлений.",
                            );
                        });
                    });
                }

                notifyService.message(
                    undefined,
                    `Уважаемый пользователь системы Ю-ПРО, просьба указать адрес электронной почты и номер мобильного телефона в <a href="#/userProfile/" class="under-line-selected">профиле пользователя</a>.`,
                    {
                        provider: "static",
                        type: "error",
                        displayCondition: () => {
                            if (employee.isImpersonated()) return false;
                            if (!employee.info) return false;
                            if (!employee.info.IsEmailMustBeEntered) return false;
                            if (employee.info.IsEmailConfirmed) return false;
                            return true;
                        }
                    });

                notifyService.message(
                    undefined,
                    `Уважаемый пользователь системы Ю-ПРО, просьба установить пароль в <a href="#/userProfile/passwordManagement" class="under-line-selected">разделе управления паролем</a>.`,
                    {
                        provider: "static",
                        type: "error",
                        displayCondition: () => {
                            return false;
                            /*const currentUrl = $state.$current.name;

                            if (!authenticationService.canUserManagePassword()) return false;
                            if (employee.isImpersonated()) return false;
                            if (!employee.info) return false;
                            if (employee.info.IsPasswordExist) return false;
                            const result = (employee.info.IsEmailConfirmed && !employee.info.IsPasswordExist && currentUrl.startsWith("app"));
                            return result;*/
                        }
                    });

                notifyService.message(
                    undefined,
                    `Уважаемый пользователь системы Ю-ПРО, вам необходимо пройти регистрацию в разделе <a href="#/selfRegistration/" class="under-line-selected">саморегистрации</a>.`,
                    {
                        provider: "static",
                        type: "error",
                        displayCondition: () => {
                            return authenticationService.canUserSelfRegister();
                        },
                        messageParams: {
                            styleClass: 'alert-warning-for-self-registration'
                        }
                    });
            });
            // конфиг для 'старого' фронта
            $localStorage.configuration = envService.getSnapshot();
        },
        onExit() {
            notifyStaticProvider.clearStaticNotifications();
            signalRService.disable();
        },
        views: {
            "topbar@": {
                controller: TopbarController,
                controllerAs: "vm",
                template: require("./topbar.html"),
            },
        },
        resolve: {
            availableProducts: (versionsInfo, productsService, employee, helpers) => {
                "ngInject";

                if (helpers.isEmbedMode()) {
                    return [];
                }
                productsService.updateProductsVersions(versionsInfo.ProductList);
                const productList = productsService.getAllProducts().filter(product => employee.hasPermission("Get", product.code));
                const expandedProductList = [];
                productList.forEach(product => {
                    if (angular.isArray(product.subProducts)) {
                        product.subProducts.forEach(sub => {
                            expandedProductList.push({
                                code: product.code,
                                groupName: product.groupName,
                                isSubProduct: true,
                                productName: sub.productName,
                                sort: sub.sort,
                                subProductCode: sub.code,
                                subProducts: null,
                            });
                        });
                    }
                    expandedProductList.push({
                        code: product.code,
                        groupName: product.groupName,
                        isSubProduct: false,
                        productName: product.productName,
                        sort: product.sort,
                        subProducts: product.subProducts,
                        version: product.version,
                    });
                });
                return expandedProductList.sort((a, b) => a.sort - b.sort);
            },

            employee: employeeService => {
                "ngInject";

                return employeeService.getEmployee();
            },
            /*
            localNotifications: ($http, $localStorage) => {
                "ngInject";

                return $http({
                    method: "GET",
                    url: "notifications.json",
                }).then(({ data: notifications }) => {
                    return notifications.notes.filter((elem) => !$localStorage.hiddenSystemNotify.includes(elem.GUID));
                });
            },
            */

            originalEmployee: (employee, employeeResource) => {
                "ngInject";

                if (!employee.isImpersonated()) return employee;

                return employeeResource.employeeInfo({ _forceNoImpersonate: true }).$promise.then(data => {
                    const originalEmployee = new OriginalEmployee();
                    originalEmployee.info = data;
                    return originalEmployee;
                });
            },

            versionsInfo(versionsInfoService, helpers) {
                "ngInject";

                return !helpers.isEmbedMode() ? versionsInfoService.getVersions() : $q.resolve({});
            },
        },
    });

    $stateRegistry.register({
        name: "app.index",
        url: "/",
        views: {
            "body@": {
                template: require("./body.html"),
                controller: BodyController,
                controllerAs: "vm"
            },
            "smallDigest@": {},
        },
        resolve: {
            saleChannel: employee => {
                "ngInject";

                return employee.info.SaleChannel || "";
            },
        }
    });

    $stateRegistry.register({
        name: "ef",
        url: "/ef",
        external: true,
        template: "<h1>Авторизация...</h1>",
        externalLink: envService.read("dashboardFrontLink"),
    });
}

function configAnimate($animateProvider) {
    "ngInject";
    /* Фиксим анимацию spinner'а*/
    $animateProvider.classNameFilter(/^((?!(fa-spinner)).)*$/);
}

function configBlockUI(blockUIConfig) {
    "ngInject";
    blockUIConfig.template = require("infrastructure/blockUITemplate.html");
    blockUIConfig.autoBlock = false;
}

function configLogger(errorHandlerProvider, $logToServerProvider) {
    "ngInject";

    errorHandlerProvider.setLogger($logToServerProvider.$get());
    errorHandlerProvider.handleWindowOnError();
    errorHandlerProvider.interceptHttp();
}

function configDebugLog($logProvider, envServiceProvider) {
    "ngInject";

    const envService = envServiceProvider.$get();
    const debug = envService.read("debug");
    $logProvider.debugEnabled(debug);
}

/**
     * Определение является ли браузер клиента IE
     * @returns Boolean
*/
function isIE() {
    var userAgent = navigator.userAgent;
    return /Trident/gi.test(userAgent) || /MSIE/gi.test(userAgent);
}

function configHttpProvider($httpProvider, envServiceProvider, authenticationServiceProvider, notifyServiceProvider) {
    "ngInject";
    /* Ссылка на оф источник
        www.w3.org/Protocols/rfc2616/rfc2616-sec14.html#sec14.32*/
    /* Pragma директива введена в HTTP/1.0 для управления кешированием
        на клиенте, ее рекомендуется использовать если на server side нет поддержки
        Cache-Control директивы*/
    $httpProvider.defaults.headers.common.Pragma = "no-cache";
    /* Cache-Control директива которая введена в HTTP/1.1
    для управления кешированием*/
    $httpProvider.defaults.headers.common["Cache-Control"] = "no-cache";

    var noCache = envServiceProvider.$get().read("forceNoCache");
    if (noCache === true || noCache === "IE" && isIE()) {
        $httpProvider.interceptors.push(function () {
            return {
                request: function (config) {
                    if (config.url.slice(-5) !== ".html") {
                        config.params = config.params || {};
                        config.params._t = Date.now();
                    }
                    return config;
                }
            };
        });
    }

    $httpProvider.defaults.timeout = 60000;
    $httpProvider.interceptors.push(
        ($q, $localStorage, validationService, $injector) => {
            "ngInject";
            return {
                request: function (config) {
                    var pathIsAbsolute = config.url.indexOf("http") === 0 || config.url.indexOf("//") === 0;
                    if (pathIsAbsolute) {
                        var oauth = $localStorage.tokenData;
                        if (oauth && oauth.access_token && config.anonymous !== true) {
                            //  не добавлять Bearer для публичной части FrontEnd
                            //  это бессмысленно и может приводить к неработоспособности приложения
                            config.headers.Authorization = "Bearer " + oauth.access_token;
                        }
                        var _forceNoImpersonate = config.params && config.params._forceNoImpersonate;
                        if (!_forceNoImpersonate && !config.anonymous) {
                            var impLogin = $localStorage.ImpersonateLogin;
                            if (impLogin) {
                                config.headers.Impersonalization = impLogin;
                            }
                        }
                    }

                    if (config.method !== "GET") {
                        if (!config.headers["Content-Type"]) {
                            config.headers["Content-Type"] = "application/json; charset=utf-8";
                        }
                    }
                    return config;
                },

                response: response => {
                    const notifyService = notifyServiceProvider.$get();
                    const { data } = response;
                    if (angular.isObject(data)) {
                        const warningsList = [];
                        const errorsList = [];
                        const messagesList = [];
                        if (data.warnings && data.warnings.length > 0) {
                            warningsList.push(...data.warnings.map(warn => warn.message || ""));
                        }

                        // TODO если потребуется доработка в будущем - отрефакторить через ResponseHandler-ы, которых пока еще нет :)
                        // вероятно, у каждого продукта могут быть свои хендлеры/middleware, которые можно здесь подключать.
                        if (data.productName && data.productName === "UAuto" && data.errors && data.errors.CommonErrors && angular.isArray(data.errors.CommonErrors) && data.errors.CommonErrors.length > 0) {
                            data.errors.CommonErrors.forEach((item) => {
                                if (item.ErrorType === 1) {
                                    if (!messagesList.includes(item.Message)) {
                                        messagesList.push(item.Message);
                                    }
                                } else if (item.ErrorType === 2) {
                                    if (!warningsList.includes(item.Message)) {
                                        warningsList.push(item.Message);
                                    }
                                } else if (item.ErrorType === 3) {
                                    if (!errorsList.includes(item.Message)) {
                                        errorsList.push(item.Message);
                                    }
                                }
                            });
                        }

                        if (warningsList.length > 0) {
                            notifyService.warningMessage("Предупреждение!", warningsList.join("<br/>"));
                        }

                        if (messagesList.length > 0) {
                            notifyService.successMessage(null, messagesList.join("<br/>"));
                        }

                        if (errorsList.length > 0) {
                            notifyService.errorMessage("Внимание!", errorsList.join("<br/>"));
                        }

                        const infoUnobtrusiveList = [...data.info || [], ...data.infos || []].filter(info => info.format === "unobtrusive");
                        const infoNoticeableList = [...data.info || [], ...data.infos || []].filter(info => info.format === "noticeable");
                        // Не навязчивые информационные сообщения
                        if (infoUnobtrusiveList.length > 0) {
                            notifyService.infoMessage("Информация", infoUnobtrusiveList.map(info => info.message || "").join("<br/>"));
                        }
                        // Большие навязчивые информационные сообщения
                        infoNoticeableList.forEach(info => {
                            notifyService.message(undefined, info.message, {
                                provider: "popup"
                            });
                        });
                    }
                    // do something on success
                    return response || $q.when(response);
                },
                responseError: function (rejection) {
                    const notifyService = notifyServiceProvider.$get();
                    const errorMessageMap = {
                        403: "Отсутствуют права доступа к объекту",
                        404: "Объект не найден, либо отсутствуют права доступа к объекту",
                        503: "Сервис временно недоступен",
                    }
                    const onError401 = function () {
                        const helpers = $injector.get('helpers');
                        authenticationServiceProvider.$get().clearTokenData();
                        if (helpers.isEmbedMode()) { // embed version
                            window.parent.postMessage({ type: "TOKENEXPECTED" }, "*");
                        } else {
                            notifyService.errorMessage("Ошибка", "Отсутствуют права доступа к объекту. Для устранения ошибки обновите страницу");
                        }
                    }
                    /**
                     * Параметр `_suppressErrors` предназначе для случаев,
                     * когда backend не возвращает читабельного ответа
                     * на некорректный запрос, а отображение корректного
                     * сообщения с ошибкой реализовано внутри front-приложения
                     */
                    if (rejection.config && rejection.config._suppressErrors) {
                        if (rejection.status === 401) {
                            onError401();
                        }
                        return $q.reject(rejection);
                    }
                    switch (rejection.status) {
                        case 401:
                            onError401();
                            break;
                        case 403:
                        case 404:
                        case 503:
                            if (rejection.data && rejection.data.error && rejection.data.error.message) {
                                notifyService.errorMessage("Ошибка", rejection.data.error.message);
                            } else if (rejection.data && rejection.data.Message) {
                                notifyService.errorMessage("Ошибка", rejection.data.Message);
                            } else {
                                notifyService.errorMessage("Ошибка", errorMessageMap[rejection.status]);
                            }
                            break;
                        case 400:
                            if (rejection.data) {
                                if (rejection.data.ModelState) {
                                    // TODO: провести очередной этап рефакторинга
                                    storeValidationErrors(rejection.data.ModelState);
                                    notifyService.errorMessage("Ошибка", getNotifyMessages(rejection.data.ModelState));
                                } else if (rejection.data.error) {
                                    if (rejection.data.error.code === "Validation error") {
                                        var dataErrorMsg = angular.fromJson(rejection.data.error.message);
                                        storeValidationErrors(dataErrorMsg);
                                        notifyService.errorMessage("Скорректируйте введенные данные", getNotifyMessages(dataErrorMsg));
                                    } else if (rejection.data.error.code === "") { //  Phone Validation Errors
                                        var msg = "";
                                        try {
                                            var msgObject = angular.fromJson(rejection.data.error.message);
                                            msg = msgObject.Code.join("<br/>");
                                        } catch (e) {
                                            msg = rejection.data.error.message;
                                        }
                                        notifyService.errorMessage(msg);
                                    }
                                }
                            }
                            break;
                        default:
                            if (rejection.data && rejection.data.ModelState && rejection.data.ModelState.Common) {
                                // редкий случай, например расчет с кривым адресом
                                const messages = [];
                                if (Array.isArray(rejection.data.ModelState.Common)) {
                                    messages.push(...rejection.data.ModelState.Common);
                                } else {
                                    messages.push(rejection.data.ModelState.Common);
                                }
                                notifyService.errorMessage("Ошибка", messages.join("<br/>"));
                            } else {
                                notifyService.errorMessage("Критическая ошибка", "Произошла критическая ошибка, пожалуйста, сообщите об этом! Номер телефона для обращений в рабочие часы: 8 (3467) 357-220 (Служба Технической Поддержки). В нерабочие часы: 8-800-100-82-00 (Федеральный Контакт-Центр)");
                            }
                    }
                    return $q.reject(rejection);
                }
            };

            function storeValidationErrors(errors) {
                for (var error in errors) {
                    validationService.addError(error, errors[error]);
                }
            }

            function getNotifyMessages(dataErrorMessage) {
                const defaultMessage = "<h4>Уважаемый пользователь<h4/>На форме имеются ошибки, устраните их и повторите попытку";
                const validationKeys = Object.keys(dataErrorMessage);
                const formKeys = validationService.getValidationKeys();
                const messages = validationKeys.filter(key => !formKeys.includes(key)).map(key => validationService.getMessages(key));
                return (messages.length === 0) ? defaultMessage : messages.join("<br/>");
            }
        });
}

function configLadda(laddaProvider) {
    "ngInject";
    laddaProvider.setOption({
        style: "zoom-in",
        spinnerSize: 35,
        spinnerColor: "#ffffff"
    });
}

function configTooltipProvider($uibTooltipProvider) {
    "ngInject";
    $uibTooltipProvider.options({
        appendToBody: false
    });
}

function run(
    $transitions,
    $rootScope,
    $anchorScroll,
    blockUI,
    confirmationPopoverDefaults,
    envService,
    authenticationService,
    notifyService,
    notifyStaticProvider,
    $state,
    employeeService,
    validationService,
    $location,
    $q,
    $sce,
    $window,
    $localStorage,
    $timeout,
    helpers,
    signalRService,
    snackbar
) {
    "ngInject";
    moment.locale("ru");
    var apiUrl = envService.read("apiUrl");
    $rootScope.currentYear = moment().format("YYYY");

    $anchorScroll.yOffset = 200;

    confirmationPopoverDefaults.confirmButtonType = "danger";
    confirmationPopoverDefaults.cancelButtonType = "primary";
    confirmationPopoverDefaults.confirmText = "Да";
    confirmationPopoverDefaults.cancelText = "Нет";

    $rootScope.$anchorScroll = $anchorScroll;

    const notification = envService.read("notification");
    if (notification) {
        if (notification.stateNames && notification.stateName !== "*") {
            notification.displayCondition = () => notification.stateNames.includes($state.current.name);
        }
        notifyStaticProvider.pop(notification);
    }
    // eslint-disable-next-line prefer-arrow-callback
    $transitions.onStart({}, function (trans) {
        function getDashboardSubUrl(transitionName, params) {
            let url = transitionName.replace("app.", "").replace(".index", "").replace(".", "/");

            if (params.id) {
                url += `/${params.id}`;
            }
            const listParams = [];
            if (params.subproduct) {
                listParams.push(`subproduct=${params.subproduct}`);
            }
            if (params.cross) {
                listParams.push(`cross=${params.cross}`);
            }
            if (params.crossFrom) {
                listParams.push(`crossFrom=${params.crossFrom}`);
            }
            if (params.prolongate) {
                listParams.push("prolongate=true");
            }
            if (params.token) {
                listParams.push(`token=${params.token}`)
            }
            if (params.login) {
                listParams.push(`login=${params.login}`);
            }
            if(params.code) {
                listParams.push(`code=${params.code}`);
            }
            if (listParams.length > 0) {
                url += `/?${listParams.join("&")}`;
            }

            return url;
        }

        function getTagBasePath () {
            const baseTags = document.getElementsByTagName("base");
            return baseTags.length ? baseTags[0].href.substring(location.origin.length) : "";
        }

        const basePath = getTagBasePath();
        const notSlashBasePath = basePath.slice(0, -1)
        if (window.location.pathname === notSlashBasePath) {
            window.location.replace(basePath) 
        }
        
        if (helpers.isEmbedMode()) {
            const transTo = trans.to();
            const params = trans.params();
            let url = getDashboardSubUrl(transTo.name, params);
            if (!["login", "index"].some((item) => url.startsWith(item))) {
                window.parent.postMessage({
                    type: "NAVIGATIONSTART",
                    value: url,
                }, "*");
            }
        } else {
            if (envService.read("disabled") && envService.read("dashboardFrontLink") && authenticationService.isAuthenticated()) {
                const baseDashboardUrl = new URL("upro/", helpers.getDashboardLink()).toString();
                const subUrl = getDashboardSubUrl(trans.to().name, trans.params());
                if (subUrl === "index") {
                    window.location.href = helpers.getDashboardLink();
                    return;
                }
                if (subUrl !== "login") {
                    const url = new URL(subUrl, baseDashboardUrl).toString();
                    window.location.href = url;
                    return;
                }
            }
        }

        if (trans.to().name === "ef" && !helpers.isEmbedMode()) {
            const forceDashboardLogin = envService.read("forceDashboardLogin");
            const dashboardFrontLink = envService.read("dashboardFrontLink");
            const dashboardFrontEnabled = envService.read("dashboardFrontEnabled");
            const redirectUrl = helpers.getDashboardLink(true);

            if (forceDashboardLogin && dashboardFrontEnabled && dashboardFrontLink) {
                if (authenticationService.isAuthenticated()) {
                    if (Array.isArray(forceDashboardLogin)) {
                        employeeService.getEmployee().then(employee => {
                            if (employee.info) {
                                forceDashboardLogin.forEach(item => {
                                    const keys = Object.keys(item);
                                    const itemHash = keys.map(key => item[key]).join("-");
                                    const employeeHash = keys.map(key => employee.info[key]).join("-");
                                    if (itemHash === employeeHash) {
                                        window.location.href = redirectUrl;
                                    }
                                });
                            }
                        });
                    } else {
                        window.location.href = redirectUrl;
                    }
                }
            }

            return trans.router.stateService.target("app.index");
        }

        validationService.clear();  //  Очистка ошибок при переходе между страницами
        var body = angular.element("body");
        var data = trans.$to().data;
        if (data && data.productPage) {
            body.addClass("product-body");
        } else {
            body.removeClass("product-body");
        }
        blockUI.stop();
        blockUI.start("Загружается страница");
        // eslint-disable-next-line prefer-arrow-callback
        trans.promise.finally(function () {
            blockUI.stop();
            //  исправляется поведение в 2-х случаях
            //  1. фича - переход с одного договора в другой, скролл остается
            //  2. баг - переход между страницей продукта и главной страницей
            //  TODO: проверить поведение после обновления главной страницы
            angular.element(window).scrollTop(0);
        });
    });

    $transitions.onBefore({}, (transition) => {
        const state = transition.to().$$state();
        if (!(state.data && state.data.anonymous) && transition.to().name !== "app.userProfile.passwordManagement") {
            // if (!["login", "app.userProfile.passwordManagement", "login.callback"].includes(transition.to().name)) {
            if (authenticationService.isAuthenticated()) {
                return employeeService.getEmployee().then(employee => {
                    if (employee.info && employee.info.IsPasswordMustBeChanged && !authenticationService.isImplicitFlowToken()) {
                        return transition.router.stateService.target("app.userProfile.passwordManagement");
                    }
                });
            }
        }
        return true;
    });

    var requiresAuthCriteria = {
        to: function (state) {
            return !(state.data && state.data.anonymous);
        }
    };

    var rejectLoadLogin = {
        to: function (state) {
            return state.name === "login";
        }
    };

    var requireAuth = function (transition) {
        if (!authenticationService.isAuthenticated()) return $q.reject({ status: 401 });
        try {
            authenticationService.getParsedToken();
        } catch (e) {
            authenticationService.clearTokenData();
            e.error = "auth-error.invalid-token",
                e.error_description = "Произошла непредвиденная ошибка при разборе токена, обратитесь в службу технической поддержки"
            return $q.reject(e);
        }
        blockUI.start("Получение данных пользователя");
        return employeeService.getEmployee().finally(() => blockUI.stop());
    };

    var redirectToMainpage = function (transition) {
        if (authenticationService.isAuthenticated() || !authenticationService.isFormAutheticate) {
            return false;
        }
        return true;
    };

    var productAccessCriteria = {
        to: function (state) {
            return (state.data && state.data.productCode);
        }
    };

    /**
     * @description Проверка доступа к продукту
     * @param {Object} transition Объект Transition
     * @return {Promise}
     */
    var checkAccess = function (transition) {
        var productCode = transition.to().data.productCode;
        return employeeService.getEmployee()
            .then(employee => {
                if (!employee.hasPermission("Get", productCode)) {
                    return $q.reject({ status: 403 });
                }
                return $q.resolve();
            });
    };

    // Register the "requires auth" hook with the TransitionsService
    $transitions.onBefore(requiresAuthCriteria, requireAuth);
    // Запретить переход к странице логина, если уже авторизировались и перенаправлять в корень
    // TODO: Вернуть, когда будет включена форма аутентификации
    // $transitions.onBefore(rejectLoadLogin, redirectToMainpage, { priority: 2 });
    // Проверка доступа к продукту
    $transitions.onBefore(productAccessCriteria, checkAccess);
    $transitions.onError({}, transition => {
        const error = transition.error();
        if (error.status === 401) {
            $state.go("login", {
                returnTo: transition.to().name,
                returnToParams: angular.toJson(transition.params()),
            });
        }
        if (error.status === 403) {
            $state.go("error", {
                error: `http-error.code-${error.status}`,
                error_description: `Доступ к запрашиваемому ресурсу (${error.config.url}) запрещен. \n ${error.data.slice(0, 64)}`,
            });
        }
        if (error.status === 404) {
            $state.go("error", {
                error: "notfound-error",
                error_description: `Объект "${error.data.slice(0, 64)}" не найден`,
            });
        }
        if (error instanceof Error) {
            $state.go("error", {
                error: "transition-error",
                error_description: `Произошла внутренняя ошибка при переходе:\n ${error.stack}`,
            });
        }
    });

    if (helpers.isEmbedMode()) { // embed version
        const footer = document.querySelector("#bottomFooter");
        if (footer) {
            footer.style.display = "none";
        }
        const navHeader = document.querySelector("#navHeader");
        if (navHeader) {
            navHeader.style.display = "none";
        }
        const bottomToolbar = document.querySelector("#bottomToolbar");
        if (bottomToolbar) {
            bottomToolbar.style.marginBottom = "0";
        }
        document.querySelector("body").style.paddingTop = "15px";
        /* eslint-disable indent */
        /* eslint-disable no-case-declarations */
        let appInitialized = false;
        if (helpers.isEmbedMode() && !$window.uproMessaging) {
            throw new Error("EmbedMode: Messaging error");
        } else {
            $window.uproMessaging.subscribe({
                next: (message) => {
                    if (message.type) {
                        // eslint-disable-next-line default-case
                        switch (String(message.type).toUpperCase()) {
                            case "SET-CONFIG":
                                const storageData = message.value;
                                const availableKeys = ["employeeInfo", "tokenData", "ImpersonateLogin", "ImpersonateLoginFlag", "KiasCrossProducts", "NPCrossProducts"];
                                const keys = Object.keys(storageData);
                                if (Array.isArray(keys)) {
                                    keys.forEach((key) => {
                                        if (availableKeys.includes(key)) {
                                            // eslint-disable-next-line no-param-reassign
                                            $localStorage[key] = storageData[key];
                                        }
                                    });
                                }
                                $state.go("/");
                                break;
                            case "NAVIGATE":
                                if (String(message.value).length > 0) {
                                    const url = String(message.value).replace("/#/", "");
                                    let route = "";
                                    const params = {};

                                    const navigationRules = [{
                                        name: "reProduct",
                                        excludes: [
                                            /^ef$/,
                                            /^reset$/,
                                            /^forgot$/,
                                            /^confirmEmail$/,
                                        ],
                                        re: /^(?<app>[a-z]{1,})$/i,
                                        action(groups) {
                                            route = `app.${groups.app}.index`;
                                            params.contract = null;
                                            params.id = null;
                                        },
                                    }, {
                                        name: "reProductCross",
                                        excludes: [
                                            /^ef$/,
                                            /^reset$/,
                                            /^forgot$/,
                                            /^confirmEmail$/,
                                        ],
                                        re: /^(?<app>[a-z]{1,})\?crossFrom=(?<cross>[a-z]+)$/i,
                                        action(groups) {
                                            route = `app.${groups.app}.index`;
                                            params.contract = null;
                                            params.id = null;
                                            params.crossFrom = groups.cross;
                                        },
                                    }, {
                                        name: "reProductWithSubproduct",
                                        re: /^(?<app>[a-z]{1,})\?subproduct=(?<subproduct>[a-z]+)$/i,
                                        action(groups) {
                                            route = `app.${groups.app}.index`;
                                            params.subproduct = groups.subproduct;
                                            params.id = null;
                                        },
                                    }, {
                                        name: "reProductJournal",
                                        re: /^(?<app>[a-z]{1,})\/journal.*$/i,
                                        action(groups) {
                                            route = `app.${groups.app}.journal.index`;
                                        },
                                    }, {
                                        name: "reProductWithId",
                                        re: /^(?<app>[a-z]{1,})\/(?<id>\d{1,})(\?(?<params>.*))?$/i,
                                        action(groups) {
                                            route = `app.${groups.app}.index`;
                                            params.id = groups.id;

                                            if (groups.params) {
                                                groups.params.split("&").forEach(paramItem => {
                                                    const [key, value] = paramItem.split("=");
                                                    if (key) {
                                                        params[key] = value;
                                                    }
                                                })
                                            }
                                        }
                                    }, {
                                        name: "reProductWithIdAndPayments",
                                        re: /^(?<app>[a-z]{1,})\/payments\/(?<id>\d{1,})$/i,
                                        action(groups) {
                                            route = `app.${groups.app}.payments`;
                                            params.id = groups.id;
                                        },
                                    }, {
                                        name: "rePaymentRegistryNew",
                                        re: /^paymentsRegistries\/new$/i,
                                        action() {
                                            route = "app.paymentsRegistries.new"
                                        },
                                    }, {
                                        name: "rePaymentsRegistryItem",
                                        re: /^paymentsRegistries\/item\/(?<id>\d+)$/i,
                                        action(groups) {
                                            route = "app.paymentsRegistries.item";
                                            params.id = groups.id;
                                        },
                                    }, {
                                        // скорее всего не нужно, но пусть будет
                                        name: "reEf",
                                        re: /^ef$/,
                                        action() {
                                            route = "ef";
                                        },
                                    }, {
                                        name: "reResetPassword",
                                        re: /^reset((\?)token=(?<token>.+))?$/i,
                                        action(groups) {
                                            route = "reset";

                                            if (groups.token) {
                                                params.token = groups.token;
                                            }
                                        },
                                    }, {
                                        name: "reForgotPassword",
                                        re: /^forgot$/,
                                        action() {
                                            route = "forgot";
                                        },
                                    }, {
                                        name: "reConfirmEmail",
                                        re: /^confirmEmail(\?(?<params>.*))?$/i,
                                        action(groups) {
                                            route = "confirmEmail";

                                            if (groups.params) {
                                                groups.params.split("&").forEach(paramItem => {
                                                    const [key, value] = paramItem.split("=");
                                                    if (key) {
                                                        params[key] = value;
                                                    }
                                                })
                                            }
                                        }
                                    }];

                                    let routeMatched = false;
                                    for (let rule of navigationRules) {
                                        let matches = null;
                                        if (matches = url.match(rule.re)) {
                                            if (rule.excludes && rule.excludes.some(re => url.match(re))) {
                                                console.warn(`Navigation route ${url} excluded`);
                                                continue;
                                            }

                                            routeMatched = true;
                                            rule.action(matches.groups ? matches.groups : {});
                                            console.debug("Navigation", url, rule.name, route, params)
                                            break;
                                        }
                                    }

                                    if (!routeMatched) {
                                        console.error("Navigation failed with URL: ", url);
                                    }

                                    if (!appInitialized) {
                                        appInitialized = true;
                                        $timeout(() => $state.go(route, params), 1000);
                                    } else {
                                        $state.go(route, params);
                                    }
                                }
                                break;
                            case "IMPERSONATE":
                                if (message.value) {
                                    helpers.changeUser(message.value.login).then(() => {
                                        $localStorage.ImpersonateLogin = message.value.login;
                                        $localStorage.ImpersonateLoginFlag = message.value.login;
                                        return $state.reload();
                                    }).then(() => {
                                        employeeService.event.emit("impersonalizated");
                                    });
                                }
                                break;
                            case "DE-IMPERSONATE":
                                $localStorage.ImpersonateLogin = undefined;
                                $localStorage.ImpersonateLoginFlag = undefined;
                                $localStorage.employeeInfo = undefined;
                                signalRService.disable();
                                $state.go($state.current, { update: Date.now() }, { reload: true }).then(() => {
                                    notifyService.successMessage("Данные пользователя обновлены");
                                }).catch((err) => {
                                    notifyService.errorMessage("Ошибка обновления данных", String(err));
                                });
                                break;
                            case "CROSS-FILL":
                                $localStorage.CrossDto = message.value;
                                break;
                            default:
                                console.warn(`Upro: message "${message.type}" ignored by application`);
                        }
                    }
                }, error: () => {
                    // пока не удалось воспроивести отображение сообщения.
                    notifyService.errorMessage("Ошибка", "Ошибка встроенного приложения");
                }
            });
        }
        /* eslint-enable no-case-declarations */
        /* eslint-enable indent */
        const version = envService.read("version").toString();
        window.parent.postMessage({
            type: "READY",
            value: version,
        }, "*");
    }

    let newWorker;

    function showUpdateBar() {
        snackbar.create({
            id: "updatefound",
            title: "Вышла новая версия приложения!",
            content: "Вы можете обновить его сейчас, нажав на «Обновить» (несохраненные данные могут быть утеряны), либо позже, в удобное для вас время (достаточно обновить страницу в браузере).",
            actionText: `<span class="btn btn-sm btn-primary" ng-click="vm.update()">Обновить</span>`,
            duration: 0,
            controller: {
                update: function () {
                    if (!newWorker) {
                        console.log("[SW] not found worker")
                        return;
                    }
                    newWorker.postMessage({ action: "skipWaiting" });
                    setTimeout(() => {
                        window.location.reload(true);
                    }, 500);
                }
            },
            controllerAs: "vm",
        });
    }

    if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
        navigator.serviceWorker.register("service-worker.js").then(registration => {
            setInterval(() => {
                registration.update();
            }, 1000 * 60 * 10); // e.g. 10 minite checks
            if (registration.waiting && registration.waiting.state === "installed") {
                registration.waiting.postMessage({ action: "skipWaiting" });
            }
            registration.addEventListener("updatefound", () => {
                // A wild service worker has appeared in registration.installing!
                newWorker = registration.installing;
                newWorker.addEventListener("statechange", () => {
                    // Has network.state changed?
                    switch (newWorker.state) {
                    case "installed":
                        if (navigator.serviceWorker.controller) {
                            // new update available
                            showUpdateBar();
                        }
                        // No update available
                        break;
                    default:
                        break;
                    }
                });
            });
        });
    }
}
