import angular from "angular";
import { NgComponentController } from "infrastructure/NgController";
import { UAutoController } from "application/uAuto/uAuto.controller";
import { UAutoContract } from "application/uAuto/uAuto.factory";
import { EnvService } from "infrastructure/services/env.service";

class ugskUautoMultiCalculationController extends NgComponentController {
  public parent: UAutoController;
  public contract: UAutoContract;
  private envService: EnvService;
  public multiCalculationEnabled: boolean;

  public onInit(): void {
    this.contract = this.parent.Contract;
    this.envService = this.di<EnvService>("envService");
    this.multiCalculationEnabled = this.envService.read("multiCalculationEnabled") ? true : false;

  }

}

export default angular.module("ugsk-uauto-multi-calculation", [])
  .component("ugskUautoMultiCalculation", {
      bindings: {
          parent: "<",
      },
      controller: ugskUautoMultiCalculationController,
      controllerAs: "vm",
      template: require("./ugskUautoMultiCalculation.component.html"),
  }).name;
