import "application/components/ugsk-insured-person/ugskInsuredPerson.component";
import "application/components/ugsk-multiple-selectpicker/ugskMultipleSelectpicker.component";
import { Product } from "domain/classes/product.class";
import { TravelingAbroadJournalToolbarController } from "./journal/travelingAbroadJournal-toolbar.controller";
import { TravelingAbroadJournalController } from "./journal/travelingAbroadJournal.controller";
import { TravelingAbroadToolbarController } from "./travelingAbroad-toolbar.controller";
import { TravelingAbroadController } from "./travelingAbroad.controller";

import template from "./body.html";
import manual from "./manual_travelingAbroad.pdf";

import angular from "angular";
import { ProductsService } from "infrastructure/services/products.service";
import { TravelingAbroadContract } from "./travelingAbroad.factory";

class TravelingAbroad extends Product {
    constructor() {
        super();
        this.code = "travelingAbroad";
        this.groupName = "Страхование путешественников";
        this.productName = "Путешествие";
        this.description = "Страхование граждан, выезжающих за пределы постоянного места жительства";
        this.insuranceRules = {
            base: "https://ugsk.ru/about/pravila/VZR.pdf",
        };
        this.insurerCanBeLegalEntity = true;
        this.logo = "nav_assuranceHome";
        this.manualUrl = manual;
        this.sort = 160;
        this.template = template;
        this.controller = TravelingAbroadController;
        this.toolbarController = TravelingAbroadToolbarController;
        this.journalController = TravelingAbroadJournalController;
        this.journalToolbarController = TravelingAbroadJournalToolbarController;
        this.extraViews = {
            "additionalRisks@app.travelingAbroad.index": {
                template: require("./view.additionalRisks.html"),
            },
            "calculating@app.travelingAbroad.index": {
                template: require("./view.calculating.html"),
            },
            "conditions@app.travelingAbroad.index": {
                template: require("./view.conditions.html"),
            },
            "contractParams@app.travelingAbroad.index": {
                template: require("./view.params.html"),
            },
            "insurant@app.travelingAbroad.index": {
                template: require("./view.insurant.html"),
            },
            "insuredPersons@app.travelingAbroad.index": {
                template: require("./view.insuredPersons.html"),
            },
            "payment@app.travelingAbroad.index": {
                template: require("./view.payment.html"),
            },
            "risks@app.travelingAbroad.index": {
                template: require("./view.risks.html"),
            },
            "specialNotes@app.travelingAbroad.index": {
                template: require("./view.specialNotes.html"),
            },
        };
    }
    get contractClass() {
        return TravelingAbroadContract;
    }
}

angular.module("app.travelingAbroad", [
    "touchSpin",
    "ugsk.components.multiple-selectpicker",
    "ugsk.components.insured-person",
]).run((productsService: ProductsService) => {
    "ngInject";

    productsService.register(new TravelingAbroad());
});
