import angular from "angular";
import ugskBeneficaryModule from "application/components/ugsk-beneficiary/ugskBeneficiary.component";
import { UAutoRepository } from "application/uAuto/uAuto.repository";
import { NgComponentController } from "infrastructure/NgController";
import { UautoBeneficiaryProxy, UAutoContract } from "../../uAuto.factory";

/**
 * ugskUautoBeneficary - выгодоприобретатель в "КАСКО"
 */

class BeneficaryModule extends NgComponentController {
    public contract: UAutoContract;
    public repository: UAutoRepository;
    public beneficiary: UautoBeneficiaryProxy;
    public beneficiaryPhoneValidationFields: string[];
    public onBeneficiaryTypeChanged: () => void;

    public onInit(): void {
        this.repository = this.contract.getRepository();
        this.beneficiary = new UautoBeneficiaryProxy(this.contract);
        this.$watch(() => this.beneficiary.contractorType, (newVal, oldVal) => {
            if (!this.contract.isLocked() && newVal !== oldVal) {
                this.onBeneficiaryTypeChanged();
            }

            if (!this.isPerson()) {
                this.beneficiary.firstName = undefined;
                this.beneficiary.lastName = undefined;
                this.beneficiary.middleName = undefined;
                this.beneficiary.docWhomGiven = undefined;
                this.beneficiary.birthday = null;
            } else {
                this.beneficiary.orgName = undefined;
                this.beneficiary.orgInn = undefined;
                this.beneficiary.orgKpp = undefined;
                this.beneficiary.isNotResident = undefined;
            }
        });
    }

    isPerson() {
        return (this.beneficiary.isIndividual() || this.beneficiary.isSoleProprietor());
    }
}

export default angular.module("app.uauto.components.beneficary", [
    ugskBeneficaryModule,
]).component("ugskUautoBeneficary", {
    bindings: {
        contract: "<",
        onBeneficiaryTypeChanged: "&",
    },
    controller: BeneficaryModule,
    controllerAs: "vm",
    template: require("./ugskUautoBeneficiary.component.html"),
}).name;
