import { StateService } from "@uirouter/core";
import { IModalInstanceService, IModalService } from "angular-ui-bootstrap";
import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { Employee } from "infrastructure/services/employee.service";
import { NotifyService } from "infrastructure/services/notifyService";
import { UAutoContract } from "../uAuto.factory";
import { UAutoToolbarController } from "../uAutoToolbar.controller";

export default {
    title: "Форма согласования",
    action(context: UAutoToolbarController) {
        const $uibModal = context.di<IModalService>("$uibModal");
        const $window = context.di<angular.IWindowService>("$window");
        const $q = context.di<angular.IQService>("$q");
        const filialService = context.di<any>("filialService");
        const notifyService = context.di<NotifyService>("notifyService");
        const $state = context.di<StateService>("$state");

        const employee = context.resolve<Employee>("employee");
        $uibModal.open({
            backdrop: "static",
            template: `
                <ugsk-dialog
                    data-title="Форма запроса согласования"
                    on-accept="vm.close()"
                    on-reject="vm.dismiss()">
                    <ugsk-uauto-approval-request
                        contract="vm.contract">
                    </ugsk-uauto-approval-request>
                </ugsk-dialog>
            `,
            controller: class {
                public dismiss: () => void;
                public contract: UAutoContract;
                static get $inject() {
                    return ["$uibModalInstance", "notifyService"];
                }
                constructor(private $uibModalInstance: IModalInstanceService, private notifyService: NotifyService) {
                    this.dismiss = $uibModalInstance.dismiss;
                    this.contract = context.Contract;
                }

                public close(): void {
                    if (!(typeof this.contract.VehicleVIN === "string" && this.contract.VehicleVIN.length === 17)) {
                        this.notifyService.message(
                            "Форма согласования",
                            "Для согласования необходимо заполнить поле VIN в договоре",
                            {
                                provider: "toaster",
                                timeout: 10000,
                                type: "error",
                            });
                        return;
                    }

                    this.$uibModalInstance.close();
                }
            },
            controllerAs: "vm",
        }).result.then(() => {
            const approvalRequstingReason = context.Contract.ApproveRequestReason;
            if (!approvalRequstingReason) {
                notifyService.errorMessage("Ошибка", "Проверьте, указана ли причина направления запроса");
                return $q.reject();
            }
            return context.saveContract().then(() => $q.all([
                context.Contract.$validateForApproveRequest(),
                filialService.query({
                    $select: "Code",
                    $filter: `Name eq '${context.Contract.Territory}'`,
                }).$promise,
            ])).then(([, filialCodeSearchResult]) => {
                const repository = context.Contract.getRepository();
                const reason = repository.ApproveRequestReason.Collection
                    .find((r) => r.ApproveRequestReason === context.Contract.ApproveRequestReason);
                const emailAddress = reason.ApproveRequestAddress;
                if (!emailAddress && emailAddress !== "") {
                    return;
                }
                notifyService.infoMessage(
                    "Информация",
                    `К запросу на согласование обязательно должны быть приложены скан-копии следующих документов:
                    <ol>
                        <li>Оригинал ПТС (копия для дубликата ПТС)</li>
                        <li>Оригинал свид-ва о регистрации ТС (если поставлено на учет)</li>
                        <li>Договор купли-продажи (для не зарегистрированных в ГИБДД)</li>
                        <li>Договор аренды/лизинга (при наличии)</li>
                        <li>Вод. удостоверение Страхователя</li>
                        <li>Паспорт страхователя (с фото и регистрацией)</li>
                        <li>Вод. удостоверения лиц, допущенных к управлению ТС (не требуется для договоров без ограничения списка водителей)</li>
                        <li>Договор на установку доп. ПУС и подтверждение оплаты абонентского обслуживания при наличии спутниковой ПУС</li>
                        <li>Иные документы на ТС при заключении договора на нестандартных условиях</li>
                    </ol>`,
                );
                const contractUrl = $state.href("app.uAuto.index", {
                    id: context.Contract.Id,
                }, {
                    absolute: true,
                    inherit: false,
                });

                const approvingRequestAbbreviation = reason.ApproveRequestAbbreviation || "";
                const territory = (context.Contract.Territory || "");
                const territoryWithoutFilial = (context.Contract.Territory || "").replace(" филиал", "");
                const insurantLastName = context.Contract.InsuredPersonLastName || "";
                const insurantFirstName = context.Contract.InsuredPersonFirstName || "";
                const insurantMiddleName = context.Contract.InsuredPersonMiddleName || "";
                const insuredPersonName = [insurantLastName, insurantFirstName, insurantMiddleName]
                    .filter((item) => item)
                    .join(" ");
                const insuredOrgName = context.Contract.InsuredOrgName  ||  "";
                const insuredContractorType = context.Contract.InsuredContractorType;
                const insuredName = (insuredContractorType === "юридическое лицо") ? insuredOrgName : insuredPersonName;
                const contractVIN = context.Contract.VehicleVIN;
                let filialCode: string;
                try {
                    filialCode = filialCodeSearchResult.value[0].Code;
                } catch (e) {
                    filialCode = "не определен";
                }
                const cc = reason.ApproveRequestAddressCopy;

                let mailSubject = reason.ApproveRequestMailSubjectTemplate;
                const dic = {
                    approvingRequestAbbreviation,
                    contractUrl,
                    filialCode,
                    insurantFirstName,
                    insurantLastName,
                    insurantMiddleName,
                    insuredName,
                    insuredOrgName,
                    insuredPersonName,
                    territory,
                    contractVIN,
                };

                const re = /\${(\S+)}/g;

                mailSubject = mailSubject.replace(re, function(all, key) {
                    return key.split(".").reduce((acc, current) => acc[current], dic);
                });

                if (emailAddress === "") {
                    notifyService.warningMessage("Информация", `Для филиала ${territoryWithoutFilial} не заполнена таблица маршрутизации запросов УАА`);
                }

                const mailBody = [
                    "Для обеспечения скорости согласования не меняйте тему письма! %0D%0A",
                    "Текстовку запроса для договора можно получить в меню ПЕЧАТЬ -> ФОРМА ЗАПРОСА СОГЛАСОВАНИЯ",
                ].join("");

                $window.location.href = `mailto:${emailAddress}?subject=${mailSubject}&body=${mailBody}&cc=${cc}`;
            })
                .catch(() => {
                    notifyService.errorMessage("Ошибка", "Ошибка составления запроса на согласование");
                });
        }).catch(() => {});
    },
    disabled(context) {
        return context.Contract.isLocked() || !context.Contract.getRepository() || context.Contract.isFrozen();
    },
    icon: "fa-list",
} as IExtraOptionPrototype;
