import { IModalService, IModalServiceInstance } from "angular-ui-bootstrap";
import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { NotifyService } from "infrastructure/services/notifyService";
import annulContractTemplate from "./annulContract.html";
import { AsyncContractSaleService } from "infrastructure/services/async.contract.sale.service";
import { CONTRACT_STATUSES } from "application/constants";

class AnnulContractController {
    static get $inject() {
        return ["$uibModalInstance", "notifyService", "asyncContractSaleService", "context"];
    }

    constructor(
        private $uibModalInstance: IModalServiceInstance,
        private notifyService: NotifyService,
        private asyncContractSaleService: AsyncContractSaleService,
        private context: any
    ) {}

    public ok() {
        this.notifyService.progressStart("Подождите, пожалуйста...");
        this.asyncContractSaleService.annulOsago(this.context.Contract.Id).on("onResult", (result) => {
            if (result.status === 200) {
                this.notifyService.progressStop();
                this.$uibModalInstance.close({ result: result });
                if (result.response.Header.Complete && result.response.Header.ProcessingResultCode === 0) {
                    this.notifyService.successMessage(undefined, "Операция выполнена успешно.");
                    if (this.context.getViewType() !== "journal") {
                        this.context.pageSharedData.Form.$setPristine();
                        this.context.$state.go(this.context.$state.current.name, { id: this.context.Contract.Id, update: Date.now() }, { inherit: false, reload: true });
                    } else {
                        this.context.Contract.StatusCode = CONTRACT_STATUSES.ANNULLED;
                        this.context.Contract.StatusId = CONTRACT_STATUSES.ANNULLED;
                        this.context.Contract.StatusName = "Аннулирован";
                    }
                }
                this.context.showCommonErrorsNotifications(result.response.Errors.CommonErrors);
            } else if (result.status !== 500) {
                this.notifyService.progressStop();
                this.notifyService.errorMessage("Ошибка", result.message);
            }
        });
    }

    public close() {
        this.$uibModalInstance.close();
    }
}

export default {
    disabled(context: any): boolean {
        const contract: any = context.Contract;
        const userHasPermission = context.originalEmployee.hasPermission("Annul", "Osago");
        const statusesWhiteList = [
            CONTRACT_STATUSES.DRAFT,
            CONTRACT_STATUSES.PREPARED_FOR_SIGNING,
            CONTRACT_STATUSES.SIGNED,
        ];
        if (context.viewType === "journal") {
            if (userHasPermission && statusesWhiteList.includes(contract.StatusId) && !contract.IsPaid) {
                return false;
            }
        } else {
            if (userHasPermission && statusesWhiteList.includes(contract.ContractStatusId) && !contract.IsPayed) {
                return false;
            }
        }
        return true;
    },
    action(context): void {
        const $uibModal = context.di<IModalService>("$uibModal");
        $uibModal
            .open({
                controller: AnnulContractController,
                controllerAs: "vm",
                resolve: {
                    context,
                },
                template: annulContractTemplate,
            })
            .result.then(({ result }) => {
                (context as any).showCommonErrorsNotifications(result.response.MessageObject.CommonErrors || result.response.Errors.CommonErrors);
            })
            .catch(() => {});
    },
    icon: "fa-times",
    title: "Аннулировать договор",
    tooltip: "Доступно только для договоров в статусе &#34;Оформлен&#34;/&#34;Подготовлен к продаже&#34; и &#34;Не оплачен&#34;",
} as IExtraOptionPrototype;
