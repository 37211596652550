import angular, { IQService } from "angular";
import { IModalInstanceService, IModalService } from "angular-ui-bootstrap";
// tslint:disable-next-line: max-line-length
import ugskPhonesValidationDialog from "application/components/ugsk-phones-validation-dialog/ugskPhonesValidationDialog.component";
import { Contract } from "domain/classes/contract.class";
import UgskPhone, { PhoneStatuses } from "domain/classes/ugsk-phone.class";
import IDTO from "infrastructure/interfaces/IDTO";
import IPVSContractorDTO from "infrastructure/interfaces/IPVSContractorDTO";
import { IResourceResult } from "infrastructure/interfaces/IResourceResult";
import IScope from "infrastructure/interfaces/IScope";
import magicVerificationCodeTemplate from "infrastructure/templates/magicVerificationCode.html";
import { AbstractEmployee } from "./employee.service";
import { NotifyService } from "./notifyService";

export default angular
.module("phoneValidation", [ugskPhonesValidationDialog])
.name;

interface IPhoneValidationResource {
    getMagicVerificationCode(arg: {}, payload: {
        ContractorPhone: UgskPhone;
        contract: IDTO;
        productName: string;
    }): IResourceResult<{
        value: string;
    }>;
    checkVerification(arg: {}, payload: {
        body: IPVSContractorDTO
    }): IResourceResult<{
        content: IPVSContractorDTO;
    }>;
    verifyPhone(arg: {}, payload: {
        body: IPVSContractorDTO;
        phoneNumber: string | UgskPhone;
        smsCode: string;
    }): IResourceResult<{
        content: IPVSContractorDTO;
    }>;
    requestCode(arg: {}, payload: {
        body: IPVSContractorDTO,
        phoneNumber: string | UgskPhone;
    }): IResourceResult<void>;
}

interface IRequiredFields {
    [key: string]: Array<{
        field: string | string[];
        description: string;
    }>;
}

interface ICheckAndConfirmOptions {
    phones?: UgskPhone[];
    autoSendCode?: boolean;
    skipConfirm?: boolean;
}

const isMobilePhone = (phone) => {
    phone.Number = String(phone.Number);
    return phone.Number[phone.Number.indexOf("(") + 1] === "9";
};
const getMobilePhones = (phones: UgskPhone[]) => phones.filter(isMobilePhone);
// tslint:disable-next-line: max-line-length
const getPhonesWithStatus = (phones: UgskPhone[], status: PhoneStatuses) => phones.filter((phone) => phone.Status === status) || [];
const getUnverifiedPhones = (phones: UgskPhone[]) => getPhonesWithStatus(getMobilePhones(phones), 0);

export class PhoneValidationService {

    private requiredFields: IRequiredFields = {
        individual: [
            { field: "InsuredPersonLastName", description: "Фамилия страхователя" },
            { field: "InsuredPersonFirstName", description: "Имя страхователя" },
            { field: "InsuredPersonBirthday", description: "Дата рождения страхователя" },
        ],
        legalEntity: [
            { field: "InsuredOrgName", description: "Наименование организации страхователя" },
            { field: "InsuredOrgINN", description: "ИНН организации страхователя" },
        ],
    };
    static get $inject() {
        return [
            "CanUseMagicVerificationCodeRoles",
            "capabilitiesService",
            "phoneValidationResource",
            "$uibModal",
            "$q",
            "notifyService",
            "employee",
            "productCode",
            "contract",
        ];
    }
    constructor(
        private CanUseMagicVerificationCodeRoles: string[],
        private capabilitiesService: any,
        private phoneValidationResource: IPhoneValidationResource,
        private $uibModal: IModalService,
        private $q: IQService,
        private notifyService: NotifyService,

        private employee: AbstractEmployee,
        private productCode: string,
        private contract: Contract,
    ) {
    }
    public isServiceEnabled() {
        return Boolean(this.capabilitiesService.isCapabilityAvailable("smsVerification"));
    }
    public isExistsVerifiedPhone(phones: UgskPhone[]) {
        return getPhonesWithStatus(phones, 1).length > 0;
    }
    public canUseMagicVerificationCode() {
        return this.CanUseMagicVerificationCodeRoles.indexOf(this.employee.info.Role) !== -1;
    }
    public isNeedConfirmPhone(phones: UgskPhone[]) {
        return this.isServiceEnabled() && !this.isExistsVerifiedPhone(phones) && getUnverifiedPhones(phones).length;
    }
    public getFirstUnverifiedPhoneNumber(phones: UgskPhone[]) {
        const unverifiedPhones = getUnverifiedPhones(phones);
        return (unverifiedPhones.length > 0) ? unverifiedPhones[0].Number : "";
    }
    public showMagicVerificationCode(phone: UgskPhone) {
        return this.phoneValidationResource.getMagicVerificationCode({}, {
            ContractorPhone: phone,
            contract: this.contract.toDTO(),
            productName: this.productCode,
        }).$promise.then((response) => this.$uibModal.open({
            controller: ($scope: IScope, $uibModalInstance: IModalInstanceService) => {
                "ngInject";

                $scope.close = () => $uibModalInstance.dismiss();
                $scope.magicCode = Object.values(response).splice(0, 4).join('');
            },
            size: "sm",
            template: magicVerificationCodeTemplate,
        }));
    }
    public setContractRequiredFields(fields: IRequiredFields) {
        this.requiredFields = fields;
    }
    public getRequiredFields() {
        return (this.requiredFields)[({
            false: "individual",
            true: "legalEntity",
        })[this.contract.isInsurantALegalEntity().toString()]];
    }
    public checkAndConfirmPhones(options: ICheckAndConfirmOptions = {}) {
        if (!this.isServiceEnabled()) {
            return this.$q.resolve();
        }
        const phones = options.phones;
        const autoSendCode = options.autoSendCode || false;
        const skipConfirm = options.skipConfirm || false;
        const PVSContractorDTO = this.contract.toPVSDTO(this.contract)
        return this.$q((resolve, reject) => {
            if (phones.length === 0) {
                this.notifyService.errorMessage("Не указан телефон страхователя");
                return reject();
            }
            const unverifiedPhones = getPhonesWithStatus(phones, 0);
            if (unverifiedPhones.length === 0) {
                this.notifyService.errorMessage("Нет телефонов для верификации");
                return reject();
            }
            if (getMobilePhones(unverifiedPhones).length === 0) {
                this.notifyService.errorMessage("Стационарные номера телефонов не верифицируются");
                return reject();
            }
            const validationFields = this.getRequiredFields();
            if (angular.isArray(validationFields)) {
                const errors: string[] = [];
                validationFields.forEach((item) => {
                    if (!angular.isArray(item.field)) {
                        item.field = [item.field];
                    }
                    if (!item.field.some((field) => this.contract[field])) {
                        errors.push(item.description);
                    }
                });
                if (errors.length > 0) {
                    this.notifyService.errorMessage(`Не указаны следующие параметры: <br /> ${errors.join(", <br/>")}`);
                    return reject();
                }
            }
            return resolve();
        }).then(() => this.phoneValidationResource.checkVerification({}, {
            body: PVSContractorDTO,
        }).$promise).then((data) => new this.$q<UgskPhone[]>((resolve, reject) => {
            if (data.content.ContractorPhone.includes("#V")) {
                this.notifyService.successMessage("", "Один из указанных номеров уже подтверждён");
                const phonesIsArray = data.content.ContractorPhone.includes(",");
                if (phonesIsArray) {
                    if (this.contract.InsuredPhones) {
                        data.content.ContractorPhone.split(",").forEach((phone, index) => {
                            phone.includes("#V") ? this.contract.InsuredPhones[index].Status = 1 : this.contract.InsuredPhones[index].Status = 0
                        })
                    }
                    if (this.contract.InsurantPhones) {
                        data.content.ContractorPhone.split(",").forEach((phone, index) => {
                            phone.includes("#V") ? this.contract.InsurantPhones[index].Status = 1 : this.contract.InsurantPhones[index].Status = 0
                        })
                    }
                } else {
                    if (this.contract.InsuredPhones) {
                        data.content.ContractorPhone.includes("#V") ? this.contract.InsuredPhones[0].Status = 1 : this.contract.InsuredPhones[0].Status = 0
                    }
                    if (this.contract.InsurantPhones) {
                        data.content.ContractorPhone.includes("#V") ? this.contract.InsurantPhones[0].Status = 1 : this.contract.InsurantPhones[0].Status = 0
                    }
                }
                return reject(this.contract.InsuredPhones);
            }
            const unverifiedPhones = this.contract.getInsurantPhones().filter(phone => phone.Status === 0);
            // Нет телефонов для подтверждение, а окошко, что телефон принадлежит другому страховтелю уже вылетело
            if (unverifiedPhones.length === 0) {
                return reject(this.contract.InsuredPhones);
            };

            return resolve(unverifiedPhones);
        })).then((unverifiedPhones) => this.$uibModal.open({
            backdrop: "static",
            controller: ($scope: IScope, $uibModalInstance: IModalInstanceService) => {
                "ngInject";

                $scope.phones = unverifiedPhones;
                $scope.dialogInstance = $uibModalInstance;
                $scope.phoneValidationService = this;
                $scope.autoSendCode = autoSendCode;
                $scope.skipConfirm = skipConfirm;
            },
            size: "400",
            template: `
                <ugsk-phones-validation-dialog
                    phones="phones"
                    dialog-instance="dialogInstance"
                    phone-validation-service="phoneValidationService"
                    auto-send-code="autoSendCode"
                    skip-confirm="skipConfirm">
                </ugsk-phones-validation-dialog>
            `,
        }).result);
    }
    public requestCode(phoneNumber: string) {
        const PVSContractorDTO = this.contract.toPVSDTO(this.contract)
        return this.phoneValidationResource.requestCode({}, {
            phoneNumber,
            body: PVSContractorDTO,
        }).$promise.then(() => {
            this.notifyService.successMessage("Код", "Код успешно отправлен");
        });
    }
    public verifyPhone(phoneNumber: string, smsCode: string) {
        const PVSContractorDTO = this.contract.toPVSDTO(this.contract)
        return this.phoneValidationResource.verifyPhone({}, {
            body: PVSContractorDTO,
            phoneNumber,
            smsCode,
        }).$promise.then((data) => {
            const hasVerifiedPhones = this.contract.getInsurantPhones()
            this.notifyService.successMessage("", "Телефон успешно подтверждён");
            return hasVerifiedPhones;
        });
    }
}
