import "angular-scroll";
import { Product } from "domain/classes/product.class";
import "ng-focus-if";
import { IFLJournalController } from "../journal/iflJournal.controller";
import { MyChoiceJournalToolbarController } from "./journal/myChoiceJournal-toolbar.controller";
import { MyChoiceToolbarController } from "./myChoice-toolbar.controller";
import { MyChoiceController } from "./myChoice.controller";

import angular from "angular";
import { ProductsService } from "infrastructure/services/products.service";
import template from "./body.html";
import manual from "./manual_mychoice.pdf";
import { MyChoiceContract } from "./myChoice.factory";

class MyChoice extends Product {
    constructor() {
        super();
        this.code = "myChoice";
        this.groupName = "Страхование имущества";
        this.productName = "Комфорт (квартира)";
        this.description = "Страхование квартир с выбором объектов, страховых сумм и периода страхования";
        this.insuranceRules = {
            base: "https://ugsk.ru/about/pravila/u-pro/08_47.pdf",
        };
        this.insurerCanBeLegalEntity = false;
        this.hasImageRepositoryAbility = true;
        this.logo = "nav_myChoice";
        this.manualUrl = manual;
        this.sort = 50;
        this.template = template;
        this.controller = MyChoiceController;
        this.toolbarController = MyChoiceToolbarController;
        this.journalController = IFLJournalController;
        this.journalToolbarController = MyChoiceJournalToolbarController;
        this.extraViews = {
            "contractParams@app.myChoice.index": {
                template: require("./view.contractParams.html"),
            },
            "insurer@app.myChoice.index": {
                template: require("./view.insurer.html"),
            },
            "mainBuilding@app.myChoice.index": {
                template: require("./view.mainBuilding.html"),
            },
            "payment@app.myChoice.index": {
                template: require("./view.payment.html"),
            },
        };
    }

    get contractClass() {
        return MyChoiceContract;
    }
}

angular.module("app.myChoice", [
    "focus-if",
    "duScroll",
]).value("duScrollBottomSpy", true).run((productsService: ProductsService) => {
    "ngInject";

    productsService.register(new MyChoice());
});
