import { Product } from "domain/classes/product.class";
import { TContractResourceFactoryFn } from "infrastructure/app.resource";
import { IContractResource } from "infrastructure/interfaces/IContractResource";

export function contractsServiceFactory(
    contractResourceFactory: TContractResourceFactoryFn,
    $q: ng.IQService,
) {
    "ngInject";

    return (product: Product): IContractResource => {
        const resource = contractResourceFactory(product.code);

        if (product.hasInspectionConclusion) {
            const originalEditMethod = resource.editContract;
            resource.editContract = ({
                id,
            }) => ({
                $promise: originalEditMethod({ id }).$promise
                    .then((contract) => resource.getInspectionConclusion({
                        contractId: contract.Id,
                    }).$promise.then((conclusions) => {
                        contract.InspectionConclusions = conclusions;
                        return $q.resolve(contract);
                    })),
            });
        }
        return resource;
    };
}
