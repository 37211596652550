import { Product } from "domain/classes/product.class";
import { BoxAccidentToolbarController } from "../boxAccident-toolbar.controller";
import { BoxAccidentJournalController } from "../journal/boxAccidentJournal.controller";
import { BoxAccidentJournalToolbarController } from "../journal/boxAccidentJournalToolbar.controller";
import { FamilyAccidentController } from "./familyAccident.controller";
import { FamilyAccidentContractDTO } from "./familyAccident.factory";

import ugskBoxAccidentInsurant from "../components/ugsk-box-accident-insurant/ugskBoxAccidentInsurant.component";
import template from "./body.html";

import "../components/ugsk-box-accident-contract-params/ugskBoxAccidentContractParams.component";
import "../components/ugsk-box-accident-insurance-conditions/ugskBoxAccidentInsuranceConditions.component";
import "../components/ugsk-box-accident-insured-person/ugskBoxAccidentInsuredPerson.component";

import angular from "angular";
import { ProductsService } from "infrastructure/services/products.service";
import manual from "./manual_familyAccident.pdf";

export class FamilyAccident extends Product {
    constructor() {
        super();
        this.code = "familyAccident";
        this.groupName = "Страхование жизни и здоровья";
        this.productName = "Семейный";
        this.description = "Добровольное страхование от несчастных случаев и болезней";
        this.insuranceRules = {
            base: "https://ugsk.ru/about/pravila/NS.pdf",
        };
        this.insurerCanBeLegalEntity = true;
        this.logo = "nav_personalAccident";
        this.manualUrl = manual;
        this.sort = 130;
        this.template = template;
        this.controller = FamilyAccidentController;
        this.toolbarController = BoxAccidentToolbarController;
        this.journalController = BoxAccidentJournalController;
        this.journalToolbarController = BoxAccidentJournalToolbarController;
        this.extraViews = {
            "contractParams@app.familyAccident.index": {
                template: require("./view.contractParams.html"),
            },
            "insuranceConditions@app.familyAccident.index": {
                template: require("./view.insuranceConditions.html"),
            },
            "insurant@app.familyAccident.index": {
                template: require("../view.insurantBase.html"),
            },
            "insuredPerson@app.familyAccident.index": {
                template: require("./view.insuredPerson.html"),
            },
            "payment@app.familyAccident.index": {
                template: require("../view.paymentBase.html"),
            },
        };
    }
    get contractClass() {
        return FamilyAccidentContractDTO;
    }
}

angular.module("app.familyAccident", [
    "touchSpin",
    "app.boxAccident.components.contract-params",
    "app.boxAccident.components.insurance-conditions",
    "app.boxAccident.components.insured-person",
    ugskBoxAccidentInsurant,
]).run((productsService: ProductsService) => {
    "ngInject";

    productsService.register(new FamilyAccident());
});
