import { MovablePropertyDTO } from "infrastructure/interfaces/WebApi/MovablePropertyDTO";
import { Decimal, Int } from "infrastructure/types";
import { Uuid } from "lib/uuid";

export interface IElementType {
    MovablePropertyTypeMaxValue: any;
    MovablePropertyType: any;
    MovablePropertyTypeId: number;
}

export default class MovableProperty implements MovablePropertyDTO {
    public elementType: IElementType = null;
    public Id: number = null;
    public Guid: string = Uuid.raw();
    public Name: string = null;
    public MovablePropertyTypeId: number;
    public PurchaseYear: Int = null;
    public PurchaseCost: number = null;
    public Description: string = null;
    public DeclaredAmount: number = null;
    public CalculatedAmount: number = null;
    public IsGroup: boolean;
    public CalculatedMinAmount: Decimal;
    public CalculatedMaxAmount: Decimal;

    public constructor(propertyTypeId: number) {
        this.MovablePropertyTypeId = propertyTypeId;
    }
}
