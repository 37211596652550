import { EnvService } from "./env.service";

interface IEnvCapabilities {
    smsVerification: boolean;
}

export class CapabilitiesService {
    private envCapabilities: IEnvCapabilities;

    constructor(envService: EnvService) {
        "ngInject";

        this.envCapabilities = envService.read("capabilities");
    }

    public isCapabilityAvailable(capabilityName: string): string | boolean {
        const enforcedValue = this.envCapabilities[capabilityName];

        //  TODO: Когда-нибудь сделать сервис для автоматичекого
        //  определения доступных сервисов
        //  а пока все значения из envService
        return enforcedValue || false;
    }
}
