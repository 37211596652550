import { Contract } from "domain/classes/contract.class";
import ProlongableIflContractMix from "domain/mixes/ProlongableIflContract.mix";
import { IFLRepository } from "infrastructure/app.factory-ts";
import IProlongableContract from "infrastructure/interfaces/IProlongableContract";
import { IFactPaymentDTO } from "infrastructure/interfaces/WebApi/IFactPaymentDTO";
import { MyChoiceContractDTO } from "infrastructure/interfaces/WebApi/MyChoiceContractDTO";
import PaymentDTO from "infrastructure/interfaces/WebApi/PaymentDTO";
import { PhoneNumberDTO } from "infrastructure/interfaces/WebApi/PhoneNumberDTO";
import { IRepositoryItem } from "infrastructure/Repository.class";
import { Day, Decimal, Guid, Int } from "infrastructure/types";

export interface IMyChoiceInsuranceTerm {
    InsuranceTermMyChoice: string;
    InsuranceTermMyChoiceId: Int;
    InsuranceTermMyChoiceValue: Int;
}

export class MyChoiceContract extends ProlongableIflContractMix(Contract) implements IProlongableContract, MyChoiceContractDTO {
    public StructuralElementsProductRiskAmountId: Int;
    public StructuralElementsProductRiskAmountPremium: Decimal;
    public InteriorAndEngineeringEquipmentProductRiskAmountId: Int;
    public InteriorAndEngineeringEquipmentProductRiskPremium: Decimal;
    public MovablePropertyProductRiskAmountId: Int;
    public MovablePropertyProductRiskPremium: Decimal;
    public CivilLiabilityProductRiskAmountId: Int;
    public CivilLiabilityProductRiskPremium: Decimal;
    public InsurancePremium: Decimal;
    public Discount: Decimal;
    public Id: Int;
    public Guid: Guid;
    public ContractStatusId: Int;
    public InsuranceProgramId: Int;
    public ContractStatusName: string;
    public PolicyPrefix: string = "";
    public PolicySerialId: Int;
    public PolicyNumber: string;
    public PreviousPolicySerial: string;
    public PreviousContractNumber: string;
    public PreviousContractId: Int;
    public CSIAPreviousContractId: Int;
    public SigningDate: Day;
    public InsuranceTermMyChoiceId: Int;
    public ContractFrom: Day;
    public ContractTo: Day;
    public PropertyAddressUserDefinedAddress: string;
    public PropertyAddressFiasId: string;
    public PropertyAddressKladrId: string;
    public PropertyConstructionYear: Int;
    public LapsedPaymentId: Int;
    public FilialId: Int;
    public InsuredContractorTypeId: Int;
    public InsuredDocTypeId: Int;
    public InsuredDocDateGiven: Day;
    public InsuredDocWhomGiven: string;
    public InsuredDocOrgCode: string;
    public InsuredBirthPlace: string;
    public InsuredDocSerial: string;
    public InsuredDocNumber: string;
    public InsuredLastName: string;
    public InsuredFirstName: string;
    public InsuredMiddleName: string;
    public InsuredBirthday: Day;
    public InsuredAddressUserDefinedAddress: string;
    public InsuredAddressFiasId: string;
    public InsuredAddressKladrId: string;
    public InsuredPhones: PhoneNumberDTO[];
    public InsuredPhoneVerificationId: Guid;
    public InsuredCommonEmail: string;
    public PaymentKindId: Int;
    public PaymentDocumentDatePay: Day;
    public IsReceiptRequired: boolean;
    public IsNumberRequired: boolean;
    public InsuredId: Int;
    public PlannedPayments: PaymentDTO[];
    public FactPayments: IFactPaymentDTO[];

    public getRepository(): MyChoiceRepository {
        return super.getRepository() as MyChoiceRepository;
    }
}

export class MyChoiceRepository extends IFLRepository {
    public InsuranceTerm: IRepositoryItem<IMyChoiceInsuranceTerm>;
}
