import moment from "moment";
import { NgComponentController } from "infrastructure/NgController";
import template from "./ugskVehicleInspection.component.html";

/**
* ugskVehicleInspection - компонент редактирования "ПСО"
*/
export default angular.module("app.components.vehicle-inspection", []).component("ugskVehicleInspectionConclusion", {
    bindings: {
        ngModel: "<",
        disabled: "<",
    },
    controller: class extends NgComponentController {
        onInit() {
            this.mileageTouchSpinConfig = {
                boostat: 5,
                buttondown_class: "btn btn-white",
                buttonup_class: "btn btn-white",
                max: 100000000000000,
                maxboostedstep: 10,
                min: 0,
                step: 1,
            };
            this.photosTouchSpinConfig = angular.copy(this.mileageTouchSpinConfig);
            this.photosTouchSpinConfig.max = 100;
        }
        getMaxInspectionDate() {
            return moment().format("YYYY-MM-DDTHH:mm:ssZ");
        }
    },
    controllerAs: "vm",
    template,
}).name;
