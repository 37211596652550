import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { OsagoToolbarController } from "../osago-toolbar.controller";

export default {
    disabled(context: OsagoToolbarController) {
        return context.Contract && !context.Contract.isReadyToProlongate();
    },
    href(context) {
        if (!context.Contract) { return ""; }
        return context.$state.href("app.osago.index", {
            cross: "osago",
            id: context.Contract.Id,
            prolongate: true,
        });
    },
    icon: "fa-share",
    title: "Пролонгировать",
} as IExtraOptionPrototype;
