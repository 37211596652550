import angular from "angular";
import ugskInsurant from "application/components/ugsk-insurant/ugskInsurant.component";
import { NgComponentController } from "infrastructure/NgController";
import { Day } from "infrastructure/types";
import moment from "moment";
import template from "./ugskBoxAccidentInsurant.component.html";

class BoxAccidentInsurantComponentController extends NgComponentController {
    public minDateRegistration: Day;
    public maxDateRegistration: Day;
    public onContractorTypeChanged: () => void;

    public onInit(): void {
        this.minDateRegistration = moment().subtract(100, "years").format("YYYY-MM-DD");
        this.maxDateRegistration = moment().format("YYYY-MM-DD");
    }
    public onUpdateFieldRepository(): void {
        this.onContractorTypeChanged();
    }
}

export default angular.module("app.boxAccident.components.insurant", [
    ugskInsurant,
]).component("ugskBoxAccidentInsurant", {
    bindings: {
        contract: "=",
        insurant: "<",
        insurantContractorTypes: "<",
        insurantDocumentTypes: "<",
        isFormLocked: "<",
        onContractorTypeChanged: "&",
        phoneValidationService: "<",
    },
    controller: BoxAccidentInsurantComponentController,
    controllerAs: "vm",
    template,
}).name;
