import * as angular from "angular";
import moment from "moment";
import { NgTableParams } from "ng-table";

import { UgskSet } from "domain/classes/ugsk-set.class";
import {IJournalResource, IUGSKLocalStorage } from "infrastructure/interfaces/";
import { NgControllerMix } from "infrastructure/NgController";
import InfiniteScrollService from "infrastructure/services/infiniteScroll.service";

import { Contract } from "domain/classes/contract.class";
import IUGSKUserContext from "infrastructure/interfaces/IUGSKUserContext";
import { Employee } from "infrastructure/services/employee.service";
import { UgskODataService } from "infrastructure/services/ugskOData.service";
import { UgskTableFilterService } from "infrastructure/services/ugskTableFilter.service";
import { UgskFormFieldLocker } from "infrastructure/services/formFieldLocker.service";
import { BaseJournalController } from "./baseJournal.controller";
import "./journal.css";
import journalsManual from "./manual_journals.pdf";
import { JournalColumnType, JournalFilterType, JournalOrderType } from "./types";
import { Helpers } from "infrastructure/app.helpers";

const JOURNAL_CLEANING_RULES = {
    VehicleLicensePlate: (value: any, record?: any) => {
        return "";
    },
    InsuredName: (value: any, record?: any) => {
        if (Object.keys(record).includes('InsuredContractorType')) {
            if (record.InsuredContractorType !== "физическое лицо") {
                return value;
            }
        }
        if (typeof value === "string" && value.length > 0) {
            const isIP = String(value).toUpperCase().startsWith("ИП ");
            if (isIP) {
                value = String(value).substring(3).trim();
            }
            const [lastName, firstName, middleName] = value.split(" ");
            const postfix = [firstName, middleName]
                .filter((name) => name)
                .map((name) => Boolean(name) ? `${String(name[0]).toUpperCase()}.` : "")
                .join(" ")
                .trim();
            return `${isIP ? "ИП " : ""}${lastName} ${postfix}`;
        }

        return value;
    },
    VehicleVIN: (value: any, record?: any) => {
        if (typeof value === "string" && value.length > 0) {
            return `***${value.slice(-4)}`;
        }

        return value;
    },
};

export class ProductsJournalController extends NgControllerMix(BaseJournalController) {
    get filter(): JournalFilterType[] {
        const storedFilter = this.$localStorage[`JournalFilter:${this.journalCode}`];
        if (angular.isArray(storedFilter)) { return storedFilter; }
        return [];
    }
    set filter(value: JournalFilterType[]) {
        const filterToStore = value
            .filter((filter) => filter.values.length && filter.values[0])
            .map((filter) => ({
                asString: UgskTableFilterService.getFilterString([filter]),
                name: filter.name,
                option: filter.option,
                values: filter.values,
            }));
        this.$localStorage[`JournalFilter:${this.journalCode}`] = filterToStore;
    }
    get selectedContract() {
        return this.selection.first();
    }
    get dateFormat() {
        return "date: 'dd.MM.yyyy HH:mm': '+0500'";
    }
    get dateFormatWithoutTimeZone() {
        return "dateNoTz: 'DD.MM.YYYY HH:mm'";
    }
    public journalCode: string;
    public isCompact = false;
    public title: string;
    public tableColumns: JournalColumnType[];
    public filtersColumns: any[];
    public infiniteScroll: InfiniteScrollService<Contract>;
    public isSupervisor: boolean;
    public supervisorId: number | null;
    public journalResource: IJournalResource;
    public manual = journalsManual;
    public columns: JournalColumnType[];
    public exportColumns: JournalColumnType[];
    public selection: UgskSet<Contract>;
    public exportFileName: string;
    public exportHeaders: string[];
    public isGroupFiltering: boolean;

    // !!! объявление для mixin-ов
    public order: JournalOrderType;
    public tableParams: NgTableParams<any>;
    private $localStorage: IUGSKLocalStorage;

    public $onInit(): void {
        super.$onInit();
        const ugskFormFieldLocker = this.di<UgskFormFieldLocker>("ugskFormFieldLocker");
        ugskFormFieldLocker.unlockAll();
        const [$location] = this.di(["$location"]) as [angular.ILocationService];
        const [
            journalResourceFactory,
            ngTableParams,
            employeeResource,
        ] = this.di([
            "journalResourceFactory",
            "NgTableParams",
            "employeeResource",
        ]);

        [
            this.$localStorage,
            this.infiniteScroll,
        ] = this.di([
            "$localStorage",
            "infiniteScroll",
        ]);

        const [
            currentProduct,
        ] = this.resolve([
            "currentProduct",
        ]);

        const [employee] = this.resolve(["employee"]) as [Employee];

        [
            this.selection,
        ] = this.resolve([
            "selection",
        ]);

        this.manual = journalsManual;
        this.isCompact = true;

        this.journalCode = currentProduct.code;
        this.title = `Журнал договоров - "${currentProduct.productName}"`;

        this.infiniteScroll.contracts = null;
        this.tableColumns = this.columns.filter((column) => column.visible !== false);
        this.filtersColumns = this.columns.filter((column) => column.displayText && column.type);

        const exportColumnsBlackList = ["VehicleLicensePlate"];
        this.exportColumns = this.columns.filter((column) => {
            return !exportColumnsBlackList.includes(column.name) && column.displayText;
        });
        this.exportFileName = `${this.title} от ${moment().format("DD.MM.YYYY")}.csv`;
        this.exportHeaders = this.exportColumns.map((col) => col.displayText);

        this.buildColumnsRendererFunctions();

        let columnsToFetch = this.columns.reduce((prev, curr) => {
            if (curr.name) { prev.push(curr.name); }
            if (curr.requires) { prev.push(...curr.requires); }
            return prev;
        }, []);

        columnsToFetch = Array.from(new Set(columnsToFetch)); // Избавление массива от дублей

        this.isSupervisor = false;
        this.supervisorId = null;

        this.isSupervisor = employee.info.IsSupervisor;
        if (this.isSupervisor) {
            this.supervisorId = employee.info.Id;
        } else {
            this.isGroupFiltering = false;
        }

        this.journalResource = journalResourceFactory(currentProduct.code);

        let firstLoad = true;
        //  Open in Journal
        if ($location.hash()) {
            const contractId = parseInt($location.hash(), 10);
            this.filter = [{
                name: "Id",
                option: "eq",
                type: "Edm.Int32",
                values: [contractId],
            }];
            $location.hash("");
        }

        this.tableParams = new ngTableParams({
            count: 25,
            page: 1,
        }, {
            counts: [],
            getData: (params) => {
                this.infiniteScroll.loading = true;
                this.infiniteScroll.hasErrors = false;

                const $orderby = (this.order.by || "Id") + " " + (this.order.direction || "desc");

                const odataParams = {
                    $count: false,
                    $filter: null,
                    $orderby,
                    $select: [
                        "Id",
                        "StatusId",
                        "PaymentCount",
                        "PaymentKindId",
                        "InsuredContractorType",
                        "PaymentCanBeChange",
                        ...columnsToFetch,
                    ].join(", "),
                    $skip: (params.page() - 1) * params.count(),
                    $top: params.count(),
                    byGroup: this.isGroupFiltering,
                };

                if (firstLoad) {
                    odataParams.$filter = this.filter.map((filter) => filter.asString).filter((f) => f).join(" and ");
                } else {
                    odataParams.$filter = UgskTableFilterService.getFilterString(this.filtersColumns);
                    this.filter = this.filtersColumns;
                }
                if (odataParams.$filter === "") { odataParams.$filter = null; }

                return this.journalResource.fetchContracts(odataParams).$promise
                    .then((data) => this.infiniteScroll.loadData(params, data))
                    .then(() => {
                        if (!firstLoad) { return undefined; }

                        return this.fillColumnsWithFilters()
                            .then((filterColumns) => {
                                this.filtersColumns = filterColumns;
                                //  restore filters visibility
                                this.filtersColumns.forEach((filterColumn) => {
                                    if (filterColumn.pinned) {
                                        filterColumn.visible = true;
                                    }
                                });
                                this.filter.forEach((storedFilterColumn) => {
                                    const filterColumn = this.filtersColumns.find(
                                        (col) => col.name === storedFilterColumn.name,
                                    );
                                    if (filterColumn) { filterColumn.visible = true; }
                                });
                                //  restore filters values
                                (this.filter || []).forEach((storedFilter) => {
                                    const filterColumn = this.filtersColumns.find(
                                        (col) => col.name === storedFilter.name,
                                    );
                                    if (filterColumn) {
                                        filterColumn.values = storedFilter.values;
                                        filterColumn.option = storedFilter.option || filterColumn.defaultFilterOperation;
                                    }
                                });
                            })
                            .then(() => {
                                firstLoad = false;
                            });
                    })
                    .catch(() => {
                        this.infiniteScroll.hasErrors = true;
                    })
                    .finally(() => {
                        this.infiniteScroll.loading = false;
                    });
            },
        });

        this.applyFilter();
    }
    /* `ngFilter` option to `renderer` converter */
    public buildColumnsRendererFunctions() {
        const [$interpolate] = this.di(["$interpolate"]) as [angular.IInterpolateService];
        this.columns.forEach((definedColumn) => {
            if (definedColumn.ngFilter) {
                const filter = definedColumn.ngFilter;
                const compiler = $interpolate(`{{val | ${filter}}}`);
                definedColumn.renderer = (item, columnName) => {
                    const result = compiler({
                        val: item[columnName],
                    });
                    return result;
                };
                delete definedColumn.ngFilter;
            }
            if (definedColumn.template) {
                //  TODO: make rendrer function
            }
        });
    }
    /*  fill `this.columns` with types in response  */
    public fillColumnsWithTypes(typesOfColumns) {
        this.columns.forEach((column) => {
            if (column.name && !column.type) {
                const columnTypeObject = typesOfColumns.find((type) => type.name === column.name);
                if (columnTypeObject) {
                    column.type = columnTypeObject.type;
                }
            }
        });
    }
    public clearFilter() {
        this.filter = [];
        this.filtersColumns.forEach((filterColumn) => {
            filterColumn.values = [null, null];
            filterColumn.option = filterColumn.defaultFilterOperation || UgskODataService.getType(filterColumn.type).default;
            if (!filterColumn.pinned) { filterColumn.visible = false; }
        });
        this.order = {
            by: "Id",
            direction: "desc",
        };
        this.applyFilter();
    }
    public getStatusVariants() {
        const [contractStatuses] = this.resolve(["contractStatuses"]);
        const [$q] = this.di(["$q"]);
        return $q.resolve(contractStatuses.map((status) => ({
            title: status.Name,
            value: status.Name,
        })));
    }
    public getStatusPaymentVariants() {
        const [$q] = this.di(["$q"]);
        return $q.resolve([{
            title: "Оплачен",
            value: true,
        }, {
            title: "Не оплачен",
            value: false,
        }]);
    }
    public fillColumnsWithFilters() {
        const [$q] = this.di(["$q"]);
        return $q.all(this.filtersColumns.map((column) => {
            column.values = [];
            if (!column.option) {
                column.option = column.defaultFilterOperation || UgskODataService.getType(column.type).default;
            }
            if (!column.getVariants) { return column; }
            return column.getVariants().then((variants) => {
                variants.unshift({
                    title: "Все",
                    value: null,
                });
                column.values = [null, null];
                column.variants = variants;
                return column;
            });
        }));
    }

    /* Export methods */
    public export() {
        const dotsRe = /\./g;
        const decimalRenderer = (decimal) => {
            if (!decimal) { return ""; }
            return String(decimal).replace(dotsRe, ",");
        };
        return this.journalResource.fetchContracts({
            $count: false,
            $expand: null,
            $filter: UgskTableFilterService.getFilterString(this.filtersColumns),
            $orderby: (this.order.by || "Id") + " " + this.order.direction,
            $select: null,
            $skip: null,
            $top: 250,
            byGroup: this.isGroupFiltering,
        }).$promise
            .then((data) => data.value)
            .then((records) => records.map((record) => {
                return this.exportColumns.map((column) => {
                    if (Object.keys(JOURNAL_CLEANING_RULES).includes(column.name)) {
                        record[column.name] = JOURNAL_CLEANING_RULES[column.name](record[column.name], record);
                    }
                    let val = record[column.name];

                    if (column.type === "Edm.Decimal") {
                        val = decimalRenderer(val);
                    } else if (column.renderer) {
                        val = column.renderer(record, column.name);
                    }
                    return val;
                });
            }));
    }
    public playJournalTour() {
        const [
            ngIntroService,
        ] = this.di([
            "ngIntroService",
        ]);
        const introOptions = {
            doneLabel: "Спасибо",
            exitOnEsc: true,
            exitOnOverlayClick: true,
            nextLabel: "Далее",
            overlayOpacity: 0.3,
            prevLabel: "Назад",
            scrollToElement: false,
            showBullets: true,
            showStepNumbers: true,
            skipLabel: "Закрыть",
            steps: [{
                intro: "Добро пожаловать!<br/>Для ознакомления с новым Журналом нажмите на кнопку «ДАЛЕЕ»",
            }, {
                element: "#products-link",
                intro: "В верхней части экрана можно создать новый договор по любому продукту. Нажмите на кнопку «Продукты» и в выпадающем меню выберите нужный продукт.<br/><br/>Для продолжения нажмите «ДАЛЕЕ»",
                position: "right",
            }, {
                element: "#journals-link",
                intro: "В верхней части экрана можно перейти в любой продуктовый Журнал. Нажмите на кнопку «Журналы» и в выпадающем меню выберите нужный журнал.<br/><br/>Для продолжения нажмите «ДАЛЕЕ»",
                position: "right",
            }, {
                element: "#dropdownUserData",
                intro: "Смена пользователя осуществляется при нажатии на эту иконку или на имя пользователя.<br/><br/>Для продолжения нажмите «ДАЛЕЕ»",
                position: "left",
            }, {
                element: "#journal-column-ordering",
                intro: "Для сортировки нажмите на заголовок, при этом индикация в заголовке столбца покажет порядок сортировки. Сортировка возможна как в прямом, так и в обратном порядке.<br/><br/>Для продолжения нажмите «ДАЛЕЕ»",
                position: "bottom",
            }, {
                element: "#journal-compact-filters",
                intro: "Доступен быстрый поиск договоров по основным полям журнала. Фильтрация возможна как по одному, так и по нескольким столбцам. Поиск договоров осуществляется после нажатия на кнопку «Отфильтровать».<br/><br/>Для продолжения нажмите «ДАЛЕЕ»",
                position: "bottom",
            }, {
                element: "#button-filter",
                intro: "Поиск договоров по фильтрам осуществляется после нажатия на кнопку «Отфильтровать».<br/><br/>Для продолжения нажмите «ДАЛЕЕ»",
                position: "bottom",
            }, {
                element: "#button-clear-filter",
                intro: "Для очистки всех фильтров необходимо нажать на кнопку «Очистить».<br/><br/>Для продолжения нажмите «ДАЛЕЕ»",
                position: "bottom",
            }, {
                element: "#button-full-filter",
                intro: "При нажатии на кнопку «Расширенный поиск» откроется панель, в которой можно будет выполнить более гибкую выборку по полям журнала. Можно настроить поля и гибкие условия, по которым будет происходить выборка. Поиск договоров по фильтрам осуществляется так же после нажатия на кнопку «Отфильтровать».<br/><br/>Для продолжения нажмите «ДАЛЕЕ»",
                position: "bottom",
            }, {
                element: "#button-instruction",
                intro: "Более подробная инструкция по журналам ЮПРО всегда доступна по нажатию на кнопку «Инструкция».<br/><br/>Для продолжения нажмите «ДАЛЕЕ»",
                position: "bottom",
            }, {
                element: "#button-journal-export",
                intro: "Любой отфильтрованный журнал можно выгрузить в удобный EXCEL-файл. Для этого нажмите на кнопку «Экспорт».<br/><br/>Для продолжения нажмите «ДАЛЕЕ»",
                position: "bottom",
            }, {
                element: "#bottom-toolbar",
                intro: "Нижняя панель предназначена для работы с договорами: печать, кроссирование в любые продукты, смена владельца договора и т.д. Если какая-то из кнопок неактивна, попробуйте выделить договор левой кнопкой мыши.",
                position: "top",
            }, {
                element: "#button-play-tour",
                intro: "Спасибо большое за ознакомление с новым функционалом. Для повторного запуска нажмите на кнопку «Помощник»",
                position: "bottom",
            }],
        };

        const helpers = this.di<Helpers>("helpers");

        if (helpers.isEmbedMode()) {
            // div-ы, которых нет во встроенном режиме
            ["#products-link", "#journals-link", "#dropdownUserData"].forEach((divId) => {
                const introStep = introOptions.steps.find((step) => step.element === divId);
                if (introStep) {
                    delete introStep.element;
                    delete introStep.position;
                }
            });
        }

        this.isCompact = true;
        ngIntroService.setOptions(introOptions);
        ngIntroService.start();

        ngIntroService.onComplete(() => {
            this.$localStorage.HideJournalTour = true;
        });
    }
    /* Body methods */
    public openContract() {
        if (this.selectedContract) {
            const [$state] = this.di(["$state"]);
            $state.go(`app.${this.journalCode}.index`, {
                id: this.selectedContract.Id,
            });
        }
    }
    public applyFilter() {
        this.selection.clear();
        super.applyFilter();
    }
}
