import EngineeringEquipment from "domain/classes/engineeringEquipment.class";
import { InsurantPersonProxy } from "domain/proxies/insurantPersonProxy.class";
import moment from "moment";

import { BaseIFLBodyController } from "application/baseIFLProduct.body.controller";
import {
    AssuranceApartmentContract,
    AssuranceApartmentRepository,
    AssuranceApartmentRepositoryKeys,
    MovablePropertyAssuranceApartment,
} from "./assuranceApartment.factory";

import bonusMalusTemplate from "application/modalDialog/showBonusMalus.html";
import interiorTypeTemplate from "application/modalDialog/showInteriorType.html";

import * as angular from "angular";
import { IModalService } from "angular-ui-bootstrap";
import { Contract } from "domain/classes/contract.class";
import { Helpers, TAnchorToElementFn } from "infrastructure/app.helpers";
import { IDatePickerOptions } from "infrastructure/interfaces/IDatePickerOptions";
import { IElementInformation } from "infrastructure/interfaces/IElementInformation";
import { IOptionsPopover } from "infrastructure/interfaces/IOptionsPopover";
import IProlongableProductBodyController from "infrastructure/interfaces/IProlongableProductBodyController";
import IScope from "infrastructure/interfaces/IScope";
import { ISliderConfig } from "infrastructure/interfaces/ISliderConfig";
import { ITouchSpinConfig } from "infrastructure/interfaces/ITouchSpinConfig";
import { AddressDTO } from "infrastructure/interfaces/WebApi/AddressDTO";
import {
    DefiniteSublimitOfConstructionPartDTO,
} from "infrastructure/interfaces/WebApi/DefiniteSublimitOfConstructionPartDTO";
import Repository, { IRepositoryItem } from "infrastructure/Repository.class";
import { NotifyService } from "infrastructure/services/notifyService";
import { Int } from "infrastructure/types";
import IInsuranceTerm from "./interfaces/IInsuranceTerm";
import IInteriorCategory from "./interfaces/IInteriorCategory";
import ISublimitForInterior from "./interfaces/ISublimitForInterior";

export class AssuranceApartmentController extends BaseIFLBodyController implements IProlongableProductBodyController {
    public IsCivilLiabilityInsuredOnlyPreviousState: boolean;
    public propertyAddress: AddressDTO;
    public propertyAddressTimer: angular.IPromise<void>;
    public insurant: InsurantPersonProxy;
    public InteriorSquareCoefficient: number;
    public structuralElementPopoverMessage: string;
    public interiorElementPopoverMessage: string;
    public franchisePopoverMessage: string;
    public isShowPopover: boolean;
    public localPropertyStorage: null;
    public underwriterCoefficientTouchSpin: ITouchSpinConfig; // todo удалить при переходе к компоненте TouchSpin
    public touchSpinBaseOptions: ITouchSpinConfig; // todo удалить при переходе к компоненте TouchSpin
    public squareTouchSpin: ITouchSpinConfig; // todo удалить при переходе к компоненте TouchSpin
    public interiorDeclaredAmountSlider: ISliderConfig;
    public structuralElementDeclaredAmountSlider: ISliderConfig;
    public civilLiabilityDeclaredAmountSlider: ISliderConfig;
    public tooltipOptionsPopover: IOptionsPopover;
    public popoverMessageGroupConstant: string;
    public structuralElementInformation: IElementInformation;
    public interiorInformation: IElementInformation;
    public movablePropertyInformation: IElementInformation;
    public engineeringEquipmentsInformation: IElementInformation;
    public $rootScope: angular.IRootScopeService;
    public insuredAddressEqualPropertyAddress: boolean;
    public datePickerBaseYearOptions: IDatePickerOptions;
    public notifyService: NotifyService;
    public constructionYearOptions: IDatePickerOptions;
    public interiorRepairYearOptions: IDatePickerOptions;
    public propertyOverhaulYearOptions: IDatePickerOptions;
    public propertyInteriorRepairYearOptions: IDatePickerOptions;
    public engineeringEquipmentYearOptions: IDatePickerOptions;
    public propertyInteriorCategory: IInteriorCategory;
    public ngModelTouchSpinOptions: angular.INgModelOptions;
    public ngModelDatepickerOptions: angular.INgModelOptions;
    public ngModelInputWithSliderOptions: angular.INgModelOptions;
    public $interval: angular.IIntervalService;
    public $filter: angular.IFilterService;
    protected Contract: AssuranceApartmentContract;
    private $uibModal: IModalService;
    private helpers: Helpers;
    private focusToElement: (id: string) => void;
    private anchorToElement: TAnchorToElementFn;

    constructor($injector, $transition$, params, $scope) {
        super($injector, $transition$, params, $scope);

        [   this.anchorToElement,
            this.$uibModal,
            this.focusToElement,
            this.touchSpinBaseOptions,
            this.popoverMessageGroupConstant,
            this.datePickerBaseYearOptions,
            this.ngModelOptions,
            this.notifyService,
            this.helpers,
            this.$filter,
            this.$interval,
        ] = this.di([
            "anchorToElement",
            "$uibModal",
            "focusToElement",
            "touchSpinBaseOptions",
            "popoverMessageGroupConstant",
            "datePickerBaseYearOptions",
            "ngModelOptions",
            "notifyService",
            "helpers",
            "$filter",
            "$interval"]);

        this.$rootScope = this.di<angular.IRootScopeService>("$rootScope");
        (this.$rootScope as IScope).$anchorScroll.yOffset = 300;
    }

    public $onInit(): void {
        super.$onInit();
        this.phoneValidationService.setContractRequiredFields({
            individual: [
                { field: "InsuredLastName", description: "Фамилия страхователя" },
                { field: "InsuredFirstName", description: "Имя страхователя" },
                { field: "InsuredBirthday", description: "Дата рождения страхователя" }
            ],
            legalEntity: [
                { field: "InsuredOrgName", description: "Наименование организации страхователя" },
                { field: "InsuredOrgINN", description: "ИНН организации страхователя" }
            ],
        });
        this.IsCivilLiabilityInsuredOnlyPreviousState = false;
        this.propertyAddressTimer = null;
        this.insurant = new InsurantPersonProxy(this.Contract);
        this.InteriorSquareCoefficient = 0.847;
        this.localPropertyStorage = null;
        this.underwriterCoefficientTouchSpin = angular.copy(this.touchSpinBaseOptions);
        this.underwriterCoefficientTouchSpin.min = this.Repository.AssuranceApartmentUnderwriterCoefficientRestriction.Collection[0].AssuranceApartmentUnderwriterCoefficientRestrictionMinValue;
        this.underwriterCoefficientTouchSpin.max = this.Repository.AssuranceApartmentUnderwriterCoefficientRestriction.Collection[0].AssuranceApartmentUnderwriterCoefficientRestrictionMaxValue;
        this.squareTouchSpin = {
            boostat: 5,
            buttondown_class: "btn btn-white",
            buttonup_class: "btn btn-white",
            decimals: 2,
            delimiter: ",",
            forcestepdivisibility: "",
            max: 500,
            maxboostedstep: 10,
            min: 0,
            step: 1,
        };

        this.interiorDeclaredAmountSlider = {
            grid: true,
            hide_from_to: false,
            hide_min_max: true,
            max: this.Repository.AssuranceApartmentInteriorCategoryCalculatedCostRestriction && this.Repository.AssuranceApartmentInteriorCategoryCalculatedCostRestriction.Collection.length ?
                this.Repository.AssuranceApartmentInteriorCategoryCalculatedCostRestriction.Collection[0].CalculatedMaxAmount :
                0,
            min: this.Repository.AssuranceApartmentInteriorCategoryCalculatedCostRestriction && this.Repository.AssuranceApartmentInteriorCategoryCalculatedCostRestriction.Collection.length ?
                this.Repository.AssuranceApartmentInteriorCategoryCalculatedCostRestriction.Collection[0].CalculatedMinAmount :
                0,
            prettify: false,
            step: 10000,
            type: "single",
        };

        this.structuralElementDeclaredAmountSlider = {
            grid: true,
            hide_from_to: false,
            hide_min_max: true,
            max: this.Repository.AssuranceApartmentStructuralElementCalculatedCostRestriction && this.Repository.AssuranceApartmentStructuralElementCalculatedCostRestriction.Collection.length ?
                this.Repository.AssuranceApartmentStructuralElementCalculatedCostRestriction.Collection[0].CalculatedMaxAmount :
                0,
            min: this.Repository.AssuranceApartmentStructuralElementCalculatedCostRestriction && this.Repository.AssuranceApartmentStructuralElementCalculatedCostRestriction.Collection.length ?
                this.Repository.AssuranceApartmentStructuralElementCalculatedCostRestriction.Collection[0].CalculatedMinAmount :
                0,
            prettify: false,
            step: 10000,
            type: "single",
        };

        this.civilLiabilityDeclaredAmountSlider = {
            from: this.Contract.CivilLiabilityDeclaredAmount,
            grid: true,
            hide_from_to: false,
            hide_min_max: true,
            max: this.Repository.AssuranceApartmentCivilLiabilityCostRestriction && this.Repository.AssuranceApartmentCivilLiabilityCostRestriction.Collection.length ?
                this.Repository.AssuranceApartmentCivilLiabilityCostRestriction.Collection[0].MaxValue :
                0,
            min: this.Repository.AssuranceApartmentCivilLiabilityCostRestriction && this.Repository.AssuranceApartmentCivilLiabilityCostRestriction.Collection.length ?
                this.Repository.AssuranceApartmentCivilLiabilityCostRestriction.Collection[0].MinValue :
                0,
            prettify: false,
            step: 10000,
            type: "single",
        };

        this.tooltipOptionsPopover = {
            popoverEvent: "'mouseenter'",
            popoverMessage: this.popoverMessageGroupConstant,
            popoverPlaceShow: "top-left",
        };

        this.structuralElementInformation = {
            content: `Несущие и не несущие стены, капитальные перегородки, перекрытия, окна (с учетом остекления),
                    лестницы, внутренние инженерные сети (не включая инженерное оборудование) водо-, электро-, тепло-,
                    газоснабжения, канализации и вентиляции, двери (исключая межкомнатные двери).`,
            title: "Конструктивные элементы",
        };

        this.interiorInformation = {
            content: `Межкомнатные двери, включая дверные замки и ручки,
                        все виды внутренних отделочных покрытий пола, стен,
                        потолков, в том числе: штукатурные и малярные покрытия,
                        лепнина, отделка стен обоями, обивки, гобелены, облицовка плиткой,
                        стеновыми панелями, отделка стен всеми видами дерева, пластика и иными
                        материалами; легкие (некапитальные) межкомнатные перегородки,
                        встроенные шкафы и антресоли, подвесные потолки
                        (металлические, пластиковые, лепные и пр.), иные виды отделки.`,
            title: "Внутренняя отделка",
        };

        this.movablePropertyInformation = {
            content: `Мебель, предметы обстановки. Электробытовая техника,
                    электроинструменты, видео-, аудио-, орг-, фото-,
                    компьютерная техника, телевизоры, музыкальные инструменты,
                    оптические приборы и др. техника. Одежда, обувь.
                    Предметы домашнего обихода: посуда, книги, ковровые изделия,
                    хозяйственный инвентарь, инструменты и др.`,
            title: "Движимое имущество",
        };

        this.engineeringEquipmentsInformation = {
            content: `Санитарно-техническое оборудование (ванны, душевые кабины,
                раковины, унитазы, сантехническая арматура к ним и т.п.),
                    оборудование встроенных источников водо-, электро-, тепло-, газоснабжения
                    (встроенные котельные, газовые колонки и т.п.),
                    оборудование для горячего водоснабжения (бойлеры, котлы, водонагреватели и т.п.),
                    оборудование систем вентиляции и кондиционирования (встраиваемые вентиляторы, кондиционеры и т.п.),
                    телевизионные антенны, пожарно-охранная сигнализация, звуковая сигнализация, телефонизация,
                    оборудование сауны, камины, бассейны, системы подогрева пола и иное инженерное оборудование,
                    а также любые стационарно установленные аппараты и приборы,
                    соединенные с внутренними системами тепло-, водо-, электро-,
                    газоснабжения и канализации (водоотведения).`,
            title: "Инженерное оборудование",
        };

        /**
         * Справочные данные для контрола "Страховое возмещение"
         */

        this.Repository.PropertyDeterioration = {
            Collection: [{
                PropertyDeterioration: "С износом",
                PropertyDeteriorationValue: true,
            }, {
                PropertyDeterioration: "Без износа",
                PropertyDeteriorationValue: false,
            }]
        };

        /*
        * Справочные данные для контрола "Количество комнат"
        */
        this.Repository.PropertyRoomsCount = {
            Collection: [],
        };

        for (let i = 0; i < 10; i++) {
            this.Repository.PropertyRoomsCount.Collection.push({
                PropertyRoomsCount: (i + 1) === 10 ? `${(i + 1).toString()}  и более` : (i + 1).toString(),
                PropertyRoomsCountValue: i + 1,
            });
        }

        this.constructionYearOptions = angular.copy(this.datePickerBaseYearOptions);
        this.interiorRepairYearOptions = angular.copy(this.datePickerBaseYearOptions);
        this.interiorRepairYearOptions.startDate = moment().add(-35, "y").format("YYYY-MM-DD");

        this.propertyOverhaulYearOptions = angular.copy(this.constructionYearOptions);
        this.propertyInteriorRepairYearOptions = angular.copy(this.constructionYearOptions);

        /* todo по верстке продукта смотрел, нигде не используется, удалить после тестирования.
        this.сontractToYearOptions = {
            language: "ru",
        }; */

        /**
         * Маска для телефона
         */

        /* todo по верстке продукта смотрел, нигде не используется, удалить после тестирования
        this.maskdefinitions = {
            "?": /\d/,
            9: undefined,
        }; */

        this.Repository.DiscountRestrictions = {
            Collection: [{
                DiscountRestrictions: "Без скидки",
                DiscountRestrictionsId: 0,
            }]
        };

        /**
         * Глобальные настройки для контролов ввода
         */
        this.ngModelOptions = angular.copy(this.ngModelOptions);

        /*
            * Настройки для TouchSpin
            */
        this.ngModelTouchSpinOptions = angular.copy(this.ngModelOptions);
        (this.ngModelTouchSpinOptions.debounce as any).default = 3000;

        /*
            * Настройки для Datepicker'ов
            */
        this.ngModelDatepickerOptions = angular.copy(this.ngModelOptions);
        (this.ngModelDatepickerOptions.debounce as any).default = 2000;

        /*
            * Настройки для контролов ввода сумм
            */
        this.ngModelInputWithSliderOptions = angular.copy(this.ngModelOptions);
        (this.ngModelInputWithSliderOptions.debounce as any).default = 3000;
        this.insuredAddressEqualPropertyAddress = true;

        const signingDate = moment(this.Contract.SigningDate, "YYYY-MM-DD").startOf("day");
        const current = moment().startOf("day");
        if (this.Contract.ContractStatusId === 1 && signingDate.isBefore(current)) {
            this.notifyService.warningMessage("Внимание", "По данному договору устарела дата заключения договора. Рассчитайте договор заново для обновления даты заключения договора.");
        }

        this.engineeringEquipmentYearOptions = angular.copy(this.constructionYearOptions);
        this.engineeringEquipmentYearOptions.startDate = moment().add(-16, "y").format("YYYY-MM-DD");

        if (!this.Contract.ContractStatusId) {
            this.Contract.SigningDate = `${moment().format("YYYY-MM-DD")}T00:00:00`;
            this.Contract.ContractFrom = `${moment().add(1, "d").format("YYYY-MM-DD")}T00:00:00`;
            this.changeInsuranceTerm();
        } else {
            let message: string = "";
            const civilLiabilityDeclaredAmount = this.Contract.CivilLiabilityDeclaredAmount;
            const structuralDeclaredAmount = this.Contract.StructuralElementDeclaredAmount;
            const interiorDeclaredAmount = this.Contract.InteriorDeclaredAmount;

            if (this.Contract.NeedToInsureCivilLiability &&
                (civilLiabilityDeclaredAmount < this.Repository.AssuranceApartmentCivilLiabilityCostRestriction.Collection[0].MinValue ||
                    civilLiabilityDeclaredAmount > this.Repository.AssuranceApartmentCivilLiabilityCostRestriction.Collection[0].MaxValue)) {
                message += `Тарифы по Гражданской ответственности были изменены.
                            Рассчитайте договор заново для обновления суммы по ГО и расчёта Премии.<br>`;
            }

            if (this.Contract.NeedToInsureStructuralElement &&
                (structuralDeclaredAmount < this.Repository.AssuranceApartmentStructuralElementCalculatedCostRestriction.Collection[0].CalculatedMinAmount ||
                    structuralDeclaredAmount > this.Repository.AssuranceApartmentStructuralElementCalculatedCostRestriction.Collection[0].CalculatedMaxAmount)) {
                message += `Тарифы по Конструктивным элементам были изменены. Рассчитайте
                            договор заново для обновления суммы по КЭ и расчёта Премии.<br>`;
            }

            if (this.Contract.NeedToInsureInterior &&
                (interiorDeclaredAmount < this.Repository.AssuranceApartmentInteriorCategoryCalculatedCostRestriction.Collection[0].CalculatedMinAmount ||
                    interiorDeclaredAmount > this.Repository.AssuranceApartmentInteriorCategoryCalculatedCostRestriction.Collection[0].CalculatedMaxAmount)) {
                message += `Тарифы по Внутренней отделке были изменены.
                            Рассчитайте договор заново для обновления суммы по ВО и расчёта Премии.<br>`;
            }

            if (message.length > 0) {
                this.notifyService.warningMessage("Внимание", message);
            }
        }

        if (!this.Contract.InsuredBirthday) {
            this.Contract.InsuredBirthday = moment().add(-18, "y").format("YYYY-MM-DD") + "T00:00:00";
        }

        if (!this.Contract.InspectionInspectionDate) {
            this.Contract.InspectionInspectionDate = `${moment().format("YYYY-MM-DD")}T00:00:00`;
        }

        if (!this.Contract.InsuredAddressFiasId ||
            this.Contract.InsuredAddressFiasId !== this.Contract.PropertyAddressFiasId ||
            this.Contract.PropertyAddressUserDefinedAddress !== this.Contract.InsuredAddressUserDefinedAddress) {
            this.insuredAddressEqualPropertyAddress = false;
        }

        const currentSpecialOfferId = this.Repository.SpecialOffer.Collection.find(elem => elem.SpecialOfferId === this.Contract.SpecialOfferId);
        if (angular.isUndefined(currentSpecialOfferId) && this.Contract.SpecialOfferId) {
            this.Contract.SpecialOfferId = null;
        }

        const discountParams = this.Repository.AssuranceApartmentDiscountRestriction.Collection[0];

        // disctountParams = undefined, если таблица dbo.AssuranceApartmentDiscountRestriction
        // не содержит данных для связки филиал - канал продаж
        if (discountParams) {
            let minDiscount: number = parseInt(discountParams.MinValue, 10);
            const maxDiscount: number = parseInt(discountParams.MaxValue, 10);

            for (minDiscount; minDiscount <= maxDiscount; minDiscount++) {
                this.Repository.DiscountRestrictions.Collection.push({
                    DiscountRestrictions: `${minDiscount} %`,
                    DiscountRestrictionsId: minDiscount,
                });
            }
        }

        // Подписка на события

        this._$scope.$watch("[vm.Contract.PropertyAddressUserDefinedAddress, vm.Contract.PropertyAddressFiasId]", () => {
            this.updateInsuredAddressByPropertyAddress();
        }, true);

        this._$scope.$watch("vm.Contract.PreviousContractId + vm.Contract.CSIAPreviousContractId", (newVal, oldVal) => {
            if (!newVal && oldVal) {
                this.Contract.ContractFrom = this.getContractFromMinDate();
                this.changeInsuranceTerm();
            }

            if ((!newVal && oldVal) || (newVal && !oldVal)) {
                this.Contract.AssuranceApartmentInsuranceTermId = this.Repository.AssuranceApartmentInsuranceTerm.Value[0].Value;
                this.updateFieldRepository();
            }
        }, false);

        this._$scope.$on("$destroy", () => {
            this.stopTimer(this.propertyAddressTimer);
        });
    }

    /**
     * Запрос справочников при изменении поля
     * @param {string} fieldName - имя изменившегося поля
     * @returns {}
     */
    public updateFieldRepository(fieldName?: string): angular.IPromise<void> {
        this.blockUI.start("Обновляются справочные данные");
        return this.Repository.update(fieldName, this.Contract).then(() => {
            this.blockUI.stop();
        });
    }

    /*
    * Если установлена checkbox о совпадении адреса Страхователя и адреса объекта, то обновляем адрес страхователя
    * @returns {}
    */
    public updateInsuredAddressByPropertyAddress(): void {
        if (this.insuredAddressEqualPropertyAddress) {
            this.Contract.InsuredAddressUserDefinedAddress = this.Contract.PropertyAddressUserDefinedAddress;
            this.Contract.InsuredAddressFiasId = this.Contract.PropertyAddressFiasId;
            this.Contract.InsuredAddressKladrId = this.Contract.PropertyAddressKladrId;
            this.Contract.InsuredPersonAddressHasNotFiasInfo = this.Contract.InsuredObjectAddressHasNotFiasInfo;
        }
    }

    public getBonusMalusName(): string | object {
        const item = this.$filter<any>("filterBy")(this.Repository.AssuranceApartmentBonusMalus.Collection, ["AssuranceApartmentBonusMalusId"], this.Contract.AssuranceApartmentBonusMalusId);
        if (item[0]) {
            return item[0].AssuranceApartmentBonusMalus;
        }
        return "";
    }

    /**
     * Получение серии документа
     * @returns {}
     */

    public getPaymentDocumentSerial(): string {
        const item = this.$filter<any>("filterBy")(this.Repository.PaymentDocumentSerial.Collection, ["PaymentDocumentSerialId"], this.Contract.PaymentDocumentSerialId);
        if (item[0]) {
            return item[0].PaymentDocumentSerial;
        }
        return "";
    }

    public getSublimitsFromRepository(collection: ISublimitForInterior[]): object[] {
        return collection.map((item) => {
            if (item.Id && item.DefaultValue) {
                return {
                    SublimitOfConstructionPartId: item.Id,
                    Value: item.DefaultValue,
                };
            }
        }).filter((sl) => sl);
    }

    /**
     * Функция проверяет доступность поля для ввода иного материала
     * @returns {bool}
     */
    public otherMaterialIsDisabled(contract: Contract, collection: object, propertyName: string, otherPropertyName: string): boolean {
        const item: object = this.$filter<any>("filterBy")(collection, [`${propertyName}Id`], contract[`${propertyName}Id`]);
        if (item[0]) {
            const value: boolean = !item[0][`${propertyName}IsOther`];
            if (value) {
                contract[otherPropertyName] = "";
            }

            return value;
        }
        contract[otherPropertyName] = "";
        return true;
    }

    /**
     *
     * @param {object} repository - Репозиторий постройки
     * @param {object} property - свойство постройки
     * @returns {bool}
     */
    public elementIsVisible(
        repository: AssuranceApartmentRepository,
        property: AssuranceApartmentRepositoryKeys,
    ): boolean {
        return repository[property] === null || !repository[property].State;
    }

    public getContractFromMinDate(): string {
        if (this.isContractProlonged()) { return null; }
        return moment(this.Contract.SigningDate).add(1, "d").format("YYYY-MM-DD");
    }

    /**
     * @return {Boolean}
     */
    public isContractProlonged(): boolean {
        return Boolean(this.Contract.CSIAPreviousContractId || this.Contract.PreviousContractId);
    }

    public getContractFromMaxDate(): string | null {
        if (this.isContractProlonged()) { return null; }

        return moment(this.Contract.SigningDate).add(30, "d").format("YYYY-MM-DD");
    }

    public getInteriorRepairYearMinDate(): string {
        let minYear: number = 35;

        const item = this.Repository.PropertyInteriorCategory
            .Collection
            .filter(
                (interiorType) => interiorType.PropertyInteriorCategoryId === this.Contract.PropertyInteriorCategoryId,
            );
        if (item && item[0]) {
            minYear = item[0].PropertyInteriorCategoryMaxYear;
        }

        return moment().add(-minYear, "y").format("YYYY");
    }

    public getContractPaymentMinDate(prevDate: Date): string {
        return moment(prevDate).add(1, "d").format("YYYY-MM-DD");
    }

    public getContractPaymentMaxDate(): string {
        return moment(this.Contract.SigningDate).add(4, "M").format("YYYY-MM-DD");
    }

    public getInspectionInspectionMinDate(): string {
        return moment(this.Contract.SigningDate, "YYYY-MM-DD").add(-10, "d").format("YYYY-MM-DD");
    }

    public getInspectionInspectionMaxDate(): string {
        return moment(this.Contract.SigningDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    }

    /**
     * Добавляем новое имущество
     * @returns {}
     */
    public addMovableProperty(property): void {
        const item = new MovablePropertyAssuranceApartment(property.MovablePropertyTypeId);
        this.Contract.MovableProperties.push(item);
        this.checkIsCivilLiabilityInsuredOnly();
        this.anchorToElement(`DeclaredAmount${item.Guid}`);
    }

    public checkIsCivilLiabilityInsuredOnly(): void {
        const isCivilLiabilityInsuredOnlyCurrentState = this.IsCivilLiabilityInsuredOnlyCondition();

        if ((this.IsCivilLiabilityInsuredOnlyPreviousState === false &&
            isCivilLiabilityInsuredOnlyCurrentState === true) ||
            (this.IsCivilLiabilityInsuredOnlyPreviousState === true &&
                isCivilLiabilityInsuredOnlyCurrentState === false)) {
            this.IsCivilLiabilityInsuredOnlyPreviousState = isCivilLiabilityInsuredOnlyCurrentState;
            this.updateFieldRepository("IsCivilLiabilityInsuredOnly");
        }
    }

    public IsCivilLiabilityInsuredOnlyCondition(): boolean {
        return !this.Contract.MovableProperties[0] &&
            !this.Contract.EngineeringEquipments[0] &&
            this.Contract.NeedToInsureStructuralElement !== true &&
            this.Contract.NeedToInsureInterior !== true &&
            this.Contract.NeedToInsureCivilLiability === true;
    }

    /**
     * Добавляем новое инженерное оборудование
     * @returns {}
     */
    public addEngineeringEquipment(): void {
        const item = new EngineeringEquipment();
        this.Contract.EngineeringEquipments.push(item);
        this.checkIsCivilLiabilityInsuredOnly();
        this.anchorToElement(`Name${item.Guid}`);
    }

    public removeProperty(elements, index): void {
        elements.splice(index, 1);
        this.checkIsCivilLiabilityInsuredOnly();
    }

    public changeInsuredObjectAddressHasNotFiasInfo(): void {
        if (this.insuredAddressEqualPropertyAddress) { this.Contract.InsuredPersonAddressHasNotFiasInfo = this.Contract.InsuredObjectAddressHasNotFiasInfo; }
        if (this.Contract.PropertySquare && this.Contract.PropertyRoomsCount) { this.updateFieldRepository("InsuredObjectAddressHasNotFiasInfo"); }
        this.Validation.removeError("PropertyAddressUserDefinedAddress");
    }

    public changeInsuredPersonAddressHasNotFiasInfo(): void {
        this.Validation.removeError("InsuredAddressUserDefinedAddress");
    }

    /**
     *
     * @returns {} Открываем модальное окно для показа информации о типах отделки
     */
    public showInteriorTypeModal(): void {
        const helpers = this.di<any>("helpers"); // TODO Сделать интерфейс для helpers
        helpers.showInformationWindow(interiorTypeTemplate, "Типы отделки", "lg");
    }

    /**
     *
     * @returns {} Открываем модальное окно для показа информации о б истории страхования
     */
    public showBonusMalusModal(): void {
        const bonusMalusCollection = this.Repository.AssuranceApartmentBonusMalus.Collection;
        const helpers = this.di<Helpers>("helpers"); // TODO Сделать интерфейс для helpers
        const data = bonusMalusCollection.map(item => ({
            content: item.AssuranceApartmentBonusMalusDescription,
            title: item.AssuranceApartmentBonusMalusName,
        }));
        helpers.showInformationWindow(bonusMalusTemplate, "История страхования", "", data);
    }

    /**
     *
     * @returns {} Сумма заявленых страховых сумм
     */
    public calculatePropertyDeclaredAmountSum(properties: object): number | string {
        let sum: number = 0;

        if (properties) {
            angular.forEach(properties, (property) => {
                if (property.DeclaredAmount) {
                    sum += parseFloat(property.DeclaredAmount);
                }
            });
        }
        if (sum === 0) return "";
        return this.$filter<any>("thousand")(sum);
    }

    /**
     *
     * @returns {} Сумма расчитаных страховых сумм по имуществу
     */
    public calculatePropertyCalculatedAmountSum(properties: object): number {
        let sum = 0;

        if (properties) {
            angular.forEach(properties, (property) => {
                if (property.CalculatedAmount) {
                    sum += parseFloat(property.CalculatedAmount);
                }
            });
        }

        return this.$filter<any>("thousand")(sum);
    }

    /**
     * Функция срабатывающая при событии изменения выбранного периода страхования
     * @returns {} Ничего не возвращает, изменяет дату окончания договора
     */
    public changeInsuranceTerm(): void | IInsuranceTerm {
        const item: IInsuranceTerm = this.getInsuranceTerm();
        if (item) {
            this.Contract.ContractTo = `${moment(this.Contract.ContractFrom, "YYYY-MM-DD").add(item.AssuranceApartmentInsuranceTermValue, "M").add(-1, "d").format("YYYY-MM-DD")}T00:00:00`;
        }
    }

    /**
     * Получение выбранного периода страхования
     * @returns {object} Выбранный период страхования
     */
    public getInsuranceTerm(): null | IInsuranceTerm {
        const item = this.Repository.AssuranceApartmentInsuranceTerm.Collection.filter((term) => term.AssuranceApartmentInsuranceTermId === this.Contract.AssuranceApartmentInsuranceTermId);

        if (item[0]) {
            return item[0];
        }
        return null;
    }

    public changeNeedToInsure(propertyName, focusToElementId): void {
        this.checkIsCivilLiabilityInsuredOnly();
        if (this.Contract[propertyName]) {
            this.focusToElement(focusToElementId);
        } else {
            this.Contract[focusToElementId] = null;
        }
    }

    public changeNeedToInsureInterior(): void {
        if (!this.Contract.NeedToInsureInterior) {
            this.Contract.InteriorSublimits = angular.copy(
                this.getSublimitsFromRepository(
                    this.Repository.SublimitForInterior.Collection,
                ) as DefiniteSublimitOfConstructionPartDTO[]
            );
        }
        this.Contract.PropertyInteriorRepairYear = this.Contract.NeedToInsureInterior ? moment().format("YYYY") : null;
    }

    /*
    * если не указана площадь, количество комнат или указан адрес которому нет соответствующего тарифа, то выводится высплывающая подсказка
    * при наведении на контрол суммы КЭ
    * @ returns boolean
    */
    public isEnableStructuralElementPopover(): boolean {
        let maxValue: number = 0;
        if (!this.Repository.AssuranceApartmentStructuralElementCalculatedCostRestriction.Collection.length) {
            maxValue = 0;
        } else {
            maxValue = this.Repository.AssuranceApartmentStructuralElementCalculatedCostRestriction.Collection[0].CalculatedMaxAmount;
        }
        const roomCount: number = this.Contract.PropertyRoomsCount;
        const square: number = this.Contract.PropertySquare;
        const address: string = this.Contract.PropertyAddressUserDefinedAddress;
        const message: string = "Укажите площадь квартиры, количество комнат и адрес.";
        if (!square || !roomCount || !address) {
            this.structuralElementPopoverMessage = message;
            return true;
        }
        if (!maxValue) {
            this.structuralElementPopoverMessage = "Для указанного адреса объекта страхования - не предусмотрено страхование КЭ.";
            return true;
        }
        return false;
    }

    /*
    * если не указана площадь, то выводится высплывающая подсказка
    * при наведении на контрол суммы ВО
    * @ returns boolean
    */
    public isEnableInteriorElementPopover(): boolean {
        const square = this.Contract.PropertySquare;
        if (!square) {
            this.interiorElementPopoverMessage = "Укажите площадь квартиры, количество комнат и адрес";
            return true;
        }
        return false;
    }

    /*
    * в блоке КЭ\ВО функция определяет показывать слайдер или скрыть.
    * @ returns boolean
    */
    public isShowSlider(maxValue: number, needToInsure: string): boolean {
        if (this.Contract.isLocked()) {
            return false;
        }
        if (maxValue && this.Contract[needToInsure]) {
            return true;
        }
        return false;
    }

    /**
     * функция определяет блокировать или нет контролы франшизы и отображение popover когда контрол заблокирован
     * блокировка контрола срабатывает в случае если устанволен чек бокс ГО
     * а так же отсутствуют элементы ДИ/ИО , и не установлены чек боксы КЭ/ВО
     * @returns boolean
     */
    public isDisabledFranchiseType(): boolean {
        if (this.Repository.FranchiseType.State === 1 &&
            this.IsCivilLiabilityInsuredOnlyPreviousState === true) {
            this.franchisePopoverMessage = "Франшиза не предлагается для выбора, если страхуется только Гражданская ответственность";
            this.isShowPopover = true;
            return true;
        }

        this.isShowPopover = false;
        return false;
    }

    public bonusMalusUpdateFieldRepository(): void {
        this.updateFieldRepository(this.Repository.AssuranceApartmentBonusMalus.FieldName).then(() => {
            if (!this.Repository.PolicyNumberOfAnotherCompany.State) {
                this.Contract.PreviousContractId = null;
                this.Contract.PreviousContractNumber = null;
                this.Contract.PreviousPolicySerial = null;
                this.Contract.CSIAPreviousContractId = null;
            }
        });
    }

    public isPlannedPaymentLocked(index: number): boolean {
        return index === 0;
    }

    public changeRepairYear(): void {
        const item = this.Repository.PropertyInteriorCategory.Collection.filter(
            (interiorType) => interiorType.PropertyInteriorCategoryId === this.Contract.PropertyInteriorCategoryId,
        );

        if (this.Contract.PropertyInteriorRepairYear) {
            const value = item[0].PropertyInteriorCategoryMaxYear;
            const minYear: number = moment().subtract(value, "y").year();
            const maxYear: number = parseInt(String(this.Contract.PropertyInteriorRepairYear), 10);

            if (minYear > maxYear) {
                this.Contract.PropertyInteriorRepairYear = String(minYear);
            }
        }
        this.updateFieldRepository("PropertyInteriorCategory");
    }

    public yearComparator(model: object, master: object): boolean {
        return (String(model) === String(master));
    }

    public stopTimer(timer: angular.IPromise<void>): void {
        if (angular.isDefined(timer)) {
            this.$interval.cancel(timer);
        }
    }

    /*
    * Функция реагирующая на смену адреса.
    * Принцип такой: каждое изменение вызывает данную функцию, в функции запускается таймер для вызова обновления FR.
    * Вызвав изменение FR таймер выключается
    */
    public changePropertyAddress(): void {
        if (angular.isDefined(this.propertyAddressTimer)) {
            this.stopTimer(this.propertyAddressTimer);
        }
        this.propertyAddressTimer = this.$interval(() => {
            this.blockUI.start("Обновляются справочные данные");
            this.Repository.update("PropertyAddressUserDefinedAddress", this.Contract).then((res) => {
                this.blockUI.stop();
            });
            this.stopTimer(this.propertyAddressTimer);
        }, 4000);
    }

    public formModified(): boolean {
        return this.pageSharedData.Form.modified;
    }
    public removePreviousContract(): void {
        this.Contract.removePreviousContract();
    }
    protected get Repository() {
        return this.Contract.getRepository();
    }
}
