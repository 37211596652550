import uiSelectModule from "ui-select";
import "ui-select/dist/select.css";

import { NgComponentController } from "infrastructure/NgController";
import template from "./ugskMultipleSelectpicker.component.html";
import "./ugskMultipleSelectpicker.css";

angular.module("ugsk.components.multiple-selectpicker", [
    uiSelectModule,
]).component("ugskMultipleSelectpicker", {
    bindings: {
        change: "&ngChange",
        collectionFieldId: "@",
        collectionFieldName: "@",
        collectionValue: "<collection",
        disabled: "<",
        filter: "&",
        onInputChars: "&",
        model: "=ngModel",
        selectPlaceholder: "@",
        selectWidth: "@",
        ugskValidation: "@",
        limitSelectedValues: "<",
        limitSizeItems: "<",
    },
    controller: class extends NgComponentController {
        onInit() {
            this.externalFilter = angular.isDefined(this.$attrs.filter);
            this.externalChange = angular.isDefined(this.$attrs.ngChange);

            this.options = [];
            if (!this.model) {
                this.model = [];
            }
            this.collectionFieldId = this.collectionFieldId ? this.collectionFieldId : "Id";
            this.collectionFieldName = this.collectionFieldName ? this.collectionFieldName : "Name";
            this.selectPlaceholder = this.selectPlaceholder ? this.selectPlaceholder : "Выберите значение";
            this.validationName = this.ugskValidation ? this.ugskValidation : null;

            [this.validationService] = this.di(["validationService"]);

            if (angular.isUndefined(this.selectWidth)) {
                this.selectWidth = "col-xs-12";
            }
        }

        searchFn(val) {
            if (angular.isFunction(this.onInputChars)) {
                this.onInputChars({str: val});
            }
        }

        onSelectionChanged() {
            if (this.validationName) {
                this.validationService.removeError(this.validationName);
            }

            if (this.externalChange) {
                this.change();
            }
        }

        isItemDisabled(el) {
            if (el.State === 1) return true;
            if (!el[this.collectionFieldId]) {
                return false;
            }
            let item;
            if (this.externalFilter) {
                item = this.filter({
                    item: el,
                    model: this.model,
                });
            } else {
                item = this.model.filter(value => value === el[this.collectionFieldId]);
            }

            return item && item[0];
        }
    },
    controllerAs: "vm",
    template,
});
