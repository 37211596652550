import angular from "angular";
import { resetable } from "domain/mixes/resetable.mix";
import { Proxy } from "domain/proxies/proxy.class";
import { Guid } from "infrastructure/types";
import { Uuid } from "lib/uuid";
import { OsagoContract } from "../classes";

export class OsagoContractorProxy extends resetable(Proxy) {
    public guid: Guid;
    constructor(source: OsagoContract) {
        super(source);

        const defaults = {};
        angular.forEach(this.getRules(), (sourceProp, selfProp) => {
            defaults[selfProp] = source[sourceProp];
        });
        this.setDefaultValues(defaults);
    }
    //  @override
    public reset() {
        angular.forEach(this.getRules(), (val, prop) => {
            this[prop] = null;
        });
        super.reset();
        this.guid = Uuid.raw();
    }
    public isIndividual() {
        return this.contractorType === "физическое лицо";
    }
    public isLegalEntity() {
        //  return Boolean(this.insurant.contractorType.toLowerCase().indexOf("юрид") === 0);
        return String(this.contractorType).toLowerCase().startsWith("юрид");
    }
    /**
     * @description для совестимости с компонентой ugskInsurant
     * @requires ugsk.components.insurant:isPersonFieldsAvailable()
     */
    public isSoleProprietor() {
        return false;
    }
}
