import { NgComponentController } from "infrastructure/NgController";
import template from "./ugskValidation.component.html";

export default angular.module("ugsk.components.validation", []).component("ugskValidation", {
    bindings: {
        name: "@",
    },
    controller: class extends NgComponentController {
        onInit() {
            [this.validationService] = this.di(["validationService"]);
            this.names = this.name.split(",").map(item => item.trim());
            this.names.forEach(name => this.validationService.registerErrorKey(name));
            this.isShowingValidation();
        }

        isShowingValidation() {
            const container = this.$element.closest("div.form-group");
            const state = this.names.some(name => this.validationService.hasError(name));
            if (state) {
                container.addClass("has-error");
            } else {
                container.removeClass("has-error");
            }
            return state;
        }

        getErrors() {
            const messages = [];
            this.names.forEach(name => {
                const message = this.validationService.getMessages(name);
                if (angular.isDefined(message)) {
                    messages.push(...message);
                }
            });

            return messages;
        }

        onDestroy() {
            this.names.forEach(name => {
                this.validationService.unRegisterErrorKey(name);
            });
        }
    },
    controllerAs: "vm",
    template,
}).name;
