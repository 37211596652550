import angular from "angular";
import { capitalizeFirstLetter } from "infrastructure/app.helpers";
import { IODataResourceResult } from "infrastructure/interfaces";
import { EnvService } from "infrastructure/services/env.service";
import { Int } from "infrastructure/types";

export interface IAccidentInsuranceProgram {
    Code: Int;
    Id: Int;
    InsuranceProductId: Int;
    Name: string;
}

export interface IAccidentInsuranceProgramResource {
    programs: () => IODataResourceResult<IAccidentInsuranceProgram>;
}

angular.module("app.boxAccident.journal-resources", [])
    .factory("boxAccidentJournalInsuranceProgramsService", (
        envService: EnvService,
        $resource: ng.resource.IResourceService,
    ) => (productName: string): ng.resource.IResourceClass<IAccidentInsuranceProgramResource> => {
        const currentProductName = capitalizeFirstLetter(productName);
        const apiUrl = `${envService.read("apiUrl")}api`;
        const oDataUrl = `${currentProductName}ContractJournalItems/K3.Get${currentProductName}InsurancePrograms`;
        const actions = {
            programs: {
                method: "Get",
                url: `${apiUrl}/${oDataUrl}`,
            },
        };
        return $resource<IAccidentInsuranceProgramResource>(oDataUrl, {}, actions);
    });
