import { Transition } from "@uirouter/core";
import { OsagoToolbarController } from "../osago-toolbar.controller";

export class OsagoJournalToolbarController extends OsagoToolbarController {
    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
    ) {
        super($injector, $transition, {
            viewType: "journal",
        });
    }
}
