import { IXLSX } from "./IXLSX";

export class ExcelWriterService {
    public write(data: unknown[][], filename: string): Promise<void> {
        return import(/* webpackChunkName: "xlsx" */ "xlsx").then((XLSX: IXLSX) => {
            const workSheet = XLSX.utils.aoa_to_sheet(data);

            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, "Лист 1");

            XLSX.writeFile(workBook, `${filename}.xlsx`);
        });
    }
}
