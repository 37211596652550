import angular from "angular";
import moment from "moment";

import { InsurantPersonProxy } from "domain/proxies/insurantPersonProxy.class";
import { IDatePickerOptions } from "infrastructure/interfaces/IDatePickerOptions";
import IProlongableProductBodyController from "infrastructure/interfaces/IProlongableProductBodyController";
import { NotifyService } from "infrastructure/services/notifyService";
import { BaseIFLBodyController } from "../baseIFLProduct.body.controller";
import { IMyChoiceInsuranceTerm, MyChoiceContract, MyChoiceRepository } from "./myChoice.factory";

export class MyChoiceController extends BaseIFLBodyController implements IProlongableProductBodyController {
    public insurant: InsurantPersonProxy;
    public insuredAddressEqualPropertyAddress: boolean = false;
    public defaultConstructionYearOptions: IDatePickerOptions;
    public constructionYearOptionsNoLimits: IDatePickerOptions;

    protected Contract: MyChoiceContract;
    private baseYearOptions: IDatePickerOptions;
    public $onInit() {
        super.$onInit();
        this.phoneValidationService.setContractRequiredFields({
            individual: [
                { field: "InsuredLastName", description: "Фамилия страхователя" },
                { field: "InsuredFirstName", description: "Имя страхователя" },
                { field: "InsuredBirthday", description: "Дата рождения страхователя" },
            ],
            legalEntity: [
                { field: "InsuredOrgName", description: "Наименование организации страхователя" },
                { field: "InsuredOrgINN", description: "ИНН организации страхователя" },
            ],
        });

        const notifyService = this.di<NotifyService>("notifyService");
        const $scope = this._$scope;
        this.insurant = new InsurantPersonProxy(this.Contract);

        /**
         * * Настройки для выбора года страхования дома
         */
        this.baseYearOptions = {
            endDate: moment().format("YYYY"),
            format: "yyyy",
            language: "ru",
            minViewMode: 2,
            outFormat: "YYYY",
            startDate: moment().add(-90, "y").format("YYYY"),
        };
        this.defaultConstructionYearOptions = angular.copy(this.baseYearOptions);
        this.constructionYearOptionsNoLimits = angular.copy(this.baseYearOptions);
        delete this.constructionYearOptionsNoLimits.startDate;
        if (!this.Contract.SigningDate) {
            this.Contract.SigningDate = `${moment().format("YYYY-MM-DD")}T00:00:00`;
        }
        if (!this.Contract.ContractFrom) {
            this.Contract.ContractFrom = `${moment(this.Contract.SigningDate)
                .add(10, "d")
                .format("YYYY-MM-DD")}T00:00:00`;
            this.calculateContractTo();
        }

        const signingDate = moment(this.Contract.SigningDate, "YYYY-MM-DD").startOf("day");
        const current = moment().startOf("day");
        if (this.Contract.ContractStatusId === 1 && signingDate.isBefore(current)) {
            notifyService.warningMessage("Внимание", "По данному договору устарела дата заключения договора." +
            " Рассчитайте договор заново для обновления даты заключения договора.");
        }
        if (!this.Contract.InsuredBirthday) {
            this.Contract.InsuredBirthday = moment().add(-18, "y").format("YYYY-MM-DD") + "T00:00:00";
        }
        /* tslint:disable-next-line */
        if (this.Contract.InsuredAddressFiasId !== this.Contract.PropertyAddressFiasId || this.Contract.PropertyAddressUserDefinedAddress !== this.Contract.InsuredAddressUserDefinedAddress || this.Contract.InsuredAddressFiasId === null) {
            this.insuredAddressEqualPropertyAddress = false;
        }

        if (this.Contract.InsuredAddressUserDefinedAddress === this.Contract.PropertyAddressUserDefinedAddress
            && this.Contract.InsuredAddressFiasId === this.Contract.PropertyAddressFiasId
            && this.Contract.InsuredAddressKladrId === this.Contract.PropertyAddressKladrId) {
            this.insuredAddressEqualPropertyAddress = true;
        }

        $scope.$watch(() => this.Contract.PropertyAddressUserDefinedAddress, () => {
            this.updateInsuredAddressByPropertyAddress();
        });

        $scope.$watch(() => this.Contract.ContractFrom, (newDate, oldDate) => {
            if (newDate) {
                if (angular.equals(newDate, oldDate)) {
                    return;
                }
                if (this.Contract.InsuranceTermMyChoiceId !== null) {
                    const item = this.getInsuranceTerm();
                    this.Contract.ContractTo = moment(this.Contract.ContractFrom)
                        .add(item.InsuranceTermMyChoiceValue, "M")
                        .add(-1, "d")
                        .format();
                }
            }
        });

        $scope.$watch(
            () => this.Contract.PreviousContractId + this.Contract.CSIAPreviousContractId,
            (newVal, oldVal) => {
                if (!newVal && oldVal) {
                    this.Contract.ContractFrom = this.getContractFromMinDate();
                }
                if ((newVal && !oldVal) || (!newVal && oldVal)) {
                    this.Contract.InsuranceTermMyChoiceId = this.Repository.InsuranceTerm.Value[0].Value;
                }
            },
        );
    }

    public yearComparator(model, master) {
        return (String(model)) === (String(master));
    }

    public updateInsuredAddressByPropertyAddress() {
        if (this.insuredAddressEqualPropertyAddress === true) {
            this.Contract.InsuredAddressUserDefinedAddress = this.Contract.PropertyAddressUserDefinedAddress;
            this.Contract.InsuredAddressFiasId = this.Contract.PropertyAddressFiasId;
            this.Contract.InsuredAddressKladrId = this.Contract.PropertyAddressKladrId;
        }
    }

    public isContractProlonged() {
        return Boolean(this.Contract.CSIAPreviousContractId || this.Contract.PreviousContractId);
    }

    public getPreviousContractId() {
        return (this.Contract.CSIAPreviousContractId || this.Contract.PreviousContractId);
    }

    public calculateContractTo() {
        const item = this.getInsuranceTerm();
        if (item) {
            this.Contract.ContractTo = `${moment(this.Contract.ContractFrom)
                .add(item.InsuranceTermMyChoiceValue, "M")
                .add(-1, "d")
                .format("YYYY-MM-DD")}T00:00:00`;
        }
    }

    public getContractFromMinDate() {
        if (this.isContractProlonged()) {
            return null;
        }
        return moment(this.Contract.SigningDate).add(10, "d").format("YYYY-MM-DD");
    }
    public getContractFromMaxDate() {
        if (this.isContractProlonged()) {
            return null;
        }
        return moment(this.Contract.SigningDate).add(60, "d").format("YYYY-MM-DD");
    }

    public removePreviousContract(): void {
        this.Contract.removePreviousContract();
    }
    private getInsuranceTerm(): IMyChoiceInsuranceTerm {
        if (this.Repository.InsuranceTerm) {
            return this.Repository.InsuranceTerm.Collection
                .find((item) => this.Contract.InsuranceTermMyChoiceId === item.InsuranceTermMyChoiceId);
        }
        return null;
    }

    get constructionYearOptions(): IDatePickerOptions {
        if (this.Contract.isLocked()) {
            return this.constructionYearOptionsNoLimits;
        }

        return this.defaultConstructionYearOptions;
    }

    protected get Repository(): MyChoiceRepository {
        return this.Contract.getRepository();
    }
}
