/**
 * ugskOsagoParticipants - Участники договора в ОСАГО
 */
import angular from "angular";
import { OsagoRepository } from "application/osago/classes";
import { ITouchSpinConfig } from "infrastructure/interfaces/ITouchSpinConfig";
import { NgComponentController } from "infrastructure/NgController";

class UgskOsagoParticipants extends NgComponentController {
    public payoutCountTouchSpin: ITouchSpinConfig;
    public repository: OsagoRepository;
    public employeeId: number;

    public onInit(): void {
        this.payoutCountTouchSpin = {
            boostat: 5,
            buttondown_class: "btn btn-primary",
            buttonup_class: "btn btn-primary",
            max: 1000,
            maxboostedstep: 10,
            min: 0,
            step: 1,
        };
    }
    get driverRestriction() {
        return this.repository.DriverRestriction.Collection;
    }
    get driverRestrictionState() {
        return this.repository.DriverRestriction.State;
    }
}

export default angular.module("app.osago.components.participants", [])
.component("ugskOsagoParticipants", {
    bindings: {
        contract: "<",
        employeeId: "<",
        isFormLocked: "<",
        repository: "<",
        userCanEditRsaResult: "<",
    },
    controller: UgskOsagoParticipants,
    controllerAs: "vm",
    template: require("./ugskOsagoParticipants.component.html"),
})
.name;
