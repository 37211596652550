/**
 * Директива валидации email. Опирается на встроенную валидацию email в AngularJS
 * Для успешной работы необходимо установить в input `type="email"`
 * @see https://docs.angularjs.org/api/ng/input/input%5Bemail%5D
 */

import { ValidationService } from "infrastructure/services/validation.service";

interface IEmailScope extends ng.IScope {
    name: string;
}

export function EmailValidationDirective(validationService: ValidationService): ng.IDirective {
    "ngInject";

    return {
        link: (
            scope: IEmailScope,
            element: ng.IAugmentedJQuery,
            attributes: ng.IAttributes,
            controller: ng.INgModelController,
        ) => {
            const isValid = (email: string) => /.@.+\../gi.test(email);
            const removeCyrillic = (email) => {
                if (typeof email === "string") {
                    const newVal = email.replace(/[а-яё]/gi, "");
                    if (newVal !== email) {
                        controller.$setViewValue(newVal);
                        controller.$render();
                    }
                }

                return email;
            };
            controller.$parsers.push(removeCyrillic);
            controller.$parsers.push((email: string) => {
                if (email === "") {
                    return null;
                }
                if (!isValid(email)) {
                    validationService.addError(scope.name, ["Неверный формат Email"]);
                    return null;
                } else {
                    validationService.removeError(scope.name);
                    return email;
                }
            });
        },
        require: "ngModel",
        restrict: "A",
        scope: {
            name: "@",
        },
    };
}
