enum DOCUMENTS {
    PASSPORT_OF_RUSSIAN = "Паспорт гражданина РФ",
    BIRTH_CERTIFICATE = "Свидетельство о рождении",
    OFFICER_IDENTITY_CARD = "Удостоверение личности офицера",
    PASSPORT_OF_MINMORFLOT = "Паспорт Минморфлота",
    MILITARY_ID_SOLDIER = "Военный билет солдата (матроса, сержанта, старшины)",
    DIPLOMATIC_PASSPORT_OF_RUSSIAN = "Дипломатический паспорт гражданина РФ",
    PASSPORT_OF_FOREIGN_CITIZEN = "Паспорт иностранного гражданина",
    RESIDENCE = "Вид на жительство",
    REFUGEE_CERTIFICATE_IN_RUSSIAN = "Удостоверение беженца в РФ",
    TEMPORARY_IDENTITY_CARD_OF_RUSSIAN = "Временное удостоверение личности гражданина РФ",
    FOREIGN_PASSPORT_OF_RUSSIAN = "Загранпаспорт гражданина РФ",
    SAILOR_PASSPORT = "Паспорт моряка",
    STOCK_OFFICIER_MILITARY_CARD = "Военный билет офицера запаса",
    OTHER_DOCUMENTS_ISSUED = "Иные документы, выдаваемые органами МВД",
    DRIVING_LICENSE_OF_RUSSIAN = "Водительское удостоверение РФ",
    REGISTRATION_AUTHORITY = "Св-во о регистрации",
    INCORPORATION = "ЕГРЮЛ",
    CERTIFICATE_OF_REGISTRATION = "Свидетельство о постановке на учет в налоговом органе"
}

enum VEHICLE_DOCUMENTS {
    VEHICLE_PASSPORT = "Паспорт ТС",
    VEHICLE_ELECTRONIC_PASSPORT = "Электронный паспорт ТС",
    VEHICLE_REGISTRATION_CERTIFICATE = "Свидетельство о регистрации ТС",
    VEHICLE_SELF_PROPELLED_VEHICLE_PASSPORT = "Паспорт самоходной машины",
    REGISTRATION_CERTIFICATE = "Техпаспорт",
    TECHNICAL_PASS = "Технический талон",
    VEHICLE_FOREIGN_PASSPORT = "Техпаспорт иностранного государства",
    VEHICLE_PASSPORT_FOREIGN_STATE = "Паспорт ТС иностранного государства",
    CERTIFICATE_REGISTRATION_VEHICLE_FOREIGN_STATE = "Свидетельство о регистрации ТС иностранного государства",
    TECHNICAL_COUPON_FOREIGN_STATE = "Технический талон иностранного государства",
    PASSPORT_SELF_PROPELLED_CAR_FOREIGN_STATE = "Паспорт самоходной машины иностранного государства",
    SIMILAR_DOCUMENTS_VEHICLE = "Аналогичные документы на ТС",
}

enum VEHICLE_CHECKUP_DOCUMENTS {
    DIAGNOSTIC_CARD = "Диагностическая карта",
    INTERNATIONAL_CERTIFICATE_TECHNICAL_INSPECTION = "МСТО (международный сертификат технического осмотра)",
}
enum DRIVER_DOCUMENT_TYPE {
    DRIVERS_LICENSE_RF = 20,
    FOREIGN_DRIVING_LICENSE = 22,
    CERTIFICATE_OF_TRACTOR_DRIVER = 24,
    TEMPORARY_CERTIFICATE = 25,
}

interface IVehicleDoc {
    Id: number;
    VehicleDocIssuredDate: string;
    VehicleDocNumber: string;
    VehicleDocSerial: string;
    VehicleDocumentType: string;
}
interface IDocsType {
    InsuredDocType: string;
    OwnerDocType: string;
    VehicleDocs: IVehicleDoc[];
    VehicleCheckupDocType: string;
}

export class StaticValidationRules {
    private EXCEPTION_CHARACTERS_ADDRESS: string = "~\!@#$%^:&?*=+";
    private EXCEPTION_CHARACTERS_EMAIL: string = "\"№;:()\\";
    private EXCEPTION_CHARACTERS_INSURANT_NAME: string = "~!@\"№#$;%:^&?*()_+={}[]|\\\/,.<>";

    public driverLicenseSerial(value: string, { Drivers }, fieldName: string, guid: string) {
        const errorMessages = [];
        const driver = Drivers.find((item) => item.guid === guid);

        if (driver.DriverLicenseDocTypeId === DRIVER_DOCUMENT_TYPE.DRIVERS_LICENSE_RF) {
            if (value.length !== 4) {
                errorMessages.push("Длина серии документа должна состоять из 4 символов");
                return errorMessages;
            }
            const valStr = value.substring(0, 2);
            if (!(/[А-Я]$/.test(valStr) || /^[0-9]{2}$/.test(valStr))) {
                errorMessages.push(`Первые два cимвола серии должны быть цифрами
                                    или заглавными буквами русского алфавита`);
            }
            if (!(/[А-Я]$/.test(value.substring(2, 4)) || /^[0-9]{2}$/.test(value.substring(2, 4)))) {
                errorMessages.push(`Последние два символа серии должны быть цифрами
                                    или заглавными буквами русского алфавита`);
            }
        }
        if (driver.DriverLicenseDocTypeId === DRIVER_DOCUMENT_TYPE.FOREIGN_DRIVING_LICENSE) {
            if (value.length > 10) {
                errorMessages.push("Длина серии не должна превышать 10 символов");
            }
        }
        if (driver.DriverLicenseDocTypeId === DRIVER_DOCUMENT_TYPE.CERTIFICATE_OF_TRACTOR_DRIVER) {
            if (!/^[А-Я]{2}$/.test(value)) {
                errorMessages.push("Длина серии должна быть 2 символа и состоять из заглавных букв русского алфавита");
            }
        }
        if (driver.DriverLicenseDocTypeId === DRIVER_DOCUMENT_TYPE.TEMPORARY_CERTIFICATE) {
            if (!/^[А-Я]{2}$/.test(value)) {
                errorMessages.push("Длина серии должна быть 2 символа и состоять из заглавных букв русского алфавита");
            }
        }
        return errorMessages;
    }

    public driverLicenseNumber(value: string, { Drivers }, fieldName: string, guid: string) {
        const errorMessages = [];
        const driver = Drivers.find((item) => item.guid === guid);
        const checkRule = (item: string) => {
            if (!/^\d{6}$/.test(item)) {
                errorMessages.push("Длина номера документа должна состоять из 6 цифр");
            }
        };

        if (driver.DriverLicenseDocTypeId === DRIVER_DOCUMENT_TYPE.FOREIGN_DRIVING_LICENSE) {
            if (!(value.length >= 1 && value.length <= 40)) {
                errorMessages.push("Длина номера документа должна составлять от 1 до 40 символов");
            }
        } else {
            checkRule(value);
        }

        return errorMessages;
    }

    public VehicleCheckupDocumentSerial(value: string, docs: IDocsType) {
        const errorMessages = [];
        if (value && VEHICLE_CHECKUP_DOCUMENTS.DIAGNOSTIC_CARD === docs.VehicleCheckupDocType) {
            const DOC_TYPE = docs.VehicleCheckupDocType.toUpperCase();
            if (value.length > 0) {
                errorMessages.push(`${DOC_TYPE}: серия документа не заполняется`);
            }
        }
        // tslint:disable-next-line:max-line-length
        if (value && VEHICLE_CHECKUP_DOCUMENTS.INTERNATIONAL_CERTIFICATE_TECHNICAL_INSPECTION === docs.VehicleCheckupDocType) {
            const DOC_TYPE = docs.VehicleCheckupDocType.toUpperCase();
            if (value.length > 10) {
                errorMessages.push(`${DOC_TYPE}: серия документа должна составлять не более 10 символов`);
            }
        }
        return errorMessages;
    }
    public VehicleCheckupDocumentNumber(value: string, docs: IDocsType) {
        const errorMessages = [];
        if (value && VEHICLE_CHECKUP_DOCUMENTS.DIAGNOSTIC_CARD === docs.VehicleCheckupDocType) {
            const DOC_TYPE = docs.VehicleCheckupDocType.toUpperCase();
            const isValidNumbers = value.split("").every((val) => /\d/.test(val));
            if (!isValidNumbers) {
                errorMessages.push(`${DOC_TYPE}: номер документа должен состоять только из цифр`);
            }
            if (!(value.length === 15)) {
                errorMessages.push(`${DOC_TYPE}: длина номера документа должна составлять 15 символов`);
            }
        }
        // tslint:disable-next-line:max-line-length
        if (value && VEHICLE_CHECKUP_DOCUMENTS.INTERNATIONAL_CERTIFICATE_TECHNICAL_INSPECTION === docs.VehicleCheckupDocType) {
            const DOC_TYPE = docs.VehicleCheckupDocType.toUpperCase();
            if (!(value.length >= 1 && value.length <= 40)) {
                errorMessages.push(`${DOC_TYPE}: номер документа должен составлять от 1 до 40 символов`);
            }
        }
        return errorMessages;
    }
    public VehicleDocSerial(value: string, docs: IDocsType, fieldName: string): string[] {
        const checkLength = (doc, DOC_TYPE) => {
            if (!(doc.VehicleDocSerial.length <= 10)) {
                errorMessages.push(`${DOC_TYPE}: длина серии документа может составлять до 10 символов`);
            }
        };
        const errorMessages = [];
        if (docs.VehicleDocs.length > 0) {
            docs.VehicleDocs.forEach((doc) => {
                if (value && VEHICLE_DOCUMENTS.VEHICLE_PASSPORT === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    if (doc.VehicleDocSerial === "0000") {
                        errorMessages.push(`${DOC_TYPE}:
                                            серия ${doc.VehicleDocSerial} не корректна для ввода`);
                    }
                    if (!/^([0-9]){2}([А-Я]{2})$/.test(doc.VehicleDocSerial)) {
                        errorMessages.push(`${DOC_TYPE}: Серия должна состоять из 4 символов. Пример - 55АА`);
                    }
                    if (!(value.length === 4)) {
                        errorMessages.push(`${DOC_TYPE}: длина серии должна быть 4 символа`);
                    }
                }
                if (value && VEHICLE_DOCUMENTS.VEHICLE_REGISTRATION_CERTIFICATE === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    if (value.length === 4) {
                        const isValidNumbers = doc.VehicleDocSerial.split("").every((val) => /\d/.test(val));
                        if (!isValidNumbers) {
                            if (!/^(?:[0-9]{2})(?:[АВЕКМНОРСТУХ]|[0-9]){2}$/.test(doc.VehicleDocSerial.toUpperCase())) {
                                errorMessages.push(`${DOC_TYPE}: cерия должна состоять из 4 символов. Пример - 2525, 55АА. Допустимые буквы алфавита:  А, В, Е, К, М, Н, О, Р, С, Т, У, Х`);
                            }
                        }
                    } else {
                        errorMessages.push(`${DOC_TYPE}: длина серии документа должна быть 4 символа`);
                    }
                }
                if (value && VEHICLE_DOCUMENTS.VEHICLE_SELF_PROPELLED_VEHICLE_PASSPORT === doc.VehicleDocumentType) {
                    const isCharsCirilic = doc.VehicleDocSerial.split("").every((val) => /[а-я]/i.test(val));
                    const isCharsEng = doc.VehicleDocSerial.split("").every((val) => /[a-z]/i.test(val));
                    const isNumbers = doc.VehicleDocSerial.split("").every((val) => /[0-9]/.test(val));
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    if (isCharsCirilic || isCharsEng || isNumbers) {
                        if (!(doc.VehicleDocSerial.length === 2 || doc.VehicleDocSerial.length === 4)) {
                            errorMessages.push(`${DOC_TYPE}: cерия документа должна составлять 2 или 4 символа`);
                        }
                    } else {
                        errorMessages.push(`${DOC_TYPE}: не корректный формат серии документа`);
                    }
                }
                if (value && VEHICLE_DOCUMENTS.REGISTRATION_CERTIFICATE === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    checkLength(doc, DOC_TYPE);
                }
                if (value && VEHICLE_DOCUMENTS.VEHICLE_FOREIGN_PASSPORT === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    checkLength(doc, DOC_TYPE);
                }
                if (value && VEHICLE_DOCUMENTS.TECHNICAL_PASS === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    checkLength(doc, DOC_TYPE);
                }
                if (value && VEHICLE_DOCUMENTS.VEHICLE_PASSPORT_FOREIGN_STATE === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    checkLength(doc, DOC_TYPE);
                }
                // tslint:disable-next-line:max-line-length
                if (value && VEHICLE_DOCUMENTS.CERTIFICATE_REGISTRATION_VEHICLE_FOREIGN_STATE === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    checkLength(doc, DOC_TYPE);
                }
                if (value && VEHICLE_DOCUMENTS.PASSPORT_SELF_PROPELLED_CAR_FOREIGN_STATE === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    checkLength(doc, DOC_TYPE);
                    if (doc.VehicleDocSerial === "000000") {
                        // tslint:disable-next-line:max-line-length
                        errorMessages.push(`${DOC_TYPE}: Серия документа не может быть значением ${doc.VehicleDocSerial}`);
                    }
                }
                if (value && VEHICLE_DOCUMENTS.SIMILAR_DOCUMENTS_VEHICLE === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    checkLength(doc, DOC_TYPE);
                }
            });
        }
        return errorMessages;
    }
    public VehicleDocNumber(value: string, docs: IDocsType, fieldName: string): string[] {
        const errorMessages = [];
        const checkLength = (doc, DOC_TYPE) => {
            if (!(doc.VehicleDocNumber.length >= 1 && doc.VehicleDocNumber.length <= 40)) {
                errorMessages.push(`${DOC_TYPE}: длина номера документа может составлять от 1 до 40 символов`);
            }
        };

        if (docs.VehicleDocs.length > 0) {
            docs.VehicleDocs.forEach((doc) => {
                if (value && VEHICLE_DOCUMENTS.VEHICLE_PASSPORT === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    if (doc.VehicleDocNumber === "000000") {
                        errorMessages.push(`${DOC_TYPE}:
                                            номер ${doc.VehicleDocNumber} не корректен для ввода`);
                    }
                    const isValidNumbers = doc.VehicleDocNumber.split("").every((val) => /\d/.test(val));
                    if (!isValidNumbers) {
                        errorMessages.push(`${DOC_TYPE}: номер документа должен состоять из цифр`);
                    }
                    if (!(doc.VehicleDocNumber.length === 6)) {
                        errorMessages.push(`${DOC_TYPE}: длина номера документа должна быть 6 цифр`);
                    }
                }
                if (value && VEHICLE_DOCUMENTS.VEHICLE_REGISTRATION_CERTIFICATE === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    if (!(doc.VehicleDocNumber.length === 6)) {
                        errorMessages.push(`${DOC_TYPE}: длина номера документа должна быть 6 символов`);
                    }
                    if (doc.VehicleDocNumber === "0000") {
                        errorMessages.push(`${DOC_TYPE}: номер документа не может быть значением
                                            ${doc.VehicleDocNumber}`);
                    }
                    const isValidNumbers = doc.VehicleDocNumber.split("").every((val) => /\d/.test(val));
                    if (!isValidNumbers) {
                        errorMessages.push(`${DOC_TYPE}: номер документа должен состоять из цифр`);
                    }
                }
                if (value && VEHICLE_DOCUMENTS.VEHICLE_SELF_PROPELLED_VEHICLE_PASSPORT === doc.VehicleDocumentType) {
                    const isNumbers = doc.VehicleDocNumber.split("").every((val) => /\d/.test(val));
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    if (!isNumbers) {
                        errorMessages.push(`${DOC_TYPE}: номер документа должен состоять только из цифр`);
                    }
                    if (!(doc.VehicleDocNumber.length === 6)) {
                        errorMessages.push(`${DOC_TYPE}: длина номера документа должна составлять 6 символов`);
                    }
                }
                if (value && VEHICLE_DOCUMENTS.REGISTRATION_CERTIFICATE === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    checkLength(doc, DOC_TYPE);
                }
                if (value && VEHICLE_DOCUMENTS.VEHICLE_FOREIGN_PASSPORT === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    checkLength(doc, DOC_TYPE);
                }
                if (value && VEHICLE_DOCUMENTS.TECHNICAL_PASS === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    checkLength(doc, DOC_TYPE);
                }
                if (value && VEHICLE_DOCUMENTS.VEHICLE_PASSPORT_FOREIGN_STATE === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    checkLength(doc, DOC_TYPE);
                }
                // tslint:disable-next-line:max-line-length
                if (value && VEHICLE_DOCUMENTS.CERTIFICATE_REGISTRATION_VEHICLE_FOREIGN_STATE === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    checkLength(doc, DOC_TYPE);
                }
                if (value && VEHICLE_DOCUMENTS.PASSPORT_SELF_PROPELLED_CAR_FOREIGN_STATE === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    checkLength(doc, DOC_TYPE);
                }
                if (value && VEHICLE_DOCUMENTS.SIMILAR_DOCUMENTS_VEHICLE === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    checkLength(doc, DOC_TYPE);
                }
                if (value && VEHICLE_DOCUMENTS.VEHICLE_ELECTRONIC_PASSPORT === doc.VehicleDocumentType) {
                    const DOC_TYPE = doc.VehicleDocumentType.toUpperCase();
                    if (!(doc.VehicleDocNumber.length === 15)) {
                        errorMessages.push(`${DOC_TYPE}: длина номера документа должна составлять 15 символов`);
                    }
                    if (!/(?:[1-3])(?:[0-9]{3})[02](?:[1-4])(?:[0-9]{9})$/gi.test(doc.VehicleDocNumber)) {
                        errorMessages.push(`${DOC_TYPE}: не корректный формат номера документа`);
                    }
                }
            });
        }
        return errorMessages;
    }
    public DocSerial(value: string, docs: IDocsType, fieldName: string) {
        const InsuredDoc = "InsuredDocType";
        const OwnerDoc = "OwnerDocType";
        const checkRuleOfficerCard = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.OFFICER_IDENTITY_CARD === docs[docType]) {
                const isValidChars = value.split("").every((val) => /[А-Я]/gi.test(val));
                if (!(value.length === 2)) {
                    errorMessages.push("Длина серии должна быть 2 символа");
                }
                if (!isValidChars) {
                    errorMessages.push("Серия должна состоять из символов русского алфавита");
                }
            }
            return errorMessages;
        };
        const checkRulePassportMinmorflot = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.PASSPORT_OF_MINMORFLOT === docs[docType]) {
                const isValidChars = value.split("").every((val) => /[А-Я]/gi.test(val));
                if (!(value.length === 2)) {
                    errorMessages.push("Длина серии должна быть 2 символа");
                }
                if (!isValidChars) {
                    errorMessages.push("Серия должна состоять из символов русского алфавита");
                }
            }
            return errorMessages;
        };
        const checkRuleMilitaryIDSoldier = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.MILITARY_ID_SOLDIER === docs[docType]) {
                const isValidChars = value.split("").every((val) => /[А-Я]/gi.test(val));
                if (!(value.length === 2)) {
                    errorMessages.push("Длина серии должна быть 2 символа");
                }
                if (!isValidChars) {
                    errorMessages.push("Серия должна состоять из символов русского алфавита");
                }
            }
            return errorMessages;
        };
        const checkRuleDiplomaticPassportOFRussian = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.DIPLOMATIC_PASSPORT_OF_RUSSIAN === docs[docType]) {
                if (value === "00") {
                    errorMessages.push(`Серия документа не может быть значением ${value}`);
                }
                if (!(value.length === 2)) {
                    errorMessages.push(`Длина серии должна быть 2 цифры`);
                }
                const isValidNumber = value.split("").every((val) => /[0-9]/gi.test(val));
                if (!isValidNumber) {
                    errorMessages.push("Серия должна состоять только из цифр");
                }
            }
            return errorMessages;
        };
        const checkRuleCertificateOfRegistration = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.CERTIFICATE_OF_REGISTRATION === docs[docType]) {
                if (value === "00") {
                    errorMessages.push(`Серия документа не может быть значением ${value}`);
                }
                if (!(value.length === 2)) {
                    errorMessages.push(`Длина серии документа должна состоять из 2 цифр`);
                }
                const isValidNumber = value.split("").every((val) => /[0-9]/gi.test(val));
                if (!isValidNumber) {
                    errorMessages.push("Серия должна состоять только из цифр");
                }
            }
            return errorMessages;
        };
        const checkRuleResidence = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.RESIDENCE === docs[docType]) {
                if (value === "00") {
                    errorMessages.push(`Серия документа не может быть значением ${value}`);
                }
                if (/\d+(?!\S)/g.test(value)) {
                    if (!/(\80|\81|\82|\83|\90)(?!\S)/g.test(value)) {
                        errorMessages.push("Серия состоящая только из цифр, может быть 80, 81, 82, 83, 90");
                    }
                } else if (!/(?:ИР|[А-Я]\Г)-(?:I{1,3}$)/gi.test(value)) {
                    errorMessages.push("Не корректный формат серии документа");
                }
            }
            return errorMessages;
        };
        const checkRefugeeCertificateINRussian = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.REFUGEE_CERTIFICATE_IN_RUSSIAN === docs[docType]) {
                if (!(value.length <= 2)) {
                    errorMessages.push("Длина серии должна быть не более 2 цифр");
                }
            }
            return errorMessages;
        };
        const checkTemporaryIdentityCardRussian = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.TEMPORARY_IDENTITY_CARD_OF_RUSSIAN === docs[docType]) {
                if (!(value.length <= 10)) {
                    errorMessages.push("Длина серии документа может быть до 10 цифр");
                }
            }
            return errorMessages;
        };
        const checkPassportOfRussian = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.PASSPORT_OF_RUSSIAN === docs[docType]) {
                const isValidNumbers = value.split("").every((val) => /\d/.test(val));
                if (value === "0000") {
                    errorMessages.push(`Серия документа ${value} запрещена для ввода`);
                }
                if (!isValidNumbers) {
                    errorMessages.push("Серия документа может состоять только из цифр");
                }
                if (value.length < 4) {
                    errorMessages.push("Серия документа должна состоять из 4 цифр");
                }
            }
            return errorMessages;
        };
        const checkForeignPassportOfRussian = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.FOREIGN_PASSPORT_OF_RUSSIAN === docs[docType]) {
                if (value.length !== 2) {
                    errorMessages.push("Длина серии документа должна быть 2 цифры");
                }
                const isValidNumbers = value.split("").every((val) => /\d/.test(val));
                if (!isValidNumbers) {
                    errorMessages.push("Серия должна состоять только из цифр");
                }
            }
            return errorMessages;
        };
        const checkOtherDocumentsIssued = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.OTHER_DOCUMENTS_ISSUED === docs[docType]) {
                if (!(value.length <= 10)) {
                    errorMessages.push("Длина серии документа должна составлять до 10 символов");
                }
            }
            return errorMessages;
        };
        const checkRegistrationAuthority = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.REGISTRATION_AUTHORITY === docs[docType]) {
                if (value === "00") {
                    errorMessages.push(`Значение серии ${value} запрещено для ввода`);
                }
                if (!(value.length === 2)) {
                    errorMessages.push("Длина серии должна составлять 2 цифры");
                }
                const isValidNumbers = value.split("").every((val) => /\d/.test(val));
                if (!isValidNumbers) {
                    errorMessages.push("Серия документа должна состоять только из цифр");
                }
            }
            return errorMessages;
        };
        const checkIncorporation = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.INCORPORATION === docs[docType]) {
                if (!(value.length === 0)) {
                    errorMessages.push("Серия документа запрещена для ввода");
                }
            }
            return errorMessages;
        };
        const checkRuleBirthCertificate = (docType): string[] => {
            const errorMessages = [];
            const romanMap = {I: 1, V: 5, X: 10};
            if (value && DOCUMENTS.BIRTH_CERTIFICATE === docs[docType]) {
                const dashIndex = value.indexOf("-");
                const isRomanNumbers = (str: string) => str.split("").every((char) => /[IXV]/i.test(char));
                const isValidChars = (str: string) => str.split("").every((char) => /[1УХ]/i.test(char));
                const convertRomanToNumber = (str: string) => {
                    if (isRomanNumbers(str)) {
                            return  [...str].reduce((accumulator, currentValue, index, arr) => {
                            if (romanMap[arr[index + 1]] > romanMap[currentValue]) {
                                return accumulator - romanMap[currentValue];
                            } else {
                                return accumulator + romanMap[currentValue];
                            }
                        }, 0);
                    }
                    return 0;
                };

                if (dashIndex >= 0) {
                    const part1 = value.slice(0, dashIndex);
                    const part2 = value.slice(dashIndex + 1, value.length);
                    if (part1 && part2) {
                        if (!(isRomanNumbers(part1) || isValidChars(part1))) {
                            errorMessages.push(`Символы серии перед дефисом могут
                            состоять только из Римских симоволов (I, X, V) или из символов (1, У, Х)`);
                        } else if (convertRomanToNumber(part1) > 33) {
                            errorMessages.push(`Число серии документа перед дефисом не может быть больше XXXIII (33),
                                                введеное значение ${part1} (${convertRomanToNumber(part1)})`);
                        }
                        if (!/^[А-Я]{2}$/gi.test(part2)) {
                            errorMessages.push(`Символы серии после дефиса должны содержать
                                                только символы русского алфавита в количестве 2 символа`);
                        }
                    } else {
                        if (!part1) { errorMessages.push("Серия документа должна содержать символы перед дефисом"); }
                        if (!part2) { errorMessages.push("Серия документа должна содержать символы после дефиса"); }
                    }
                } else {
                    errorMessages.push("Серия документа должна содержать символ дефис (-)");
                }
            }
            return errorMessages;
        };

        if (["InsuredDocSerial", "InsuredPersonDocSerial"].includes(fieldName)) {
            return [...checkRuleOfficerCard(InsuredDoc),
                    ...checkRulePassportMinmorflot(InsuredDoc),
                    ...checkRuleMilitaryIDSoldier(InsuredDoc),
                    ...checkRuleDiplomaticPassportOFRussian(InsuredDoc),
                    ...checkRuleCertificateOfRegistration(InsuredDoc),
                    ...checkRuleResidence(InsuredDoc),
                    ...checkRefugeeCertificateINRussian(InsuredDoc),
                    ...checkTemporaryIdentityCardRussian(InsuredDoc),
                    ...checkPassportOfRussian(InsuredDoc),
                    ...checkForeignPassportOfRussian(InsuredDoc),
                    ...checkOtherDocumentsIssued(InsuredDoc),
                    ...checkRegistrationAuthority(InsuredDoc),
                    ...checkIncorporation(InsuredDoc),
                    ...checkRuleBirthCertificate(InsuredDoc)];
        }

        if (["OwnerDocSerial", "OwnerPersonDocSerial"].includes(fieldName)) {
                return [...checkRuleOfficerCard(OwnerDoc),
                        ...checkRulePassportMinmorflot(OwnerDoc),
                        ...checkRuleMilitaryIDSoldier(OwnerDoc),
                        ...checkRuleDiplomaticPassportOFRussian(OwnerDoc),
                        ...checkRuleCertificateOfRegistration(OwnerDoc),
                        ...checkRuleResidence(OwnerDoc),
                        ...checkRefugeeCertificateINRussian(OwnerDoc),
                        ...checkTemporaryIdentityCardRussian(OwnerDoc),
                        ...checkPassportOfRussian(OwnerDoc),
                        ...checkForeignPassportOfRussian(OwnerDoc),
                        ...checkOtherDocumentsIssued(OwnerDoc),
                        ...checkRegistrationAuthority(OwnerDoc),
                        ...checkIncorporation(OwnerDoc)];
        }
    }

    public DocNumber(value: string, docs: IDocsType, fieldName: string): string[] {
        const InsuredDoc = "InsuredDocType";
        const OwnerDoc = "OwnerDocType";
        const checkRuleBirthCertificate = (docType): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.BIRTH_CERTIFICATE === docs[docType]) {
                const isValidNumber = value.split("").every((val) => /\d/.test(val));
                if (value === "000000") {
                    errorMessages.push(`Введено недопустимое значение ${value}`);
                }
                if (!isValidNumber) {
                    errorMessages.push("Номер свидельства о рождении должен содержать только цифры от 0 до 9");
                }
                if (!(value.length === 6)) {
                    errorMessages.push("Номер свидельства о рождении должен состоять из 6 цифр");
                }
            }
            return errorMessages;
        };
        const checkRuleOfficerCard = (docType): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.OFFICER_IDENTITY_CARD === docs[docType]) {
                if (value === "0000000") {
                    errorMessages.push(`Значение ${value} для ввода запрещено`);
                }
                if (value === "1234567") {
                    errorMessages.push(`Значение ${value} для ввода запрещено`);
                }
                if (!(value.length === 6)) {
                    errorMessages.push("Номер удостоверения личности офицера должен состоять из 6 цифр");
                }
                const isValidNumber = value.split("").every((val) => /\d+/g.test(val));
                if (!isValidNumber) {
                    errorMessages.push("Номер удостоверия личности должен состоять только из цифр");
                }
            }
            return errorMessages;
        };
        const checkRulePassportMinmorflot = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.PASSPORT_OF_MINMORFLOT === docs[docType]) {
                const isValidChars = value.split("").every((val) => /[0-9]/gi.test(val));
                if (!(value.length === 6)) {
                    errorMessages.push("Длина серии должна быть 6 символов");
                }
                if (!isValidChars) {
                    errorMessages.push("Номер должен состоять только из цифр");
                }
            }
            return errorMessages;
        };
        const checkRuleMilitaryIDSoldier = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.MILITARY_ID_SOLDIER === docs[docType]) {
                if (value === "0000000" || value === "123456") {
                    errorMessages.push(`Номер документа не может быть значением ${value}`);
                }
                if (!([6, 7].includes(value.length))) {
                    errorMessages.push("Номер документа может состоять только из 6 или 7 цифр");
                }
                const isValidValues = value.split("").every((val) => /\d/g.test(val));
                if (!isValidValues) {
                    errorMessages.push("Номер документа должен состоять только из цифр");
                }
            }
            return errorMessages;
        };
        const checkRuleDiplomaticPassportOFRussian = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.DIPLOMATIC_PASSPORT_OF_RUSSIAN === docs[docType]) {
                if (value === "0000000" || value === "1234567") {
                    errorMessages.push(`Номер документа не может быть значением ${value}`);
                }
                if (!(value.length === 7)) {
                    errorMessages.push(`Длина Номера документа должна быть 7 цифр`);
                }
                const isValidNumber = value.split("").every((val) => /[0-9]/g.test(val));
                if (!isValidNumber) {
                    errorMessages.push("Номер документа должен состоять только из цифр");
                }
            }
            return errorMessages;
        };
        const checkRuleCertificateOfRegistration = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.CERTIFICATE_OF_REGISTRATION === docs[docType]) {
                if (value === "0000000" || value === "1234567") {
                    errorMessages.push(`Номер документа не может быть значением ${value}`);
                }
                if (!(value.length === 9)) {
                    errorMessages.push(`Длина номера документа должна состоять из 9 цифр`);
                }
                const isValidNumber = value.split("").every((val) => /[0-9]/g.test(val));
                if (!isValidNumber) {
                    errorMessages.push("Номер документа должен состоять только из цифр");
                }
            }
            return errorMessages;
        };
        const checkRulePassportForeignCitizen = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.PASSPORT_OF_FOREIGN_CITIZEN === docs[docType]) {
                const isValidNumbers = value.split("").every((val) => /\d/.test(val));
                if (!isValidNumbers) {
                    errorMessages.push("Номер документа должен состоять только из цифр");
                }
            }
            return errorMessages;
        };
        const checkRuleResidence = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.RESIDENCE === docs[docType]) {
                if (value === "0000000" || value === "1234567") {
                    errorMessages.push(`Номер документа не может быть значением ${value}`);
                }
                if (!(value.length >= 1 && value.length <= 12)) {
                    errorMessages.push("Длина номера документа должна составлять от 1 до 12 цифр");
                }
            }
            return errorMessages;
        };
        const checkRefugeeCertificateINRussian = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.REFUGEE_CERTIFICATE_IN_RUSSIAN === docs[docType]) {
                if (value === "0000000" || value === "1234567") {
                    errorMessages.push(`Номер документа не может быть значением ${value}`);
                }
                if (!(value.length >= 1 && value.length <= 12)) {
                    errorMessages.push("Длина номера документа должна составлять от 1 до 12 цифр");
                }
                const isValidNumbers = value.split("").every((val: string) => /d/.test(val));
                if (!isValidNumbers) {
                    errorMessages.push("Номер документа может состоять только из цифр");
                }
            }
            return errorMessages;
        };
        const checkTemporaryIdentityCardRussian = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.TEMPORARY_IDENTITY_CARD_OF_RUSSIAN === docs[docType]) {
                if (!(value.length >= 1 && value.length <= 12)) {
                    errorMessages.push("Длина номера документа может быть от 1 до 12 цифр");
                }
                const isValidNumbers = value.split("").every((val) => /\d/.test(val));
                if (!isValidNumbers) {
                    errorMessages.push("Номер документа должен состоять только из цифр");
                }
            }
            return errorMessages;
        };
        const checkPassportOfRussian = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.PASSPORT_OF_RUSSIAN === docs[docType]) {
                const isValidNumbers = value.split("").every((val) => /\d/.test(val));
                if (!isValidNumbers) {
                    errorMessages.push("Номер документа может состоять только из цифр");
                }
                if (value.length < 6) {
                    errorMessages.push("Номер документа должен состоять из 6 цифр");
                }
                if (value === "000000" || value === "123456") {
                    errorMessages.push(`Номер документа ${value} запрещен для ввода`);
                }
            }
            return errorMessages;
        };
        const checkForeignPassportOfRussian = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.FOREIGN_PASSPORT_OF_RUSSIAN === docs[docType]) {
                if (value.length !== 7) {
                    errorMessages.push("Длина номера документа должна быть 7 цифр");
                }
                const isValidNumbers = value.split("").every((val) => /\d/.test(val));
                if (!isValidNumbers) {
                    errorMessages.push("Номер документа должен состоять только из цифр");
                }
            }
            return errorMessages;
        };
        const checkSailorPassport = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.SAILOR_PASSPORT === docs[docType]) {
                if (![6, 7].includes(value.length)) {
                    errorMessages.push("Длина номера документа может составлять 6 или 7 цифр");
                }
                if (value === "0000000" || value === "123456") {
                    errorMessages.push(`Значение ${value} запрещено для ввода`);
                }
                const isValidNumbers = value.split("").every((val) => /\d/.test(val));
                if (!isValidNumbers) {
                    errorMessages.push("Номер документа может состоять только из цифр");
                }
            }
            return errorMessages;
        };
        const checkRuleStockOfficerMilitaryCard = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.STOCK_OFFICIER_MILITARY_CARD === docs[docType]) {
                if (![6, 7].includes(value.length)) {
                    errorMessages.push("Длина номера документа может состоять из 6 или 7 цифр");
                }
                if (value === "0000000") {
                    errorMessages.push(`Номер документа не может быть значением ${value}`);
                }
                const isValidNumbers = value.split("").every((val) => /\d/.test(val));
                if (!isValidNumbers) {
                    errorMessages.push("Номер документа может состоять только из цифр");
                }
            }
            return errorMessages;
        };
        const checkOtherDocumentsIssued = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.OTHER_DOCUMENTS_ISSUED === docs[docType]) {
                if (!(value.length >= 1 && value.length <= 12)) {
                    errorMessages.push("Длина номера документа должна состовалять от 1 до 12 символов");
                }
            }
            return errorMessages;
        };
        const checkRegistrationAuthority = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.REGISTRATION_AUTHORITY === docs[docType]) {
                if (value === "000000000") {
                    errorMessages.push(`Значение номера ${value} запрещено для ввода`);
                }
                if (!(value.length === 9)) {
                    errorMessages.push("Длина номера должна составлять 9 цифр");
                }
                const isValidNumbers = value.split("").every((val) => /\d/.test(val));
                if (!isValidNumbers) {
                    errorMessages.push("Номер документа должен состоять только из цифр");
                }
            }
            return errorMessages;
        };
        const checkIncorporation = (docType: string): string[] => {
            const errorMessages = [];
            if (value && DOCUMENTS.INCORPORATION === docs[docType]) {
                if (!(value.length >= 1 && value.length <= 40)) {
                    errorMessages.push("Длина номера документа должна составлять от 1 до 40 символов");
                }
            }
            return errorMessages;
        };
        if (["InsuredDocNumber", "InsuredPersonDocNumber"].includes(fieldName)) {
                return [...checkRuleBirthCertificate(InsuredDoc),
                        ...checkRuleOfficerCard(InsuredDoc),
                        ...checkRulePassportMinmorflot(InsuredDoc),
                        ...checkRuleMilitaryIDSoldier(InsuredDoc),
                        ...checkRuleDiplomaticPassportOFRussian(InsuredDoc),
                        ...checkRuleCertificateOfRegistration(InsuredDoc),
                        ...checkRulePassportForeignCitizen(InsuredDoc),
                        ...checkRuleResidence(InsuredDoc),
                        ...checkRefugeeCertificateINRussian(InsuredDoc),
                        ...checkTemporaryIdentityCardRussian(InsuredDoc),
                        ...checkPassportOfRussian(InsuredDoc),
                        ...checkForeignPassportOfRussian(InsuredDoc),
                        ...checkSailorPassport(InsuredDoc),
                        ...checkRuleStockOfficerMilitaryCard(InsuredDoc),
                        ...checkOtherDocumentsIssued(InsuredDoc),
                        ...checkRegistrationAuthority(InsuredDoc),
                        ...checkIncorporation(InsuredDoc)];
        }

        if (["OwnerDocNumber", "OwnerPersonDocNumber"].includes(fieldName)) {
                return [...checkRuleBirthCertificate(OwnerDoc),
                        ...checkRuleOfficerCard(OwnerDoc),
                        ...checkRulePassportMinmorflot(OwnerDoc),
                        ...checkRuleMilitaryIDSoldier(OwnerDoc),
                        ...checkRuleDiplomaticPassportOFRussian(OwnerDoc),
                        ...checkRuleCertificateOfRegistration(OwnerDoc),
                        ...checkRulePassportForeignCitizen(OwnerDoc),
                        ...checkRuleResidence(OwnerDoc),
                        ...checkRefugeeCertificateINRussian(OwnerDoc),
                        ...checkTemporaryIdentityCardRussian(OwnerDoc),
                        ...checkPassportOfRussian(OwnerDoc),
                        ...checkForeignPassportOfRussian(OwnerDoc),
                        ...checkSailorPassport(OwnerDoc),
                        ...checkRuleStockOfficerMilitaryCard(OwnerDoc),
                        ...checkOtherDocumentsIssued(OwnerDoc),
                        ...checkRegistrationAuthority(OwnerDoc),
                        ...checkIncorporation(OwnerDoc)];
        }
    }

    public OrgINN(val: string): string[] {
        if (val === "0000000000") {
            return [`${val} недопустимое значение`];
        }
    }

    public OrgName(str: string): string[] {
        if (str.length < 10) {
            return ["Наименование организации должно быть не меньше 10 символов"];
        }

        const characters = this.getMoreThanThreeCharacter(str);
        if (characters) {
            return [`Наименование организации содержит больше трех повторяющихся символов ${characters}`];
        }

        // const EXCEPTION_CHARACTERS: string[] = str.split("").filter((char) => {
        //     return !/[а-я]|[0-9]|[-/()\s№,.`']/gi.test(char);
        // });
        //
        // if (EXCEPTION_CHARACTERS.length > 0) {
        //     return [`Наименование организации содержит недопустимые символ(ы)
        //                 ${[...(new Set(EXCEPTION_CHARACTERS))].join("")}`];
        // }
    }

    public VehicleLicensePlateCustomRules(docType: string, licensePlate: string) {
        if (!licensePlate) {
            return [];
        }
        if (/иностр/gi.test(docType)) {
            const EXCEPTION_CHARACTERS = licensePlate.split("").filter((char) => !/[0-9]|[a-z]/gi.test(char));
            if (EXCEPTION_CHARACTERS.length > 0) {
                return [`Введены не допустимые символы (${EXCEPTION_CHARACTERS.join(", ")}),
                допустимы символы только английского алфавита и цифры от 0 до 9`];
            }
            return [];
        } else {
            const errorMessages = [];
            // tslint:disable-next-line:max-line-length
            const EXCEPTION_CHARACTERS = licensePlate.split("").filter((char) => !/[АВЕКМНОРСТУХ]|[0-9]|[CDT]|[\s]/gi.test(char));
            // tslint:disable-next-line:max-line-length
            const EXCEPTION_WORDS = licensePlate.split(" ").filter((word) => ["ТРАНЗИТ", "ПРИЦЕП", "ТР-Р", "ПР-П", "RUS"].includes(word));
            const EXCEPTION_SPACE_CHARACTERS = licensePlate.split("").filter((char) => /\s/.test(char));
            const EXCEPTION_CHARACTERS_LOWER_CASE = licensePlate.split("").filter((char) => {
                return /[авекмнорстух]|[cdt]/g.test(char);
            });
            if (EXCEPTION_CHARACTERS.length > 0) {
                errorMessages.push(`Гос. знак содержит недопустимые символы ${EXCEPTION_CHARACTERS}`);
            }
            if (EXCEPTION_WORDS.length > 0) {
                errorMessages.push(`Гос. знак содержит недопустимые слова ${EXCEPTION_WORDS}`);
            }
            if (EXCEPTION_CHARACTERS_LOWER_CASE.length > 0) {
                errorMessages.push("Гос. знак должен содержать только заглавные буквы");
            }
            if (EXCEPTION_SPACE_CHARACTERS.length > 0) {
                errorMessages.push("Гос. знак не должен содержать символ (пробел)");
            }
            if (EXCEPTION_WORDS.length > 0) {
                errorMessages.push(`Гос. знак не должен содержать слово ${EXCEPTION_WORDS}`);
            }
            return errorMessages;
        }
    }

    public CommonRealAddress(address: string): string[] {
        const errorMessages = [];
        // tslint:disable-next-line:max-line-length
        const exceptionCharacters = this.getExceptionCharacters(address, this.EXCEPTION_CHARACTERS_ADDRESS.split(""));
        const characters = this.getMoreThanThreeCharacter(address);
        // tslint:disable-next-line:max-line-length
        const isNotValid = (str: string) => str.split("").some((char) => /[a-z]|[~\\!@#$%^:&?*=+]/gi.test(char));

        if (isNotValid(address) && exceptionCharacters.length === 0) {
            errorMessages.push(`Адресс может содержать символы только русского алфавита`);
        }
        if (exceptionCharacters.length > 0) {
            errorMessages.push(`
            ${exceptionCharacters.length > 1 ? "Адресс содержит недопустимые символы " :
            "Адресс содержит недопустимый символ "}
            ${exceptionCharacters}`);
        }
        if (characters) {
            errorMessages.push(`Адрес содержит три повторяющихся символа ${characters}`);
        }

        return errorMessages;
    }
    public Email(email: string): string[] {
        const errorMessages = [];
        if (!email) {
            return null;
        }
        // tslint:disable-next-line:no-shadowed-variable
        const isValidAlphabet = (email: string): boolean => {
            // tslint:disable-next-line:max-line-length
            return email.split("").every((char) => /[!#$%&'*+-/=?^_`{|}~@.]|[a-z]|[а-я]|[0-9]/gi.test(char));
        };
        // tslint:disable-next-line:max-line-length
        const exceptionCharacters = this.getExceptionCharacters(email, this.EXCEPTION_CHARACTERS_EMAIL.split(""));
        if (email.charAt(0) === "." || email.charAt(email.length - 1) === ".") {
            errorMessages.push("Символ точка (.) не может быть в начале и в конце строки");
        }
        if (!this.isValidEmail(email)) {
            errorMessages.push("Не верный формат Email");
        }
        if (exceptionCharacters.length > 0) {
            errorMessages.push(`Email содержит
            ${exceptionCharacters.length > 1 ? "недопустимые символы " : "недопустимый символ "}
            ${exceptionCharacters}`);
        }
        if (!isValidAlphabet(email) && exceptionCharacters.length === 0) {
            errorMessages.push("Допустимы символы из русского и английского алфавита");
        }
        return errorMessages;
    }
    public LastName(name: string): string[] {
        if (!name) {
            return [];
        }
        const errorMessages = [];
        const characters = this.getMoreThanThreeCharacter(name);
        // tslint:disable-next-line:max-line-length
        const exceptionCharacters = this.getExceptionCharacters(name, this.EXCEPTION_CHARACTERS_INSURANT_NAME.split(""));
        if (characters) {
            errorMessages.push(`Фамилия
            содержит более трех повторяющихся символов ${characters}`);
        }
        if (!/^[ '`—–а-яё-]+$/gi.test(name) && exceptionCharacters.length === 0) {
            errorMessages.push("Фамилия должна состоять из символов русского алфавита");
        }
        if (exceptionCharacters.length > 0) {
            errorMessages.push(`Поле фамилия содержит
            ${exceptionCharacters.length > 1 ? "недопустимые символы " : "недопустимый символ "}
            ${exceptionCharacters}`);
        }
        return errorMessages;
    }
    public FirstName(name: string): string[] {
        if (!name) {
            return [];
        }
        const errorMessages = [];
        const characters = this.getMoreThanThreeCharacter(name);
        // tslint:disable-next-line:max-line-length
        const exceptionCharacters = this.getExceptionCharacters(name, this.EXCEPTION_CHARACTERS_INSURANT_NAME.split(""));
        if (characters) {
            errorMessages.push(`Имя содержит более трех повторяющихся символов ${characters}`);
        }
        if (!/^[ '`—–а-яё-]+$/gi.test(name) && exceptionCharacters.length === 0) {
            errorMessages.push("Поле имя должно состоять из символов русского алфавита");
        }
        if (exceptionCharacters.length > 0) {
            errorMessages.push(`Поле имя содержит
            ${exceptionCharacters.length > 1 ? "недопустимые символы " : "недопустимый символ "}
            ${exceptionCharacters}`);
        }
        return errorMessages;
    }
    public MiddleName(name: string): string[] {
        if (!name) {
            return [];
        }
        const errorMessages = [];
        const characters = this.getMoreThanThreeCharacter(name);
        if (characters) {
            errorMessages.push(`Отчество содержит более трех пвоторяющихся символов ${characters}`);
        }
        return errorMessages;
    }
    public VehicleDocChassisNumber(num: string) {
        const errorMessages = [];
        const isValid = (num: string) => num.split("").every((val) => /[0-9a-zA-Z]/.test(val));
        if (!isValid(num)) {
            errorMessages.push("Допустимы символы только из английского алфавита");
        }
        return errorMessages;
    }
    public VehicleDocBodyNumber(num: string) {
        const errorMessages = [];
        const isValid = (num: string) => num.split("").every((val) => /[0-9a-zA-Z]/.test(val));
        if (!isValid(num)) {
            errorMessages.push("Допустимы символы только из английского алфавита");
        }
        return errorMessages;
    }
    private getMoreThanThreeCharacter(str: string): boolean | string {
        for (let i = 0, j = 1, k = 2, l = 3; i < str.length; i++) {
            if (str[i] === str[j] && str[i] === str[k] && str[i] === str[l]) {
                return str[i] + str[j] + str[k] + str[l];
            }
            j++; k++; l++;
        }
        return false;
    }
    private getExceptionCharacters(str: string, exceptionCharacters: string[]): string[] {
        const list = [];
        const item = str.split("");
        exceptionCharacters.forEach((char) => {
            if (item.includes(char)) {
                list.push(char);
            }
        });
        return list;
    }
    private isValidEmail(email: string) {
        return /.@.+\../gi.test(email);
    }
}
