import { NgComponentController } from "infrastructure/NgController";
import previousPolicyFinderServiceModule from "infrastructure/services/previousPolicyFinder.service";
import { UgskPreviousContractInfoController } from "./ugskPreviousContractInfo.component";
import template from "./ugskPreviousContract.component.html";
import templatePreviousContractInfo from "./ugskPreviousContractInfo.component.html";

export default angular.module("ugsk.components.ugsk-previous-contract", [
    previousPolicyFinderServiceModule,
]).component("ugskPreviousContract", {
    bindings: {
        //  @todo: remove binding
        contract: "<",
        defaultPolicySerial: "<",
        disabled: "<ngDisabled",
        onImportPreviousContractRequested: "&",
        onPreviousContractFound: "&",
        onRemovePreviousContractRequested: "&",
        policyNumber: "<",
        policySerial: "<",
        previousContractSource: "@",
        productCode: "@",
        serials: "<",
    },

    controller: class extends NgComponentController {
        find() {
            const [previousPolicyFinder] = this.di(["previousPolicyFinder"]);
            this.stateClass = "fa-spinner fa-spin";
            this.state = "searching";
            previousPolicyFinder.find({
                policyNumber: this.policyNumber,
                policySerial: this.policySerial,
            }, {
                productCode: this.productCode,
                source: this.previousContractSource,
            }).then(previousContract => {
                this.state = "success";
                if (Object.keys(previousContract).length === 1) {
                    //  only `Id` in previous contract
                    this.stateClass = "fa-external-link";
                    previousContract.PolicySerial = this.policySerial; // eslint-disable-line
                    previousContract.PolicyNumber = this.policyNumber; // eslint-disable-line
                } else {
                    this.stateClass = "fa-info";
                }
                this.previousContractData = previousContract;
                //  @callback
                this.onPreviousContractFound({ previousContract });
            }).catch(() => {
                this.state = "error";
                this.stateClass = "fa-times";
                this.previousContractData = null;
            });
        }

        isFindButtonActive() {
            return this.policySerial && this.policyNumber && this.policyNumber.length >= 6 && this.policyNumber.length <= 8;
        }

        showContractInfo() {
            const [$uibModal] = this.di(["$uibModal"]);
            $uibModal.open({
                controller: UgskPreviousContractInfoController,
                controllerAs: "vm",
                resolve: {
                    previousContractData: this.previousContractData,
                    productCode: () => this.productCode,
                },
                template: templatePreviousContractInfo,
            }).result.then(() => {
                const { previousContractData } = this;
                //  @callback
                this.onImportPreviousContractRequested({ previousContractData });
                this.policySerial = previousContractData.PreviousPolicySerial;
            }).catch(() => {

            });
        }

        /**
         * @description Удаляем сведения о предыдущем контракте.
         * !!! Момент: удаляются только номера предыдущего договора
         * (номер, серия полиса, id договора, id договора ЦССУ). Остальные поля не трогаются.
         */
        removePreviousContract() {
            this.policyNumber = null;
            this.policySerial = this.defaultPolicySerial;
            this.onNumberChanged();
            //  @callback
            this.onRemovePreviousContractRequested();
        }

        onNumberChanged() {
            this.previousContractData = null;
            this.state = null;
        }

        onKeyPressed(event) {
            if (event.type === "keypress" && event.keyCode === 13 && this.isFindButtonActive()) {
                this.find();
            }
        }

        isAlreadyCopied() {
            return this.contract.PreviousContractId || this.contract.CSIAPreviousContractId;
        }

        clear() {
            this.policyNumber = null;
            this.state = null;
        }

        onInit() {
            this.state = null;
            this.stateClass = null;
            this.previousContractData = null;

            //  @todo Не делать find до тех пор пока это действительно не необходимо (информационное окно)
            if (!this.disabled && (this.policySerial || this.policyNumber)) {
                if (!(this.productCode === "uAuto" && this.contract.PreviousContractId)) {
                    this.find();
                } else {
                    this.state = "success";
                    this.previousContractData = {
                        PolicySerial: this.policySerial,
                        PolicyNumber: this.policyNumber,
                        Id: this.contract.PreviousContractId,
                    };
                }
            }
            this.policySerial = this.policySerial || this.defaultPolicySerial;
        }

        openPreviousContract() {
            const [ $state, $window ] = this.di([ "$state", "$window" ]);
            const url = $state.href(`app.${this.productCode}.index`, {
                id: this.contract.PreviousContractId
            }, { inherit: false });

            $window.open(url, "_blank");
        }

        get policySerial() {
            return this.privatePolicySerial;
        }
        set policySerial(val) {
            if (val) {
                this.privatePolicySerial = val;
            }
        }
    },
    controllerAs: "vm",
    template,
}).name;
