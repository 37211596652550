import angular from "angular";
import { Contract } from "domain/classes/contract.class";
import UgskPhone from "domain/classes/ugsk-phone.class";
import ProlongableIflContractMix from "domain/mixes/ProlongableIflContract.mix";
import { IFLRepository } from "infrastructure/app.factory-ts";
import IProlongableContract from "infrastructure/interfaces/IProlongableContract";
import {
    ConstructorHomeAdditionalConstructionDTO,
} from "infrastructure/interfaces/WebApi/ConstructorHomeAdditionalConstructionDTO";
import { ConstructorHomeContractDTO } from "infrastructure/interfaces/WebApi/ConstructorHomeContractDTO";
import { IFactPaymentDTO } from "infrastructure/interfaces/WebApi/IFactPaymentDTO";
import PaymentDTO from "infrastructure/interfaces/WebApi/PaymentDTO";
import { PhoneNumberDTO } from "infrastructure/interfaces/WebApi/PhoneNumberDTO";
import Repository, { IRepositoryItem } from "infrastructure/Repository.class";
import { Day, Decimal, Guid, Int } from "infrastructure/types";
import { Uuid } from "lib/uuid";

export class AdditionalConstruction implements ConstructorHomeAdditionalConstructionDTO {
    public Id: Int;
    public Guid: Guid = Uuid.raw();
    public ConstructionTypeId: Int = null; // Тип постройки
    public WallMaterial = null; // Материал стен
    public WallMaterialId: Int = null;
    public Square = null; // Площадь
    public ConstructionYear = null; // Год строения
    public OverhaulYear = null; // Год. кап. ремонта
    public Name: string = null; // Название строения
    public AdditionalConstructionProductRiskAmountId: Int = null; // Страховая сумма
    public AdditionalConstructionProductRiskPremium: number = null; // Премия
    // Почему-то id не используется
    constructor(id: Int, private repository: Repository) {}
    // TODO Нигде не нашел использование этой функции
    public getName() {
        let name = "";
        const typeId = this.ConstructionTypeId;
        angular.forEach((this.repository as any).ConstructionType.Collection, (element) => {
            if (element.ConstructionTypeId === typeId) {
                name = element.ConstructionType;
            }
        });
        return name;
    }
}

export interface IInsuranceTerm {
    InsuranceTerm: string;
    InsuranceTermId: Int;
    InsuranceTermValue: Int;
}

export class ContructorHomeRepository extends IFLRepository {
    public AdditionalConstructionWallMaterial: IRepositoryItem<AdditionalConstruction>;
    public InsuranceTerm: IRepositoryItem<IInsuranceTerm>;
    public PaymentDocumentSerial: IRepositoryItem<{
        PaymentDocumentSerial: string;
        PaymentDocumentSerialId: Int;
    }>;
    public AdditionalConstructionType: IRepositoryItem<{
        ConstructionType: string;
        ConstructionTypeMeasureUnit: string;
        ConstructionTypeId: Int;
    }>;
    public AdditionalConstructionProductRiskAmount: IRepositoryItem<{
        AdditionalConstructionProductRiskAmount: Int;
        AdditionalConstructionProductRiskAmountPhotoNeeded: boolean;
        AdditionalConstructionProductRiskAmountId: Int;
    }>;
    public ConstructorHomeBonusMalus: IRepositoryItem<{
        ConstructorHomeBonusMalusName: string;
        ConstructorHomeBonusMalusDescription: string;
        ConstructorHomeBonusMalusId: Int;
    }>;
    public PreviousPolicyNumber: IRepositoryItem<any>; // TODO Не нашел что же должно приходить в ФР
}

export class ContructorHomeContract extends ProlongableIflContractMix(Contract) implements IProlongableContract, ConstructorHomeContractDTO {
    public AdditionalConstructions: AdditionalConstruction[] = [];
    public PropertyWallMaterialId: Int;
    public PropertyInteriorWallMaterialId: Int;
    public PropertyInteriorCeilingMaterialId: Int;
    public PropertyInteriorFloorMaterialId: Int;
    public PropertyExteriorMaterialId: Int;
    public PropertyFoundationMaterialId: Int;
    public PropertyRoofMaterialId: Int;
    public PropertyConstructionYear: Int;
    public PropertyMansardIsExist: boolean;
    public PropertySquare: Decimal;
    public PropertyFloorCount: Int;
    public PropertyAddressUserDefinedAddress: string;
    public PropertyAddressFiasId: string;
    public PropertyAddressKladrId: string;
    public InsuredObjectAddressHasNotFiasInfo: boolean;
    public StructuralElementsProductRiskAmountId: Int;
    public StructuralElementsProductRiskPremium: Decimal;
    public InteriorAndEngineeringEquipmentProductRiskAmountId: Int;
    public InteriorAndEngineeringEquipmentProductRiskPremium: Decimal;
    public MovablePropertyProductRiskAmountId: Int;
    public MovablePropertyProductRiskPremium: Decimal;
    public StructuralElementsAndInteriorAndEngineeringEquipmentProductRiskAmountId: Int;
    public StructuralElementsAndInteriorAndEngineeringEquipmentProductRiskPremium: Decimal;
    public AdditionalConstructionTypeId: Int;
    public AdditionalConstructionBuildingYear: Int;
    public Id: Int;
    public Guid: Guid;
    public ContractStatusId: Int;
    public ContractStatusName: string;
    public PolicyPrefix: string;
    public PolicySerialId: Int;
    public PolicyNumber: string;
    public PreviousPolicySerial: string;
    public PreviousContractNumber: string;
    public PreviousContractId: Int;
    public CSIAPreviousContractId: Int;
    public SigningDate: Day;
    public ContractFrom: Day;
    public ContractTo: Day;
    public FilialId: Int;
    public InsuranceTermId: Int;
    public InsurancePremium: Decimal;
    public Discount: Decimal;
    public ConstructorHomeBonusMalusId: Int;
    public InsuredContractorTypeId: Int;
    public InsuredDocTypeId: Int;
    public InsuredDocDateGiven: Day;
    public InsuredDocWhomGiven: string;
    public InsuredDocOrgCode: string;
    public InsuredBirthPlace: string;
    public InsuredDocSerial: string;
    public InsuredDocNumber: string;
    public InsuredLastName: string;
    public InsuredFirstName: string;
    public InsuredMiddleName: string;
    public InsuredBirthday: Day;
    public InsuredAddressUserDefinedAddress: string;
    public InsuredAddressFiasId: string;
    public InsuredAddressKladrId: string;
    public InsuredPersonAddressHasNotFiasInfo: boolean;
    public InsuredPhones: PhoneNumberDTO[];
    public InsuredPhoneVerificationId: Guid;
    public InsuredId: Int;
    public InsuredCommonEmail: string;
    public LapsedPaymentId: Int;
    public PaymentKindId: Int;
    public PaymentDocumentSerialId: Int;
    public PaymentDocumentDatePay: Day;
    public PaymentDocumentIsA7Required: boolean;
    public IsReceiptRequired: boolean;
    public IsNumberRequired: boolean;
    public ResidenceTypeId: Int;
    public PlannedPayments: PaymentDTO[];
    public FactPayments: IFactPaymentDTO[];

    public getRepository(): ContructorHomeRepository {
        return (super.getRepository() as ContructorHomeRepository);
    }
}
