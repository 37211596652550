import angular from "angular";

export function UibTabDockingDirective(): ng.IDirective {
    "ngInject";

    return {
        link: (
            scope: ng.IScope,
            element: ng.IAugmentedJQuery,
        ) => {
            angular.element(document).ready(() => {
                const headerElement = angular.element("header");
                const tabHeadElement = element;
                const uibTabElement = element.find(".nav.nav-pills");
                const uibTabContentElement = element.find(".tab-content");
                if (!tabHeadElement || !uibTabElement.length || !uibTabContentElement.length) {
                    return;
                }

                const updateDocking = () => {
                    const headerRect = headerElement[0].getBoundingClientRect();
                    const offsetTabContent = uibTabContentElement[0].getBoundingClientRect();

                    if (offsetTabContent.top >= headerRect.height + uibTabElement.height()) {
                        tabHeadElement.removeClass("uib-tab-fixed");
                    } else {
                        tabHeadElement.addClass("uib-tab-fixed");
                    }
                    uibTabElement.css({
                        left: offsetTabContent.left,
                        top: headerRect.height,
                        width: uibTabContentElement.width(),
                    });
                };
                angular.element(window).bind("resize", () => updateDocking());
                angular.element(document).bind("scroll", () => updateDocking());
            });
        },
        restrict: "A",
    };
}
