
import { Transition } from "@uirouter/core";
import { BaseToolbarController } from "application/baseProduct.toolbar.controller";
import { Helpers } from "infrastructure/app.helpers";
import { TContractResourceFactoryFn } from "infrastructure/app.resource";
const PrintOptions = [
    "policy",
    "policyWithoutA7",
    {
        title: "Печать пустого бланка, постоянное место жительства",
        action() {
            this.printEmptyContractContstructorHome("Permanent");
        },
        icon: "fa-download",
    },
    {
        title: "Печать пустого бланка, непостоянное место жительства",
        action() {
            this.printEmptyContractContstructorHome("Temporary");
        },
        icon: "fa-download",
    },
    "worksheet",
    "paymentAccountAll",
    "paymentAccountFirst",
    "paymentAccountSecond",
    "freeFormReceipt",
];
export class ConstructorHomeToolbarController extends BaseToolbarController {
    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
        {
            viewType = "form",
        },
    ) {
        super($injector, $transition, {
            viewType,
            printOptions: PrintOptions,
            crossOptions: [
                "snugHome",
                "express",
                "myChoice",
                "constructorHome",
                "assuranceApartment",
                "assuranceHome",
            ],
            extraOptions: [
                "manual",
                "integrationInfo",
                "annul",
            ],
        });
    }
    public printEmptyContractContstructorHome(residenceType) {
        const contractResourceFactory = this.di<TContractResourceFactoryFn>("contractResourceFactory");
        const helpers = this.di<Helpers>("helpers");
        const contractResource = contractResourceFactory(this.product.code);

        return contractResource.getPrintable({
            docType: `PolicyHandwritterFor${residenceType}ResidenceTypeConstructorHome`,
            id: 0,
        }).$promise.then((blob) => { helpers.openUrlInIframe(blob.url); });
    }
}
