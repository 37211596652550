import "ion-rangeslider";
import $ from "jquery";
import { NgComponentController } from "infrastructure/NgController";

class UgskSliderController extends NgComponentController {
    constructor($injector, $scope, $element, $attrs) {
        super($injector, $scope, $element, $attrs);
        [
            this.$timeout,
        ] = this.di([
            "$timeout",
        ]);
        this.slider = $($element).find("div");
    }
    onInit() {
        const {
            $element,
            $scope,
            slider,
        } = this;

        if (!this.config) {
            this.config = {};
        }

        if (this.baseConfig) {
            this.config = angular.copy(Object.assign(this.baseConfig, this.config));
        }

        if (this.config.from > this.config.max) {
            this.config.from = this.config.max;
        }
        if (this.config.from < this.config.min) {
            this.config.from = this.config.min;
        }

        this.config.onFinish = data => {
            this.from = data.from;
        };

        this.config.onUpdate = data => {
            if (this.from > data.max) {
                this.from = data.max;
            }
            if (this.from < data.min) {
                this.from = data.min;
            }

            if (angular.isFunction(this.onChange)) {
                this.onChange();
            }
        };

        this.config.onStart = data => {
            this.from = data.from;
        };

        slider.ionRangeSlider(this.config);

        this.componentIsReady = true;

        const fieldset = $($element).closest("fieldset");
        if (fieldset) {
            $scope.$watch(() => fieldset.attr("disabled"), () => {
                if (fieldset.attr("disabled") === "disabled") {
                    this.update({ disable: true });
                }
            });
        }
    }
    get from() {
        return this.config.from;
    }
    set from(from) {
        this.update({ from });
    }
    get to() {
        return this.config.to;
    }
    set to(to) {
        this.update({ to });
    }
    get min() {
        return this.config.min;
    }
    set min(min) {
        this.update({ min });
    }
    get max() {
        return this.config.max;
    }
    set max(max) {
        this.update({ max });
    }
    get fromMin() {
        return this.config.from_min;
    }
    set fromMin(fromMin) {
        this.update({ from_min: fromMin });
    }
    get fromMax() {
        return this.config.from_max;
    }
    set fromMax(fromMax) {
        this.update({ from_max: fromMax });
    }
    get grid() {
        return this.config.grid;
    }
    set grid(grid) {
        this.update({ grid: parseInt(grid, 10) });
    }
    set gridNum(num) {
        this.config.grid_num = num;
    }
    set disable(disable) {
        this.update({ disable });
    }
    update(newConfig) {
        this.config = angular.merge(this.config || {}, newConfig);
        if (this.componentIsReady) {
            this.$timeout(() => {
                this.slider.data("ionRangeSlider").update(this.config);
            });
        }
    }

    onDestroy() {
        const sl = this.slider.data("ionRangeSlider");
        if (sl) {
            sl.destroy();
        }
    }
}

angular.module("ugsk.components.slider", []).component("ugskSlider", {
    bindings: {
        /**
         * TODO: вынести свойства задаваемые через baseConfig в биндинги
         * Слайдеры есть в УРК (несколько штук). УРД. Экспресс, ВЗР
         */
        baseConfig: "<",
        disable: "<ngDisabled",
        from: "=ngModel",
        fromMax: "<",
        fromMin: "<",
        gridNum: "<",
        max: "<?",
        min: "<?",
        onChange: "&ngChange",
    },
    controller: UgskSliderController,
    replace: true,
    template: "<div></div>",
});
