import angular from "angular";
import ugskOwnerModule from "application/components/ugsk-owner/ugskOwner.component";
import { OsagoContract } from "application/osago/classes";
import { NgComponentController } from "infrastructure/NgController";
import { Uuid } from "lib/uuid";
import moment from "moment";
import { OsagoInsurantProxy, OsagoOwnerProxy } from "../../osago.factory";

/**
 * ugskOsagoOwner - Собственник в ОСАГО
 */
class UgskOsagoOwner extends NgComponentController {
    public contract: OsagoContract;
    public owner: OsagoOwnerProxy;
    public insurant: OsagoInsurantProxy;
    public formIsLocked: boolean;
    public isNotResidentDisable: boolean;
    public minDate: string;
    public maxDate: string;
    public minBirthDay: string;
    public onOwnerTypeChanged: () => void;

    public onInit(): void {
        this.owner = new OsagoOwnerProxy(this.contract);
        this.insurant = new OsagoInsurantProxy(this.contract);
        this.minBirthDay = moment().subtract(120, "years").format("YYYY-MM-DD");

        this.$watch(() => this.owner.contractorType, (oldVal, newVal) => {
            if (!this.formIsLocked && oldVal !== newVal) {
                this.onOwnerTypeChanged();
            }
        });

        this.$watch(() => this.insurant.toString(), () => {
            if (this.formIsLocked) {
                return;
            }
            if (this.ownerIsInsurant) {
                this.copyOwnerFromInsurant();
            }
        });
        this.$watch(() => this.owner.documentType, () => {
            if (!["Паспорт иностранного гражданина", "Водительское удостоверение иностранного государства"]
                .includes(this.owner.documentType)
            && this.owner.contractorType === "физическое лицо") {
                this.isNotResidentDisable = true;
                this.owner.country = "Россия";
                this.owner.isNotResident = false;
            } else {
                this.isNotResidentDisable = false;
            }
        });
        this.minDate = moment().subtract(100, "years").format("YYYY-MM-DD");
        this.maxDate = moment().format("YYYY-MM-DD");
    }

    public copyOwnerFromInsurant(): void {
        this.owner.copyFrom(this.insurant);
    }

    get ownerIsInsurant(): boolean {
        return this.owner.guid === this.insurant.guid;
    }

    get ownerIsLegal(): boolean {
        return this.owner.isLegalEntity();
    }

    get insurantIsLegal(): boolean {
        return this.insurant.isLegalEntity();
    }

    set ownerIsInsurant(val: boolean) {
        if (val) {
            this.copyOwnerFromInsurant();
        } else {
            this.insurant.guid = Uuid.raw();
        }
    }

    public copyInsurantToOwner(): void {
        const insurant = new OsagoInsurantProxy(this.contract);
        insurant.copyTo(this.owner);
    }

    public copyFirstDriverToOwner(): void {
        const firstDriver = this.contract.Drivers[0];
        Object.assign(this.owner, firstDriver);
    }

    public isAccountingDateVisible(): boolean {
        return this.owner.isLegalEntity()
            && this.owner.documentType === "Свидетельство о постановке на учет в налоговом органе";
    }

    public onDocumentTypeChanged(): void {
        if (this.owner.documentType !== "Свидетельство о постановке на учет в налоговом органе") {
            this.owner.accountingDate = null;
        }
    }
}

export default angular.module("app.osago.components.owner", [
    ugskOwnerModule,
]).component("ugskOsagoOwner", {
    bindings: {
        contract: "=",
        formIsLocked: "<",
        onOwnerTypeChanged: "&",
        repository: "<",
        addressMaxLevel: "<",
    },
    controller: UgskOsagoOwner,
    controllerAs: "vm",
    template: require("./ugskOsagoOwner.component.html"),
})
.name;
