import { DateTimeOffset } from "infrastructure/types";

/**
 * @see UGSK.K3.Service.DTO/Shared/VehicleDocumentDTO.cs
 */
export default class VehicleDocument {
    public Id: number = 0;
    public VehicleDocumentType: string = "";
    public VehicleDocSerial: string = "";
    public VehicleDocNumber: string = "";
    public VehicleDocIssuredDate: DateTimeOffset = null;

    static get validators(): any[] {
        return [{
            description: "Проверка наличия вида у каждого документа",
            message: "Не указан вид документа ТС",
            code: "VehicleVehicleDocuments",
            isValid: document => {
                return document.VehicleDocumentType.length;
            }
        }];
    }
}
