import angular from "angular";
import ugskDateTimePickerComponent from "application/components/ugsk-date-time-picker/ugskDateTimePicker.component";
import { Contract as ContractClass } from "domain/classes/contract.class";
import { ITouchSpinConfig } from "infrastructure/interfaces/ITouchSpinConfig";
import { NgComponentController } from "infrastructure/NgController";
import { Int } from "infrastructure/types";
import moment from "moment";
import template from "./ugskContractParams.component.html";

interface IInsuranceTerm {
    InsuranceTerm: string;
    InsuranceTermValue: Int;
    InsuranceTermId: Int;
}

class ContractParamsController extends NgComponentController {
    public contract: ContractClass;
    public insuranceTerms: IInsuranceTerm[];
    public contractFromDateMinDate: Date;
    public contractFromDateMaxDate: Date;
    public insuranceTermConfig: ITouchSpinConfig;

    public onInit(): void {
        this.contractFromDateMinDate = moment(this.contract.SigningDate).toDate();
        this.contractFromDateMaxDate = moment(this.contract.SigningDate).add(6, "months").toDate();
        this.insuranceTermConfig = {
            boostat: 5,
            buttondown_class: "btn btn-white",
            buttonup_class: "btn btn-white",
            max: 30,
            maxboostedstep: 10,
            min: 1,
            step: 1,
        };

        if (!this.contract.ContractStatusId) {
            this.contract.SigningDate = moment().toDate().toString();
            this.contract.ContractFrom = moment(this.contract.SigningDate).toDate().toString();
            this.contract.ContractTo = moment(this.contract.ContractFrom)
                .add(this.getInsuranceTermValue() - 1, "days")
                .format("YYYY-MM-DDT23:59:59");
        }

        this.$scope.$watchGroup(["vm.contract.InsuranceTermId", "vm.contract.ContractFrom"], () => {
            this.contract.ContractTo = moment(this.contract.ContractFrom)
                .add(this.getInsuranceTermValue() - 1, "days")
                .endOf("day")
                .format();
        });
    }

    public getInsuranceTermValue(): number | undefined {
        const term = this.insuranceTerms.find((item) => item.InsuranceTermId === this.contract.InsuranceTermId);
        return (term) ? term.InsuranceTermValue : undefined;
    }

    public getContractFromMinDate(): Date {
        return moment(this.contract.SigningDate).toDate();
    }
}

export default angular.module("app.boxAccident.shortTermAccident.contract-params", [ugskDateTimePickerComponent])
.component("ugskContractParams", {
    bindings: {
        contract: "=",
        insuranceTerms: "<",
        isFormLocked: "<",
        serialNumbers: "<",
    },
    controller: ContractParamsController,
    controllerAs: "vm",
    template,
}).name;
