import { IModalServiceInstance } from "angular-ui-bootstrap";
import { OsagoContract } from "../classes";

export class ForcedCalculationController {
    public Contract: OsagoContract;
    public close: (reason?: any) => void;
    public calc: (result?: any) => void;
    static get $inject() {
        return ["$uibModalInstance", "contract"];
    }

    constructor(
        private $uibModalInstance: IModalServiceInstance,
        private contract: OsagoContract,
    ) { }
    public $onInit() {
        this.Contract = this.contract;
        this.close = this.$uibModalInstance.dismiss;
        this.calc = this.$uibModalInstance.close;
    }
}
