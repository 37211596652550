import { Transition } from "@uirouter/core";
import { ExpressToolbarController } from "../express-toolbar.controller";

export class ExpressJournalToolbarController extends ExpressToolbarController {
    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
    ) {
        super($injector, $transition, {
            viewType: "journal",
        });
    }
}
