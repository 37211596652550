import { IModalServiceInstance } from "angular-ui-bootstrap";
import changeEmailManual from "./instructionEmailChange.pdf";

export class ChangeEmailModalController {
    static get $inject() {
        return ["$uibModalInstance"];
    }
    constructor(private $uibModalInstance: IModalServiceInstance) { /**  */ }

    public close(): void {
        this.$uibModalInstance.close();
    }

    public openInstruction(): void {
        this.$uibModalInstance.close();
        window.open(changeEmailManual);
    }
}
