import { Contract } from "domain/classes/contract.class";
import { ContractorProxy } from "domain/proxies/insurantPersonProxy.class";
import { BoxVHIContractDTO } from "infrastructure/interfaces/WebApi/BoxVHIContractDTO";
import { BoxVHIInsuredPersonDTO } from "infrastructure/interfaces/WebApi/BoxVHIInsuredPersonDTO";
import { PhoneNumberDTO } from "infrastructure/interfaces/WebApi/PhoneNumberDTO";
import { ProductRiskDTO } from "infrastructure/interfaces/WebApi/ProductRiskDTO";
import Repository, { IRepositoryItem } from "infrastructure/Repository.class";
import { DateTimeOffset, Day, Decimal, Guid, Int } from "infrastructure/types";
import { Uuid } from "lib/uuid";

export type BoxVHIRepositoryKeys =
    "DiscountRestriction" |
    "DaysToContractActivityFromSigningDate" |
    "InsuranceTerm" |
    "InsuredCountry" |
    "UnderwriterCoefficientRestriction" |
    "InsuranceProduct"
    ;
export class BoxVHIRepository extends Repository {
    public InsuredDocType: IRepositoryItem<{
        InsuredDocType: string;
        InsuredDocTypeId: Int;
    }>;
    public InsuredMinAge: IRepositoryItem<{}>;
    public InsuredContractorType: IRepositoryItem<{
        InsuredContractorType: string;
        InsuredContractorTypeCode: string;
        InsuredContractorTypeId: Int;
    }>;
    public InsuredPersonCountry: IRepositoryItem<{
        CountryId: Int;
        Country: string;
    }>;
    public InsuredPersonMinAge: IRepositoryItem<{}>;
    public InsuredPersonMaxAge: IRepositoryItem<{}>;
    public InsuredPersonDocType: IRepositoryItem<{}>;
    public InsuredPersonsMaxCount: IRepositoryItem<{}>;
    public InsuredPersonIsInsured: IRepositoryItem<{}>;
    public AllowablePrintFormForProduct: IRepositoryItem<{
        PrintFormCode: string;
        PrintFormName: string;
        State: Int;
    }>;
    public DiscountRestriction: IRepositoryItem<{}>;
    public DaysToContractActivityFromSigningDate: IRepositoryItem<{}>;
    public InsuranceTerm: IRepositoryItem<{
        InsuranceTermId: Int;
        InsuranceTermCode: Int;
        InsuranceTermMonthCount: Int;
    }>;
    public InsuredCountry: IRepositoryItem<{
        CountryId: Int;
        Country: string;
    }>;
    public UnderwriterCoefficientRestriction: IRepositoryItem<{
        MaxValue: Int;
        MinValue: Int;
    }>;
    public InsuranceProduct: IRepositoryItem<{
        InsuranceProduct: string;
        InsuranceProductId: Int;
    }>;
}
export class BoxVHIContract extends Contract implements BoxVHIContractDTO {
    public InsuranceTermId: Int;
    public InsuredHasNotFiasInfo: boolean;
    public InsuredAddressWithoutHomeNumber = false;
    public InsuredPersons: BoxVHIInsuredPersonDTO[];
    public UnderwriterCoefficient: number;
    public AssumedRisksOnTheContract: ProductRiskDTO[];
    public InsuranceProductId: Int;
    public InsuranceProgramId: Int;
    public InsuranceAmountVariantId: Int;
    public CoverageTerritoryId: Int;
    public ContractStatusName: string;
    public PolicySerialId: Int;
    public HologramNumber: string;
    public Discount: Decimal;
    public FilialId: Int;
    public SpecialNote: string;
    public InsuredId: Int;
    public InsuredGuid: Guid;
    public InsuredLastName: string;
    public InsuredFirstName: string;
    public InsuredMiddleName: string;
    public InsuredLastNameInInternationalDocument: string;
    public InsuredFirstNameInInternationalDocument: string;
    public InsuredMiddleNameInInternationalDocument: string;
    public InsuredGender: string;
    public InsuredBirthday: Day;
    public InsuredDocTypeId: Int;
    public InsuredDocSerial: string;
    public InsuredDocNumber: string;
    public InsuredDocDateGiven: string;
    public InsuredDocWhomGiven: string;
    public InsuredDocOrgCode: string;
    public InsuredBirthPlace: string;
    public InsuredCountryId: Int;
    public InsuredRegistrationInLocationDate: Day;
    public InsuredAddressUserDefinedAddress: string;
    public InsuredAddressFiasId: string;
    public InsuredAddressKladrId: string;
    public InsuredPhoneVerificationId: Guid;
    public InsuredEmail: string;
    public InsuredOrgName: string;
    public InsuredOrgNameInInternationalDocument: string;
    public InsuredOrgINN: string;
    public InsuredINN: string;
    public OGRN: string;
    public OGRNIP: string;
    public InsuredRegistrationDate: Day;
    public InsuredRepresentativeName: string;
    public InsuredRepresentativeJobPosition: string;
    public InsuredRepresentativeDoc: string;
    public IsReceiptRequired: boolean;
    public IsNumberRequired: boolean;
    public InsuredPhones: PhoneNumberDTO[];

    constructor(prototype) {
        super(prototype);
        this.InsuredPersons = [];
        this.UnderwriterCoefficient = 1;
        this.InsuredPhones = [];
        this.InsuranceTermId = undefined;
        this.InsuredHasNotFiasInfo = false;
    }
    public getInsurantFields() {
        if (this.isInsurantANaturalPerson() || this.isInsurantASoleProprietor()) {
            return [
                "InsuredLastName",
                "InsuredFirstName",
                "InsuredMiddleName",
            ];
        } else if (this.isInsurantALegalEntity()) {
            return ["InsuredOrgName"];
        }
        throw new Error("unknown insurant type");
    }
    public getRepository() {
        return super.getRepository() as BoxVHIRepository;
    }
    public getSubProductCode() {
        const item = this.getRepository().InsuranceProduct.Collection
            .find((product) => this.InsuranceProductId === product.InsuranceProductId);
        return (!item) ? "" : item.InsuranceProduct;
    }
}

export class AntiMiteVHIPerson {
    public Guid: Guid = Uuid.raw();
    public InsuredPersonLastName: string = null;
    public InsuredPersonFirstName: string = null;
    public InsuredPersonMiddleName: string = null;
    public SpecialNote: string = null;
    public InsuredPersonBirthday: DateTimeOffset = null;

    public constructor(birthday: DateTimeOffset) {
        this.InsuredPersonBirthday = birthday;
    }
}

export class StudentVHIPerson extends AntiMiteVHIPerson {
    public InsuredPersonCountryId: Int = null;
    public InsuredPersonDocTypeId: Int = null;
    public InsuredPersonDocNumber: string = null;
    public InsuredPersonDocDateGiven: DateTimeOffset = null;

    public constructor(birthday: DateTimeOffset, docGiven: DateTimeOffset, InsuredPersonDocTypeId: Int = null) {
        super(birthday);
        this.InsuredPersonDocTypeId = InsuredPersonDocTypeId;
        this.InsuredPersonDocDateGiven = docGiven;
    }

}

export class ImmigrantVHIPerson extends StudentVHIPerson {
    public InsuredPersonGender: string = null;
    public InsuredAddressUserDefinedAddress: string = null;
    public InsuredAddressFiasId: Int = null;
    public InsuredAddressKladrId: Int = null;
    public InsuredPersonHasNotFiasInfo: boolean = false;
    public InsuredLastNameInInternationalDocument: string = null;
    public InsuredFirstNameInInternationalDocument: string = null;
    public InsuredMiddleNameInInternationalDocument: string = null;
    public InsuredPersonDocWhomGiven: string = null;

    public constructor(birthday: DateTimeOffset, docGiven: DateTimeOffset, InsuredPersonDocTypeId: Int = null) {
        super(birthday, docGiven, InsuredPersonDocTypeId);
    }
}

/**
 * @todo убрать лишние rules
 */
export class BoxVHIInsurantProxy extends ContractorProxy {
    constructor(source: BoxVHIContract) {
        super(source);
    }
    static get proxyRules() {
        return {
            contractorType: "InsuredContractorTypeId",
            documentType: "InsuredDocTypeId",
            docSerial: "InsuredDocSerial",
            docNumber: "InsuredDocNumber",
            docWhomGiven: "InsuredDocWhomGiven",
            docDateGiven: "InsuredDocDateGiven",
            lastName: "InsuredLastName",
            firstName: "InsuredFirstName",
            middleName: "InsuredMiddleName",
            lastNameTranslit: "InsuredLastNameInInternationalDocument",
            firstNameTranslit: "InsuredFirstNameInInternationalDocument",
            middleNameTranslit: "InsuredMiddleNameInInternationalDocument",
            birthday: "InsuredBirthday",
            phones: "InsuredPhones",
            regInLocationDate: "InsuredRegistrationInLocationDate",
            email: "InsuredEmail",
            birthPlace: "InsuredBirthPlace",
            docOrgCode: "InsuredDocOrgCode",
            commonRealAddress: "InsuredAddressUserDefinedAddress",
            fiasId: "InsuredAddressFiasId",
            kladrId: "InsuredAddressKladrId",
            inn: "InsuredINN",
            orgInn: "InsuredOrgINN",
            ogrn: "OGRN",
            ogrnip: "OGRNIP",
            orgName: "InsuredOrgName",
            orgNameTranslit: "InsuredOrgNameInInternationalDocument",
            orgRegDate: "InsuredRegistrationDate",
            EGRIPRegistrationDate: "InsuredRegistrationDate",
            representativeName: "InsuredRepresentativeName",
            representativeJobPosition: "InsuredRepresentativeJobPosition",
            representativeDoc: "InsuredRepresentativeDoc",
        };
    }
}
