import { StateService } from "@uirouter/core";
import { IModalService, IModalServiceInstance } from "angular-ui-bootstrap";
import cyrillicToTranslit from "cyrillic-to-translit-js";
import { Options as IGeneratePasswordOptions} from "generate-password-browser/src/generate.js";
import { capitalizeFirstLetter } from "infrastructure/app.helpers";
import { IEmployeeResource } from "infrastructure/interfaces";
import { NgController } from "infrastructure/NgController";
import { AuthenticationService } from "infrastructure/services/authentication.service";
import { NotifyService } from "infrastructure/services/notifyService";
import { ValidationService } from "infrastructure/services/validation.service";
import { Int } from "infrastructure/types";
import picturePngUrl from "./image/input_variant.png";
import modalDialogTemplate from "./modalDialog.html";
import templatePopoverHtml from "./templatePopover.html";

const NUMBER_OF_PASSWORDS = 5;
const MIN_PASSWORD_LENGTH = 8;
const PASSWORD_GENERATOR_CONFIG: IGeneratePasswordOptions = {
    exclude: "^&_\}{[]|:\"?><\`~",
    length: MIN_PASSWORD_LENGTH,
    numbers: true,
    strict: true,
    symbols: true,
    uppercase: true,
};
interface IGeneratePassword {
    generate(options?: IGeneratePasswordOptions): string;
    generateMultiple(amount: number, options?: IGeneratePasswordOptions): string[];
}

export class SelfRegistrationController extends NgController {
    public validationMessage: string[] = [];
    public firstName: string = "";
    public middleName: string = "";
    public lastName: string = "";
    public translitWords: string = "";
    public wordSplits: string[] = [];
    public selectedPasswordIndex: Int = 0;
    public isShowPasswordList: boolean = false;
    public passwordsCollection: string[];
    public isVisiblePassword: boolean = false;
    public password: string | null = null;
    public name: string;
    public login: string | null;
    public mail: string;
    public phone: string;
    public confirmPassword: string | null = null;
    public $compile: ng.ICompileService;
    public templatePopover: string = "templatePopover.html";
    public ngModelOptions: angular.INgModelOptions;
    public maskOptions = {
        maskDefinitions: {
            "9": undefined,
            "?": /\d/,
        },
    };
    private generatePasswordModule: IGeneratePassword;

    public $onInit(): void {
        this.ngModelOptions = this.di<angular.INgModelOptions>("ngModelOptions");
        this.$compile = this.di<ng.ICompileService>("$compile");

        import(/* webpackChunkName: "generatePassword" */ "generate-password-browser/src/generate.js")
            .then((generatePasswordModule: IGeneratePassword) => {
                this.generatePasswordModule = generatePasswordModule;
                this.passwordsCollection = this.generatePasswordModule
                    .generateMultiple(NUMBER_OF_PASSWORDS, PASSWORD_GENERATOR_CONFIG);
            });
        this.$compile(templatePopoverHtml)(this._$scope);
        this.templatePopover = "templatePopover.html";

    }

    public showPassword(): void {
        this.isVisiblePassword = !this.isVisiblePassword;
    }

    public generatePasswordsList(): void {
        if (this.passwordsCollection) {
            this.selectedPasswordIndex = 0;
            this.passwordsCollection = this.generatePasswordModule
                .generateMultiple(NUMBER_OF_PASSWORDS, PASSWORD_GENERATOR_CONFIG);
        }
    }

    public resetSelectedPasswordStatus(): void {
        this.selectedPasswordIndex = 0;
        this.confirmPassword = null;
    }

    public showPasswordList(): void {
        this.isShowPasswordList = true;
    }

    public setPassword(password: string, index: number): void {
        this.password = password;
        this.selectedPasswordIndex = index;
        this.confirmPassword = password;
        this.isShowPasswordList = false;
    }

    public createLoginFromTranslit(): void {
        this.translitWords = new cyrillicToTranslit().transform(this.name, ""),
        this.wordSplits = this.translitWords.match(/([A-Z][a-z]*)(?:,\s*)?/gi);

        if (this.wordSplits && this.wordSplits[0]) {
            this.firstName = capitalizeFirstLetter(this.wordSplits[0]);
        } else {
            this.firstName = "";
        }
        if (this.wordSplits && this.wordSplits[1]) {
            this.middleName = this.wordSplits[1].charAt(0).toUpperCase();
        } else {
            this.middleName = "";
        }
        if (this.wordSplits && this.wordSplits[2]) {
            this.lastName = this.wordSplits[2].charAt(0).toUpperCase();
        } else {
            this.lastName = "";
        }

        if (this.firstName || this.middleName || this.lastName) {
            this.login = this.firstName + this.middleName + this.lastName;
        } else {
            this.login = null;
        }
    }

    public openModalDialog(): void {
        const authenticationService = this.di<AuthenticationService>("authenticationService");
        const $state = this.di<StateService>("$state");
        const $uibModal = this.di<IModalService>("$uibModal");

        $uibModal.open({
            backdrop: "static",
            controller: class {
                public static get $inject() {
                    return ["$uibModalInstance"];
                }
                public pictureUrl = picturePngUrl;
                constructor(
                    private $uibModalInstance: IModalServiceInstance,
                ) { /** */}
                public close(): void {
                    this.$uibModalInstance.close();
                }
            },
            controllerAs: "vm",
            keyboard: false,
            size: "xl",
            template: modalDialogTemplate,
        }).result.then(() => {
            authenticationService.logout();
            $state.go("login");
        }).catch((e) => { /** */ });
    }

    public doRegister(): void {
        const validationService = this.di<ValidationService>("validationService");
        const employeeResource = this.di<IEmployeeResource>("employeeResource");
        const notifyService = this.di<NotifyService>("notifyService");

        // @validation
        if (!this.name) {
            validationService.addError("name", ["Не заполнено ФИО пользователя"]);
        }
        if (!this.login) {
            validationService.addError("login", ["Не заполнено поле логин"]);
        }
        if (!this.password) {
            validationService.addError("password", ["Не заполнено поле пароль"]);
        }
        if (!this.confirmPassword) {
            validationService.addError("confirmPassword", ["Не заполнено поле подтверждения пароля"]);
        }
        if (!this.mail) {
            validationService.addError("mail", ["Не указан адрес электронной почты"]);
        }

        if (validationService.getAllMessages().length !== 0) {
            notifyService.errorMessage("Ошибка", validationService.getAllMessages());
            return;
        }

        if (this.confirmPassword !== this.password) {
            validationService.addError("confirmPassword", ["Пароли не совпадают"]);
            return;
        }
        const data = {
            ClientUri: location.origin + location.pathname,
            Email: this.mail,
            Login: this.login,
            Name: this.name,
            Password: this.password,
            Phone: this.phone,
        };
        employeeResource.saveSelfRegistrationData(data).$promise.then(() => {
            this.openModalDialog();
        }).catch((e) => {
            validationService.clear();
        });
    }
}
