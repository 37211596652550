import { Transition } from "@uirouter/core";
import { BaseToolbarController } from "application/baseProduct.toolbar.controller";
import IBaseViewParams from "infrastructure/interfaces/IBaseViewParams";

const PrintOptions = [
    "policy",
    "policyWithoutA7",
    "application",
    "worksheet",
    "emptyApplication",
    "paymentAccountAll",
    "paymentAccountFirst",
    "paymentAccountSecond",
    "freeFormReceipt",
];

export class AssuranceApartmentToolbarController extends BaseToolbarController {
    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
        {
            viewType = "form",
        }: IBaseViewParams,
    ) {
        super($injector, $transition, {
            crossOptions: ["snugHome", "express", "myChoice",
                "constructorHome", "assuranceApartment", "assuranceHome"],
            extraOptions: [
                "manual",
                "integrationInfo",
                "annul",
            ],
            printOptions: PrintOptions,
            viewType,
        });
    }
}
