import angular, { IQService } from "angular";
import { IModalInstanceService, IModalService } from "angular-ui-bootstrap";
import UGSKBlob from "domain/classes/blob.class";
import { Helpers } from "infrastructure/app.helpers";
import { IJournalResource } from "infrastructure/interfaces";
import IAvailableProduct from "infrastructure/interfaces/IAvailableProduct";
import { IJournalContractDTO } from "infrastructure/interfaces/IJournalContractDTO";
import { IODataResourceClass } from "infrastructure/interfaces/OData/IODataResource";
import { NgController } from "infrastructure/NgController";
import { BannersService } from "infrastructure/services/banners.service";
import { Employee } from "infrastructure/services/employee.service";
import { EnvService } from "infrastructure/services/env.service";
import { NotifyService } from "infrastructure/services/notifyService";
import { Int } from "infrastructure/types";
import moment from "moment";
import { SALE_CHANNELS } from "./constants";
import "./dashboard/dashboard.resource";
import { ChangeEmailModalController } from "./modalDialog/change-email-modal/changeEmailModal.controller";
import changeEmailModalTemplate from "./modalDialog/change-email-modal/changeEmailModal.html";
import "./modalDialog/journalsExport.css";
import jouranlsExportTemplate from "./modalDialog/journalsExport.html";

interface IProductGroup {
    code: string;
    icon: string;
    name: string;
}

interface ITool {
    action: () => void;
    available: () => boolean;
    icon: string;
    title: string;
}

export class BodyController extends NgController {
    public bannersList: Array<string | {}>;
    public searchText: string;
    public rowFactor: Int;
    public groups: IProductGroup[];
    public tools: ITool[];
    public embedMode = false;
    private availableProducts: IAvailableProduct[];
    private searching: boolean;
    private helpers: Helpers;

    public $onInit() {
        const productGroups: IProductGroup[] = [{
            code: "AutoInsurance",
            icon: "#wheel",
            name: "Автострахование",
        }, {
            code: "PropertyInsurance",
            icon: "#house",
            name: "Страхование имущества",
        }, {
            code: "HealthInsurance",
            icon: "#hearth",
            name: "Страхование жизни и здоровья",
        }, {
            code: "TravelInsurance",
            icon: "#plane",
            name: "Страхование путешественников",
        }];

        this.availableProducts = this.resolve("availableProducts");
        const saleChannel = this.resolve<string>("saleChannel");
        const employee = this.resolve<Employee>("employee");
        const bannersService = this.di<BannersService>("bannersService");
        const $uibModal = this.di<IModalService>("$uibModal");
        const envService = this.di<EnvService>("envService");
        this.helpers = this.di<Helpers>("helpers");
        this.embedMode = this.helpers.isEmbedMode();
        const getPrintableDocument = this.di<any>("getPrintableDocument");
        this.bannersList = [];
        const apiUrl = envService.read("apiUrl");
        // @todo убрать хардкод после вынесения канала продаж в нормальный справочник.
        const saleChannelsMap: {
            [key: string]: string;
        } = {
            "Агентский": "agentskiy",
            "Офисно-прямой": "ofisno-pryamoy",
            "Партнерский": "partnerskiy",
            "РСА Агент": "rsa-agent",
        };
        const channel = saleChannelsMap[saleChannel];
        bannersService.getList(channel).then((banners) => {
            this.bannersList = banners;
        });
        this.groups = productGroups.filter(
            (group) => this.availableProducts.find(
                (product) => product.groupName === group.name,
            ),
        );
        this.rowFactor = (this.groups.length > 0 && this.groups.length <= 4) ? 12 / this.groups.length : 3;
        this.searching = false;
        this.searchText = "";
        if (employee.info.IsEmailMustBeEntered && !employee.info.IsEmailConfirmed) {
            $uibModal.open({
                controller: ChangeEmailModalController,
                controllerAs: "vm",
                template: changeEmailModalTemplate,
            });
        }

        this.tools = [
            {
                action: () => {
                    this.openJournalsExportDialog();
                },
                available: () => true,
                icon: "fa-download",
                title: "Выгрузка из всех журналов",
            },
            {
                action: async () => {
                    if (employee.info.SaleChannel === SALE_CHANNELS.PARTNER || true) {
                        const notifyService = this.di<NotifyService>("notifyService");
                        await notifyService.warningMessage("Внимание", "Внимание! В соответствии с законодательством РФ агенты-юридические лица и агенты-индивидуальные предприниматели при расчетах со страхователями должны применять свою контрольно-кассовую технику.", {
                            provider: "popup",
                        });
                    }
                    getPrintableDocument({
                        contentType: "application/pdf",
                        docType: "FreeFormReceipt",
                        extension: "pdf",
                        fileName: "Квитанция на получение страховой премии",
                        headers: {
                            "X-ProductTitle": "Osago",
                        },
                        url: `${apiUrl}FreeFormReceipt/getblankform`,
                    }).then((blob: UGSKBlob) => {
                        this.helpers.openUrlInIframe(blob.url);
                    });
                },
                available: () => {
                    /*
                    * Для проверки резрешения доступности печати на главной странице, согласовались с БЭК разработчиками
                    * проверять для продукта ОСАГО
                    * задача: http://jr.ugsk.ru/jr/browse/UPB-619
                    */
                    return employee.hasPermission("GetFreeFormReceipt", "Osago");
                },
                icon: "fa-print",
                title: "Печать пустой квитанции",
            },
            {
                action: async () => {
                    const openNotificationTrafficAccident = this.di<any>("openNotificationTrafficAccident");
                    const notifyService = this.di<NotifyService>("notifyService");

                    openNotificationTrafficAccident
                        .sendRequest().$promise.then((data: { Link: string; }) => {
                            window.open(data.Link);
                        }).catch((error: any) => {
                            notifyService.warningMessage("Извещение о ДТП", error.data.Message
                                ? error.data.Message
                                : "Произошла ошибка", {
                                timeout: 5000,
                                type: "warning",
                            });
                        });
                },
                available: () => employee.hasPermission("Get", "Osago"),
                icon: "fa-print",
                title: "Печать пустого бланка Извещения о ДТП",
            },
        ];
    }
    public getContracts(val: string) {
        const odataParams = {
            $count: true,
            $filter: undefined,
            $orderby: "SigningDate desc",
            $skip: 0,
            $top: 10,
        };
        odataParams.$filter = this.getFilter(val.replace(/'|"/gi, ""));
        const [
            dashboardResource,
            notifyService,
            statusService,
        ] = this.di([
            "dashboardResource",
            "notifyService",
            "statusService",
        ]);
        return (
            (dashboardResource  as IODataResourceClass<{
                labelClass: string;
                [key: string]: any; // :(
            }>).query(odataParams)
        ).$promise.then((data) => {
            /* eslint-disable no-param-reassign */
            angular.forEach(data.value, (contract) => {
                contract.labelClass = statusService.getStatusLabelClass(contract.StatusId || contract.ContractStatusId);
            });
            /* eslint-enable no-param-reassign */
            if (data["@odata.count"] === 0) {
                notifyService.warningMessage("Поиск договора", "По вашему запросу ничего не найдено");
            }
            return data.value;
        });
    }

    public getFilter(val: string) {
        const fieldsList = [
            "PolicyNumber",
            "Discriminator",
            "Name",
            "Phone",
            "Address",
            "ContractFromAsString",
            "ContractToAsString",
        ];
        const filter = fieldsList.map((field) => `(indexof(tolower(${field}), tolower('${val}')) gt -1)`).join(" or ");
        return `(${filter})`;
    }

    public getStateClasses() {
        return (this.searching) ? ["fa", "fa-spinner", "fa-spin"] : ["fa", "fa-search"];
    }

    public selectContract($item: IJournalContractDTO) {
        this.helpers.openContract($item.ContractId, $item.ProductCode);
        this.searchText = "";
    }

    public openJournalsExportDialog() {
        const uibModal = this.di<IModalService>("$uibModal");
        const $q = this.di<IQService>("$q");
        const [
            journalResourceFactory,
            blockUI,
        ] = this.di([
            "journalResourceFactory",
            "blockUI",
        ]);
        const availableProducts = this.resolve<IAvailableProduct[]>("availableProducts");
        uibModal.open({
            controller: class {
                public availableProducts: IAvailableProduct[];
                public groups: IProductGroup[];
                public exportColumn: string[];
                public exportHeaders: string[];
                public insuranceObjectMap: {
                    [key: string]: string;
                };
                public ConsiderFilteringParameters: boolean;
                public exportFileName: string;
                private uibModalInstance: IModalInstanceService;
                private Products: {};
                private recordCount: Int;
                constructor($uibModalInstance: IModalInstanceService) {
                    "ngInject";

                    this.uibModalInstance = $uibModalInstance;
                    this.Products = {};
                    this.ConsiderFilteringParameters = false;
                    this.exportFileName = `Общая выгрузка журнала договоров от ${moment().format("DD.MM.YYYY")}.csv`;
                    this.recordCount = 0;
                    this.availableProducts = availableProducts;

                    const productGroups: IProductGroup[] = [{
                        code: "AutoInsurance",
                        icon: "#wheel",
                        name: "Автострахование",
                    }, {
                        code: "PropertyInsurance",
                        icon: "#house",
                        name: "Страхование имущества",
                    }, {
                        code: "HealthInsurance",
                        icon: "#hearth",
                        name: "Страхование жизни и здоровья",
                    }, {
                        code: "TravelInsurance",
                        icon: "#plane",
                        name: "Страхование путешественников",
                    }];

                    this.groups = productGroups.filter(
                        (group) => availableProducts.find((product) => product.groupName === group.name),
                    );

                    this.exportColumn = [
                        "ProductName",
                        "InsuranceObject",
                        "PolicyFullValue",
                        "StatusName",
                        "SigningDate",
                        "ContractFrom",
                        "ContractTo",
                        "SummaryPremium",
                        "InsuredName",
                        "EmployeeFilialName",
                        "SaleChannel",
                        "EmployeeName",
                        /* "InsuranceProgram",
                        "InsuranceAmount",
                        "SellerComment",
                        "AddressUserDefinedAddress",
                        "InsuredName",
                        "TravelingAbroadDestinationsName",
                        "VehicleVehicleModelSpelt",
                        "VehicleYearMade",
                        "VehicleVIN",
                        "VehicleLicensePlate", */
                    ];

                    this.exportHeaders = [
                        "Продукт",
                        "Объект страхования",
                        "Cерия-номер полиса",
                        "Статус договора",
                        "Дата заключения договора",
                        "Начало срока страхования",
                        "Окончание срока страхования",
                        "Страховая премия",
                        "Страхователь",
                        "Филиал",
                        "Канал продаж",
                        "Пользователь, продовец, контрагент",
                        /* "Программа страхования",
                        "Страховая сумма, франшиза",
                        "Комментарий продавца",
                        "Адрес объекта страхования",
                        "Застрахованный",
                        "Направления путешествия",
                        "Модель",
                        "Год",
                        "Vin",
                        "Гос.номер", */
                    ];

                    this.insuranceObjectMap = {
                        adultAccident: "InsuredName",
                        assuranceAppartment: "AddressUserDefinedAddress",
                        assuranceHome: "AddressUserDefinedAddress",
                        boxVHI: "InsuredPersonsFullName",
                        childAccident: "InsuredName",
                        constructorHome: "AddressUserDefinedAddress",
                        express: "AddressUserDefinedAddress",
                        familyAccident: "InsuredName",
                        myChoice: "AddressUserDefinedAddress",
                        personalAccident: "InsuredName",
                        safeCarAccident: "InsuredName",
                        shortTermAccident: "InsuredName",
                        snugHome: "AddressUserDefinedAddress",
                        travelingAbroad: "InsuredName",
                        uAuto: "VehicleVehicleModelSpelt",
                    };
                }

                public export() {
                    blockUI.start("Загрузка данных...");
                    const productCodes = Object.keys(this.Products).filter((code) => this.Products[code] === true);
                    return $q.all(productCodes.map((productCode) => {
                        const resource: IJournalResource = journalResourceFactory(productCode);
                        return resource.fetchContracts({
                            $top: this.recordCount || 250,
                        }).$promise
                            .then((data) => data.value)
                            .then((records) => records.map((record) => {
                                record.ProductName = (
                                    record.InsuranceProductName || availableProducts.find(
                                        (product) => product.code === productCode,
                                    ).productName
                                );
                                record.InsuranceObject = record[this.insuranceObjectMap[productCode]];
                                record.SummaryPremium = record.SummaryPremium || record.InsurancePremium;
                                return this.exportColumn.map((name) => record[name]);
                            }));
                    })).then((data) => {
                        return data.reduce((acc, item) => {
                            return [...acc, ...item];
                        }, [])/*.sort((a, b) => {
                            //  a и b - массивы, этот код работать не будет
                            return Number(moment(a.SigningDate).isAfter(b.SigningDate))
                        })*/;
                    }).finally(() => {
                        blockUI.stop();
                    });
                }

                public isExportDisabled() {
                    return !(this.recordCount && Object.keys(this.Products).some((key) => this.Products[key]));
                }

                public close() {
                    this.uibModalInstance.close();
                }
            },
            controllerAs: "vm",
            size: "lg",
            template: jouranlsExportTemplate,
        });
    }

    public isProductPageDisabled(code: string): boolean {
        return this.helpers.isProductDisabled(code);
    }
}
