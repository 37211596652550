import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { UAutoToolbarController } from "../uAutoToolbar.controller";

export default {
    action(context) {
        const emailResource: any = context.di("emailResource");
        const productCode = context.product.code;
        const Id = context.Contract.Id;
        emailResource(productCode).sendOffer({ id: Id }).$promise.then(() => ({})).catch(() => ({}));
    },
    icon: "fa-envelope",
    title: "Отправить полис-оферту",
    disabled(context: UAutoToolbarController) {
        return !(context.Contract.isSigned() && context.isPolicyWithEds());
    },
    visible(context: UAutoToolbarController) {
        return !context.isPolicyWithEds();
    }
} as IExtraOptionPrototype;
