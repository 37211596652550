import { IModalServiceInstance } from "angular-ui-bootstrap";
import { NgControllerBase } from "infrastructure/NgController";

import "./changeSellerConfirm.css";

export class ChangeSellerConfirmController extends NgControllerBase {
    public isConfirmed = false;

    static get $inject() {
        return ["$injector", "$uibModalInstance", "sellerName"];
    }

    public constructor($injector, public $uibModalInstance: IModalServiceInstance, public sellerName: string) {
        super($injector);
    }

    public onAccept(): void {
        this.$uibModalInstance.close(true);
    }

    public onReject(): void {
        this.$uibModalInstance.dismiss();
    }
}
