import { NgComponentController } from "infrastructure/NgController";
import uiMask from "angular-ui-mask";
import template from "./ugskPhonesValidationDialog.component.html";

export default angular.module("ugskPhonesValidationDialog", [
    uiMask,
]).component("ugskPhonesValidationDialog", {
    bindings: {
        autoSendCode: "<?",
        dialogInstance: "<",
        phones: "<",
        phoneValidationService: "<",
    },
    controller: class extends NgComponentController {
        onInit() {
            this.selectedPhone = (this.phones.length > 0) ? this.phones[0] : null;
            this.sentCodes = [];
            this.codeInvalid = false;
            this.currentSmsCode = "";
            if (this.autoSendCode) {
                this.requestCode();
            }
        }
        dismiss() {
            if (this.dialogInstance) {
                this.dialogInstance.dismiss(this.phones);
            }
        }
        close() {
            if (this.dialogInstance) {
                this.dialogInstance.close(this.phones);
            }
        }
        get tooltipHtml() {
            return `
                Нажмите "Обновить код" для отправки нового SMS с новым <b>кодом</b>
            `;
        }
        get codeSentForNumber() {
            return (this.selectedPhone && this.selectedPhone.Number) ? this.sentCodes[this.selectedPhone.Number] : false;
        }
        get serviceEnabled() {
            return (this.phoneValidationService && this.phoneValidationService.isServiceEnabled());
        }
        requestCode() {
            this.codeRequestLoading = true;
            this.phoneValidationService.requestCode(this.selectedPhone.Number).then(() => {
                this.sentCodes[this.selectedPhone.Number] = true;
            }).finally(() => {
                this.codeRequestLoading = false;
            });
        }
        verifyPhone() {
            this.verifyPhoneLoading = true;
            this.phoneValidationService.verifyPhone(
                this.selectedPhone.Number,
                this.currentSmsCode
            ).then((verifiedPhones) => {
                if (verifiedPhones) {
                    this.phones = verifiedPhones;
                }
                this.close();
            }).catch(() => {
                this.codeInvalid = true;
            }).finally(() => {
                this.verifyPhoneLoading = false;
            });
        }
    },
    controllerAs: "vm",
    template,
}).name;
