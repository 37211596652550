import angular, { IQService } from "angular";
import ugskAnotherInsuranceHistoryComponent from "application/components/ugsk-another-insurance-history/ugskAnotherInsuranceHistory.component";
import "application/components/ugsk-risks/ugskRisks.component";
import ugskSublimits from "application/components/ugsk-sublimits/ugskSublimits.component";
import { Product } from "domain/classes/product.class";
import { Logger } from "infrastructure/logToServer";
import { ProductsService } from "infrastructure/services/products.service";
import { IFLJournalController } from "../journal/iflJournal.controller";
import { AssuranceHomeToolbarController } from "./assuranceHome-toolbar.controller";
import { AssuranceHomeController } from "./assuranceHome.controller";
import { AssuranceHomeContract, AssuranceHomeRepository } from "./assuranceHome.factory";
import assuranceHomeResourceModule from "./assuranceHome.resource";
import template from "./body.html";
import ugskAssuranceHomePropertyPartComponent from "./components/ugsk-assurance-home-property-part/ugskAssuranceHomePropertyPart.component";
import { AssuranceHomeJournalToolbarController } from "./journal/assuranceHomeJournal-toolbar.controller";
import manual from "./manual_assuranceHome.pdf";
import { stringLiteral } from "babel-types";

class AssuranceHome extends Product {
    constructor() {
        super();
        this.code = "assuranceHome";
        this.groupName = "Страхование имущества";
        this.productName = "Уверенное решение (дом)";
        this.description = "Универсальное страхование домов";
        this.insuranceRules = {
            base: "https://ugsk.ru/about/pravila/Imushestvo.pdf",
        };
        this.insurerCanBeLegalEntity = false;
        this.logo = "nav_assuranceHome";
        this.manualUrl = manual;
        this.sort = 80;
        this.template = template;
        this.hasImageRepositoryAbility = true;
        this.controller = AssuranceHomeController;
        this.toolbarController = AssuranceHomeToolbarController;
        this.journalController = IFLJournalController;
        this.journalToolbarController = AssuranceHomeJournalToolbarController;
        this.extraViews = {
            "contractParams@app.assuranceHome.index": {
                template: require("./view.contractParams.html"),
            },
            "mainBuilding@app.assuranceHome.index": {
                template: require("./view.mainBuilding.html"),
            },
            "mainBuildingSublimits@app.assuranceHome.index": {
                template: require("./view.mainBuildingSublimits.html"),
            },
            "additionalConstructionsSublimits@app.assuranceHome.index": {
                template: require("./view.additionalConstructionsSublimits.html"),
            },
            "inspection@app.assuranceHome.index": {
                template: require("./view.inspection.html"),
            },
            "composition@app.assuranceHome.index": {
                template: require("./view.composition.html"),
            },
            "movableProperties@app.assuranceHome.index": {
                template: require("./view.movableProperties.html"),
            },
            "engineeringEquipment@app.assuranceHome.index": {
                template: require("./view.engineeringEquipment.html"),
            },
            "additionalConstructions@app.assuranceHome.index": {
                template: require("./view.additionalConstructions.html"),
            },
            "risks@app.assuranceHome.index": {
                template: require("./view.risks.html"),
            },
            "conditions@app.assuranceHome.index": {
                template: require("./view.conditions.html"),
            },
            "insurant@app.assuranceHome.index": {
                template: require("./view.insurant.html"),
            },
            "payment@app.assuranceHome.index": {
                template: require("./view.payment.html"),
            },
        };
        this.extraResolve = {
            additionalData(
                $q: IQService,
                $log: Logger,
                Contract: AssuranceHomeContract,
                assuranceHomeAdditionalConstructionRepositoryService,
                assuranceHomeConstructionPartRepositoryService,
                hashList,
            ) {
                "ngInject";

                const pArray = [];

                $log.debug("Загрузка дополнительного контента");
                angular.forEach(Contract.AdditionalConstructions, (construction, $index) => {
                    const repository = new AssuranceHomeRepository(assuranceHomeAdditionalConstructionRepositoryService);
                    const promise = repository.load(construction);
                    promise.then(() => {
                        $log.debug("Загрузка дополнительного контента #" + $index);
                        hashList.set(construction.Guid, repository);
                    });
                    pArray.push(promise);
                });
                angular.forEach(Contract.PropertyParts, (part, $index) => {
                    const repository = new AssuranceHomeRepository(assuranceHomeConstructionPartRepositoryService);
                    /**
                     * Патчинг этажа значениями из контракта.
                     * Будет исправленно в следующей задаче, когда чекбоксы будут в разрезе каждого этажа.
                     */
                    part.NeedToInsureInterior = Contract.NeedToInsureInterior;
                    part.PropertyIsFinished = Contract.PropertyIsFinished;
                    const promise = repository.load(part);
                    promise.then(() => {
                        $log.debug("Загрузка дополнительного контента #" + $index);
                        part.setRepository(repository);
                    });
                    pArray.push(promise);
                });
                return $q.all(pArray).then(() => {
                    $log.debug("Загрузка дополнительного контента окончена");
                });
            },
        };
    }
    get contractClass() {
        return AssuranceHomeContract;
    }
}

angular.module("app.assuranceHome", [
    "touchSpin",
    assuranceHomeResourceModule,
    "ugsk.components.risks",
    ugskAnotherInsuranceHistoryComponent,
    ugskSublimits,
    ugskAssuranceHomePropertyPartComponent,
]).run((productsService: ProductsService) => {
    productsService.register(new AssuranceHome());
});
