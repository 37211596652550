import { Transition } from "@uirouter/core";
import { AssuranceHomeToolbarController } from "../assuranceHome-toolbar.controller";

export class AssuranceHomeJournalToolbarController extends AssuranceHomeToolbarController {
    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
    ) {
        super($injector, $transition, {
            viewType: "journal",
        });
    }
}
