/**
 * ugskOsagoPreviousContract - компонента "Предыдущий договор"
 */

import angular from "angular";
import { NgComponentController } from "infrastructure/NgController";
import { PreviousContractInfo } from "application/osago/classes/PreviousContractInfo";

class UgskOsagoPreviousContractController extends NgComponentController {
    public contractId: number;
    public contractInfo: PreviousContractInfo;
    public isFormLocked: boolean;
    public isExternalTransition: boolean;
    public isProlongation: boolean;
    public insurerName: string;
    public policyNumber: string;
    public policySerial: string;

    public statusesDictionary = new Map<string, string>([
        ["signed", "Заключен"],
    ]);

    public getStatusTitle(status?: string): string {
        if (status) {
            return this.statusesDictionary.get(status.toLowerCase()) || "";
        }

        return "";
    }
}

export default angular.module("app.osago.components.previous-contract", [])
.component("ugskOsagoPreviousContract", {
    bindings: {
        contractId: "<",
        contractInfo: "<",
        insurerName: "<",
        isExternalTransition: "<",
        isFormLocked: "<",
        isProlongation: "<",
        policyNumber: "<",
        policySerial: "<",
    },
    controller: UgskOsagoPreviousContractController,
    controllerAs: "vm",
    template: require("./ugskOsagoPreviousContract.component.html"),
})
.name;
