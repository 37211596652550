import angular from "angular";

export function DropdownMinTopDirective($timeout: ng.ITimeoutService): ng.IDirective {
    "ngInject";

    return {
        link: (
            scope: ng.IScope,
            element: ng.IAugmentedJQuery,
            attributes: ng.IAttributes,
        ) => {
            const maxTop = parseInt(attributes.dropdownMinTop, 10);
            if (Number.isNaN(maxTop)) {
                return;
            }
            const setMaxTop = () => {
                const windowHeight = angular.element(window).height();
                const elementHeight = element.height();
                const elementOffset = element.offset();
                const topOffset = elementOffset.top;
                const bottomOffset = windowHeight - elementHeight - topOffset;
                element.css({
                    maxHeight: (windowHeight - bottomOffset - maxTop) + "px",
                    overflowY: "auto",
                });
            };
            element.parent(".dropdown").on("show.bs.dropdown", () => {
                $timeout(() => {
                    setMaxTop();
                });
            });
        },
        restrict: "A",
    };
}
