import angular from "angular";
import inspectionViewModule from "application/components/views/ugsk-inspection-of-contract-view/ugskInspectionOfContractView.component";
import { Product } from "domain/classes/product.class";
import formFieldLockerServiceModule from "infrastructure/services/formFieldLocker.service";
import { ProductsService } from "infrastructure/services/products.service";
import ugskPaymentsServiceComponent from "../components/ugsk-payments-service/ugskPayments.component";
import ugskAdditionalPhonesComponent from "../components/ugsk-additional-phones/ugskAdditionalPhones.component";
import ugskPublicPaymentComponent from "../components/ugsk-public-payment/ugskPublicPayment.component";
import template from "./body.html";
import uAutoAdditionalRisks from "./components/ugsk-uauto-additional-risks/ugskUautoAdditionalRisks.component";
import uAutoApprovalRequest from "./components/ugsk-uauto-approval-request/ugskUautoApprovalRequest.component";
import ugskUautoInsuranceProgram from "./components/ugsk-uauto-insurance-program/ugskUautoInsuranceProgram.component";
import ugskUautoMultiCalculation from "./components/ugsk-uauto-multi-calculation/ugskUautoMultiCalculation.component";
import ugskUautoPaymentParams from "./components/ugsk-uauto-payment-params/ugskUautoPaymentParams.component";
import ats from "./components/ugsk-uauto-ats/ugskUautoAts.component";
import uAutoContractParamsModule from "./components/ugsk-uauto-contract-params/ugskUautoContractParams.component";
import participants from "./components/ugsk-uauto-participants/ugskUautoParticipants.component";
import vehicleAccessories from "./components/ugsk-uauto-vehicle-accessories/ugskUautoVehicleAccessories.component";
import uAutoVehicleParamsModule from "./components/ugsk-uauto-vehicle-params/ugskUAutoVehicleParams.component";
import { UAutoJournalController } from "./journal/uAutoJournal.controller";
import { UAutoJournalToolbarController } from "./journal/uAutoJournalToolbar.controller";
import manual from "./manual_uAuto.pdf";
import { UAutoController } from "./uAuto.controller";
import "./uAuto.css";
import { UAutoContract } from "./uAuto.factory";
import "./uAuto.mapping";
import { UAutoToolbarController } from "./uAutoToolbar.controller";
import ugskSellerCuratorComponent from "application/components/ugsk-seller-curator/ugskSellerCurator.component";

class UAuto extends Product {
    constructor() {
        super();
        this.code = "uAuto";
        this.manualUrl = manual;
        this.template = template;
        this.controller = UAutoController;
        this.toolbarController = UAutoToolbarController;
        this.groupName = "Автострахование";
        this.productName = "КАСКО";
        this.description = "Страхование автомобилей по договору КАСКО";
        this.insuranceRules = {
            base: "https://ugsk.ru/pravila/Kasko.pdf",
            insurancePrograms: [
                {
                    insuranceRules: "https://ugsk.ru/upload/ugsk_official/pravila/update-12-08-2019/GAP.pdf",
                    programName: "«GAP»",
                },
            ],
        };
        this.insurerCanBeLegalEntity = true;
        this.logo = "nav_kasko";
        this.sort = 10;
        this.journalController = UAutoJournalController;
        this.journalToolbarController = UAutoJournalToolbarController;
        this.hasInspectionConclusion = true;
        this.extraResolve = {
            banks: (dictionariesService) => {
                "ngInject";

                return dictionariesService.get("Banks");
            },
        };
    }
    get contractClass() {
        return UAutoContract;
    }
    public getContractBasedProductName(contract: UAutoContract) {
        const clearedInsuranceProgram = String(contract.InsuranceProgram).replace(/[«»]/g, "");
        return `${this.productName}. ${clearedInsuranceProgram}`;
    }
}

export default angular
    .module("app.uauto", [
        formFieldLockerServiceModule,
        uAutoVehicleParamsModule,
        uAutoApprovalRequest,
        uAutoContractParamsModule,
        ats,
        vehicleAccessories,
        participants,
        inspectionViewModule,
        uAutoAdditionalRisks,
        ugskUautoInsuranceProgram,
        ugskUautoMultiCalculation,
        ugskUautoPaymentParams,
        ugskPaymentsServiceComponent,
        ugskAdditionalPhonesComponent,
        ugskPublicPaymentComponent,
        ugskSellerCuratorComponent,
    ])
    .run((productsService: ProductsService) => {
        "ngInject";

        productsService.register(new UAuto());
    }).name;
