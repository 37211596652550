import { IModalService } from "angular-ui-bootstrap";
import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { OsagoToolbarController } from "../osago-toolbar.controller";
import { ForcedCalculationController } from "./forcedCalculation.controller";
import forcedCalculationTemplate from "./forcedCalculation.html";

export default {
    action(context: OsagoToolbarController) {
        const $uibModal = context.di<IModalService>("$uibModal");
        $uibModal.open({
            controller: ForcedCalculationController,
            controllerAs: "vm",
            resolve: {
                contract: context.Contract,
            },
            template: forcedCalculationTemplate,
        }).result.then(() => {
            context.saveAsyncContract(true);
        }).catch(() => { /**/ });
    },
    disabled(context: OsagoToolbarController) {
        const userHasPermission = context.originalEmployee.hasPermission("ForceCalculate", "osago");
        const isReliableDriveForceCalcAvailable = context.isReliableDriveAvailable() && context.reliableDriveContract.Id >= 0;
        return context.Contract.isBlank()
            || !userHasPermission
            || context.Contract.isLocked()
            || isReliableDriveForceCalcAvailable;
    },
    icon: "fa-calculator",
    title: "Рассчитать c измененными коэффициентами",
    view: "form",
} as IExtraOptionPrototype;
