import angular from "angular";
import { IDatePickerOptions } from "infrastructure/interfaces/IDatePickerOptions";
import { NgComponentController } from "infrastructure/NgController";
import moment from "moment";
import ugskPersonComponentModule from "../ugsk-person/ugskPerson.component";
import template from "./ugskDriver.component.html";

class UgskDriver extends NgComponentController {
  public datepickerConfig: Partial<IDatePickerOptions>;

  public onInit(): void {
    this.datepickerConfig = {
      endDate: moment().format("YYYY-MM-DD"),
      outFormat: "YYYY-MM-DDT00:00:00",
      startDate: moment()
        .subtract(100, "year")
        .format("YYYY-MM-DD"),
    };
  }
}

export default angular
  .module("ugsk.components.driver", [ugskPersonComponentModule])
  .component("ugskDriver", {
    bindings: {
      drivingExperience: "=",
      guid: "<",
      licenseNumber: "=",
      licenseSerial: "=",
      ngDisabled: "<",
    },
    controller: UgskDriver,
    controllerAs: "vm",
    template,
  }).name;
