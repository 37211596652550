import angular from "angular";
import { NgControllerBase } from "infrastructure/NgController.ts";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { EnvService } from "./env.service";

import { IAuthTokenData, IParsedToken, IUGSKLocalStorage } from "infrastructure/interfaces";
import { Helpers } from "infrastructure/app.helpers";

export class AuthenticationService extends NgControllerBase {
    private $localStorage: IUGSKLocalStorage;
    private envService: EnvService;
    private $window: angular.IWindowService;
    private mainPath: string;
    private sasMainPath: string;
    private parsedToken: IParsedToken;
    private helpers: Helpers;
    constructor($injector: angular.auto.IInjectorService) {
        super($injector);
        [
            this.$localStorage,
            this.envService,
            this.$window,
            this.helpers,
        ] = this.di([
            "$localStorage",
            "envService",
            "$window",
            "helpers",
        ]);
        const dashboardFrontLink = this.envService.read("dashboardFrontLink");
        const isDashboardFrontEnabled = this.envService.read("dashboardFrontEnabled");
        const isFrontDisabled = this.envService.read("disabled");

        if (dashboardFrontLink && isDashboardFrontEnabled && isFrontDisabled) {
            const dashboardRedirectUrl = new URL("uproFront", dashboardFrontLink);
            this.mainPath = dashboardRedirectUrl.toString();
            this.sasMainPath = new URL("uproFront/authorization.html", this.mainPath).toString();
        } else {
            this.mainPath = this.$window.location.origin + this.$window.location.pathname;
            this.sasMainPath = this.mainPath + "authorization.html";
        }
    }
    private get tokenData(): IAuthTokenData {
        return this.$localStorage.tokenData;
    }
    private set tokenData(tokenData: IAuthTokenData) {
        if (angular.isObject(tokenData)) {
            tokenData.receivingTokenDate = new Date();
        }
        this.$localStorage.tokenData = tokenData;
    }
    public isImplicitFlowToken(): boolean {
        return Boolean(this.tokenData.id_token);
    }

    public logout(): void {
        if (!this.isImplicitFlowToken()) {
            this.clearTokenData();
            return;
        }

        const idSrvUrl = this.envService.read("idSrvUrl");
        const logoutUrl = `${idSrvUrl}connect/endsession`;
        const url = `${logoutUrl}?id_token_hint=${this.tokenData.id_token}&post_logout_redirect_uri=${this.mainPath}`;
        this.clearTokenData();
        this.$window.location.href = url;

    }
    public isAuthenticated(): boolean {
        const tokenData = this.tokenData;
        if (tokenData && tokenData.access_token && tokenData.receivingTokenDate && tokenData.expires_in) {
            const expiresIn = moment(tokenData.receivingTokenDate).add(tokenData.expires_in, "s");
            if (moment().isBefore(expiresIn)) {
                return true;
            } else if (this.helpers.isEmbedMode()) {
                this.clearTokenData();
                setTimeout(() => {
                    window.parent.postMessage({ type: "TOKENEXPECTED" }, "*");
                }, 1000);
            }
        }
        return false;
    }
    public reLogin(): void {
        window.parent.postMessage({ type: "LOGIN" }, "*");
    }
    public authenticateViaSAS(): void {
        const idSrvUrl = this.envService.read("idSrvUrl");
        const tokenUrl = `${idSrvUrl}connect/authorize`;
        const clientId = "front.js.implicit";
        const redirectUri = this.sasMainPath;
        const responseType = "token id_token";
        const scope = "userinfo openid webapi idm pay-agent-receivables pay-payments";
        const state = String(Date.now()) + Math.random();
        const nonce = String(Math.random());

        const url = tokenUrl +
            "?" +
            "client_id=" +
            encodeURI(clientId) +
            "&" +
            "response_type=" +
            encodeURI(responseType) +
            "&" +
            "scope=" +
            encodeURI(scope) +
            "&" +
            "state=" +
            encodeURI(state) +
            "&" +
            "nonce=" +
            encodeURI(nonce) +
            "&" +
            "redirect_uri=" +
            encodeURI(redirectUri);
        this.$window.location.href = url;
    }
    public authenticateViaDomain(): void {
        const ntlmAuthProxy = this.envService.read("ntlmAuthProxy");
        const url = `${ntlmAuthProxy}Login?redirectTo=${this.mainPath}`;
        this.$window.location.href = url;
    }
    public clearTokenData(): void {
        this.tokenData = null;
    }
    public getAccessToken(): string {
        return this.tokenData.access_token;
    }
    public getParsedToken(): IParsedToken {
        if (!this.tokenData) {
            return {} as IParsedToken;
        }
        if (!this.parsedToken) {
            this.parsedToken = jwtDecode<IParsedToken>(this.tokenData.access_token);
        }
        return this.parsedToken;
    }
    public canUserManagePassword(): boolean {
        return ({ False: false, True: true })[this.getParsedToken().IsPasswordManagementAllowed];
    }

    public canUserSelfRegister(): boolean {
        return ({ False: false, True: true })[this.getParsedToken().IsSelfRegistrationAllowed];
    }
}

export default angular
    .module("ugsk.services.authentication", [])
    .service("authenticationService", AuthenticationService)
    .name;
