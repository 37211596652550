angular.module("app.payments.registries", [])
.run(($state) => {
    "ngInject";

    const stateRegistry = $state.router.stateRegistry;
    stateRegistry.register({
        name: "app.paymentsRegistries",
        abstract: true,
        url: "/paymentsRegistries"
    });
    stateRegistry.register({
        name: "app.paymentsRegistries.index",
        url: "",
        data: {
            pageTitle: "Реестры платежей"
        },
        views: {
            "body@": {
                template: "<ugsk-payments-registries-page></ugsk-payments-registries-page>"
            }
        }
    });
    const paymentsRegistryPageContext = {};
    stateRegistry.register({
        name: "app.paymentsRegistries.new",
        url: "/new",
        data: {
            pageTitle: "Отчет агента"
        },
        views: {
            "body@": {
                template: "<ugsk-payments-registry-page ng-model='vm.context.registry' employee-filter-enabled='vm.employeeFilterEnabled'></ugsk-payments-registry-page>",
                controllerAs: "vm",
                controller: function(employee) {
                    "ngInject";

                    this.context = paymentsRegistryPageContext;

                    return;
                    if(employee.info){
                        this.employeeFilterEnabled = (employee.info.Role !== "Партнер");
                        //  Функционал поиска по продавцу отключен до тех пор, пока не решится вопрос с производительностью
                    } else {
                        this.employeeFilterEnabled = false;
                    }
                }
            },
            "bottomToolbar@": {
                template: "<ugsk-payments-registries-page-toolbar ng-model='vm.context.registry'></ugsk-payments-registries-page-toolbar>",
                controllerAs: "vm",
                controller: function() {
                    this.context = paymentsRegistryPageContext;
                }
            }
        }
    });
    stateRegistry.register({
        name: "app.paymentsRegistries.item",
        url: "/:id",
        data: {
            pageTitle: "Отчет агента"
        },
        views: {
            "body@": {
                template: "<ugsk-payments-registry-page registry-id='vm.registryId' ng-model='vm.context.registry'></ugsk-payments-registry-page>",
                controllerAs: "vm",
                controller: function(registryId) {
                    "ngInject";
                    this.registryId = registryId;
                    this.context = paymentsRegistryPageContext;
                }
            },
            "bottomToolbar@": {
                template: "<ugsk-payments-registries-page-toolbar ng-model='vm.context.registry' ng-model='vm.context.registry'></ugsk-payments-registries-page-toolbar>",
                controllerAs: "vm",
                controller: function() {
                    this.context = paymentsRegistryPageContext;
                }
            }
        },
        resolve: {
            registryId: $stateParams => {
                "ngInject";

                return parseInt($stateParams.id, 10);
            }
        }
    });
});


import { ugskPaymentsRegistriesPageComponent } from "./components/ugsk-payments-registries-page/ugskPaymentsRegistriesPage.component";

angular.module("app.payments.registries").component("ugskPaymentsRegistriesPage", ugskPaymentsRegistriesPageComponent);

import { ugskPaymentsRegistryComponent } from "./components/ugsk-payments-registry/ugskPaymentsRegistry.component";

angular.module("app.payments.registries").component("ugskPaymentsRegistry", ugskPaymentsRegistryComponent);

import { ugskPaymentsRegistryPageComponent } from "./components/ugsk-payments-registry-page/ugskPaymentsRegistryPage.component";

angular.module("app.payments.registries").component("ugskPaymentsRegistryPage", ugskPaymentsRegistryPageComponent);

import { ugskPaymentsRegistriesPageToolbarComponent } from "./components/ugsk-payments-registries-page-toolbar/ugskPaymentsRegistriesPageToolbar.component";

angular.module("app.payments.registries").component("ugskPaymentsRegistriesPageToolbar", ugskPaymentsRegistriesPageToolbarComponent);
