//  common libraries
import "expose-loader";
import "lib/jquery-mask-plugin";
import "bootstrap";
import "icheck";
// import "babel-polyfill";

//  angularjs libraries
import "angular";

import "angular-resource";
import "angularjs-toaster";
import "angular-filter";
import "angular-block-ui";
import "angular-bootstrap-confirm";
import "angular-ladda";
import "angular-spinner";
import "angular-odata-resources";
import "ngstorage";
import "ng-csv";
import "ng-infinite-scroll";

import "lib/jquery.bootstrap-touchspin";
import "lib/touchspin.directive";

// import "../scripts/angular-ui/ui-utils.js";  //  unused
import "node_modules/ng-table/bundles/ng-table";
import "lib/angular-input-modified";

import "infrastructure/polyfill";
import "infrastructure/app.resource";
import "infrastructure/app.factory";
import "infrastructure/app.service";
import "infrastructure/app.directives.ts";
import "infrastructure/app.filters.ts";
import "infrastructure/app.helpers";

import "application/components/ugsk-phones/ugskPhones.component";
import "application/components/ugsk-policy-serial/ugskPolicySerial.component";

import "application/components/ugsk-icheck-component/iCheck.component";

import "application/components/ugsk-components.index";

import "node_modules/signalr/jquery.signalR";

import "application/app.constants";
import "application/app.module";
import "application/bootstrapper";

//  local
import "./assets/styles/bootstrap-xl.css";
import "./assets/styles/inspinia.css"; //    inspinia
//  todo: переместить в соответствующий компонент
import "./assets/styles/introjs-nassim.css";
import "./assets/styles/introjs-rtl.min.css";
import "./assets/styles/introjs.min.css";
import "./assets/styles/passwordStyle.css";
import "./assets/styles/Site.css";
import "./assets/styles/uib-tab.css";
//  todo: переместить в соответствующий компонент
import "application/userProfile/passwordManagement.css";

import $ from "jquery";
$(".container.loading").hide();

const moment = require("moment");
moment.fn.addOriginal = moment.fn.add;
moment.fn.add = function (v1, v2) {
    const duration = typeof v1 === "number" ? v1 : v2;
    const unit = typeof v2 === "string" ? v2 : v1;
    const isOneYear = (duration === 12 && ["month", "months", "M"].includes(unit))
        || (duration === 1 && ["year", "years", "y"].includes(unit));
    if (this.isLeapYear() && this.month() === 1 && this.date() === 29 && isOneYear) {
        console.warn("MomentJS (UgskLeapYearPatch): Выполняется модифицированный метод add");
        const result = this.addOriginal(duration, unit).add(1, "d");
        return result;
    }
    return this.addOriginal(v1, v2);
}
