import { NgComponentController } from "infrastructure/NgController";
import { PAYMENT_KINDS } from "application/constants.ts";

import template from "./ugskPayment.component.html";

const paymentKey = Symbol("payment");
const originPaymentKey = Symbol("originPayment");

export default angular.module("ugsk.components.payment", []).component("ugskPayment", {
    bindings: {
        allowedPaymentKinds: "<",
        lock: "<?",
        lockAmount: "<?",
        lockPaymentKindId: "<?",
        allowedPaymentKinds: "<",
        payment: "=ngModel",
        suggestedPaymentNumber: "<",
    },
    controller: class extends NgComponentController {
        get logic() {
            const vm = this;
            return {
                condition() {
                    return !vm.lock;
                },
                constants: {
                    BankCardPaymentKindId: 2,
                    CashPaymentKindId: 1,
                    InvoicePaymentKindId: 5,
                },
                rules: [{
                    description: "Блокировка поля 'Серия А7' для типов оплаты, которые не требуют A7",
                    properties: ["serialDisabled"],
                    when: "paymentKindWithId(payment.PaymentKindId).IsA7Required eq true",
                    then: "serialDisabled = false", // eslint-disable-line sort-keys
                    else: "serialDisabled = true; payment.Serial = null;", // eslint-disable-line sort-keys
                }, {
                    description: "Блокировка поля 'Подтверждение' для неподтверждаемых видов оплаты",
                    properties: ["PaymentKindUniqueDataDisabled"],
                    when: "payment.PaymentKindId eq BankCardPaymentKindId",
                    then: "PaymentKindUniqueDataDisabled = false;", // eslint-disable-line sort-keys
                    else: "PaymentKindUniqueDataDisabled = true; payment.PaymentKindUniqueData = null;", // eslint-disable-line sort-keys
                }, {
                    description: "Ограничение по длине поля 'Номер документа' в зависимости от вида оплаты",
                    properties: ["numberMaxLength"],
                    when: "payment.PaymentKindId eq BankCardPaymentKindId or payment.PaymentKindId eq CashPaymentKindId",
                    then: "numberMaxLength = 7", // eslint-disable-line sort-keys
                    else: "numberMaxLength = 16", // eslint-disable-line sort-keys
                }, {
                    description: "Маска ввода для поля 'Номер документа', для некоторых видов оплаты возмножны только цифры",
                    properties: ["numberInputMask"],
                    when: "payment.PaymentKindId eq BankCardPaymentKindId or payment.PaymentKindId eq CashPaymentKindId",
                    then: "numberInputMask = '0000000';", // eslint-disable-line sort-keys
                    else: "numberInputMask = '0000000000000000';", // eslint-disable-line sort-keys
                }, {
                    description: "Блокировка поля 'Номер документа' если с бэка приходят флаги IsNumberRequired [false|true]",
                    properties: ["numberDisabled"],
                    when: `paymentKindWithId(payment.PaymentKindId).IsNumberRequired`,
                    then: "numberDisabled = false", // eslint-disable-line sort-keys
                    else: "numberDisabled = true", // eslint-disable-line sort-keys
                }, {
                    description: "Определение начального состояния поля Номер документа",
                    properties: ["numberChangedByUser"],
                    once: true,
                    when: "payment.Number ne suggestedPaymentNumber",
                    then: "numberChangedByUser = true",
                    else: "numberChangedByUser = false"
                }, {
                    description: "Если пользователь не редактировал номер платежного документа, то берем его из номера полиса",
                    when: `not(numberChangedByUser) and defined(suggestedPaymentNumber)`,
                    then: "payment.Number = slice(suggestedPaymentNumber, numberMaxLength);",
                }, {
                    description: "Если поле тип оплаты заблокировано то очищается значение номера в модели",
                    when:`numberDisabled eq true`,
                    then: `payment.Number = null`,
                }],
            };
        }
        get paymentKindUniqueDataDescription() {
            return `
                <ul>
                    <li>
                        Банковские карты - последние 4 цифры номера
                    </li>
                </ul>
            `;
        }
        slice(str, numberMaxLength) {
            return String(str).slice(0, numberMaxLength);
        }
        paymentKindWithId(id) {
            return this.paymentKinds.find(kind => kind.Id === id);
        }
        onInit() {
            const [
                dictionariesService,
                $q,
            ] = this.di([
                "dictionariesService",
                "$q",
            ]);
            this.paymentKinds = [];
            this.A7DocumentSerials = [];
            return $q.all([
                dictionariesService.get("PaymentKinds"),
                dictionariesService.get("A7DocumentSerials"),
            ]).then(([paymentKinds, A7DocumentSerials]) => {
                this.A7DocumentSerials = A7DocumentSerials;
                this.paymentKinds = paymentKinds.map(paymentKind => {
                    if (paymentKind.IsDisabled === true) {
                        paymentKind.disabled = true; // eslint-disable-line no-param-reassign
                    } else {
                        paymentKind.disabled = // eslint-disable-line no-param-reassign
                            this.allowedPaymentKinds &&
                            !this.allowedPaymentKinds.includes(paymentKind.Id) &&
                            !this.allowedPaymentKinds.includes(paymentKind.Name);
                    }
                    return paymentKind;
                });
            });
        }
        reset() {
            this[paymentKey] = angular.copy(this[originPaymentKey], this[paymentKey]);
        }
        onPaymentKindChanged() {
            if (this.payment.PaymentKindId === PAYMENT_KINDS.INTERNET_ACQUIRING) {
                const [notifyService] = this.di(["notifyService"]);
                notifyService.message(
                    "Внимание",
                    "Оплата должна быть произведена до наступления срока начала действия договора",
                    { timeout: 15000, provider: "toaster", type: "warning" }
                );
            }
        }
        get payment() {
            return this[paymentKey];
        }
        set payment(model) {
            if (this.form) {
                this.form.$setPristine();
            }
            this[originPaymentKey] = angular.copy(model);
            this[paymentKey] = model;
        }
    },
    controllerAs: "vm",
    template,
}).name;
