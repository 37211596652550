import { Product } from "domain/classes/product.class";
import { IFLJournalController } from "../journal/iflJournal.controller";
import { SnugHomeJournalToolbarController } from "./journal/snugHomeJournal-toolbar.controller";
import { SnugHomeToolbarController } from "./snugHome-toolbar.controller";
import { SnugHomeController } from "./snugHome.controller";

import angular from "angular";
import { Contract } from "domain/classes/contract.class";
import ProlongableIflContractMix from "domain/mixes/ProlongableIflContract.mix";
import { ProductsService } from "infrastructure/services/products.service";
import template from "./body.html";
import manual from "./manual_snughome.pdf";

class SnugHome extends Product {
    constructor() {
        super();
        this.code = "snugHome";
        this.groupName = "Страхование имущества";
        this.productName = "Лёгкое решение";
        this.description = "Страхование квартир и домов";
        this.insuranceRules = {
            base: "https://ugsk.ru/about/pravila/u-pro/08_47.pdf",
        };
        this.insurerCanBeLegalEntity = false;
        this.logo = "nav_snugHome";
        this.manualUrl = manual;
        this.sort = 30;
        this.template = template;
        this.controller = SnugHomeController;
        this.toolbarController = SnugHomeToolbarController;
        this.journalController = IFLJournalController;
        this.journalToolbarController = SnugHomeJournalToolbarController;
    }
    get contractClass() {
        return class extends ProlongableIflContractMix(Contract) {};
    }
}

angular.module("app.snugHome", [])
.run((productsService: ProductsService) => {
    "ngInject";

    productsService.register(new SnugHome());
});
