import { Helpers } from "infrastructure/app.helpers";
import { NgController } from "infrastructure/NgController";
import { AuthenticationService } from "infrastructure/services/authentication.service";
import { EmployeeService } from "infrastructure/services/employee.service";
import { EnvService } from "infrastructure/services/env.service";

export class LoginController extends NgController {

    public visibleAssistanceSAS: boolean = false;
    public dashboardFrontEnabled: boolean;
    public dashboardFrontLink: string;
    private $stateParams: any = null;
    private helpers: Helpers;

    public $onInit(): void {
        [this.$stateParams] = this.resolve(["$stateParams"]);
        this.helpers = this.di<Helpers>("helpers");
        const envService = this.di<EnvService>("envService");
        this.visibleAssistanceSAS = this.$stateParams.assistanceSAS;
        this.dashboardFrontEnabled = envService.read("dashboardFrontEnabled");
        this.dashboardFrontLink = envService.read("dashboardFrontLink");
    }

    public doAuthenticate(codeType: string): void {
        const authenticationService = this.di<AuthenticationService>("authenticationService");
        const employeeService = this.di<EmployeeService>("employeeService");
        const [$localStorage] = this.di(["$localStorage"]);

        $localStorage.redirectTo = this.$stateParams.returnTo;
        $localStorage.redirectToParams = this.$stateParams.returnToParams;
        authenticationService.clearTokenData();
        employeeService.resetEmployee();

        switch (codeType) {
            case "domain":
                authenticationService.authenticateViaDomain();
                break;
            case "sas":
                authenticationService.authenticateViaSAS();
                break;
        }
    }

    public isEmbed(): boolean {
        return this.helpers.isEmbedMode();
    }

}
