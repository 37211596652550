import { StateService } from "@uirouter/core";
import angular from "angular";
import { IModalServiceInstance } from "angular-ui-bootstrap";
import { Helpers } from "infrastructure/app.helpers";
import { IFilialResource } from "infrastructure/app.resource";
import { IEmployeeResource, IODataParams } from "infrastructure/interfaces";
import * as WebApi from "infrastructure/interfaces/WebApi";
import Filial from "infrastructure/interfaces/WebApi/Filial";
import { EmployeeService } from "infrastructure/services/employee.service";
import { NotifyService } from "infrastructure/services/notifyService";
import { NgTableParams as NgTableParamsType } from "ng-table";

export class ShowUsersController {
    static get $inject() {
        return [
            "employeeResource", "NgTableParams", "$uibModalInstance",
            "filialService", "employeeService", "$state", "notifyService",
            "helpers",
        ];
    }

    public tableParams: NgTableParamsType<WebApi.AccessUser>;
    public Login: string;
    public filials: Filial[];

    constructor(
        private employeeResource: IEmployeeResource,
        private NgTableParams: typeof NgTableParamsType,
        private $uibModalInstance: IModalServiceInstance,
        private filialService: IFilialResource,
        private employeeService: EmployeeService,
        private $state: StateService,
        private notifyService: NotifyService,
        private helpers: Helpers,
    ) { /** */}

    public $onInit(): void {
        this.tableParams = new this.NgTableParams({
            count: 10,
            page: 1,
        }, {
            counts: [],
            getData: this.getDataFromController.bind(this),
            total: 0,
        });
        this.initFilials();
    }

    public getDataFromController(params: NgTableParamsType<WebApi.AccessUser>): angular.IPromise<WebApi.AccessUser[]> {
        let $expand = "Employee($select=Name),Employee($expand=Filial($select=Name)),";
        $expand    += "Employee($expand=IntermediateSeller($select=Name)),";
        $expand    += "Employee($expand=PointOfSale($select=Name)),Employee($expand=Position($select=Name))";
        const odataParams: IODataParams = {
            $count: true,
            $expand,
            $filter: this.getFilterParams(params),
            $format: "json",
            $orderby: "Employee/Filial/Name asc",
            $skip: (params.page() - 1) * params.count(),
            $top: params.count(),
            _forceNoImpersonate: true,
        };
        return this.employeeResource.query(odataParams).$promise.then((data) => {
            params.total(data["@odata.count"]);
            return data.value;
        });
    }

    public selectItem(selected: WebApi.AccessUser) {
        this.Login = selected.Login;
    }

    public getFilterValue(value: string): string {
        return value.replace(/'|"/g, "");
    }

    public close(): void {
        this.$uibModalInstance.close();
    }

    public initFilials(): angular.IPromise<Filial[]> {
        const odataParams = {
            $count: null,
            $filter: null,
            $format: "json",
            $orderby: "Name asc",
            $skip: 0,
        };
        return this.filialService.query(odataParams).$promise.then((data) => {
            this.filials = data.value;
            return data.value;
        });
    }

    public getFilterParams(params: NgTableParamsType<WebApi.AccessUser>) {
        const filter: string[] = [];
        if (params.filter().Filial || params.filter().Filial === 0) {
            filter.push(`Employee/Filial/Id eq ${params.filter().Filial}`);
        }
        if (params.filter().Employee) {
            filter.push(`indexof(tolower(Employee/Name), '${this.getFilterValue(params.filter().Employee)}') gt -1`);
        }
        if (params.filter().IntermediateSeller) {
            // tslint:disable-next-line:max-line-length
            const line = `indexof(tolower(Employee/IntermediateSeller/Name), '${this.getFilterValue(params.filter().IntermediateSeller)}') gt -1`;
            filter.push(line);
        }
        if (params.filter().PointOfSale) {
            // tslint:disable-next-line:max-line-length
            const line = `indexof(tolower(Employee/PointOfSale/Name), '${this.getFilterValue(params.filter().PointOfSale)}') gt -1`;
            filter.push(line);
        }
        if (params.filter().Position) {
            // tslint:disable-next-line:max-line-length
            const line = `indexof(tolower(Employee/Position/Name), '${this.getFilterValue(params.filter().Position)}') gt -1`;
            filter.push(line);
        }
        return filter.length > 0 ? `(${filter.join(" and ")})` : null;
    }

    public changeUser(): void {
        this.helpers.changeUser(this.Login).then(() => {
            this.close();
            return this.$state.reload();
        }).then(() => {
            this.notifyService.successMessage("Имперсонализация", "Вы успешно вошли под другим пользователем");
            this.employeeService.event.emit("impersonalizated");
        });
    }
}
