import { ProductsJournalController } from "../../journal/journal.controller";

export class PersonalAccidentJournalController extends ProductsJournalController {
    public $onInit(): void {
        /* tslint:disable: object-literal-sort-keys */
        this.columns = [{
            defaultFilterOperation: "startswith",
            displayText: "Серия-номер полиса",
            inlineFilter: true,
            name: "PolicyNumber",
            pinned: true,
            renderer: (item) => item.PolicyFullValue,
            tooltipText: "Поиск только<br/>по номеру",
            type: "Edm.String",
        }, {
            name: "InsuredName",
            displayText: "Страхователь",
            width: 2,
            pinned: true,
            defaultFilterOperation: "startswith",
            inlineFilter: true,
            type: "Edm.String",
        }, {
            name: "SigningDate",
            displayText: "Дата заключения договора",
            ngFilter: this.dateFormatWithoutTimeZone,
            pinned: true,
            defaultFilterOperation: "eq",
            inlineFilter: true,
            type: "Edm.DateTimeOffset",
        }, {
            name: "ContractFrom",
            displayText: "Начало срока страхования",
            ngFilter: this.dateFormatWithoutTimeZone,
            inlineFilter: true,
            type: "Edm.DateTimeOffset",
        }, {
            name: "ContractTo",
            displayText: "Окончание срока страхования",
            ngFilter: this.dateFormatWithoutTimeZone,
            inlineFilter: true,
            type: "Edm.DateTimeOffset",
        }, {
            name: "InsurancePremium",
            displayText: "Страховая премия",
            ngFilter: "thousand",
            inlineFilter: true,
            type: "Edm.Int32",
        }, {
            name: "EmployeeFilialName",
            displayText: "Филиал",
            pinned: true,
            defaultFilterOperation: "startswith",
            inlineFilter: true,
            type: "Edm.String",
        }, {
            name: "EmployeeName",
            displayText: "Пользователь",
            inlineFilter: true,
            type: "Edm.String",
        }, {
            name: "SaleChannel",
            displayText: "Канал продаж",
            pinned: true,
            defaultFilterOperation: "startswith",
            inlineFilter: true,
            type: "Edm.String",
        }, {
            name: "StatusName",
            displayText: "Статус договора",
            getVariants: () => this.getStatusVariants(),
            pinned: true,
            defaultFilterOperation: "eq",
            inlineFilter: true,
            type: "Edm.String",
            template: `
                <span
                    ng-bind="item.StatusName"
                    ng-class="vm.getContractStatusLabelClass(item)">
                </span>
            `,
        }, {
            name: "EmployeeSubdivisionName",
            displayText: "Подразделение",
            visible: false,
            inlineFilter: true,
            defaultFilterOperation: "startswith",
            type: "Edm.String",
        }, {
            name: "AddressUserDefinedAddress",
            displayText: "Адрес объекта страхования",
            type: "Edm.String",
            visible: false,
        }, {
            name: "PaymentKindName",
            displayText: "Вид оплаты",
            type: "Edm.String",
            visible: false,
        }, {
            name: "Id",
            displayText: "Id-договора",
            type: "Edm.Int32",
            visible: false,
        }, {
            displayText: "Серия и номер полиса",
            name: "PolicyFullValue",
            type: "Edm.String",
            visible: false,
        }];
        /* tslint:enable: object-literal-sort-keys */
        super.$onInit();
    }
}
