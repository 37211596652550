import moment from "moment";

import { BoxAccidentContractDTOBase, BoxAccidentPersonBase } from "../boxAccident.factory";

export class FamilyAccidentContractDTO extends BoxAccidentContractDTOBase {
    constructor(prototype) {
        super(prototype);
        this.InsuranceProductId = 18;
    }
}

export class FamilyAccidentInsuredPerson extends BoxAccidentPersonBase {
    constructor() {
        super();
        this.InsuredPersonBirthday = moment().subtract(30, "year").format("YYYY-MM-DD");
    }
}
