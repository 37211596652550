import {
    IRefreshPaymentPayload,
    IReliableDriveContract,
    IReliableDriveError,
    IReliableDriveService,
} from "application/osago/types";
import IDTO from "infrastructure/interfaces/IDTO";
import automapper from "infrastructure/services/automapper";
import { Uuid } from "lib/uuid";
import moment from "moment";

const contractResourceProperty = Symbol("contractResourceProperty");
const errorsProperty = Symbol("errorsProperty");
const isActiveProperty = Symbol("isActiveProperty");
const isUserChoiceProperty = Symbol("isUserChoiceProperty");
const isManualCancelProperty = Symbol("isManualCancelProperty");

export class ReliableDriveContract implements IReliableDriveContract {
    public Id: number = 0;
    public OsagoContractGuid: string = "";
    public ContractGuid: string = Uuid.raw();
    public Status: number = 1; // 1 - Черновик, 2 - Оформлен
    public Serial: string = null;
    public Number: string = null;
    public SignDate: string = moment().format("YYYY-MM-DD");
    public ContractFrom: string = null;
    public ContractTo: string = null;
    public Premium: number = null;
    public IsPaid: boolean = false;
    private [contractResourceProperty]: IReliableDriveService;
    private [errorsProperty]: IReliableDriveError[] = [];
    private [isActiveProperty] = false;
    private [isUserChoiceProperty] = false;
    private [isManualCancelProperty] = false;

    public static fromDTO(dto: IDTO): ReliableDriveContract {
        if (!dto) {
            throw new Error();
        }
        return automapper.map(Object, ReliableDriveContract, dto);
    }

    public toDTO(): IReliableDriveContract {
        return automapper.map(ReliableDriveContract, "DTO", this);
    }

    public isSigned(): boolean {
        return this.Status === 2;
    }

    public isDraft(): boolean {
        return this.Status === 1;
    }

    public setResourceService(service: IReliableDriveService) {
        this[contractResourceProperty] = service;
    }

    public getResourceService(): IReliableDriveService {
        return this[contractResourceProperty];
    }

    public async $loadByOsagoGuid(osagoContractGuid: string): Promise<ReliableDriveContract> {
        this[errorsProperty] = [];

        const result = await this.getResourceService().getByGuid({ Guid: osagoContractGuid }).$promise;

        if (result.Content === null) {
            if (result.HasCriticalErrors || result.HasCriticalErrors) {
                this.addErrors(result.Errors);
            }
            this.initEmptyContract(osagoContractGuid);
        } else {
            this.init(ReliableDriveContract.fromDTO(result.Content));

            const state = await this.getResourceService().getActivity({ guid: this.OsagoContractGuid }).$promise;
            this[isActiveProperty] = state.IsActive;
            this[isManualCancelProperty] = state.IsManualCancel;
            this[isUserChoiceProperty] = state.IsUserChoice;
        }

        return this;
    }

    public async $forceCalc(premium: number): Promise<void> {
        this[errorsProperty] = [];

        const payload = {
            OsagoContractGuid: this.OsagoContractGuid,
            Premium: premium,
        };

        const result = await this.getResourceService().create(payload).$promise;

        if (result.Content) {
            await this.$loadByOsagoGuid(this.OsagoContractGuid);
        }
        if (result.HasCriticalErrors || result.HasErrors) {
            this.addErrors(result.Errors);
        }
    }

    public init(data: IReliableDriveContract) {
        for (const key in data) {
            if (!data.hasOwnProperty(key)) {
                continue;
            }
            //  ignore special properties like "$promise"
            if (key[0] === "$") {
                continue;
            }
            const value = data[key];
            if (value !== null) {
                this[key] = value;
            }
        }
    }

    public addErrors(errors: IReliableDriveError[]): void {
        this[errorsProperty].push(...errors);
    }

    public getErrors(): IReliableDriveError[] {
        return this[errorsProperty];
    }

    public async $cancelContract(): Promise<ReliableDriveContract> {
        if (this.Id > 0) {
            await this.getResourceService()
                .cancel({
                    guid: this.OsagoContractGuid,
                }).$promise;

            return this.$loadByOsagoGuid(this.OsagoContractGuid);
        }
    }

    public async $deactivateContract(): Promise<ReliableDriveContract> {
        if (this.isUserChoice()) {
            await this.getResourceService().deactivate({ guid: this.OsagoContractGuid }).$promise;
            return this.$loadByOsagoGuid(this.OsagoContractGuid);
        }
    }

    public async $activateContract(): Promise<void> {
        await this.getResourceService().activate({ guid: this.OsagoContractGuid }).$promise;
    }

    public isActive(): boolean {
        return this[isActiveProperty];
    }

    public isUserChoice(): boolean {
        return this[isUserChoiceProperty];
    }

    public isManualCancel(): boolean {
        return this[isManualCancelProperty];
    }

    public initEmptyContract(osagoContractGuid: string): void {
        this.ContractFrom = null;
        this.ContractGuid = Uuid.raw();
        this.ContractTo = null;
        this.Id = 0;
        this.IsPaid = false;
        this.Number = null;
        this.OsagoContractGuid = osagoContractGuid;
        this.Premium = null;
        this.Serial = null;
        this.SignDate = moment().format("YYYY-MM-DD");
        this.Status = 1;
        this[isActiveProperty] = false;
        this[isUserChoiceProperty] = false;
        this[isManualCancelProperty] = false;
    }

    public async $refreshPaymentStatus(signingDate?: string): Promise<void> {
        const payload: IRefreshPaymentPayload = {
            id: this.Id,
        };

        if (signingDate) {
            payload.signDate = signingDate;
        }

        await this.getResourceService().refreshPayment(payload).$promise;
        await this.$loadByOsagoGuid(this.OsagoContractGuid);
    }
}
