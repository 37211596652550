import { IPromise, IIntervalService } from "angular";
import { OsagoContract } from "application/osago/classes/OsagoContract";
import { EventEmitter } from "events";
import { IAsyncResource, IOsagoSaveResponse } from "infrastructure/interfaces";
import { IOsagoCalculateResult } from "infrastructure/interfaces/IAsyncResource";
import { data } from "jquery";

/*
асинхронный расчет
post http://localhost:52649/async/OsagoCalculate    body:ContractDTO
put http://localhost:52649/async/OsagoCalculate?id=<id договора>&isForced=false    body:ContractDTO
get http://localhost:52649/async/OsagoCalculate?taskId=1212
*/

class AsyncIntervalRunner {
    public currentInterval: IPromise<any>;
    private refreshInterval: number = 1e3;
    private mapInterval = [
        2e3,
        3e3,
        5e3,
        5e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
        10e3,
    ]; // = 335e3
    private currentTime = 0;
    private attrResponse: string[] = [];
    private emmiter: EventEmitter;
    private $interval: IIntervalService;
    private currentWorkStage: number = null;
    private alreadyInProgress = false;
    private runner: () => IPromise<IOsagoCalculateResult>;

    constructor({
        runner,
        $interval,
        response,
        emmiter,
    }: {
        runner: () => IPromise<IOsagoCalculateResult>;
        $interval: IIntervalService;
        response: string[];
        emmiter: EventEmitter;
    }) {
        this.runner = runner;
        this.$interval = $interval;
        this.attrResponse = response;
        this.emmiter = emmiter;
        this.run();
    }

    private runing(): void {
        this.alreadyInProgress = true;
        this.runner()
            .then((response) => {
                const workStage = response.Header.WorkStage;
                if (this.currentWorkStage !== workStage) {
                    this.currentWorkStage = workStage;
                    this.emmiter.emit("changeWorkStage", workStage);
                }

                if (this.mapInterval.length === 0 && response.Header.Complete === false) {
                    this.emmiter.emit("onResult", {
                        message: "Время ожидания результата превышено",
                        status: 404,
                    });
                }

                if (response.Header.Complete === false) {
                    return;
                } else {
                    this.clear();
                    const filterResponse = {};
                    for (const attr of this.attrResponse) {
                        filterResponse[attr] = response[attr];
                    }
                    this.emmiter.emit("onResult", {
                        response: filterResponse,
                        status: 200,
                    });
                }
            })
            .catch((response) => {
                this.emmiter.emit("onResult", {
                    // tslint:disable-next-line: max-line-length
                    message:
                        "Произошла критическая ошибка, пожалуйста, сообщите об этом! Номер телефона для обращений в рабочие часы: 8 (3467) 357-220 (Служба Технической Поддержки). В нерабочие часы: 8-800-100-82-00 (Федеральный Контакт-Центр)",
                    status: response.status,
                });
            })
            .finally(() => {
                this.alreadyInProgress = false;
            });
    }
    private run() {
        this.currentInterval = this.$interval(() => {
            this.currentTime += this.refreshInterval;
            if (this.runner !== undefined && typeof this.runner === "function") {
                if (this.mapInterval.length > 0 && this.mapInterval[0] < this.currentTime && !this.alreadyInProgress) {
                    const time = this.mapInterval.shift();
                    this.currentTime -= time;
                    this.runing();
                }
            }
        }, this.refreshInterval);
    }
    private clear() {
        this.$interval.cancel(this.currentInterval);
        this.alreadyInProgress = false;
        delete this.runing;
    }
}

export class AsyncContractSaleService {
    public static get $inject() {
        return ["asyncResource", "$interval"];
    }

    constructor(private asyncResource: IAsyncResource, private $interval: IIntervalService) {}

    public cancelOsago(contractId: number): EventEmitter {
        const emmiter = new EventEmitter();

        this.asyncResource.osagoCancel({ contractId }).$promise.then(({ taskId }) => {
            if (!isNaN(taskId)) {
                this.createQueryCancelChain(taskId, emmiter);
            } else {
                emmiter.emit("onResult", {
                    // tslint:disable-next-line: max-line-length
                    message:
                        "Произошла критическая ошибка, пожалуйста, сообщите об этом! Номер телефона для обращений в рабочие часы: 8 (3467) 357-220 (Служба Технической Поддержки). В нерабочие часы: 8-800-100-82-00 (Федеральный Контакт-Центр)",
                    status: 520,
                });
            }
        }).catch((reason) => {
            emmiter.emit("onResult", {
                message: reason.data.message ? reason.data.message : "Произошла неизвестная ошибка",
                status: 404,
            });
        });
        return emmiter;
    }

    public annulOsago(contractId: number): EventEmitter {
        const emmiter = new EventEmitter();

        this.asyncResource.osagoAnnul({ contractId }).$promise.then(({ taskId }) => {
            if (!isNaN(taskId)) {
                this.createQueryAnnulChain(taskId, emmiter);
            } else {
                emmiter.emit("onResult", {
                    // tslint:disable-next-line: max-line-length
                    message:
                        "Произошла критическая ошибка, пожалуйста, сообщите об этом! Номер телефона для обращений в рабочие часы: 8 (3467) 357-220 (Служба Технической Поддержки). В нерабочие часы: 8-800-100-82-00 (Федеральный Контакт-Центр)",
                    status: 520,
                });
            }
        }).catch((reason) => {
            emmiter.emit("onResult", {
                message: reason.data.message ? reason.data.message : "Произошла неизвестная ошибка",
                status: 404,
            });
        });;
        return emmiter;
    }

    public saveOsago(contract: OsagoContract, isForced = false): EventEmitter {
        const emmiter = new EventEmitter();

        let resource;
        if (contract.isBlank()) {
            resource = this.asyncResource.osagoCreate({}, contract.toDTO()).$promise;
        } else {
            resource = this.asyncResource.osagoUpdate(
                {
                    id: contract.Id,
                    isForced,
                },
                contract.toDTO()
            ).$promise;
        }
        resource
            .then((data: IOsagoSaveResponse) => {
                if (!isNaN(data.TaskId)) {
                    if (data.PreviousTaskInProgress === true) {
                        emmiter.emit("alreadyInProgress", data.TaskId);

                        if (data.TaskId === 0) {
                            return;
                        }
                    }

                    this.createQueryCalculateChain(data.TaskId, emmiter);
                } else {
                    emmiter.emit("onResult", {
                        // tslint:disable-next-line: max-line-length
                        message:
                            "Произошла критическая ошибка, пожалуйста, сообщите об этом! Номер телефона для обращений в рабочие часы: 8 (3467) 357-220 (Служба Технической Поддержки). В нерабочие часы: 8-800-100-82-00 (Федеральный Контакт-Центр)",
                        status: 520,
                    });
                }
            })
            .catch((e) => {
                emmiter.emit("onResult", {
                    message: e.statusText,
                    status: e.status,
                });
            });

        return emmiter;
    }

    public signOsago(contract: OsagoContract): EventEmitter {
        const emmiter = new EventEmitter();

        this.asyncResource
            .osagoSign({ contractId: contract.Id })
            .$promise.then((data) => {
                const taskId = parseInt(String(data.taskId), 10);
                if (!isNaN(taskId)) {
                    this.createQuerySignChain(taskId, emmiter);
                } else {
                    emmiter.emit("onResult", {
                        // tslint:disable-next-line: max-line-length
                        message:
                            "Произошла критическая ошибка, пожалуйста, сообщите об этом! Номер телефона для обращений в рабочие часы: 8 (3467) 357-220 (Служба Технической Поддержки). В нерабочие часы: 8-800-100-82-00 (Федеральный Контакт-Центр)",
                        status: 520,
                    });
                }
            })
            .catch((e) => {
                emmiter.emit("onResult", {
                    message: e.statusText,
                    status: e.status,
                });
            });

        return emmiter;
    }

    // ----sign throught draft----
    public signOsagoThroughtDraft(contract: OsagoContract): EventEmitter {
        const emmiter = new EventEmitter();

        this.asyncResource
            .osagoSignThroughtDraft({ contractId: contract.Id })
            .$promise.then((data) => {
                if (!isNaN(data.TaskId)) {
                    if (data.PreviousTaskInProgress === true) {
                        emmiter.emit("alreadyInProgress", data.TaskId);

                        if (data.TaskId === 0) {
                            return;
                        }
                    }

                    this.createQuerySignChainTrhoughtDraft(data.TaskId, emmiter);
                } else {
                    emmiter.emit("onResult", {
                        // tslint:disable-next-line: max-line-length
                        message:
                            "Произошла критическая ошибка, пожалуйста, сообщите об этом! Номер телефона для обращений в рабочие часы: 8 (3467) 357-220 (Служба Технической Поддержки). В нерабочие часы: 8-800-100-82-00 (Федеральный Контакт-Центр)",
                        status: 520,
                    });
                }
            })
            .catch((e) => {
                emmiter.emit("onResult", {
                    message: e.statusText,
                    status: e.status,
                });
            });

        return emmiter;
    }
    // -----

    // ---sign through formed---
    public signOsagoThroughtFormed(contract: OsagoContract): EventEmitter {
        const emmiter = new EventEmitter();

        this.asyncResource
            .osagoSignThroughtFormed({ contractId: contract.Id })
            .$promise.then((data) => {
                if (!isNaN(data.TaskId)) {
                    if (data.PreviousTaskInProgress === true) {
                        emmiter.emit("alreadyInProgress", data.TaskId);

                        if (data.TaskId === 0) {
                            return;
                        }
                    }

                    this.createQuerySignChainTrhoughtFormed(data.TaskId, emmiter);
                } else {
                    emmiter.emit("onResult", {
                        // tslint:disable-next-line: max-line-length
                        message:
                            "Произошла критическая ошибка, пожалуйста, сообщите об этом! Номер телефона для обращений в рабочие часы: 8 (3467) 357-220 (Служба Технической Поддержки). В нерабочие часы: 8-800-100-82-00 (Федеральный Контакт-Центр)",
                        status: 520,
                    });
                }
            })
            .catch((e) => {
                emmiter.emit("onResult", {
                    message: e.statusText,
                    status: e.status,
                });
            });

        return emmiter;
    }

    public signOsagoOffline(contract: OsagoContract): EventEmitter {
        const emmiter = new EventEmitter();

        this.asyncResource
            .osagoSignOffline({ contractId: contract.Id })
            .$promise.then((data) => {
                if (!isNaN(data.TaskId)) {
                    if (data.PreviousTaskInProgress === true) {
                        emmiter.emit("alreadyInProgress", data.TaskId);

                        if (data.TaskId === 0) {
                            return;
                        }
                    }

                    this.createQuerySignChainOffline(data.TaskId, emmiter);
                } else {
                    emmiter.emit("onResult", {
                        // tslint:disable-next-line: max-line-length
                        message:
                            "Произошла критическая ошибка, пожалуйста, сообщите об этом! Номер телефона для обращений в рабочие часы: 8 (3467) 357-220 (Служба Технической Поддержки). В нерабочие часы: 8-800-100-82-00 (Федеральный Контакт-Центр)",
                        status: 520,
                    });
                }
            })
            .catch((e) => {
                emmiter.emit("onResult", {
                    message: e.statusText,
                    status: e.status,
                });
            });

        return emmiter;
    }

    private createQuerySignChainTrhoughtDraft(taskId: number, emmiter: EventEmitter) {
        return new AsyncIntervalRunner({
            emmiter,
            $interval: this.$interval,
            response: ["Header", "MessageObject", "Errors"],
            runner: () => this.asyncResource.osagoSignResultThroughtDraft({ taskId }).$promise,
        });
    }

    private createQuerySignChainTrhoughtFormed(taskId: number, emmiter: EventEmitter) {
        return new AsyncIntervalRunner({
            emmiter,
            $interval: this.$interval,
            response: ["Header", "MessageObject", "Errors"],
            runner: () => this.asyncResource.osagoSignResultThroughtFormed({ taskId }).$promise,
        });
    }

    private createQuerySignChainOffline(taskId: number, emmiter: EventEmitter) {
        return new AsyncIntervalRunner({
            emmiter,
            $interval: this.$interval,
            response: ["Header", "MessageObject", "Errors"],
            runner: () => this.asyncResource.osagoSignResultOffline({ taskId }).$promise,
        });
    }
    // -------

    private createQuerySignChain(taskId: number, emmiter: EventEmitter) {
        return new AsyncIntervalRunner({
            emmiter,
            $interval: this.$interval,
            response: ["Header", "MessageObject", "Errors"],
            runner: () => this.asyncResource.osagoSignResult({ taskId }).$promise,
        });
    }

    private createQueryCalculateChain(taskId: number, emmiter: EventEmitter) {
        return new AsyncIntervalRunner({
            emmiter,
            $interval: this.$interval,
            response: ["Content", "Header", "MessageObject", "Errors"],
            runner: () => this.asyncResource.osagoCalculateResult({ taskId }).$promise,
        });
    }

    private createQueryCancelChain(taskId: number, emmiter: EventEmitter) {
        return new AsyncIntervalRunner({
            emmiter,
            $interval: this.$interval,
            response: ["Content", "Header", "MessageObject", "Errors"],
            runner: () => this.asyncResource.osagoCancelResult({ taskId }).$promise,
        });
    }

    private createQueryAnnulChain(taskId: number, emmiter: EventEmitter) {
        return new AsyncIntervalRunner({
            emmiter,
            $interval: this.$interval,
            response: ["Content", "Header", "MessageObject", "Errors"],
            runner: () => this.asyncResource.osagoAnnulResult({ taskId }).$promise,
        });
    }
}
