import * as angular from "angular";
import { Contract } from "domain/classes/contract.class";
import IDTO from "infrastructure/interfaces/IDTO";
import { IPaymentVariantRepositoryItem } from "infrastructure/interfaces/IPaymentVariantRepositoryItem";
import { Int } from "./types";

export interface IRepositoryItem<T> {
    Collection: T[];
    FieldName?: string;
    State?: number;
    Value?: IAPIRepositoryItem[];
    DefaultValue?: any;
}
export interface IAPIRepositoryItem {
    FieldName: string;
    Value: any;
}
export interface IAPIRepository {
    CollectionSource: Array<{
        CollectionSource: IAPIRepositoryItem[];
        State: number;
    }>;
    FieldName: string;
    State: number;
    Value: IAPIRepositoryItem[];
}

export interface IRepositoryResource {
    loadRepository(params: {
        contract: IDTO,
        fieldName?: string,
    }): ng.resource.IResource<IAPIRepository[]>;
}

export interface IPolicySerialRepository {
    PolicySerialId: Int;
    PolicySerial: string;
}
export default class Repository {
    public PolicySerial: IRepositoryItem<IPolicySerialRepository>;
    public PolicyPrefix: IRepositoryItem<{

    }>;
    public VehicleCountry: IRepositoryItem<{
        VehicleCountryId: Int;
        VehicleCountryName: string;
    }>;
    public PaymentVariant: IRepositoryItem<IPaymentVariantRepositoryItem>;
    public DriverLicenseIssueCountry: IRepositoryItem<{
        DriverLicenseIssueCountryId: Int;
        DriverLicenseIssueCountryName: string;
    }>;
    public LapsedPayment: IRepositoryItem<{
        LapsedPaymentId: Int;
        LapsedPaymentCount: Int;
    }>;
    [s: string]: IRepositoryItem<{
        [s: string]: Int | string | any;
    }> | ((arg0, arg1, arg2) => any) | IRepositoryResource;
    constructor(private resource: IRepositoryResource) {
    }
    public parseData(data: IAPIRepository[]): void {
        const $this = this;
        angular.forEach(data, (element) => {
            $this[element.FieldName] = {
                Collection: [],
                FieldName: element.FieldName,
                State: element.State,
                Value: element.Value,
            };

            angular.forEach(element.CollectionSource, (collectionElement) => {
                const item: any = {};
                angular.forEach(collectionElement.CollectionSource, (subElement) => {
                    item[subElement.FieldName] = subElement.Value;
                });
                item.State = collectionElement.State;
                ($this[element.FieldName] as IRepositoryItem<any>).Collection.push(item);
                /**
                 * Не использовать в разработке DefaultValue!
                 */
                if (element.Value) {
                    ($this[element.FieldName] as IRepositoryItem<any>).DefaultValue = element.Value[0].Value;
                }
            });
        });
    }
    public getAvailableValuesOf(fieldName: string) {
        return (this[fieldName] as IRepositoryItem<any>).Collection.map((item) => item[fieldName]);
    }
    public load(dto: IDTO) {
        return this.resource.loadRepository({ contract: dto }).$promise.then((data) => {
            // $log.debug("Репозиторий загружен");
            this.parseData(data);
            return data;
        });
    }
    public loadAdditionalRepository(contract: Contract, fieldName: string): angular.IPromise<any> {
        return this.resource.loadRepository({ contract, fieldName }).$promise.then((data) => {
            // $log.debug("Дополнительный репозиторий загружен");
            this.parseData(data);
            return data;
        });
    }
    public update(fieldName: string, contract: IDTO, notUpdatingContract: boolean = false): angular.IPromise<any> {
        return this.resource.loadRepository({ contract, fieldName }).$promise.then((data) => {
            //  $log.debug("Репозиторий обновлён");
            this.parseData(data);
            if (!notUpdatingContract) {
                contract.setFromRepository(data);
            }
            return data;
        });
    }
}
