import { DateTimeOffset, Day } from "infrastructure/types";
import * as Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);
export interface IPeriodAddDate {
    date: DateTimeOffset;
    count: Moment.DurationInputArg1;
    unitOfTime: Moment.unitOfTime.DurationConstructor;
    endOf?: Moment.unitOfTime.StartOf;
}
export interface IPeriodRangeDiff {
    From: DateTimeOffset;
    To: DateTimeOffset;
    unitOfTime: Moment.unitOfTime.Diff;
    precise?: boolean;
}
export class DatePeriod {

    get inMonthes() {
        return DatePeriod.getRange({From: this.From, To: this.To, unitOfTime: "months"});
    }
    set inMonthes(val: number) {
        if (val === 0) {
            this.setUsagePeriodInMonth(5, "days");
        } else {
            this.setUsagePeriodInMonth(val);
        }
    }

    public static getAddDate({ date, count, unitOfTime, endOf = "day" }: IPeriodAddDate) {
        return moment(date).add(count, unitOfTime).endOf(endOf);
    }

    public static getRange({ From, To, unitOfTime, precise = true }: IPeriodRangeDiff) {
        const dur = moment.range(moment(From), moment(To));
        return Math.floor(dur.diff(unitOfTime, precise) % 1 === 0 ? dur.diff(unitOfTime) : +dur.diff(unitOfTime) + 1);
    }

    public static setPrevDay(From: DateTimeOffset) {
        return moment(From)
            .startOf("day")
            .subtract(1, "second");
    }

    public fromMinDate: Day | boolean = false;
    public fromMaxDate: Day | boolean = false;
    public toMinDate: Day | boolean = false;

    constructor(public From: DateTimeOffset, public To: DateTimeOffset) {
    }

    public rangeGreaterThenOneMonth() {
        return DatePeriod.getRange({From: this.From, To: this.To, unitOfTime: "months"}) >= 1;
    }

    public setUsagePeriodInMonth(monthsCount: number, dimension: Moment.DurationInputArg2 = "month") {
        this.To = DatePeriod.setPrevDay(this.From).add(monthsCount, dimension).format();
    }

    public fromMonthes(To: DateTimeOffset, precise = true): number {
        return DatePeriod.getRange({From: DatePeriod.setPrevDay(this.From).format(), To, unitOfTime: "months", precise});
    }

    public toMonthes(To: DateTimeOffset, precise = true): number {
        return DatePeriod.getRange({From: this.To, To, unitOfTime: "months", precise});
    }

    // last day with time is 23:59:59.999 after startOf 23:59:59.000
    public setLastDay(From = this.From, unitOfTime: Moment.unitOfTime.StartOf = "year") {
        this.To = moment.parseZone(From).endOf(unitOfTime).startOf("second").format();
        return this;
    }
}
