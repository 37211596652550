import moment from "moment";

import { BoxAccidentContractDTOBase, BoxAccidentPersonBase } from "../boxAccident.factory";

export class ChildAccidentContractDTO extends BoxAccidentContractDTOBase {
    constructor(prototype) {
        super(prototype);
        this.InsuranceProductId = 17;
    }
}

export class ChildAccidentInsuredPerson extends BoxAccidentPersonBase {
    constructor() {
        super();
        this.InsuredPersonBirthday = moment().subtract(10, "year").format("YYYY-MM-DD");
    }
}
