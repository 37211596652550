import angular from "angular";

export function DropdownAutoPositionDirective(
    $window: ng.IWindowService,
    $document: ng.IDocumentService,
): ng.IDirective {
    "ngInject";

    return {
        link: (
            scope: ng.IScope,
            element: ng.IAugmentedJQuery,
            attributes: ng.IAttributes,
        ) => {
            let heightBottomToolbar = 0;
            let heightTopToolbar = 0;
            const options = {
                offsetBottom: 110,
                offsetTop: 70,
            };

            const calculate = (calcElement: ng.IAugmentedJQuery) => {
                if (attributes.hasOwnProperty("offsetBottom")) {
                    if (!attributes.offsetBottom) {
                        heightBottomToolbar = options.offsetBottom;
                    } else {
                        heightBottomToolbar = parseInt(attributes.offsetBottom, 10);
                    }
                } else {
                    heightBottomToolbar = options.offsetBottom;
                }
                if (attributes.hasOwnProperty("offsetTop")) {
                    if (!attributes.offsetTop) {
                        heightTopToolbar = options.offsetTop;
                    } else {
                        heightTopToolbar = parseInt(attributes.offsetTop, 10);
                    }
                } else {
                    heightTopToolbar = options.offsetTop;
                }

                const fullScreenHeight = $window.innerHeight;
                const visibleScreenHeight = fullScreenHeight - (heightBottomToolbar + heightTopToolbar);
                const distanceFromButtom = visibleScreenHeight - (
                    calcElement[0].getBoundingClientRect().top - heightTopToolbar
                );

                return {
                    distanceFromButtom,
                    distanceFromTop: calcElement[0].getBoundingClientRect().top - heightTopToolbar,
                };
            };

            const openUpDropdown = () => {
                if (!element.hasClass("dropup")) {
                    element.addClass("dropup");
                } else {
                    element.removeClass("dropup");
                    element.addClass("dropup");
                }
            };

            const positionDropdown = () => {
                const heightDropdownMenu = parseInt(element.find("ul.dropdown-menu").css("height"), 10);
                const calcDistance = calculate(element);

                if (calcDistance.distanceFromButtom > heightDropdownMenu) {
                    element.removeClass("dropup");
                    return;
                }
                if (calcDistance.distanceFromTop > heightDropdownMenu) {
                    openUpDropdown();
                    return;
                }
                if (calcDistance.distanceFromButtom > calcDistance.distanceFromTop) {
                    element.removeClass("dropup");
                    return;
                }
                openUpDropdown();
            };
            angular.element($document).bind("scroll", positionDropdown);
            angular.element($window).bind("resize", positionDropdown);
        },
        restrict: "A",
    };
}
