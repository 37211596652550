
import { Helpers } from "infrastructure/app.helpers";
import integrationInfoTemplate from "infrastructure/extraMenuOptionsPresets/integrationInfo.html"
import IBlockUI from "infrastructure/interfaces/IBlockUI";
import { IIntegrationInfo } from "infrastructure/interfaces/IIntegrationInfo";

export class IntegrationInfo {
    public helpers: Helpers;
    public blockUI: IBlockUI;
    constructor(helpers: Helpers, blockUI: IBlockUI) {
        "ngInject";
        this.helpers = helpers;
        this.blockUI = blockUI;
    }
    public showInfoWindow(getIntegrationInfo) {
        this.blockUI.start("Загрузка данных");
        getIntegrationInfo().then((rows: IIntegrationInfo[]) => {
            this.helpers.showInformationWindow(integrationInfoTemplate, "Инфо ЦССУ", "", rows);
        }).finally(() => {
            this.blockUI.stop();
        });
    }
}
