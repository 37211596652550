import { IPromise, IQService } from "angular";
import { EventEmitter } from "events";
import { capitalizeFirstLetter } from "infrastructure/app.helpers";
import { IEmployeeResource } from "infrastructure/interfaces";
import IUGSKUserContext from "infrastructure/interfaces/IUGSKUserContext";
import { NgControllerBase } from "infrastructure/NgController";
import { ngStorage } from "ngstorage";

class IEmployeeStorage {
    public ImpersonateLogin: string;
    public ImpersonateLoginFlag: string;
    public employeeInfo: IUGSKUserContext;
    public employeeData: any;
}

export class AbstractEmployee {
    constructor(protected storage: IEmployeeStorage = new IEmployeeStorage()) {}
    public isLoaded() {
        return Boolean(this.info);
    }
    /**
     * Проверка наличия разрешений на выполнение действия с продуктом
     * @param  {String}  permission  Название разрешения
     * @param  {String}  productCode код продукта
     * @return {Boolean}             Наличие разрешения
     */
    public hasPermission(permission: string, productCode: string): boolean {
        if (!productCode || !permission) {
            throw new Error("invalid productCode");
        }
        if (!this.isLoaded()) {
            return false;
        }
        const capitalizedProductCode = capitalizeFirstLetter(productCode) as string;
        return this.info.Permissions.some((perm) => perm.Product === capitalizedProductCode && perm.Permission === permission);
    }

    get info() {
        return this.storage.employeeInfo;
    }

    set info(newEmployeeInfo) {
        this.storage.employeeInfo = newEmployeeInfo;
    }

    public deleteEmployeeInfo() {
        this.info = undefined;
    }

    get data() {
        return this.storage.employeeData;
    }

    set data(newEmployeeData) {
        this.storage.employeeData = newEmployeeData;
    }
}

export class Employee extends AbstractEmployee {
    get impersonateLogin() {
        return this.storage.ImpersonateLogin;
    }
    set impersonateLogin(login) {
        this.storage.ImpersonateLogin = login;
    }
    get impersonateLoginFlag() {
        return this.storage.ImpersonateLoginFlag;
    }
    set impersonateLoginFlag(flag) {
        this.storage.ImpersonateLoginFlag = flag;
    }
    /**
     * @override
     */
    public isLoaded() {
        return this.info && this.impersonateLogin === this.impersonateLoginFlag;
    }
    public isImpersonated() {
        return Boolean(this.impersonateLogin);
    }
    public canImpersonate() {
        return this.info && this.info.CanBeImpersonated;
    }
    public impersonate(login: string) {
        this.impersonateLogin = login;
        this.impersonateLoginFlag = login;
        this.deleteEmployeeInfo();
    }
    public deImpersonate() {
        this.impersonateLogin = undefined;
        this.impersonateLoginFlag = undefined;
        this.deleteEmployeeInfo();
    }
    public saveUserData(userData: { email: string; phone: string; IsEmailConfirmed: boolean }) {
        this.info.Email = userData.email;
        this.info.PhoneNumber = userData.phone;
        this.info.IsEmailConfirmed = userData.IsEmailConfirmed;
    }
    public addUserData(userData: any) {
        this.data.birthDate = userData.birthDate;
        this.data.gender = userData.gender;
        this.data.passportData = userData.passportData;
    }
}

export class OriginalEmployee extends AbstractEmployee {}

export class EmployeeService extends NgControllerBase {
    public event = new EventEmitter();
    private getEmployeeOperation: IPromise<Employee>;
    private employee: Employee;
    constructor($injector: angular.auto.IInjectorService) {
        super($injector);
        const $localStorage = this.di<ngStorage.StorageService>("$localStorage");
        //  @FIXME: Разобраться со способом хранения
        this.employee = new Employee(($localStorage as any) as IEmployeeStorage);
    }
    public getEmployee() {
        const $q = this.di<IQService>("$q");
        const employeeResource = this.di<IEmployeeResource>("employeeResource");
        const $log = this.di<angular.ILogService>("$log");
        const employee = this.employee;
        if (employee.isLoaded()) {
            return $q.resolve(employee);
        }
        if (this.getEmployeeOperation) {
            return this.getEmployeeOperation;
        }
        $log.debug("Информация о пользователе загружается");
        this.getEmployeeOperation = employeeResource.employeeInfo().$promise.then((info) => {
            info.AccessToken = undefined;
            employee.info = info;
            employee.impersonateLoginFlag = employee.impersonateLogin;
            $log.debug("Данные о пользователе получены");
            this.getEmployeeOperation = undefined;
            return $q.resolve(employee);
        });
        return this.getEmployeeOperation;
    }
    public resetEmployee() {
        const employee = this.employee;
        employee.deleteEmployeeInfo();
        employee.deImpersonate();
    }
}
