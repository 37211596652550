import { NgComponentController } from "infrastructure/NgController";

export class SmallDigestController extends NgComponentController {
    onInit() {
        const [
            dictionariesService,
        ] = this.di(["dictionariesService"]);

        dictionariesService.get("ContractStatuses").then(contractStatuses => {
            this.contractStatuses = contractStatuses;
        });
    }
    getDisplayProductName() {
        if (this.currentProduct.getContractBasedProductName) {
            return this.currentProduct.getContractBasedProductName(this.contract);
        }
        return this.currentProduct.productName;
    }
    getStatus() {
        if (this.contractStatuses) {
            const status = this.contractStatuses.find(item => item.Id === (this.contract.ContractStatusId || 1));
            if (status) return status.Name;
        }

        return "";
    }
    getContractPremium() {
        if (!this.contract.isFrozen() && this.contractModified) return "-";
        return this.contract.getPremium() || "-";
    }
    /**
     * @todo Убрать класс в дальнейшем, т.к. КБМ класса не будет. Будет толь коэф.
     */
    getKBM() {
        if (!this.contract.RSAKBMValue && !this.contract.KBMClass) return "";
        const labels = [];

        if (this.contract.RSAKBMValue) labels.push(`Коэф. КБМ: ${this.contract.RSAKBMValue}`);
        if (this.contract.KBMClass) labels.push(`КБМ: ${this.contract.KBMClass}`);

        return `| ${labels.join(" | ")}`;
    }
}
