import * as angular from "angular";
import Configuration from "domain/classes/Configuration.class";
export class EnvService {
    public configuration: Configuration = new Configuration();
    public read<T = any>(property: string): T {
        return this.configuration[property];
    }
    public setConfiguration(newConfiguration: Configuration): void {
        this.configuration = newConfiguration;
    }
    public getSnapshot(): Configuration {
        return angular.copy(this.configuration);
    }
}

const envService = new EnvService();

export default angular.module("app.service.env", [])
.provider("envService", function() {
    return {
        $get() {
            return envService;
        },
    };
 }).name;
