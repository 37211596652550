/**
 * @name infiniteScrollExtraLoader
 * @description Директива, позволяющая загружать данные (infiniteScroll)
 * @see http://sroze.github.io/ngInfiniteScroll/documentation.html
 * @example
 * <div infinite-scroll="..."
 *      infinite-scroll-listen-for-event="<infiniteScrollEventName>"
 *      infinite-scroll-extra-loader="<causalEventName>">...</div>
 * @argument {String} infiniteScrollEventName Имя события, которое слушает infiniteScroll для загрузки данных
 * @argument {String} causalEventName Имя события - причины
 */

import { Contract } from "domain/classes/contract.class";
import InfiniteScrollService from "infrastructure/services/infiniteScroll.service";

export function InfiniteScrollExtraLoaderDirective(
    $timeout: ng.ITimeoutService,
    $document: ng.IDocumentService,
    infiniteScroll: InfiniteScrollService<Contract>,
): ng.IDirective {
    "ngInject";

    return {
        link: (
            scope: ng.IScope,
            element: ng.IAugmentedJQuery,
            attributes: IAttributes,
        ) => {
            if (attributes.infiniteScrollListenForEvent && attributes.infiniteScrollExtraLoader) {
                scope.$on(attributes.infiniteScrollExtraLoader, () => {
                    const body = $document.find("body").get(0);
                    $timeout(() => {
                        if (infiniteScroll.canGetMore() && body.scrollHeight <= body.clientHeight) {
                            scope.$emit(attributes.infiniteScrollListenForEvent);
                        }
                    });
                });
            }
        },
        restrict: "A",
    };
}
