import { IElementType } from "domain/classes/movableProperty.class";
import Repository, { IRepositoryItem } from "./Repository.class";
import { Int } from "./types";

export class IFLRepository extends Repository {
    public MovablePropertyType: IRepositoryItem<IElementType>;
    public EngineeringEquipmentAgeRestriction: IRepositoryItem<{
        EngineeringEquipmentAgeRestrictionMaxValue: Int;
    }>;
    public PaymentDocumentSerial: IRepositoryItem<{
        PaymentDocumentSerial: string;
        PaymentDocumentSerialId: Int;
    }>;

}
