import { Transition } from "@uirouter/core";
import IBaseViewParams from "infrastructure/interfaces/IBaseViewParams";
import moment from "moment";
import { BaseToolbarController } from "../baseProduct.toolbar.controller";
import { IInsuranceProgram, TravelingAbroadContract, TravelingAbroadInsurantProxy } from "./travelingAbroad.factory";

const PrintOptions = [
    {
        icon: "fa-print",
        title() {
            return this.getPolicyPrintFormName("Полис (бланк БСО)");
        },
        action() {
            return this.printContract();
        },
        disabled() {
            return this.isDisabledPrint();
        },
        isVisible() {
            return this.policyWithA7IsAvailable;
        },
    },
    "policyWithoutA7",
    {
        icon: "fa-print",
        title: "Полис (экз. консульства)",
        action() {
            const printForm = {
                id: this.Contract.Id,
                docType: "Policy",
                fileNamePrefix: "Полис (экз. консульства)",
            };
            this.openPdf(printForm);
        },
        disabled() {
            return this.isDisabledPrint() || !this.Contract.IsVisaObtainingBasis;
        },
    }, {
        icon: "fa-print",
        title: "Приложение к Полису «Список застрахованных лиц»",
        action() {
            const printForm = {
                id: this.Contract.Id,
                docType: "InsuredPersonsApplication",
                fileNamePrefix: "Приложение к Полису «Список застрахованных лиц»",
            };
            this.openPdf(printForm);
        },
        disabled() {
            return this.isDisabledPrint() || this.Contract.InsuredPersons.length < 5;
        },
    }, {
        icon: "fa-print",
        title: "Заявление о страховании ФЛ",
        action() {
            const printForm = {
                id: this.Contract.Id,
                docType: "ApplicationRealPerson",
                fileNamePrefix: "Заявление о страховании ФЛ",
            };
            this.openPdf(printForm);
        },
        disabled() {
            return !this.insurant.isIndividual() || this.isDisabledPrint() || !this.canPrintApplication();
        },
    }, {
        icon: "fa-print",
        title: "Заявление ЮЛ о страховании",
        action() {
            const printForm = {
                id: this.Contract.Id,
                docType: "ApplicationArtificalPerson",
                fileNamePrefix: "Заявление ЮЛ о страховании",
            };
            this.openPdf(printForm);
        },
        disabled() {
            return this.insurant.isIndividual() || this.isDisabledPrint() || !this.canPrintApplication();
        },
    },
    "paymentAccountAll",
    "freeFormReceipt"];

export class TravelingAbroadToolbarController extends BaseToolbarController {
    public insurant: TravelingAbroadInsurantProxy;
    public policyWithA7IsAvailable: boolean;
    public Contract: TravelingAbroadContract;
    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
        {
            viewType = "form",
            printOptions = PrintOptions,
        }: IBaseViewParams,
    ) {
        super($injector, $transition, {
            viewType,
            printOptions,
            crossOptions: ["travelingAbroad"],
            extraOptions: [
                "manual",
                "integrationInfo",
                "annul",
            ],
        });
    }

    /**
     * @override
     */
    public $onInit() {
        super.$onInit();
        if (this.viewType === "form") {
            this.insurant = new TravelingAbroadInsurantProxy(this.Contract);
            this.policyWithA7IsAvailable = !this.employee.hasPermission("FrontGetPolicyWithoutA7", this.product.code);
        }
    }
    // @override
    public isDisabledSell() {
        return !this.isCurrencyRateAvaiable() || super.isDisabledSell();
    }
    public isDisabledCalculate() {
        return !this.isCurrencyRateAvaiable() || super.isDisabledCalculate();
    }
    public isDisabledPrint() {
        return !this.isCurrencyRateAvaiable() || super.isDisabledPrint();
    }
    public isCurrencyRateAvaiable() {
        const repositiry = this.Contract.getRepository();
        return repositiry && repositiry.hasOwnProperty("Currency") && repositiry.Currency.Collection.length > 0;
    }
    /**
     * @description Определяет возможность печати заявлений
     * @return {Boolean} true - печать доступна, false -`
     */
    public canPrintApplication(): boolean {
        const vm = this;
        const repository = this.Contract.getRepository();
        let currentInsuranceProgram: IInsuranceProgram = null;
        let oldManInsurance = false;

        if (repository && repository.InsuranceProgram) {
            currentInsuranceProgram = repository.InsuranceProgram.Collection.find(function(item) {
                return item.InsuranceProgramId === vm.Contract.InsuranceProgramId;
            });
        }

        for (let i = 0; i < vm.Contract.InsuredPersons.length; i++) {
            if (moment().diff(vm.Contract.InsuredPersons[i].InsuredPersonBirthday, "years") > 75) {
                oldManInsurance = true;
                break;
            }
        }

        return ((currentInsuranceProgram && currentInsuranceProgram.InsuranceProgramName === "D") ||
            oldManInsurance || vm.Contract.TourCancellationInsuranceAmount > 3000);
    }
    /**
     * override
     */
    public printContract() {
        const printForm = {
            id: this.Contract.Id,
            docType: "PolicyTypographicForm",
            fileNamePrefix: "Полис (экз. консульства)",
        };
        return this.openPdf(printForm).then(
            () => {
                this.showNoticeNeedPrintReceiptForm();
            },
        );
    }
}
