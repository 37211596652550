import angular from "angular";
import { StaticValidationRules } from "application/osago/static.validation.rules";
import { ValidationService } from "infrastructure/services/validation.service";

interface IVehicleDoc {
    Id: number;
    VehicleDocIssuredDate: string;
    VehicleDocNumber: string;
    VehicleDocSerial: string;
    VehicleDocumentType: string;
    VehicleCheckupDocType: string;
}
interface IScope extends ng.IScope {
    rules: StaticValidationRules;
    values: {
        InsuredDocType: string;
        OwnerDocType: string;
        VehicleDocs: IVehicleDoc[];
    };
}

interface IEvent extends EventTarget {
    target: {
        name: string;
        value: string;
    };
}

// tslint:disable-next-line:max-line-length
export function StaticValidationDirective(validationService: ValidationService, $timeout: ng.ITimeoutService) {
    "ngInject";
    return {
        link: (scope: IScope, element: ng.IAugmentedJQuery, attr: ng.IAttributes, ctrl: ng.INgModelController) => {
            const rules = scope.rules;
            element.on({
                focusout(e: IEvent) {
                    $timeout(() => {
                        const fieldName = e.target.name;
                        let guid = "";
                        let keyRule = e.target.name.replace(/(Insured)|(Owner)|(Person)/gi, "");
                        if (keyRule.indexOf("driver") >= 0) {
                            [keyRule, guid] = e.target.name.split(";");
                        }
                        if (angular.isFunction(rules[keyRule])) {
                            const messages: string[] = rules[keyRule](e.target.value,
                                                                        scope.values,
                                                                        fieldName,
                                                                        guid) || [];
                            if (messages.length > 0) {
                                validationService.addError(fieldName, messages);
                                scope.$apply();
                            }
                        }
                    });
                },
            });
        },
        restrict: "A",
        rquire: ["?ngModel"],
        scope: {
            rules: "=rules",
            values: "<staticValidationValues",
        },
    };
}
