interface IUsSpinnerService {
    spin: (name: string) => void;
    stop: (name: string) => void;
}

export function LazyLoadDirective(usSpinnerService: IUsSpinnerService): ng.IDirective {
    "ngInject";

    return {
        link: (scope, element) => {
            element.lazyload({
                appear: () => usSpinnerService.spin("ugsk-banners"),
                load: () => usSpinnerService.stop("ugsk-banners"),
                placeholder: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
            });
        },
        restrict: "A",
    };
}
