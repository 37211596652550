import { NgControllerBase } from "infrastructure/NgController.ts";
import template from "./ugskInsuredPersonUpload.component.html";

export default angular.module("ugsk.components.upload-insured-person", []).component("ugskInsuredPersonUpload", {
    bindings: {
        importedInsuredPersons: "=",
    },
    controller: class extends NgControllerBase {
        constructor($injector) {
            super($injector);

            // @todo решить проблему с потерей контекста при вызове
            // callback-а из директивы upload-excel-file
            // проблема актуальна для boxAccident
            this.fileChanged = (file) => {
                const [
                    excelReaderService,
                    notifyService,
                ] = this.di([
                    "excelReaderService",
                    "notifyService",
                ]);
                excelReaderService.readFile(file).then(data => {
                    this.importedInsuredPersons = data;
                    this.countItem = `Найдено записей: (${data.length})`;
                }).catch(() => {
                    notifyService.errorMessage("Импорт списка застрахованных", "Ошибка чтения Excel-файла");
                });
            };
        }
    },
    controllerAs: "vm",
    template,
}).name;
