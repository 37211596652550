import angular from "angular";
import { NgComponentController } from "infrastructure/NgController";
import { ValidationService } from "infrastructure/services/validation.service";
import { Day } from "infrastructure/types";
import { Uuid } from "lib/uuid";
import moment from "moment";
import { BoxVHIContract, BoxVHIInsurantProxy, BoxVHIRepository } from "../../boxVHI.factory";
import template from "./ugskBoxVhiInsurant.component.html";

class BoxVHIInsurantController extends NgComponentController {
    public contract: BoxVHIContract;
    public repository: BoxVHIRepository;
    public insurant: BoxVHIInsurantProxy;
    public minDateRegistration: Day;
    public maxDateRegistration: Day;

    public onInit() {
        if (!this.contract.InsuredBirthday) {
            this.contract.InsuredBirthday = this.getBirthdateMaxDate();
        }

        if (!this.contract.InsuredGuid) {
            this.contract.InsuredGuid = Uuid.raw();
        }

        this.$watch(() => this.contract.InsuredContractorTypeId, (oldVal, newVal) => {
            if (newVal !== oldVal) {
                this.updateFieldRepository("InsuredContractorType");
            }
        });

        this.$watch(() => this.contract.InsuredDocTypeId, (oldVal, newVal) => {
            if (newVal !== oldVal) {
                this.updateInsuredDocTypeField();
            }
        });

        this.insurant = new BoxVHIInsurantProxy(this.contract);
        this.minDateRegistration = moment().subtract(100, "years").format("YYYY-MM-DD");
        this.maxDateRegistration = moment().format("YYYY-MM-DD");
    }

    public changeInsuredAddressHasNotFiasInfo(): void {
        const validationService = this.di<ValidationService>("validationService");
        validationService.removeError("InsuredAddressUserDefinedAddress");
    }

    public getBirthdateMinDate(): Day {
        return moment().subtract(100, "years").format("YYYY-MM-DD");
    }

    public getBirthdateMaxDate(): Day {
        return moment().subtract(this.repository.InsuredMinAge.Value[0].Value, "years").format("YYYY-MM-DD");
    }

    public getContractorTypeCode(): string {
        const item = this.repository.InsuredContractorType.Collection.find((type) => {
            return type.InsuredContractorTypeId === this.contract.InsuredContractorTypeId;
        });
        return (!item) ? undefined : item.InsuredContractorTypeCode;
    }

    public isForeignDocType(): boolean {
        if (!(this.repository.InsuredDocType && this.repository.InsuredDocType.Collection.length)) {
            return false;
        }
        const doc = this.repository.InsuredDocType.Collection.find((type) => {
            return type.InsuredDocTypeId === this.contract.InsuredDocTypeId;
        });
        if (!doc) {
            return false;
        }
        return (doc.InsuredDocType === "Паспорт иностранного гражданина") || (doc.InsuredDocTypeId === 7);
    }

    public getSubProductCode() {
        if (!(this.repository && this.repository.InsuranceProduct.Collection.length)) {
            return;
        }
        const product = this.repository.InsuranceProduct.Collection.find((item) => {
            return item.InsuranceProductId === this.contract.InsuranceProductId;
        });
        return product.InsuranceProduct;
    }

    public updateFieldRepository(fieldName: string): angular.IPromise<void> {
        this.blockUIInstance.start("Обновляются справочные данные");
        return this.repository.update(fieldName, this.contract).then(() => {
            this.blockUIInstance.stop();
        });
    }

    public updateInsuredDocTypeField(): void {
        this.updateFieldRepository("InsuredDocType").then(() => {
            this.contract.InsuredCountryId = null;
        });
    }
}

export default angular.module("app.boxVHI.components.Insurant", [])
.component("ugskBoxVhiInsurant", {
    bindings: {
        contract: "=",
        englishInsurantNameIsVisible: "<",
        insuredContractorTypes: "<",
        insuredDocTypes: "<",
        insuredMinAge: "<",
        isFieldVisible: "<",
        isFormLocked: "<",
        isShowInsuredRegistrationDate: "<",
        phoneValidationService: "<",
        repository: "<",
        validation: "<",
    },
    controller: BoxVHIInsurantController,
    controllerAs: "vm",
    template,
    transclude: true,
}).name;
