import angular from "angular";
import { UautoPreviousContractsController } from "application/modalDialog/uAutoPreviousContracts.controller";
import previousContractInfoTemplate from "application/modalDialog/uAutoPreviousContracts.html";
import { IPreviousContract } from "application/uAuto/uAuto.factory";
import resources, { TContractResourceFactoryFn, TJournalResourceFactoryFn } from "infrastructure/app.resource";
import IHttpService from "infrastructure/interfaces/IHttpService";
import { NgControllerBase } from "infrastructure/NgController";
import moment from "moment";
import { EnvService } from "./env.service";

class PreviousPolicyFinder extends NgControllerBase {
    public find(
        {
            policySerial,
            policyNumber,
        }: {
            policySerial: string,
            policyNumber: string,
        },
        {
            source,
            productCode,
        }: {
            source: string,
            productCode: string,
        },
    ) {
        const $q = this.di<angular.IQService>("$q");
        const contractResourceFactory = this.di<TContractResourceFactoryFn>("contractResourceFactory");
        const journalResourceFactory = this.di<TJournalResourceFactoryFn>("journalResourceFactory");
        const journalResource = journalResourceFactory(productCode);
        const contractResource = contractResourceFactory(productCode);

        if (source === "csia") {
            return contractResource.previousContract({
                policyNumber,
                policySerial,
            }).$promise;
        }

        if (source === "search") {
            //  Search/ContractBySerialNumber
            const envService = this.di<EnvService>("envService");
            const $http = this.di<IHttpService>("$http");
            // tslint:disable-next-line:max-line-length
            const url = `${envService.read("apiUrl")}/Search/${productCode !== "uAuto" ? "ContractBySerialNumber" : "ContractsBySerialNumber"}`;
            return $http<string>({
                method: "GET",
                params: {
                    number: policyNumber,
                    serial: policySerial,
                },
                url,
            }).then((response) => {
                if (productCode === "uAuto" && Array.isArray(response.data)) {
                    if (response.data.length === 0) {
                        return $q.reject("Not found");
                    } else if (response.data.length === 1) {
                        return { Id: response.data[0].Id };
                    } else {
                        const [$uibModal] = this.di(["$uibModal"]);
                        return $uibModal.open({
                            controller: UautoPreviousContractsController,
                            controllerAs: "vm",
                            resolve: {
                                previousContracts: () => response.data,
                            },
                            size: "lg",
                            template: previousContractInfoTemplate,
                        }).result.then((selectedContract: IPreviousContract) => {
                            if (selectedContract) {
                                return { Id: selectedContract.Id };
                            }
                        });
                    }
                }

                const Id = Number.parseInt(response.data, 10);
                if (Number.isNaN(Id)) {
                    return $q.reject("Not Found");
                }
                return { Id };
            });
        }
        /**
         * @description Поиск информации о предыдущем контракте,
         * приведение данных к одному виду (КУ и ДУ - не оdata, остальные продукты oData).
         * Вввиду этой же причины, отвечают сервисы по разному.
         * Определение "формата" по видимым признакам с соответствующим поведением.
         */

        const request = {
            $filter: [
                "StatusId eq 2",
                `indexof(PolicyFullValue, '${policySerial}') gt -1`,
                `indexof(PolicyNumber, '${policyNumber}') gt -1`,
            ].join(" and "),
            $select: "Id, PolicyFullValue",
        };
        let PolicySerial = "";

        return journalResource.query(request).$promise.then(({ value: foundContracts }) => {
            if (foundContracts.length !== 1) {
                return $q.reject();
            }
            const foundContract = foundContracts[0];
            [, PolicySerial] = foundContract.PolicyFullValue.split("-");

            return contractResource.editContract({
                id: foundContract.Id,
            }).$promise;
        }).then((contract) => ({
            CSIAPreviousContractId: null,
            ContractFrom: contract.ContractFrom,
            ContractTo: contract.ContractTo,
            /* tslint:disable-next-line:max-line-length */
            InsuredBirthday: contract.InsuredBirthday ? moment.parseZone(contract.InsuredBirthday).format("YYYY-MM-DDTHH:mm:ss") : null,
            InsuredFirstName: contract.InsuredFirstName,
            InsuredLastName: contract.InsuredLastName,
            InsuredMiddleName: contract.InsuredMiddleName,
            PreviousContractId: contract.Id,
            PreviousContractNumber: contract.PolicyNumber,
            PreviousPolicySerial: PolicySerial,
            PreviousPolicySerialId: contract.PolicySerialId,
            PropertyAddressFiasId: contract.PropertyAddressFiasId,
            PropertyAddressUserDefinedAddress: contract.PropertyAddressUserDefinedAddress,
            SigningDate: contract.SigningDate,
        }));
    }
}

export default angular.module("app.services.previous-policy-finder", [resources])
    .service("previousPolicyFinder", PreviousPolicyFinder)
    .name;
