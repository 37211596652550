import { IModalService, IModalServiceInstance } from "angular-ui-bootstrap";
import ClipboardJS from "clipboard";

import { BaseToolbarController } from "application/baseProduct.toolbar.controller";
import { BoxVHIToolbarController } from "application/boxVHI/boxVHI-toolbar.controller";
import { UAutoContract } from "application/uAuto/uAuto.factory";
import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { NotifyService } from "infrastructure/services/notifyService";
import sendInsuranceRulesTemplate from "./sendInsuranceRules.html";

export default {
    action(context): void {
        if (!this.disabled(context)) {
            const sendInsuranceRulesResource: any = context.di("sendInsuranceRulesResource");
            const notifyService = context.di<NotifyService>("notifyService");

            sendInsuranceRulesResource(context.product.code)
                .sendRules({ id: context.Contract.Id }).$promise.then((data) => {
                    notifyService.successMessage("Правила страхования", data.message);
                }).catch((error) => {
                    const $uibModal = context.di<IModalService>("$uibModal");

                    const rulesUrl = context.product.getInsuranceRulesUrl(...this.getProductInfo(context));
                    $uibModal.open({
                        backdrop: "static",
                        controllerAs: "vm",
                        resolve: {
                            notifyService: () => notifyService,
                            url: () => rulesUrl,
                        },
                        controller: class {
                            static get $inject() {
                                return ["$uibModalInstance", "url", "notifyService"];
                            }
                            constructor(
                                private $uibModalInstance: IModalServiceInstance,
                                public url: string,
                                private notify: NotifyService,
                            ) {
                                "ngInject";
                            }
                            public $onInit() {
                                const clipboard = new ClipboardJS("#rulesUrlBtn");
                                clipboard.on("success", e => {
                                    this.notify.successMessage("Ссылка скопирована в буфер обмена");
                                    e.clearSelection();
                                });
                            }
                            public close() {
                                this.$uibModalInstance.dismiss();
                            }
                        },
                        template: sendInsuranceRulesTemplate,
                    });
                    notifyService.warningMessage("Правила страхования", error.data.message
                        ? error.data.message
                        : "Произошла ошибка", {
                        timeout: 5000,
                        type: "warning",
                    });
                });
        }
    },
    disabled(context): boolean {
        return !context.Contract.Id || !Boolean(context.product.getInsuranceRulesUrl(...this.getProductInfo(context)));
    },
    getProductInfo(context: BaseToolbarController | BoxVHIToolbarController) {
        const insuranceProgram = (context.Contract as UAutoContract).InsuranceProgram;
        const subProduct = "getCurrentSubProduct" in context ? context.getCurrentSubProduct() : null;

        return [insuranceProgram, subProduct];
    },
    icon: "fa-send",
    title: "Отправить Правила страхования",
    view: "form",
} as IExtraOptionPrototype;
