/* eslint-disable func-names */
/* eslint-disable space-before-function-paren */
import "./snackbar.css";

function constructLocals(scope, obj, template, instanceOnScope, injectable, resolves = {}) {
    const objt = obj;
    objt.$scope = scope;
    objt.$scope.$resolve = {};

    angular.forEach(resolves, (value, key) => {
        if (injectable) {
            objt[key] = value;
        }

        objt.$scope.$resolve[key] = value;
    });
    return objt;
}

export default angular.module("snackbarModule", [])
    .factory("snackbar", ["$rootScope", function ($rootScope) {
        return {
            create (params) {
                $rootScope.$broadcast("createSnackbar", params);
            },
        };
    }])
    .directive("snackbar", ["$rootScope", "$compile", "$timeout", "$controller", function($rootScope, $compile, $timeout, $controller) {
        return function (scope, element, attrs) {
            // snackbar container
            const snackbarContainer = angular.element(element);
            const childSnackItems = typeof snackbarContainer === "object" && snackbarContainer[0] && snackbarContainer[0].children;
            // snackbar duration time (ms)
            const { snackbarDuration } = attrs;
            // delay time to remove from DOM after hide (ms)
            const snackbarRemoveDelay = attrs.snackbarRemoveDelay || 500;

            // receive broadcating
            $rootScope.$on("createSnackbar", (event, received) => {
                if (typeof childSnackItems === "object") {
                    try {
                        // eslint-disable-next-line no-restricted-syntax, no-unused-vars
                        for (const el of childSnackItems) {
                            if (el.id === `snackbarid-${received.id}`) {
                                // hide snackbar
                                el.classList.replace("snackbar--show", "snackbar--hide");
                                // remove snackbar
                                $timeout(() => { el.remove(); }, snackbarRemoveDelay, false);
                            }
                        }
                    } catch (e) {
                        //
                    }
                }

                // snackbar template
                const template = `
                    <div class="snackbar snackbar--show" id="snackbarid-${received.id}">
                        <div class="snackbar-info">
                            <span class="snackbar-title">${received.title}</span>
                            <span class="snackbar-content">${received.content}</span>
                        </div>
                        <div class="snackbar-action">
                            <div class="snackbar-close" ng-click="${received.controllerAs}.onClose()">&#x2715;</div>
                            ${received.actionText ? `${received.actionText}` : ""}
                        </div>
                    </div>`;
                // template compile
                const snackbarScope = $rootScope.$new();
                const snackbar = angular.element($compile(template)(snackbarScope));

                // В одном scope прописываем controller
                let ctrlLocals = {};
                let ctrlInstance = {};
                let ctrlInstantiate = {};
                const receivedController = received.controller;

                const onMainController = function () {
                    "ngInject";

                    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
                    for (const method in receivedController) {
                        if (Object.prototype.hasOwnProperty.call(receivedController, method)) {
                            this[method] = receivedController[method];
                        }
                    }

                    this.onClose = function () {
                        // hide snackbar
                        snackbar.removeClass("snackbar--show").addClass("snackbar--hide");
                        // remove snackbar
                        $timeout(() => { snackbar.remove(); }, snackbarRemoveDelay, false);
                    };
                };

                ctrlLocals = constructLocals(snackbarScope, ctrlLocals, true, false, true);

                // the third param will make the controller instantiate later,private api
                // @see https://github.com/angular/angular.js/blob/master/src/ng/controller.js#L126
                ctrlInstantiate = $controller(onMainController, ctrlLocals, true, received.controllerAs);

                if (received.controllerAs && received.bindToController) {
                    ctrlInstance = ctrlInstantiate.instance;
                    // ctrlInstance.$close = snackbarScope.$close;

                    angular.extend(ctrlInstance, {
                        $resolve: ctrlLocals.$scope.$resolve,
                    }, $rootScope);
                }

                ctrlInstance = ctrlInstantiate();

                if (angular.isFunction(ctrlInstance.$onInit)) {
                    ctrlInstance.$onInit();
                }

                // add snackbar
                snackbarContainer.append(snackbar);
                // snackbar duration time
                if (received.duration || snackbarDuration) {
                    $timeout(ctrlInstance.onClose, received.duration || snackbarDuration, false);
                }
            });
        };
    }])
    .name;
