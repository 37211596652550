import ClipboardJS from "clipboard";

export default class {
    constructor(
        $uibModalInstance,
        $http,
        notifyService,
        validationService,

        apiUrl,
        acquiringBanks,
        contract,
        productCode,
        currentLink,
        blockUI,
    ) {
        "ngInject";

        this.instance = $uibModalInstance;
        this.$http = $http;
        this.notifyService = notifyService;
        this.validationService = validationService;

        this.apiUrl = apiUrl;
        this.acquiringBanks = acquiringBanks;
        this.contract = contract;
        this.productCode = productCode;
        this.blockUI = blockUI;

        if (currentLink) {
            const bank = this.acquiringBanks.find(item => currentLink.AcquiringBank === item.AcquiringBankShortName);
            this.bank = bank ? bank.AcquiringBank : null;
            this.url = currentLink.AcquiringLink;
            this.description = currentLink.OrderDescription;
            this.orderId = currentLink.OrderId;
            this.error = this.errorToString(currentLink.Error);
        }
    }

    dismiss() {
        this.instance.dismiss();
    }

    $onInit() {
        if (!this.bank) {
            this.bank = this.acquiringBanks[0].AcquiringBank;
        }

        const clipboard = new ClipboardJS("#acquireLinkBtn");
        clipboard.on("success", e => {
            this.notifyService.successMessage("Ссылка скопирована в буфер обмена");
            e.clearSelection();
        });
    }

    requestPayment() {
        const { AcquiringBankShortName } = this.acquiringBanks.find(item => item.AcquiringBank === this.bank);
        this.blockUI.start("Запрос оплаты");
        this.$http.post(`${this.apiUrl}/SendPaymentLink/${this.contract.Id}/${AcquiringBankShortName}`).then(({ data }) => {
            this.url = data.content.AcquiringLink;
            this.blockUI.stop();
        }).catch(() => {
            this.blockUI.stop();
        });
    }

    // костыль, т.к кнопку Запросить оплату нужно показать только в каско
    // потом когда появится во всех продуктах, можно удалить
    isuAutoProduct() {
        return this.productCode === "uAuto";
    }

    generateLink() {
        const acquiringBank = this.acquiringBanks.find(item => item.AcquiringBank === this.bank);
        const acquiringBankShortName = acquiringBank.AcquiringBankShortName;
        this.notifyService.progressStart("Получение платежной ссылки");
        return this.$http({
            url: `${this.apiUrl}/CreateAcquiringLink/${this.contract.Id}/${acquiringBankShortName}`,
        }).then(({
            data,
        }) => {
            this.url = data.AcquiringLink;
            this.description = data.OrderDescription;
            this.orderId = data.OrderId;
            this.error = this.errorToString(data.Error);
        }).then(() => {
            this.notifyService.successMessage("Платёжная ссылка успешно сформирована");
        }).catch(({ data }) => {
            let rawErrors = null;
            try {
                rawErrors = JSON.parse(data);
            } catch (e) {
                this.notifyService.errorMessage(data);
                return;
            }
            const errors = [];

            if (Array.isArray(rawErrors)) {
                rawErrors.forEach((error) => {
                    if (error.reports) {
                        Object.values(error.reports).forEach((reportItems) => {
                            reportItems.forEach((reportItem) => errors.push(reportItem));
                        });
                    } else {
                        errors.push(error);
                    }
                });
            }

            const validationMap = {
                "/Customer/DocumentNumber": {
                    message: "Укажите Номер документа и расчитайте договор",
                    names: ["InsuredDocNumber"],
                },
                "/Customer/DocumentSerial": {
                    message: "Укажите Серию документа и расчитайте договор",
                    names: ["InsuredDocSerial"],
                },
                "/Customer/Email": {
                    message: "Укажите номер телефона или Email и расчитайте договор",
                    names: ["InsuredEmail", "InsuredCommonEmail"],
                },
                "/Customer/FirstName": {
                    message: "Укажите Имя и расчитайте договор",
                    names: ["InsuredFirstName", "InsuredPersonFirstName"],
                },
                "/Customer/LastName": {
                    message: "Укажите Фамилию и расчитайте договор",
                    names: ["InsuredLastName", "InsuredPersonLastName"],
                },
                "/Customer/MiddleName": {
                    message: "Укажите Отчество и расчитайте договор",
                    names: ["InsuredMiddleName", "InsuredPersonMiddleName"],
                },
                "/Customer/Phone": {
                    message: "Укажите номер телефона или Email и расчитайте договор",
                    names: ["InsuredCommonPhone", "InsuredPhones", "InsuredPersonPhones"],
                },
            };

            errors.forEach((error) => {
                if (validationMap[error.instance.pointer]) {
                    validationMap[error.instance.pointer].names.forEach(validationName => {
                        this.validationService.addError(validationName, [`Интернет-эквайринг: ${validationMap[error.instance.pointer].message}`]);
                    });
                }
            });

            let notifyMessage = "Ошибка формирования платёжной ссылки.";

            if (errors.length > 0) {
                notifyMessage += " Исправьте ошибки на форме. Убедитесь, что сохранили договор после последнего изменения.";
            }

            this.notifyService.errorMessage(notifyMessage);
        })
            .finally(() => this.notifyService.progressStop());
    }

    errorToString(error) {
        if (error) {
            return JSON.stringify(error, null, 4);
        }

        return null;
    }
}
