import { NgComponentController } from "infrastructure/NgController";
import { InspectionConclusion } from "domain/classes/inspectionConclusion.class";
import VehicleInspectionModule from "application/components/ugsk-vehicle-inspection/ugskVehicleInspection.component";
import moment from "moment";
import template from "./ugskInspectionOfContractView.component.html";
import angular from "angular";

export default angular.module("ugsk.components.inspection-of-contract-view", [
    VehicleInspectionModule,
]).component("ugskInspectionOfContractView", {
    bindings: {
        contract: "<",
        fieldNames: "<",
        customLock: "=",
        onRepositoryFieldChanged: "&",
        product: "@",
        hasInspectionDateOff: "<",
        hasImageRepositoryContainerFeature: "<",
        hasImageUploadPermission: "<",
        employeeRole: "<",
        isFormModified: "=",
    },
    controller: class extends NgComponentController {
        $onInit() {
            [
                this.imageRepositoryService,
            ] = this.di([
                "imageRepositoryService",
            ]);
            [
                this.notRequiredFieldName,
                this.absenceReasonFieldName,
            ] = this.fieldNames;
            this.$watch(
                () => this.conclusionAbsenceReasons.length,
                (newVal) => {
                    if (newVal === 0 && this.notRequired) {
                        this.notRequired = false;
                        this.onRepositoryFieldChanged({
                            fieldName: this.notRequiredFieldName,
                        });
                    }
                }
            );
            this.maxInspectionDate = moment();
            
            if (!this.isEmployeeUnderwriter || !this.isProductUAuto) {
                return;
            }
            this.notifyService = this.di("notifyService");
            this.$http = this.di("$http");
            this.apiUrl = this.di("envService").read("apiUrl");
            const $timeout = this.di("$timeout");
            this.$http.post(`${this.apiUrl}UAuto/GetPSOConfirmation/${this.contract.Id}`)
                .then(({ data }) => {
                    if (data) {
                        this.IsPSOConsistent = data;
                    }
                    $timeout(() => {
                        this.isFormModified = false;
                    });
                })
                .catch(() => {
                    this.notifyService.errorMessage("Ошибка при попытке запроса данных о состоянии ПСО");
                });
        }
        
        get logic() {
            const vm = this;
            return {
                condition() {
                    return !vm.contract.isLocked();
                },
                rules: [{
                    description: "Удаление ПСО, если включено новое ТС или ПСО не требуется",
                    when: "(isNew or notRequired or contract.HasInspectionId) and conclusions.length gt 0 and not(inspectionConclusionDeletingError) and not(inspectionConclusionDeletingInProgress)",
                    then: "removeConclusion(0)", // eslint-disable-line
                }, {
                    description: "Если снята галочка акт осмотра не требуется то убрать галочку акт осмотра будет составлен дополнительно",
                    when: "notRequired eq true",
                    then: "contract.IsTowardInspection = false; contract.HasInspectionId = false;", // eslint-disable-line
                }],
            };
        }
        
        get isEmployeeUnderwriter() {
            return this.employeeRole === "Андеррайтер";
        }
        
        get isProductUAuto() {
            return this.product === "uauto";
        }

        get isNew() {
            return this.contract.IsNew;
        }

        get isLocked() {
            //Заблокирован контракт, или пришла кастомная блокировка из родительского компонента
            return (this.contract.isLocked() || Boolean(this.customLock));
        }

        get conclusions() {
            return this.contract.InspectionConclusions;
        }

        get conclusionAbsenceReasons() {
            return (this.contract.getRepository()[this.absenceReasonFieldName] && this.contract.getRepository()[this.absenceReasonFieldName].Collection) || [];
        }

        get notRequired() {
            return this.contract[this.notRequiredFieldName];
        }

        set notRequired(val) {
            this.contract[this.notRequiredFieldName] = val;
        }
        
        changePSO() {
            this.$http.post(`${this.apiUrl}UAuto/SetPSOConfirmation`, {
                isPSOConfirmed: this.IsPSOConsistent,
                ContractId: this.contract.Id
            })
                .then(({ data }) => {
                    if (data && data.errors && data.errors.length) {
                        this.IsPSOConsistent = !this.IsPSOConsistent;
                    }
                 })
                .catch(() => {
                    this.notifyService.errorMessage("Ошибка при попытке подтверждения ПСО");
                    this.IsPSOConsistent = !this.IsPSOConsistent;
                })
                .finally(() => {
                    this.isFormModified = false;
                });
        }

        //  ХИ
        canUploadImages() {
            if (this.product === "osago" && this.hasImageUploadPermission) {
                return true;
            }
            if (this.product === "uauto" && !this.isNew && this.hasImageUploadPermission) {
                return true;
            }
            return !this.isNew && !this.contract.IsTowardInspection && !this.notRequired && !this.contract.HasInspectionId;
        }

        isImageRepositoryAvailable() {
            return this.hasImageUploadPermission
                && !this.contract.isBlank() && this.contract.PolicyNumber && this.contract.Guid;
        }

        openImageRepositoryUploader() {
            const params = {
                ContractGuid: this.contract.Guid,
                ContractPolicyNumber: this.contract.PolicyNumber,
                ContractSigningDate: this.contract.SigningDate,
                ContractTerritoryGuid: this.contract.FilialGuid || this.contract.TerritoryGuid,
            };

            return this.imageRepositoryService.openImageRepositoryUploader(params);
        }

        openImageRepositoryContainer() {
            const params = {
                ContractGuid: this.contract.Guid,
                ContractPolicyNumber: this.contract.PolicyNumber,
                ContractSigningDate: this.contract.SigningDate,
                ContractTerritoryGuid: this.contract.FilialGuid || this.contract.TerritoryGuid,
            };

            return this.imageRepositoryService.openImageRepositoryContainer(params);
        }

        //  ПСО
        canAddConclusion() {
            return angular.isArray(this.conclusions)
                    && this.conclusions.length === 0
                    && !this.isNew
                    && !this.contract.IsTowardInspection
                    && !this.notRequired
                    && !this.contract.HasInspectionId;
        }

        addConclusion() {
            const conclusion = new InspectionConclusion();
            if (this.contract.Id > 0) conclusion.ContractId = this.contract.Id;
            this.conclusions.push(conclusion);
        }

        removeConclusion() {
            const [
                notifyService,
                $timeout,
            ] = this.di([
                "notifyService",
                "$timeout",

            ]);
            notifyService.progressStart("Удаление акта ПСО");
            this.inspectionConclusionDeletingInProgress = true;
            this.contract.$deleteInspectionConclusion().catch(() => {
                this.inspectionConclusionDeletingError = true;
            }).finally(() => $timeout(() => {
                notifyService.progressStop();
                this.inspectionConclusionDeletingInProgress = false;
            }));
        }

        clearInspection() {
            this.contract.InspectionId = null;
            this.contract.InspectionDate = null;
        }
    },
    controllerAs: "vm",
    template,
}).name;
