import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";

export default {
    disabled(context) {
        return context.Contract.isBlank();
    },
    href(context) {
        const journalUrl = context.$state.href(`app.${context.product.code}.journal.index`);
        return `${journalUrl}#${context.Contract.Id}`;
    },
    icon: "fa-search",
    title: "Найти в журнале",
    view: "form",
} as IExtraOptionPrototype;
