import angular, { INgModelController } from "angular";
import { Helpers } from "infrastructure/app.helpers";
import { Logger } from "infrastructure/logToServer";
import { ValidationService } from "infrastructure/services/validation.service";
import $ from "jquery";

export function ValidationDirective(
    $compile: ng.ICompileService,
    $log: Logger,
    validationService: ValidationService,
    helpers: Helpers,
): ng.IDirective {
    "ngInject";

    return {
        link: (scope, element, attributes: IAttributes, controller: INgModelController) => {
            element = $(element);
            if (!attributes.ugskValidation) {
                $log.debug("You don't set value fo attribute 'ugsk-validation'");
                $log.debug(element);
                return;
            }

            const validationCodesProp = helpers.getUniqName();

            scope[validationCodesProp] = [];
            const contentTr = angular.element(`
                <ugsk-validation ng-repeat="validationCode in ${validationCodesProp} track by validationCode"
                name="{{validationCode}}"></ugsk-validation>
            `);
            element.closest("div.form-group").append(contentTr);
            $compile(contentTr)(scope);

            const removeAllValidationErrors = () => {
                scope[validationCodesProp].forEach((validationCode: string) => {
                    validationService.removeError(validationCode);
                });
            };

            scope.$watch(() => attributes.ugskValidation, (newVal) => {
                scope[validationCodesProp] = newVal.split(", ");
            });

            scope.$watch(() => attributes.disabled, (newVal, oldVal) => {
                if (angular.equals(newVal, oldVal) || angular.isUndefined(oldVal)) {
                    return;
                }
                if (newVal && !oldVal) {
                    removeAllValidationErrors();
                }
            });

            if (controller) {
                controller.$formatters.push((val) => {
                    removeAllValidationErrors();
                    return val;
                });

                controller.$parsers.push((val) => {
                    if (controller.$dirty) {
                        removeAllValidationErrors();
                    }
                    return val;
                });

                controller.$viewChangeListeners.push(() => {
                    if (controller.$dirty) {
                        removeAllValidationErrors();
                    }
                });
            }
        },
        require: "?ngModel",
        restrict: "A",
    };
}
