import { IModalService, IModalServiceInstance } from "angular-ui-bootstrap";
import ClipboardJS from "clipboard";

import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { NotifyService } from "infrastructure/services/notifyService";
import sendNotificationTrafficAccidentTemplate from "./sendNotificationTrafficAccident.html";

export default {
    action(context): void {
        if (!this.disabled(context)) {
            const sendNotificationTrafficAccident = context.di<any>("sendNotificationTrafficAccident");
            const notifyService = context.di<NotifyService>("notifyService");

            sendNotificationTrafficAccident({ id: context.Contract.Id })
                .sendRequest().$promise.then((data: { Message: string; }) => {
                    notifyService.successMessage("Извещение о ДТП", data.Message
                        ? data.Message
                        : "Извещение о ДТП успешно отправлено"
                    );
                }).catch((error: { data: { Link: string; Message: string; }; }) => {
                    const $uibModal = context.di<IModalService>("$uibModal");

                    if (error.data.Link) {
                        $uibModal.open({
                            backdrop: "static",
                            controllerAs: "vm",
                            resolve: {
                                notifyService: () => notifyService,
                                url: () => error.data.Link,
                            },
                            controller: class {
                                static get $inject() {
                                    return ["$uibModalInstance", "url", "notifyService"];
                                }
                                constructor(
                                    private $uibModalInstance: IModalServiceInstance,
                                    public url: string,
                                    private notify: NotifyService,
                                ) {
                                    "ngInject";
                                }
                                public $onInit() {
                                    const clipboard = new ClipboardJS("#notificationUrlBtn");
                                    clipboard.on("success", e => {
                                        this.notify.successMessage("Ссылка скопирована в буфер обмена");
                                        e.clearSelection();
                                    });
                                }
                                public close() {
                                    this.$uibModalInstance.dismiss();
                                }
                            },
                            template: sendNotificationTrafficAccidentTemplate,
                        });
                    }
                    notifyService.warningMessage("Извещение о ДТП", error.data.Message
                        ? error.data.Message
                        : "Произошла ошибка", {
                        timeout: 5000,
                        type: "warning",
                    });
                });
        }

    },
    disabled(context): boolean {
        return !context.Contract.Id;
    },
    icon: "fa-send",
    title: "Отправить Извещение о ДТП",
    view: "form",
} as IExtraOptionPrototype;
