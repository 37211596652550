import angular from "angular";
import { NgComponentController } from "infrastructure/NgController";
import template from "./ugskBoxAccidentInsuranceConditions.component.html";

export default angular.module("app.boxAccident.components.insurance-conditions", [])
.component("ugskBoxAccidentInsuranceConditions", {
    bindings: {
        contractStatusId: "=",
        insuranceAmountVariantId: "=",
        insuranceAmountVariants: "<",
        insuranceProgramId: "=",
        insurancePrograms: "<",
        isFormLocked: "<",
        onInsuranceProgramChanged: "&",
    },
    controller: NgComponentController,
    controllerAs: "vm",
    template,
}).name;
