export function IcheckDirective($timeout: ng.ITimeoutService): ng.IDirective {
    "ngInject";

    return {
        link: (scope, element: JQuery<HTMLInputElement>, attributes, controller) => {
            return $timeout(() => {
                const value = attributes.value;
                const fieldset = element.closest("fieldset");

                scope.$watch(() => {
                    const fieldsetLocked = Boolean(fieldset && fieldset.attr("disabled"));
                    const elementLocked = Boolean(attributes.disabled);
                    return fieldsetLocked || elementLocked;
                }, (newVal) => {
                    $timeout(() => {
                        if (newVal) {
                            element.iCheck("disable");
                        } else {
                            element.iCheck("enable");
                        }
                        element.iCheck("update");
                    });
                });

                scope.$watch(attributes.ngModel, () => {
                    $timeout(() => element.iCheck("update"));
                });

                return element.iCheck({
                    checkboxClass: "icheckbox_ugsk",
                    radioClass: "iradio_ugsk",

                }).on({
                    ifChanged: (event: JQuery.TriggeredEvent<
                        HTMLInputElement,
                        undefined,
                        HTMLInputElement,
                        HTMLInputElement
                    >,
                ) => {
                        const el = event.target;
                        if ($(element).attr("type") === "checkbox" && attributes.ngModel) {
                            scope.$apply(() => controller.$setViewValue(el.checked));
                        }
                        if ($(element).attr("type") === "radio" && attributes.ngModel && el.checked) {
                            return scope.$apply(() => controller.$setViewValue(value));
                        }
                    },
                });
            });
        },
        require: "ngModel",
        restrict: "A",
    };
}
