import { NgComponentController } from "infrastructure/NgController";
import ugskPerson from "../ugsk-person/ugskPerson.component";
import moment from "moment";
import template from "./ugskContractor.component.html";

export default angular.module("ugsk.components.contractor", [ugskPerson]).component("ugskContractor", {
    bindings: {
        maxBirthday: "<?",
        minBirthday: "<?",
        contractorCountries: "<",
        contractorDocumentTypes: "<",
        contractorTypes: "<",
        prefix: "<",
        ngModel: "<",
        ngDisabled: "<",
        ownerIsInsurant: "<",
        subProductCode: "<",
        maxlengthOrgName: "<",
        minlengthOrgName: "<",
        maxlengthOrgInn: "<",
        isNotResidentDisable: "<",
        isNotResidentCheckboxOnly: "<",
        isOwner: "<",
        currentProduct: "@",
        /**
         * TODO:
         * onUpdateFieldRepository - не совсем точное название,
         * фактически как я понял функция используется только для функции
         * onContractorTypeChanged - во время рефакторинга
         * имеет смысл подумать над доработкой этого момента.
         */
        onUpdateFieldRepository: "&"
    },
    controller: class extends NgComponentController {
        get logic() {
            const vm = this;
            /* eslint-disable sort-keys */
            return {
                rules: [{
                    description: "Выставляем ограничение длины серии и номера документа",
                    properties: ["maxLengthForDocNumber", "maxLengthForDocSerial", "docWhomGivenVisible"],
                    when: "true",
                    then: "maxLengthForDocNumber = 50; maxLengthForDocSerial = 50; docWhomGivenVisible = true;"
                }, {
                    description: "Ограничение длины номера и серии документа",
                    properties: ["maxLengthForDocNumber", "maxLengthForDocSerial"],
                    when: "ngModel.documentType eq 'Паспорт гражданина РФ' or ngModel.documentType eq 12",
                    then: "maxLengthForDocNumber = 6; maxLengthForDocSerial = 4;",
                    else: "docWhomGivenVisible = false;"
                }, {
                    description: "Ограничение длиы номера и серии для паспорта иностранного гражданина",
                    properties: ["maxLengthForDocNumber", "maxLengthForDocSerial"],
                    when: `ngModel.documentType eq 'Паспорт иностранного гражданина' or ngModel.documentType eq 7`,
                    then: "maxLengthForDocNumber = 11; maxLengthForDocSerial = 10",
                }]
            };
            /* eslint-enable sort-keys */
        }
        onInit() {
            /* Супер костыль */
            //  @todo завести задачу на бэке хоть как-то унифицировать прикол с разными State для заблокированного/разблокированного состояния
            const [global] = this.di(["global"]);
            const { currentProduct } = global;
            this.currentProduct = currentProduct;
            this.contractorTypeDisabledState = 1;
            this.documentTypeDisabledState = 1;
            if (this.currentProduct === "osago" || this.currentProduct === "uAuto") {
                this.documentTypeDisabledState = 0;
            }
            if (currentProduct === "uAuto") {
                this.contractorTypeDisabledState = 0;
            }
            /*  */
            this.$watch(() => this.contractorCountries, () => this.syncDictionaries());
            this.$watch(() => this.contractorTypes, () => this.syncDictionaries());
            this.$watch(() => this.contractorDocumentTypes, () => this.syncDictionaries());
            this.$watch(() => this.ngModel.Inn, (val) => this.ngModel.Inn = val === "" ? null : val);
            this.$watch(() => this.ngModel.Snils, (val) => this.ngModel.Snils = val === "" ? null : val);
            this.$watch(() => this.ngModel.isNotResident, () => this.syncDictionaries());

            this.minDate = this.minBirthday ? this.minBirthday : moment().subtract(100, "years").format("YYYY-MM-DD");
            this.maxDate = moment().format("YYYY-MM-DD");
        }

        syncDictionaries() {
            this.countries = (this.contractorCountries || []).map(country => {
                //  easy monkey patch
                country.country = country[`${this.prefix}Country`] || country.InsuredCountry; // eslint-disable-line
                return country;
            });
            this.types = this.contractorTypes.map(type => {
                //  easy monkey patch
                type.type = type[`${this.prefix}ContractorType`]; // eslint-disable-line
                type.key = type[`${this.prefix}ContractorTypeId`] || type.type; // eslint-disable-line
                return type;
            });
            this.documentTypes = (this.contractorDocumentTypes || []).map(type => {
                if (this.ngModel.isNotResident === true) {
                    type.type = type[`${this.prefix}DocumentType`] || type[`${this.prefix}DocType`] || type[`${this.prefix}DocType`];
                    type.key = type[`${this.prefix}DocumentTypeId`] || type[`${this.prefix}DocTypeId`] || type[`${this.prefix}DocTypeId`] || type.type;
                    // TODO: Сделать переменную с документами, которые нельзя выбрать с параметром "Нерезидент"
                    type.State = type.type === "Свидетельство о постановке на учет в налоговом органе" ? 0 : 1
                    return type;
                } else {
                    type.type = type[`${this.prefix}DocumentType`] || type[`${this.prefix}DocType`] || type[`${this.prefix}DocType`];
                    type.key = type[`${this.prefix}DocumentTypeId`] || type[`${this.prefix}DocTypeId`] || type[`${this.prefix}DocTypeId`] || type.type;
                    return type;
                }
            });
        }

        onContractorTypeChanged() {
            this.onUpdateFieldRepository();
            if (angular.isFunction(this.ngModel.reset)) {
                const storedContractorType = this.ngModel.contractorType;
                this.ngModel.reset();
                this.ngModel.contractorType = storedContractorType;
            }
        }

        onContractorIsNotResidentChanged() {
            if (!this.ngModel.isNotResident) {
                this.ngModel.country = this.ngModel.getDefaultValueOf("country");
            }
        }

        onCountryChanged() {
            if (this.ngModel.isNotResident) {
                this.ngModel.commonRealAddress = null;
                this.ngModel.fiasId = null;
                this.ngModel.kladrId = null;
            }
        }

        onDocumentTypeChanged() {
            this.ngModel.docNumber = null;
            this.ngModel.docSerial = null;
            this.ngModel.docWhomGiven = null;
        }

        isForeignCountry() {
            let country = "Россия";
            if (this.countries && this.countries.length !== 0) {
                country = this.ngModel.country;
            }
            return String(country).toLowerCase() !== "россия";
        }

        onValidationError(error) {
            const [validationService] = this.di(["validationService"]);
            validationService.addError(error.name, error.errors);
        }
    },
    controllerAs: "vm",
    template,
    transclude: {
        personFields: "?personFields",
        translitFields: "?translitFields",
    },
}).name;
