import moment from "moment";

angular.module("ugsk.constants", [])
    .constant("EmptyObject", {})
    .constant("RolesWithReturnForRevisionAccessConstant", ["Куратор", "Куратор ГО", "Андеррайтер"])
    .constant("CanUseMagicVerificationCode", ["Куратор ГО", "Андеррайтер"])
    .constant("popoverMessageGroupConstant", "В составе группы могут объединяться несколько объектов с разными годами приобретения.")
    .constant("popoverMessageWallMaterialConstant", "Обратите внимание: если указан разный материал стен для каждого этажа, то для Движимого имущества и инженерного оборудования применяется тариф горючего материала.")
    .constant("ngModelOptions", {
        updateOn: "default blur",
        debounce: { default: 500, blur: 0 }
    })// Изменения модели будут проходить при потере фокуса или через 500 ms после последнего изменения данных в контроле
    .constant("datePickerBaseYearOptions", {
        outFormat: "YYYY",
        format: "yyyy",
        startDate: moment().add(-90, "Y").format("YYYY"),
        endDate: moment().format("YYYY"),
        minViewMode: 2
    })
    .constant("touchSpinBaseOptions", {
        step: 0.0001,
        decimals: 4,
        boostat: 5,
        forcestepdivisibility: "round",
        maxboostedstep: 10,
        buttondown_class: "btn btn-white",
        buttonup_class: "btn btn-white",
        delimiter: ","
    })
    .constant("global", {})
    .constant("CanUseMagicVerificationCodeRoles", ["Куратор ГО", "Андеррайтер", "Куратор филиала", "Куратор региона", "Куратор филиала 1-го уровня"]);
