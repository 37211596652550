export default class UGSKBlob {
    public blob: Blob;
    public url: string;
    public filename: string;
    constructor(data: BlobPart, contentType: string, filename: string) {
        this.blob = new window.Blob([data], { type: contentType });
        this.url = window.URL.createObjectURL(this.blob);
        this.filename = filename;
    }
}
