import angular, { IPromise } from "angular";
import { EnvService } from "infrastructure/services/env.service";

function transdekraResource(
    $resource: ng.resource.IResourceService,
    envService: EnvService,
) {
    "ngInject";
    return $resource(`${envService.read("apiUrl")}`, {}, {
        getModels: {
            isArray: true,
            method: "GET",
            url: `${envService.read("apiUrl")}Search/Transdekra`,
        },
        getModifications: {
            isArray: true,
            method: "GET",
            params: {
                skip: 0,
                top: 10000,
            },
            transformResponse: (data) => {
                if (data) {
                    const result = angular.fromJson(data);

                    return result && result.transdekraVehicles ? result.transdekraVehicles : [];
                }
            },
            url: `${envService.read("apiUrl")}TransdekraExtended/TransdekraModifications`,
        },
        getCategories: {
            isArray: true,
            method: "GET",
            url: `${envService.read("apiUrl")}TransdekraExtended/TransdekraCategories`,
        }
    });
}

export interface IGetModelsResponseItem {
    MarkId: number;
    ModelId: number;
    Result: string;
    VehicleType: string;
}

interface ITransdekraKeyItem {
    Id: number;
    KeyCode: string;
    Name: string;
}

export interface IGetModificationsResponseItem {
    CarModif: string;
    EngPwr: number;
    EngPwrMin: number;
    EngPwrMax: number;
    EngVol: number;
    Id: number;
    Fuel: ITransdekraKeyItem;
    FuelId: number;
    Kpp: ITransdekraKeyItem;
    KppId: number;
    Marka: {
        Id: number;
        KeyCode: string;
        Name: string;
        VehicleManufacturerType: {
            Id: number;
            Name: string;
        },
        VehicleManufacturerTypeId: number;
    };
    Model: ITransdekraKeyItem;
    ModelId: number;
    Privod: ITransdekraKeyItem;
    PrivodId: number;
    ProdStart: string;
    ProdEnd: string;
    TdCode: string;
    Type: ITransdekraKeyItem;
    TypeId: number;
    IsTransdekra: boolean;
    MaxWeight: number;
    MaxWeightMin: number;
    MaxWeightMax: number;
    CarSeats: number;
    CarSeatsMin: number;
    CarSeatsMax: number;
    ExtendedFields: boolean;
}

export interface IGetCategoriesResponseItem {
    TypeCar: ITransdekraKeyItem;
    VehicleType: {
        IsDisabled: boolean;
        Name: string;
        Id: number;
    },
    Vorbenko: ITransdekraKeyItem;
    VehicleTypeId: number;
    TypeCarId: number;
    VorbenkoId: number;
    Id: number;
}

export interface ITransdekraResource {
    getModels: (params: { query: string; category: string }) => ng.resource.IResourceArray<IGetModelsResponseItem>;
    getModifications: (params: { markId: number, modelId: number, id?: number, vehicleTypeName: string })
        => ng.resource.IResourceArray<IGetModificationsResponseItem>;
    getCategories: () => ng.resource.IResourceArray<IGetCategoriesResponseItem>;
}

export default angular.module("app.osago.components.vehicle-transdekra.service", [
    "ngResource",
]).service("transdekraResource", transdekraResource).name;
