import { Transition } from "@uirouter/core";
import { BaseToolbarController } from "application/baseProduct.toolbar.controller";
import IBaseViewParams from "infrastructure/interfaces/IBaseViewParams";
import EmptyDocumentUrl from "./EmptyDocumentMyChoice.pdf";

const PrintOptions = [
    "policy",
    "policyWithoutA7",
    "emptyPolicy",
    "worksheet",
    "paymentAccountAll",
    "paymentAccountFirst",
    "paymentAccountSecond",
    "freeFormReceipt",
];

export class MyChoiceToolbarController extends BaseToolbarController {
    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
        {
            viewType = "form",
            printOptions = PrintOptions,
        }: IBaseViewParams,
    ) {
        super($injector, $transition, {
            viewType,
            printOptions,
            crossOptions: [
                "snugHome",
                "express",
                "myChoice",
                "constructorHome",
                "assuranceApartment",
                "assuranceHome",
            ],
            extraOptions: [
                "manual",
                "integrationInfo",
                "annul",
            ],
        });
    }
    //  @override
    // Todo : Return this ?
    public printEmptyContract() {
        return this.helpers.openUrlInIframe(EmptyDocumentUrl);
    }
}
