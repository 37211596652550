if (!Array.prototype.find) {
    Array.prototype.find = function(predicate) {
        if (this == null) {
            throw new TypeError("Array.prototype.find called on null or undefined");
        }
        if (typeof predicate !== "function") {
            throw new TypeError("predicate must be a function");
        }
        var list = Object(this);
        var length = list.length >>> 0;
        var thisArg = arguments[1];
        var value;

        for (var i = 0; i < length; i++) {
            value = list[i];
            if (predicate.call(thisArg, value, i, list)) {
                return value;
            }
        }
        return undefined;
    };
}
if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function(search, replacement) {
        var target = this;
        return target.replace(new RegExp(search, "g"), replacement);
    };
}

(function(e) {
    //  https://developer.mozilla.org/ru/docs/Web/API/Element/matches
    e.matches = e.matches || e.webkitMatchesSelector || e.mozMatchesSelector || e.msMatchesSelector || e.matchesSelector || function(selector) {
        var matches = document.querySelectorAll(selector),
            th = this;
        return Array.prototype.some.call(matches, function(e) {
            return e === th;
        });
    };
})(Element.prototype);

(function(e) {
    //  https://developer.mozilla.org/ru/docs/Web/API/Element/closest
    e.closest = e.closest || function(css) {
        var node = this;
        while (node) {
            if (node.matches(css)) return node;
            node = node.parentElement;
        }
        return null;
    };
})(Element.prototype);

if (![].includes) {
    Array.prototype.includes = function(searchElement/* , fromIndex*/) {
        "use strict";
        var O = Object(this);
        var len = parseInt(O.length) || 0;
        if (len === 0) {
            return false;
        }
        var n = parseInt(arguments[1]) || 0;
        var k;
        if (n >= 0) {
            k = n;
        } else {
            k = len + n;
            if (k < 0) {
                k = 0;
            }
        }
        while (k < len) {
            var currentElement = O[k];
            if (searchElement === currentElement ||
         (searchElement !== searchElement && currentElement !== currentElement)
      ) {
                return true;
            }
            k++;
        }
        return false;
    };
}

// https://github.com/uxitten/polyfill/blob/master/string.polyfill.js
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
if (!String.prototype.padStart) {
    String.prototype.padStart = function padStart(targetLength, padString) {
        targetLength >>= 0; // floor if number or convert non-number to 0;
        padString = String(padString || " ");
        if (this.length > targetLength) {
            return String(this);
        }
        targetLength -= this.length;
        if (targetLength > padString.length) {
            padString += padString.repeat(targetLength / padString.length); // append to original to ensure we are longer than needed
        }
        return padString.slice(0, targetLength) + String(this);
    };
}
Number.isInteger = Number.isInteger || function(value) {
    return angular.isNumber(value) && Number.isFinite(value) && !(value % 1);
};

Number.isFinite = Number.isFinite || function(value) {
    return angular.isNumber(value) && isFinite(value);
};

//  +Chrome 49
if (!Object.prototype.values) {
    Object.defineProperty(Object.prototype, "values", {
        value: function values(object) {
            return Object.keys(object).map(key => object[key]);
        },
        configurable: false,
        enumerable: false,
        writable: true
    })
}
