import { UGSKDriver } from "domain/classes/ugsk-driver.class";

import { DateTimeOffset, Int } from "infrastructure/types";

import moment from "moment";
import { IKBMError } from "../interfaces";

export class OsagoDriver extends UGSKDriver {
    public isNotResident: boolean = false;
    public country = "Россия";
    public KBMClass = "3";
    public RSAKBMValue = 0;
    public RSAKBMClass: string;
    public RSAPayoutCount: Int;
    public PayoutCount: Int = 0;
    public KBMErrors: IKBMError[] = [];
    public licenseIssueDate: DateTimeOffset = moment().format("YYYY-MM-DDT00:00:00");
    // Предыдущие ВУ
    public hasPreviousDriverLicense = false;
    public previousLicenseIssueDate: DateTimeOffset = null;
    public previousLicenseLastName: string = null;
    public previousLicenseNumber: string = null;
    public DriverLicenseDocTypeId: Int = 20;
    public DriverLicenseIssueCountryId: Int = 643;
    public previousLicenseSerial: string = null;
    public isIndividual() {
        return true;
    }
    public getHash() {
        return [
            moment(this.birthday, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD"),
            this.country,
            this.firstName,
            this.lastName,
            this.middleName,
            moment(this.licenseIssueDate, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD"),
            this.licenseNumber,
            this.licenseSerial,
        ].join(":");
    }
}
