import { unbreakWord } from "infrastructure/app.helpers";
import "./ugskDigest.component.css";
import { SmallDigestController } from "./ugskSmallDigest.component";
import digestTemplate from "./ugskDigest.component.html";
import smallDigestTemplate from "./ugskSmallDigest.component.html";
import moment from "moment";
import { Uuid } from "lib/uuid";
import integrationInfoTemplate from "infrastructure/extraMenuOptionsPresets/integrationInfo.html"
import  { USER_ROLES, CHECKED_RESULT_MESSAGES } from "../../constants";

const SECONDS_FOR_NEXT_UPDATE_OF_PAYMENT_STATUS = 30;

class DigestController extends SmallDigestController {
    onInit() {
        super.onInit();
        this.inProgressText = "Загрузка дайджеста";
        const [
            $q,
            dictionariesService,
            statusService,
        ] = this.di([
            "$q",
            "dictionariesService",
            "statusService",
        ]);

        this.employeeService = this.di("employeeService");
        this.getContractStatusLabelClass = statusService.getStatusLabelClass;
        [this.helpers, this.showIntegrationInfoService, this.notifyService] = this.di(["helpers", "showIntegrationInfoService", "notifyService"]);
        this.unbreakWord = unbreakWord;
        this.isSendContractToQREnabled = false;

        this.paymentKinds = [];

        this.$timeout = this.di("$timeout");
        const nextReloadPaymentStatusTime = window.sessionStorage.getItem("nextReloadPaymentStatusTime");
        const reloadPaymentStatusSecondsLeft = nextReloadPaymentStatusTime
            ? moment(nextReloadPaymentStatusTime).diff(moment(), "seconds")
            : 0;
        this.reloadPaymentStatusTimer = reloadPaymentStatusSecondsLeft > 0 ? reloadPaymentStatusSecondsLeft : null;
        this.updateReloadPaymentStatusTimer();

        return $q.all([
            this.contract.getIntegrationInfo(),
            dictionariesService.get("PaymentKinds"),
        ]).then(([integrationInfos, paymentKinds]) => {
            if (integrationInfos.length) {
                this.CSSUStatus = `ЦССУ: ${integrationInfos[0].Stage.Name}`;

                this.isSendContractToQREnabled = ["поставлен в очередь", "ошибка интеграции", "ошибка учета"].includes(String(integrationInfos[0].Stage.Name).toLowerCase())
                    && moment().subtract(2, "hours").isSameOrAfter(moment(integrationInfos[0].StageTime));
            } else {
                this.CSSUStatus = "Нет данных";
            }
            this.paymentKinds = paymentKinds;
        });
    }

    getPaymentKind(paymentKindId) {
        const paymentKind = this.paymentKinds.find(item => item.Id === paymentKindId);
        if (paymentKind) {
            return paymentKind.Name;
        }
        return "";
    }

    showIntegrationInfo() {
        const resource = this.contract.getIntegrationInfo.bind(this.contract);
        this.showIntegrationInfoService.showInfoWindow(resource);
    }
    /**
     * @description Получение списка полей из контракта для отображения сведений о страхователе,
     * основываясь на структуре договора и типе страхователя.
     * @returns {Array} Список полей из контракта
     */
    getInsuredFields() {
        //  OMG!!! hardcode
        return this.contract.getInsurantFields().filter(name => (name !== "InsuredBirthday" && name !== "InsuredPersonBirthday"));
    }
    //  untestable
    focusTo(fieldName) {
        const [
            anchorToElement,
        ] = this.di([
            "anchorToElement",
        ]);
        const element = angular.element([
            `.contract-container ${fieldName}`,
            `.contract-container [ng-model^="vm.${fieldName}"]`,
            `.contract-container [ng-model^="vm.contract.${fieldName}"]`,
            `.contract-container [ng-model^="vm.Contract.${fieldName}"]`,
            `.contract-container [ng-bind^="vm.${fieldName}"]`,
            `.contract-container [ng-bind^="vm.contract.${fieldName}"]`,
            `.contract-container [ng-bind^="vm.Contract.${fieldName}"]`,
            `.contract-container [ugsk-validation="${fieldName}"]`,
            `.contract-container [digest-anchor^="${fieldName}"]`,
        ].join(", ")).first();
        anchorToElement(element);
    }

    isPaymentStatusAvailable() {
        return "IsPayed" in this.contract;
    }

    isUserUnderwriter() {
        return this.employeeService.employee.info && this.employeeService.employee.info.Role === USER_ROLES.UNDERWRITER;
    }

    isProductKasko() {
        return this.currentProduct.code === "uAuto";
    }

    isCheckedResultDemand() {
        return this.contract.checkedResult === "Demand";
    }

    getPremiumShowCondition() {
        return (
            !this.isProductKasko()
            || this.contract.isTerminated() || this.contract.isAnnuled()
            || !this.isCheckedResultDemand()
            || this.isUserUnderwriter()
        );
    }

    getCheckedResultMessage(status) {
        return status ? CHECKED_RESULT_MESSAGES[status] : CHECKED_RESULT_MESSAGES["Attention"];
    }

    getCheckedResultClass() {
        return {
            "label-primary": ["Recommendation", "Information", "Notification", "None"].includes(this.contract.checkedResult),
            "label-warning": ["Forbidden", "Warning", "Attention", "ForbiddenAsWarning", "Demand"].includes(this.contract.checkedResult)
        };
    }

    isPayed() {
        if (this.isPaymentStatusAvailable()) {
            return this.contract.IsPayed;
        }

        return false;
    }

    updateReloadPaymentStatusTimer() {
        if (this.reloadPaymentStatusTimer && this.reloadPaymentStatusTimer > 1) {
            this.reloadPaymentStatusTimer -= 1;
            this.$timeout(this.updateReloadPaymentStatusTimer.bind(this), 1000);
        } else {
            window.sessionStorage.setItem("nextReloadPaymentStatusTime", null);
            this.reloadPaymentStatusTimer = null;
        }
    }

    reloadPaymentStatus() {
        window.sessionStorage.setItem(
            "nextReloadPaymentStatusTime",
            moment().add(SECONDS_FOR_NEXT_UPDATE_OF_PAYMENT_STATUS, "seconds")
        );
        this.reloadPaymentStatusTimer = SECONDS_FOR_NEXT_UPDATE_OF_PAYMENT_STATUS;
        this.$timeout(this.updateReloadPaymentStatusTimer.bind(this), 1000);
        this.blockUIInstance.start(" ");
        const paymentStatusResource = this.di("paymentStatusResource");
        paymentStatusResource.getStatus({ Id: this.contract.Id }).$promise.then((data) => {
            if (this.isPaymentStatusAvailable()) {
                this.contract.IsPayed = data.IsPayed;
            }
            const id = this.contract.Id;
            this.contract.Id = null;
            return this.contract.$load(id);

        }, (reason) => {
            if (reason.data.Message) {
                this.notifyService.warningMessage("Статус оплаты", reason.data.Message);
            }
        }).finally(() => {
            this.blockUIInstance.stop();
        });
    }

    isPaymentServiceIntegrationAvailable(productCode) {
        return ['uAuto', 'osago'].includes(productCode);
    }

    onSendContractToQRClicked() {
        if (!this.isSendContractToQREnabled) {
            return;
        }

        const sendContractToQRResource = this.di("sendContractToQRResource");
        this.blockUIInstance.start(" ");
        sendContractToQRResource.send([{
            PolicySerial: this.contract.getPolicySerial(),
            PolicyNumber: this.contract.PolicyNumber,
            ContractGuid: this.contract.Guid,
            RequestGuid: Uuid.raw(),
        }]).$promise.then(() => {
            this.notifyService.successMessage("Постановка договора в очередь ЦССУ", "Договор успешно поставлен в очередь!");
            this.isSendContractToQREnabled = false;
        }, () => {
            this.notifyService.errorMessage("Постановка договора в очередь ЦССУ", "При постановке договора в очередь возникла ошибка!");
        }).finally(() => {
            this.blockUIInstance.stop();
        });
    }
}

export default angular
    .module("app.components.digest", [])
    .component("ugskDigest", {
        bindings: {
            contract: "<?",
            contractModified: "<?",
            currentProduct: "<",
        },
        controller: DigestController,
        controllerAs: "vm",
        template: digestTemplate,
        transclude: {
            digestHeader: "?digestHeader",
            extraDigest: "?extraDigest",
            afterContractParams: "?afterContractParams",
            afterInsurant: "?afterInsurant",
            beforeContractParams: "?beforeContractParams",
            payment: "?payment",
            afterPayment: "?afterPayment",
            premiumDetails: "?premiumDetails",
            premiumProcent: "?premiumProcent",
        },
    })
    .component("ugskSmallDigest", {
        bindings: {
            contract: "<?",
            contractModified: "<?",
            currentProduct: "<",
        },
        controller: SmallDigestController,
        controllerAs: "vm",
        template: smallDigestTemplate,
        transclude: {
            digestHeader: "?digestHeader",
            digestHeaderThird: "?digestHeaderThird",
            premiumDetails: "?premiumDetails",
        },
    })
    .name;
