import template from "./ugskDialog.component.html";

export default angular.module("ugsk.components.dialog", []).component("ugskDialog", {
    bindings: {
        onAccept: "&?",
        onReject: "&?",
        title: "@",
    },
    controllerAs: "vm",
    template,
    transclude: true,
}).name;
