import { StateParams, StateService } from "@uirouter/core";
import angular, { INgModelOptions } from "angular";
import { IEmployeeResource, StateRegistryPatched } from "infrastructure/interfaces";
import IBlockUI from "infrastructure/interfaces/IBlockUI";
import IScope from "infrastructure/interfaces/IScope";
import { NotifyService } from "infrastructure/services/notifyService";
import { ValidationService } from "infrastructure/services/validation.service";
import forgotPasswordTemplate from "./forgotPassword.html";
import resetPasswordTemplate from "./resetPassword.html";
import outdatedLinkTemplate from "./outdatedLink.html";
import ugskTreeViewComponent from "application/components/ugsk-tree-view/ugskTreeView.component";
import { getQRCodeByString } from "@ugsk/payments";

angular.module("app.userProfile", [
    ugskTreeViewComponent
]).run(($stateRegistry: StateRegistryPatched) => {
    $stateRegistry.register({
        data: {
            anonymous: true,
        },
        name: "reset",
        url: "/resetPassword?token",
        views: {
            "topbar@": {},
            // tslint:disable-next-line: object-literal-sort-keys
            "body@": {
                // tslint:disable-next-line: object-literal-shorthand
                controller: function (
                    ngModelOptions: INgModelOptions,
                    employeeResource: IEmployeeResource,
                    blockUI: IBlockUI,
                    notifyService: NotifyService,
                    $stateParams: StateParams,
                    $state: StateService,
                    validationService: ValidationService,
                ) {
                    "ngInject";

                    const vm = this;
                    vm.ngModelOptions = ngModelOptions;
                    vm.resetPassword = resetPassword;
                    vm.recoveryData = {
                        password: null,
                        token: $stateParams.token,
                        otpCode: null,
                        clientDateTime: null
                    };
                    vm.isDisablePasswordReset = () => !vm.recoveryData.password || !vm.passwordConfirm;
                    vm.checkToken = checkToken;
                    vm.isOtpRequired = true;
                    vm.otpQrCode = null;

                    function checkToken() {
                        if ($stateParams == null || $stateParams.token == null) {
                            $state.go("outdatedlink");
                            return;
                        }
                        blockUI.start("Проверка ссылки сброса пароля");
                        employeeResource
                            .requestCheckResetPasswordToken({ token: $stateParams.token })
                            .$promise.then(res => {
                                if (!res.isActiveToken) {
                                    $state.go("outdatedlink");
                                }
                                vm.isOtpRequired = res.isOtpRequired;
                                blockUI.start("Генерация кода");
                                getQRCodeByString(atob(res.otpUri))
                                    .then(url => { vm.otpQrCode = url })
                                    .catch(err => {})
                                    .finally(() => {
                                        blockUI.stop();
                                    });
                            })
                            .catch(() => {
                                $state.go("outdatedlink");
                            })
                            .finally(() => {
                                blockUI.stop();
                            });
                    };

                    function resetPassword() {
                        if (vm.recoveryData.password !== vm.passwordConfirm) {
                            validationService.addError("passwordConfirm", ["Введенные пароли не совпадают"]);
                            return;
                        }
                        blockUI.start("Установка пароля");
                        vm.recoveryData.clientDateTime = new Date().toISOString();
                        employeeResource
                            .requestPasswordReset(vm.recoveryData)
                            .$promise.then(() => {
                                $state.go("login");
                                notifyService.successMessage("Смена пароля прошла успешно");
                            })
                            .catch(() => { /** */ })
                            .finally(() => {
                                blockUI.stop();
                            });
                    };
                    function IsOtpServiceEnabled() {
                        blockUI.start("Опрос сервиса 2fa");
                        employeeResource
                            .requestGetOtpServiceStatus()
                            .$promise.then((res) => {
                                vm.otpSetState(res.IsTwoFactorServiceEnabled);
                            })
                            .catch(() => {
                                vm.otpSetState(false);
                            })
                            .finally(() => {
                                blockUI.stop();
                            });
                    };
                },
                controllerAs: "vm",
                template: resetPasswordTemplate,
            },
        },
    });

    $stateRegistry.register({
        data: {
            anonymous: true,
        },
        name: "outdatedlink",
        url: "/outdatedLink",
        views: {
            "topbar@": {},
            // tslint:disable-next-line: object-literal-sort-keys
            "body@": {
                // tslint:disable-next-line: object-literal-shorthand
                controller: function () {
                    "ngInject";
                },
                controllerAs: "vm",
                template: outdatedLinkTemplate,
            },
        }
    });

    $stateRegistry.register({
        data: {
            anonymous: true,
        },
        name: "forgot",
        url: "/forgotPassword/",
        views: {
            "topbar@": {},
            // tslint:disable-next-line: object-literal-sort-keys
            "body@": {
                // tslint:disable-next-line: object-literal-shorthand
                controller: function (
                    ngModelOptions: INgModelOptions,
                    employeeResource: IEmployeeResource,
                    blockUI: IBlockUI,
                    notifyService: NotifyService,
                ) {
                    "ngInject";

                    const vm = this;
                    vm.ngModelOptions = ngModelOptions;
                    vm.forgotPassword = forgotPassword;
                    vm.recoveryData = {
                        ClientUri: location.origin + location.pathname,
                        Email: null,
                    };
                    function forgotPassword() {
                        blockUI.start("Отправка Email");
                        employeeResource
                            .requestPasswordForgot(vm.recoveryData)
                            .$promise.then(() => {
                                notifyService.successMessage(`На адрес ${vm.recoveryData.Email} электронной почты выслано сообщение с ссылкой для сброса пароля`);
                            })
                            .catch((response) => {
                                if (response.data.ModelState.Login) {
                                    vm.loginRequired = true;
                                }
                            })
                            .finally(() => {
                                blockUI.stop();
                            });
                    }
                },
                controllerAs: "vm",
                template: forgotPasswordTemplate,
            },
        },
    });

    $stateRegistry.register({
        data: {
            anonymous: true,
        },
        name: "confirmEmail",
        url: "/confirmEmail?login?code",
        views: {
            "topbar@": {},
            // tslint:disable-next-line: object-literal-sort-keys
            "body@": {
                // tslint:disable-next-line: object-literal-shorthand
                controller: function (
                    $stateParams: StateParams,
                    $state: StateService,
                    $interval: angular.IIntervalService,
                    $scope: IScope,
                    employeeResource: IEmployeeResource,
                ) {
                    "ngInject";

                    this.timerSeconds = 5;
                    this.isConfirmSuccess = false;

                    if (!$stateParams.code || !$stateParams.login) {
                        let error = "";
                        if (!$stateParams.code && !$stateParams.login) {
                            error = "не указан логин и код подтверждения";
                        } else if (!$stateParams.code) {
                            error = "не указан код подтверждения";
                        } else if (!$stateParams.login) {
                            error = "не указан логин";
                        }
                        return $state.go("error", {
                            error: "confirm-email.error",
                            error_description: `Ошибка подтверждения адреса электронной почты - ${error}`,
                        });
                    }
                    const data = {
                        Code: $stateParams.code,
                        Login: $stateParams.login,
                    };
                    employeeResource
                        .verifyEmail(data)
                        .$promise.then(() => {
                            this.isConfirmSuccess = true;
                            $scope.$on("$destroy", () => {
                                if (this.hInterval) {
                                    $interval.cancel(this.hInterval);
                                }
                            });
                            this.hInterval = $interval(
                                () => {
                                    this.timerSeconds--;
                                    if (this.timerSeconds <= 0) {
                                        $state.go("app.index");
                                    }
                                },
                                1000,
                                10,
                            );
                        })
                        .catch((error) => {
                            if (error.status === 410) {
                                return $state.go("error", {
                                    error: "confirm-email.expired",
                                    error_description: "Ссылка устарела",
                                });
                            }
                            return $state.go("error", {
                                error: "confirm-email.error",
                                error_description: "Ошибка подтверждения адреса электронной почты.",
                            });
                        });
                },
                controllerAs: "vm",
                template: `
                    <h1>Подтверждение почты <span ng-if="!vm.isConfirmSuccess">...</span></h1>
                    <div ng-if="vm.isConfirmSuccess">
                        <div class="alert alert-success">Адрес электронной почты успешно подтвержден.<br />
                        Вы будете перенаправлены <a ng-href="#/">на главную страницу</a> через: <span ng-bind="vm.timerSeconds"></span></div>
                    </div>
                `,
            },
        },
    });
});
