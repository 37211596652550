import angular from "angular";
import { EventEmitter } from "events";
import { IContractOwner } from "infrastructure/interfaces";
import { IAbstractContract, IContractMeta } from "infrastructure/interfaces/IAbstractContract";
import { IContractResource } from "infrastructure/interfaces/IContractResource";
import IDTO from "infrastructure/interfaces/IDTO";
import IPVSContractorDTO from "infrastructure/interfaces/IPVSContractorDTO";
import { IGetPrintableResourceParams } from "infrastructure/interfaces/IGetPrintableParams";
import { IFactPaymentDTO } from "infrastructure/interfaces/WebApi/IFactPaymentDTO";
import PaymentDTO from "infrastructure/interfaces/WebApi/PaymentDTO";
import Repository from "infrastructure/Repository.class";
import automapper from "infrastructure/services/automapper";
import { DateTimeOffset, Guid, Int } from "infrastructure/types";
import UGSKBlob from "./blob.class";
import UgskPhone from "./ugsk-phone.class";

const integrationResourceProperty = Symbol("integrationResourceProperty");
const contractResourceProperty = Symbol("contractResourceProperty");
const eventEmitterKey = Symbol("eventEmitterKey");
const repositoryKey = Symbol("repositoryKey");
const metaKey = Symbol("metaKey");

export abstract class AbstractContract implements IAbstractContract {
    public Id: Int;
    public PolicyNumber: string;
    public Guid: Guid;
    public SigningDate: DateTimeOffset;
    public ContractStatusId: number | null;
    public PaymentCanBeChange: boolean;
    public StatusName: string;

    public EmployeeName: string;
    public EmployeeIntermediateSellerName: string;
    public EmployeePointOfSaleName: string;

    public InsuredContractorType?: string;
    public InsuredContractorTypeId: Int;
    public FilialId?: Int;
    public FilialGuid?: Guid;
    public StatusId?: Int;

    public InsuredPhones: UgskPhone[];
    public PlannedPayments: PaymentDTO[];
    public FactPayments: IFactPaymentDTO[];

    // @todo типизировать эту штуку. В @types есть, но там видимо для более старой версии тайпинги
    private [integrationResourceProperty]: any;
    private [eventEmitterKey]: EventEmitter = new EventEmitter();
    private [contractResourceProperty]: IContractResource;
    private [repositoryKey]: Repository;
    private [metaKey]: IContractMeta = {};

    public getInsurantPhones(): UgskPhone[] {
        return this.InsuredPhones;
    }

    public getPlannedPaymentsCount(): number {
        if (angular.isArray(this.PlannedPayments)) {
            return this.PlannedPayments.length;
        }
        return 1;
    }

    public getFirstFactPaymentKindId(): number {
        const firstPayment = this.FactPayments[0];
        if (firstPayment) {
            return firstPayment.PaymentKindId;
        }
    }

    public setIntegrationResource(resource: ng.resource.IResource<any>) {
        this[integrationResourceProperty] = resource;
    }

    public async getIntegrationInfo(): Promise<any[]> {
        if (!this.Id) {
            return [];
        }
        return this[integrationResourceProperty].odata()
            .select("StageTime", "Message")
            .expandPredicate("Stage")
            .select("Name")
            .finish()
            .filter("Contract/Id", this.Id)
            .orderBy("StageTime", "desc")
            .query().$promise;
    }

    public setMetadata(meta: Partial<IContractMeta>) {
        // @todo убрать костыль после реализации автомеппинга
        if (!this[metaKey]) {
            this[metaKey] = {};
        }
        Object.assign(this[metaKey], meta);
    }

    public getMetadata(key: keyof IContractMeta): any;
    public getMetadata(): IContractMeta;
    public getMetadata(key?: keyof IContractMeta | undefined): IContractMeta | any {
        // @todo убрать костыль после реализации автомеппинга
        if (!this[metaKey]) {
            this[metaKey] = {};
        }

        if (!key) {
            return this[metaKey];
        }

        return this[metaKey][key];
    }

    public setInsurantPhones(value: UgskPhone[]) {
        this.InsuredPhones = value;
    }

    public isInsurantANaturalPerson(): boolean {
        return this.getInsurantTypeId() === 1;
    }

    public getInsurantTypeId(): number {
        return this.InsuredContractorTypeId;
    }

    public canBeAnnulled(): boolean {
        return this.isSigned() || this.isReturnedForRevision();
    }

    public isReturnedForRevision(): boolean {
        return this.getStatus() === 4;
    }

    public isBlank(): boolean {
        return !this.Id;
    }

    public isDraft(): boolean {
        return this.getStatus() === 1 || this.isBlank();
    }

    public isApproved(): boolean {
        return this.getStatus() === 3;
    }

    public isFrozen(): boolean {
        return this.getStatus() === 6;
    }

    public isAnnuled(): boolean {
        return this.getStatus() === 5;
    }

    public isSigned(): boolean {
        return this.getStatus() === 2;
    }

    public isTerminated(): boolean {
        return this.getStatus() === 12;
    }

    public isLocked(): boolean {
        return this.isSigned() || this.isAnnuled() || this.isTerminated();
    }

    public getStatus(): number {
        return this.ContractStatusId;
    }

    public setStatus(newStatus: number) {
        this.ContractStatusId = newStatus;
    }

    /**
     * @todo Перенести в контракт журнала, т.к. используется в контексте журнала
     * @abstract
     * @param employee
     */
    public setEmployee(employee: IContractOwner) {
        this.EmployeeName = employee.Employee.Name;
        this.EmployeeIntermediateSellerName = employee.Employee.IntermediateSeller.Name;
        this.EmployeePointOfSaleName = employee.Employee.PointOfSale.Name;
    }

    public $loadPrintable(params: IGetPrintableResourceParams): angular.IPromise<UGSKBlob> {
        return this.getResourceProvider().getPrintable(params).$promise;
    }

    public setResourceProvider(resource: IContractResource): void {
        this[contractResourceProperty] = resource;
    }

    public getResourceProvider(): IContractResource {
        return this[contractResourceProperty];
    }

    public setRepository(repository: Repository) {
        this[repositoryKey] = repository;
    }

    public getRepository(): Repository {
        return this[repositoryKey];
    }

    public toDTO(): IDTO {
        const SourceType = this.constructor;
        const TargetType = "DTO";
        try {
            return automapper.map(SourceType, TargetType, this);
        } catch (e) {
            // console.warn(`${SourceType.name} -> ${TargetType} mapping failed with error `, e, e.stack);
            return this;
        }
    }

    /**
     * @todo Убрать contract из аргументов и строго типизировать.
     */
    public toPVSDTO(contract): IPVSContractorDTO {
        const phones = contract.InsuredPhones || contract.InsurantPhones || contract.InsuredCommonPhone;
        const toPVSDTO = {
            ContractorId: contract.InsuredId,
            ContractorTypeCode: this.getContractorTypeCode(contract.InsuredContractorType),
            OrgName: contract.InsuredOrgName,
            FirstName: contract.InsuredPersonFirstName,
            LastName: contract.InsuredPersonLastName,
            MiddleName: contract.InsuredPersonMiddleName,
            ContractorBirthday: contract.InsuredPersonBirthday,
            OrgInn: contract.InsuredOrgINN,
            ContractorPhone: UgskPhone.phoneArrayToPhoneString(phones),
            PhoneVerificationId: contract.InsuredPhoneVerificationId,
        };
        for (const propName in toPVSDTO) {
            if (toPVSDTO[propName] === null) {
                delete toPVSDTO[propName];
            }
        }
        return toPVSDTO;
    }

    public getContractorTypeCode(type: string) {
        switch(type) {
            case ("физическое лицо"):
                return 1;
            case ("юридическое лицо"):
                return 2;
            case ("индивидуальный предприниматель"):
                return 3;
        }
    }

    public $annul(): angular.IPromise<void> {
        return this.getResourceProvider().annul(this).$promise.then((response) => { // TODO Надо бы типизировать
            if (angular.isDefined(response.PaymentCanBeChange)) {
                this.PaymentCanBeChange = response.PaymentCanBeChange;
            }
            this.setStatus(5);
        });
    }

    public $approve(): angular.IPromise<void> {
        return this.getResourceProvider().approve(this).$promise.then(() => {
            this.setStatus(3);
        });
    }

    public $unlock(): angular.IPromise<void> {
        return this.getResourceProvider().unlock({ Id: this.Id }).$promise
            .then((response) => {  // TODO Надо бы типизировать
                this.StatusName = "Проект"; // StatusName
                if (angular.isDefined(response.PaymentCanBeChange)) {
                    this.PaymentCanBeChange = response.PaymentCanBeChange;
                }
                this.setStatus(1);
            });
    }

    public $returnToRevision(): angular.IPromise<void> {
        return this.getResourceProvider().returnForRevision({
            Id: this.Id,
        }).$promise.then((response) => { // TODO Надо бы типизировать
            if (angular.isDefined(response.PaymentCanBeChange)) {
                this.PaymentCanBeChange = response.PaymentCanBeChange;
            }
            this.init(response);
            this.setStatus(4);
        });
    }

    public $save({
        isForced = false,
    } = {}): angular.IPromise<void> {
        return (() => {
            if (this.Id) {
                return this.getResourceProvider().updateContract({ isForced }, this).$promise;
            }
            return this.getResourceProvider().saveContract(this).$promise;
        })().then(({
            content: newContractData,
        }) => {
            this.init(newContractData);
            this.eventEmitter.emit("afterSave");
        });
    }

    public $sign(): angular.IPromise<void> {
        return this.getResourceProvider().signContract({
            Id: this.Id,
        }).$promise.then(() => {
            this.setStatus(2);
        });
    }

    public $reassign(NewOwnerId: number): angular.IPromise<void> {
        return this.getResourceProvider().reassign({
            Id: this.Id,
            NewOwnerId,
        }).$promise;
    }

    /**
     * @description Копирование данных объекта в текущий
     * @param {Object} data Базовый объект для копирования
     */
    public init(data) {
        for (const key in data) {
            if (!data.hasOwnProperty(key)) continue;
            //  ignore special properties like "$promise"
            if (key[0] === "$") continue;
            let value = data[key];
            //  http://jr.ugsk.ru/jr/browse/SL-930
            if (value !== null) {
                this[key] = value;
            }
        }
    }

    get eventEmitter(): EventEmitter {
        return this[eventEmitterKey];
    }
}
