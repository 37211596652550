import angular from "angular";
import { NgComponentController } from "infrastructure//NgController";
import { ILogic } from "infrastructure/interfaces/ILogic";
import { ISliderConfig } from "infrastructure/interfaces/ISliderConfig";
import { Employee } from "infrastructure/services/employee.service";
import { PhoneValidationService } from "infrastructure/services/phoneValidation.service";
import { Day, Guid } from "infrastructure/types";
import { Uuid } from "lib/uuid";
import moment from "moment";
import { GenderAgeExperiences, UAutoContract, UautoInsurantProxy } from "../../uAuto.factory";
import uAutoBeneficiary from "../ugsk-uauto-beneficiary/ugskUautoBeneficiary.component";
import uAutoCreditor from "../ugsk-uauto-creditor/ugskUautoCreditor.component";
import UgskUautoDriver from "../ugsk-uauto-driver/ugskUautoDriver.component";
import uAutoInsurant from "../ugsk-uauto-insurant/ugskUautoInsurant.component";
import template from "./ugskUautoParticipants.component.html";

const dateStringToAge = (ageStr: string): number => {
    if (!ageStr) {
        return 0;
    }
    const birthYearString = ageStr.slice(0, 4);
    const birthYear = Number.parseInt(birthYearString, 10);
    const currentYear = moment().year();
    return currentYear - birthYear;
};

const ageToDateString = (age: number): Day => {
    return moment().subtract(age, "years").startOf("year").format("YYYY-MM-DDT00:00:00");
};

// TODO Дождаться влития TS src/application/uAuto/uAuto.controller.js
class UAutoController {
    public Contract: UAutoContract;
    public employee: Employee;
    public phoneValidationService: PhoneValidationService;
    public updateFieldRepository: (name: string) => angular.IPromise<void>;
}

class ParticipantsController extends NgComponentController {
    public contract: UAutoContract;
    public parent: UAutoController;
    public phoneValidationService: PhoneValidationService;
    public insurantIsDriver: boolean;
    public insurant: UautoInsurantProxy;
    public insurantDriver: GenderAgeExperiences;
    public sliderConfig: Partial<ISliderConfig>;
    public preCalcOptionsPartiallyDisabled = false;
    public visibleAdditionalPhones = false;

    public onInit(): void {
        this.contract = this.parent.Contract;
        this.visibleAdditionalPhones = Boolean(
            this.contract && this.contract.Id
            && this.parent.employee && this.parent.employee.hasPermission("GetAdditionalPhones", "uAuto")
        );
        this.phoneValidationService = this.parent.phoneValidationService;
        this.$watch(
            () => this.contract.GenderAgeExperiences.length,
            () => {
                this.insurantDriver = this.contract.GenderAgeExperiences.find((driver) => {
                    return driver.guid === this.insurant.guid;
                });
                if (this.insurantDriver) {
                    this.insurantIsDriver = true;
                } else {
                    this.insurantIsDriver = false;
                }
            },
        );
        this.insurant = new UautoInsurantProxy(this.contract);
        this.sliderConfig = {
            grid: true,
        };
        this.$watch(
            () => this.preCalcByDriversListDisabled(),
            (newVal, oldVal) => {
                if (this.contract.isLocked()) {
                    return;
                }

                if (newVal !== oldVal) {
                    if (newVal === true && this.contract.DriversRestrictionApproachName === "Список водителей") {
                        this.contract.DriversRestrictionApproachName = null;
                    }
                }
            },
        );
        this.$watch(
          () => this.contract.InsuredContractorType,
          (newVal, oldVal) => {
              if (this.contract.isLocked()) {
                  return;
              }
              if (newVal !== oldVal && this.insurant.isLegalEntity()) {
                  this.clearDrivers();
              }
          },
        );
    }

    get logic(): ILogic {
        const vm = this;
        return {
            condition() {
                return !vm.contract.isLocked();
            },
            /* tslint:disable object-literal-sort-keys */
            rules: [{
                description: "Когда нажимается галка 'Страхователь является водителем'",
                properties: [
                    "insurantDriver",
                ],
                when: "insurantIsDriver and not(insurantDriver)",
                then: "insurantDriver = addDriver()",
            }, {
                description: "Когда отжимается галка 'Страхователь является водителем'",
                when: "not(insurantIsDriver) and insurantDriver",
                then: "insurantDriver.guid = getNewGuid(); insurantDriver = undefined",
            }, {
                description: "Когда нажата галка 'Страхователь является водителем'",
                when: "insurantDriver",
                then: "insurant.copyTo(insurantDriver)",
            }, {
                description: "Управляем доступностью полей 'Предварительный расчет' "
                    + "и 'По списку водителей', в зависимости от Типа страхователя",
                when: "insurant.isLegalEntity()",
                then: "preCalcOptionsPartiallyDisabled = true",
                else: "preCalcOptionsPartiallyDisabled = false",
            }],
            /* tslint:enable object-literal-sort-keys */
        };
    }

    get birthDate(): number {
        return dateStringToAge(this.contract.GenderAgeExperiences[0].birthday);
    }

    set birthDate(ageInYears: number) {
        this.contract.GenderAgeExperiences[0].birthday = ageToDateString(ageInYears);
    }

    get drivingExperience(): number {
        return dateStringToAge(this.contract.GenderAgeExperiences[0].drivingExperience);
    }

    set drivingExperience(ageInYears: number) {
        this.contract.GenderAgeExperiences[0].drivingExperience = ageToDateString(ageInYears);
    }

    get insurantIsLegal(): boolean {
        return this.insurant.isLegalEntity();
    }

    public canAddMoreDrivers(): boolean {
        return !this.contract.isLocked() && !this.contract.isFrozen() && this.contract.canAddMoreDrivers();
    }

    public addDriver(): GenderAgeExperiences | undefined {
        if (this.canAddMoreDrivers()) {
            const newGenderAgeExperiences = new GenderAgeExperiences();
            this.contract.GenderAgeExperiences.push(newGenderAgeExperiences);
            return newGenderAgeExperiences;
        }
    }

    public removeDriver(index: number): void {
        this.contract.GenderAgeExperiences.splice(index, 1);
    }

    public clearDrivers(): void {
        this.contract.GenderAgeExperiences.splice(0, this.contract.GenderAgeExperiences.length);
    }

    public onDriversRestrictionChanged(val: string): void {
        this.clearDrivers();
        if (val === "Предварительный расчёт") {
            this.addDriver();
        }
    }

    public updateFieldRepository(field: string): void {
        this.parent.updateFieldRepository(field);
    }

    public getNewGuid(): Guid {
        return Uuid.raw();
    }
    /**
     * @description: Костыль для обновления списка причин отсутствия акта осмотра
     */
    public onCreditorChanged(): void {
        this.updateFieldRepository("IsNew");
    }

    public onUseMaxKbmTimeoutChanged(): void {
        if (this.contract.UseMaxKbmTimeout) {
            const $window = this.di<angular.IWindowService>("$window");
            const result = $window.confirm(
                "Внимание! Время ожидания может существенно увеличить время расчета договора! Продолжить?"
            );

            if (!result) {
                this.contract.UseMaxKbmTimeout = false;
            }
        }
    }

    public preCalcByDriversListDisabled(): boolean {
        if (this.insurant.isLegalEntity()) {
            return false;
        }

        return this.preCalcOptionsPartiallyDisabled;
    }
}

export default angular.module("ugsk-uauto-participants", [
    uAutoInsurant,
    uAutoBeneficiary,
    uAutoCreditor,
    UgskUautoDriver,
]).component("ugskUautoParticipants", {
    bindings: {
        licenseDocumentType: "<",
        parent: "<",
    },
    controller: ParticipantsController,
    controllerAs: "vm",
    template,
}).name;
