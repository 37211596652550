import "application/components/ugsk-slider/ugskSlider.component";
import { Product } from "domain/classes/product.class";
import { IFLJournalController } from "../journal/iflJournal.controller";
import { ExpressToolbarController } from "./express-toolbar.controller";
import { ExpressController } from "./express.controller";
import { ExpressJournalToolbarController } from "./journal/expressJournal-toolbar.controller";

import template from "./body.html";

import angular from "angular";
import { ProductsService } from "infrastructure/services/products.service";
import manual from "./manual_express.pdf";

class Express extends Product {
    constructor() {
        super();
        this.code = "express";
        this.groupName = "Страхование имущества";
        this.productName = "Экспресс";
        this.description = "Страхование внутренней отделки и инженерного оборудования квартир и домов";
        this.insuranceRules = {
            base: "https://ugsk.ru/about/pravila/Imushestvo.pdf",
        };
        this.insurerCanBeLegalEntity = false;
        this.logo = "nav_express";
        this.manualUrl = manual;
        this.sort = 40;
        this.template = template;
        this.controller = ExpressController;
        this.toolbarController = ExpressToolbarController;
        this.journalController = IFLJournalController;
        this.journalToolbarController = ExpressJournalToolbarController;
    }
}

export default angular
.module("app.express", [
    "touchSpin",
    "ugsk.components.slider",
])
.run((
    productsService: ProductsService,
) => {
    "ngInject";

    productsService.register(new Express());
})
.name;
