import "icheck";
import { NgComponentController } from "infrastructure/NgController";
import "./icheckUgsk.css"; // icheck - классы для нас

class iCheckComponentController extends NgComponentController {
    get ngModel() {
        return this.virtualModel;
    }
    set ngModel(val) {
        this.virtualModel = val;
        if (this.inputElement) {
            let isChecked = false;
            if (this.type === "checkbox") {
                isChecked = this.virtualModel;
            } else {
                isChecked = (this.virtualModel === this.ngValue);
            }
            if (isChecked) {
                this.inputElement.iCheck("check");
            } else {
                this.inputElement.iCheck("uncheck");
            }
        }
    }

    onInit() {
        this.virtualModel = this.ngModel;
        this.name = this.$element.attr("name");
        this.type = this.$element.attr("type") || "checkbox";
        this.inputElement = this.$element.find("input").first();
        if (this.type === "checkbox") {
            this.inputElement.prop("checked", this.virtualModel);
        } else {
            this.inputElement.prop("checked", (this.virtualModel === this.ngValue));
        }
        this.inputElement
            .attr("type", this.type)
            .attr("name", this.name)
            .iCheck({
                checkboxClass: "icheckbox_ugsk",
                radioClass: "iradio_ugsk",
            })
            .on("ifChecked", () => {
                if (angular.isFunction(this.onChange)) {
                    this.onChange({
                        newVal: this.ngValue,
                    });
                }
                if (this.type === "radio") {
                    this.ngModelCtrl.$setViewValue(this.ngValue);
                }
            })
            .on("ifChanged", event => {
                if (this.type === "checkbox") {
                    this.ngModelCtrl.$setViewValue(event.target.checked);
                }
            });

        this.fieldset = angular.element(this.$element).closest("fieldset");
        this.watchParentFieldSet();
    }
    isDisabled() {
        return (this.fieldset) ? this.fieldset.attr("disabled") === "disabled" || this.ngDisabled : this.ngDisabled;
    }
    watchParentFieldSet() {
        this.$watch(() => this.isDisabled(), newVal => {
            if (newVal) {
                this.inputElement.iCheck("disable");
            } else {
                this.inputElement.iCheck("enable");
            }
        });
    }
}

const iCheckComponent = {
    bindings: {
        ngDisabled: "<",
        ngModel: "=",
        ngValue: "<",
        onChange: "&",
    },
    controller: iCheckComponentController,
    controllerAs: "vm",
    require: {
        ngModelCtrl: "^ngModel",
    },
    template: `
        <input
            type="{{ vm.type }}"
            ng-value="vm.ngValue"
            ng-model="vm.ngModel"
            ng-disabled="vm.isDisabled()"/>
    `,
};
angular.module("ugsk.components.icheck", []).component("icheck", iCheckComponent);
