import { IModalInstanceService } from "angular-ui-bootstrap";
import IPreviousContractRecord from "infrastructure/interfaces/IPreviousContractRecord";

export class UgskPreviousContractInfoModalController {
    constructor(
        private $uibModalInstance: IModalInstanceService,
        public previousPolicy: IPreviousContractRecord[],
    ) {
    }

    static get $inject() {
        return ["$uibModalInstance", "previousPolicyData"];
    }

    public close(): void {
        this.$uibModalInstance.dismiss();
    }
}
