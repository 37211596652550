import angular from "angular";
import { CONTRACT_STATUSES, USAGE_PURPOSES_ENUM } from "application/constants";
import { OsagoToolbarController } from "application/osago/osago-toolbar.controller";
import { Employee } from "infrastructure/services/employee.service";
import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { IModalService, IModalServiceInstance } from "angular-ui-bootstrap";
import { ReliableDriveContract } from "application/osago/classes/ReliableDriveContract";
import modalTemplate from "./reliableDriveForcedCalculation.html";
import { NotifyService } from "infrastructure/services/notifyService";
import { USER_ROLES } from "application/constants";

class ReliableDriveForcedCalculationModalController {
    public premium: number = this.contract.Premium;

    constructor(
        public readonly $uibModalInstance: IModalServiceInstance,
        private readonly contract: ReliableDriveContract,
        private readonly employee: Employee,
        private notifyService: NotifyService,
    ) { }

    public calc(): void {
        if (
            this.contract.Premium &&
            this.premium < this.contract.Premium
            && !([
                USER_ROLES.UNDERWRITER,
                USER_ROLES.CURATOR_GO,
                USER_ROLES.CURATOR_FILIAL
            ] as string[]).includes(this.employee.info.Role)
        ) {
            this.notifyService.errorMessage(
                "Ошибка",
                "Уменьшить стоимость Надежной поездки могут только сотрудники с ролью Андеррайтер, Куратор ГО, Куратор филиала.",
            );
        } else {
            this.contract.Premium = this.premium;
            this.$uibModalInstance.close({ premium: this.premium });
        }
    }

    static get $inject() {
        return ["$uibModalInstance", "contract", "employee", "notifyService"];
    }
}

export default {
    action(context: OsagoToolbarController) {
        const $uibModal = context.di<IModalService>("$uibModal");
        $uibModal.open({
            controller: ReliableDriveForcedCalculationModalController,
            controllerAs: "vm",
            resolve: {
                contract: context.reliableDriveContract,
                employee: context.employee,
                notifyService: context.notifyService,
            },
            template: modalTemplate,
        }).result.then((data: { premium: number }) => {
            if (angular.isNumber(data.premium) && data.premium >= 0) {
                return context.forceCalcReliableDrive(data.premium);
            }
        }).catch((e) => {
            console.log(e);
        });
        return;
    },
    disabled(context: OsagoToolbarController): boolean {
        const isTaxiUsagePurpose = context.Contract.UsagePurpose === USAGE_PURPOSES_ENUM.TAXI;
        const isOsagoContractStatusSuitable = context.Contract.ContractStatusId === CONTRACT_STATUSES.DRAFT;
        const isReliableDriveAvailable = context.reliableDriveContract.Id > 0 &&
            context.reliableDriveContract.isActive() || context.reliableDriveContract.isUserChoice();
        const isRCategory = ["R", "R1"].includes(context.Contract.ContractCategory);
        const hasPermission = context.employee.hasPermission("ForceCalculate", "osago");

        const condition = !isOsagoContractStatusSuitable || !isReliableDriveAvailable;

        if ((isTaxiUsagePurpose || isRCategory) && hasPermission) {
            return condition;
        }

        return condition || isTaxiUsagePurpose;

    },
    visible(context: OsagoToolbarController): boolean {
        const isReliableDriveAvailable = context.reliableDriveContract.isActive()
            || context.reliableDriveContract.isUserChoice() && context.reliableDriveContract.Id > 0;
        return context.viewType === "form" && isReliableDriveAvailable;
    },
    icon: "fa-calculator",
    title: "Тариф дополнительного продукта",
    view: "form",
} as IExtraOptionPrototype;
