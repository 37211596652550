/**
 * @description Валидация КПП
 * @see https://www.b-kontur.ru/enquiry/239
 */

interface IKppScope extends ng.IScope {
    model: string;
    name: string;
    maxlength: number;
    onKppValidationError: (e: { error: {
        errors: string[],
        name: string,
    }}) => void;
}

export function KppValidationDirective(): ng.IDirective {
    "ngInject";

    return {
        link: (
            scope: IKppScope,
        ) => {
            scope.$watch("model", () => {
                if (!scope.model || typeof scope.model !== "string" || scope.model.length !== scope.maxlength) {
                    return;
                }
                if (/^\d{4}[\dA-Z]{2}\d{3}$/.test(String(scope.model)) === false) {
                    const error = {
                        errors: [
                            "Неверный формат значения. Проверьте корректность введенного КПП.",
                        ],
                        name: scope.name,
                    };
                    scope.onKppValidationError({ error });
                }
            });
        },
        require: "ngModel",
        restrict: "A",
        scope: {
            maxlength: "<",
            model: "=ngModel",
            name: "@ugskValidation",
            onKppValidationError: "&",
        },
    };
}
