import angular from "angular";
import { NgComponentController } from "infrastructure/NgController";

const Accessories = [
    "Панорамная крыша", "Камера заднего вида", "Магнитола",
    "CD/DVD-проигрыватель", "CD-чейнджер", "Телевизор", "Защитные дуги: задние",
    "Защитные дуги: пороги", "Салон: кожа", "Салон: велюр", "Салон: ткань",
    "Парктроник: передний", "Парктроник: задний", "Фары: ксенон", "Фары: противотуманные",
    "Диски колёс: литые", "Диски колёс: штампованные", "Диски колёс: кованые",
    "Система кругового обзора", "Электрообогрев лобового стекла", "Навигационная система", "Светодиодные LED-фары",
    "Солнцезащитные шторки: боковые", "Программируемый предпусковой отопитель",
    "Передний центральный подлокотник", "Солнцезащитные шторки: задняя",
];

interface IAccessory {
    name: string;
    value: boolean;
}

class UautoVehicleAccessories extends NgComponentController {
    public accessories: string[];
    public internalAccessories: IAccessory[] = [];

    public accessoryChanged(val: IAccessory): void {
        const { name } = val;
        const checked = val.value;
        const inArray = this.accessories.includes(name);
        if (checked && !inArray) {
            this.accessories.push(name);
        } else if (!checked && inArray) {
            const itemIndex = this.accessories.indexOf(name);
            this.accessories.splice(itemIndex, 1);
        }
    }

    public onInit(): void {
        this.internalAccessories = Accessories.map((accessory) => ({
            name: accessory,
            value: false,
        }));
        this.$watch(() => this.accessories.join(), () => {
            this.internalAccessories.forEach((item) => {
                item.value = this.accessories.includes(item.name);
            });
        });
    }
}

export default angular.module("ugsk-uauto-vehicle-accessories", [])
    .component("ugskUautoVehicleAccessories", {
        bindings: {
            accessories: "<",
        },
        controller: UautoVehicleAccessories,
        controllerAs: "vm",
        template: require("./ugskUautoVehicleAccessories.component.html"),
    }).name;
