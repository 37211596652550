import moment from "moment";

import { BoxAccidentContractDTOBase, BoxAccidentPersonBase } from "../boxAccident.factory";

export class SafeCarAccidentContractDTO extends BoxAccidentContractDTOBase {
    constructor(prototype) {
        super(prototype);
        this.InsuranceProductId = 19;
    }
}

export class SafeCarAccidentInsuredPerson extends BoxAccidentPersonBase {
    constructor() {
        super();
        this.InsuredPersonBirthday = moment().subtract(30, "year").format("YYYY-MM-DD");
    }
}
