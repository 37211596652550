import { StateRegistry } from "@uirouter/angularjs";
import angular from "angular";
import "./component/ugskPasswordCheck.component";
import { SelfRegistrationController } from "./selfRegistration.controller";
import "./selfRegistration.style.css";

export default angular.module("app.selfRegistration", ["ugsk.selfRegistration.passwordCheck"])
.run(function config($stateRegistry: StateRegistry) {
    "ngInject";

    $stateRegistry.register({
        abstract: true,
        name: "app.selfRegistration",
        url: "/selfRegistration",
    });
    $stateRegistry.register({
        name: "app.selfRegistration.index",
        url: "/",
        views: {
            "body@": {
                controller: SelfRegistrationController,
                controllerAs: "vm",
                template: require("./selfRegistration.html"),
            },
        },
    });
}).name;
