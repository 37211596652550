import angular from "angular";
import { UAutoContract } from "application/uAuto/uAuto.factory";
import { IBank } from "application/uAuto/uAuto.repository";
import { NgComponentController } from "infrastructure/NgController";
import "./ugskUautoCreditor.component.css";

class UautoCreditor extends NgComponentController {
    public contract: UAutoContract;
    public banks: IBank[];
    public autoIsInCredit: boolean;
    public bankIsAccredited: boolean;
    public bankAddress: string;
    public onChange: () => void;

    public onInit(): void {
        if (!angular.isArray(this.banks)) {
            this.banks = [];
        }
        this.autoIsInCredit = Boolean(this.contract.CreditorId);
        this.bankIsAccredited = false;
        this.bankAddress = null;
        if (this.autoIsInCredit) {
            const bank = this.banks.find((item) => item.CreditorId === this.contract.CreditorId);
            if (bank) {
                this.bankIsAccredited = bank.IsAccredited;
                // TODO Хак. С бэка адрес приходит null
                this.bankAddress = this.contract.CreditorAddress || bank.CreditorAddress;
            }
        }

        this.$watch(() => this.contract.CreditorId, (newVal, oldVal) => {
            if (newVal === null && String(oldVal) === "") {
                this.changeCreditor();
            }
            if (newVal === null && oldVal !== null && this.contract.CreditorId !== null) {
                this.changeCreditor();
            }
            if (newVal !== oldVal) {
                const bank = this.banks.find((item) => item.CreditorId === newVal);
                if (bank) {
                    this.setCreditorValuesToContract(bank);
                } else {
                    this.clearCreditor();
                }
            }
        });
    }

    public formatBankName(selectedId: number): string | undefined {
        const bank = this.banks.find((item) => item.CreditorId === selectedId);
        return (bank) ? bank.CreditorName : undefined;
    }

    public setCreditorValuesToContract(bank?: IBank): void {
        if (angular.isObject(bank)) {
            this.bankIsAccredited = bank.IsAccredited;
            this.bankAddress = bank.CreditorAddress;
            ["CreditorId", "CreditorContractProps", "CreditorAddress"].forEach((key) => {
                this.contract[key] = (bank[key] || null);
            });
        } else {
            this.bankIsAccredited = undefined;
            this.bankAddress = undefined;
            ["CreditorId", "CreditorContractProps", "CreditorAddress"].forEach((key) => {
                this.contract[key] = null;
            });
        }
    }

    public changeCreditor(): void {
        if (!this.autoIsInCredit) {
            this.clearCreditor();
        }
        this.onChange();
    }

    public clearCreditor(): void {
        this.setCreditorValuesToContract();
    }

    public onAutoIsInCreditChanged(): void {
        if (!this.autoIsInCredit) {
            this.clearCreditor();
        }
    }
}

export default angular.module("ugsk-uauto-creditor", [])
    .component("ugskUautoCreditor", {
        bindings: {
            banks: "<",
            contract: "<",
            onChange: "&",
        },
        controller: UautoCreditor,
        controllerAs: "vm",
        template: require("./ugskUautoCreditor.component.html"),
    }).name;
