import { StateService } from "@uirouter/core";
import angular, { IHttpResponse } from "angular";
import { PAYMENT_KINDS, UAUTO_PROGRAM_TOTAL_THEFT_PLUS } from "application/constants";
import { UAutoController } from "application/uAuto/uAuto.controller";
import { UAutoContract } from "application/uAuto/uAuto.factory";
import { IInsuranceAmountType } from "application/uAuto/uAuto.repository";
import { IPolicySerialsStatesItem } from "infrastructure/interfaces/IPolicySerialsStatesItem";
import { NgComponentController } from "infrastructure/NgController";
import { EnvService } from "infrastructure/services/env.service";
import moment from "moment";
import { IRepositoryItem } from "infrastructure/Repository.class";

interface IDiscountIsNumbericCollection {
    title: string;
    value: boolean;
}

class UautoContractParams extends NgComponentController {
    public parent: UAutoController;
    public contract: UAutoContract;
    public policySerialsStatesMap = new Map<string, boolean>();
    public policySerial: string;
    public defaultPolicySerial: string;
    public discountIsNumbericCollection: IDiscountIsNumbericCollection[];
    public maxContractDate = moment().format("YYYY-MM-DD");
    private $state: StateService;
    private $interval: ng.IIntervalService;
    private envService: EnvService;
    private virtualInsuranceAmountType: IInsuranceAmountType;
    private contractFromChanged = false;

    public onInit(): void {
        this.$state = this.di<StateService>("$state");
        this.$interval = this.di<ng.IIntervalService>("$interval");
        this.envService = this.di<EnvService>("envService");
        this.contract = this.parent.Contract;
        this.loadPolicySerialsStates().then(({ data }) => {
            data.forEach((policySerial) => {
                this.policySerialsStatesMap.set(policySerial.Value, policySerial.IsAutoGenerated);
            });
        });
        this.defaultPolicySerial = this.contract.getRepository().PolicySerial
            ? this.contract.getRepository().PolicySerial.Collection[0].PolicySerial
            : this.policySerial;

        this.discountIsNumbericCollection = [{
            title: "В рублях",
            value: true,
        }, {
            title: "В процентах",
            value: false,
        }];

        this.updateInsuranceAmountType("InsuranceProgram");
        const dateFrom = this.contract.ContractFrom;
        const signingDate = this.contract.SigningDate;
        if (moment(dateFrom).diff(moment(signingDate), "days") < 0) {
            const signingTime = moment(signingDate);
            const subsractDate = moment(signingTime, "HH:mm:ss").format("YYYY-MM-DDTHH:mm:00");
            const dateFromNew = moment(signingDate).subtract(subsractDate).format("YYYY-MM-DDTHH:mm:00");
            this.contract.ContractFrom = dateFromNew;
        }

        if (this.contract.isBlank()) {
            this.contract.ContractFrom = moment().add(1, "day").startOf("day").format();
        }

        this.setInitialPolicySerial();
    }

    get insuranceAmountType(): IInsuranceAmountType {
        return this.virtualInsuranceAmountType;
    }

    set insuranceAmountType(val: IInsuranceAmountType) {
        if (!val) {
            return;
        }
        this.contract.InsuranceAmountType = val.InsuranceAmountType;
        this.contract.InsuranceAmountNumber = val.InsuranceAmountNumber;
        this.contract.Gap = val.Gap;
        this.virtualInsuranceAmountType = val;
    }

    public getContractFromMinDate() {
        if (this.contract.FactPayments[0].PaymentKindId === PAYMENT_KINDS.INTERNET_ACQUIRING) {
            return moment(this.contract.SigningDate).add(1, "hour").format();
        }

        if (!this.contractFromChanged) {
            return null;
        }

        return this.contract.SigningDate;
    }

    public onContractFromChanged() {
        this.contractFromChanged = true;
    }

    public onRepositoryFieldChanged(name: string): angular.IPromise<void> {
        return this.parent.updateFieldRepository(name).then(() => {
            this.updateInsuranceAmountType(name);
        });
    }

    public setValidContractFrom(momentValue: moment.Moment): moment.Moment {
        if (!this.contractFromChanged) {
            return momentValue;
        }
        const signingDateMoment = moment(this.contract.SigningDate);
        if (signingDateMoment.isAfter(momentValue)) {
            return signingDateMoment.add(1, "minute");
        }
        return momentValue;
    }

    public changePolicySerialAndPolicyNumber({ PolicySerial }) {
        if (this.contract.PolicySerial !== PolicySerial) {
            this.contract.PolicyNumber = null;
        }
        this.contract.PolicySerial = PolicySerial;
    }

    public loadPolicySerialsStates(): ng.IPromise<IHttpResponse<IPolicySerialsStatesItem[]>> {
        const apiUrl: string = this.envService.read("apiUrl");
        const $http = this.di<angular.IHttpService>("$http");
        return $http.get(`${apiUrl}/PolicySerials`);
    }

    public updateInsuranceAmountType(name: string): void {
        if (name === "InsuranceProgram" || name === "PaymentForm") {
            this.insuranceAmountType = this.contract.getRepository().InsuranceAmountType.Collection.find((type) =>
                type.InsuranceAmountType === this.contract.InsuranceAmountType &&
                type.InsuranceAmountNumber === this.contract.InsuranceAmountNumber &&
                type.Gap === this.contract.Gap);
        }
    }

    public getFranchiseAmountCollection() {
        return this.contract.getRepository()
            .FranchiseAmount.Collection.sort((valueA, valueB) => valueA.FranchiseAmount - valueB.FranchiseAmount);
    }

    public getPolicySerial(): string {
        const item = this.contract.getRepository().PolicySerial.Collection
            .find((value) => value.PolicySerial === this.contract.PolicySerial);
        return item ? item.PolicySerial : "";
    }

    public isUnionPayVisible(): boolean {
        return this.contract.InsuranceProgram === UAUTO_PROGRAM_TOTAL_THEFT_PLUS;
    }

    private setInitialPolicySerial(): void {
        const policySerialCollection = this.contract.getRepository().PolicySerial.Collection;
        if (this.contract.isBlank() && Array.isArray(policySerialCollection) && policySerialCollection.length > 0) {
            this.contract.PolicySerial = policySerialCollection[0].PolicySerial;
        }
    }
}

export default angular.module("app.uauto.components.contract-params", []).component("ugskUautoContractParams", {
    bindings: {
        parent: "<",
    },
    controller: UautoContractParams,
    controllerAs: "vm",
    template: require("./ugskUautoContractParams.component.html"),
}).name;
