import angular, { IWindowService } from "angular";
import { NgComponentController } from "infrastructure/NgController";
import template from "./ugskOsagoUAutoContractInfo.component.html";
import { StateService } from "@uirouter/angularjs";

class UgskOsagoUAutoContractInfoComponentController extends NgComponentController {
    public kaskoContractId: number;
    public policyNumber: string;
    public policySerial: string;
    public programName: string;

    public openContract(): void {
        if (!this.kaskoContractId) {
            return;
        }

        const $state = this.di<StateService>("$state");
        const $window = this.di<IWindowService>("$window");
        const href = $state.href("app.uAuto.index", {
            id: this.kaskoContractId,
        }, {
            inherit: false,
        });
        $window.open(href, "_blank");
    }
}

export default angular.module("app.osago.components.uauto-contract-info", [])
    .component("ugskOsagoUautoContractInfo", {
        bindings: {
            kaskoContractId: "<",
            policyNumber: "<",
            policySerial: "<",
            programName: "<",
        },
        controller: UgskOsagoUAutoContractInfoComponentController,
        controllerAs: "vm",
        template,
    }).name;
