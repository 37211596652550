import { Transition } from "@uirouter/core";
import * as angular from "angular";
import moment from "moment";

import { BaseProductBodyController } from "application/baseProduct.body.controller.ts";
import IScope from "infrastructure/interfaces/IScope";
import { ITouchSpinConfig } from "infrastructure/interfaces/ITouchSpinConfig";
import { BoxVHIContract, BoxVHIInsurantProxy, BoxVHIRepository, BoxVHIRepositoryKeys  } from "./boxVHI.factory";
import { Helpers } from "infrastructure/app.helpers";
import { NotifyService } from "infrastructure/services/notifyService";

export class BoxVHIController extends BaseProductBodyController {
    public Contract: BoxVHIContract;
    public insurant: BoxVHIInsurantProxy;
    public discountTouchSpinConfig: ITouchSpinConfig;
    public underwriterCoefficientTouchSpin: ITouchSpinConfig;
    public contractMinDate: string;
    public contractMaxDate: string;
    public discountCollection: Array<{Id: number; Value: string}>;
    public phoneVerificationDisabled: boolean;
    public englishInsurantNameIsVisible: boolean;
    private setContractToThisYearEnd = 13;

    constructor($injector: angular.auto.IInjectorService, $transition$: Transition,
                params: any, $scope: IScope) {
        super($injector, $transition$, params, $scope);
    }

    protected get Repository() {
        return super.Repository as BoxVHIRepository;
    }

    public $onInit() {
        super.$onInit();
        this.phoneValidationService.setContractRequiredFields({
            individual: [
                { description: "Фамилия страхователя", field: "InsuredLastName" },
                { description: "Имя страхователя", field: "InsuredFirstName" },
                { description: "Дата рождения страхователя", field: "InsuredBirthday" },
            ],
            legalEntity: [
                { description: "Наименование организации страхователя", field: "InsuredOrgName" },
                { description: "ИНН организации страхователя", field: "InsuredOrgINN" },
            ],
        });

        this.insurant = new BoxVHIInsurantProxy(this.Contract);

        this.discountTouchSpinConfig = {
            boostat: 3,
            buttondown_class: "btn btn-white",
            buttonup_class: "btn btn-white",
            max: 25,
            maxboostedstep: 5,
            min: 0,
            postfix: "%",
            step: 1,
        };

        this.underwriterCoefficientTouchSpin = {
            boostat: 5,
            buttondown_class: "btn btn-white",
            buttonup_class: "btn btn-white",
            decimals: 2,
            delimiter: ",",
            forcestepdivisibility: "round",
            max: this.Repository.UnderwriterCoefficientRestriction.Collection[0].MaxValue,
            maxboostedstep: 10,
            min: this.Repository.UnderwriterCoefficientRestriction.Collection[0].MinValue,
            step: 0.01,
        };

        if (!this.Contract.ContractStatusId) {
            this.Contract.SigningDate = moment().format("YYYY-MM-DD");
            this.Contract.ContractFrom = this.getContractFromMinDate();
            this.insuranceTermChanged();
        }

        this.contractMinDate = this.getContractFromMinDate();
        this.contractMaxDate = moment(this.Contract.SigningDate, "YYYY-MM-DD").add(3, "months").format("YYYY-MM-DD");

        this.discountCollection = this.getDiscountCollection();

        if (this.Contract.ContractStatusId === 1
            && moment(this.Contract.SigningDate, "YYYY-MM-DD").isBefore(moment().format("YYYY-MM-DD"), "day")) {
            const [notifyService] = this.di(["notifyService"]);
            notifyService.warningMessage(
                "Внимание",
                "По данному договору устарела дата заключения договора. "
                + "Рассчитайте договор заново для обновления даты заключения договора."); // ;)
        }

        if (this.getCurrentSubProduct() === "ImmigrantVHI") {
            this.phoneVerificationDisabled = true;
            this.englishInsurantNameIsVisible = true;
        }

        const helpers = this.di<Helpers>("helpers");
        if (helpers.isProductDisabled(this.getCurrentSubProduct()) && !this.isProductLocked) {
            this.Contract.isLocked = () => true; // :(
            const notifyService = this.di<NotifyService>("notifyService");
            notifyService.infoMessage("Внимание", "Продукт отключен. Договор доступен только для просмотра.");
        }
    }
    public getPhoneValidationService() {
        if (this.getCurrentSubProduct() === "ImmigrantVHI") {
            return undefined;
        }
        return this.phoneValidationService;
    }

    public setInsuredGenderValue(value) {
        if (value) {
            this.Contract.InsuredGender = value;

            if (this.Validation.hasError("InsuredGender")) {
                this.Validation.removeError("InsuredGender");
            }
        }
    }

    public formatCountryLabel(model) {
        const country = this.Repository.InsuredCountry.Collection.find((item) => model === item.CountryId);

        if (!country) {
            return undefined;
        }

        return country.Country;
    }

    public getCurrentSubProduct() {
        return this.Contract.getSubProductCode();
    }

    public isFieldVisible(fieldName: BoxVHIRepositoryKeys, resetValue: boolean) {
        if (this.Repository.hasOwnProperty(fieldName)) {
            const isVisible = this.Repository[fieldName].State === 0;
            // TODO при скрытиии поля на форме, так же происходит очистка значения поля в контракте
            if (!isVisible && resetValue) {
                this.Contract[fieldName] = null;
            }

            return this.Repository[fieldName].State === 0;
        }

        return true;
    }

    /**
     * @override
     */
    public updateFieldRepository(fieldName) {
        this.blockUI.start("Обновляются справочные данные");
        return this.Repository.update(fieldName, this.Contract).then(() => {
            this.blockUI.stop();
        });
    }

    public isShowInsuredRegistrationDate() {
        // id:7 - паспорт иностранного гражданина
        const foreignDocTypeId = 7;
        const isImmigrantVHI = (this.getCurrentSubProduct() === "ImmigrantVHI");

        return (this.Contract.InsuredDocTypeId === foreignDocTypeId) && isImmigrantVHI;
    }

    // приватные методы:
    private getContractFromMinDate() {
        return moment(this.Contract.SigningDate, "YYYY-MM-DD")
            .add(this.Repository.DaysToContractActivityFromSigningDate.Value[0].Value, "days")
            .format("YYYY-MM-DD");
    }

    private insuranceTermChanged() {
        const endDate = `${moment(this.Contract.ContractFrom, "YYYY-MM-DD").get("year")}-12-31`;
        const item = this.Repository.InsuranceTerm.Collection
            .find((term) => term.InsuranceTermId === this.Contract.InsuranceTermId);

        if (!item) {
            return;
        }
        // константа setContractToThisYearEnd, сравнивается с значением приходящим с Beckend, для определения
        // когда считать до конца года, а когда с прибавлением 3мес , 6мес
        if (item.InsuranceTermCode === this.setContractToThisYearEnd) {
            this.Contract.ContractTo = moment(endDate, "YYYY-MM-DD").creationData().input as string;
        } else {
            this.Contract.ContractTo = moment(this.Contract.ContractFrom, "YYYY-MM-DD")
                .add(item.InsuranceTermMonthCount, "months")
                .subtract(1, "days")
                .format("YYYY-MM-DD");
        }
    }

    private getDiscountCollection() {
        const collection = [];
        const minValue = this.Repository.DiscountRestriction.Value[0].Value;
        const maxValue = this.Repository.DiscountRestriction.Value[1].Value;
        for (let i = minValue; i <= maxValue; i++) {
            collection.push({
                Id: i,
                Value: i > 0 ? `${i} %` : "Без скидки",
            });
        }

        return collection;
    }
}
