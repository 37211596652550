import { StateService } from "@uirouter/angularjs";
import { NgController } from "infrastructure/NgController";
import { AuthenticationService } from "infrastructure/services/authentication.service";
import { Employee } from "infrastructure/services/employee.service";
import { NotifyService } from "infrastructure/services/notifyService";
import { ValidationService } from "infrastructure/services/validation.service";
import { IEmployeeResource } from "infrastructure/interfaces";

export class PasswordManagementController extends NgController {
    public currentPassword: string = null;
    public newPassword: string = "";
    public newPasswordConfirm: string = "";
    public passwordExist: boolean = false;
    public isVisiblePassword: boolean = false;
    public savingProcess: boolean = false;
    private employee: Employee = null;
    private $state: StateService = null;

    public $onInit(): void {
        this.employee = this.resolve("employee");
        this.$state = this.di<StateService>("$state");
        this.passwordExist = (this.employee.info.IsPasswordMustBeChanged) ? false : this.employee.info.IsPasswordExist;
    }

    public cancel(): void {
        if (this.passwordExist) {
            this.$state.go("app.userProfile.index");
        } else {
            this.logout(false);
        }
    }

    public apply(): void {
        const validation = this.di<ValidationService>("validationService");
        let checkPasswords = true;
        if (this.passwordExist && this.currentPassword && this.currentPassword.trim() === "") {
            validation.addError("currentPassword", ["Укажите старый пароль"]);
            checkPasswords = false;
        }
        if (this.newPassword.trim() === "") {
            validation.addError("newPassword", ["Укажите новый пароль"]);
            checkPasswords = false;
        }
        if (this.newPassword !== this.newPasswordConfirm) {
            validation.addError("newPasswordConfirm", ["Введенные пароли не совпадают"]);
            checkPasswords = false;
        }
        if (!checkPasswords) {
            return;
        }

        const notifyService = this.di<NotifyService>("notifyService");
        const employeeResource = this.di<IEmployeeResource>("employeeResource");

        this.savingProcess = true;
        employeeResource.saveUserPassword({
            oldPassword: this.currentPassword,
            password: this.newPassword,
        }).$promise.then(() => {
            notifyService.successMessage("Пароль успешно сохранен");
            this.logout();
        }).finally(() => {
            this.savingProcess = false;
        });
    }

    public logout(withAssistanceSAS = true) {
        const authenticationService = this.di<AuthenticationService>("authenticationService");
        const isImplicitFlowToken = authenticationService.isImplicitFlowToken();
        this.employee.deImpersonate(); // В ней идет вызов deleteEmployeeInfo, поэтому отдельно удалять не нужно
        authenticationService.logout(); //  Если была авторизация НЕ через домен, то в logout() свой переход
        if (!isImplicitFlowToken) { // Иначе переходим на страницу авторизации самостоятельно
            if (withAssistanceSAS) {
                this.$state.go("login", {assistanceSAS: true});
            } else {
                this.$state.go("login");
            }
        }
    }
}
