import angular from "angular";
import { IModalServiceInstance } from "angular-ui-bootstrap";
import { ExcelReaderService } from "infrastructure/services/excelReader.service";
import { NotifyService } from "infrastructure/services/notifyService";

export class InsuredPersonUploadController {
    static get $inject() {
        return [
            "$uibModalInstance", "notifyService", "excelReaderService", "requiredFields",
        ];
    }

    constructor(
        private $uibModalInstance: IModalServiceInstance,
        private notifyService: NotifyService,
        private excelReaderService: ExcelReaderService,
        private requiredFields: string[],
    ) { /** */ }

    get browserIsValid(): boolean {
        return this.excelReaderService.isValidBrowser();
    }

    public close(data?: any[]): void {
        this.$uibModalInstance.close({
            $data: data,
        });
    }

    public isValidFile(item: string[]): boolean {
        if (!this.requiredFields || this.requiredFields.length === 0) {
            this.requiredFields = ["Фамилия", "Имя", "Отчество"];
        }
        for (let i = 0; i < this.requiredFields.length; i++) {
            if (item && !item.hasOwnProperty(this.requiredFields[i])) {
                return false;
            }
        }
        return true;
    }

    public fileChanged(file: File): void {
        const toasterTitle = "Импорт списка застрахованных";
        this.excelReaderService.readFile(file).then((data) => {
            if (data) {
                if (angular.isArray(data) && data.length === 0) {
                    this.notifyService.warningMessage(toasterTitle, "Данных не найдено");
                    this.close();
                    return;
                }

                if (!angular.isArray(data) || !this.isValidFile(data[0])) {
                    this.notifyService.errorMessage(toasterTitle, "Неверная структура файла");
                    this.close();
                    return;
                }
                this.close(data);
            }
        }).catch(() => {
            this.notifyService.errorMessage(toasterTitle, "Ошибка чтения Excel-файла");
            this.close();
        });
    }
}
