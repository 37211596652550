import { IODataResourceResult } from "infrastructure/interfaces";
import { NgTableParams } from "ng-table";

export interface ITableItem {
    Id: number;
}

/**
 * @description Пока используется только для типизации.
 * @todo: Перенести реализацию сюда из src\infrastructure\app.service.js
 */
export default class InfiniteScrollService<T extends ITableItem> {
    public contracts: T[] = [];
    public loading = true;
    public hasErrors = false;
    private currentPage = 1;
    private maxPage = Number.MAX_VALUE;
    private ids: number[] = [];

    public getMoreData(params: NgTableParams<T>) {
        if (!this.hasErrors && !this.loading && this.canGetMore()) {
            this.loading = true;
            this.currentPage++;
            params.page(this.currentPage).reload();
        }
    }

    public canGetMore() {
        return this.currentPage < this.maxPage;
    }

    public loadData(params: NgTableParams<T>, data: IODataResourceResult<T>): T[] {
        if (params.page() === 1) {
            this.currentPage = 1;
            this.contracts = data.value;
        } else {
            const itemsToInsert = data.value.filter((record) => !this.ids.includes(record.Id));
            this.contracts = this.contracts.concat(itemsToInsert);
        }
        if (data.value.length < params.count()) {
            this.maxPage = this.currentPage;
        } else {
            this.maxPage = Number.MAX_VALUE;
        }
        this.loading = false;
        this.ids = this.contracts.map((i) => i.Id);
        return this.contracts;
    }
}
