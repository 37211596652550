import moment from "moment";

import { BoxAccidentContractDTOBase, BoxAccidentPersonBase } from "../boxAccident.factory";

export class AdultAccidentContractDTO extends BoxAccidentContractDTOBase {
    constructor(prototype) {
        super(prototype);
        this.InsuranceProductId = 16;
    }
}

export class AdultAccidentInsuredPerson extends BoxAccidentPersonBase {
    constructor() {
        super();
        this.InsuredPersonBirthday = moment().subtract(30, "year").format("YYYY-MM-DD");
    }
}
