import angular from "angular";
import { ICashboxResource, IStateProvider } from "infrastructure/interfaces";
import { DictionariesService } from "infrastructure/services/dictionaries.service";
import { Employee, EmployeeService } from "infrastructure/services/employee.service";
import { Guid } from "infrastructure/types";
import { SALE_CHANNELS } from "../constants";
import { PasswordManagementController } from "./passwordManagement.controller";
import passwordManagementTemplate from "./passwordManagement.html";
import { UserProfileController } from "./userProfile.controller";
import "./userProfile.module";

interface IUserData {
    IsEmailConfirmed: boolean;
    IsImpersonated: boolean;
    SaleChannel: string;
    cashboxGuid: Guid;
    email: string;
    filial: string;
    name: string;
    phone: string;
    role: string;
}

angular
    .module("app.userProfile")
    .config(config);

function config($stateProvider: IStateProvider) {
    "ngInject";

    $stateProvider
            .state("app.userProfile", {
                abstract: true,
                url: "/userProfile",
            })
            .state("app.userProfile.index", {
                resolve: {
                    cashboxList: (
                        dictionariesService: DictionariesService,
                        cashboxResource: ICashboxResource,
                        userData: IUserData,
                    ) => {
                        "ngInject";

                        if (userData.SaleChannel === SALE_CHANNELS.PARTNER) {
                            return [];
                        }
                        return dictionariesService.get("Filials").then((filialsList) => {
                            return cashboxResource.getCashboxList().$promise.then((cashboxes) => {
                                const cashboxList = [];
                                if (angular.isArray(cashboxes)) {
                                    cashboxes.forEach((cashbox) => {
                                        const filial = filialsList.find((item) => {
                                            return item.Guid === cashbox.SetupFilialGuid;
                                        });
                                        const cashboxNames = [];
                                        cashboxNames.push(cashbox.Name);
                                        if (filial) {
                                            cashboxNames.push(filial.Name);
                                        }
                                        cashboxNames.push(cashbox.SetupAddress);
                                        cashboxList.push({
                                            Guid: cashbox.Guid,
                                            Name: cashboxNames.join(", "),
                                        });
                                    });
                                }
                                return cashboxList;
                            }).catch(() => []);
                        }).catch(() => []);
                    },
                    userData: (
                        employee: Employee,
                        employeeService: EmployeeService,
                        cashboxResource: ICashboxResource,
                    ): angular.IPromise<IUserData> => {
                        "ngInject";

                        employee.deleteEmployeeInfo();
                        return employeeService.getEmployee().then((context) => {
                            const employeeInfo: IUserData = {
                                IsEmailConfirmed: context.info.IsEmailConfirmed,
                                IsImpersonated: context.info.IsImpersonated,
                                SaleChannel: context.info.SaleChannel,
                                cashboxGuid: null,
                                email: context.info.Email,
                                filial: context.info.Filial,
                                name: context.info.Name,
                                phone: context.info.PhoneNumber,
                                role: context.info.Role,
                            };
                            if (employeeInfo.SaleChannel === SALE_CHANNELS.PARTNER) {
                                return employeeInfo;
                            }
                            return cashboxResource.getEmployeeCashbox().$promise.then((data) => {
                                employeeInfo.cashboxGuid = data.cashboxGuid;
                                return employeeInfo;
                            }).catch(() => employeeInfo);

                        });
                    },
                },
                url: "/",
                views: {
                    "body@": {
                        controller: UserProfileController,
                        controllerAs: "vm",
                        template: require("./userProfile.html"),
                    },
                },
            })
        .state("app.userProfile.passwordManagement", {
            url: "/passwordManagement",
            views: {
                "body@": {
                    controller: PasswordManagementController,
                    controllerAs: "vm",
                    template: passwordManagementTemplate,
                },
                "topbar@": {},
            },
        });
}
