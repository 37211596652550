import { NgControllerBase } from "infrastructure/NgController";
import { NotifyService } from "infrastructure/services/notifyService";
import { IImageRepositoryResource } from "./imageRepository.resource";
import { IParamsOpenImageRespository } from "./imageRepository.resource";

export class ImageRepositoryService extends NgControllerBase {
    private imageRepositoryResource: IImageRepositoryResource;
    constructor($injector) {
        super($injector);
        [this.imageRepositoryResource] = this.di(["imageRepositoryResource"]);
    }
    public canUploadFilesToImageRepository(): ng.IPromise<boolean> {
        return this.imageRepositoryResource.canUploadFilesToImageRepository()
            .$promise.then(() => true)
            .catch(() => false);
    }
    public openImageRepositoryUploader(params: IParamsOpenImageRespository) {
        return this.openImageRepository(params, "uploadUrl");
    }

    public openImageRepositoryContainer(params: IParamsOpenImageRespository) {
        return this.openImageRepository(params, "containerUrl");
    }

    private openImageRepository(params: IParamsOpenImageRespository, urlProperty: string) {
        const notifyService = this.di<NotifyService>("notifyService");

        const $window = this.di<ng.IWindowService>("$window");
        if (!["containerUrl", "uploadUrl"].includes(urlProperty)) {
            const $q = this.di<ng.IQService>("$q");
            return $q.reject();
        }
        notifyService.progressStart("Открытие контейнера ХИ");
        return this.imageRepositoryResource.openOrCreateContainer(params).$promise
            .then(() => this.imageRepositoryResource.getImageRepositoryUploaderUrl(params).$promise)
            .then((data) => $window.open(data[urlProperty], "_blank", `width=${$window.innerWidth}, height=${$window.innerHeight}, status = no, menubar=no, toolbar = no, location = no, resizable = yes`))
            .catch(() => notifyService.errorMessage("Произошла ошибка при работе с хранилищем изображений"))
            .finally(() => {
                notifyService.progressStop();
            });
    }
}
