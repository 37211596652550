import { IModalServiceInstance } from "angular-ui-bootstrap";
import ClipboardJS from "clipboard";

import { NgControllerBase } from "infrastructure/NgController";
import { NotifyService } from "infrastructure/services/notifyService";
import sbpLogo from "../../../../presentation/assets/images/logo_sbp.svg"
export class ShowLinkController extends NgControllerBase {
  public sbpLogoIcon: string = sbpLogo;

  static get $inject() {
    return ["$injector", "$uibModalInstance", "url", "qr"];
  }

  public constructor(
    $injector,
    private $uibModalInstance: IModalServiceInstance,
    public url: string,
    public qr: string
  ) {
    super($injector);
  }

  public onReject(): void {
    this.$uibModalInstance.dismiss();
  }

  public $onInit(): void {
    const notifyService = this.di<NotifyService>("notifyService");
    const clipboard = new ClipboardJS("#acquireLinkBtn");
    clipboard.on("success", (e) => {
      notifyService.successMessage("Ссылка скопирована в буфер обмена");
      e.clearSelection();
    });
  }
}
