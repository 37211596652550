
import { Transition } from "@uirouter/core";
import { ConstructorHomeToolbarController } from "../constructorHome-toolbar.controller";
export class ConstructorHomeJournalToolbarController extends ConstructorHomeToolbarController {
    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
    ) {
        super($injector, $transition, {
            viewType: "journal",
        });
    }
}
