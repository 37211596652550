import { Contract } from "domain/classes/contract.class";
import EngineeringEquipment from "domain/classes/engineeringEquipment.class";
import MovableProperty from "domain/classes/movableProperty.class";
import ProlongableIflContractMix from "domain/mixes/ProlongableIflContract.mix";
import { IFLRepository } from "infrastructure/app.factory-ts";
import { ISetFromRepositoryFlags } from "infrastructure/interfaces/IContractResource";
import IProlongableContract from "infrastructure/interfaces/IProlongableContract";
import { AssuranceApartmentContractDTO } from "infrastructure/interfaces/WebApi/AssuranceApartmentContractDTO";
import {
    DefiniteSublimitOfConstructionPartDTO,
} from "infrastructure/interfaces/WebApi/DefiniteSublimitOfConstructionPartDTO";
import { IFactPaymentDTO } from "infrastructure/interfaces/WebApi/IFactPaymentDTO";
import PaymentDTO from "infrastructure/interfaces/WebApi/PaymentDTO";
import { PhoneNumberDTO } from "infrastructure/interfaces/WebApi/PhoneNumberDTO";
import { ProductRiskDTO } from "infrastructure/interfaces/WebApi/ProductRiskDTO";
import { IAPIRepository, IRepositoryItem } from "infrastructure/Repository.class";
import { ICastMap } from "infrastructure/services/autoCast.service";
import { Day, Decimal, Guid, Int } from "infrastructure/types";
import IDiscountRestriction from "./interfaces/IDiscountRestriction";
import IInsuranceTerm from "./interfaces/IInsuranceTerm";
import IInteriorCategory from "./interfaces/IInteriorCategory";
import ISublimitForInterior from "./interfaces/ISublimitForInterior";
import IPVSContractorDTO from "infrastructure/interfaces/IPVSContractorDTO";
import UgskPhone from "domain/classes/ugsk-phone.class";

export class MovablePropertyAssuranceApartment extends MovableProperty {
    public CalculatedMinAmount: number;
    public CalculatedMaxAmount: number;
    public IsGroup: boolean;
    constructor(id: number) {
        super(id);
        this.CalculatedMinAmount = null;
        this.CalculatedMaxAmount = null;
        this.IsGroup = false;
    }
}

export type AssuranceApartmentRepositoryKeys =
    "AssuranceApartmentUnderwriterCoefficientRestriction" |
    "PropertyInteriorCategory" |
    "AssuranceApartmentBonusMalus" |
    "PolicyNumberOfAnotherCompany" |
    "FranchiseType" |
    "AssuranceApartmentStructuralElementCalculatedCostRestriction" |
    "SublimitForInterior" |
    "PropertyWallMaterial" |
    "AssuranceApartmentInsuranceTerm" |
    "PaymentDocumentSerial" |
    "DiscountRestrictions" |
    "AssuranceApartmentDiscountRestriction" |
    "SpecialOffer" |
    "AssuranceApartmentInteriorCategoryCalculatedCostRestriction" |
    "AssuranceApartmentCivilLiabilityCostRestriction" |
    "PropertyRoomsCount" |
    "PropertyDeterioration"
    ;
export class AssuranceApartmentRepository extends IFLRepository {
    public AssuranceApartmentUnderwriterCoefficientRestriction: IRepositoryItem<{
        AssuranceApartmentUnderwriterCoefficientRestrictionMinValue: Int;
        AssuranceApartmentUnderwriterCoefficientRestrictionMaxValue: Int;
    }>;
    public PropertyInteriorCategory: IRepositoryItem<IInteriorCategory>;
    public AssuranceApartmentBonusMalus: IRepositoryItem<{
        AssuranceApartmentBonusMalusDescription: Int;
        AssuranceApartmentBonusMalusName: Int;
    }>;
    public PolicyNumberOfAnotherCompany: IRepositoryItem<{

    }>;
    public FranchiseType: IRepositoryItem<{

    }>;
    public AssuranceApartmentStructuralElementCalculatedCostRestriction: IRepositoryItem<{
        CalculatedMinAmount: Int;
        CalculatedMaxAmount: Int;
    }>;
    public SublimitForInterior: IRepositoryItem<ISublimitForInterior>;
    public PropertyWallMaterial: IRepositoryItem<{
        PropertyWallMaterialId: Int;
        PropertyWallMaterialMaxConstructionAgeValue: Int;
    }>;
    public AssuranceApartmentInsuranceTerm: IRepositoryItem<IInsuranceTerm>;
    public DiscountRestrictions: IRepositoryItem<IDiscountRestriction>;
    public AssuranceApartmentDiscountRestriction: IRepositoryItem<{
        MinValue: string;
        MaxValue: string;
    }>;
    public SpecialOffer: IRepositoryItem<{
        SpecialOfferId: Int;

    }>;
    public AssuranceApartmentInteriorCategoryCalculatedCostRestriction: IRepositoryItem<{
        CalculatedMinAmount: Int;
        CalculatedMaxAmount: Int;
    }>;
    public AssuranceApartmentCivilLiabilityCostRestriction: IRepositoryItem<{
        MinValue: Int;
        MaxValue: Int;
    }>;
    public PropertyRoomsCount: IRepositoryItem<{}>;
    public PropertyDeterioration: IRepositoryItem<{
        PropertyDeterioration: string;
        PropertyDeteriorationValue: boolean;
    }>;
}

export class AssuranceApartmentContract extends ProlongableIflContractMix(Contract) implements IProlongableContract, AssuranceApartmentContractDTO {
    public CivilLiabilityDeclaredAmount: number;
    public InsuredCommonEmail: string;
    public EngineeringEquipments: EngineeringEquipment[];
    public MovableProperties: MovableProperty[];
    public PropertyInteriorRepairYear: string;
    public UnderwriterCoefficient: number;
    public StructuralElementDeclaredAmount: number;
    public InteriorDeclaredAmount: number;
    public NeedToInsureCivilLiability: boolean;
    public NeedToInsureStructuralElement: boolean;
    public NeedToInsureInterior: boolean;
    public InsuredBirthday: string;
    public InspectionInspectionDate: string;
    public InspectionInspector: string;
    public InsuredAddressFiasId: string;
    public PropertyAddressFiasId: string;
    public InsuredAddressKladrId: string;
    public PropertyAddressUserDefinedAddress: string;
    public InsuredAddressUserDefinedAddress: string;
    public SpecialOfferId: number;
    public AssuranceApartmentInsuranceTermId: number;
    public PropertyAddressKladrId: string;
    public InsuredObjectAddressHasNotFiasInfo: boolean;
    public InsuredPersonAddressHasNotFiasInfo: boolean;
    public AssuranceApartmentBonusMalusId: number;
    public PropertyInteriorCategoryId: number;
    public PropertySquare: number;
    public PropertyRoomsCount: number;
    public InteriorSublimits: DefiniteSublimitOfConstructionPartDTO[];
    public AssumedRisksOnTheContract: ProductRiskDTO[];
    public MovablePropertyDeterioration: boolean;
    public EngineeringEquipmentDeterioration: boolean;
    public StructuralElementsHasWoodenFloorOverlaps: boolean;
    public PropertyForRent: boolean;
    public PropertyHasSecurityAlarm: boolean;
    public PropertyHasFireAlarm: boolean;
    public ConstructionMaterialTypeId: Int;
    public FranchiseTypeId: Int;
    public FranchiseAmountId: Int;
    public Discount: Decimal;

    public ContractStatusName: string;
    public PolicyPrefix: string;
    public PolicySerialId: Int;
    public FilialId: Int;
    public InsurancePremium: Decimal;

    public InsuranceCompanyOfAnotherPolicyId: Int;
    public PolicyNumberOfAnotherCompany: string;

    // в продукте не используется
    public InsuredDocTypeId: Int;
    public InsuredDocDateGiven: Day;
    public InsuredDocWhomGiven: string;
    public InsuredDocOrgCode: string;
    public InsuredBirthPlace: string;
    public InsuredDocSerial: string;
    public InsuredDocNumber: string;
    public InsuredLastName: string;
    public InsuredFirstName: string;
    public InsuredMiddleName: string;
    public InsuredPhoneVerificationId: Guid;
    public InsuredPhones: PhoneNumberDTO[];
    public InsuredId: Int;

    public LapsedPaymentId: Int;
    public PaymentKindId: Int;
    // public PaymentDocument: string; ???
    public PaymentDocumentSerialId: Int;
    // public PaymentDocumentDatePay: Day;
    // public IsA7Required: boolean; ??
    public IsReceiptRequired: boolean;
    public IsNumberRequired: boolean;
    public PlannedPayments: PaymentDTO[];
    public FactPayments: IFactPaymentDTO[];
    public ContractorIsEmployee: boolean;

    constructor(prototype: object) {
        super(prototype);
        this.CivilLiabilityDeclaredAmount = null;
        this.EngineeringEquipments = [];
        this.MovableProperties = [];
        this.PropertyInteriorRepairYear = null;
        this.UnderwriterCoefficient = 1;
    }
    public setFromRepository(data: IAPIRepository[], flags: ISetFromRepositoryFlags): void {
        super.setFromRepository(data, flags);
        const $this = this;
        function getSublimits(collection: IAPIRepository): DefiniteSublimitOfConstructionPartDTO[] {
            return collection.CollectionSource.map((collectionElement) => {
                const idElement = collectionElement.CollectionSource.find((item) => {
                    return item.FieldName === "Id";
                });
                const valueElement = collectionElement.CollectionSource.find((item) => {
                    return item.FieldName === "DefaultValue";
                });
                if (idElement.Value && valueElement.Value) {
                    return {
                        SublimitOfConstructionPartId: idElement.Value,
                        Value: valueElement.Value,
                    };
                }
            }).filter((sl) => sl);
        }
        const sublimitsCollection = data.find((item) => {
            return item.FieldName === "SublimitForInterior";
        });
        if (sublimitsCollection) {
            $this.InteriorSublimits = getSublimits(sublimitsCollection);
        }
    }
    public getRepository(): AssuranceApartmentRepository {
        return (super.getRepository() as AssuranceApartmentRepository);
    }
    static get castMap(): ICastMap {
        return {
            EngineeringEquipments: [EngineeringEquipment],
            MovableProperties: [MovablePropertyAssuranceApartment],
        };
    }

    /**
     * @override
     */
    public toPVSDTO(): IPVSContractorDTO {
        const toPVSDTO = {
            ContractorId: this.InsuredId,
            ContractorTypeCode: this.InsuredContractorTypeId,
            FirstName: this.InsuredFirstName,
            LastName: this.InsuredLastName,
            MiddleName: this.InsuredMiddleName,
            ContractorBirthday: this.InsuredBirthday,
            ContractorPhone: UgskPhone.phoneArrayToPhoneString(this.InsuredPhones),
            PhoneVerificationId: this.InsuredPhoneVerificationId,
        };
        for (const propName in toPVSDTO) {
            if (toPVSDTO[propName] === null) {
                delete toPVSDTO[propName];
            }
        }

        return toPVSDTO;
    }
}
