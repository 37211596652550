import angular from "angular";

export default angular.module("app.services", [])
    .service("osagoService", function ($resource, $http, envService) {
        const apiUrl = envService.read("apiUrl");
        return function () {
        const actions = {
                EOsagoStatus: {
                    method: "GET",
                    url: `${apiUrl}EOsagoStatus`,
                    _suppressErrors: true,
                },
            };

            const resource = $resource("", { id: "@Id" }, actions);
            resource.EOsagoInsuredAccountLink = (params) => {
                return {
                    $promise: $http({
                        url: `${apiUrl}EOsagoInsuredAccount/InsuredAccountLink`,
                        params,
                        paramSerializer(params) {
                            return `login=${params.login}`;
                        }
                    })
                }
            };
            return resource;
        };
    })

    .service("toService", function ($resource, envService) {
        const serviceUrl = `${envService.read("apiUrl")}To`;
        const actions = {
            sendRequest: {
                url: serviceUrl,
                method: "POST",
                responseType: "text",
                cancellable: true,
                _suppressErrors: true,
            },
        };

        return $resource("", {}, actions);
    })

    .service("toEGarantService", function ($resource, envService) {
        const serviceUrl = `${envService.read("apiUrl")}ToEGarant`;
        const actions = {
            sendRequest: {
                url: serviceUrl,
                method: "POST",
                responseType: "text",
                cancellable: true,
                _suppressErrors: true,
            },
        };

        return $resource("", {}, actions);
    })

    .service("openNotificationTrafficAccident", function ($resource, envService) {
        const apiUrl = envService.read("apiUrl");
        const actions = {
            sendRequest: {
                url: `${apiUrl}osago/SendNotificationTrafficAccident/Link`,
                method: "GET",
                responseType: "text",
                cancellable: true,
                _suppressErrors: true,
            },
        };

        return $resource("", {}, actions);
    })

    .service("sendNotificationTrafficAccident", function ($resource, envService) {
        const apiUrl = envService.read("apiUrl");
        return function (params) {
            const actions = {
                sendRequest: {
                    url: `${apiUrl}osago/SendNotificationTrafficAccident/:id`,
                    method: "POST",
                    responseType: "text",
                    cancellable: true,
                    _suppressErrors: true,
                },
            };

            return $resource("", params, actions);
        };
    })

    .service("reliableDriveService", function ($resource, envService) {
        const baseUrl = envService.read("apiUrl").endsWith("/") ? envService.read("apiUrl") : `${envService.read("apiUrl")}/`;
        const apiUrl = `${baseUrl}reliable-drive/`;
        return function (params) {
            const actions = {
                create: {
                    _suppressErrors: true,
                    method: "POST",
                    url: `${apiUrl}create`,
                },
                getByGuid: {
                    _suppressErrors: true,
                    method: "GET",
                    url: `${apiUrl}get-by-osago-guid/:Guid`,
                },
                sign: {
                    _suppressErrors: true,
                    method: "POST",
                    url: `${apiUrl}sign/:id`,
                    transformRequest: () => null,
                    params: {
                        "id": "@id",
                    }
                },
                getActivity: {
                    method: "GET",
                    url: `${apiUrl}get-activity-contract/:guid`,
                    params: {
                        guid: "@guid",
                    },
                },
                cancel: {
                    method: "POST",
                    url: `${apiUrl}set-activity-contract/:guid`,
                    params: {
                        guid: "@guid"
                    },
                    transformRequest: () => {
                        return angular.toJson({
                            IsActive: false,
                            IsManualCancel: true,
                        });
                    }
                },
                activate: {
                    method: "POST",
                    url: `${apiUrl}set-activity-contract/:guid`,
                    params: {
                        guid: "@guid"
                    },
                    transformRequest: () => {
                        return angular.toJson({
                            IsUserChoice: true,
                        });
                    }
                },
                deactivate: {
                    method: "POST",
                    url: `${apiUrl}set-activity-contract/:guid`,
                    params: {
                        guid: "@guid"
                    },
                    transformRequest: () => {
                        return angular.toJson({
                            IsUserChoice: false,
                        });
                    }
                },
                refreshPayment: {
                    method: "POST",
                    _suppressErrors: true,
                    url: `${apiUrl}refresh-payments/:id`,
                    params: {
                        id: "@id",
                    },
                },
            };

            return $resource(apiUrl, params, actions);
        };
    })

    .service("vehicleInfo", function ($resource, envService) {
        const apiUrl = `${envService.read("vehicleInfoUrl")}vehicleinfo/by-all`;
        const actions = {
            getVehicleInfoByAll: {
                _suppressErrors: true,
                method: "GET",
                params: {
                    bodyNumber: "@bodyNumber",
                    chassisNumber: "@chassisNumber",
                    licensePlate: "@licensePlate",
                    vin: "@vin",
                },
                url: apiUrl,
            },
        };

        return $resource("", {}, actions);
    })

    .service("sendOsagoPolicyService", function ($resource, envService) {
        const apiUrl = `${envService.read("apiUrl")}Osago/SendOffer`;

        const actions = {
            send: {
                method: "POST",
                url: `${apiUrl}/:id`,
                params: {
                    id: "@id",
                },
            },
        };

        return $resource("", {}, actions);
    })

    .name;
