import angular from "angular";
const objectDefaultsPropery = Symbol("objectDefaultsPropery");
/**
 * @description Фабрика класса реализующего логику сброса значений
 * @param {Class} Base ссылка на родительский класс
 */
export function resetable<T extends new(...args: any[]) => {}>(constructor: T) {
    return class extends constructor {
        public constructor(...args) {
            super(...args);
            this.setDefaultValues(this);
        }
        public setDefaultValues(defaults) {
            this[objectDefaultsPropery] = Object.assign({}, defaults);
        }
        public reset() {
            const defaults = this[objectDefaultsPropery];
            if (!angular.isObject(defaults)) {
                return;
            }
            const defaultsKeys = Object.keys(defaults);
            defaultsKeys.forEach((key) => {
                this[key] = this.getDefaultValueOf(key);
            });
        }
        public getDefaultValueOf(property: string) {
            const defaults = this[objectDefaultsPropery];
            if (!angular.isObject(defaults)) {
                return undefined;
            }
            return defaults[property];
        }
    };
}
