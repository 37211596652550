import angular from "angular";
import { NgComponentController } from "infrastructure/NgController";
import { Int } from "infrastructure/types";
import template from "./ugskAnotherInsuranceHistory.component.html";

interface IInsuranceCompanyOfAnotherPolicy {
    InsuranceCompanyId: Int;
    InsuranceCompany: string;
}

class AnotherInsuranceHistoryComponent extends NgComponentController {
    public anotherInsuranceCompanies: IInsuranceCompanyOfAnotherPolicy[];
    public insuranceCompanyOfAnotherPolicyId: Int;
    public isShowComponent: boolean;
    public policyNumberOfAnotherCompany: string;

    public doCheck(): void {
        if (this.isShowComponent) {
            this.policyNumberOfAnotherCompany = null;
            this.insuranceCompanyOfAnotherPolicyId = null;
        }
    }
}

export default angular.module("ugsk.components.another-insurance-history", [])
.component("ugskAnotherInsuranceHistory", {
    bindings: {
        anotherInsuranceCompanies: "<",
        insuranceCompanyOfAnotherPolicyId: "=",
        isShowComponent: "<",
        policyNumberOfAnotherCompany: "=",
    },
    controller: AnotherInsuranceHistoryComponent,
    controllerAs: "vm",
    template,
}).name;
