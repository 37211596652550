import angular from "angular";
import { NgComponentController } from "infrastructure/NgController";
import { OsagoDriver } from "../../osago/osago.factory";
import { GenderAgeExperiences } from "../../uAuto/uAuto.factory";
import template from "./ugskPerson.component.html";

class UgskPerson extends NgComponentController {
  public maxBirthday: string | boolean;
  public minBirthday: string | boolean;
  public minlength: number;

  public onInit(): void {
    this.minBirthday = this.minBirthday || false;
    this.maxBirthday = this.maxBirthday || false;
  }
}

export default angular
  .module("ugsk.components.person", [])
  .component("ugskPerson", {
    bindings: {
      birthday: "=",
      colStyle: "<",
      firstName: "=",
      guid: "<",
      lastName: "=",
      maxBirthday: "<?",
      maxlength: "<",
      middleName: "=",
      minBirthday: "<?",
      minlength: "<",
      ngDisabled: "<",
      prefix: "<",
    },
    controller: UgskPerson,
    controllerAs: "vm",
    template,
    transclude: true,
  }).name;
