import angular from "angular";

import { NgComponentController } from "infrastructure/NgController";
import "./ugskTreeView.component.css";
import treeViewTemplate from "./ugskTreeView.component.html";

export default angular.module("app.components.tree.view", []).component("ugskTreeView", {
    bindings: {
        attribute: "<",
        data: "<",
        onTransformResult: "&",
        subitem: "<",
    },
    controller: class extends NgComponentController {
        public onTransformResult: (item: any) => string;

        public transformTitle(item) {
            return this.onTransformResult({ item });
        }
    },
    controllerAs: "vm",
    template: treeViewTemplate,
}).name;
