import { IPromise, isFunction } from "angular";
import { BaseToolbarController } from "application/baseProduct.toolbar.controller";
import { NotifyService } from "infrastructure/services/notifyService";

export interface IExtraOptionPrototype {
    icon: string;
    title: string;
    tooltip?: string;
    view?: "form";
    action?(context: BaseToolbarController): void | IPromise<void>;
    href?(context: BaseToolbarController): string;
    disabled?(context: BaseToolbarController): boolean;
    visible?(context: BaseToolbarController): boolean;
}

export class ExtraMenuOption {
    public icon: string;
    public title: string;
    public view: "form";
    public tooltip?: string;
    constructor(private proto: IExtraOptionPrototype, private context: BaseToolbarController) {
        this.icon = proto.icon;
        this.title = proto.title;
        this.view = proto.view;
        this.tooltip = proto.tooltip;
    }
    public isVisible(): boolean {
        if (isFunction(this.proto.visible)) {
            return this.proto.visible(this.context);
        }
        return true;
    }
    public isDisabled(): boolean {
        if (!this.context.Contract) {
            return true;
        }
        if (isFunction(this.proto.disabled)) {
            return this.proto.disabled(this.context);
        }
        return false;
    }
    public handler(): void {
        if (isFunction(this.proto.action)) {
            if (this.isDisabled()) {
                return;
            }
            const actionResult = this.proto.action(this.context);
            if (actionResult && isFunction(actionResult.then)) {
                const notifyService = this.context.di<NotifyService>("notifyService");
                notifyService.progressStart(`Операция "${this.title}" выполняется`);
                actionResult.finally(() => notifyService.progressStop());
            }
        }
    }
    public getHref(): string {
        if (isFunction(this.proto.href)) {
            if (this.isDisabled()) {
                return "";
            }
            return this.proto.href(this.context);
        }
    }
}
