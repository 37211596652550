import { IWindowService } from "angular";
import INotificationProvider, { INotificationProviderPopParams } from "infrastructure/interfaces/INotificationProvider";

export interface IDesktopProviderPopParams extends INotificationProviderPopParams {
    icon?: string;
    tag?: string;
    onclick?: () => void;
}
export class NotifyDesktopProvider implements INotificationProvider {
    private hasNotificationCapability = "Notification" in window;
    public pop(data: IDesktopProviderPopParams) {
        if (!this.hasNotificationCapability) {
            throw new Error("Notification Api not available");
        }
        const options: NotificationOptions = {
            body: data.body,
            icon: "",
            lang: "RU",
            renotify: true,
            requireInteraction: true,
            tag: "defaultTag",
        };

        if (data.icon) {
            options.icon = data.icon;
        }
        if (data.tag) {
            options.tag = data.tag;
        }
        const notification = new Notification(data.title, options);

        if (data.onclick) {
            notification.onclick = data.onclick;
        }
    }

    public async requestPermission() {
        if (!this.hasNotificationCapability) {
            throw new Error("Notification Api not available");
        }
        return Notification.requestPermission();
    }
}
