import { CONTRACT_STATUSES } from "application/constants";
import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { OsagoToolbarController } from "../osago-toolbar.controller";
import IBlockUI from "infrastructure/interfaces/IBlockUI";
import { NotifyService } from "infrastructure/services/notifyService";
import { IHttpResponse, IHttpService } from "angular";
import { EnvService } from "infrastructure/services/env.service";
import { Contract } from "domain/classes/contract.class";

interface ISendPaymentLinkResponse {
    IsPayed: boolean;
}

export default {
    disabled(context: OsagoToolbarController) {
        const isPaid = context.Contract.IsPayed;
        const statusesWhiteList = [
            CONTRACT_STATUSES.PREPARED_FOR_SIGNING,
        ];
        const hasSuitableContractStatus = statusesWhiteList.includes(context.Contract.ContractStatusId);

        if (isPaid && hasSuitableContractStatus) {
            return false;
        }
        return true;
    },

    action(context: OsagoToolbarController): void {

        this.notifyService = context.di<NotifyService>("notifyService");
        const blockUI = context.di<IBlockUI>("blockUI");
        const $http = context.di<IHttpService>("$http");
        const envService = context.di<EnvService>("envService");

        blockUI.start("Синхронизация статуса договора");
        this.notifyService.infoMessage(
            "Синхронизация статуса договора",
            "Операция запущена",
        );

        $http.post(`${envService.read("apiUrl")}/Payments/RefreshPaymentStatus/${context.Contract.Id}`, undefined)
            .then((response: IHttpResponse<ISendPaymentLinkResponse>) => {
                    if (Object.keys(context.Contract).includes("IsPayed")) {
                        context.Contract.IsPayed = response.data.IsPayed;
                    }
                    this.notifyService.removeToasts();
                    this.notifyService.successMessage(
                        "Синхронизация статуса договора",
                        "Процесс синхронизации договора завершен",
                    );
                    const contract = new Contract();
                    contract.Id = context.Contract.Id;
                    return contract.$load(context.Contract.Id);
                },
            ).catch((reason) => {
                if (reason.data.Message) {
                    this.notifyService.removeToasts();
                    this.notifyService.errorMessage(
                        "Синхронизация статуса договора",
                        "В процессе синхронизации статуса договора произошла ошибка",
                    );
                }
                console.error("Синхронизация статуса договора НП", reason);
            },
        ).finally(() => {
            blockUI.stop();
        });
    },
    title: "Синхронизировать статус договора",
    tooltip: "Доступно только для договоров в статусе &#34;Подготовлен к продаже&#34; и &#34;Оплачен&#34;",
} as IExtraOptionPrototype;
