import angular from "angular";

export function DecimalFormatDirective(): ng.IDirective {
    "ngInject";

    return {
        link: (
            scope: ng.IScope,
            element: JQuery<HTMLInputElement>,
            attributes: ng.IAttributes,
            controller: ng.INgModelController,
        ) => {
            const sep = ",";
            controller.$parsers.push((val: string) => {
                if (angular.isUndefined(val)) {
                    val = "";
                }
                let clean = val.replace(".", ",");
                clean = clean.replace(/[^-0-9\,]/g, "");
                const negativeCheck = clean.split("-");
                const decimalCheck = clean.split(sep);
                if (!angular.isUndefined(negativeCheck[1])) {
                    negativeCheck[1] = negativeCheck[1].slice(0, negativeCheck[1].length);
                    clean = (negativeCheck[0] ? negativeCheck[0] : "0") + "-" + negativeCheck[1];
                    if (negativeCheck[0].length > 0) {
                        clean = negativeCheck[0];
                    }
                }

                if (!angular.isUndefined(decimalCheck[1])) {
                    decimalCheck[1] = decimalCheck[1].slice(0, 2);
                    clean = (decimalCheck[0] ? decimalCheck[0] : "0") + sep + decimalCheck[1];
                }

                if (val !== clean) {
                    const position = element[0].selectionEnd;
                    controller.$setViewValue(clean);
                    controller.$render();
                    if (clean) {
                        element[0].setSelectionRange(position, position);
                    }
                }
                return parseFloat(clean.replace(",", "."));
            });

            controller.$formatters.push((val: (string | undefined)) => (!val) ? val : (String(val)).replace(".", ","));
            element.bind("keypress", (event) => {
                if (event.keyCode === 32) {
                    event.preventDefault();
                }
            });
        },
        require: "ngModel",
        restrict: "A",
    };
}
