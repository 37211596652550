import ngResource from "angular-resource";
import moment from "moment";

import angular, { IHttpRequestTransformer, IHttpResponseTransformer } from "angular";
import BlobObject from "domain/classes/blob.class";
import UGSKBlob from "domain/classes/blob.class";
import { Contract } from "domain/classes/contract.class";
import { Payment } from "domain/classes/payment.class";
import { journalContractClassFactory } from "./app.factory";
import { capitalizeFirstLetter } from "./app.helpers";
import ODataResourceResult from "./classes/ODataResourceResult";
import { IJournalResource, IODataParams } from "./interfaces";
import IBlockUI from "./interfaces/IBlockUI";
import { IContractResource, ICrossParams } from "./interfaces/IContractResource";
import IDTO from "./interfaces/IDTO";
import { IGetPrintableDocumentFnParams, IGetPrintableResourceParams } from "./interfaces/IGetPrintableParams";
import IHttpService from "./interfaces/IHttpService";
import { IJournalContractDTO } from "./interfaces/IJournalContractDTO";
import { IUproResourceActionHash } from "./interfaces/IUproResourceActionHash";
import { IODataResourceClass } from "./interfaces/OData/IODataResource";
import { IODataResourceResult } from "./interfaces/OData/IODataResourceResult";
import IOdataResourceService from "./interfaces/OData/IODataResourceService";
import { AssignmentFactPaymentOnASignedContractViewModel } from "./interfaces/WebApi/AssignmentFactPaymentOnASignedContractViewModel";
import Filial from "./interfaces/WebApi/Filial";
import InspectionConclusionDTO from "./interfaces/WebApi/InspectionConclusionDTO";
import { IRepositoryResource } from "./Repository.class";
import { cast } from "./services/autoCast.service";
import { PaymentDTO } from "./services/DTO.service";
import { EnvService } from "./services/env.service";
import { ProductsService } from "./services/products.service";
import { DateTimeOffset, Guid, Int } from "./types";
import IPVSContractorDTO from "./interfaces/IPVSContractorDTO";
import UgskPhone from "domain/classes/ugsk-phone.class";

type IGetPrintableDocumentFn = ({
    url,
    contentType,
    docType,
    fileName,
    fileNamePrefix,
    extension,
    _suppressErrors,
    headers,
}: IGetPrintableDocumentFnParams) => angular.IPromise<UGSKBlob>;

function getPrintableDocumentService($http: IHttpService, blockUI: IBlockUI): IGetPrintableDocumentFn {
    "ngInject";

    return ({ url, contentType, docType, fileName, fileNamePrefix, extension, _suppressErrors, headers }) => {
        blockUI.start("Подготовка печатной формы");
        return $http<BlobPart>({
            _suppressErrors,
            headers: angular.extend(
                {
                    Accept: `${contentType}; k3.presentation=${docType}`,
                },
                headers
            ),
            method: "GET",
            responseType: "arraybuffer",
            transformResponse: (data, headerGetter, status) => {
                if (status === 200) {
                    return data;
                }
                /**
                 * Если произошла ошибка, то это будет JSON
                 * в виде ArrayBuffer - его необходимо превратить
                 * обратно в нормальный объект
                 */
                const enc = new TextDecoder();
                let parsedResponse;
                try {
                    parsedResponse = angular.fromJson(enc.decode(data));
                } catch (e) {}
                return parsedResponse;
                /**
                 * TODO: Удалить после написания теста когда-нибудь
                 * @deprecated
                 */
                /*
                var strHexNotation = "";
                angular.forEach((new Uint8Array(data)), (value) => {
                    strHexNotation += Number(value).toString(16);
                });
                var strToEncode = strHexNotation.replace(/[0-9a-f]{2}/g, "%$&");
                var strDecode = decodeURIComponent(strToEncode);

                return angular.fromJson(strDecode);
                */
            },
            url,
        })
            .then(({ data }) => {
                const d = new Date();
                // tslint:disable-next-line: max-line-length
                const name = (fileName || fileNamePrefix + " - " + d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear()) + "." + extension;
                const blob = new BlobObject(data, contentType, name);
                return blob;
            })
            .finally(() => {
                blockUI.stop();
            });
    };
}

function paymentRegistriesResource($odataresource: IOdataResourceService, envService: EnvService, getPrintableDocument: IGetPrintableDocumentFn) {
    "ngInject";
    const apiUrl = envService.read<string>("apiUrl");
    const baseUrl = apiUrl + "api/PaymentRegistries";
    const resource = $odataresource<
        UGSKBlob,
        {
            getPrintable: (params: { Id: Int; RegistryNumber: string; Created: DateTimeOffset }) => { $promise: angular.IPromise<UGSKBlob> };
        }
    >(
        baseUrl,
        {},
        {
            save: {
                method: "POST",
                transformRequest: ({
                    PeriodFrom,
                    PeriodTo,
                    FactPayments,
                    AgentCardNumber,
                }: {
                    PeriodFrom: DateTimeOffset;
                    PeriodTo: DateTimeOffset;
                    FactPayments: Payment[];
                    AgentCardNumber: string;
                }) => {
                    return angular.toJson({
                        AgentCardNumber,
                        FactPayments: FactPayments.map((payment) => ({
                            Id: payment.Id,
                        })),
                        PeriodFrom,
                        PeriodTo,
                    });
                },
            },
        },
        {
            isodatav4: true,
            odatakey: "id",
        }
    );
    resource.getPrintable = ({ Id, RegistryNumber, Created }) => {
        const registryCreatedFormatted = moment(Created).format("YYYY-MM-DD");
        return {
            $promise: getPrintableDocument({
                contentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                docType: "AgentAccount",
                extension: "xlsx",
                fileName: `Реестр №${Id} (${RegistryNumber}) от ${registryCreatedFormatted}`,
                url: `${baseUrl}(${Id})/K3.GetPaymentRegistryPrintForm`,
            }),
        };
    };
    return resource;
}

export type IFactPaymentsResource = IODataResourceClass<
    OData.IResource<AssignmentFactPaymentOnASignedContractViewModel> & AssignmentFactPaymentOnASignedContractViewModel
>;
function factPaymentsResource($odataresource: IOdataResourceService, envService: EnvService): IFactPaymentsResource {
    "ngInject";
    const apiUrl = envService.read("apiUrl");
    const baseUrl = apiUrl + "api/ActualFactPayments";
    return $odataresource<OData.IResource<AssignmentFactPaymentOnASignedContractViewModel> & AssignmentFactPaymentOnASignedContractViewModel>(
        baseUrl,
        {},
        {},
        {
            isodatav4: true,
            odatakey: "id",
        }
    );
}

function employeeResource($resource: ng.resource.IResourceService, envService: EnvService) {
    "ngInject";
    const apiUrl = envService.read<string>("apiUrl");
    const idMngrUrl = envService.read<string>("idMngrUrl");

    const actions: IUproResourceActionHash = {
        employeeInfo: {
            method: "GET",
            url: apiUrl + "employee",
        },
        getActualFactPaymentsEmployees: {
            isArray: true,
            method: "GET",
            url: apiUrl + "Payments/GetActualFactPaymentsEmployees",
        },
        getContractOwners: {
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "GET",
            url: `${apiUrl}api/ContractOwners`,
        },
        hasAllowedCase: {
            isArray: false,
            method: "GET",
            transformResponse: (data: "true" | "false") => {
                return { hasAllowedCase: data === "true" };
            },
            url: `${apiUrl}Employee/HasAllowedCase`,
        },
        query: {
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "GET",
            url: apiUrl + "api/Users",
        },
        requestPasswordForgot: {
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "POST",
            url: idMngrUrl + "api/users/SettingPasswordRequest",
        },
        requestPasswordReset: {
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "POST",
            nolog: true,
            url: idMngrUrl + "api/users/ResetPassword",
        },
        requestGetOtpServiceStatus: {
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "GET",
            nolog: true,
            url: idMngrUrl + "api/Users/GetOtpServiceStatus",
        },
        requestCheckResetPasswordToken: {
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "POST",
            nolog: true,
            url: idMngrUrl + "api/Users/CheckResetPasswordToken",
        },
        saveSelfRegistrationData: {
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "POST",
            nolog: true,
            url: idMngrUrl + "api/Users/SelfRegister",
        },
        saveUserData: {
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "POST",
            url: idMngrUrl + "api/users/SaveUserInfo",
        },
        addUserData: {
            headers: { "X-Requested-With": "XMLHttpRequest", "sc-api": "OK" },
            method: "POST",
            url: `${apiUrl}Employee/IntermediateSellerInfo`,
        },
        getUserData: {
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "GET",
            url: `${apiUrl}Employee/IntermediateSellerInfo`,
        },
        checkVerification: {
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "GET",
            url: idMngrUrl + "api/users/PhoneCheckVerification?login=:login",
        },
        PhoneSendRequestCode: {
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "GET",
            url: idMngrUrl + "api/users/PhoneSendRequestCode?login=:login",
        },
        SendCodeVerification: {
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "GET",
            url: idMngrUrl + `api/users/PhoneVerify?login=:login&code=:code`,
        },
        saveUserPassword: {
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "POST",
            nolog: true,
            url: idMngrUrl + "api/users/SetPassword",
        },
        verifyEmail: {
            _suppressErrors: true,
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "POST",
            url: idMngrUrl + "api/users/ConfirmEmail",
        },
        getSubordinates: {
            isArray: true,
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "GET",
            url: apiUrl + "Employee/GetSubordinates",
        },
    };
    return $resource("", {}, actions);
}

function productsVersionResource($resource: ng.resource.IResourceService, envService: EnvService) {
    "ngInject";

    const apiUrl = envService.read("apiUrl");
    const actions: IUproResourceActionHash = {
        getProductsVersion: {
            method: "GET",
            url: `${apiUrl}Versions`,
        },
    };
    return $resource("", {}, actions);
}

export type IRepositoryResourceFactoryFn = (productCode: string) => IRepositoryResource;
function repositoryService($resource: ng.resource.IResourceService, envService: EnvService) {
    "ngInject";

    return (productName: string) => {
        productName = capitalizeFirstLetter(productName);
        const apiUrl = envService.read("apiUrl");
        const defaultParams = { fieldName: "@fieldName" };

        const actions: IUproResourceActionHash = {
            loadRepository: {
                cache: true,
                isArray: true,
                method: "POST",
                url: apiUrl + "Field/" + productName,
                transformRequest(data) {
                    return angular.toJson(data.contract);
                },
            },
        };
        return $resource("", defaultParams, actions);
    };
}

function emailResource($resource: ng.resource.IResourceService, envService: EnvService): any {
    return (productName: string) => {
        productName = capitalizeFirstLetter(productName);
        const apiUrl = envService.read("apiUrl");
        const params = { id: "@id" };
        const actions = {
            sendOffer: {
                method: "POST",
                url: `${apiUrl}${productName}/SendOffer/:id`,
            },
        };

        return $resource("", params, actions);
    };
}

function sendInsuranceRules($resource: ng.resource.IResourceService, envService: EnvService): any {
    return (productName: string) => {
        productName = capitalizeFirstLetter(productName);
        const apiUrl = envService.read("apiUrl");
        const params = { id: "@id" };
        const actions = {
            sendRules: {
                _suppressErrors: true,
                method: "POST",
                url: `${apiUrl}${productName}/SendInsuranceRules/:id`,
                transformResponse: (data: string, headersGetter, status) => {
                    const result = { message: "" };
                    let parsed = { Message: "" };

                    try {
                        parsed = angular.fromJson(data);
                    } catch (e) {}
                    switch (status) {
                        case 200:
                            result.message = "Правила страхования успешно отправлены";
                            break;
                        case 400:
                            result.message = parsed.Message;
                            break;
                        case 404:
                            result.message = "Договор не найден";
                            break;
                        case 500:
                            result.message = "Произошла ошибка при отправке правил страхования";
                            break;
                    }

                    return result;
                },
            },
        };

        return $resource("", params, actions);
    };
}

export type TContractResourceFactoryFn = (productCode: string) => IContractResource;
function contractResourceFactoryService(
    $resource: ng.resource.IResourceService,
    envService: EnvService,
    $http: IHttpService,
    getPrintableDocument: IGetPrintableDocumentFn,
    productsService: ProductsService
): TContractResourceFactoryFn {
    const longTimeout: Int = envService.read("longTimeout");
    return (productCode: string) => {
        const product = productsService.getByCode(productCode);
        const productContractClass = product.contractClass;
        const productName = capitalizeFirstLetter(productCode) as string;
        const apiUrl = envService.read<string>("apiUrl");
        const multiCalculationState = envService.read("multiCalculationEnabled") ? "enabled" : "disabled";
        const defaultParams = { id: "@Id" };
        let inspectionConclusionUrlPrefix = "";
        if (productCode === "osago") {
            inspectionConclusionUrlPrefix = "Osago";
        }
        // tslint:disable-next-line: max-line-length
        const inspectionConclusionUrl = `${envService.read("apiUrl")}${inspectionConclusionUrlPrefix}InspectionConclusion`;

        //  @todo: перенести эту штуку куда-то наверх (в contracts.service например)
        const transformResponse: IHttpResponseTransformer = (data: string, headersGetter, status) => {
            let parsed:
                | IDTO
                | {
                      content: IDTO;
                  };
            try {
                parsed = angular.fromJson(data);
            } catch (e) {
                return data;
            }
            if (status !== 200) {
                return parsed;
            }
            let contract: IDTO = parsed;
            if (parsed.content) {
                contract = parsed.content;
            }

            const mappedContract = productContractClass.fromDTO(contract);

            if (parsed.content) {
                parsed.content = mappedContract;
            } else {
                parsed = mappedContract;
            }

            return parsed;
        };

        const transformRequest: IHttpRequestTransformer = (contract: Contract) => angular.toJson(contract.toDTO());
        // describe our API actions
        const actions: IUproResourceActionHash = {
            annul: {
                headers: { "X-Requested-With": "XMLHttpRequest" },
                method: "POST",
                transformRequest: ({ Id }: { Id: Int }) =>
                    angular.toJson({
                        Id,
                    }),
                url: `${apiUrl}api/${productName}Contracts(:id)/K3.Annul`,
            },
            applyArtificialCalculation: {
                method: "POST",
                transformRequest: ({ calculationId }: { calculationId: Int }) =>
                    angular.toJson({
                        calculationId,
                    }),
                url: `${apiUrl}api/${productName}Contracts(:id)/K3.ApplyArtificialCalculation`,
            },
            approve: {
                method: "POST",
                url: `${apiUrl}api/${productName}Contracts(:id)/K3.Approve`,
            },
            deleteInspectionConclusion: {
                method: "DELETE",
                url: inspectionConclusionUrl,
            },
            freezeContract: {
                method: "POST",
                url: `${apiUrl}api/${productName}Contracts(:id)/K3.Freeze`,
            },
            resetCacheKBM: {
                method: "POST",
                url: `${apiUrl}${productName}/ResetCache/:id`,
            },
            getInspectionConclusion: {
                isArray: true,
                method: "GET",
                transformResponse: (data: string, headersGetter, status) => {
                    const parsed: InspectionConclusionDTO[] = angular.fromJson(data);
                    if (status === 200 && parsed.length > 0) {
                        return [parsed.slice().sort((a, b) => b.Id - a.Id)[0]];
                    }
                    return [];
                },
                url: inspectionConclusionUrl,
            },
            getOsagoContractCategory: {
                method: "GET",
                transformResponse: (data: string, headersGetter, status) => {
                    const parsed = data.replace(/\"/g, "");
                    return {
                        category: status === 200 && parsed.length ? parsed : "",
                    };
                },
                url: `${apiUrl}osago/GetContractCategory/:id`,
            },
            getOsagoProlongationDenialInfo: {
                _suppressErrors: true,
                method: "GET",
                url: apiUrl + productName + "/OsagoProlongationDenialInfo",
            },
            getProlongationDenialInfo: {
                _suppressErrors: true,
                method: "GET",
                url: apiUrl + productName + "/:id/ProlongationDenialInfo",
            },
            hasScoringRate: {
                method: "GET",
                transformResponse: (data, headerGetter, status) => {
                    if (status >= 200 && status < 300) {
                        if (typeof data === "string" && data.length > 0 && data !== '""' && data !== '"Attention"') {
                            // :(
                            return { result: true };
                        }
                    }

                    return { result: false };
                },
                url: `${apiUrl}${productName}/GetContractScoringState/:id`,
            },
            invalidateFactPayments: {
                method: "POST",
                url: `${apiUrl}api/InsuranceContracts(:id)/K3.SetPaymentAccountingStatusToNotAccounted`,
            },
            preApproveRequestValidate: {
                method: "POST",
                url: `${apiUrl}${productName}/PreApproveRequestValidate`,
            },
            // пока только для замороженного Каско
            preSignContract: {
                method: "POST",
                url: `${apiUrl}api/${productName}Contracts(:id)/K3.PreSign`,
            },
            previousContract: {
                method: "GET",
            },
            reassign: {
                method: "POST",
                transformRequest: ({ NewOwnerId }: { NewOwnerId: Int }) =>
                    angular.toJson({
                        NewOwnerId,
                    }),
                url: `${apiUrl}api/${productName}Contracts(:id)/K3.Reassign`,
            },
            returnForRevision: {
                method: "POST",
                url: `${apiUrl}api/${productName}Contracts(:id)/K3.ReturnForRevision`,
            },
            saveContract: {
                method: "POST",
                transformRequest,
                transformResponse,
            },
            saveInspectionConclusion: {
                method: "POST",
                url: inspectionConclusionUrl,
            },
            saveOsagoProlongationDenialInfo: {
                method: "POST",
                url: apiUrl + productName + "/OsagoProlongationDenialInfo",
            },
            saveProlongationDenialInfo: {
                method: "POST",
                url: apiUrl + productName + "/:id/ProlongationDenialInfo",
            },
            signContract: {
                method: "POST",
                url: `${apiUrl}api/${productName}Contracts(:id)/K3.Sign`,
            },
            signContractEOsago: {
                method: "POST",
                url: `${apiUrl}api/${productName}Contracts(:id)/K3.SignEOsago`,
            },
            signEGarant: {
                method: "POST",
                timeout: longTimeout,
                url: `${apiUrl}api/OsagoContracts(:id)/K3.SignEGarant`,
                transformRequest() {
                    return;
                },
            },
            signInExternalService: {
                method: "POST",
                url: `${apiUrl}api/${productName}Contracts(:id)/K3.SignInExternalService`,
                transformRequest(dto: IDTO) {
                    delete dto.Id;
                    return angular.toJson(dto);
                },
            },
            unFreezeContract: {
                method: "POST",
                url: `${apiUrl}api/${productName}Contracts(:id)/K3.Unfreeze`,
            },
            unlock: {
                method: "POST",
                url: `${apiUrl}api/${productName}Contracts(:id)/K3.Unlock`,
            },
            updateContract: {
                headers: {
                    "X-MultiCalculation": multiCalculationState,
                },
                method: "PUT",
                transformRequest,
                transformResponse,
                url: `${apiUrl}${productName}/:id`,
            },
            updateFactPayments: {
                method: "POST",
                transformRequest: (contract) => {
                    return angular.toJson({
                        FactPaymentsDTO: contract.FactPayments.map((payment: Payment) => new PaymentDTO(payment)),
                    });
                },
                url: `${apiUrl}api/${productName}Contracts(:id)/K3.UpdateFactPayments`,
            },
            updateInspectionConclusion: {
                method: "PUT",
                url: inspectionConclusionUrl,
            },
        };

        if (["UAuto"].includes(productName)) {
            actions.editContract = {
                method: "GET",
                url: `${apiUrl}api/${productName}Contracts(:id)`,
            };
        } else {
            actions.editContract = {
                method: "GET",
                url: `${apiUrl}${productName}/:id`,
            };
        }

        const resource = $resource<Contract, IContractResource>(apiUrl + productName, defaultParams, actions);
        /**
         * Метод получения печатной формы договора.
         * Нет возможности использовать $resource в чистом виде,
         * не позволяет передавать заголовки для каждого вызова отдельно
         * @param {object} params параметры запроса
         * @return {object} Объект со свойством $promise
         */
        resource.getPrintable = ({
            _suppressErrors,
            headers,
            contentType = "application/pdf",
            id,
            url = `${apiUrl}${productName}/${id}`,
            docType = "Policy",
            fileNamePrefix = "Полис",
        }: IGetPrintableResourceParams) => {
            const fileNameExtensionMap = {
                "application/pdf": "pdf",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
                "text/rtf": "rtf",
            };
            const extension: string = fileNameExtensionMap[contentType];
            return {
                $promise: getPrintableDocument({
                    _suppressErrors,
                    contentType,
                    docType,
                    extension,
                    fileNamePrefix,
                    headers,
                    url: angular.isFunction(url) ? url(id) : url,
                }),
            };
        };

        resource.getCrossContract = ({ id, from }: ICrossParams) => {
            let actionName: string;
            //  @compat Совместимость с ссылками старого фронта
            if (from.startsWith("from")) {
                from = from.slice(4);
            }
            if (from === "Kasko") {
                from = "uAuto";
            }
            from = capitalizeFirstLetter(from);
            if (from === productName) {
                actionName = "K3.Copy";
            } else {
                actionName = "K3.ShiftTo" + productName;
            }
            return {
                $promise: $http({
                    method: "GET",
                    url: `${apiUrl}api/${from}Contracts(${id})/${actionName}`,
                }).then(({ data }) => data),
            };
        };
        return resource;
    };
}

export interface IFilialResource extends ng.resource.IResource<any> {
    get(): IODataResourceResult<Filial>;
    query(IODataParams): IODataResourceResult<Filial>;
    getFilialById(params: { Id: Int }): IODataResourceResult<Filial>;
    getFilialByGuid(params: { Guid: Guid }): IODataResourceResult<Filial>;
}
function filialService($resource: ng.resource.IResourceService, envService: EnvService): IFilialResource {
    "ngInject";
    const apiUrl = envService.read("apiUrl");
    const oDataUrl = `${apiUrl}api/Filials`;
    const actions = {
        getFilialByGuid: {
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "GET",
            params: {
                Guid: "@Guid",
            },
            url: `${oDataUrl}?$filter=Guid eq :Guid`,
        },
        getFilialById: {
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "GET",
            params: {
                Id: "@id",
            },
            url: `${oDataUrl}?$filter=Id eq :id`,
        },
        query: {
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "GET",
        },
    };
    return $resource<Filial, IFilialResource>(oDataUrl, {}, actions);
}

type IIntegrationResourceFactoryFn = (productName: string) => ng.resource.IResource<void>;
function integrationResourceFactoryService($odataresource: IOdataResourceService, envService: EnvService) {
    "ngInject";

    return (productName: string) => {
        const apiUrl = envService.read<string>("apiUrl");
        const oDataUrl = `${apiUrl}integration/QR`;
        return $odataresource(
            oDataUrl,
            {},
            {},
            {
                isodatav4: true,
            }
        );
    };
}
export type TJournalResourceFactoryFn = (productName: string) => IJournalResource;
function journalResourceFactory(
    $http: IHttpService,
    $resource: ng.resource.IResourceService,
    envService: EnvService,
    productsService: ProductsService,
    contractResourceFactory: TContractResourceFactoryFn,
    integrationResourceFactory: IIntegrationResourceFactoryFn
): TJournalResourceFactoryFn {
    "ngInject";

    return (productName: string) => {
        const apiUrl = envService.read<string>("apiUrl");
        const oDataUrl = apiUrl + "api/" + capitalizeFirstLetter(productName);

        const actions: IUproResourceActionHash = {
            osagoReassign: {
                method: "POST",
                transformRequest: (data) => {
                    delete data.Id;
                    return angular.toJson(data);
                },
                url: oDataUrl + "(:id)/K3.Reassign",
            },
            query: {
                headers: { "X-Requested-With": "XMLHttpRequest" },
                method: "GET",
                url: oDataUrl + "ContractJournalItems",
            },
        };

        if (productName === "travelingAbroad") {
            actions.programs = {
                method: "GET",
                url: oDataUrl + "ContractJournalItems/K3.GetTravelingAbroadInsurancePrograms",
            };
        }

        const resource = $resource<IJournalContractDTO, IJournalResource>(oDataUrl, {}, actions);
        resource.fetchContracts = (params: IODataParams) => {
            const operation = $http<IODataResourceResult<IJournalContractDTO>>({
                headers: {
                    Accept: "application/json; odata.metadata=none",
                    "OData-Version": "4.0",
                },
                method: "GET",
                params,
                url: oDataUrl + "ContractJournalItems",
            }).then(({ data }) => {
                const product = productsService.getByCode(productName);
                const ContractClass = product.contractClass;
                const modelResource = contractResourceFactory(productName);
                const integrationResource = integrationResourceFactory(productName);
                class JournalContractClass extends journalContractClassFactory(ContractClass) {}
                const contracts = data.value.map((contract) => {
                    const contractPrototype = cast(contract, JournalContractClass) as JournalContractClass;
                    contractPrototype.setResourceProvider(modelResource);
                    contractPrototype.setIntegrationResource(integrationResource);
                    return contractPrototype;
                });
                const response = new ODataResourceResult<Contract>(contracts, operation);
                return response;
            });
            return {
                $promise: operation,
            };
        };
        return resource;
    };
}

function statusResource($resource: ng.resource.IResourceService, envService: EnvService) {
    "ngInject";

    const apiUrl = envService.read<string>("apiUrl");
    const oDataUrl = `${apiUrl}api/ContractStatuses`;

    // create the service
    return $resource(oDataUrl, {
        $count: true,
        $filter: null,
        $format: "json",
        $orderby: "Name",
        $skip: 0,
    });
}

function saleLimitsService($resource: ng.resource.IResourceService, envService: EnvService) {
    const salesRestrictionUrl = envService.read<string>("salesRestrictionUrl");
    const oDataUrl = `${salesRestrictionUrl}api/EmployeeSalesRestrictionAggregates`;

    const actions: IUproResourceActionHash = {
        save: {
            method: "POST",
            url: oDataUrl,
        },
        saveFilialsKaskoDays: {
            method: "POST",
            params: {
                amount: "@amount",
            },
            // tslint:disable-next-line: max-line-length
            url: `${oDataUrl}/K3.SetGlobalAmountOfDaysSinceLastKaskoContract?amountOfdaysSinceLastKaskoContract=:amount`,
        },
    };

    return $resource(oDataUrl, {}, actions);
}

function phoneValidationResource($resource: ng.resource.IResourceService, envService: EnvService) {
    const pvsUrl = `${envService.read<string>("apiUrl")}pvs`;
    const actions: IUproResourceActionHash = {
        checkVerification: {
            method: "POST",
            url: `${pvsUrl}/CheckVerification`,
            transformRequest: (data: { body: IPVSContractorDTO }) => {
                return angular.toJson(data.body);
            },
        },
        getMagicVerificationCode: {
            responseType: "text",
            method: "POST",
            params: {
                phoneNumber: "@ContractorPhone",
            },
            transformRequest: (data: { ContractorPhone: UgskPhone; contract: IDTO; productName: string }) => {
                return angular.toJson(data.contract);
            },
            transformResponse: (data) => {
                return data;
            },
            url: `${pvsUrl}/GetMagicVerificationCode`,
        },
        requestCode: {
            method: "POST",
            params: {
                phoneNumber: "@phoneNumber",
            },
            transformRequest: (data: { body: IPVSContractorDTO; phoneNumber: string }) => {
                return angular.toJson(data.body);
            },
            url: `${pvsUrl}/RequestCode`,
        },
        verifyPhone: {
            method: "POST",
            params: {
                phoneNumber: "@phoneNumber",
                smsCode: "@smsCode",
            },
            transformRequest: (data: { body: IPVSContractorDTO; phoneNumber: string; smsCode: string }) => {
                return angular.toJson(data.body);
            },
            url: `${pvsUrl}/VerifyPhone`,
        },
    };
    return $resource("", {}, actions);
}

function vehicleResource($resource: ng.resource.IResourceService, envService: EnvService) {
    const apiUrl = envService.read<string>("apiUrl");
    const actions: IUproResourceActionHash = {
        getVehicleBrand: {
            method: "GET",
            params: {
                guid: "@id",
            },
            url: `${apiUrl}api/VehicleBrands?$filter=Id eq :id`,
        },
        getVehicleModel: {
            method: "GET",
            params: {
                guid: "@id",
            },
            url: `${apiUrl}api/VehicleModels?$filter=Id eq :id`,
        },
        getVehicleModification: {
            method: "GET",
            params: {
                guid: "@id",
            },
            url: `${apiUrl}api/VehicleModifications?$filter=VehicleModelId eq :id`,
        },
    };
    return $resource("", {}, actions);
}

function addressResource($resource: ng.resource.IResourceService, envService: EnvService) {
    const apiUrl = envService.read<string>("apiUrl");
    const actions = {
        searchAddress: {
            isArray: true,
            method: "GET",
            params: {
                query: "@query",
            },
            url: `${apiUrl}Search/Address?query=:query`,
        },
    };
    return $resource("", {}, actions);
}

function cashboxResource($resource: ng.resource.IResourceService, envService: EnvService) {
    const apiUrl = envService.read("apiUrl");
    const actions: IUproResourceActionHash = {
        createPrecheck: {
            _suppressErrors: true,
            method: "POST",
            params: {
                Id: "@Id",
            },
            url: `${apiUrl}CashBox/CreatePrecheck?contractId=:Id`,
        },
        getCashboxList: {
            isArray: true,
            method: "GET",
            url: `${apiUrl}CashBox/GetCashBoxList`,
        },
        getEmployeeCashbox: {
            _suppressErrors: true,
            method: "GET",
            transformResponse: (data, headersGetter, status) => {
                const parsed = data.replace(/\"/g, "");
                return {
                    cashboxGuid: status === 200 && parsed.length ? parsed : "",
                };
            },
            url: `${apiUrl}CashBox`,
        },
        saveEmployeeCashbox: {
            method: "POST",
            params: {
                guid: "@guid",
            },
            url: `${apiUrl}CashBox?cashBoxGuid=:guid`,
        },
    };
    return $resource("", {}, actions);
}

function additionalPhonesResource($resource: ng.resource.IResourceService, envService: EnvService) {
    const apiUrl = envService.read("apiUrl");
    const actions: IUproResourceActionHash = {
        getAdditionalPhones: {
            isArray: true,
            method: "GET",
            params: {
                Id: "@Id",
            },
            url: `${apiUrl}Phone/GetAdditionalPhones?contractId=:Id`,
        },
    };
    return $resource("", {}, actions);
}

function asyncResource($resource: ng.resource.IResourceService, envService: EnvService) {
    const apiUrl = envService.read("apiUrl");
    const actions: IUproResourceActionHash = {
        osagoCalculateResult: {
            _suppressErrors: true,
            method: "GET",
            params: {
                taskId: "@taskId",
            },
            url: `${apiUrl}async/Osago/Calculate?taskId=:taskId`,
        },

        osagoCreate: {
            method: "POST",
            url: `${apiUrl}async/Osago/Calculate`,
        },
        osagoSign: {
            method: "POST",
            params: {
                contractId: "@contractId",
            },
            transformResponse: (data, headerGetter, status) => {
                if (status === 200) {
                    return { taskId: parseInt(String(data), 10) };
                }
            },
            url: `${apiUrl}async/Osago/Sign?contractId=:contractId`,
        },
        osagoSignOffline: {
            method: "POST",
            params: {
                contractId: "@contractId",
            },
            url: `${apiUrl}async/Osago/signOffline?contractId=:contractId`,
        },
        osagoSignResult: {
            _suppressErrors: true,
            method: "GET",
            params: {
                taskId: "@taskId",
            },
            transformResponse: (data, headerGetter, status) => {
                if (String(data).toLocaleUpperCase() === "NULL") {
                    return null;
                }
                if (status === 200) {
                    const json = angular.fromJson(data);
                    if (typeof json.MessageObject === "string") {
                        try {
                            json.MessageObject = JSON.parse(json.MessageObject);
                        } catch (error) {
                            /* */
                        }
                    }
                    return json;
                } else if (status === 404) {
                    return {
                        status: 404,
                    };
                }
            },
            url: `${apiUrl}async/Osago/Sign?taskId=:taskId`,
        },
        // ------ sign throught draft
        osagoSignResultThroughtDraft: {
            _suppressErrors: true,
            method: "GET",
            params: {
                taskId: "@taskId",
            },
            transformResponse: (data, headerGetter, status) => {
                if (String(data).toLocaleUpperCase() === "NULL") {
                    return null;
                }
                if (status === 200) {
                    const json = angular.fromJson(data);
                    if (typeof json.MessageObject === "string") {
                        try {
                            json.MessageObject = JSON.parse(json.MessageObject);
                        } catch (error) {
                            /* */
                        }
                    }
                    return json;
                } else if (status === 404) {
                    return {
                        status: 404,
                    };
                }
            },
            url: `${apiUrl}async/Osago/sign-t-draft?taskId=:taskId`,
        },
        osagoSignThroughtDraft: {
            method: "POST",
            params: {
                contractId: "@contractId",
            },
            url: `${apiUrl}async/Osago/sign-t-draft?contractId=:contractId`,
        },
        // ----
        // --- signt throught formed
        osagoSignThroughtFormed: {
            method: "POST",
            params: {
                contractId: "@contractId",
            },
            url: `${apiUrl}async/Osago/sign?contractId=:contractId`,
        },
        osagoSignResultThroughtFormed: {
            _suppressErrors: true,
            method: "GET",
            params: {
                taskId: "@taskId",
            },
            transformResponse: (data, headerGetter, status) => {
                if (String(data).toLocaleUpperCase() === "NULL") {
                    return null;
                }
                if (status === 200) {
                    const json = angular.fromJson(data);
                    if (typeof json.MessageObject === "string") {
                        try {
                            json.MessageObject = JSON.parse(json.MessageObject);
                        } catch (error) {
                            /* */
                        }
                    }
                    return json;
                } else if (status === 404) {
                    return {
                        status: 404,
                    };
                }
            },
            url: `${apiUrl}async/Osago/sign?taskId=:taskId`,
        },
        osagoSignResultOffline: {
            _suppressErrors: true,
            method: "GET",
            params: {
                taskId: "@taskId",
            },
            transformResponse: (data, headerGetter, status) => {
                if (String(data).toLocaleUpperCase() === "NULL") {
                    return null;
                }
                if (status === 200) {
                    const json = angular.fromJson(data);
                    if (typeof json.MessageObject === "string") {
                        try {
                            json.MessageObject = JSON.parse(json.MessageObject);
                        } catch (error) {
                            /* */
                        }
                    }
                    return json;
                } else if (status === 404 || status === 403) {
                    return {
                        message: status === 403 ? "Недостаточно прав для выполнения операции" : null,
                        status,
                    };
                }
            },
            url: `${apiUrl}async/Osago/signOffline?taskId=:taskId`,
        },
        // ----
        osagoUpdate: {
            method: "PUT",
            params: {
                id: "@id",
                isForced: "@isForced",
            },
            url: `${apiUrl}async/Osago/Calculate?id=:id&isForced=:isForced`,
        },
        osagoCancel: {
            _suppressErrors: true,
            method: "POST",
            params: {
                contractId: "@contractId",
            },
            transformResponse: (data, headerGetter, status) => {
                if (status === 200) {
                    return { taskId: parseInt(String(data), 10) };
                } else if (status === 404) {
                    return angular.fromJson(data);
                }

                return data;
            },
            url: `${apiUrl}async/Osago/cancel/:contractId`,
        },
        osagoCancelResult: {
            _suppressErrors: true,
            method: "GET",
            params: {
                taskId: "@taskId",
            },
            transformResponse: (data, headerGetter, status) => {
                if (String(data).toLocaleUpperCase() === "NULL") {
                    return null;
                }
                if (status === 200) {
                    const json = angular.fromJson(data);
                    if (typeof json.MessageObject === "string") {
                        try {
                            json.MessageObject = JSON.parse(json.MessageObject);
                        } catch (error) {
                            /* */
                        }
                    }
                    return json;
                } else if (status === 404 || status === 403) {
                    return {
                        message: status === 403 ? "Недостаточно прав для выполнения операции" : null,
                        status,
                    };
                }
            },
            url: `${apiUrl}async/Osago/cancel?taskId=:taskId`,
        },
        osagoAnnul: {
            _suppressErrors: true,
            method: "POST",
            params: {
                contractId: "@contractId",
            },
            transformResponse: (data, headerGetter, status) => {
                if (status === 200) {
                    return { taskId: parseInt(String(data), 10) };
                } else if (status === 404) {
                    return angular.fromJson(data);
                }

                return data;
            },
            url: `${apiUrl}async/Osago/annul/:contractId`,
        },
        osagoAnnulResult: {
            _suppressErrors: true,
            method: "GET",
            params: {
                taskId: "@taskId",
            },
            transformResponse: (data, headerGetter, status) => {
                if (String(data).toLocaleUpperCase() === "NULL") {
                    return null;
                }
                if (status === 200) {
                    const json = angular.fromJson(data);
                    if (typeof json.MessageObject === "string") {
                        try {
                            json.MessageObject = JSON.parse(json.MessageObject);
                        } catch (error) {
                            /* */
                        }
                    }
                    return json;
                } else if (status === 404 || status === 403) {
                    return {
                        message: status === 403 ? "Недостаточно прав для выполнения операции" : null,
                        status,
                    };
                }
            },
            url: `${apiUrl}async/Osago/annul?taskId=:taskId`,
        },
    };
    return $resource("", {}, actions);
}

function paymentProcessResource($resource: ng.resource.IResourceService, envService: EnvService) {
    const apiUrl = envService.read<string>("apiUrl");
    const actions = {
        refreshStatus: {
            method: "POST",
            params: {
                contractId: "@contractId",
            },
            transformResponse: (data, headerGetter, status) => {
                if (status === 200) {
                    return { data: data };
                } else {
                    return angular.fromJson(data);
                }
            },
            url: `${apiUrl}Payments/RefreshPaymentProcess/:contractId`,
        },
    };
    return $resource("", {}, actions);
}

function paymentStatusResource($resource: ng.resource.IResourceService, envService: EnvService) {
    "ngInject";

    const apiUrl: string = envService.read("apiUrl");
    const actions = {
        getStatus: {
            _suppressErrors: true,
            cancellable: true,
            method: "POST",
            params: {
                id: "@Id",
            },
            transformResponse: (data, headerGetter, status) => {
                if (status === 200) {
                    return { IsPayed: true };
                } else {
                    data = angular.fromJson(data);
                    const result = {
                        IsPayed: false,
                        Message: undefined,
                    };

                    if (data.Message) {
                        result.Message = data.Message;
                    }
                    return result;
                }
            },
            url: `${apiUrl}Payments/RefreshPaymentStatus/:id`,
        },
    };

    return $resource("", {}, actions);
}

function sendContractToQRResource($resource: ng.resource.IResourceService, envService: EnvService) {
    "ngInject";

    const apiUrl: string = envService.read("apiUrl");
    const actions = {
        send: {
            _suppressErrors: true,
            method: "POST",
            url: `${apiUrl}ManualAccounting/ManualSendContractsToQr`,
        },
    };

    return $resource("", {}, actions);
}

export default angular
    .module("ugsk.resource", [ngResource])
    .service("employeeResource", employeeResource)
    .service("productsVersionResource", productsVersionResource)
    .service("repositoryService", repositoryService)
    // .service("prolongationResourceFactory", prolongationResourceFactory)
    .factory("filialService", filialService)
    .service("contractResourceFactory", contractResourceFactoryService)
    .service("factPaymentsResource", factPaymentsResource)
    .service("paymentRegistriesResource", paymentRegistriesResource)
    .service("integrationResourceFactory", integrationResourceFactoryService)
    .factory("journalResourceFactory", journalResourceFactory)
    .service("statusResource", statusResource)
    .service("getPrintableDocument", getPrintableDocumentService)
    // .service("authenticationByLoginService", authenticationByLoginService)
    .service("saleLimitsService", saleLimitsService)
    .service("phoneValidationResource", phoneValidationResource)
    .service("vehicleResource", vehicleResource)
    .service("addressResource", addressResource)
    .service("cashboxResource", cashboxResource)
    .service("asyncResource", asyncResource)
    .service("emailResource", emailResource)
    .service("sendInsuranceRulesResource", sendInsuranceRules)
    .service("paymentProcessResource", paymentProcessResource)
    .service("paymentStatusResource", paymentStatusResource)
    .service("additionalPhonesResource", additionalPhonesResource)
    .service("sendContractToQRResource", sendContractToQRResource).name;
