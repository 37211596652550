import angular from "angular";
import { Contract } from "domain/classes/contract.class";
import { IODataParams } from "infrastructure/interfaces";
import { Day, Int } from "infrastructure/types";
import moment from "moment";
import { ChartType, IChart, IChartsList, IDashboardResource } from "./interfaces";

class DashboardChartDataService {
    static get $inject() {
        return ["dashboardResource"];
    }

    public charts: IChartsList;
    public chartType: ChartType;

    constructor(private dashboardResource: IDashboardResource) { /** */ }

    public setCharts(charts: IChartsList, chartType: ChartType): void {
        this.charts = charts;
        this.chartType = chartType;
    }

    public clearCharts(): void {
        angular.forEach(this.charts, (chart) => {
            chart.data = [];
            chart.count = 0;
            chart.amount = 0;
            chart.toProlong = 0;
            chart.prolonged = 0;
        });
    }

    public populate(params: IODataParams, periodStart: Day, periodEnd: Day): angular.IPromise<void> {
        this.clearCharts();
        return this.dashboardResource.query(params).$promise.then((data) => {
            angular.forEach(data.value, (item) => {
                const productCode = this.getChartNameByProductName(item.ProductCode);

                if (!productCode) {
                    console.error("ProductCode not found", item);
                } else {
                    if (this.inPeriod(item.ContractTo, periodStart, periodEnd)) {
                        this.charts[productCode].toProlong++;

                        if (
                            item.NextContractStatusId === 2
                            || (productCode.toLowerCase() === "osago"
                            && [7, 8, 9, 10].includes(item.NextContractStatusId))
                        ) {
                            this.charts[productCode].prolonged++;
                        }
                    }
                    if (this.inPeriod(item.SigningDate, periodStart, periodEnd)) {
                        this.pushChartItem(this.charts[productCode], item.SigningDate, item.InsurancePremium);
                    }
                }
            });
        });
    }

    public inPeriod(date: Day, begin: Day, end: Day): boolean {
        return (
            !end || moment(date).format("YYYYMMDD") === moment(end).format("YYYYMMDD")
            || moment(date).isBetween(begin, end)
        );
    }

    public getChartNameByProductName(productName: string): string | undefined {
        if (!productName) {
            return;
        }

        let chartName: string;
        for (const chart in this.charts) {
            if (this.charts[chart].products.includes(productName)) {
                chartName = chart;
                break;
            }
        }
        return chartName;
    }

    public pushChartItem(object: IChart, date: Day, premium: Int) {
        object.count += 1;
        object.amount += premium;

        let time: Date | number = new Date(date);
        const offset = new Date().getTimezoneOffset() * 60000;

        if (this.chartType === "today" || this.chartType === "yesterday") {
            time = new Date(time.getFullYear(), time.getMonth(), time.getDate(), time.getHours()).getTime() - offset;
        } else {
            time = new Date(time.getFullYear(), time.getMonth(), time.getDate()).getTime() - offset;
        }

        for (let i = 0; i < object.data.length; i++) {
            const item = object.data[i];
            if (item[0] === time) {
                item[1] += 1;
                return;
            }
        }
        object.data.push([time, 1]);
    }

    public getCharts(): IChartsList {
        return this.charts;
    }

    public getCount(): Int {
        let count = 0;
        angular.forEach(this.charts, (chart) => {
            count += chart.count;
        });
        return count;
    }

    public getAmount(): Int {
        let amount = 0;
        angular.forEach(this.charts, (chart) => {
            amount += chart.amount;
        });
        return amount;
    }
}

angular
    .module("app.dashboard.service", [])
    .service("dashboardChartDataService", DashboardChartDataService);
