import { Transition } from "@uirouter/angularjs";
import angular from "angular";
import { capitalizeFirstLetter, Helpers } from "infrastructure/app.helpers";
import IScope from "infrastructure/interfaces/IScope";
import { Employee } from "infrastructure/services/employee.service";
import { EnvService } from "infrastructure/services/env.service";
import { NotifyService } from "infrastructure/services/notifyService";
import { Observable, Subject } from "rxjs";
import { BaseProductBodyController } from "./baseProduct.body.controller";
import IPageSharedData from "infrastructure/interfaces/IPageSharedData";

// todo: перенести общую логику для КАСКО и ОСАГО в этот базовый контроллер
export class BaseAutoBodyController extends BaseProductBodyController {
    public paymentUpdate$: Observable<void>;
    public paymentsUpdate: Subject<void>;
    protected stateCross: string;
    constructor(
        $injector: angular.auto.IInjectorService,
        $transition$: Transition,
        params: object,
        $scope: IScope,
    ) {
        super($injector, $transition$, params, $scope);
    
        this.pageSharedData = this.resolve<IPageSharedData>("pageSharedData");
        this.hasInspectionPermission = this.hasInspectionPermission.bind(this);
        this.stateCross = $transition$.params().cross;
    }

    public updateFieldRepository(fieldName: string): angular.IPromise<void> {
        const [$timeout] = this.di(["$timeout"]);
        return $timeout().then(() => super.updateFieldRepository(fieldName));
    }

    public hasInspectionPermission(): boolean {
        if (this.Product === "uAuto") {
            return true;
        }

        const employee = this.resolve<Employee>("employee");
        return Boolean(employee.hasPermission("CreateInspectionConclusion", this.Product));
    }

    public hasImageUploadPermission(): boolean {
        const employee = this.resolve<Employee>("employee");
        return employee.hasPermission("UploadInspectionImages", this.Product);
    }

    public $onInit(): void {
        super.$onInit();
        this.phoneValidationService.setContractRequiredFields({
            individual: [
                { description: "ФИО страхователя", field: ["InsuredPersonLastName", "InsuredPersonFirstName"] },
                { description: "Дата рождения страхователя", field: "InsuredPersonBirthday" },
            ],
            legalEntity: [
                { description: "Наименование организации страхователя", field: "InsuredOrgName" },
                { description: "ИНН организации страхователя", field: "InsuredOrgINN" },
            ],
        });

        this.paymentsUpdate = new Subject();
        this.paymentUpdate$ = this.paymentsUpdate.asObservable();
    }

    public onPaymentsTabSelected(): void {
        this.paymentsUpdate.next();
    }

    public onPrintReceipt(invoiceId: string): angular.IPromise<void> {
        const helpers = this.di<Helpers>("helpers");
        const notifyService = this.di<NotifyService>("notifyService");
        const envService = this.di<EnvService>("envService");
        return this.Contract.$loadPrintable({
            contentType: `application/pdf; k3.presentation=InvoiceForPayment; k3.parameter.InvoiceGuid=${invoiceId}`,
            url: `${envService.read("apiUrl")}${capitalizeFirstLetter(this.Product)}/${this.Contract.Id}`,
        }).then((blob) => {
            notifyService.progressStop();
            const $q = this.di<angular.IQService>("$q");
            return $q((resolve) => {
                return helpers
                    .openUrlInIframe(blob.url)
                    .then(resolve, resolve);
            });
        });
    }

    public onPrintFreeFormReceipt(invoiceId: string, paymentId: string): angular.IPromise<void> {
        const helpers = this.di<Helpers>("helpers");
        const notifyService = this.di<NotifyService>("notifyService");
        const envService = this.di<EnvService>("envService");

        return this.Contract.$loadPrintable({
            contentType: "application/pdf",
            url: `${envService.read("apiUrl")}GetFreeFormReceiptPaymentService/${invoiceId}/${paymentId}`,
        }).then((blob) => {
            notifyService.progressStop();
            const $q = this.di<angular.IQService>("$q");
            return $q((resolve) => {
                return helpers
                    .openUrlInIframe(blob.url)
                    .then(resolve, resolve);
            });
        });
    }

    public getInsuredPersonFullName(): string {
        if (this.Contract.isInsurantALegalEntity()) {
            return this.Contract.InsuredOrgName;
        }

        return [
            this.Contract.InsuredPersonLastName,
            this.Contract.InsuredPersonFirstName,
            this.Contract.InsuredPersonMiddleName,
        ].filter((item) => item).join(" ");
    }
}
