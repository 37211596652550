import angular from "angular";
import moment from "moment";

import { BoxAccidentBodyControllerBase, IDiscountListItem } from "../boxAccidentBodyControllerBase";
import { ChildAccidentInsuredPerson } from "./childAccident.factory";

export class ChildAccidentController extends BoxAccidentBodyControllerBase {
    public $onInit() {
        super.$onInit();
        const getContractFromMinDate = () => {
            return moment(this.Contract.SigningDate, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
        };

        this.registrationDateConfig = {
            endDate: moment().format("YYYY-MM-DD"),
            startDate: moment().subtract(100, "years").format("YYYY-MM-DD"),
        };

        if (!this.Contract.ContractStatusId) {
            this.Contract.SigningDate = moment().format("YYYY-MM-DD");
            this.Contract.ContractFrom = getContractFromMinDate();
            this.Contract.UnderwriterCoefficient = this.Repository.UnderwriterCoefficientRestriction.Collection[0].MinValue;
            this.insuranceTermChanged();
        }

        this.contractMinDate = getContractFromMinDate();
        this.contractMaxDate = moment(this.Contract.SigningDate, "YYYY-MM-DD").add(6, "months").format("YYYY-MM-DD");

        this.discountCollection = this.getDiscountCollection();

        this.defaultNumberOfInsuredPersons = this.Repository.InsuredPersonCountRestriction.Collection[0].MinValue;

        if (!angular.isArray(this.Contract.InsuredPersons)) {
            this.Contract.InsuedPersons = [];
        }

        if (angular.equals(this.Contract.InsuredPersons.length, 0)) {
            for (let i = 0; i < this.defaultNumberOfInsuredPersons; i++) {
                this.Contract.InsuredPersons.push(new ChildAccidentInsuredPerson());
            }
        }
    }

    public addPerson() {
        for (let i = 1; i <= this.personsNumber; i++) {
            this.Contract.InsuredPersons.push(new ChildAccidentInsuredPerson());
        }

        if (!this.isDisableAddPerson()) {
            this.personsNumber = 1;
        }
        this.pageSharedData.Form.modified = true;
    }

    public insuranceTermChanged() {
        this.Contract.ContractTo = moment(this.Contract.ContractFrom, "YYYY-MM-DD")
            .add(12, "months")
            .subtract(1, "days")
            .format("YYYY-MM-DD");
    }

    private getDiscountCollection(): IDiscountListItem[] {
        const collection = [];
        let labelDiscount = "";
        const fromValue = this.Repository.DiscountRestriction.Value[0].Value;
        const toValue = this.Repository.DiscountRestriction.Value[1].Value;

        for (let i = fromValue; i <= toValue; i++) {
            if (i > 0) {
                labelDiscount = `${i} %`;
            } else {
                labelDiscount = "Без скидки";
            }

            collection.push({ Id: i, Value: labelDiscount });
        }
        return collection;
    }
}
