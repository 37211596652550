export function InputRegExpDirective(): ng.IDirective {
    "ngInject";

    return {
        link: (scope, element, attributes: IAttributes) => {
            const reg = new RegExp(attributes.inputRegExp);

            element.on("keypress", (event) => {
                if (!reg.test(event.key)) event.preventDefault();
            });
        },
        require: "^ngModel",
        restrict: "A",
    };
}
