import angular from "angular";
import AuthenticationServiceModule from "./services/authentication.service";
import { BannersService } from "./services/banners.service";
import { CapabilitiesService } from "./services/capabilities.service";
import { contractsServiceFactory } from "./services/contracts.service";
import dictionariesService from "./services/dictionaries.service";
import { EmployeeService } from "./services/employee.service";
import envService from "./services/env.service";
import { ExcelReaderService } from "./services/excelReader.service";
import { ExcelWriterService } from "./services/excelWriter.service";
import { FactPaymentsService } from "./services/factPayments.service";
import InfiniteScroll from "./services/infiniteScroll.service";
import { IntegrationInfo } from "./services/integrationInfo.service";
import notifyModules from "./services/notifyService";
import productsServiceModules from "./services/products.service";
import { SignalRService } from "./services/signalR.service";
import { StatusService } from "./services/status.service";
import { UgskODataService } from "./services/ugskOData.service";
import { UgskTableFilterService } from "./services/ugskTableFilter.service";
import { ValidationService } from "./services/validation.service";
import { VersionsInfoService } from "./services/versionsInfo.service";
import { AsyncContractSaleService } from "./services/async.contract.sale.service";
import { RSAMessages } from "./services/rsaMessages";

angular.module("ugsk.service.validation", []).service("validationService", ValidationService);

export default angular.module("ugsk.services", [
        envService,
        productsServiceModules,
        dictionariesService,
        AuthenticationServiceModule,
        "ugsk.service.validation",
        notifyModules,
    ])
    .service("infiniteScroll", InfiniteScroll)
    .service("employeeService", EmployeeService)
    .service("versionsInfoService", VersionsInfoService)
    .service("factPaymentsService", FactPaymentsService)
    .service("statusService", StatusService)
    .service("capabilitiesService", CapabilitiesService)
    .service("excelReaderService", ExcelReaderService)
    .service("excelWriterService", ExcelWriterService)
    .service("ugskTableFilterService", UgskTableFilterService)
    .service("ugskODataService", UgskODataService)
    .service("signalRService", SignalRService)
    .service("bannersService", BannersService)
    .service("contractsServiceFactory", contractsServiceFactory)
    .service("showIntegrationInfoService", IntegrationInfo)
    .service("asyncContractSaleService", AsyncContractSaleService)
    .service("rsaMessagesService", RSAMessages)
    .name;
