import { BaseAutoToolbarController, IBaseAutoSaveContractParams } from "application/baseAuto.toolbar.controller";

import { Transition } from "@uirouter/core";
import angular, { IHttpResponse, IIntervalService, ITimeoutService } from "angular";
import prolongationDenial from "infrastructure/extraMenuOptionsPresets/prolongationDenial";
import { IToastInstance } from "infrastructure/interfaces";
import IBaseViewParams from "infrastructure/interfaces/IBaseViewParams";
import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { IWebApiResponseBody } from "infrastructure/interfaces/IWebApiResponseBody";
import { IKBMDTO } from "infrastructure/interfaces/WebApi/IKBMDTO";
import {
    CommonErrorsErrorTypeEnum,
    OsagoAsyncResponseCommonError,
    OsagoAsyncResponseDTO,
    ProcessingResultCodeEnum,
} from "infrastructure/interfaces/WebApi/OsagoAsyncResponseDTO";
import { AsyncContractSaleService } from "infrastructure/services/async.contract.sale.service";
import { Employee, EmployeeService } from "infrastructure/services/employee.service";
import { AbstractEmployee } from "infrastructure/services/employee.service";
import { EnvService } from "infrastructure/services/env.service";
import { NotifyService } from "infrastructure/services/notifyService";
import { RSAMessages } from "infrastructure/services/rsaMessages";
import { ValidationService } from "infrastructure/services/validation.service";
import { DateTimeOffset, Int } from "infrastructure/types";
import moment from "moment";
import {
    EOSAGO_POLICY_SERIAL,
    OSAGO_DRAFT_SERIAL,
    OSAGO_WORK_STAGES,
    PAYMENT_KINDS,
    RELIABLE_DRIVE_PRODUCT_NAME,
    SALE_CHANNELS,
    USAGE_PURPOSES_ENUM,
} from "../constants";
import { OsagoContract, OsagoDriver } from "../osago/osago.factory";
import forcedCalculation from "./extraMenuOptionsPresets/forcedCalculation";
import reliableDriveForcedCalculation from "./extraMenuOptionsPresets/reliableDriveForcedCalculation";
import sendPolicy from "./extraMenuOptionsPresets/sendPolicy";
import sendNotificationTrafficAccident from "./extraMenuOptionsPresets/sendNotificationTrafficAccident";
import prolongate from "./extraMenuOptionsPresets/prolongate";
import syncContractStatus from "./extraMenuOptionsPresets/syncContractStatus";
import rsaMessages from "./extraMenuOptionsPresets/rsaMessages";
import cancelContract from "./extraMenuOptionsPresets/cancelContract";
import annulContract from "./extraMenuOptionsPresets/annulContract";
import { IOSAGOPageSharedObject } from "./interfaces/IOSAGOPageSharedObject";
import { ReliableDriveContract } from "application/osago/classes/ReliableDriveContract";
import RelatedProductService from "application/osago/relatedProduct.service";
import { isPeriodsOverlapped } from "application/osago/helpers";
import { Helpers } from "infrastructure/app.helpers";
import resetCacheKBM from "./extraMenuOptionsPresets/resetCacheKBM";

const ExtraOptions: Array<IExtraOptionPrototype | string> = [
    "manual",
    "integrationInfo",
    "changeOwner",
    forcedCalculation,
    reliableDriveForcedCalculation,
    prolongate,
    syncContractStatus,
    prolongationDenial,
    rsaMessages,
    sendNotificationTrafficAccident,
    sendPolicy,
    cancelContract,
    annulContract,
    resetCacheKBM,
];

const ExtraSignOptions: IExtraOptionPrototype[] = [
    {
        action(context: OsagoToolbarController) {
            context.signAsyncContractTroughtDraft();
        },
        disabled(context: OsagoToolbarController) {
            const whiteList = ["РРР", "МММ", "ААС", EOSAGO_POLICY_SERIAL, "ААМ", "ААН"];
            const policySerial = context.Contract.PolicySerial;
            if (policySerial === "ТТТ") {
                return false;
            }
            return !whiteList.includes(policySerial);
        },
        visible(context: OsagoToolbarController) {
            return context.originalEmployee.hasPermission("SignTDraft", "Osago");
        },
        icon: "fa-check",
        title: "Продать (через проект)",
        tooltip:
            "Продажа договора, когда перед заключением в РСА направляется " +
            "рассчитанный черновик договора для проверки. Если РСА вернет " +
            "ошибки, то данные договора правятся в свободном формате, т.к. для РСА договор еще не заключен",
        view: "form",
    },
    {
        action(context: OsagoToolbarController) {
            context.signAsyncContractTroughtFormed(false);
        },
        disabled(context: OsagoToolbarController) {
            const whiteList = ["РРР", "МММ", "ААС", "ААМ", "ААН"];
            const policySerial = context.Contract.PolicySerial;
            return !whiteList.includes(policySerial);
        },
        visible(context: OsagoToolbarController) {
            return context.originalEmployee.hasPermission("SignConcluded", "Osago");
        },
        icon: "fa-check",
        title: "Продать (уже заключенный)",
        tooltip:
            "Продажа договора, когда в РСА направляется уже по факту проданный " +
            "договор. Если РСА вернет ошибки, то правки по договору - через " +
            "аннулирование и заключение нового, либо через доп. соглашение / тех. корректировку",
        view: "form",
    },
    {
        action(context: OsagoToolbarController) {
            context.signAsyncContractTroughtFormed(true);
        },
        disabled(context: OsagoToolbarController) {
            const blackList = [EOSAGO_POLICY_SERIAL, OSAGO_DRAFT_SERIAL];
            return blackList.includes(context.Contract.PolicySerial);
        },
        visible(context: OsagoToolbarController) {
            return context.originalEmployee.hasPermission("SignConcludedOffline", "Osago");
        },
        icon: "fa-check",
        title: "Продать оффлайн",
        view: "form",
    },
];

export class OsagoToolbarController extends BaseAutoToolbarController {
    public originalEmployee: AbstractEmployee;
    public employee: Employee;
    public Contract: OsagoContract;
    public validationService: ValidationService;
    public viewType: string;
    public egSignIsAvailable: boolean = false;
    public notifyService: NotifyService;
    public pageSharedData: IOSAGOPageSharedObject;
    public extraSignOptions: IExtraOptionPrototype[];
    public reliableDriveContract: ReliableDriveContract;
    public relatedProductService: RelatedProductService;
    private rsaMessagesService: RSAMessages;

    constructor($injector: angular.auto.IInjectorService, $transition: Transition, { viewType = "form" }: IBaseViewParams) {
        super($injector, $transition, {
            crossOptions: [
                "uAuto",
                "osago",
                "snugHome",
                "express",
                "myChoice",
                "constructorHome",
                "assuranceHome",
                "assuranceApartment",
                "boxVHI",
                "personalAccident",
                "travelingAbroad",
                "childAccident",
                "adultAccident",
                "familyAccident",
                "shortTermAccident",
                "safeCarAccident",
            ],
            extraOptions: ExtraOptions,
            extraSignOptions: ExtraSignOptions,
            printOptions: [
                {
                    action() {
                        this.printContract();
                    },
                    disabled() {
                        return this.isDisabledPrint();
                    },
                    icon: "fa-print",
                    title: "Печать полиса",
                },
                {
                    action() {
                        const apiUrl = this.envService.read("apiUrl");
                        return this.openPdf({
                            url: `${apiUrl}/reliable-drive/print/${this.reliableDriveContract.Id}`,
                        });
                    },
                    disabled() {
                        const isReliableDriveActive = this.reliableDriveContract.isActive() || this.reliableDriveContract.isUserChoice();
                        return !isReliableDriveActive || this.isDisabledPrint();
                    },
                    icon: "fa-print",
                    title: 'Печать полиса "Надежная поездка"',
                    isVisible() {
                        if (this.viewType !== "form") {
                            return false;
                        }

                        const isReliableDriveSelected
                            = this.reliableDriveContract.isActive() || this.reliableDriveContract.isUserChoice();
                        return isReliableDriveSelected && !this.hideReliableRide;
                    },
                },
                {
                    action() {
                        return this.openPdf({
                            docType: "FiveDrivers",
                            fileNamePrefix: "Печать дополнительных водителей",
                            id: this.Contract.Id,
                        });
                    },
                    disabled() {
                        return this.isDisabledDriversPrint();
                    },
                    icon: "fa-print",
                    title: "Печать дополнительных водителей",
                },
                {
                    action() {
                        return this.openPdf({
                            docType: "Statement",
                            fileNamePrefix: "Заявление",
                            id: this.Contract.Id,
                        });
                    },
                    disabled() {
                        return this.isDisabledPrint();
                    },
                    icon: "fa-print",
                    title: "Заявление",
                },
                {
                    action() {
                        return this.openPdf({
                            docType: "OsagoInspectionConclusionReport",
                            fileNamePrefix: "Акт ПСО",
                            id: this.Contract.Id,
                        });
                    },
                    disabled() {
                        return (
                            !this.Contract ||
                            this.Contract.isSentExternalSystem() ||
                            this.Contract.isBlank() ||
                            !this.Contract.InspectionConclusions ||
                            this.Contract.InspectionConclusions.length < 1
                        );
                    },
                    icon: "fa-print",
                    title: "Акт ПСО",
                },
                {
                    action() {
                        return this.openPdf({
                            docType: "NotificationOsago",
                            fileNamePrefix: "Извещение ДТП ОСАГО",
                            id: this.Contract.Id,
                        });
                    },
                    disabled() {
                        return !this.Contract
                            || this.Contract.isBlank();
                    },
                    icon: "fa-print",
                    title: "Извещение ДТП ОСАГО"
                },
                "worksheet",
                {
                    action() {
                        this.showPrintNotifications();
                        return this.openPdf({
                            docType: "NewPolicy",
                            id: this.Contract.Id,
                        });
                    },
                    disabled() {
                        return this.isDisabledNewPolicyPrint();
                    },
                    // скрываем опцию печати навсегда, в связи с требованиями бизнеса.
                    // http://jr.ugsk.ru/jr/browse/UPF-1077
                    icon: "fa-print",
                    isVisible: () => false,
                    title: "Печать нового полиса",
                },
                "freeFormReceipt",
                {
                    action() {
                        return this.openPdf({
                            docType: "UnderwriterCheckOsago",
                            id: this.Contract.Id,
                        });
                    },
                    disabled() {
                        return this.isDisabledPrint();
                    },
                    icon: "fa-print",
                    isVisible() {
                        return this.employee.hasPermission("GetUnderwriterCheckOsago", "Osago");
                    },
                    title: "Чек Андеррайтера",
                },
            ],
            viewType,
        });

        const employee = this.resolve<Employee>("employee");
        this.relatedProductService = this.di("relatedProductService");
        this.originalEmployee = this.resolve<AbstractEmployee>("originalEmployee");
        this.egSignIsAvailable = viewType === "form" && employee.hasPermission("SignEGarant", "Osago");
        this.reliableDriveContract = viewType === "form" ? this.resolve("reliableDriveContract") : null;
        this.validationService = this.di<ValidationService>("validationService");
        this.rsaMessagesService = this.di<RSAMessages>("rsaMessagesService");
        this.envService = this.di<EnvService>("envService");
    }

    public get hideReliableRide(): boolean {
        const impersonateLogin = this.employee.impersonateLogin;
        return [
            "partner\\KropivnitskayaOA_Khan",
            "partner\\KropivnitskayaOA_Kha",
            "partner\\TitarenkoOA_Hm",
        ].includes(impersonateLogin);
    }
    /**
     * @override
     * @todo по некоторым данным, в рамках ОСАГО 2.0, флаг HasBeenRSACalculated не актуален! Необходимо провести ревизию
     * @return {Boolean}
     */
    public isDisabledSell(): boolean {
        // const isKBMCalculated = this.Contract.HasBeenRSACalculated;
        const isDisabledOsagoSell = this.envService.read("isDisabledOsagoSell");
        return super.isDisabledSell() || isDisabledOsagoSell; // || !isKBMCalculated;
    }

    public isDisabledCalculate(): boolean {
        const isDisabledOsagoCalculate = this.envService.read("isDisabledOsagoSell");
        return super.isDisabledCalculate() || isDisabledOsagoCalculate;
    }

    /**
     * @override
     * @return {String}
     */
    public getPolicyPrintFormName(): string {
        return "Печать полиса";
    }

    /**
     * см. uAuto/toolbar.controller.js
     * @override
     * @returns {Boolean}
     */
    public isDisabledPrintPaymentAccount(): boolean {
        return false;
    }

    /**
     * @description Отличие от стандартой схемы: у контракта не может быть статуса 0.
     * Черновик можно определить наличием статуса 1 и Contract.Id === 0
     * @override
     * @return {Boolean}
     */
    public isDisabledPrint(): boolean {
        if (this.viewType === "form") {
            const isDraft = this.Contract.ContractStatusId === 1 && !this.Contract.Id;
            return isDraft || this.pageSharedData.Form.modified || this.Contract.isSentExternalSystem();
        } else if (this.viewType === "journal") {
            return !this.Contract || this.Contract.isSentExternalSystem();
        }
        return true;
    }

    public isDisabledNewPolicyPrint(): boolean {
        if (this.isDisabledPrint()) {
            return true;
        }
        if (this.viewType === "form") {
            return this.Contract.isEOSAGO();
        } else if (this.viewType === "journal") {
            return !this.Contract || String(this.Contract.PolicyFullValue).indexOf(EOSAGO_POLICY_SERIAL) > 0;
        }
    }

    public isDisabledDriversPrint(): boolean {
        if (this.isDisabledPrint()) {
            return true;
        }
        if (this.viewType === "form") {
            return this.Contract.isEOSAGO() || this.Contract.Drivers.length < 5;
        } else if (this.viewType === "journal") {
            return String(this.Contract.PolicyFullValue).indexOf(EOSAGO_POLICY_SERIAL) > 0 || this.Contract.DriversCount < 5;
        }
    }

    /**
     * @override
     * @see general\UGSK.K3.Front\Scripts\Application\OSAGO.js:395
     */
    public printContract(): ng.IPromise<any> {
        this.showPrintNotifications();
        return super.printContract();
    }

    /**
     * @description Показывает напоминание о неободимости печати Анкеты/Акта ПСО.
     * Используется при печати Полиса c формы договора
     * @return {undefined}
     */
    public showPrintNotifications() {
        if (this.viewType === "form") {
            const isShowPSOMessage =
                this.Contract.InspectionConclusions.length > 0 &&
                !this.Contract.InspectionIsNotRequired &&
                !this.Contract.IsTowardInspection &&
                this.hasInspectionPermission();
            const isLegalType = this.Contract.InsuredContractorType === "юридическое лицо" || this.Contract.OwnerContractorType === "юридическое лицо";
            const isIndividualType = this.Contract.InsuredContractorType === "физическое лицо" || this.Contract.OwnerContractorType === "физическое лицо";
            const isShowWorkSheetMessage = isLegalType || (isIndividualType && this.Contract.InsurancePremium >= 15000);
            const isShowSignWarningMessage = this.Contract.PolicySerial === OSAGO_DRAFT_SERIAL && this.Contract.Drivers.length > 4;
            let message = "";

            if (isShowPSOMessage) {
                message = "Не забудьте распечатать акт ПСО";
            }

            if (isShowWorkSheetMessage) {
                message = "Не забудьте распечатать анкету";
            }

            if (isShowPSOMessage && isShowWorkSheetMessage) {
                message = "Не забудьте распечатать акт ПСО и анкету";
            }

            if (message) {
                this.notifyService.infoMessage("Информация", message);
            }

            if (isShowSignWarningMessage) {
                this.notifyService.message(
                    "Внимание!",
                    // tslint:disable-next-line:max-line-length
                    "Количество водителей больше 4-х. Не забудьте расписаться на 2-й странице договора для подтверждения перечня водителей",
                    {
                        type: "warning",
                    },
                );
            }
        }
    }

    public hasInspectionPermission() {
        const employee = this.resolve<AbstractEmployee>("originalEmployee");
        return Boolean(employee.hasPermission("CreateInspectionConclusion", this.pageSharedData.currentProduct));
    }

    public egSign() {
        const $interval = this.di<IIntervalService>("$interval");
        const validationService = this.di<ValidationService>("validationService");

        const dateInOsagoPeriod = (date: DateTimeOffset) => {
            const minContractFrom = moment()
                .add(4, "d")
                .startOf("d"); //  22.10
            const maxContractFrom = moment()
                .add(60, "d")
                .startOf("d"); //  17.12
            const contractFrom = moment(date).startOf("d"); //  18.10
            const minDiff = minContractFrom.diff(contractFrom, "d"); //  4
            const maxDiff = maxContractFrom.diff(contractFrom, "d"); //  60
            return minDiff <= 0 && maxDiff >= 0;
        };
        const errorsToAdd: {
            [key: string]: string[];
        } = {};
        const preSaleValidations: Array<{
            code: string;
            message: string;
            fn(contract: OsagoContract): boolean;
        }> = [
            {
                code: "VehicleCheckupDocumentType",
                fn: (contract) => {
                    return contract.VehicleCheckupIsNotRequired || contract.VehicleCheckupDocumentType === "Диагностическая карта";
                },
                message: "Выберите тип Диагностическая карта",
            },
            {
                code: "VehicleCheckupDocumentNumber",
                fn: (contract) => {
                    return contract.VehicleCheckupIsNotRequired || Boolean(contract.VehicleCheckupDocumentNumber);
                },
                message: "Укажите номер диагностической карты",
            },
            {
                code: "VehicleCheckupDate",
                fn: (contract) => {
                    return contract.VehicleCheckupIsNotRequired || Boolean(contract.VehicleCheckupDate);
                },
                message: "Укажите дату ТО",
            },
            {
                //  Валидация никогда не сработает, потому, что такая же валидация есть на WebApi
                code: "VehicleDocGrossVehicleWeight",
                fn: (contract) => {
                    return contract.VehicleType !== "Грузовые автомобили" || Boolean(contract.VehicleDocGrossVehicleWeight);
                },
                message: "Укажите разрешенная максимальную массу в кг",
            },
            {
                code: "ContractFrom",
                fn: (contract) => {
                    return dateInOsagoPeriod(contract.ContractFrom);
                },
                message: "Дата должна быть не ранее 4 дней и не позднее 60 дней от текущей даты",
            },
            {
                code: "UsagePeriods",
                fn: (contract) => {
                    for (const usagePeriod of contract.UsagePeriods) {
                        if (!dateInOsagoPeriod(usagePeriod.From)) {
                            return false;
                        }
                    }
                    return true;
                },
                message: "Дата должна быть не ранее 4 дней и не позднее 60 дней от текущей даты",
            },
        ];
        preSaleValidations.forEach((validation) => {
            if (validation.fn(this.Contract)) {
                return;
            }
            if (!errorsToAdd[validation.code]) {
                errorsToAdd[validation.code] = [];
            }
            errorsToAdd[validation.code].push(validation.message);
        });
        for (const [key, value] of Object.entries(errorsToAdd)) {
            validationService.addError(key, value);
        }
        if (Object.keys(errorsToAdd).length) {
            this.notifyService.errorMessage("Ошибка", "На форме имеются ошибки, исправьте их и повторите попытку");
            return;
        }
        let elapsedSeconds = 0;
        const maxElapsedSeconds: Int = Math.floor(this.envService.read("longTimeout") / 1000);
        const progressInstance = this.$uibModal.open({
            backdrop: "static",
            controller: class {
                public get value() {
                    return Math.floor((100 * elapsedSeconds) / maxElapsedSeconds);
                }
                public getElapsed() {
                    const val = maxElapsedSeconds - elapsedSeconds;
                    const minutes = String(Math.floor(val / 60)).padStart(2, "0");
                    const seconds = String(Math.floor(val % 60)).padStart(2, "0");
                    return `${minutes}:${seconds}`;
                }
            },
            controllerAs: "vm",
            template: `
                <div class="ibox">
                    <div class="ibox-title">
                        <h2>Генерация ссылки {{vm.getElapsed()}}</h2>
                    </div>
                    <div class="ibox-content">
                        <div class="progress progress-striped active">
                            <div
                                ng-style="{ width: vm.value + '%'}"
                                class="progress-bar progress-bar-info"></div>
                        </div>
                    </div>
                </div>
            `,
        });
        $interval(() => {
            elapsedSeconds++;
        }, 1000);
        window.onbeforeunload = () => true;
        this.notifyService.removeToasts();
        this.Contract.eGarantSign()
            .catch((requestError: IHttpResponse<IWebApiResponseBody>) => {
                const message = requestError.data.error.message;
                try {
                    //  Если распарсить получилось, то это ответ от WebApi, с его разбором справится общий http interceptor
                    angular.fromJson(message);
                    return;
                } catch (parseError) {
                    /* */
                }
                const errorCodesToValidationCodeMap: {
                    [key: string]: string;
                } = {
                    465: "ContractFrom",
                    474: "UsagePeriods",
                    476: "Insurant",
                    489: "InsuredCommonRealAddress",
                    492: "OwnerCommonRealAddress",
                    495: "InsuredCommonRealAddress",
                    496: "InsuredCommonRealAddress",
                    500: "OwnerCommonRealAddress",
                    501: "OwnerCommonRealAddress",
                    506: "VehicleCheckupDocumentNumber",
                    // 507: "", //  no mapping
                    645: "VehicleVehicleDocuments",
                };

                const wellKnownErrorsRe = /Ошибка\ (\d)(\ (\d+))?:\ (.+)/i;
                for (const error of message.split("; ")) {
                    const result = error.match(wellKnownErrorsRe);
                    if (!result) {
                        if (!errorsToAdd.egSign) {
                            errorsToAdd.egSign = [];
                        }
                        errorsToAdd.egSign.push(error);
                        continue;
                    }
                    const [, , , errorCode, text] = result;
                    if (errorCode) {
                        const validationCode = errorCodesToValidationCodeMap[errorCode];
                        if (validationCode) {
                            if (!errorsToAdd[validationCode]) {
                                errorsToAdd[validationCode] = [];
                            }
                            errorsToAdd[validationCode].push(text);
                        }
                    }
                }
                for (const [key, value] of Object.entries(errorsToAdd)) {
                    validationService.addError(key, value);
                }
            }).finally(() => {
                window.onbeforeunload = null;
                progressInstance.close();
            });
    }

    /**
     * @description Асинхронный расчет и сохранение договора
     */

    public saveAsyncContract(isForced = false) {
        const errorsToAdd: {
            [key: string]: string[];
        } = {};
        const vehicleModificationRSARepository = this.Contract.getRepository().VehicleModificationRSA;

        const preSaleValidations: Array<{
            code: string;
            message: string;
            fn(contract: OsagoContract): boolean;
        }> = [
            {
                code: "Drivers",
                fn: (contract) => {
                    if (contract.Drivers.length < 2) {
                        return true;
                    }
                    const refinedDriversData = contract.Drivers.map((driver) => ({
                        licenseData: this.getDriverLicenseData(driver),
                        personalData: this.getDriverPersonalData(driver),
                    }));
                    for (let i = 0; i < refinedDriversData.length - 1; i++) {
                        for (let j = i + 1; j < refinedDriversData.length; j++) {
                            if (
                              angular.equals(
                                refinedDriversData[i].personalData,
                                refinedDriversData[j].personalData,
                              ) ||
                              angular.equals(
                                refinedDriversData[i].licenseData,
                                refinedDriversData[j].licenseData,
                              )
                            ) {
                                return false;
                            }
                        }
                    }
                    return true;
                },
                message: "Нарушена уникальность состава водителей. Скорректируйте состав ЛДУ или данные о ВУ",
            },
            {
                code: "VehicleModelSpelt",
                fn: (contract) => {
                    return !!contract.VehicleModelSpelt;
                },
                message: "Выберите Марку/Модель ТС",
            },
            {
                code: "VehicleYearMade",
                fn: (contract) => {
                    return !!contract.VehicleYearMade;
                },
                message: "Выберите год выпуска ТС",
            },
            {
                code: "VehicleModificationRSA",
                fn: (contract) => {
                    // TODO вроде как не нужно осталось от http://jr.ugsk.ru/jr/browse/UPF-1348
                    // if (vehicleModificationRSARepository && vehicleModificationRSARepository.Collection.filter(modification => !!modification.VehicleModificationRSA).length === 0) {
                    //     return true;
                    // }
                    return !!contract.VehicleModificationRSAId;
                },
                message: "Выберите модификацию ТС",
            },
            {
                code: "InsuredCommonEmail",
                fn: (contract) => {
                    if (!contract.InsuredCommonEmail) {
                        return true;
                    }
                    const isValidHost = (email: string) => /.@ugsk.ru/gi.test(email);
                    return !isValidHost(contract.InsuredCommonEmail);
                },
                message: "Запрещено указывать почту ugsk.ru в данных страхователя",
            },
            {
                code: "UsagePeriods",
                fn: (contract) => {
                    return !isPeriodsOverlapped(contract.UsagePeriods);
                },
                message: "Периоды использования ТС не должны пересекаться",
            },
        ];

        this.notifyService.removeToasts();
        this.rsaMessagesService.reset();
        let alreadyInProgressToastInstance: IToastInstance = null;
        const defaultBlockMessage = "Идет расчет договора. Подождите, пожалуйста...";
        const validationService = this.di<ValidationService>("validationService");

        if (!this.Contract.isLocked()) {
            validationService.clear();
            preSaleValidations.forEach((validation) => {
                if (validation.fn(this.Contract)) {
                    return;
                }
                if (!errorsToAdd[validation.code]) {
                    errorsToAdd[validation.code] = [];
                }
                errorsToAdd[validation.code].push(validation.message);
            });

            for (const [key, value] of Object.entries(errorsToAdd)) {
                validationService.addError(key, value);
            }
            if (Object.keys(errorsToAdd).length) {
                this.notifyService.errorMessage("Ошибка", "На форме имеются ошибки, исправьте их и повторите попытку");
                return;
            }
        }
        const validationErrors = validationService.validateObject(this.Contract);
        /**
         * Ignore payment validations
         * @description У черновика договора не должно быть фактического платежа,
         * но он, увы, есть. Ошибки его валидации приходится игнорировать
         * @todo Когда у черновика пропадет фактический платеж - удалить этот костыль
         */
        const ErrorsIgnoreList = ["Number", "Amount"];
        const filteredValidationErrors = validationErrors.filter((error) => !ErrorsIgnoreList.some((ignoredCode) => error.code.startsWith(ignoredCode)));

        const getNotifyMessages = (errors: { [key: string]: string[] }) => {
            // tslint:disable-next-line: max-line-length
            const defaultMessage = "<h4>Уважаемый пользователь<h4/>На форме имеются ошибки, устраните их и повторите попытку";
            const validationKeys = Object.keys(errors);
            const formKeys = this.validationService.getValidationKeys();
            const messages = validationKeys.filter((key) => !formKeys.includes(key)).map((key) => this.validationService.getMessages(key));
            return messages.length === 0 ? defaultMessage : messages.join("<br/>");
        };

        if (filteredValidationErrors.length) {
            validationService.addObjectErrors(filteredValidationErrors);
            this.notifyService.errorMessage("Скорректируйте введенные данные", validationService.getAllMessages());
        } else {
            const asyncSave = () => new Promise((resolve, reject) => {
                const asyncContractSaleService = this.di<AsyncContractSaleService>("asyncContractSaleService");
                this.Contract.asyncProcessing = true;
                this.notifyService.progressStart(defaultBlockMessage);
                const insurnerNames = ["ГСК «Югория»", "СО «Сургутнефтегаз»"];
                if (insurnerNames.includes(this.Contract.PreviousInsurerName)) {
                    this.notifyService.infoMessage("Информация", "Не забудьте заполнить причину непролонгации");
                }

                asyncContractSaleService
                    .saveOsago(this.Contract, isForced)
                    .on("onResult", (result) => {
                        if (alreadyInProgressToastInstance) {
                            this.notifyService.removeToasts(alreadyInProgressToastInstance);
                        }
                        this.Contract.asyncProcessing = false;
                        this.validationService.clear();
                        this.notifyService.progressStop();
                        if (result.status === 200) {
                            const response: OsagoAsyncResponseDTO = result.response;
                            if (response.Header && response.Header.ProcessingResultCode === ProcessingResultCodeEnum.NotFound) {
                                this.notifyService.errorMessage("Ошибка", "Договор не найден");
                                reject();
                                // tslint:disable-next-line: max-line-length
                            } else if (
                                response.Header &&
                                (response.Header.ProcessingResultCode === ProcessingResultCodeEnum.RequestError ||
                                    response.Header.ProcessingResultCode === ProcessingResultCodeEnum.InternalError ||
                                    response.Header.ProcessingResultCode === ProcessingResultCodeEnum.Forbidden)
                            ) {
                                if (response.Errors.ValidationErrors) {
                                    this.Contract.InsurancePremium = null;
                                    // tslint:disable-next-line: max-line-length
                                    Object.keys(response.Errors.ValidationErrors).forEach((errorKey) =>
                                        this.validationService.addError(errorKey, response.Errors.ValidationErrors[errorKey])
                                    );
                                    this.notifyService.errorMessage("Скорректируйте введенные данные", getNotifyMessages(response.Errors.ValidationErrors));
                                    reject();
                                }
                                // tslint:disable-next-line: max-line-length
                            } else if (response.Header && (response.Header.ProcessingResultCode === 0 || response.Header.ProcessingResultCode === 2)) {
                                this.notifyService.successMessage("", "Договор успешно сохранен");
                                this.pageSharedData.Form.$setPristine();
                                if (this.Contract.isBlank() || !this.$stateParams.id || this.$stateParams.cross) {
                                    this.$state.go(
                                        this.$state.current.name,
                                        {
                                            id: response.Content.Id,
                                        },
                                        { inherit: false }
                                    );
                                } else {
                                    this.Contract.patchWithRSAResponse((response.Content as unknown) as IKBMDTO);
                                    this.Contract.PreviousContractInfo = response.Content.PreviousContractInfo;
                                    this.Contract.IsProlongation = response.Content.IsProlongation;
                                    this.Contract.IsExternalTransition = response.Content.IsExternalTransition;
                                    this.Contract.InsurancePremium = response.Content.InsurancePremium;
                                    this.Contract.Calculation = response.Content.Calculation;
                                    this.Contract.KaskoContractId = response.Content.KaskoContractId;
                                    this.Contract.KaskoProgramName = response.Content.KaskoProgramName;
                                    this.Contract.KaskoPolicySerial = response.Content.KaskoPolicySerial;
                                    this.Contract.KaskoPolicyNumber = response.Content.KaskoPolicyNumber;
                                    const $timeout = this.di<angular.ITimeoutService>("$timeout");
                                    $timeout(angular.noop);
                                    this.Contract.loadContractCategory().then(() => {
                                        if (this.Contract.isLocked()) {
                                            return;
                                        }
                                        if (this.Contract.ContractCategory === "Z" && this.employee.info.SaleChannel === SALE_CHANNELS.OFFICE) {
                                            const helpers = this.di<Helpers>("helpers");
                                            helpers.confirm({
                                                text: "Продать ОСАГО в других страховых компаниях?",
                                            }).then(
                                              () => helpers.openUrlInIframe("external-sale-iframe.html#calculator", null),
                                            );
                                        }
                                    });
                                }
                                // ХИ
                                if (this.Contract.PolicyNumber && this.Contract.Guid && this.Contract.FilialGuid && this.Contract.SigningDate) {
                                    const [imageRepositoryResource] = this.di(["imageRepositoryResource"]);
                                    imageRepositoryResource
                                        .openOrCreateContainer({
                                            ContractGuid: this.Contract.Guid,
                                            ContractPolicyNumber: this.Contract.PolicyNumber,
                                            ContractSigningDate: this.Contract.SigningDate,
                                            ContractTerritoryGuid: this.Contract.FilialGuid,
                                        })
                                        .$promise.catch(() => {
                                            this.notifyService.warningMessage("Хранилище изображений", "Не удалось создать контейнер в хранилище изображений");
                                        });
                                }
                                if (this.hasInspectionPermission()) {
                                    this.Contract.Id = response.Content.Id; // Чтобы ПСО сохранилось для нового договора
                                    this.pageSharedData.Form.$setPristine();
                                    this.Contract.$saveInspectionConclusion().then(() => {
                                        this.pageSharedData.Form.$setPristine();
                                    });
                                }
                                resolve();
                            }

                            this.showCommonErrorsNotifications(response.Errors.CommonErrors);
                        } else if (result.status !== 500) {
                            this.notifyService.errorMessage("Ошибка", result.message);
                            reject();
                        }
                    })
                    .on("changeWorkStage", (workStage: number) => {
                        const message = OSAGO_WORK_STAGES.get(workStage) || defaultBlockMessage;
                        this.notifyService.progressMessage(message);
                    })
                    .on("alreadyInProgress", (taskId?: number) => {
                        alreadyInProgressToastInstance = this.contractAlreadyInProgess(taskId);
                    });
            });

            asyncSave().then(() => {
                this.refreshReliableDriveContract().then(angular.noop, () => {
                    const $timeout = this.di<angular.ITimeoutService>("$timeout");
                    $timeout(() => {
                        this.Contract.InsurancePremium = null;
                    });
                });
            }).catch(angular.noop);
        }
    }

    /**
     * Асинхронная продажа договора
     * @deprecated Не нашел использования данного метода в проекте
     */
    public signAsyncContract() {
        this.notifyService.removeToasts();
        this.rsaMessagesService.reset();
        const asyncContractSaleService = this.di<AsyncContractSaleService>("asyncContractSaleService");
        const titleText = "Подтверждение продажи";
        const bodyText = "<p>Перевести договор в статус &laquo;Продан&raquo;?" +
            "<br>Проданные договоры нельзя редактировать.</p>" +
            '<p class="text-accent">Оплатить договор необходимо не&nbsp;позднее даты, указанной на&nbsp;вкладке ' +
            "&laquo;Оплата&raquo;, оплата после указанной даты невозможна.</p>";
        const getNotifyMessages = (errors) => {
            // tslint:disable-next-line: max-line-length
            const defaultMessage = "<h4>Уважаемый пользователь<h4/>На форме имеются ошибки, устраните их и повторите попытку";
            const validationKeys = Array.isArray(errors) ? errors.map((item) => item.Key) : [];
            const formKeys = this.validationService.getValidationKeys();
            const messages = validationKeys.filter((key) => !formKeys.includes(key)).map((key) => this.validationService.getMessages(key));
            return messages.length === 0 ? defaultMessage : messages.join("<br/>");
        };
        return this.helpers.confirm({ text: bodyText, title: titleText }).then(() => {
            this.validationService.clear();
            if (this.Contract.isEOSAGO()) {
                return this.signContract();
            }
            this.Contract.asyncProcessing = true;

            this.notifyService.progressStart("Идет продажа договора. Подождите, пожалуйста...");
            asyncContractSaleService.signOsago(this.Contract).on("onResult", (result) => {
                this.Contract.asyncProcessing = false;
                this.notifyService.progressStop();
                if (result.status === 200) {
                    const response: OsagoAsyncResponseDTO = result.response;
                    // tslint:disable-next-line: max-line-length
                    if (
                        response.Header &&
                        (response.Header.ProcessingResultCode === ProcessingResultCodeEnum.RequestError ||
                            response.Header.ProcessingResultCode === ProcessingResultCodeEnum.InternalError)
                    ) {
                        if (response.Errors.ValidationErrors && Object.keys(response.Errors.ValidationErrors).length > 0) {
                            this.Contract.InsurancePremium = null;
                            // tslint:disable-next-line: max-line-length
                            Object.keys(response.Errors.ValidationErrors).forEach((errorKey) =>
                                this.validationService.addError(errorKey, response.Errors.ValidationErrors[errorKey])
                            );
                            this.notifyService.errorMessage("Скорректируйте введенные данные", getNotifyMessages(response.Errors.ValidationErrors));
                        }
                        // tslint:disable-next-line: max-line-length
                    } else if (response.Header && response.Header.ProcessingResultCode === ProcessingResultCodeEnum.Ok) {
                        this.notifyService.successMessage("", "Договор успешно продан");
                        this.Contract.setStatus(2);
                        this.focusToPayments();
                    }

                    this.showCommonErrorsNotifications(response.Errors.CommonErrors);
                } else if (result.status !== 500) {
                    this.notifyService.errorMessage("Ошибка", result.message);
                }
            });
        });
    }

    public signAsyncContractTroughtDraft() {
        this.notifyService.removeToasts();
        this.rsaMessagesService.reset();
        let alreadyInProgressToastInstance: IToastInstance = null;
        const defaultBlockMessage = "Идет продажа договора. Подождите, пожалуйста...";
        const asyncContractSaleService = this.di<AsyncContractSaleService>("asyncContractSaleService");
        const titleText = "Подтверждение продажи";
        const bodyText = "<p>Перевести договор в статус &laquo;Продан&raquo;?" +
            "<br>Проданные договоры нельзя редактировать.</p>" +
            '<p class="text-accent">Оплатить договор необходимо не&nbsp;позднее даты, указанной на&nbsp;вкладке ' +
            "&laquo;Оплата&raquo;, оплата после указанной даты невозможна.</p>";

        const getNotifyMessages = (errors: { [key: string]: string[] }) => {
            // tslint:disable-next-line: max-line-length
            const defaultMessage = "<h4>Уважаемый пользователь<h4/>На форме имеются ошибки, устраните их и повторите попытку";
            const validationKeys = Object.keys(errors);
            const formKeys = this.validationService.getValidationKeys();
            const messages = validationKeys.filter((key) => !formKeys.includes(key)).map((key) => this.validationService.getMessages(key));
            return messages.length === 0 ? defaultMessage : messages.join("<br/>");
        };
        return this.helpers.confirm({ text: bodyText, title: titleText }).then(() => {

            this.preSignCategoryCheck().then(() => {
                const asyncSign = () => new Promise((resolve, reject) => {
                    this.validationService.clear();
                    this.Contract.asyncProcessing = true;

                    this.notifyService.progressStart(defaultBlockMessage);
                    asyncContractSaleService
                        .signOsagoThroughtDraft(this.Contract)
                        .on("onResult", (result) => {
                            if (alreadyInProgressToastInstance) {
                                this.notifyService.removeToasts(alreadyInProgressToastInstance);
                            }
                            this.Contract.asyncProcessing = false;
                            this.notifyService.progressStop();
                            if (result.status === 200) {
                                const response: OsagoAsyncResponseDTO = result.response;

                                if (
                                    response.Header &&
                                    (response.Header.ProcessingResultCode === ProcessingResultCodeEnum.RequestError ||
                                        response.Header.ProcessingResultCode === ProcessingResultCodeEnum.InternalError)
                                ) {
                                    if (response.Errors.ValidationErrors && Object.keys(response.Errors.ValidationErrors).length > 0) {
                                        this.Contract.InsurancePremium = null;
                                        // tslint:disable-next-line: max-line-length
                                        Object.keys(response.Errors.ValidationErrors).forEach((errorKey) =>
                                            this.validationService.addError(errorKey, response.Errors.ValidationErrors[errorKey])
                                        );
                                        this.notifyService.errorMessage("Скорректируйте введенные данные", getNotifyMessages(response.Errors.ValidationErrors));
                                        reject();
                                    }
                                } else if (response.Header && response.Header.ProcessingResultCode === ProcessingResultCodeEnum.Ok) {
                                    const reloadContract = () => {
                                        const { Id } = this.Contract;
                                        this.Contract.Id = null;
                                        this.notifyService.progressStart("Обновление данных");
                                        return this.Contract.$load(Id).finally(() => {
                                            this.notifyService.progressStop();
                                        });
                                    };
                                    if (this.Contract.IsEgarant) {
                                        reloadContract().finally(() => {
                                            resolve();
                                            this.pageSharedData.Form.$setPristine();
                                        });
                                    } else if (this.Contract.isEOSAGO() || this.Contract.PolicySerial === OSAGO_DRAFT_SERIAL) {
                                        reloadContract()
                                            .then(() => {
                                                this.notifyService.successMessage("", "Договор успешно продан");
                                                // FIX проблемы (UPF-1493), которая возникает после загрузки договора
                                                // (вероятно с целью получения актуального номера)
                                                this.pageSharedData.Form.$setPristine();
                                            })
                                            .finally(() => {
                                                resolve();
                                            });
                                    } else {
                                        this.notifyService.successMessage("", "Договор успешно продан");
                                        reloadContract().then(() => {
                                            this.pageSharedData.Form.$setPristine();
                                        }).finally(() => {
                                            this.focusToPayments();
                                            resolve();
                                        });
                                    }
                                }

                                this.showCommonErrorsNotifications(response.Errors.CommonErrors);
                            } else if (result.status !== 500) {
                                this.notifyService.errorMessage("Ошибка", result.message);
                                reject();
                            }
                        })
                        .on("changeWorkStage", (workStage: number) => {
                            const message = OSAGO_WORK_STAGES.get(workStage) || defaultBlockMessage;
                            this.notifyService.progressMessage(message);
                        })
                        .on("alreadyInProgress", (taskId?: number) => {
                            alreadyInProgressToastInstance = this.contractAlreadyInProgess(taskId);
                        });
                });

                asyncSign().then(() => {
                    this.refreshReliableDriveContract().then(angular.noop, () => {
                        const $timeout = this.di<angular.ITimeoutService>("$timeout");
                        $timeout(() => {
                            this.Contract.InsurancePremium = null;
                        });
                    });
                }).catch(angular.noop);
            }).catch(angular.noop);

        });
    }

    public signAsyncContractTroughtFormed(isOfflineSign = false) {
        this.notifyService.removeToasts();
        let alreadyInProgressToastInstance: IToastInstance = null;
        const defaultBlockMessage = "Идет продажа договора. Подождите, пожалуйста...";
        const asyncContractSaleService = this.di<AsyncContractSaleService>("asyncContractSaleService");
        const titleText = "Подтверждение продажи";
        const bodyText = "<p>Перевести договор в статус &laquo;Продан&raquo;?" +
            "<br>Проданные договоры нельзя редактировать.</p>" +
            '<p class="text-accent">Оплатить договор необходимо не&nbsp;позднее даты, указанной на&nbsp;вкладке ' +
            "&laquo;Оплата&raquo;, оплата после указанной даты невозможна.</p>";

        const getNotifyMessages = (errors: { [key: string]: string[] }) => {
            // tslint:disable-next-line: max-line-length
            const defaultMessage = "<h4>Уважаемый пользователь<h4/>На форме имеются ошибки, устраните их и повторите попытку";
            const validationKeys = Object.keys(errors);
            const formKeys = this.validationService.getValidationKeys();
            const messages = validationKeys.filter((key) => !formKeys.includes(key)).map((key) => this.validationService.getMessages(key));
            return messages.length === 0 ? defaultMessage : messages.join("<br/>");
        };
        return this.helpers.confirm({ text: bodyText, title: titleText }).then(() => {

            this.preSignCategoryCheck().then(() => {
                const asyncSign = () => new Promise((resolve, reject) => {
                    this.validationService.clear();
                    this.Contract.asyncProcessing = true;

                    this.notifyService.progressStart(defaultBlockMessage);

                    const osagoSignResource = isOfflineSign
                        ? asyncContractSaleService.signOsagoOffline(this.Contract)
                        : asyncContractSaleService.signOsagoThroughtFormed(this.Contract);

                    osagoSignResource
                        .on("onResult", (result) => {
                            if (alreadyInProgressToastInstance) {
                                this.notifyService.removeToasts(alreadyInProgressToastInstance);
                            }
                            this.Contract.asyncProcessing = false;
                            this.notifyService.progressStop();
                            if (result.status === 200) {
                                const response: OsagoAsyncResponseDTO = result.response;

                                if (
                                    response.Header &&
                                    (response.Header.ProcessingResultCode === ProcessingResultCodeEnum.RequestError ||
                                        response.Header.ProcessingResultCode === ProcessingResultCodeEnum.InternalError)
                                ) {
                                    if (response.Errors.ValidationErrors && Object.keys(response.Errors.ValidationErrors).length > 0) {
                                        this.Contract.InsurancePremium = null;
                                        // tslint:disable-next-line: max-line-length
                                        Object.keys(response.Errors.ValidationErrors).forEach((errorKey) =>
                                            this.validationService.addError(errorKey, response.Errors.ValidationErrors[errorKey])
                                        );
                                        this.notifyService.errorMessage("Скорректируйте введенные данные", getNotifyMessages(response.Errors.ValidationErrors));
                                        reject();
                                    }
                                } else if (response.Header && response.Header.ProcessingResultCode === ProcessingResultCodeEnum.Ok) {
                                    this.notifyService.successMessage("", "Договор успешно продан");
                                    const { Id } = this.Contract;
                                    this.Contract.Id = null;
                                    this.notifyService.progressStart("Обновление данных");
                                    this.Contract.$load(Id).then(() => {
                                        this.pageSharedData.Form.$setPristine();
                                    }).finally(() => {
                                        this.notifyService.progressStop();
                                        this.focusToPayments();
                                        resolve();
                                    });
                                }

                                this.showCommonErrorsNotifications(response.Errors.CommonErrors);
                            } else if (result.status !== 500) {
                                this.notifyService.errorMessage("Ошибка", result.message);
                                reject();
                            }
                        })
                        .on("changeWorkStage", (workStage: number) => {
                            const message = OSAGO_WORK_STAGES.get(workStage) || defaultBlockMessage;
                            this.notifyService.progressMessage(message);
                        })
                        .on("alreadyInProgress", (taskId?: number) => {
                            alreadyInProgressToastInstance = this.contractAlreadyInProgess(taskId);
                        });
                });

                asyncSign().then(() => {
                    this.refreshReliableDriveContract().then(angular.noop, () => {
                        const $timeout = this.di<angular.ITimeoutService>("$timeout");
                        $timeout(() => {
                            this.Contract.InsurancePremium = null;
                        });
                    });
                }).catch(angular.noop);
            }).catch(angular.noop);
        });
    }

    public forceCalcReliableDrive(premium): Promise<void> {
        return new Promise((resolve, reject) => {
            const hasReliableDriveFeature = this.reliableDriveContract.isActive() || this.reliableDriveContract.isUserChoice();
            const isReliableDriveSelected = this.relatedProductService.getSelectedProduct()
                === RELIABLE_DRIVE_PRODUCT_NAME;
            if (hasReliableDriveFeature || isReliableDriveSelected) {
                if (!this.hideReliableRide) {
                    this.notifyService.progressStart('Расчет договора "Надежная поездка"');
                }
                return this.reliableDriveContract.$forceCalc(premium).then(() => {
                    const rawErrors = this.reliableDriveContract.getErrors();
                    const hasCriticalErrors = rawErrors.some((rawError) => rawError.IsCritical);

                    if (hasCriticalErrors) {
                        this.notifyService.progressStop();
                        return reject(null);
                    } else {
                        if (!this.hideReliableRide) {
                            this.notifyService.successMessage("Надежная поездка", "Договор успешно сохранен");
                        }
                        return resolve(null);
                    }
                }).finally(() => {
                    this.notifyService.progressStop();
                }).catch(() => {
                    if (!this.hideReliableRide) {
                        this.notifyService.errorMessage('Ошибка расчета договора "Надежная поездка"');
                    }
                    this.notifyService.progressStop();
                    reject();
                });
            }

            return resolve(null);
        });
    }

    public refreshReliableDriveContract(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.notifyService.progressStart("Получение данных связанного договора");
            this.reliableDriveContract.$loadByOsagoGuid(this.Contract.Guid)
                .then(() => {
                    const isActive = this.reliableDriveContract.isActive() || this.reliableDriveContract.isUserChoice();
                    if (isActive) {
                        this.relatedProductService.selectProduct(RELIABLE_DRIVE_PRODUCT_NAME);
                    } else {
                        this.relatedProductService.selectProduct(null);
                    }
                    resolve();
                })
                .finally(() => {
                    const $timeout = this.di<ITimeoutService>("$timeout");
                    $timeout(() => {
                        this.notifyService.progressStop();
                    });
                })
                .catch(() => {
                    this.notifyService.errorMessage("Ошибка получения данных связанного договора");
                    reject();
                });
        });
    }

    /**
     * override
     */
    public showNoticeNeedPrintReceiptForm() {
        if (this.viewType !== "form") {
            return;
        }
        const getFreeFromReceiptAvailable = this.employee.hasPermission("GetFreeFormReceipt", this.product.code);
        const validChannel = [SALE_CHANNELS.AGENT, SALE_CHANNELS.OFFICE].includes(this.employee.info.SaleChannel);
        const insurantANaturalPerson = this.Contract.isInsurantANaturalPerson();
        const validPaymentKind = this.Contract.getFirstFactPaymentKindId() === PAYMENT_KINDS.CASH; // вид оплаты = только наличные
        if (getFreeFromReceiptAvailable && validChannel && insurantANaturalPerson && validPaymentKind) {
            this.notifyService.message(undefined, "Не забудьте распечатать Печатную форму Квитанции", {
                provider: "popup",
                type: "warning",
            });
        }
    }

    public isExtraSignOptionsAvailable(): boolean {
        return true;
    }

    public isEgarantSign(): boolean {
        if (this.viewType === "form") {
            return this.Contract.IsEgarant;
        }

        return false;
    }

    public showCommonErrorsNotifications(allErrors: OsagoAsyncResponseCommonError[]) {
        if (Array.isArray(allErrors) && allErrors.length > 0) {
            const errors = allErrors.filter((err) => err.ExternalSystem !== "РСА");
            this.rsaMessagesService.setMessages(allErrors.filter((err) => err.ExternalSystem === "РСА"));

            const filterByErrorType = (errorType: CommonErrorsErrorTypeEnum) => errors.filter((err) => err.ErrorType === errorType);

            const warningMessages = filterByErrorType(CommonErrorsErrorTypeEnum.Warning);
            const infoMessages = filterByErrorType(CommonErrorsErrorTypeEnum.Info);
            const errorMessages = [...filterByErrorType(CommonErrorsErrorTypeEnum.Error), ...filterByErrorType(CommonErrorsErrorTypeEnum.Critical)];

            if (warningMessages.length > 0) {
                this.notifyService.warningMessage("Предупреждение", warningMessages.map((warn) => warn.Message || "").join("<br/>"));
            }

            if (infoMessages.length > 0) {
                this.notifyService.infoMessage("Информация", infoMessages.map((info) => info.Message || "").join("<br/>"));
            }

            if (errorMessages.length > 0) {
                this.notifyService.errorMessage("Ошибка", errorMessages.map((error) => error.Message || "").join("<br/>"));
            }
        }
    }

    public isReliableDriveAvailable(): boolean {
        const isReliableDriveSelected = this.relatedProductService.getSelectedProduct()
            === RELIABLE_DRIVE_PRODUCT_NAME;
        const hasReliableDriveContract = this.reliableDriveContract.isActive() || this.reliableDriveContract.isUserChoice();

        return isReliableDriveSelected || hasReliableDriveContract;
    }

    private preSignCategoryCheck(): Promise<void> {
        const hasPermission = this.employee.hasPermission("CanSignWithoutCategory", this.product.code);
        const notificationTitle = "Категория договора";

        return new Promise((resolve, reject) => {
            this.notifyService.progressStart("Получение категории договора");

            this.Contract.loadContractCategory().then(() => {
                this.notifyService.progressStop();
                if (this.Contract.ContractCategory) {
                    resolve();
                    return;
                }

                if (!this.Contract.ContractCategory && !hasPermission) {
                    this.notifyService.errorMessage(
                        notificationTitle,
                        "Продажа договора без категории невозможна. Попробуйте рассчитать повторно через несколько минут. В случае, если ошибка повторяется - обратитесь к куратору.",
                    );
                    reject("Договор бег категории");
                    return;
                }

                if (!this.Contract.ContractCategory && hasPermission) {
                    this.notifyService.infoMessage(
                        notificationTitle,
                        "Договор без категории. Требуется подтвердить продажу.",
                        { timeout: 10000, type: "warning" },
                    );
                    this.helpers.confirm({
                        // tslint:disable-next-line:max-line-length
                        text: 'Договор без категории. Требуется подтвердить продажу. При подтверждении продажи договору будет присвоена категория "Т" если цель использования "Такси", в остальных случаях договору будет присвоена категория "С"',
                        title: notificationTitle,
                    }).then((r) => {
                        resolve();
                    }, (r) => {
                        reject("Отказ пользователя от подтверждения продажи договора без категории");
                    });
                }
            }).catch(() => {
                reject("Ошибка при попытке получения категории договора");
            });
        });

    }

    private getDriverPersonalData(driver: OsagoDriver) {
        return {
            birthday: driver.birthday,
            firstName: driver.firstName ? driver.firstName.toLowerCase() : driver.firstName,
            lastName: driver.lastName ? driver.lastName.toLowerCase() : driver.lastName,
            middleName: driver.middleName ? driver.middleName.toLowerCase() : driver.middleName,
        };
    }

    private getDriverLicenseData(driver: OsagoDriver) {
        return {
            drivingExperience: driver.drivingExperience,
            licenseIssueDate: driver.licenseIssueDate,
            licenseNumber: driver.licenseNumber,
            licenseSerial: driver.licenseSerial,
        };
    }

    private contractAlreadyInProgess(taskId?: number): IToastInstance {
        if (taskId === 0) {
            const toasterInstance = (this.notifyService.warningMessage(
                "Внимание!",
                "Предыдущая задача расчета в работе. Попробуйте позже."
            ) as unknown) as IToastInstance;
            this.notifyService.progressMessage("Подождите, пожалуйста...");
            const $timeout = this.di<angular.ITimeoutService>("$timeout");
            $timeout(() => {
                this.notifyService.progressStop();
                this.notifyService.removeToasts(toasterInstance);
                this.Contract.asyncProcessing = false;
            }, 15000);
            return;
        }
        return (this.notifyService.warningMessage("Внимание!", "Договор в процессе расчета. Дождитесь окончания операции.") as unknown) as IToastInstance;
    }
}
