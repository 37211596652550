import moment from "moment";
import { NgComponentController } from "infrastructure/NgController";
import template from "./ugskVehiclePicker.component.html";
import vehiclesServiceModule from "./ugskVehiclePicker.service";
import "./ugskVehiclePicker.css";

/**
 * ugskVehiclePicker - компонента выбора ТС
 * @todo см. http://jr.ugsk.ru/jr/browse/UPF-1073
 * @todo выпилить биндинг про трансдекру - он нигде не используется
 *  - функционал ОСАГО в других компонентах
 */

export default angular.module("ugsk.components.vehicle-picker", [
    vehiclesServiceModule,
]).component("ugskVehiclePicker", {
    bindings: {
        maxYear: "<",
        minYear: "<",
        model: "=",
        modelSpelt: "=ngModel",
        ngDisabled: "<",
        onModelChanged: "&",
        onYearChanged: "&",
        onYearOutOfSet: "&?",
        previousModelSpelt: "<",
        transdekra: "<",
        vehicleType: "=",
        year: "=",
    },
    controller: class ugskVehiclePickerController extends NgComponentController {
        onInit() {
            [
                this.$q,
                this.vehicleService,
            ] = this.di([
                "$q",
                "vehicleService",
            ]);

            this.typeAheadModelOptions = {
                debounce: 250,
            };
            if (angular.isUndefined(this.maxYear)) {
                this.maxYear = moment().year();
            }

            this.modelSpeltWatcher = null;

            if (this.model && this.modelSpelt) {
                this.initModelSpeltWatcher();
            }
        }

        getModels(text) {
            return this.vehicleService.get({
                query: text,
            }).$promise;
        }

        onSelect($item) {
            this.vehicleType = $item.VehicleType;
            this.model = $item.ModelId || $item.Id;
            this.onModelChanged();

            this.initModelSpeltWatcher();
        }

        getSelectionStateClasses() {
            if (this.modelSpelt && this.model) {
                return ["fa", "fa-check"];
            }
            return ["fa", "fa-times"];
        }

        initModelSpeltWatcher() {
            if (typeof this.modelSpeltWatcher === "function") {
                this.modelSpeltWatcher();
            }

            this.modelSpeltWatcher = this.$scope.$watch(() => this.modelSpelt, (newVal, oldVal) => {
                if (String(newVal).trim() !== String(oldVal).trim()) {
                    this.model = 0;
                    this.modelSpeltWatcher();
                }
            });
        }

        onYearOutOfSetHandler(value) {
            if (typeof this.onYearOutOfSet === "function") {
                this.onYearOutOfSet({ value });
            }
        }

        onDestroy() {
            if (typeof this.modelSpeltWatcher === "function") {
                this.modelSpeltWatcher();
            }
        }
    },
    controllerAs: "vm",
    template,
    transclude: true,
}).name;
