import { NotifyService } from "infrastructure/services/notifyService";
/**
 * Директива для выбора Excel-файла.
 * Выбранный файл передается в callback-функцию
 */

interface IExcelFileSelectDirectiveScope extends ng.IScope {
    onFileSelected: ({ $file: File }) => void;
}

export function ExcelFileSelectDirective(): ng.IDirective {
    return {
        link: (
            scope: IExcelFileSelectDirectiveScope,
            element: JQuery<HTMLInputElement>,
        ) => {
            element.attr("accept", ".xlsx, .xls");
            element.attr("type", "file");
            element.bind({
                change: (e: JQuery.ChangeEvent<
                    HTMLInputElement,
                    undefined,
                    HTMLInputElement,
                    HTMLInputElement
                >) => {
                    const file = e.target.files[0];
                    if (file) {
                        scope.onFileSelected({ $file: file });
                    }
                },
            });
        },
        restrict: "A",
        scope: {
            onFileSelected: "&",
        },
    };
}
