import * as angular from "angular";
import errorHandlerModule from "infrastructure/error-handler.ts";
import logToServerModule from "infrastructure/logToServer.ts";
import envServiceModule from "infrastructure/services/env.service.ts";

export default angular.module("bootstrapper", [envServiceModule, logToServerModule, errorHandlerModule])
.config(["errorHandlerProvider", (errorHandlerProvider) => {

    errorHandlerProvider.setLogger({
        error: console.error,
    });
}])
.run(["$http", "envService", "$logToServer", "errorHandler", ($http, envService, $logToServer, errorHandler) => {
    $http({
        method: "GET",
        url: "configuration.json",
    }).then((response) => {
        envService.setConfiguration(response.data);
        $logToServer.initialize(envService.read("clientSideLoggerUrl"));
        angular.bootstrap(document, ["app"]);
    }).catch((err) => {
        errorHandler.emitError("Критическая ошибка при запуске приложения", "", 0, 0, err);
    });
}]);
