import { IPromise } from "angular";
import { IODataResourceResult } from "infrastructure/interfaces";
import { IStatusItem } from "infrastructure/interfaces/IStatusItem";

//  TODO: Переписать сервис - плохо написан
// get<...> - не всегда имеют закешированное значение
export class StatusService {
    private statusesCache: IStatusItem[] = [];
    private statusesCachePromise: IPromise<{
        value?: IStatusItem[],
    }>;

    constructor(statusResource: ng.resource.IResourceClass<IODataResourceResult<IStatusItem>>) {
        this.statusesCachePromise = statusResource.get().$promise;
    }

    static get $inject() {
        return ["statusResource"];
    }

    public get(productCode: string): IPromise<IStatusItem[]> {
        return this.statusesCachePromise.then((data: {
            count: number;
            value: IStatusItem[]
        }) => {
            this.statusesCache = data.value;
            if (!productCode || productCode === "osago") {
                return data.value;
            }
            return data.value.filter((statusObject: IStatusItem) => statusObject.ScopeCode === "Common");
        });
    }
    public getStatusTitleById(statusId: number): string {
        const status = this.getStatusById(statusId);
        return (!status) ? "" : status.Name;
    }
    public getStatusByCode(code: string): IStatusItem {
        return this.statusesCache.find((item: IStatusItem) => item.Code === code);
    }
    public getStatusById(id: number): IStatusItem {
        return this.statusesCache.find((item: IStatusItem) => item.Id === id);
    }

    public getStatusLabelClass(statusId: number) {
        if ([2, 7, 8, 9, 10].includes(statusId)) { // 7, 8, 9, 10 - финальные статусы договора ОСАГО.
            return "label-primary";
        }
        if (statusId === 6) {
            return "label-frozen";
        }
        if (statusId > 2) {
            return "label-warning";
        }
        return "";
    }
}
