import angular, { IHttpHeadersGetter } from "angular";
import { EnvService } from "infrastructure/services/env.service";
import { ProductsService } from "infrastructure/services/products.service";
import "./dashboard.module";
import { ILastUserContractDTO } from "./interfaces";

angular
    .module("app.dashboard")
    .service("dashboardResource", dashboardResource);

function dashboardResource(
    $resource: ng.resource.IResourceService,
    envService: EnvService,
    productsService: ProductsService,
) {
    "ngInject";

    const apiUrl = envService.read("apiUrl");
    const oDataUrl = `${apiUrl}api/LastUserContracts`;

    /**
     * Суперкостыльная функция получения настоящего кода продукта по
     * дискриминатору продукта.
     * Для её удаления необходима доработка на Backend
     * http://jr.ugsk.ru/jr/browse/SL-1070
     */
    const boxAccidentSuperHardcodeCodeResolver = (discriminator: string): string | undefined => {
        const product = productsService.getByProductName(discriminator);
        if (product) {
            return product.code;
        }
    };

    const actions = {
        query: {
            method: "GET",
            headers: { "X-Requested-With": "XMLHttpRequest" },
            /**
             * Исправляем "особенности работы" с кодами продуктов
             * 1) Приводим коды в camelCase
             * 2) Нормализуем коды продуктов в НС-BOX
             */
            //  @TODO: Выпилить трансформ, перенести в контроллер
            transformResponse: (data: any, headersGetter: IHttpHeadersGetter, status: number) => {
                if (status !== 200) {
                    return angular.fromJson(data);
                }
                data = angular.fromJson(data);
                const contracts: ILastUserContractDTO[] = data.value;
                const count = data["@odata.count"];
                return {
                    "@odata.count": count,
                    value: contracts.map((contract) => {
                        let code = contract.ProductCode;
                        code = code[0].toLowerCase() + code.slice(1);
                        //  TODO: Выпилить, когда у продуктов НС-BOX появятся правильные коды
                        if (code === "boxAccident") {
                            code = boxAccidentSuperHardcodeCodeResolver(contract.Discriminator);
                        }
                        contract.ProductCode = code;
                        return contract;
                    }),
                };
            },
        },
    };

    return $resource(oDataUrl, {}, actions);
}
