import moment from "moment";
import $ from "jquery";
import "expose-loader?jQuery!jquery";
import "bootstrap-datepicker";
import "node_modules/bootstrap-datepicker/dist/locales/bootstrap-datepicker.ru.min.js";

function ugskDatepickerOptions() {
    return {
        outFormat: "YYYY-MM-DDT00:00:00",
        format: "dd.mm.yyyy",
        language: "ru",
        autoclose: true,
        todayHighlight: true
    };
}

export function DatepickerDirective($timeout, $parse, $filter) {
    "ngInject";

    return {
        require: "ngModel",
        restrict: "AE",
        scope: {
            model: "=ngModel",
            opts: "=options",
            minDate: "&",
            maxDate: "&"
        },
        link: function($scope, $element, $attrs, $ctrl) {
            $element = $($element);
            var options = ugskDatepickerOptions();
            try {
                if ($attrs.options) {
                    var opts = $scope.opts;
                    angular.extend(options, opts);

                    if (options.startDate) {
                        options.startDate = moment(options.startDate, options.outFormat).format($filter("uppercase")(options.format));
                    }

                    if (options.endDate) {
                        options.endDate = moment(options.endDate, options.outFormat).format($filter("uppercase")(options.format));
                    }
                }
            } catch (e) { }

            $ctrl.$parsers.push(function(val) {
                if (!val)
                    return null;

                var date = moment(val, $filter("uppercase")(options.format), true);
                if (date.isValid()) {
                    var current = moment($element.datepicker("getDate")).format($filter("uppercase")(options.format));
                    if (val !== current) {
                        $element.datepicker("setDate", date.format($filter("uppercase")(options.format)));
                    }

                    return date.format(options.outFormat);
                }

                return $scope.model;
            });

            $ctrl.$formatters.push(function(val) {
                if (!val)
                    return val;

                return moment(val, options.outFormat).format($filter("uppercase")(options.format));
            });

            function setDate() {
                var date = $element.datepicker("getDate"),
                    val = moment(date).format($filter("uppercase")(options.format));

                $ctrl.$setViewValue(val);
            }

            function init() {
                /*
                 * Приводим значение к внутреннему формату
                 */
                if ($scope.model) {
                    var model = moment($scope.model, options.outFormat).format($filter("uppercase")(options.format));
                    $element.val(model);
                }
                $element.datepicker(options)
                    .on("changeDate", setDate);

                $scope.$watch("model", function(newVal, oldVal) {
                    if (angular.equals(newVal, oldVal))
                        return;
                    if (!newVal && !oldVal)
                        return;
                    if (newVal) {
                        var oldDate = moment($element.datepicker("getDate")).format(options.outFormat);
                        if (newVal !== oldDate) {
                            $element.datepicker("setDate",
                                moment(newVal, options.outFormat).format($filter("uppercase")(options.format)));
                        }

                        return;
                    }
                    $element.datepicker("setDate", null);
                });

                $scope.$watch(function() {
                    return $scope.minDate();
                }, function(newVal) {
                    var startDate = $element.datepicker("getStartDate");
                    if (startDate) {
                        if (newVal === moment(startDate).format(options.outFormat)) {
                            return;
                        }
                    }

                    if (newVal) {
                        $timeout(function() {
                            $element.datepicker("setStartDate", moment(newVal, options.outFormat).format($filter("uppercase")(options.format)));
                        });
                    } else {
                        $timeout(function() {
                            if (options.startDate) {
                                $element.datepicker("setStartDate",
                                    moment(options.startDate, $filter("uppercase")(options.format))
                                    .format($filter("uppercase")(options.format)));
                            } else {
                                $element.datepicker("setStartDate", null);
                            }
                        });
                    }
                    return;
                });

                $scope.$watch(function() {
                    return $scope.maxDate();
                }, function(newVal) {
                    var endDate = $element.datepicker("getEndDate");
                    if (endDate) {
                        if (newVal === moment(endDate).format(options.outFormat)) {
                            return;
                        }
                    }
                    if (newVal) {
                        $timeout(function() {
                            $element.datepicker("setEndDate", moment(newVal, options.outFormat).format($filter("uppercase")(options.format)));
                        });
                    } else {
                        $timeout(function() {
                            if (options.endDate) {
                                $element.datepicker("setEndDate",
                                    moment(options.endDate, $filter("uppercase")(options.format))
                                    .format($filter("uppercase")(options.format)));
                            } else {
                                $element.datepicker("setEndDate", null);
                            }
                        });
                    }
                });
            }
            init();

            $scope.$on("$destroy", function() {
                $element.datepicker("destroy");
            });
        }
    };
}
