import { IHttpResponse, IHttpService } from "angular";
import { IModalServiceInstance } from "angular-ui-bootstrap";
import ClipboardJS from "clipboard";
import IBlockUI from "infrastructure/interfaces/IBlockUI";

import { NgControllerBase } from "infrastructure/NgController";
import { EnvService } from "infrastructure/services/env.service";
import { NotifyService } from "infrastructure/services/notifyService";

interface ISendPaymentLinkContent {
    AcquiringLink: string;
    OrderId: string;
}

// Описаные только нужные поля
interface ISendPaymentLinkResponse {
    content: ISendPaymentLinkContent
}

export class PublicPaymentModalController extends NgControllerBase {
    public url: string;
    public orderId: string;

    static get $inject() {
        return ["$injector", "$uibModalInstance", "contractId", "productCode", "currentLink"];
    }

    public constructor(
        $injector, private $uibModalInstance: IModalServiceInstance,
        public contractId: number, private productCode: string,
        currentLink: ISendPaymentLinkContent,
    ) {
        super($injector);

        if (currentLink) {
            this.orderId = currentLink.OrderId;
            this.url = currentLink.AcquiringLink;
        }
    }

    public $onInit() {
        const clipboard = new ClipboardJS("#acquireLinkBtn");
        clipboard.on("success", (e) => {
            const notifyService = this.di<NotifyService>("notifyService");
            notifyService.successMessage("Ссылка скопирована в буфер обмена");
            e.clearSelection();
        });
    }

    public dismiss(): void {
        this.$uibModalInstance.dismiss();
    }

    public requestPayment(): void {
        const blockUI = this.di<IBlockUI>("blockUI");
        const $http = this.di<IHttpService>("$http");
        const envService = this.di<EnvService>("envService");
        blockUI.start("Запрос оплаты");
        $http.post(`${envService.read("apiUrl")}/${this.productCode}/SendPaymentLink/${this.contractId}/SB`, undefined)
            .then((response: IHttpResponse<ISendPaymentLinkResponse>) => {
                this.url = response.data.content.AcquiringLink;
                this.orderId = response.data.content.OrderId;
                blockUI.stop();
            }).catch(() => {
                blockUI.stop();
            });
    }
}
