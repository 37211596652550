import angular from "angular";
import bonusMalusTemplate from "application/modalDialog/showBonusMalus.html";
import interiorTypesTemplate from "application/modalDialog/showInteriorType.html";
import EngineeringEquipment from "domain/classes/engineeringEquipment.class";
import MovableProperty from "domain/classes/movableProperty.class";
import PropertyPart from "domain/classes/propertyPart.class";
import Repository from "infrastructure/Repository.class";
import moment from "moment";

import { Transition } from "@uirouter/angularjs";
import { IModalService } from "angular-ui-bootstrap";
import { BaseIFLBodyController } from "application/baseIFLProduct.body.controller";
import { InsurantPersonProxy } from "domain/proxies/insurantPersonProxy.class";
import { Helpers } from "infrastructure/app.helpers";
import { IDatePickerOptions } from "infrastructure/interfaces/IDatePickerOptions";
import { IOptionsPopover } from "infrastructure/interfaces/IOptionsPopover";
import IScope from "infrastructure/interfaces/IScope";
import IPreviousContractRecord from "infrastructure/interfaces/IPreviousContractRecord";
import IProlongableProductBodyController from "infrastructure/interfaces/IProlongableProductBodyController";
import { ISliderConfig } from "infrastructure/interfaces/ISliderConfig";
import { Logger } from "infrastructure/logToServer";
import { NotifyService } from "infrastructure/services/notifyService";
import { AdditionalConstructionAssuranceHome } from "./AdditionalConstructionAssuranceHome";
import { AssuranceHomeContract, PropertyPartsRepository } from "./assuranceHome.factory";
import { IAssuranceHomeResource } from "./assuranceHome.resource";
import IConstructionType from "./interfaces/IConstructionType";

export class AssuranceHomeController extends BaseIFLBodyController implements IProlongableProductBodyController {
    protected Contract: AssuranceHomeContract;

    private hashList: Map<string, any> = null;
    private MaxAdditionalConstruction: number = 4;
    private isAddingAdditionalConstruction: boolean = false;
    private localPropertyStorage = null;
    private insurant: InsurantPersonProxy = null;
    private tooltipWallMaterialOptionsPopover: IOptionsPopover = {
        popoverEvent: "'mouseenter'",
        /* tslint:disable-next-line:max-line-length */
        popoverMessage: "Обратите внимание: если указан разный материал стен для каждого этажа, то для Движимого имущества и инженерного оборудования применяется тариф горючего материала.",
        popoverPlaceShow: "top",
    };
    private tooltipOptionsPopover: IOptionsPopover = {
        popoverEvent: "'mouseenter'",
        popoverMessage: "В составе группы могут объединяться несколько объектов с разными годами приобретения.",
        popoverPlaceShow: "top-left",

    };
    private constructionYearOptions: IDatePickerOptions = null;
    private engineeringEquipmentYearOptions: IDatePickerOptions = null;
    private additionalContructionYearOptions: IDatePickerOptions = null;
    private propertyOverhaulYearOptions: IDatePickerOptions = null;
    private propertyInteriorRepairYearOptions: IDatePickerOptions = null;
    private сontractToYearOptions: object = {
        language: "ru",
    };
    private maskdefinitions: object = {
        "?": /\d/,
        9: undefined,
    };
    private additionalConstructionCostSliderConfig: ISliderConfig;
    private frPromise: angular.IPromise<any> = null;
    private focusToElement: any = null;
    private $uibModal: IModalService = null;
    private thousandFilter: any = null;
    private anchorToElement: any = null;
    private $filter: angular.IFilterService = null;
    private $log: Logger = null;
    private notifyService: NotifyService = null;
    public get Repository() {
        return this.Contract.getRepository();
    }

    constructor(
        $injector: angular.auto.IInjectorService,
        $transition$: Transition,
        params: any,
        $scope: IScope,
    ) {
        super($injector, $transition$, params, $scope);

        this.hashList = this.resolve<Map<string, any>>("hashList");
        this.insurant = new InsurantPersonProxy(this.Contract);
        this.focusToElement = this.di<any>("focusToElement");
        this.$uibModal = this.di<IModalService>("$uibModal");
        this.thousandFilter = this.di<any>("thousandFilter");
        this.anchorToElement = this.di<any>("anchorToElement");
        this.$filter = this.di<angular.IFilterService>("$filter");
        this.$log = this.di<Logger>("$log");
        // @todo Нафига?
        const [$rootScope] = this.di(["$rootScope"]);
        $rootScope.$anchorScroll.yOffset = 300;
        this.notifyService = this.di<NotifyService>("notifyService");

        /**
         * Справочные данные для контрола "Страховаое возмещение"
         */

        this.Repository.PropertyDeterioration = {
            Collection: [{
                PropertyDeterioration: "С износом",
                PropertyDeteriorationValue: true,
            },
            {
                PropertyDeterioration: "Без износа",
                PropertyDeteriorationValue: false,
            }],
        };

        this.Repository.PropertyFloorCount = {
            Collection: [{
                PropertyFloorCount: "1",
                PropertyFloorCountValue: 1,
            },
            {
                PropertyFloorCount: "2",
                PropertyFloorCountValue: 2,
            },
            {
                PropertyFloorCount: "3",
                PropertyFloorCountValue: 3,
            }],
        };

        /*
         * Справочные данные для контрола Скидка
         */
        this.Repository.DiscountRestrictions = {
            Collection: [{
                DiscountRestrictions: "Без скидки",
                DiscountRestrictionsId: 0,
            }],
        };

        const datePickerBaseYearOptions = this.di<IDatePickerOptions>("datePickerBaseYearOptions");
        this.constructionYearOptions = angular.copy(datePickerBaseYearOptions);
        this.additionalContructionYearOptions = angular.copy(datePickerBaseYearOptions);
        this.propertyOverhaulYearOptions = angular.copy(this.constructionYearOptions);
        this.propertyInteriorRepairYearOptions = angular.copy(this.constructionYearOptions);

        const $q = this.di<angular.IQService>("$q");
        this.frPromise = $q.resolve();

        this.ngModelOptions = this.di<angular.INgModelOptions>("ngModelOptions");
        this.additionalConstructionCostSliderConfig = {
            grid: true,
            hide_from_to: false,
            hide_min_max: true,
            max: 0,
            min: 0,
            prettify: false,
            step: 1000,
            type: "single",
        };
        this.selectConstructionType = this.selectConstructionType.bind(this);
        this. selectPropertyPart = this. selectPropertyPart.bind(this);
        this.init();
    }

    public importPreviousContract(previousContractRecord: IPreviousContractRecord): angular.IPromise<void> {
        const [
            $q,
            assuranceHomeAdditionalConstructionRepositoryService,
            assuranceHomeConstructionPartRepositoryService,
        ] = this.di([
            "$q",
            "assuranceHomeAdditionalConstructionRepositoryService",
            "assuranceHomeConstructionPartRepositoryService",
        ]);
        const customMappingRules = {
            AdditionalConstructions: (value: any, object: any) => {
                object.AdditionalConstructions = value.map((element) => {
                    return angular.extend(new AdditionalConstructionAssuranceHome(element.ConstructionTypeId), element);
                });
            },
        };
        const syncRepositoryCallback = (contractPrototype: any, fieldName: string) => {
            if (fieldName === "PropertyParts") {
                return $q.all(contractPrototype.PropertyParts.map((element: PropertyPart) => {
                    const constructionPartRepository = new Repository(assuranceHomeConstructionPartRepositoryService);
                    /**
                     * Патчинг этажа значениями из контракта.
                     * Будет исправленно в следующей задаче, когда чекбоксы будут в разрезе каждого этажа.
                     */
                    element.NeedToInsureInterior = contractPrototype.NeedToInsureInterior;
                    element.PropertyIsFinished = contractPrototype.PropertyIsFinished;
                    return constructionPartRepository.load(element).then((data: any) => {
                        element.setRepository(constructionPartRepository);
                        return $q.resolve();
                    });
                }));
            }
            if (fieldName === "AdditionalConstructions") {
                return $q.all(contractPrototype.AdditionalConstructions.map((element: any) => {
                    const additionalConstructionRepository = new Repository(assuranceHomeAdditionalConstructionRepositoryService);
                    return additionalConstructionRepository.load(element).then((data: any) => {
                        this.hashList.set(element.Guid, additionalConstructionRepository);
                        return $q.resolve();
                    });
                }));
            }
        };
        return super.importPreviousContract(previousContractRecord, customMappingRules, syncRepositoryCallback);
    }

    public getPropertyPartTitle(part: any): string {
        const item = this.Repository.PropertyPartType.Collection.filter((type: any) => type.PropertyPartTypeId === part.PropertyPartTypeId);
        return (item[0]) ? item[0].PropertyPartType : "";
    }

    public getAdditionalConstructionsTitle(property: IConstructionType): string {
        const item = this.Repository.ConstructionType.Collection.filter((type: IConstructionType) => type.ConstructionTypeId === property.ConstructionTypeId);
        return (item[0]) ? item[0].ConstructionType : "";
    }

    public isPlannedPaymentLocked(index: number): boolean {
        return index === 0;
    }

    public $onInit(): void {
        super.$onInit();
        this.phoneValidationService.setContractRequiredFields({
            legalEntity: [
                { field: "InsuredOrgName", description: "Наименование организации страхователя" },
                { field: "InsuredOrgINN", description: "ИНН организации страхователя" }
            ],
            individual: [
                { field: "InsuredLastName", description: "Фамилия страхователя" },
                { field: "InsuredFirstName", description: "Имя страхователя" },
                { field: "InsuredBirthday", description: "Дата рождения страхователя" }
            ]
        });
    }

    public yearComparator(model: any, master: any): boolean {
        return (String(model) === String(master));
    }

    public formModified(): boolean {
        return this.pageSharedData.Form.modified;
    }

    public getContractFromMinDate(): string {
        return (this.Contract.PreviousContractId || this.Contract.CSIAPreviousContractId) ? moment().subtract(1, "years").format("YYYY-MM-DD") : moment(this.Contract.SigningDate).add(1, "d").format("YYYY-MM-DD");
    }

    public getContractFromMaxDate(): string {
        return (this.Contract.PreviousContractId || this.Contract.CSIAPreviousContractId) ? moment().add(1, "years").add(1, "d").format("YYYY-MM-DD") : moment(this.Contract.SigningDate).add(30, "d").format("YYYY-MM-DD");
    }

    public getInspectionInspectionMinDate(): string {
        return moment(this.Contract.SigningDate, "YYYY-MM-DD").add(-10, "d").format("YYYY-MM-DD");
    }

    public getInspectionInspectionMaxDate(): string {
        return moment(this.Contract.SigningDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    }

    public getContractPaymentMinDate(prevDate: string): string {
        return moment(prevDate).add(1, "d").format("YYYY-MM-DD");
    }

    public bonusMalusUpdateFieldRepository(): void {
        this.blockUI.start("Обновляются справочные данные");
        this.updateFieldRepository(this.Repository.BonusMalusAssuranceHome.FieldName).then(() => {
            if (!this.Repository.PolicyNumberOfAnotherCompany.State) {
                this.Contract.PreviousPolicyNumber = null;
                this.Contract.PreviousContractId = null;
                this.Contract.PreviousContractNumber = null;
                this.Contract.PreviousPolicySerial = null;
                this.Contract.CSIAPreviousContractId = null;
            }
        }).catch(() => {}).finally(() => this.blockUI.stop());
    }

    public resetDeclaredAmountForAdditionalConstruction(fieldName: string, property: AdditionalConstructionAssuranceHome): void {
        if (!property[fieldName] || property[fieldName] === 0) {
            property.DeclaredAmount = null;
        }
    }

    public isEnabledDeclaredAmountForAdditionalConstruction(property: AdditionalConstructionAssuranceHome): boolean {
        return (((property.Length || property.Square) && property.ConstructionYear) && this.Contract.ContractStatusId !== 2 && this.Contract.ContractStatusId !== 5);
    }

    public updateFieldRepositoryAdditionalConstructionsCostRestriction(property: AdditionalConstructionAssuranceHome, fieldName: string): void {
        if ((Number(property.Square) > 0 || Number(property.Length) > 0) || fieldName === "WallMaterial") {
            const assuranceHomeAdditionalConstructionRepositoryService = this.di<IAssuranceHomeResource>(
                "assuranceHomeAdditionalConstructionRepositoryService",
            );
            const repository = new Repository(assuranceHomeAdditionalConstructionRepositoryService);
            this.blockUI.start("Обновляются справочные данные");
            repository.loadAdditionalRepository((property as any), fieldName)
                .then(() => {
                    this.hashList.set(property.Guid, angular.extend(this.hashList.get(property.Guid), repository));
                    if (this.hashList.get(property.Guid).ConstructionYearMin.Value[0].Value > property.ConstructionYear) {
                        property.ConstructionYear = null;
                        property.DeclaredAmount = null;
                    }
                }).finally(() => {
                    this.blockUI.stop();
                });
        }
    }

    public getContractPaymentMaxDate(): string {
        return moment(this.Contract.SigningDate).add(4, "M").format("YYYY-MM-DD");
    }

    /**
     * Меняем максимально допустимый год постройки
     * @returns {}
     */
    public changePropertyWallMaterial(): void {
        const item = this.Repository.PropertyWallMaterial.Collection.filter((material: any) => material.PropertyWallMaterialId === this.Contract.PropertyWallMaterialId);
        if (item[0]) {
            this.constructionYearOptions.startDate = moment().add(-item[0].PropertyWallMaterialMaxConstructionAgeValue, "y").format("YYYY-MM-DD");
        }
    }

    public changeInsuredObjectAddressHasNotFiasInfo(): void {
        this.Contract.InsuredPersonAddressHasNotFiasInfo = this.Contract.InsuredObjectAddressHasNotFiasInfo;
        this.Validation.removeError("PropertyAddressUserDefinedAddress");
    }

    /**
     * Запрос справочников при изменении поля
     * @param {string} fieldName - имя изменившегося поля
     * @returns {}
     */
    public updateFieldRepository(fieldName?: string): angular.IPromise<any> {
        return this.Repository.update(fieldName, this.Contract, false);
    }

    public updateFieldRepositoryWithBlockUi(fieldName: string): angular.IPromise<any> {
        this.blockUI.start("Обновляются справочные данные");
        return this.Repository.update(fieldName, this.Contract, false).then((res) => {
            this.blockUI.stop();
        });
    }

    public removeProperty(elements: any, index: number): void {
        elements.splice(index, 1);
        // TODO: необходимо переработать на $setDirty
        this.pageSharedData.Form.modified = true;
    }

    public clearPropertyList(): void {
        if (!this.Contract.NeedToInsureInterior && !this.Contract.NeedToInsureStructuralElement) {
            this.localPropertyStorage = angular.copy(this.Contract.PropertyParts);
            angular.forEach(
                this.localPropertyStorage,
                (property) => {
                    property.Id = 0;
                }
            );
            this.Contract.PropertyParts = [];
        }
        if (this.Contract.NeedToInsureInterior || this.Contract.NeedToInsureStructuralElement) {
            if (this.localPropertyStorage) {
                this.Contract.PropertyParts = angular.copy(this.localPropertyStorage);
                angular.forEach(
                    this.Contract.PropertyParts,
                    (property) => {
                        property.NeedToInsureInteriorOfPropertyPart = this.Contract.NeedToInsureInterior;
                        property.NeedToInsureInterior = this.Contract.NeedToInsureInterior;
                        property.NeedToInsureStructuralElement = this.Contract.NeedToInsureStructuralElement;
                        this.updatePropertyPartRepository(property, "NeedToInsureInterior");
                    }
                );
                this.localPropertyStorage = null;
            }
        }
    }

    public changeNeedToInsureStructuralElement(id: string): void {
        this.clearPropertyList();
        if (this.Contract.NeedToInsureStructuralElement) {
            this.focusToElement(id);
        } else {
            if (this.isAvailableEngineeringEquipments()) {
                this.Contract.EngineeringEquipments = [];
            }
            this.Contract.StructuralElementDeclaredAmount = null;
        }
    }
    /**
     * Получение коллекции сублиммитов с дефолтными значениями из реппозитория
     * @param {*} collection - this.Repostiory.
     */
    public getSublimitsFromRepository(collection: any): any[] {
        return collection.map((item: any) => {
            if (item.Id && item.DefaultValue) {
                return {
                    SublimitOfConstructionPartId: item.Id,
                    Value: item.DefaultValue,
                };
            }
        }).filter((sl: any) => sl);
    }

    public isAvailableEngineeringEquipments(): boolean {
        return (this.Repository.NeedToInsureEngineeringEquipment.State && !this.Contract.NeedToInsureStructuralElement);
    }

    public changeNeedToInsureInterior(id: string): void {
        this.clearPropertyList();
        if (this.Contract.NeedToInsureInterior) {
            this.focusToElement(id);
        } else {
            this.Contract.InteriorDeclaredAmount = null;
        }
    }

    /**
     * Поиск элементов массива справочных данных, которые уже добавили в список на страхование
     * @param {object} el  - элемент массива, который должен быть проверен на наличие в списке выбранных
     * @returns {bool} - true - если его нет, false - если выбран
     */
    public selectPropertyPart(el: PropertyPart): boolean {
        const item: PropertyPart[] = this.Contract.PropertyParts.filter((part: PropertyPart) => part.PropertyPartTypeId === el.PropertyPartTypeId);
        return !(item && item[0]);
    }

    public shouldDisplayAdditionalConstructionName(el: IConstructionType): boolean {
        const referenceItem: IConstructionType = this.Repository.ConstructionType.Collection.find((type: IConstructionType) => type.ConstructionTypeId === el.ConstructionTypeId);
        return (referenceItem) ? referenceItem.IsOther : false;
    }

    /**
     * Проверка возможности добавления дополнительной постройки
     * @param {} el - элемент массива, который должен быть проверен
     * @returns {bool} - true - если элемент доступен для добавления, false - если нет
     */
    public selectConstructionType(el: IConstructionType): boolean {
        const referenceItem: IConstructionType = this.Repository.ConstructionType.Collection.find((type: IConstructionType) => type.ConstructionTypeId === el.ConstructionTypeId);
        const item = this.Contract.AdditionalConstructions.filter((part: AdditionalConstructionAssuranceHome) => part.ConstructionTypeId === el.ConstructionTypeId);
        return !(item && item[0] && !referenceItem.IsMultipleSelect);
    }

    /**
     *
     * @returns {} Открываем модальное окно для показа информации о б истории страхования
     */
    public showBonusMalusModal(): void {
        const bonusMalusCollection = this.Repository.BonusMalusAssuranceHome.Collection;
        const helpers = this.di<Helpers>("helpers");
        const data = bonusMalusCollection.map((item) => ({
            content: item.BonusMalusAssuranceHomeDescription,
            title: item.BonusMalusAssuranceHomeName,
        }));
        helpers.showInformationWindow(bonusMalusTemplate, "История страхования", "", data);
    }
    /**
     * @todo http://jr.ugsk.ru/jr/browse/UPRO-2018
     * @returns {} Открываем модальное окно для показа информации о типах отделки
     */
    public showInteriorTypeModal(): void {
        const helpers = this.di<any>("helpers"); // TODO Сделать интерфейс для helpers
        helpers.showInformationWindow(interiorTypesTemplate, "Типы отделки", "lg");
    }
    /**
     * Добавляем новое имущество
     * @returns {}
     */
    public addMovableProperty(property: MovableProperty): void {
        const item = new MovableProperty(property.MovablePropertyTypeId);
        this.Contract.MovableProperties.push(item);
        this.anchorToElement(`DeclaredAmount${item.Guid}`);
    }

    /**
     * @returns {} очистка суммы КЭ/ВО
     */
    public clearStuffAmountSum(properties: any, propertyName: string): void {
        angular.forEach(properties, (property) => {
            if (property[propertyName]) {
                property[propertyName] = 0;
            }
        });
    }

    /**
     * Функция проверяет доступность поля для ввода иного материала
     * @returns {bool}
     */
    public otherMaterialIsDisabled(
        contract: any,
        collection: any,
        propertyName: string,
        otherPropertyName: string,
    ): boolean {
        const item = this.$filter<any>("filterBy")(
            collection,
            [`${propertyName}Id`],
            contract[`${propertyName}Id`]
        );
        if (item[0]) {
            const value = !item[0][`${propertyName}IsOther`];
            if (value) {
                contract[otherPropertyName] = null;
            }
            return value;
        }
        contract[otherPropertyName] = null;
        return true;
    }

    /**
     * @returns {} сумма по общим имущественным элементам
     */
    public calculateStuffAmountSum(properties: any, propertyName: string): string {
        let sum = 0;
        if (properties) {
            angular.forEach(properties, (property) => {
                if (property[propertyName]) {
                    sum += parseFloat(property[propertyName]);
                }
            });
        }
        return (sum === 0) ? "" : this.thousandFilter(sum);
    }

    /**
     * Добавляем новое инженерное оборудование
     * @returns {}
     */
    public addEngineeringEquipment(): void {
        const item = new EngineeringEquipment();
        this.Contract.EngineeringEquipments.push(item);
        this.anchorToElement(`Name${item.Guid}`);
    }

    /**
     * Меняем максимально допустимый год ремонта ВО
     * @returns {}
     */
    public onInsuranceTermChanged(): void {
        if (this.Contract.PropertyConstructionYear > this.Contract.PropertyInteriorRepairYear) {
            this.Contract.PropertyInteriorRepairYear = null;
        }
    }

    /**
     * Меняем максимально допустимый год постройки
     * @returns {}
     */
    public changeWallMaterial(): void {
        const item = this.Repository.WallMaterial.Collection.filter((material: any) => material.WallMaterialId === this.Contract.WallMaterialId);

        if (item[0]) {
            this.constructionYearOptions.startDate = `-${item[0].WallMaterialMaxConstructionAgeValue}y`;
        }
    }

    public checkPresenceAdditionalConstruction(): number {
        return this.Contract.AdditionalConstructions.length;
    }

    /**
     * Получение выбранного периода страхования
     * @returns {object} Выбранный период страхования
     */
    public getInsuranceTerm(): any {
        const item = this.Repository.InsuranceTermAssuranceHome.Collection.filter((term: any) => term.InsuranceTermAssuranceHomeId === this.Contract.InsuranceTermAssuranceHomeId);
        return (item[0]) ? item[0] : null;
    }

    /**
     * Функция срабатывающая при событии изменения выбранного периода страхования
     * @returns {} Ничего не возвращает, изменяет дату окончания договора
     */
    public changeInsuranceTerm(): void {
        const item = this.getInsuranceTerm();
        if (item) {
            this.Contract.ContractTo = `${moment(this.Contract.ContractFrom, "YYYY-MM-DD").add(item.InsuranceTermAssuranceHomeValue, "M").add(-1, "d").format("YYYY-MM-DD")}T00:00:00`;
        }
    }

    /*
     * Добавляем новую постройку в список
     * @returns {}
     */
    public addAdditionalConstruction(property: any): void {
        const item = new AdditionalConstructionAssuranceHome(property.ConstructionTypeId);
        this.isAddingAdditionalConstruction = true;
        const assuranceHomeAdditionalConstructionRepositoryService = this.di<IAssuranceHomeResource>(
            "assuranceHomeAdditionalConstructionRepositoryService",
        );
        const repository = new Repository(assuranceHomeAdditionalConstructionRepositoryService);
        repository.load((item))
            .then((data) => {
                item.setFromRepository(data);
                this.hashList.set(item.Guid, repository);
                this.Contract.AdditionalConstructions.push(item);
                this.anchorToElement(`DeclaredAmount${item.Guid}`);
            })
            .catch(() => {
                this.$log.debug("Ошибка загрузки репозитория постройки");
            })
            .finally(() => {
                this.isAddingAdditionalConstruction = false;
            });
    }

    /**
     * Функция добавления элемента основого строения.
     * @param {*} property элемент основого строения
     */
    public addPropertyPart(property: any): void {
        const item = new PropertyPart(property.PropertyPartTypeId);
        const assuranceHomeConstructionPartRepositoryService = this.di<IAssuranceHomeResource>(
            "assuranceHomeConstructionPartRepositoryService",
        );
        const constructionPartRepositoryService = new Repository(assuranceHomeConstructionPartRepositoryService);
        if (this.Contract.PropertyWallMaterialId) {
            item.WallMaterialId = this.Contract.PropertyWallMaterialId;
        }
        item.NeedToInsureInterior = this.Contract.NeedToInsureInterior;
        item.PropertyIsFinished = this.Contract.PropertyIsFinished;
        item.NeedToInsureStructuralElement = this.Contract.NeedToInsureStructuralElement;
        item.ConstructionYear = this.Contract.PropertyConstructionYear;
        item.InteriorRepairYear = this.Contract.PropertyInteriorRepairYear;
        constructionPartRepositoryService.load((item as any))
            .then((data) => {
                item.setFromRepository(data);
                item.setRepository(constructionPartRepositoryService);
                this.Contract.PropertyParts.push(item);
                this.pageSharedData.Form.$setDirty();
                this.anchorToElement(`StructuralElementSquare${item.Guid}`);
            })
            .catch(() => {
                this.$log.debug("Ошибка загрузки репозитория постройки");
            });
    }

    /**
     * Функция обнавления репозитория для элемента основого строения.
     * @param {*} property элемент основого строения для которого нужно обновить репозиторий
     * @param {*} fieldName имя поля по которому происходит обновление репозитория
     * TODO Отрефакторить!!!
     */
    public updatePropertyPartRepository(property: any, fieldName: string): angular.IPromise<any> {
        this.blockUI.start("Обновляются справочные данные");
        this.frPromise = this.frPromise.then(() => {
            const assuranceHomeConstructionPartRepositoryService = this.di<IAssuranceHomeResource>(
                "assuranceHomeConstructionPartRepositoryService",
            );
            const repository = new PropertyPartsRepository(assuranceHomeConstructionPartRepositoryService);
            return repository.loadAdditionalRepository(property, fieldName).then((data) => {
                property.setRepository(angular.extend(property.getRepository(), repository));
                property.setFromRepository(data);
            }).catch(() => {
                this.$log.debug("Ошибка загрузки репозитория постройки");
            });
        }).finally(() => {
            this.blockUI.stop();
        });
        return this.frPromise;
    }
    /**
     *
     * @returns {}
     */
    public getBonusMalusName(): string {
        const item = this.$filter<any>("filterBy")(
            this.Repository.BonusMalusAssuranceHome.Collection,
            ["BonusMalusAssuranceHomeId"],
            this.Contract.BonusMalusAssuranceHomeId,
        );
        return (item[0]) ? item[0].BonusMalusAssuranceHome : "";
    }

    /**
     * Получение серии документа
     * @returns {}
     */
    public getPaymentDocumentSerial(): string {
        const item = this.$filter<any>("filterBy")(
            this.Repository.PaymentDocumentSerial.Collection,
            ["PaymentDocumentSerialId"],
            this.Contract.PaymentDocumentSerialId,
        );
        return (item[0]) ? item[0].PaymentDocumentSerial : "";
    }

    /**
     * Изменение серии документа
     * @returns {}
     */
    public setPaymentDocumentSerial(value: any): void {
        this.Contract.PaymentDocumentSerialId = value;
        this.Contract.InsurancePremium = null;
        this.Contract.Payments = null;
    }

    /**
     * Функция инициализирующая дополнительные watch'и
     * @returns {}
     */
    public initWatchList(): void {
        this._$scope.$watch("vm.Contract.PropertyParts.length", (newVal: any, oldVal: any) => {
            if (!newVal && oldVal !== newVal) {
                this.Contract.MainConstructionSublimits = angular.copy(this.getSublimitsFromRepository(this.Repository.SublimitForMainConstruction.Collection));
            }
        });
        this._$scope.$watch("vm.Contract.PropertyIsFinished", (newVal) => {
            if (newVal === false && !this.Contract.isLocked()) {
                if (!this.Contract.PropertyIsFinished) {
                    if (!this.Contract.NeedToInsureStructuralElement) {
                        this.Contract.PropertyParts = [];
                    }
                    this.Contract.MovableProperties = [];
                    this.Contract.PropertyConstructionYear = Number(moment(this.Contract.SigningDate).format("YYYY"));
                    this.Contract.PropertyInteriorRepairYear = Number(moment(this.Contract.SigningDate).format("YYYY"));
                    this.clearStuffAmountSum(this.Contract.PropertyParts, "InteriorDeclaredAmount");
                    if (this.isAvailableEngineeringEquipments()) {
                        this.Contract.EngineeringEquipments = [];
                    }
                } else {
                    this.Contract.PropertyConstructionYear = null;
                    this.Contract.PropertyInteriorRepairYear = null;
                }
            }
        });
        this._$scope.$watch("vm.Contract.AdditionalConstructions.length", (newVal: any, oldVal: any) => {
            if (!newVal && oldVal !== newVal) {
                this.Contract.AdditionalConstructionSublimits = angular.copy(this.getSublimitsFromRepository(this.Repository.SublimitForAdditionalConstruction.Collection));
            }
        });

        this._$scope.$watch("vm.Contract.PreviousContractId + vm.Contract.CSIAPreviousContractId", (newVal: any, oldVal: any) => {
            if (!newVal && oldVal) {
                this.Contract.ContractFrom = this.getContractFromMinDate();
                this.changeInsuranceTerm();
            }

            if ((!newVal && oldVal) || (newVal && !oldVal)) {
                this.Contract.InsuranceTermAssuranceHomeId = this.Repository.InsuranceTermAssuranceHome.Value[0].Value;
                this.updateFieldRepository();
            }
        }, false);
    }


    /**
     *
     * @param {object} repository - Репозиторий постройки
     * @param {object} property - свойство постройки
     * @returns {bool}
     */
    public elementIsVisible(repository: any, property: any): boolean {
        return repository[property] === null || !repository[property].State;
    }

    /**
     * Функция инициализатор приложения
     * @returns {}
     */
    public init(): void {
        const signingDate = moment(this.Contract.SigningDate, "YYYY-MM-DD").startOf("day");
        const current = moment().startOf("day");
        if (this.Contract.ContractStatusId === 1 && signingDate.isBefore(current)) {
            this.notifyService.warningMessage("Внимание", "По данному договору устарела дата заключения договора. Рассчитайте договор заново для обновления даты заключения договора.");
        }

        this.engineeringEquipmentYearOptions = angular.copy(this.constructionYearOptions);
        this.engineeringEquipmentYearOptions.startDate =
            moment().add(-this.Repository.EngineeringEquipmentAgeRestriction.Collection[0].EngineeringEquipmentAgeRestrictionMaxValue, "y").format("YYYY-MM-DD");

        if (!this.Contract.ContractStatusId) {
            this.Contract.SigningDate = `${moment().format("YYYY-MM-DD")}T00:00:00`;
            this.Contract.ContractFrom = `${moment().add(1, "d").format("YYYY-MM-DD")}T00:00:00`;
            this.changeInsuranceTerm();
        }

        if (!this.Contract.InsuredBirthday) {
            this.Contract.InsuredBirthday = `${moment().add(-18, "y").format("YYYY-MM-DD")}T00:00:00`;
        }

        const currentSpecialOfferId = this.Repository.SpecialOffer.Collection.find(elem => elem.SpecialOfferId === this.Contract.SpecialOfferId);
        if (angular.isUndefined(currentSpecialOfferId) && this.Contract.SpecialOfferId) {
            this.Contract.SpecialOfferId = null;
        }

        const discountParams = this.Repository.AssuranceHomeDiscountRestriction.Collection[0];

        // disctountParams = undefined, если таблица dbo.AssuranceHomeDiscountRestriction
        // не содержит данных для связки филиал - канал продаж
        if (discountParams) {
            let minDiscount = parseInt(discountParams.MinValue, 10);
            const maxDiscount = parseInt(discountParams.MaxValue, 10);

            for (minDiscount; minDiscount <= maxDiscount; minDiscount++) {
                this.Repository.DiscountRestrictions.Collection.push({
                    DiscountRestrictions: `${minDiscount} %`,
                    DiscountRestrictionsId: minDiscount,
                });
            }
        }

        this.initWatchList();
    }
    public removePreviousContract(): void {
        this.Contract.removePreviousContract();
    }

}
