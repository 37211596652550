import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { IntegrationInfo } from "infrastructure/services/integrationInfo.service";

export default {
    action(context) {
        const showIntegrationInfoService = context.di<IntegrationInfo>("showIntegrationInfoService");
        const resource = context.Contract.getIntegrationInfo.bind(context.Contract);
        showIntegrationInfoService.showInfoWindow(resource);
    },
    icon: "fa-info-circle",
    title: "Инфо ЦССУ",
} as IExtraOptionPrototype;
