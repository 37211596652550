import automapper from "infrastructure/services/automapper";
import { Payment } from "domain/classes/payment.class";
import VehicleDocument from "domain/classes/vehicleDocument.class";
import { InspectionConclusion } from "domain/classes/inspectionConclusion.class";
import UgskPhone from "domain/classes/ugsk-phone.class";
import moment from "moment";
import { GenderAgeExperiences, UAutoContract, ProlongationDenialInfo, OptionalEquipment } from "./uAuto.factory.ts";

const arrayMapperRule = (sourceType, targetType, filterFn = () => true) => {
    //  @warning Ни в коем случае не переделывать в стрелочную функцию - падает автомаппер при юнит тестах
    return function func1(opts) {
        const prop = opts.sourceObject[opts.sourcePropertyName];
        if (prop === null || prop === undefined) {
            return [];
        }
        return automapper.map(sourceType, targetType, prop.filter(filterFn));
    };
};

const dateTimeToDate = str => `${str.slice(0, 10)}T00:00:00`;
const dateToLocalDateTime = str => `${str.slice(0, 10)}T00:00:00${moment().format("Z")}`;

automapper.createMap(Object, GenderAgeExperiences)
    .forMember("Id", opts => opts.mapFrom("Id"))
    .forMember("gender", opts => opts.mapFrom("Sex"))
    .forMember("birthday", opts => {
        return opts.sourceObject.BirthDate ? dateToLocalDateTime(opts.sourceObject.BirthDate) : null;
    })
    .forMember("drivingExperience", opts => {
        return opts.sourceObject.DrivingExperienceStartDate ? dateTimeToDate(opts.sourceObject.DrivingExperienceStartDate) : null;
    })
    .forMember("licenseNumber", opts => opts.mapFrom("LicenseNumber"))
    .forMember("licenseSerial", opts => opts.mapFrom("LicenseSerial"))
    .forMember("firstName", opts => opts.mapFrom("ContractorPersonFirstName"))
    .forMember("lastName", opts => opts.mapFrom("ContractorPersonLastName"))
    .forMember("middleName", opts => opts.mapFrom("ContractorPersonMiddleName"))
    .forMember("licenseDocumentType", opts => opts.mapFrom("LicenseDocumentType"))
    .forMember("kbm", opts => opts.mapFrom("KBM"))
    .forMember("licenseIssueDate", opts => {
        return opts.sourceObject.LicenseIssueDate ? dateTimeToDate(opts.sourceObject.LicenseIssueDate) : null;
    })
    .forMember("hasPreviousDriverLicense", opts => opts.mapFrom("HasPreviousDriverLicense"))
    .forMember("previousLicenseIssueDate", opts => opts.mapFrom("PreviousLicenseIssueDate"))
    .forMember("previousLicenseLastName", opts => opts.mapFrom("PreviousLicenseLastName"))
    .forMember("previousLicenseNumber", opts => opts.mapFrom("PreviousLicenseNumber"))
    .forMember("previousLicenseSerial", opts => opts.mapFrom("PreviousLicenseSerial"))
    .forMember("guid", opts => opts.mapFrom("Guid"));

automapper.createMap(GenderAgeExperiences, "DTO")
    .forMember("Id", opts => opts.mapFrom("Id"))
    .forMember("Sex", opts => opts.mapFrom("gender"))
    .forMember("BirthDate", opts => opts.mapFrom("birthday"))
    .forMember("DrivingExperienceStartDate", opts => opts.mapFrom("drivingExperience"))
    .forMember("LicenseNumber", opts => opts.mapFrom("licenseNumber"))
    .forMember("LicenseSerial", opts => {
        if (typeof opts.sourceObject.licenseSerial === "string" && opts.sourceObject.licenseSerial.length === 0) {
            return null;
        }

        return opts.sourceObject.licenseSerial;
    })
    .forMember("ContractorPersonFirstName", opts => opts.mapFrom("firstName"))
    .forMember("ContractorPersonLastName", opts => opts.mapFrom("lastName"))
    .forMember("ContractorPersonMiddleName", opts => opts.mapFrom("middleName"))
    .forMember("LicenseDocumentType", opts => opts.mapFrom("licenseDocumentType"))
    .forMember("KBM", opts => opts.mapFrom("kbm"))
    .forMember("LicenseIssueDate", opts => opts.mapFrom("licenseIssueDate"))
    .forMember("HasPreviousDriverLicense", opts => opts.mapFrom("hasPreviousDriverLicense"))
    .forMember("PreviousLicenseIssueDate", opts => opts.mapFrom("previousLicenseIssueDate"))
    .forMember("PreviousLicenseLastName", opts => opts.mapFrom("previousLicenseLastName"))
    .forMember("PreviousLicenseNumber", opts => opts.mapFrom("previousLicenseNumber"))
    .forMember("PreviousLicenseSerial", opts => opts.mapFrom("previousLicenseSerial"))
    .forMember("Guid", opts => opts.mapFrom("guid"));

//Todo: склеить с интерфейсом
class PlannedPayment {
    constructor() {
        this.Amount = 0;
        this.DatePay = null;
        this.Id = undefined;
    }
}

class Calculation {
    constructor() {
        this.IsPrintable = false;
        this.Name = "";
        this.Title = "";
        this.Role = "";
        this.SortOrder = 0;
        this.Value = null;
    }
}
automapper.createMap(Object, Calculation);
automapper.createMap(Object, ProlongationDenialInfo);
automapper.createMap(Object, PlannedPayment);
automapper.createMap(Object, Payment);
automapper.createMap(Object, VehicleDocument);
automapper.createMap(Object, InspectionConclusion);
automapper.createMap(Object, OptionalEquipment);

const convert = fn => opts => {
    const sourceValue = opts.sourceObject[opts.sourcePropertyName];
    if (sourceValue === null) return null;
    //  @todo remove it after 11.4.0
    if (sourceValue === "") return null;
    return fn(sourceValue);
};

automapper.createMap(Object, UAutoContract)
    .forMember("BlockedFields", opts => opts.mapFrom("BlockedFields"))
    .forMember("Calculation", arrayMapperRule(Object, Calculation))
    .forMember("VehicleDocuments", arrayMapperRule(Object, VehicleDocument))
    .forMember("VehicleAccessories", opts => opts.mapFrom("VehicleAccessories"))
    .forMember("PlannedPayments", arrayMapperRule(Object, PlannedPayment))
    .forMember("FactPayments", arrayMapperRule(Object, Payment))
    .forMember("GenderAgeExperiences", arrayMapperRule(Object, GenderAgeExperiences))
    .forMember("OptionalEquipments", arrayMapperRule(Object, OptionalEquipment))
    .forMember("InspectionConclusions", arrayMapperRule(Object, InspectionConclusion))
    .forMember("ProlongationDenialInfos", arrayMapperRule(Object, ProlongationDenialInfo))
    .forMember("MultiCalculationOffers", opts => opts.mapFrom("MultiCalculationOffers"))
    .forMember("BeneficiaryPhones", opts => UgskPhone.phoneStringToPhoneArray(opts.sourceObject.BeneficiaryCommonPhone))
    .forMember("InsuredPersonBirthday", convert(dateToLocalDateTime))
    .forMember("ContractDate", convert(dateToLocalDateTime))
    .forMember("InsurantPhones", opts => UgskPhone.phoneStringToPhoneArray(opts.sourceObject.InsuredCommonPhone))
    .forMember("VehicleDocGrossVehicleWeight", opts => opts.condition((sourceObject) => sourceObject.VehicleDocGrossVehicleWeight !== null));

automapper.createMap(VehicleDocument, "DTO");
automapper.createMap(UAutoContract, "DTO")
    .forMember("InspectionConclusions", () => [])
    .forMember("BeneficiaryPhones", opts => opts.ignore())
    .forMember("BeneficiaryCommonPhone", opts => UgskPhone.phoneArrayToPhoneString(opts.sourceObject.BeneficiaryPhones))
    .forMember("InsurantPhones", opts => opts.ignore())
    .forMember("InsuredCommonPhone", opts => UgskPhone.phoneArrayToPhoneString(opts.sourceObject.InsurantPhones))
    .forMember("GenderAgeExperiences", arrayMapperRule(GenderAgeExperiences, "DTO"))
    .forMember("InsuredPersonBirthday", convert(dateTimeToDate))
    .forMember("ContractDate", convert(dateTimeToDate))
    .forMember("VehicleDocuments", arrayMapperRule(VehicleDocument, "DTO", (document) => Boolean(document.VehicleDocumentType)))
    .forMember("VehicleDocGrossVehicleWeight", opts => opts.condition((sourceObject) => sourceObject.VehicleDocGrossVehicleWeight !== 0));
