import { NgComponentController } from "infrastructure/NgController";
import { UgskPreviousContractModalController } from "./ugskPreviousContractFinderModal";
import { UgskPreviousContractInfoModalController } from "./ugskPreviousContractInfoModalController";

import angular from "angular";
import { IModalService } from "angular-ui-bootstrap";
import { IContractResource } from "infrastructure/interfaces/IContractResource";
import IPreviousContractRecord from "infrastructure/interfaces/IPreviousContractRecord";
import IProlongableContract from "infrastructure/interfaces/IProlongableContract";
import { Int } from "infrastructure/types";
import template from "./ugskPreviousContractFinder.component.html";
import templatePreviousContractFinderModal from "./ugskPreviousContractFinderModal.html";
import templatePreviousContractInfoModal from "./ugskPreviousContractInfoModal.html";

export default angular.module("ugsk.components.previous-contract-finder", []).component("ugskPreviousContractFinder", {
    bindings: {
        contractResourceDelegate: "<",
        disabled: "<ngDisabled",
        /* Функция в bodyController продукта используемая для копирования данных из предыдущего договора */
        onPreviousContractSelected: "&",
        /* Функция в bodyController продукта используемая для отвязывания предыдущего договора */
        onRemovePreviousContract: "&",
        /* необхадим для отоброжения кнопки i и формирования запроса после пролонгации */
        previousContractId: "<",
        /* необхадим для отоброжения номера после пролонгации */
        previousPolicyNumber: "<",
        /* необхадим для отоброжения серии после пролонгации */
        previousPolicySerial: "<",
    },
    controller: class extends NgComponentController {
        public state: string;
        public stateClass: string;
        public previousContractId: Int;
        public requiredContractNumber: string;
        public disabled: boolean;
        public found: boolean;
        public contractResourceDelegate: IContractResource;
        public onRemovePreviousContract: (...args: any) => void;
        public onPreviousContractSelected: (...args: any) => void;
        public onInit(): void {
            // Атрибут State позаимствован из ugsk-address-picker и определен в site.css
            this.state = null;
            this.stateClass = null;

            if (this.previousContractId) {
                this.stateClass = "fa-info";
                this.state = "success";
            }
        }
        public isFindButtonActive(): boolean {
            return (
                this.requiredContractNumber &&
                this.requiredContractNumber.length >= 6 &&
                this.requiredContractNumber.length <= 7 &&
                this.state !== "error"
            );
        }
        public doCheck(): void {
            if (this.disabled) {
                this.clearField();
            }
        }
        public clearField(): void {
            this.requiredContractNumber = null;
            this.onRemovePreviousContract();
            this.onNumberChanged();
        }
        public onKeyPressed(event: KeyboardEvent): void {
            if (event.type === "keypress" && event.keyCode === 13 && this.isFindButtonActive()) {
                this.onFindButtonClicked();
            }
        }
        public onInfoButtonClicked(): void {
            if (this.state === "success") {
                this.showPreviousContractInfo();
            } else if (this.state === "error") {
                this.requiredContractNumber = null;
                this.onRemovePreviousContract();
                this.onNumberChanged();
            }
        }
        public onNumberChanged(): void {
            this.found = false;
            this.state = null;
        }
        public searchPreviousContractById(
            prevContractId: Int,
        ): angular.IPromise<Array<IPreviousContractRecord<IProlongableContract>>> {
            return this.contractResourceDelegate.query({ prevContractId }).$promise;
        }
        public searchPreviousContractByNumber(
            prevPolicyNumber: string,
        ): angular.IPromise<Array<IPreviousContractRecord<IProlongableContract>>> {
            return this.contractResourceDelegate.query({ prevPolicyNumber }).$promise;
        }
        public showPreviousContractInfo(): angular.IPromise<void> {
            this.stateClass = "fa-spinner fa-spin";
            this.state = "searching";
            const $uibModal = this.di<IModalService>("$uibModal");
            return this.searchPreviousContractById(this.previousContractId).then((data) => {
                this.state = "success";
                this.found = true;
                this.stateClass = "fa-info";
                $uibModal.open({
                    controller: UgskPreviousContractInfoModalController,
                    controllerAs: "vm",
                    resolve: {
                        previousPolicyData: () => data,
                    },
                    template: templatePreviousContractInfoModal,
                });
            }).catch(() => {
                this.state = "error";
                this.stateClass = "fa-times";
                this.found = false;
            });
        }
        public promptUserProlongatedContracts(
            foundPreviousContracts: IPreviousContractRecord[],
        ): angular.IPromise<IPreviousContractRecord> {
            const $uibModal = this.di<IModalService>("$uibModal");
            return $uibModal.open({
                backdrop: "static",
                controller: UgskPreviousContractModalController,
                controllerAs: "vm",
                keyboard: false,
                resolve: {
                    previousPolicyData: () => foundPreviousContracts,
                },
                size: "lg",
                template: templatePreviousContractFinderModal,
            }).result;
        }
        public onFindButtonClicked(): void {
            this.stateClass = "fa-spinner fa-spin";
            this.state = "searching";
            this.searchPreviousContractByNumber(this.requiredContractNumber).then((foundContracts) => {
                this.state = "success";
                this.found = true;
                this.stateClass = "fa-info";
                return this.promptUserProlongatedContracts(foundContracts).then((previousContractRecord) => {
                    this.onPreviousContractSelected({ previousContractRecord });
                }).catch(() => {
                    this.clearField();
                });
            }).catch(() => {
                this.state = "error";
                this.stateClass = "fa-times";
                this.found = false;
            });
        }
    },
    controllerAs: "vm",
    template,
}).name;
