import InspectionConclusionDTO from "infrastructure/interfaces/WebApi/InspectionConclusionDTO";
import { DateTimeOffset, Int } from "infrastructure/types";
import moment from "moment";

/**
 * @see UGSK.K3.Service.DTO/OSAGO/OsagoInspectionConclusionDTO.cs
 * @todo rename to InspectionConclusionDTO
 */
export class InspectionConclusion implements InspectionConclusionDTO {
    public Id: Int = 0;
    public ContractId: Int = 0;
    public Damages: string = null;
    public InspectionDate: DateTimeOffset = moment().subtract(1, "hour").format("YYYY-MM-DDTHH:mm:ss");
    public InspectionPlace: string = null;
    public Inspector: string = null;
    public Mileage: Int = 0;
    public PhotoCount?: Int = 0;
    public SpecialNote: string = null;
    public TiresMark: string = null;
    public TiresSize: string = null;
}
