import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { OsagoToolbarController } from "../osago-toolbar.controller";

export default {
    icon: "fa fa-refresh",
    title: "Сбросить кэш КБМ",
    action(context: OsagoToolbarController) {
        return context.Contract.resetCacheKBM().then(() => {
            context.notifyService.successMessage(
                null,
                "Кэш КБМ успешно сброшен",
            );
        }).catch(() => {
            context.notifyService.errorMessage(
                null,
                "Ошибка при попытке сброса кэша КБМ",
            );
        });
    },
} as IExtraOptionPrototype;
