import { NgComponentController } from "infrastructure/NgController";
import $ from "jquery";
import "expose-loader?jQuery!jquery";
import "lib/jquery.bootstrap-touchspin";

import template from "./ugskTouchSpin.component.html";

export default angular.module("ugsk.components.ugsk-touch-spin", []).component("ugskTouchSpin", {
    bindings: {
        boostat: "<",
        debounce: "<",
        decimals: "<",
        forcestepdivisibility: "@",
        max: "<",
        min: "<",
        ngChange: "&",
        ngDisabled: "<",
        ngModel: "=",
        step: "<",
        onOutOfSet: "&?",
        thousands: "<?",
    },
    controller: class extends NgComponentController {
        onInit() {
            const [$timeout, notifyService] = this.di(["$timeout", "notifyService"]);
            this.touchSpinConfig = {
                boostat: this.boostat,
                buttondown_class: "btn btn-white",
                buttonup_class: "btn btn-white",
                decimals: this.decimals,
                forcestepdivisibility: this.forcestepdivisibility || "round",
                initval: this.internalModel,
                max: this.max,
                min: this.min,
                step: this.step,
            };
            if (this.thousands === true) {
                this.touchSpinConfig.formatter = this.di("thousandFilter");
            }
            this.element = $(this.$element).find("input");
            this.element.TouchSpin(this.touchSpinConfig).on("change", () => {
                const currentValue = this.element.val();
                if (currentValue > this.max || currentValue < this.min) {
                    notifyService.message(
                        "Внимание, значение поля автоматически изменено! ",
                        `Допустимые значения: от ${this.min} до ${this.max}`,
                        {
                            type: "warning",
                            viewType: "toaster",
                            timeout: 5000
                        }
                    );
                }
                $timeout(() => {
                    this.internalModel = Number(("" + this.element.val().replace(",", ".")).replaceAll(" ", ""));
                });

                $timeout.cancel(this.debounceOut);
                this.debounceOut = $timeout(() => {
                    this.ngChange();
                }, this.debounce);
            });
            this.element.TouchSpin(this.touchSpinConfig).on("focus", () => {
                const value = this.element.val().replace(".", ",").replace(/[^-0-9\,]/g, "");
                this.element.val(value)
            });
            this.$scope.$watch(
                () => this.min,
                () => {
                    this.element.trigger("touchspin.updatesettings", { min: this.min });
                }
            );
            this.$scope.$watch(
                () => this.max,
                () => {
                    this.element.trigger("touchspin.updatesettings", { max: this.max });
                }
            );
            this.$scope.$watch(
                () => this.ngDisabled,
                () => {
                    this.element.trigger("touchspin.updatesettings", {
                        disabled: this.ngDisabled,
                    });
                }
            );

            if (typeof this.onOutOfSet === "function") {
                this.element.on("touchspin.on.outOfSet", (_, value) => {
                    this.onOutOfSet({ value });
                });
                this.element.on("touchspin.on.min touchspin.on.max", () => {
                    this.onOutOfSet();
                });
            }
        }
        get ngModel() {
            return this.internalModel;
        }
        set ngModel(val) {
            this.internalModel = val;
            if (this.element) {
                this.element.val(val);
            }
        }
    },
    controllerAs: "vm",
    template,
}).name;
