import ugskCollapsibleComponent from "application/components/ugsk-collapsible/ugskCollapsible.component";
import { Product } from "domain/classes/product.class";
import { BoxVHIToolbarController } from "./boxVHI-toolbar.controller";
import { BoxVHIController } from "./boxVHI.controller";
import { BoxVHIJournalToolbarController } from "./journal/boxVHIJournal-toolbar.controller";
import { BoxVHIJournalController } from "./journal/boxVHIJournal.controller";

import template from "./body.html";
import "./boxVHI.css";

import "./components/insured-person/insuredPerson.component";
import "./components/ugsk-box-vhi-insurant/ugskBoxVhiInsurant.component";

import angular from "angular";
import { ProductsService } from "infrastructure/services/products.service";
import { BoxVHIContract } from "./boxVHI.factory";
import manual from "./manual_boxVHI.pdf";

class BoxVHI extends Product {
    constructor() {
        super();
        this.code = "boxVHI";
        this.groupName = "Страхование жизни и здоровья";
        this.productName = "Медицинское страхование";
        this.insurerCanBeLegalEntity = true;
        this.description = "<ul class='p-l-sm'><li><strong>Антиклещ</strong><br> Медицинское страхование от последствий укуса клеща;</li><li><strong>Студент</strong><br> Медицинское страхование иностранных студентов;</li><li><strong>Трудовой мигрант</strong><br> Медицинское страхование трудовых мигрантов.</li></ul>";
        this.logo = "nav_boxVHI";
        this.subProducts = [{
            code: "AntiMiteVHI",
            insuranceRules: "https://ugsk.ru/about/pravila/DMS.pdf",
            productId: 10,
            productName: "Антиклещ",
            sort: 91,
        }, {
            code: "ImmigrantVHI",
            insuranceRules: "https://ugsk.ru/about/pravila/Migranti.pdf",
            productId: 11,
            productName: "Трудовой мигрант",
            sort: 92,
        }, {
            code: "StudentVHI",
            productId: 12,
            productName: "Студент",
            sort: 93,
        }];
        this.manualUrl = manual;
        this.sort = 90;
        this.template = template;
        this.controller = BoxVHIController;
        this.toolbarController = BoxVHIToolbarController;
        this.journalController = BoxVHIJournalController;
        this.journalToolbarController = BoxVHIJournalToolbarController;
    }
    get contractClass() {
        return BoxVHIContract;
    }
}

angular.module("app.boxVHI", [
    "touchSpin",
    ugskCollapsibleComponent,
    "app.boxVHI.components.Insurant",
    "app.boxVHI.components.insuredPerson",
]).run((productsService: ProductsService) => {
    "ngInject";

    productsService.register(new BoxVHI());
});
