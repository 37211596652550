import { _ViewDeclaration, Transition } from "@uirouter/angularjs";
import angular, { IQService } from "angular";
import "intro.js";
import "lib/angular-intro";
import moment from "moment";

import bottomToolbarTemplate from "application/bottomToolbar.html";
import journalTemplate from "application/journal/body.html";
import { Contract } from "domain/classes/contract.class";
import { Product } from "domain/classes/product.class";
import { UgskSet } from "domain/classes/ugsk-set.class";
import { capitalizeFirstLetter, Helpers } from "infrastructure/app.helpers";
import { StateRegistryPatched } from "infrastructure/interfaces";
import { IContractResource } from "infrastructure/interfaces/IContractResource";
import { IProductVersionItem } from "infrastructure/interfaces/IProductsVersion";
import IUGSKWindow from "infrastructure/interfaces/IUGSKWindow";
import { Logger } from "infrastructure/logToServer";
import { NgControllerBase } from "infrastructure/NgController";
import Repository, { IRepositoryResource } from "infrastructure/Repository.class";
import { AbstractEmployee, Employee } from "infrastructure/services/employee.service.js";
import { EnvService } from "infrastructure/services/env.service";
import { StatusService } from "infrastructure/services/status.service";
import { SubLicensesController } from "infrastructure/subLicensesController";
import { ProductVersionInfoController } from "infrastructure/versionInfoController";
import { PhoneValidationService } from "./phoneValidation.service";

export class ProductsService extends NgControllerBase {
    private products: {
        [key: string]: Product;
    } = {};
    public register(product: Product) {
        const helpers = this.di<Helpers>("helpers");

        if (helpers.isProductDisabled(product.code)) {
            product.originalProductName = product.productName;
            product.productName = `${product.productName} (архив)`
        }

        if (Array.isArray(product.subProducts)) {
            product.subProducts.forEach(subProduct => {
                if (helpers.isProductDisabled(subProduct.code)) {
                    subProduct.productName = `${subProduct.productName} (архив)`;
                }
            });
        }

        this.products[product.code] = product;
        this.registerProductStates(product);
        this.registerJournalStates(product);
    }
    public updateProductsVersions(versionsInfo: IProductVersionItem[]) {
        this.getAllProducts().forEach((product) => {
            const productVersionInfo = versionsInfo
                .find((item) => item.ProductName.toLowerCase() === product.code.toLowerCase());
            product.setVersion(productVersionInfo);
        });
    }
    public getAllProducts() {
        return Object.values(this.products);
    }
    public getByProductName(productName: string) {
        return Object.values(this.products).find((product) => product.productName === productName || product.originalProductName === productName);
    }
    public getByCode(code: string) {
        return this.products[code];
    }
    private registerProductStates(product: Product) {
        const stateRegistry = this.di<StateRegistryPatched>("$stateRegistry");
        const ProductContractClass = product.contractClass;
        const productCode = product.code;
        const {
            productName,
            extraViews,
            extraResolve,
        } = product;
        const toolbarController = product.toolbarController || `${productCode}ToolbarController`;
        const bodyController = product.controller || `${productCode}BodyController`;
        stateRegistry.register({
            abstract: true,
            name: `app.${productCode}`,
            url: `/${productCode}`,
            // tslint:disable-next-line: object-literal-sort-keys
            title: productName,
            resolve: {
                contractStatuses(statusService: StatusService) {
                    "ngInject";

                    return statusService.get(productCode);
                },
                currentProduct() {
                    return product;
                },
                staticValidationRules() {
                    return product.staticValidationRules;
                },
                cashPaymentKindEnabled(
                    employee: Employee,
                    envService: EnvService,
                ) {
                    "ngInject";

                    const cfgValue = envService.read("cashPaymentKindEnabled");
                    if (angular.isArray(cfgValue)) {
                        return cfgValue.some(
                            (cfg) => Object.keys(cfg).every((key) => cfg[key] === employee.info[key]),
                        );
                    }
                    return Boolean(cfgValue);
                },
                chatbotIsEnabled(
                    employee: Employee,
                    envService: EnvService,
                ) {
                    "ngInject";

                    //  TODO: убрать, когда запустят чат бот в офисно прямом канале
                    if (!(window as IUGSKWindow).chatbot) {
                        return;
                    }
                    const cfgItems = envService.read("chatbot");
                    if (cfgItems && angular.isArray(cfgItems.enabled)) {
                        return cfgItems.enabled.some((cfgItem) => {
                            if (angular.isArray(cfgItem.Products)) {
                                if (!cfgItem.Products.includes(productCode)) {
                                    return false;
                                }
                            }
                            return Object.keys(cfgItem)
                                .filter((val) => val !== "Products")
                                .every(
                                    (cfgKey) =>
                                        angular.isArray(cfgItem[cfgKey]) &&
                                        cfgItem[cfgKey].includes(employee.info[cfgKey]) ||
                                        employee.info[cfgKey] === cfgItem[cfgKey],
                                );
                        });
                    } else {
                        return Boolean(cfgItems && angular.isArray(cfgItems.enabled));
                    }
                },
                selection() {
                    return new UgskSet<Contract>();
                },
                pageSharedData() {
                    "ngInject";

                    return {
                        currentProduct: productCode,
                    };
                },
                isProductLocked(helpers: Helpers) {
                    "ngInject";

                    return helpers.isProductDisabled(productCode);
                },
            },
            views: {
                "productVersionInfo@": {
                    controller: ProductVersionInfoController,
                    controllerAs: "vm",
                    template: "<span ng-bind=\"vm.productVersionInfo\"></span>",
                },
                "subLicensesInfo@": {
                    controller: SubLicensesController,
                    controllerAs: "vm",
                    template: "<span ng-bind='vm.getSubLicensesList()'></span>",
                },
            },
        });
        stateRegistry.register({
            data: {
                pageTitle: `${productName} | Платежи`,
                productPage: true,
            },
            name: `app.${productCode}.payments`,
            title: "Платежи",
            url: "/:id/payments",
            views: {
                "body@": {
                    controllerAs: "vm",
                    controller: function($transition$) {
                        "ngInject";

                        const $stateParams = $transition$.params();
                        const { id } = $stateParams;
                        this.contractId = parseInt(id, 10);

                        this.productCode = productCode;

                        this.paymentKindsBlacklist = [];
                    },
                    template: `
                        <ugsk-contract-payments-page
                            contract-id="vm.contractId"
                            payment-kinds-blacklist="vm.paymentKindsBlacklist"
                            product-code="vm.productCode"></ugsk-contract-payments-page>
                    `,
                },
            },
        });
        stateRegistry.register({
            data: {
                pageTitle: productName,
                productCode,
                productPage: true,
            },
            name: `app.${productCode}.index`,
            params: {
                contract: null,
            },
            url: "/:id?cross?subproduct?prolongate?crossFrom",
            views: {
                "body@": {
                    controller: bodyController,
                    controllerAs: "vm",
                    template: product.template,
                },
                "bottomToolbar@": {
                    controller: toolbarController,
                    controllerAs: "vm",
                    template: bottomToolbarTemplate,
                },
                "navigation@": {
                    controller: class NavigatorController {
                        public items: Array<{
                            target: string;
                            title: string;
                        }>;
                        public $onInit() {
                            this.items = product.navigation;
                        }
                    },
                    controllerAs: "vm",
                    template: `
                        <ugsk-top-navigation
                            items="vm.items"
                            with-scroll-bar="true">
                        </ugsk-top-navigation>
                    `,
                },
                "smallDigest@": {
                    controller: function smallDigestController(
                        Contract: Contract,
                        currentProduct: Product,
                        $scope,
                        $controller,
                        $transition$
                    ) {
                        "ngInject";

                        this.Contract = Contract;
                        this.currentProduct = currentProduct;
                        if (["uAuto"].includes(currentProduct.code)) {
                            this.baseController = $controller(product.controller, { $scope, $transition$ });
                        }
                    },
                    controllerAs: "vm",
                    template: `
                        <ugsk-small-digest
                            contract="vm.Contract"
                            contract-modified="vm.pageSharedData.Form.modified"
                            current-product="vm.currentProduct">
                            <premium-details ng-if="['uAuto'].includes(vm.currentProduct.code)">
                                <a
                                    ng-click="vm.baseController && vm.baseController.showPremiumDetails()"
                                    ng-show="vm.Contract.Calculation.length > 0"
                                    popover-placement="left">
                                    <i class="fa fa-question-circle "></i>
                                </a>
                            </premium-details>
                            <digest-header ng-if="['uAuto'].includes(vm.currentProduct.code)">
                                <ugsk-uauto-insurance-program
                                    parent="vm.baseController"
                                ></ugsk-uauto-insurance-program>
                            </digest-header>
                            <digest-header-third ng-if="['uAuto'].includes(vm.currentProduct.code)">
                                <ugsk-uauto-multi-calculation
                                    parent="vm.baseController"
                                ></ugsk-uauto-multi-calculation>
                            </digest-header-third>
                        </ugsk-small-digest>`,
                },
                ...extraViews,
            },
            // tslint:disable-next-line: object-literal-sort-keys
            resolve: {
                //  TODO: Удалить, используется только в assuranceHome
                hashList() {
                    return new Map<string, any>();
                },
                /*
                 * Последовательная загрузка договора и репозитория.
                 * До загрузки репозитория, данные по договору хранятся в переменной. Репозиторий обновляется сам.
                 * Договор обновляется после загрузки репозитория
                 * Если создаётся новый договор, он дополнительно обновляется значениями из репозитория
                 */
                Contract(
                    $q: IQService,
                    $log: Logger,
                    contractsServiceFactory: (product: Product) => IContractResource,
                    $transition$: Transition,
                    integrationResourceFactory: (productName: string) => ng.resource.IResource<any>,
                    repositoryService: (productName: string) => IRepositoryResource,
                ) {
                    "ngInject";

                    //  TODO: https://ui-router.github.io/guide/ng1/migrate-to-1_0#stateparams-deprecation
                    const $stateParams = $transition$.params();
                    const id: string = $stateParams.id;
                    const prolongate = Boolean($stateParams.prolongate);
                    const cross: string = $stateParams.cross;
                    const previousContract: Contract = $stateParams.contract;
                    const subProductCode = $stateParams.subproduct;
                    const idInt = parseInt(id, 10);
                    //  Предотвращаем повторную загрузку данных
                    //  после смены состояния при сохранении договора
                    if (!subProductCode && !cross && previousContract && idInt === previousContract.Id) {
                        return previousContract;
                    }

                    const repositoryServiceInstance = repositoryService(productCode);
                    const contractRepository = new Repository(repositoryServiceInstance);
                    const contractsService = contractsServiceFactory(product);
                    const isBlank = Number.isNaN(parseInt(id, 10));
                    return $q((resolve, reject) => {
                        const contract: Contract = new ProductContractClass();
                        contract.setRepository(contractRepository);
                        contract.setResourceProvider(contractsService);
                        contract.setIntegrationResource(integrationResourceFactory(productCode));
                        if (isBlank) {
                            $log.debug("Загрузка репозитория для нового контракта");
                            return resolve(contract);
                        }

                        $log.debug("Загрузка контракта");

                        return contract.$load(idInt, cross).then(() => {
                            // tslint:disable-next-line: max-line-length
                            //  @blackmagic http://bitbucket.ugsk.ru/projects/UPRO/repos/general/browse/UGSK.K3.Front/Controllers/OSAGOController.cs#138
                            if (cross) {
                                contract.SigningDate = moment().format();
                            }
                            if (prolongate) {
                                const originalContract: Contract = new ProductContractClass();
                                originalContract.setResourceProvider(contractsService);
                                return originalContract.$load(idInt).then(() => {
                                    const srcContractTo = originalContract.ContractTo;
                                    const targetContractFrom = moment(srcContractTo).add(1, "second").format();
                                    contract.ContractFrom = targetContractFrom;
                                    contract.ContractTo = null;
                                    return resolve(contract);
                                });
                            }
                            return resolve(contract);
                        }, (error) => {
                            error.data = `договор ${productCode} № ${idInt}`;
                            reject(error);
                        });
                    }).then((contract: Contract) => {
                        $log.debug("Контракт загружен");
                        $log.debug("Загрузка репозитория для контракта");
                        if (subProductCode) {
                            const subproduct = product.subProducts.find((sub) => sub.code === subProductCode);
                            contract.InsuranceProductId = subproduct.productId;
                        }
                        return contract.getRepository().load(contract.toDTO()).then((repository) => {
                            $log.debug("Контракт и репозиторий загружены");
                            //  @fixme `setFromRepository` правильно будет работать только с `ContractDTO`,
                            //  (но пока это не проблема)
                            //  соответственное нужно выполнить `set` на DTO, потом смапить в `Contract`
                            if (cross || isBlank || prolongate) {
                                contract.setFromRepository(repository, { cross, isBlank, prolongate });
                            }
                            return contract;
                        });
                    });
                },
                exportToGlobals(global) {
                    "ngInject";

                    global.currentProduct = productCode;
                },
                phoneValidationService(
                    Contract: Contract,
                    originalEmployee: AbstractEmployee,
                    $injector: angular.auto.IInjectorService,
                ) {
                    "ngInject";

                    return $injector.instantiate(PhoneValidationService, {
                        contract: Contract,
                        employee: originalEmployee,
                        productCode,
                    });
                },
                ...extraResolve,
            },
        });
    }
    private registerJournalStates(product: Product) {
        const stateRegistry = this.di<StateRegistryPatched>("$stateRegistry");
        const productCode = product.code;
        const journalBodyController = product.journalController || productCode + "JournalController";
        stateRegistry.register({
            abstract: true,
            data: {
                pageTitle: product.productName + " | Журнал договоров",
                productCode: product.code,
            },
            name: "app." + productCode + ".journal",
            url: "/journal/",
            views: {
                "bottomToolbar@": {
                    controller: product.journalToolbarController,
                    controllerAs: "vm",
                    template: bottomToolbarTemplate,
                },
                "journalBody@": {
                    controller: journalBodyController,
                    controllerAs: "vm",
                    template: journalTemplate,
                },
            },
            // tslint:disable-next-line: object-literal-sort-keys
            resolve: {
                contractStatuses(statusService) {
                    "ngInject";

                    return statusService.get(productCode);
                },
            },
        });
        stateRegistry.register({
            name: `app.${productCode}.journal.index`,
            url: "",
        });
    }
}

export default angular
    .module("ugsk.services.products", [
        //  TODO: Когда появится модуль журналов, перенести зависимость в него
        "angular-intro",
    ])
    .service("productsService", ProductsService)
    .name;
