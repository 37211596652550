import angular from "angular";

import "infrastructure/app.service";
import ugskDigestComponent from "./ugsk-digest/ugskDigest.component";

import ugskPaymentComponent from "./ugsk-payment/ugskPayment.component";
import ugskJournalTableComponent from "./ugsk-journal-table/ugskJournalTable.component";

angular.module("ugsk.components", [
    "ugsk.services",
    ugskJournalTableComponent,
    ugskPaymentComponent,
    ugskDigestComponent,
]);

import { ugskCommonBodyComponent } from "./ugsk-common-body/ugskCommonBody.component";

angular.module("ugsk.components").component("ugskCommonBody", ugskCommonBodyComponent);

import { ugskContractPaymentsPageComponent } from "./ugsk-contract-payments-page/ugskContractPaymentsPage.component";

angular.module("ugsk.components").component("ugskContractPaymentsPage", ugskContractPaymentsPageComponent);

import { ugskJournalFilterComponent } from "./ugsk-journal/ugskJournalFilter.component";

angular.module("ugsk.components").component("ugskJournalFilter", ugskJournalFilterComponent);


import { ugskPaymentsComponent } from "./ugsk-payments/ugskPayments.component";

angular.module("ugsk.components").component("ugskPayments", ugskPaymentsComponent);

import { ugskPaymentOfContractView } from "./views/ugsk-payment-of-contract-view/ugskPaymentOfContractView.component";

angular.module("ugsk.components").component("ugskPaymentOfContractView", ugskPaymentOfContractView);

import { ugskFilterFieldComponent } from "./ugsk-filter-field/ugskFilterField.component";

angular.module("ugsk.components").component("ugskFilterField", ugskFilterFieldComponent);
