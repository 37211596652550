import angular from "angular";
import { NgComponentController } from "infrastructure/NgController";
import template from "./ugskBoxAccidentContractParams.component.html";

export default angular.module("app.boxAccident.components.contract-params", [])
.component("ugskBoxAccidentContractParams", {
    bindings: {
        contract: "=",
        insuranceTermChanged: "&",
        insuranceTerms: "<",
        isFormLocked: "<",
        maxDate: "=",
        minDate: "=",
        serialNumbers: "<",
    },
    controller: NgComponentController,
    controllerAs: "vm",
    template,
}).name;
