import "lib/ng-switchery";
import { NgComponentController } from "infrastructure/NgController";
import AssumedRiskOnTheContract from "domain/classes/assumedRiskOnTheContract.class";
import template from "./ugskRisks.component.html";


angular.module("ugsk.components.risks", []).component("ugskRisks", {
    bindings: {
        disabled: "<",
        model: "=ngModel",
        risks: "<",
    },
    controller: class extends NgComponentController {
        init() {
            if (!this.model) {
                this.model = [];
            }

            let allRisks = false;
            if (this.model && this.risks) {
                allRisks = this.risks.length === this.model.length;
            }

            angular.forEach(this.risks, item => {
                item.selected = allRisks; // eslint-disable-line
                const value = item.AssumedRiskOnTheContractId;
                if (!allRisks && this.model.length) {
                    item.selected = Boolean(this.model.find(modelItem => modelItem.Id === value)); // eslint-disable-line
                }
            });
        }
        onInit() {
            this.init();
        }

        onChanges(changesObj) {
            if (changesObj.model && !changesObj.model.isFirstChange()) {
                this.model = changesObj.model.currentValue;
                this.init();
            }
            if (changesObj.risks && !changesObj.risks.isFirstChange()) {
                this.risks = changesObj.risks.currentValue;
                this.init();
            }
        }

        changeRisk(risk) {
            if (this.$attrs.ugskValidation) {
                const [validationService] = this.di(["validationService"]);
                validationService.removeError(this.$attrs.ugskValidation);
            }
            if (risk.selected) {
                this.model.push(new AssumedRiskOnTheContract(
                    risk.AssumedRiskOnTheContractId,
                    risk.AssumedRiskOnTheContractName,
                    risk.AssumedRiskOnTheContractDescription
                ));
            } else {
                this.model = this.model.filter(item => item.Id !== risk.AssumedRiskOnTheContractId);
            }
        }

        changeAllRisks() {
            this.model = [];
            angular.forEach(this.risks, risk => {
                risk.selected = true; // eslint-disable-line
                this.model.push(new AssumedRiskOnTheContract(
                    risk.AssumedRiskOnTheContractId,
                    risk.AssumedRiskOnTheContractName,
                    risk.AssumedRiskOnTheContractDescription
                ));
            });
        }
    },
    controllerAs: "vm",
    template,
});
