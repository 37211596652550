import angular from "angular";
import UGSKBlob from "domain/classes/blob.class";
import { Helpers, unbreakWord } from "infrastructure/app.helpers";
import { IDatePickerOptions } from "infrastructure/interfaces/IDatePickerOptions";
import { IElementInformation } from "infrastructure/interfaces/IElementInformation";
import { IOptionsPopover } from "infrastructure/interfaces/IOptionsPopover";
import { ITouchSpinConfig } from "infrastructure/interfaces/ITouchSpinConfig";
import { BeneficiaryDTO } from "infrastructure/interfaces/WebApi/BeneficiaryDTO";
import { IAPIRepositoryItem } from "infrastructure/Repository.class";
import { NotifyService } from "infrastructure/services/notifyService";
import { Day, Int } from "infrastructure/types";
import { Uuid } from "lib/uuid";
import moment from "moment";
import { BaseProductBodyController } from "../baseProduct.body.controller";
import {
    IInsuranceTerm, IInsuredObjectDocTypeItem, InsurantPersonalAccidentProxy, IPaymentDocumentSerial, IPolicySerial,
    PersonalAccidentContract, PersonalAccidentRepository,
} from "./personalAccident.factory";

interface IDeclarationCollection {
    Name: string;
    Value: boolean;
}
interface IDiscountCollection {
    Id: Int;
    Value: string;
}

export class PersonalAccidentController extends BaseProductBodyController {
    public Contract: PersonalAccidentContract;
    public $filter: angular.FilterFactory;
    public notifyService: NotifyService;
    public insurant: InsurantPersonalAccidentProxy;
    public insuranceTargetInformation: IElementInformation;
    public unbreakWord = unbreakWord;
    public tooltipActiveRestOptionsPopover: IOptionsPopover = {
        popoverEvent: "'mouseenter'",
        /* tslint:disable-next-line:max-line-length */
        popoverMessage: "Понятие «Активный отдых» подразумевает: катание на аттракционах в парках (при условии, что правила техники безопасности не были нарушены), катание на велосипедах, катание на роликах всех типов, катание на скейтбордах и коньках, катание на самокатах, лыжные прогулки.",
        popoverPlaceShow: "top",
    };
    public tooltipSportsOptionsPopover: IOptionsPopover = {
        popoverEvent: "'mouseenter'",
        /* tslint:disable-next-line:max-line-length */
        popoverMessage: "Вид спорта указывается в том случае, если Застрахованное лицо планирует регулярные занятия определенными видами спорта на любительском, профессиональном уровне, включая тренировки, соревнования, а также самостоятельные регулярные занятия.",
        popoverPlaceShow: "top",
    };
    public diseaseFromDeclarationCollection: IDeclarationCollection[] = [{
        Name: "Да",
        Value: true,
    }, {
        Name: "Нет",
        Value: false,
    }];

    public coefficientTouchSpin: ITouchSpinConfig = {
        boostat: 5,
        buttondown_class: "btn btn-white",
        buttonup_class: "btn btn-white",
        decimals: 2,
        delimiter: ",",
        forcestepdivisibility: "round",
        max: 10,
        maxboostedstep: 10,
        min: 1,
        step: 0.01,
    };
    public insurerBirthdateConfig: Partial<IDatePickerOptions> = {
        endDate: moment().add(-18, "y").format("YYYY-MM-DD"),
        startDate: moment().add(-100, "y").format("YYYY-MM-DD"),
    };

    public insuredPersonBirthdateConfig: Partial<IDatePickerOptions> = {
        endDate: moment().add(-1, "y").format("YYYY-MM-DD"),
        startDate: moment().add(-81, "y").add(1, "d").format("YYYY-MM-DD"),
    };

    public beneficiaryBirthdateConfig: Partial<IDatePickerOptions> = {
        endDate: moment().add(-1, "d").format("YYYY-MM-DD"),
        startDate: moment().add(-110, "y").format("YYYY-MM-DD"),
    };

    public insuredPersonPeriodFromConfig: Partial<IDatePickerOptions> = {
        endDate: moment().format("YYYY-MM-DD"),
        startDate: moment().add(-100, "y").format("YYYY-MM-DD"),
    };

    public insuredPersonPeriodToConfig: Partial<IDatePickerOptions> = {
        startDate: moment().format("YYYY-MM-DD"),
    };

    public docDateGivenConfig: Partial<IDatePickerOptions> = {
        endDate: moment().format("YYYY-MM-DD"),
        startDate: moment().add(-100, "y").format("YYYY-MM-DD"),
    };
    public HasDiseaseFromDeclaration: Int;
    public insuredDocSerialLength: Int;
    public insuredDocNumberLength: Int;
    public insuredObjectDocSerialLength: Int;
    public insuredObjectDocNumberLength: Int;
    public discountCollection: IDiscountCollection[];

    get InsuredIsInsurer(): boolean {
        return this.Contract.InsuredPersonGuid && this.Contract.InsuredPersonGuid === this.Contract.InsuredObjectGuid;
    }
    set InsuredIsInsurer(value: boolean) {
        if (value) {
            this.Contract.InsuredObjectGuid = this.Contract.InsuredPersonGuid;
        } else {
            this.Contract.InsuredObjectGuid = Uuid.raw();
        }
        this.updateInsuredPersonByInsurer();
    }

    public updateDateOfBirth(): void {
        this.getFieldRepository("InsuredObjectBirthday");
        if (this.InsuredIsInsurer) {
            this.Contract.InsuredBirthday = this.Contract.InsuredObjectBirthday;
        }
    }

    public getInforamtion(targetId: Int): IElementInformation {
        /* tslint:disable max-line-length */
        const contentInformation = {
            0: [
                "- Инвалидность, установленная впервые, в результате несчастного случая (Полная или частичная утрата трудоспособности)",
                "- Временное растройство здоровья, в результате несчастного случая",
                "- Смерть в результате несчастного случая."],
            1: [
                "- Смерть в результате несчастного случая,",
                "- Смерть в результате заболеваний, впервые диагностированных в период страхования,",
                "- Смерть в результате обострения хронических заболеваний в период страхования."],
            2: [
                "- Инвалидность, установленная впервые, в результате несчастного случая",
                "- Инвалидность, установленная впервые, в результате заболеваний, впервые диагностированных в период страхования",
                "- Инвалидность, установленная впервые, в результате обострения хронических заболеваний в период страхования.",
                "- Смерть в результате несчастного случая",
                "- Смерть в результате заболеваний, впервые диагностированных в период страхования",
                "- Смерть в результате обострения хронических заболеваний в период страхования",
                "- Временное расстройство здоровья в результате несчастного случая",
                "- Временное расстройство здоровья в результате заболеваний, впервые диагностированных в период страхования",
                "- Временное расстройство здоровья в результате обострения хронических заболеваний в период страхования."],
            3: [
                "- Инвалидность (I или IIгр.), установленная впервые, в результате несчастного случая,",
                "- Инвалидность (I или IIгр.), установленная впервые, в результате заболеваний, впервые диагностированных в период страхования,",
                "- Инвалидность (I или IIгр.), установленная впервые, в результате обострения хронических заболеваний в период страхования.",
                "- Смерть в результате несчастного случая,",
                "- Смерть в результате заболеваний, впервые диагностированных в период страхования,",
                "- Смерть в результате обострения хронических заболеваний в период страхования."],
        };
        /* tslint:enable max-line-length */
        return {
            content: contentInformation[targetId - 1],
            header: "Набор рисков варьируется автоматически, в зависимости от состояния здоровья застрахованного лица",
            templateHtml: "InsuranceTargetTemplate.html",
            title: "",
        };
    }

    public $onInit(): void {
        super.$onInit();

        this.$filter = this.di<angular.FilterFactory>("$filter");
        this.notifyService = this.di<NotifyService>("notifyService");
        this.insurant = new InsurantPersonalAccidentProxy(this.Contract);
        this.discountCollection = this.getDiscountCollection();
        /* tslint:disable max-line-length */
        this.insuranceTargetInformation = {
            content:
                `<p>(Набор рисков варьируется автоматически, в зависимости от состояния здоровья застрахованного лица)</p>
                    <h4>Страхование от несчастного случая:</h4>
                <ul>
                    <li>Инвалидность, установленная впервые, в результате несчастного случая (полная или &nbsp;частичная утрата трудоспособности),</li>
                    <li>Смерть в результате несчастного случая,</li>
                    <li>Временное растройство здоровья в результате несчастного случая.</li>
                </ul>
                    <h4>Страхование на случай ухода из жизни:</h4>
                <ul>
                    <li>Смерть в результате несчастного случая,</li>
                    <li>Смерть в результате заболеваний, впервые диагностированных в период страхования,</li>
                    <li>Смерть в результате обострения хронических заболеваний в период страхования.</li>
                </ul>
                    <h4>Страхование жизни от всех непредвиденных ситуаций:</h4>
                <ul>
                    <li>Инвалидность, установленная впервые, в результате несчастного случая,</li>
                    <li>Инвалидность, установленная впервые, в результате заболеваний, впервые &nbsp;диагностированных в период страхования,</li>
                    <li>Инвалидность, установленная впервые, в результате обострения хронических &nbsp;заболеваний в период страхования.</li>
                </ul>
                <ul>
                    <li>Смерть в результате несчастного случая,</li>
                    <li>Смерть в результате заболеваний, впервые диагностированных в период страхования,</li>
                    <li>Смерть в результате обострения хронических заболеваний в период страхования.</li>
                </ul>
                <ul>
                    <li>Временное расстройство здоровья в результате несчастного случая,</li>
                    <li>Временное расстройство здоровья в результате заболеваний, впервые &nbsp;диагностированных в период страхования,</li>
                    <li>Временное расстройство здоровья в результате обострения хронических заболеваний &nbsp;в период страхования.</li>
                </ul>
                <h4>Кредитное страхование:</h4>
                <ul>
                    <li>Инвалидность (I или IIгр.), установленная впервые, в результате несчастного случая,</li>
                    <li>Инвалидность (I или IIгр.), установленная впервые, в результате заболеваний, впервые &nbsp;диагностированных в период страхования,</li>
                    <li>Инвалидность (I или IIгр.), установленная впервые, в результате обострения &nbsp;хронических заболеваний в период страхования.</li>
                    <li>Смерть в результате несчастного случая,</li>
                    <li>Смерть в результате заболеваний, впервые диагностированных в период страхования,</li>
                    <li>Смерть в результате обострения хронических заболеваний в период страхования.</li>
                </ul>`,

            title: "Цель страхования",
        };
        const signingDate = moment(this.Contract.SigningDate, "YYYY-MM-DD").startOf("day");
        const current = moment().startOf("day");
        if (this.Contract.ContractStatusId === 1 && signingDate.isBefore(current)) {
            this.notifyService.warningMessage("Внимание", "По данному договору устарела дата заключения договора. Рассчитайте договор заново для обновления даты заключения договора.");
        }

        if (!this.Contract.ContractStatusId) {
            this.Contract.SigningDate = `${moment().format("YYYY-MM-DD")}T00:00:00`;
            this.Contract.PaymentDocumentDatePay = `${moment().format("YYYY-MM-DD")}T00:00:00`;
            this.Contract.ContractFrom = `${moment(this.Contract.SigningDate).add(1, "d").format("YYYY-MM-DD")}T00:00:00`;
            this.Contract.Discount = 0;
            this.Contract.UnderwriterCoefficient = 1;
            this.changeInsuranceTerm();
        }
        /* tslint:enable max-line-length */
        this.initBeneficiaries();
        this.initWatchList();
        this.initHasDiseaseFromDeclaration();
        this.updateInsuredPersonDocParams();
        this.updateInsuredObjectDocParams();

        this.phoneValidationService.setContractRequiredFields({
            individual: [
                { description: "ФИО страхователя", field: ["InsuredLastName", "InsuredFirstName"] },
                { description: "Дата рождения страхователя", field: "InsuredBirthday" },
            ],
            legalEntity: [
                { description: "Наименование организации страхователя", field: "InsuredOrgName" },
                { description: "ИНН организации страхователя", field: "InsuredOrgINN" },
            ],
        });

    }

    public initHasDiseaseFromDeclaration(): void {
        if (!this.Contract.HasDiseaseFromDeclaration) {
            this.HasDiseaseFromDeclaration = 2;
        } else if (this.Contract.HasDiseaseFromDeclaration) {
            this.HasDiseaseFromDeclaration = 1;
        } else {
            // TODO данная ветка else видимо не работает, никогда. Нужно будет удалить и проверить
            this.HasDiseaseFromDeclaration = 0;
        }
    }

    public updateHasDiseaseFromDeclaration(): void {
        if (this.Repository.IsDeclarationRequired.Value[0].Value === false) {
            this.Contract.HasDiseaseFromDeclaration = null;
        }
    }

    public updateInsuredPersonDocParams(): void {
        const requiredDocTypeId = 12;
        /* tslint:disable-next-line:max-line-length */
        const item = this.Repository.InsuredDocType.Collection.find((element) => element.InsuredDocTypeId === this.Contract.InsuredDocTypeId);

        if (item) {
            if (item.InsuredDocTypeId === requiredDocTypeId) {
                this.insuredDocSerialLength = 4;
                this.insuredDocNumberLength = 6;
            } else {
                this.insuredDocSerialLength = 50;
                this.insuredDocNumberLength = 50;
            }
        }
    }

    public isInsuredAChild(): boolean {
        return Boolean(this.Repository.Industry.State);
    }

    public updateInsuredObjectDocParams(): void {
        const requiredDocTypeId = 12;
        const item = this.Repository.InsuredObjectDocType.Collection.find((element: IInsuredObjectDocTypeItem) => {
            return element.InsuredObjectDocTypeId === this.Contract.InsuredObjectDocTypeId;
        });

        if (item) {
            if (item.InsuredObjectDocTypeId === requiredDocTypeId) {
                this.insuredObjectDocSerialLength = 4;
                this.insuredObjectDocNumberLength = 6;
            } else {
                this.insuredObjectDocSerialLength = 50;
                this.insuredObjectDocNumberLength = 50;
            }
        }
    }

    public getPolicySerial(): string {
        const item: IPolicySerial[] = this.$filter("filterBy")(
            this.Repository.PolicySerial.Collection,
            ["PolicySerialId"],
            this.Contract.PolicySerialId,
        );
        if (item[0]) {
            return item[0].PolicySerial;
        }
        return "";
    }

    public getPaymentDocumentSerial(): string {
        const item: IPaymentDocumentSerial[] = this.$filter("filterBy")(
            this.Repository.PaymentDocumentSerial.Collection,
            ["PaymentDocumentSerialId"],
            this.Contract.PaymentDocumentSerialId,
        );
        if (item[0]) {
            return item[0].PaymentDocumentSerial;
        }
        return "";
    }

    /**
     * Функция срабатывающая при событии изменения выбранного периода страхования
     * @returns {} Ничего не возвращает, изменяет дату окончания договора
     */
    public changeInsuranceTerm(): void {
        const item = this.getInsuranceTerm();
        if (item) {
            const dateTo = moment(this.Contract.ContractFrom, "YYYY-MM-DD")
                .add(item.PersonalAccidentInsuranceTermId, "M")
                .add(-1, "d")
                .format("YYYY-MM-DD");
            this.Contract.ContractTo = `${dateTo}T00:00:00`;
        }
    }

    /**
     * Получение выбранного периода страхования
     * @returns {object} Выбранный период страхования
     */
    public getInsuranceTerm(): IInsuranceTerm | null {
        const item: IInsuranceTerm[] = this.$filter("filterBy")(
            this.Repository.InsuranceTerm.Collection,
            ["PersonalAccidentInsuranceTermId"],
            this.Contract.PersonalAccidentInsuranceTermId,
        );

        if (item[0]) {
            return item[0];
        }
        return null;
    }

    /**
     * Отключение ошибок при выбранном чекбоксе Адрес отсутствует в ФИАС
     * @param {string} имя поля адреса в Contrct.
     */
    public changeHasNotFiasInfo(fieldName: string): void {
        if (this.Validation.hasError(fieldName)) {
            this.Validation.removeError(fieldName);
        }
    }

    public getContractFromMinDate(): Day {
        return moment(this.Contract.SigningDate).startOf("day").add(1, "d").format("YYYY-MM-DD");
    }

    public getContractFromMaxDate(): Day | undefined {
        let item: IAPIRepositoryItem;
        try {
            item = this.Repository.MaxMonthsForContractFrom.Value.find((entry) => {
                return entry.FieldName === "MaxMonthsForContractFrom";
            });
        } catch (e) { /* do notning */ }
        if (!item) {
            return undefined;
        }
        const MaxMonthsForContractFrom = item.Value;
        const maxDate = moment(this.Contract.SigningDate).add(MaxMonthsForContractFrom, "M").format("YYYY-MM-DD");
        return maxDate;
    }

    public getFieldRepository(fieldName: string): angular.IPromise<void> {
        const [blockUI] = this.di(["blockUI"]);
        blockUI.start("Обновляются справочные данные");
        return this.Repository.update(fieldName, this.Contract).then(() => {
            blockUI.stop();
            this.initBeneficiaries();
            this.updateHasDiseaseFromDeclaration();
        });
    }

    public initBeneficiaries(): void {
        if (!this.Contract.Beneficiaries) {
            this.Contract.Beneficiaries = [];
        }

        angular.forEach(this.Repository.BeneficiaryContractorType.Collection, (v) => {
            const item = this.Contract.Beneficiaries.filter((type) => {
                return type.BeneficiaryContractorTypeId === v.BeneficiaryContractorTypeId;
            });
            if (item && item.length === 0) {
                const newBeneficiary = { BeneficiaryContractorTypeId: v.BeneficiaryContractorTypeId } as BeneficiaryDTO;
                this.Contract.Beneficiaries.push(newBeneficiary);
            }
        });

        for (let i = this.Contract.Beneficiaries.length - 1; i >= 0; i--) {
            const item = this.$filter("filterBy")(
                this.Repository.BeneficiaryContractorType.Collection,
                ["BeneficiaryContractorTypeId"],
                this.Contract.Beneficiaries[i].BeneficiaryContractorTypeId,
            );

            if (item.length === 0) {
                this.Contract.Beneficiaries.splice(i, 1);
            }
        }
    }

    /*
    * Если установлен checkbox "Застрахованное лицо является страхователем"
    */
    public updateInsuredPersonByInsurer(): void {
        const document = this.Repository.InsuredObjectDocType.Collection.filter((collectionItem) => {
            return collectionItem.InsuredObjectDocTypeId === this.Contract.InsuredDocTypeId;
        });

        if (this.InsuredIsInsurer && document.length > 0) {
            this.Contract.InsuredObjectLastName = this.Contract.InsuredLastName;
            this.Contract.InsuredObjectFirstName = this.Contract.InsuredFirstName;
            this.Contract.InsuredObjectMiddleName = this.Contract.InsuredMiddleName;
            if (!this.Contract.InsuredBirthday && this.Contract.InsuredObjectBirthday) {
                this.Contract.InsuredBirthday = this.Contract.InsuredObjectBirthday;
            }
            if (this.Contract.InsuredObjectBirthday !== this.Contract.InsuredBirthday) {
                this.Contract.InsuredObjectBirthday = this.Contract.InsuredBirthday;
                this.getFieldRepository("InsuredObjectBirthday");
            }
            this.Contract.InsuredObjectDocTypeId = this.Contract.InsuredDocTypeId;
            this.Contract.InsuredObjectDocSerial = this.Contract.InsuredDocSerial;
            this.Contract.InsuredObjectDocNumber = this.Contract.InsuredDocNumber;
            this.Contract.InsuredObjectDocDateGiven = this.Contract.InsuredDocDateGiven;
            this.Contract.InsuredObjectDocWhomGiven = this.Contract.InsuredDocWhomGiven;
            if (!this.Contract.InsuredPersonHasNotFiasInfo) {
                this.Contract.InsuredObjectAddressFiasId = this.Contract.InsuredPersonAddressFiasId;
                this.Contract.InsuredObjectAddressKladrId = this.Contract.InsuredPersonAddressKladrId;
            }
            this.Contract.InsuredObjectHasNotFiasInfo = this.Contract.InsuredPersonHasNotFiasInfo;
            this.Contract.InsuredObjectAddressUserDefinedAddress = this.Contract.InsuredPersonAddressUserDefinedAddress;
            this.Contract.InsuredObjectPhones = angular.copy(this.Contract.InsuredPersonPhones);
            this.Contract.InsuredObjectEmail = this.Contract.InsuredPersonEmail;
        }
    }

    public updateAnotherPersonalAccident(): void {
        if (!this.Contract.HasAnotherPersonalAccidentInsuranceContract) {
            this.Contract.AnotherContractCompanyName = null;
            this.Contract.AnotherContractAmount = null;
            this.Contract.AnotherContractProductRisks = null;
            this.Contract.AnotherContractFrom = null;
            this.Contract.AnotherContractTo = null;
        }
    }

    public updateInjury(): void {
        if (!this.Contract.HasInjury) {
            this.Contract.InjuryOrTrafficAccidentDescription = null;
        }
    }

    public isChildrenEducationalInstitution(): boolean {
        if (!this.Repository || !this.Repository.PolicySerial || !this.Repository.ChildrenEducationalInstitution) {
            return false;
        }

        let insuredDocTypeIsNotAvailableInInsuredObjectDocType = false;
        const document = this.Repository.InsuredObjectDocType.Collection.filter((collectionItem) => {
            return collectionItem.InsuredObjectDocTypeId === this.Contract.InsuredDocTypeId;
        });

        if (document && document.length === 0) {
            insuredDocTypeIsNotAvailableInInsuredObjectDocType = true;
            if (this.InsuredIsInsurer) {
                /* tslint:disable-next-line:max-line-length */
                this.notifyService.infoMessage("", "Удостоверяющий документ Страхователя не позволяет выбрать страхователя в качестве Застрахованного лица.");
            }
        }

        let isChildProgramm = false;
        const item = this.$filter("filterBy")(
            this.Repository.PolicySerial.Collection,
            ["PolicySerialId"],
            this.Contract.PolicySerialId,
        );
        if (item && item.length > 0) {
            isChildProgramm = item[0].PolicySerial.startsWith("ЛЗД");
        }
        if (
            this.Repository.ChildrenEducationalInstitution.State === 0
            || isChildProgramm
            || insuredDocTypeIsNotAvailableInInsuredObjectDocType
        ) {
            this.InsuredIsInsurer = false;
            return true;
        }
        return false;
    }

    public getDiscountCollection(): IDiscountCollection[] {
        const collection = [];
        if (
            this.Repository.PersonalAccidentDiscountRestriction
            && this.Repository.PersonalAccidentDiscountRestriction.Value
            && Array.isArray(this.Repository.PersonalAccidentDiscountRestriction.Value)
        ) {
            const startValue = this.Repository.PersonalAccidentDiscountRestriction.Value[0].Value;
            const endValue = this.Repository.PersonalAccidentDiscountRestriction.Value[1].Value;
            for (let i = startValue; i <= endValue; i++) {
                collection.push({
                    Id: i,
                    Value: i > 0 ? `${i} %` : "Без скидки",
                });
            }
        }

        return collection;
    }

    public disableListProfessionOptions(): void {
        this.Contract.IndustryId = null;
        this.Contract.ProfessionId = null;
    }

    public disableListSportsOptions(): void {
        this.Contract.ActiveRest = true;
        if (this.Contract.SportId.length > 0) {
            this.Contract.SportId.splice(0, this.Contract.SportId.length);
        }
    }

    public printDeclaration(): angular.IPromise<void> {
        const helpers = this.di<Helpers>("helpers");
        return this.Contract.$loadPrintable({
            docType: "Declaration",
            fileNamePrefix: "Декларация",
            id: 0,
        }).then((blob: UGSKBlob) => { helpers.openUrlInIframe(blob.url); });
    }

    /**
     * Функция инициализирующая дополнительные watch'и
     */
    public initWatchList(): void {
        // Подписка на события
        /* tslint:disable-next-line:max-line-length */
        this._$scope.$watch("[vm.Contract.InsuredLastName, vm.Contract.InsuredFirstName, vm.Contract.InsuredMiddleName, vm.Contract.InsuredBirthday, vm.Contract.InsuredDocTypeId, vm.Contract.InsuredDocSerial, vm.Contract.InsuredDocNumber, vm.Contract.InsuredDocDateGiven, vm.Contract.InsuredDocWhomGiven, vm.Contract.InsuredPersonAddressUserDefinedAddress, vm.Contract.InsuredPersonAddressFiasId, vm.Contract.InsuredPersonAddressKladrId, vm.Contract.InsuredPersonPhones, vm.Contract.InsuredPersonEmail, vm.Contract.InsuredPersonHasNotFiasInfo]", () => {
            this.updateInsuredPersonByInsurer();
        }, true);

        // / todo: У компоненты ugsk-multiple-selectpicker есть обработчик события ng-change.
        // / потому watch можно убрать
        // !!! Но сперва заюзать этот самый обработчик
        this._$scope.$watch("vm.Contract.SportId", () => {
            if (this.Contract.SportId && this.Contract.SportId.length > 0) {
                this.Contract.ActiveRest = true;
            }
        }, true);

        this._$scope.$watch(() => this.Contract.InsuredObjectBirthday, () => {
            this.updateDateOfBirth();
        });
        /**
         * @see http://jr.ugsk.ru/jr/browse/UPRO-2881
         * @description Это костыль для ошибки на бэке, но там это делать долго.
         */
        this._$scope.$watch(() => this.Contract.InsuredObjectHasNotFiasInfo, (newValue: boolean) => {
            if (newValue) {
                this.Contract.InsuredObjectAddressKladrId = null;
            }
        });
    }

    protected get Repository(): PersonalAccidentRepository {
        return this.Contract.getRepository();
    }
}
