import template from "./ugskCommonBody.component.html";

export const ugskCommonBodyComponent = {
    bindings: {},
    controller: class {
        constructor($transclude, $localStorage) {
            this.withDigest = $transclude.isSlotFilled("digest");
            this.localStorage = $localStorage;
        }
        static get $inject() {
            return ["$transclude", "$localStorage"];
        }
    },
    controllerAs: "vm",
    template,
    transclude: {
        body: "paneBody",
        digest: "?paneDigest",
    },
};
