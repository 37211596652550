import { DictionariesService } from "infrastructure/services/dictionaries.service";
import { ProductsJournalController } from "../../journal/journal.controller";
import { TravelingAbroadContract } from "../travelingAbroad.factory";

export class TravelingAbroadJournalController extends ProductsJournalController {
    public $onInit(): void {
        this.columns = [{
            defaultFilterOperation: "eq",
            displayText: "Программа страхования",
            getVariants: () => {
                const dictionariesService = this.di<DictionariesService>("dictionariesService");
                return dictionariesService.get("TravelingAbroadContractJournalItems/K3.GetTravelingAbroadInsurancePrograms")
                    .then((programs) => programs.map((program) => ({
                        title: program.Name,
                        value: program.Name,
                    })));
            },
            inlineFilter: true,
            name: "InsuranceProgramName",
            type: "Edm.String",
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Серия-номер полиса",
            inlineFilter: true,
            name: "PolicyNumber",
            pinned: true,
            renderer: (item) => item.PolicyFullValue,
            tooltipText: "Поиск только<br/>по номеру",
            type: "Edm.String",
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Страхователь",
            inlineFilter: true,
            name: "InsuredName",
            pinned: true,
            type: "Edm.String",
            width: 2,
        }, {
            displayText: "Направления путешествия",
            inlineFilter: true,
            name: "TravelingAbroadDestinationsName",
            renderer: this.getTravelingAbroadDestinations,
            type: "Edm.String",
        }, {
            defaultFilterOperation: "eq",
            displayText: "Дата заключения договора",
            inlineFilter: true,
            name: "SigningDate",
            ngFilter: this.dateFormatWithoutTimeZone,
            pinned: true,
            type: "Edm.DateTimeOffset",
        }, {
            displayText: "Начало срока страхования",
            inlineFilter: true,
            name: "ContractFrom",
            ngFilter: this.dateFormatWithoutTimeZone,
            type: "Edm.DateTimeOffset",
        }, {
            displayText: "Окончание срока страхования",
            inlineFilter: true,
            name: "ContractTo",
            ngFilter: this.dateFormatWithoutTimeZone,
            type: "Edm.DateTimeOffset",
        }, {
            displayText: "Страховая премия",
            inlineFilter: true,
            name: "InsurancePremium",
            ngFilter: "thousand",
            type: "Edm.Decimal",
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Филиал",
            inlineFilter: true,
            name: "EmployeeFilialName",
            pinned: true,
            type: "Edm.String",
        }, {
            displayText: "Пользователь",
            inlineFilter: true,
            name: "EmployeeName",
            type: "Edm.String",
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Канал продаж",
            inlineFilter: true,
            name: "SaleChannel",
            pinned: true,
            type: "Edm.String",
        }, {
            defaultFilterOperation: "eq",
            displayText: "Статус договора",
            getVariants: () => this.getStatusVariants(),
            inlineFilter: true,
            name: "StatusName",
            pinned: true,
            template: `
                <span
                ng-bind="item.StatusName"
                ng-class="vm.getContractStatusLabelClass(item)">
                </span>
            `,
            type: "Edm.String",
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Подразделение",
            inlineFilter: true,
            name: "EmployeeSubdivisionName",
            type: "Edm.String",
            visible: false,
        }, {
            displayText: "Адрес объекта страхования",
            name: "AddressUserDefinedAddress",
            type: "Edm.String",
            visible: false,
        }, {
            displayText: "Вид оплаты",
            name: "PaymentKindName",
            type: "Edm.String",
            visible: false,
        }, {
            displayText: "Id-договора",
            name: "Id",
            type: "Edm.Int32",
            visible: false,
        }, {
            displayText: "Серия и номер полиса",
            name: "PolicyFullValue",
            type: "Edm.String",
            visible: false,
        }];
        super.$onInit();
    }

    public getTravelingAbroadDestinations(item: TravelingAbroadContract): string {
        return String(item.TravelingAbroadDestinationsName)
            .split(";")
            .filter((i) => i)
            .map((i) => i.trim())
            .join(", ");
    }
}
