import MovableProperty from "domain/classes/movableProperty.class";
import { IFLRepository } from "infrastructure/app.factory-ts";
import moment from "moment";
import { BaseProductBodyController } from "./baseProduct.body.controller";

export class BaseIFLBodyController extends BaseProductBodyController {
    protected get Repository() {
        return this.Contract.getRepository() as IFLRepository;
    }
    public getMinYearOfMovableProperty(property: MovableProperty): string {
        if (!property.elementType) {
            const item = this.Repository.MovablePropertyType.Collection.find((type) => {
                return type.MovablePropertyTypeId === property.MovablePropertyTypeId;
            });
            if (item) {
                property.elementType = item;
            } else {
                return null;
            }
        }
        return moment().subtract(property.elementType.MovablePropertyTypeMaxValue, "Y").format("YYYY");
    }

    public getTitleOfMovableProperty(property: MovableProperty): string {
        if (!property.elementType) {
            const item = this.Repository.MovablePropertyType.Collection.find((type) => {
                return type.MovablePropertyTypeId === property.MovablePropertyTypeId;
            });
            if (item[0]) {
                property.elementType = item;
            } else {
                return null;
            }
        }
        return (property.Name)
            ? `${property.Name} (${property.elementType.MovablePropertyType}${property.IsGroup ? ", группа" : ""})`
            : "";
    }

    public getEngineeringEquipmentMinYear(): string {
        return moment()
            .subtract(
                this.Repository.EngineeringEquipmentAgeRestriction.Collection[0]
                    .EngineeringEquipmentAgeRestrictionMaxValue,
                "y",
            ).format("YYYY-MM-DD");
    }
}
