import angular, { IPromise } from "angular";
import { Helpers } from "infrastructure/app.helpers";
import IHttpService, { IRequestConfig } from "infrastructure/interfaces/IHttpService";
import { NgControllerBase } from "infrastructure/NgController";
import $ from "jquery";

export class BannersService extends NgControllerBase {
    public getList(channel: string): IPromise<Array<string | {}>> {
        const $http = this.di<IHttpService>("$http");
        const helpers = this.di<Helpers>("helpers");

        if (helpers.isEmbedMode()) {
            const $q = this.di<angular.IQService>("$q");
            return $q.resolve([]);
        }

        const url: string = `images/banners/${channel}/`;
        return $http({
            _suppressErrors: true,
            method: "GET",
            url,
        } as IRequestConfig).then((response: angular.IHttpResponse<any>) => {
            const contentType: string = response.headers("content-type").toLowerCase();
            let filesList: string[];
            if (contentType.indexOf("application/json") !== -1) {
                filesList = angular.fromJson(response.data);
            } else {
                filesList = $(response.data).find("a").map((i, item) => item.href.split("/").pop()).toArray();
            }
            if (angular.isArray(filesList)) {
                const reImgTest: RegExp = /\.(jpg|jpeg|png)$/;
                const reMedia: RegExp = /\.(xs|sm|md|lg|xl)\./;
                const banners: string[] & object[] = [];
                filesList.forEach((file: string) => {
                    if (file.match(reImgTest)) {
                        if (file.match(reMedia)) {
                            const key: string = file.replace(reMedia, ".");
                            const mediaName: string = file.match(reMedia).slice(-1).join("");
                            let bgObj: object = banners.find((b: {key: string}) => b.key === key);
                            if (!bgObj) {
                                bgObj = { key };
                                banners.push(bgObj);
                            }
                            bgObj[mediaName] = url + file;
                        } else {
                            banners.push(url + file);
                        }
                    }
                });
                return banners;
            }
            return [];
        }).catch(() => []);
    }
}
