import angular from "angular";
import { UAutoContract, UAutoRepositoryKeys } from "application/uAuto/uAuto.factory";
import { UAutoRepository } from "application/uAuto/uAuto.repository";
import { NgComponentController } from "infrastructure/NgController";
import { IRepositoryItem } from "infrastructure/Repository.class";

export class UautoAtsComponentController extends NgComponentController {
    public contract: UAutoContract;
    public Repository: UAutoRepository;

    public onInit(): void {
        this.Repository = this.contract.getRepository();
    }

    public isFieldRequired({fieldName}: {fieldName: UAutoRepositoryKeys}): boolean {
        if (!this.Repository.hasOwnProperty(fieldName)) {
            return false;
        }
        const item = (this.Repository[fieldName] as IRepositoryItem<any>).Collection[0];
        return item && item.Required;
    }
}

export default angular.module("ugsk-uauto-ats", [])
    .component("ugskUautoAts", {
        bindings: {
            contract: "<",
            onRepositoryFieldChanged: "&",
        },
        controller: UautoAtsComponentController,
        controllerAs: "vm",
        template: require("./ugskUautoAts.component.html"),
}).name;
