import { IFiscalizationInfo } from "@ugsk/payments";
import { IModalServiceInstance } from "angular-ui-bootstrap";
import ClipboardJS from "clipboard";

import { NgControllerBase } from "infrastructure/NgController";
import { NotifyService } from "infrastructure/services/notifyService";
import "./ticketLink.css";
import { FISCALIZATION_STATUSES_ENUM } from "application/components/ugsk-payments-service/types";

export class TicketLinkController extends NgControllerBase {
  static get $inject() {
    return ["$injector", "$uibModalInstance", "data", "fiscalizationStatusId"];
  }

  public constructor($injector, private $uibModalInstance: IModalServiceInstance, public data: IFiscalizationInfo, private fiscalizationStatusId: string) {
    super($injector);
  }

  public onReject(): void {
    this.$uibModalInstance.dismiss();
  }

  public $onInit(): void {
    const notifyService = this.di<NotifyService>("notifyService");
    const clipboard = new ClipboardJS("#clipboardBtn");
    clipboard.on("success", (e) => {
      notifyService.successMessage("Скопировано в буфер обмена");
      e.clearSelection();
    });
  }

  public getOperationType(): string {
    if (this.fiscalizationStatusId === FISCALIZATION_STATUSES_ENUM.RECEIPT) {
      return "Приход";
    } else if (this.fiscalizationStatusId === FISCALIZATION_STATUSES_ENUM.REFUND) {
      return "Возврат прихода";
    }

    return "";
  }

  public getSelfFormedLink(): string {
    if (this.data.ofdPayload) {
      const payloadKeysList = ["fn_number", "fiscal_document_number", "fiscal_document_attribute"];
      if (!payloadKeysList.every((key) => Boolean(this.data.ofdPayload[key]))) {
        return "";
      }

      // tslint:disable-next-line:max-line-length
      return `https://cash-ntt.kontur.ru/?fnSerialNumber=${this.data.ofdPayload.fn_number}&fiscalDocumentNumber=${this.data.ofdPayload.fiscal_document_number}&fiscalSignature=${this.data.ofdPayload.fiscal_document_attribute}`;
    }

    return "";
  }
}
