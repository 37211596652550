import { Transition } from "@uirouter/core";
import { BaseToolbarController } from "application/baseProduct.toolbar.controller";
import { capitalizeFirstLetter } from "infrastructure/app.helpers";
import IBaseViewParams from "infrastructure/interfaces/IBaseViewParams";

const PrintOptions = [
    {
        title() {
            return this.getPolicyPrintFormName("Печать полиса");
        },
        action() {
            return this.printContract();
        },
        disabled() {
            return this.isDisabledPrint();
        },
        isVisible() {
            return this.policyWithA7IsAvailable;
        },
        icon: "fa-print",
    },
    {
        action() {
            return this.openPdf(
                {
                    docType: `PolicyWithoutA7${capitalizeFirstLetter(this.product.code)}`,
                    id: this.Contract.Id,
                },
            );
        },
        disabled() {
            return this.isDisabledPrint() || !this.policyWithoutA7IsAvailable;
        },
        icon: "fa-print",
        title: "Печать полиса без А7",
    },
    {
        action() {
            return this.openPdf({
                docType: "ApplicationInsuredPersons",
                id: this.Contract.Id,
            });
        },
        disabled() {
            return this.isDisabledPrint() || (this.product.code === "safeCarAccident");
        },
        icon: "fa-print",
        title: "Cписок застрахованных лиц",
    }, "paymentAccountAll", "freeFormReceipt",
];

export class BoxAccidentToolbarController extends BaseToolbarController {
    public policyWithA7IsAvailable: boolean;
    public policyWithoutA7IsAvailable: boolean;

    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
        {
            viewType = "form",
        }: IBaseViewParams,
    ) {
        super($injector, $transition, {
            crossOptions: [
                "childAccident",
                "adultAccident",
                "familyAccident",
                "shortTermAccident",
                "safeCarAccident",
            ],
            extraOptions: [
                "manual",
                "integrationInfo",
                "annul",
            ],
            printOptions: PrintOptions,
            viewType,
        });
        this.policyWithA7IsAvailable = !this.employee.hasPermission("FrontGetPolicyWithoutA7", this.product.code);
        this.policyWithoutA7IsAvailable = this.employee.hasPermission("GetFreeFormReceipt", this.product.code);
    }
    /**
     * @override
     */
    public printContract() {
        return this.openPdf({
            docType: "Policy" + capitalizeFirstLetter(this.product.code),
            id: this.Contract.Id,
        }).then(
            () => {
                this.showNoticeNeedPrintReceiptForm();
            },
        );
    }
}
