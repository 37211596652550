import { IXLSX } from "./IXLSX";

export class ExcelReaderService {
    constructor(private $q: angular.IQService) {}

    static get $inject() {
        return ["$q"];
    }

    /**
     * @description Проверка браузера. Для работы функционала импорта необходима поддержка FileReader и Uint8Array
     * @return {Boolean} Подходит браузер или нет
     */
    public isValidBrowser(): boolean {
        return (window as any).FileReader && (window as any).Uint8Array;
    }

    /**
     * @description Чтение Excel-файла. В случае успеха - resolve данных, в противном случае пустой reject
     * @throws notifyService.errorMessage при любых ошибках: как чтения данных, так работы библиотеки XLSX
     * @param {File} Файл для чтения
     * @returns {Promise}
     */
    public readFile(file: File) {
        const deferred = this.$q.defer();
        const reader = new FileReader();

        reader.onload = (event) => {
            import(/* webpackChunkName: "xlsx" */ "xlsx").then((XLSX: IXLSX) => {
                let result = null;
                try {
                    const data = String.fromCharCode.apply(null, new Uint8Array((event.target as any).result));
                    const book = XLSX.read(btoa(data), { type: "base64" });

                    for (const sheet in book.Sheets) {
                        if (book.Sheets[sheet]) {
                            // задаём формат дат
                            for (const prop in book.Sheets[sheet]) {
                                if (
                                    book.Sheets[sheet][prop].w
                                    && book.Sheets[sheet][prop].v === parseInt(book.Sheets[sheet][prop].v, 10)
                                    && book.Sheets[sheet][prop].w.match(/\d{1,2}\/\d{1,2}\/\d{1,2}/)
                                ) {
                                    delete book.Sheets[sheet][prop].w;
                                    book.Sheets[sheet][prop].z = "yyyy-mm-dd";
                                }
                            }
                            result = XLSX.utils.sheet_to_json(book.Sheets[sheet]);
                            break;
                        }
                    }
                } catch (e) {
                    deferred.reject();
                    return;
                }
                deferred.resolve(result);
            });
        };

        reader.onerror = () => deferred.reject();
        reader.readAsArrayBuffer(file);
        return deferred.promise;
    }
}
