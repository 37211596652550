import angular from "angular";

export function BindDomDirective($compile: ng.ICompileService): ng.IDirective {
    "ngInject";

    return {
        link: (
            scope: ng.IScope,
            element: ng.IAugmentedJQuery,
            attributes: IAttributes,
        ) => {
            const newEl = angular.element(attributes.ugskBindDom);
            element.append(newEl);
            $compile(newEl)(scope);
        },
        restrict: "A",
    };
}
