import { OsagoAsyncResponseCommonError } from "infrastructure/interfaces/WebApi/OsagoAsyncResponseDTO";

export class RSAMessages {
    private messages: OsagoAsyncResponseCommonError[] = [];

    public setMessages(messages: OsagoAsyncResponseCommonError[]) {
        this.messages = Array.isArray(messages) ? messages : [];
    }

    public getMessages(): OsagoAsyncResponseCommonError[] {
        return this.messages;
    }

    public reset(): void {
        this.messages.length = 0;
    }
}
