import angular from "angular";
import template from "./ugskPasswordCheck.component.html";

interface IPasswordRule {
    pattern: RegExp;
    messagePositive: string;
    messageNegative: string;
}

export class PasswordCheckComponentController {
    public password: string;
    public rules: IPasswordRule[];

    constructor() {
        this.rules = [{
            messageNegative: "Пароль слишком короткий, минимальная длина 8 символов",
            messagePositive: "Пароль имеет достаточную длину",
            pattern: /[\d\w\#\;\,\.\%\$\@\!\(\)\-\=\+\*\`\"\№\^\&/]{8}/,
        }, {
            messageNegative: "Пароль не содержит число(а)",
            messagePositive: "Пароль содержит число(а)",
            pattern: /[0-9]/,
        }, {
            messageNegative: "Пароль не содержит специальные символ(ы) ( # ; , . % $ @ ! ( ) - = + * / )",
            messagePositive: "Пароль содержит специальный символ(ы)",
            pattern: /([\#\;\,\.\%\$\@\!\(\)\-\=\+\*/])/,
        }, {
            messageNegative: "Пароль не содержит прописные символ(ы)",
            messagePositive: "Пароль содержит прописные символ(ы)",
            pattern: /[A-Z]/,
        }, {
            messageNegative: "Пароль не содержит строчные символ(ы)",
            messagePositive: "Пароль содержит строчные символ(ы)",
            pattern: /[a-z]/,
        }];
    }

    public match(rule: IPasswordRule) {
        return rule.pattern.test(this.password);
    }
}

export default angular.module("ugsk.selfRegistration.passwordCheck", [])
    .component("ugskPasswordCheck", {
        bindings: {
            password: "<",
        },
        controller: PasswordCheckComponentController,
        controllerAs: "vm",
        template,
});
