import moment from "moment";
import { NgComponentController } from "infrastructure/NgController";
import ugskContractorModule from "application/components/ugsk-contractor/ugskContractor.component";
import template from "./ugskOwner.component.html";

export default angular.module("ugsk.components.owner", [
    ugskContractorModule,
])
    .component("ugskOwner", {
        bindings: {
            minBirthDay: "<?",
            ngDisabled: "<",
            owner: "<",
            ownerContractorTypes: "<", // TODO: fix
            ownerCountries: "<", // TODO: fix
            ownerDocumentTypes: "<",
            ownerIsInsurant: "<",
            isNotResidentDisable: "<",
        },
        controller: class extends NgComponentController {
            onInit() {
                this.maxBirthDay = moment().format();
            }
        },
        controllerAs: "vm",
        template,
    })
    .name;
