import angular from "angular";
import ugskAnotherInsuranceHistoryComponent from "application/components/ugsk-another-insurance-history/ugskAnotherInsuranceHistory.component";
import ugskEngineeringEquipmentComponent from "application/components/ugsk-engineering-equipment/ugskEngineeringEquipment.component";
import ugskMovablePropertyComponent from "application/components/ugsk-movable-property/ugskMovableProperty.component";
import "application/components/ugsk-risks/ugskRisks.component";
import ugskSublimits from "application/components/ugsk-sublimits/ugskSublimits.component";
import { Product } from "domain/classes/product.class";
import { ProductsService } from "infrastructure/services/products.service";
import { IFLJournalController } from "../journal/iflJournal.controller";
import { AssuranceApartmentToolbarController } from "./assuranceApartment-toolbar.controller";
import { AssuranceApartmentController } from "./assuranceApartment.controller";
import { AssuranceApartmentContract } from "./assuranceApartment.factory";
import template from "./body.html";
import { AssuranceApartmentJournalToolbarController } from "./journal/assuranceApartmentJournal-toolbar.controller";
import manual from "./manual_assuranceApartment.pdf";

export class AssuranceApartment extends Product {
    constructor() {
        super();
        this.code = "assuranceApartment";
        this.groupName = "Страхование имущества";
        this.productName = "Уверенное решение (квартира)";
        this.description = "Универсальное страхование квартир";
        this.insuranceRules = {
            base: "https://ugsk.ru/about/pravila/u-pro/08_47.pdf",
        };
        this.insurerCanBeLegalEntity = false;
        this.logo = "nav_assuranceApartment";
        this.manualUrl = manual;
        this.sort = 70;
        this.template = template;
        this.hasImageRepositoryAbility = true;
        this.controller = AssuranceApartmentController;
        this.toolbarController = AssuranceApartmentToolbarController;
        this.journalController = IFLJournalController;
        this.journalToolbarController = AssuranceApartmentJournalToolbarController;
        this.extraViews = {
            "contractParams@app.assuranceApartment.index": {
                template: require("./view.contractParams.html"),
            },
            "generalApartmentParameters@app.assuranceApartment.index": {
                template: require("./view.generalApartmentParameters.html"),
            },
            "structuralElements@app.assuranceApartment.index": {
                template: require("./view.structuralElements.html"),
            },
            "interior@app.assuranceApartment.index": {
                template: require("./view.interior.html"),
            },
            "civilResponsibility@app.assuranceApartment.index": {
                template: require("./view.civilResponsibility.html"),
            },
            "movableProperties@app.assuranceApartment.index": {
                template: require("./view.movableProperties.html"),
            },
            "engineeringEquipment@app.assuranceApartment.index": {
                template: require("./view.engineeringEquipment.html"),
            },
            "risks@app.assuranceApartment.index": {
                template: require("./view.risks.html"),
            },
            "sublimits@app.assuranceApartment.index": {
                template: require("./view.sublimits.html"),
            },
            "conditions@app.assuranceApartment.index": {
                template: require("./view.conditions.html"),
            },
            "inspection@app.assuranceApartment.index": {
                template: require("./view.inspection.html"),
            },
            "insurant@app.assuranceApartment.index": {
                template: require("./view.insurant.html"),
            },
            "payment@app.assuranceApartment.index": {
                template: require("./view.payment.html"),
            },
        };
    }
    get contractClass() {
        return AssuranceApartmentContract;
    }
}

angular.module("app.assuranceApartment", [
    "touchSpin",
    "ugsk.components.risks",
    ugskMovablePropertyComponent,
    ugskEngineeringEquipmentComponent,
    ugskAnotherInsuranceHistoryComponent,
    ugskSublimits,
]).run((productsService: ProductsService) => {
    productsService.register(new AssuranceApartment());
});
