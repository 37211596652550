import { CONTRACT_STATUSES, EOSAGO_POLICY_SERIAL } from "application/constants";
import { Contract } from "domain/classes/contract.class";
import { DatePeriod } from "domain/classes/datePeriod";
import UgskPhone from "domain/classes/ugsk-phone.class";
import VehicleDocument from "domain/classes/vehicleDocument.class";
import withInspectionConclusion from "domain/mixes/inspectionConclusion.mix";
import { ISetFromRepositoryFlags } from "infrastructure/interfaces/IContractResource";
import { IFactPaymentDTO } from "infrastructure/interfaces/WebApi/IFactPaymentDTO";
import InspectionConclusionDTO from "infrastructure/interfaces/WebApi/InspectionConclusionDTO";
import PaymentDTO from "infrastructure/interfaces/WebApi/PaymentDTO";
import { IAPIRepository } from "infrastructure/Repository.class";
import { DateTimeOffset, Guid, Int } from "infrastructure/types";
import { Uuid } from "lib/uuid";
import moment from "moment";
import { ICalculation, IKBMDTO, IKBMError } from "../interfaces";
import { OsagoDriver } from "../osago.factory";
import { OsagoRepository } from "./OsagoRepository";
import { PreviousContractInfo } from "application/osago/classes/PreviousContractInfo";

const asyncProcessingKey = Symbol("asyncProcessingKey");
/**
 * @see UGSK.K3.Service.DTO/OSAGO/ContractDTO.cs
 */
@withInspectionConclusion
export class OsagoContract extends Contract { // mix(Contract).with(inspectionConclusionMix) {
    public Id: number = undefined;
    public Calculation: ICalculation[] = undefined;
    public InsurancePremium = 0;
    public ContractStatusName = "";
    public VehicleType = "";
    public UserDefinedMarkModel: string = undefined;
    public FilialGuid = "";
    public PolicyPrefix = "";
    public PolicySerial = "";
    public PolicyNumber = "";
    public ContractFrom: DateTimeOffset = null;
    public ContractTo: DateTimeOffset = null;
    public PreviousContractId: Int = null;
    public PreviousContractInfo: PreviousContractInfo = null;
    public IsProlongation = false;
    public IsExternalTransition = false;
    public PaymentDocumentSerial = "";
    public PaymentKind = "";
    public PaymentVariantId: Int = null;
    public PaymentKindUniqueData = "";
    public PaymentCanBeChange = false;
    public VehicleCountryId: Int = 643;
    public DatePay: DateTimeOffset = null;
    public SigningDate: DateTimeOffset = null;
    public InsuredPhoneVerificationId: Guid = null;
    public OwnerPhoneVerificationId: Guid = null;
    public ProlongationDenialInfos: any[] = [];
    public ByTender = false; // Запрос КБМ для тендера
    public TenderDate: DateTimeOffset = null; // Дата объявления конкурса

    public InsuredId = 0;
    public OwnerId = 0;
    public VehicleId = 0;
    public KBMErrors: IKBMError[] = [];
    public Guid = Uuid.raw();
    public InsuredVID: Guid = null;
    public IsEOsago = false;
    public IsEgarant: boolean = false;
    public EgarantAgreementLink: string = "";
    public FactPayments: IFactPaymentDTO[] = [];
    public IsPayed = false;

    /* common fields */
    public ContractStatusId = 1; // Статус договора

    /* блок параметры ТС */
    public VehicleYearMade?: Int = null;
    public VehicleDocGrossVehicleWeight: Int = null; // Разрешенная максимальная масса
    public VehicleDocCountPassengerSeat: Int = null; // Количество пассажирских мест
    public VehicleDocEnginePowerHP: Int = 0; // Мощность двигателя л.с
    public VehicleDocEnginePowerKW: Int = 0; // Мощность двигателя КВт
    public VehicleDocChassisNumber: string = null;
    public UsagePurpose: string = null; // Цель использования
    public VehicleModelSpelt: string = null; // Марка ТС
    public WithTrailer  = false; // Транспортное средство может быть использовано с прицепом
    public TrailerLicensePlate: string = null; // Государственный регистрационный номер прицепа
    public HandicappedCar: boolean = false; // ТС принадлежит инвалиду
    public IsSeason = false; // Сезонное использование
    public IsNew: boolean = false; // Новое ТС
    public VehicleVIN: string = null; // VIN
    public WrittenManufacturerType: number = null; // Тип производителя ТС
    public VehicleModel = 0; // для сеовместимости с договорами до Трансдекра
    /* tslint:disable-next-line:max-line-length */
    //  TODO: поведение по обработке этого поля на бэке изменилось, отправлять null теперь нельзя - выбрасывает исключение, но только в этом продукте
    public VehicleMarkRSAId: Int = -1;
    public VehicleLicensePlate: string = null; // Государственный регистрационный знак
    public VehicleDocuments: VehicleDocument[] = []; // Документы Транспортного средства

    /*
     * Параметры ТС, если его нет в Трансдекра
    */
    public VehicleIsNotInTransdekra = false; // ТС нет в Трансдекра
    public WrittenModel: string = null;
    public WrittenMark: string = null;
    public WrittenEnginePowerHp: Int = null;
    public WrittenGrossVehicleWeight: Int = null;
    public WrittenCountPassengerSeat: Int = null;
    public VehicleTypeRSA: string = null;
    public VehicleCategoryRSA: string = null;
    /*
        **  not exists  **
    public VehicleDocumentType = null; // Тип документа
    public VehicleDocSerial = null; // Серия документа
    public VehicleDocNumber = null; // Номер документа
    public VehicleDocIssuredDate = null; // Дата выдачи документа
    */
    public VehicleRegistration: string = null; // Регистрация ТС
    public VehicleDocBodyNumber: string = null; // Кузов №
    public VehicleCheckupIsNotRequired: boolean = true; // Тех осмотр не требуется
    public VehicleCheckupDate: DateTimeOffset = null; // Дата очередного ТО
    public VehicleCheckupDocumentType = "Диагностическая карта"; // Документ, выданный по результатам ТО
    public VehicleCheckupDocumentSerial: string = null; // Серия
    public VehicleCheckupDocumentNumber: string = null; // Номер
    public CaseType: string = null;
    public Kpp: string = null;
    public Privod: string = null;
    public Fuel: string = null;
    public EngineVolume: string = null;
    public VehicleModificationRSAId: Int = null;
    public VehicleModelRSAId: Int = null;

    /* периоды использования */
    public UsagePeriods: DatePeriod[] = [];

    /* параметры договора */
    public InsuranceTerm: string = null;

    /* блок страхователь */
    public InsuredGuid: Guid = Uuid.raw();
    public InsuredIsNotResident = false; // Страхователь не резидент
    public InsuredContractorType = "физическое лицо"; // Тип страхователя
    public InsuredDocumentType = "Паспорт гражданина РФ"; // Тип документа
    public InsuredPersonDocNumber: string = null; // Номер
    public InsuredPersonDocSerial: string = null; // Серия
    public InsuredPersonDocDateGiven: DateTimeOffset = null; // Дата выдачи документа, удостоверяющего личность
    public InsuredPersonDocWhomGiven: string = null; // Кем выдан документ, удостоверяющий личность
    public InsuredPersonBirthday: DateTimeOffset = null; // Дата Рождения
    public InsuredPersonSnils: string = null;
    public InsuredPersonMiddleName: string = null; // Отчество
    public InsuredPersonFirstName: string = null; // Имя
    public InsuredPersonLastName: string = null; // Фамилия
    public InsuredPhones: UgskPhone[] = []; // Телефон
    public InsuredCommonRealAddress: string = null; // Адресс
    public InsuredCommonRealAddressFiasId: string = null;
    public InsuredCommonRealAddressKladrId: string = null;
    public InsuredCountry = "Россия"; // Страна
    public InsuredOrgINN: string = null; // ИНН организации
    public InsuredOrgName: string = null; // Наименование организации
    public InsuredPersonBirthPlace: string = null; // Место рождения (только для паспорта гражданина РФ)
    public InsuredPersonDocOrgCode: string = null; // Код подразделения (только для паспорта гражданина РФ)
    public InsuredPersonINN: string = null; // ИНН страхователя
    public InsuredAccountingDate: DateTimeOffset = null; // Дата поставки на учет в налоговый орган
    public InsuredOrgKPP: string = null;

    /* блок Собственник */
    public OwnerGuid: Guid = Uuid.raw();
    public OwnerIsNotResident = false; // Собственник не резидент
    public OwnerContractorType = "физическое лицо"; // Тип собственника
    public OwnerPersonDocSerial: string = null; // Серия
    public OwnerPersonDocNumber: string = null; // Номер
    public OwnerPersonDocWhomGiven: string = null; // Кем выдан документ удостоверяющий личность
    public OwnerPersonDocDateGiven: DateTimeOffset = null; // Дата выдачи документа удостоверяющего личность
    public OwnerPersonLastName: string = null; // Фамилия
    public OwnerPersonFirstName: string = null; // Имя
    public OwnerPersonMiddleName: string = null; // Отчество
    public OwnerPersonBirthday: DateTimeOffset = null; // Дата Рождения
    public OwnerPersonSnils: string = null;
    public OwnerCommonRealAddress: string = null; // Адресс
    public OwnerCommonRealAddressFiasId: string = null;
    public OwnerCommonRealAddressKladrId: string = null;
    public OwnerPhones: UgskPhone[] = [];
    public OwnerCountry = "Россия"; // страна
    public OwnerDocumentType = "Паспорт гражданина РФ"; // тип документа собственника
    public OwnerOrgINN: string = null; // ИНН организации
    public OwnerOrgName: string = null; // Наименование организации
    public OwnerPersonINN: string = null; // ИНН собственника
    public OwnerAccountingDate: DateTimeOffset = null; // Дата поставки на учет в налоговый орган
    public OwnerOrgKPP: string = null;

    /* блок участиники договора */
    public DriverRestriction = "Да"; // Ограниченный список лиц допущенных к управлению
    public KBMClass: string = null; // КБМ Полиса
    public PayoutCount = 0; // Количество страховых выплат
    public EmployeeName = "";
    public CuratorId = "";

    /* РСА */
    public HasBeenRSACalculated = false;
    public RSAID: string = null;
    public RSAKBMClass: string = null;
    public RSAKBMValue = 0;
    public RSAPayoutCount = 0;

    /* блок  Лица допущенные к управлению ТС , КБМ */
    //  public PersonsAdmittedVehicle = []; //  not exists
    public Drivers: OsagoDriver[] = [];

    /* данные о предыдущем договоре */
    public PreviousInsurerName: string = null;
    public PreviousPolicySerial: string = null;
    public PreviousPolicyNumber: string = null;
    public PreviousModelSpelt: string = null;

    /* ПСО */
    public InspectionIsNotRequired = false; // default value false in new contract
    public OsagoInspectionConclusionAbsenceReasonName: string = null;
    public IsTowardInspection = false;
    public InspectionConclusions: InspectionConclusionDTO[] = [];
    public HasInspectionId: boolean = false;
    public InspectionId: string = null;
    public InspectionDate: string = null;

    public OthersInformation: string = null;
    public SpecialNote: string = null; // Особые отметки
    public SellerComment: string = null; // Комментарий
    public AreViolationsExist = false; // грубые нарушения условий страхования default value false in new contract
    public InsuredCommonEmail: string = null;

    public ContractNum: string = null;
    public ContractDate: string = null;

    /* Информация по договору КАСКО */
    public KaskoContractId: number = null;
    public KaskoPolicyNumber: string = null;
    public KaskoPolicySerial: string = null;
    public KaskoProgramName: string = null;

    /* for Async */
    public [asyncProcessingKey]: boolean = false;

    public get asyncProcessing(): boolean {
        return this[asyncProcessingKey];
    }

    public set asyncProcessing(val: boolean) {
        this[asyncProcessingKey] = val;
    }

    /**
     * @description Front only property
     */
    public ContractCategory = "";
    public isEOSAGO(): boolean {
        return this.PolicySerial === EOSAGO_POLICY_SERIAL;
    }
    public isDriversListLimited(): boolean {
        return this.DriverRestriction && this.DriverRestriction.toLowerCase() === "да";
    }
    public isKBMCalculationNeeded(): boolean {
        return (
            this.Drivers.length > 0 &&
            this.Id &&
            this.getStatus() === 1 &&
            !this.HasBeenRSACalculated
        );
    }
    public canAddMoreDrivers(): boolean {
        return this.Drivers.length < 10;
    }
    public isOwnerADriver(): boolean {
        return this.Drivers.some((driver) => driver.guid === this.OwnerGuid);
    }
    public isInsurantADriver(): boolean {
        return this.Drivers.some((driver) => driver.guid === this.InsuredGuid);
    }
    public isSentToRSA(): boolean {
        return this.getStatus() === 7;
    }
    public isAcceptedByRSA(): boolean {
        return this.getStatus() === 8;
    }
    public isSentToAccount(): boolean {
        return this.getStatus() === 9;
    }
    public isAccounted(): boolean {
        return this.getStatus() === 10;
    }
    public isReadyToSign(): boolean {
        return this.getStatus() === 11;
    }
    public isReadyToProlongate(): boolean {
        return this.isSigned() || this.isSentToAccount() || this.isAccounted();
    }
    public isSentExternalSystem(): boolean {
        return this.getStatus() === 13;
    }
    public isCanceled(): boolean {
        return this.getStatus() === CONTRACT_STATUSES.CANCELED;
    }
    public isLocked(): boolean {
        return super.isLocked() ||
        this.isCanceled() ||
        this.isSentToRSA() ||
        this.isAcceptedByRSA() ||
        this.isSentToAccount() ||
        this.isAccounted() ||
        this.isReadyToSign() ||
        this.isSentExternalSystem() ||
        this.isReturnedForRevision()
        || this.asyncProcessing;
    }
    public canBePaid(): boolean {
        return super.canBePaid() ||
                this.isSentToRSA() ||
                this.isAcceptedByRSA() ||
                this.isSentToAccount() ||
                this.isAccounted() ||
                this.isSentExternalSystem();
    }
    //  @override
    public setFromRepository(repository: IAPIRepository[], flags: ISetFromRepositoryFlags = {}): void {
        /* tslint:disable-next-line:max-line-length */
        //  http://bitbucket.ugsk.ru/projects/UPRO/repos/general/browse/UGSK.K3.Front/Controllers/OSAGOController.cs?at=refs%2Ftags%2Fv2.34.8h2#147
        const storedInsuredCommonRealAddress = this.InsuredCommonRealAddress;
        const storedOwnerCommonReadlAddress = this.OwnerCommonRealAddress;
        const storedInsuredDocumentType = this.InsuredDocumentType;
        const storedOwnerDocumentType = this.OwnerDocumentType;
        const storedVehicleModificationRSAId = this.VehicleModificationRSAId;
        const storedDrivers = this.Drivers;

        const ret = super.setFromRepository(repository, flags);
        if (flags.cross) {
            this.VehicleModificationRSAId = storedVehicleModificationRSAId || this.VehicleModificationRSAId;

            this.InsuredCommonRealAddress = storedInsuredCommonRealAddress || this.InsuredCommonRealAddress;
            this.OwnerCommonRealAddress = storedOwnerCommonReadlAddress || this.OwnerCommonRealAddress;

            this.InsuredDocumentType = storedInsuredDocumentType || this.InsuredDocumentType;
            this.OwnerDocumentType = storedOwnerDocumentType || this.OwnerDocumentType;
            if (Array.isArray(storedDrivers) && storedDrivers.length > 0) {
                storedDrivers.forEach((storedDriver) => {
                    const driver = this.Drivers.find((item) => item.getHash() === storedDriver.getHash());
                    if (driver) {
                        driver.DriverLicenseDocTypeId = storedDriver.DriverLicenseDocTypeId;
                    }
                });
            }
        }
        return ret;
    }

    public patchWithRSAResponse(data: IKBMDTO): void {
        // из UGSK.K3.Front.Helpers.KBMHelper.UpdateKBMFromSession
        this.HasBeenRSACalculated = true;
        this.RSAID = data.RSAID;
        this.KBMClass = data.KBMClass;
        this.PayoutCount = data.PayoutCount;
        this.RSAKBMClass = data.RSAKBMClass;
        this.RSAKBMValue = data.RSAKBMValue;
        this.RSAPayoutCount = data.RSAPayoutCount;
        /* tslint:disable-next-line:max-line-length */
        // @todo Этот хак исправляет ситуацию, когда нужно указать вручную данные, чтобы они не перезатирались запросом КБМ при каждом расчете
        this.VehicleCheckupDate = this.VehicleCheckupDate || data.VehicleCheckupDate;
        this.VehicleCheckupDocumentNumber = this.VehicleCheckupDocumentNumber || data.VehicleCheckupDocumentNumber;
        this.VehicleCheckupDocumentSerial = this.VehicleCheckupDocumentSerial || data.VehicleCheckupDocumentSerial;

        if (data.PreviousInsurerName) {
            this.PreviousInsurerName = data.PreviousInsurerName;
            this.PreviousPolicySerial = data.PreviousPolicySerial;
            this.PreviousPolicyNumber = data.PreviousPolicyNumber;
        }
        (data.Drivers || []).forEach((rsaDriver) => {
            const contractDriver = this.Drivers.find((item) => {
                /* tslint:disable max-line-length */
                return (
                    String(item.licenseNumber || "").toLowerCase() === String(rsaDriver.LicenseNumber || "").toLowerCase() &&
                    String(item.licenseSerial || "").toLowerCase() === String(rsaDriver.LicenseSerial || "").toLowerCase()
                );
                /* tslint:enable max-line-length */
            });
            if (contractDriver) {
                contractDriver.KBMClass = rsaDriver.KBMClass;
                contractDriver.PayoutCount = rsaDriver.PayoutCount;
                contractDriver.KBMErrors = rsaDriver.KBMErrors;
                contractDriver.RSAKBMClass = rsaDriver.RSAKBMClass;
                contractDriver.RSAKBMValue = rsaDriver.RSAKBMValue;
                contractDriver.RSAPayoutCount = rsaDriver.RSAPayoutCount;
            }
        });
    }
    /**
     * Функционал разблокировки договора отключен навсегда.
     * Переопредление необходимо для отсутствия возможности вызвать метод $unlock родителя.
     * @override
     */
    public $unlock(): angular.IPromise<void> {
        throw new Error('Нет возможности разблокировать договор "ОСАГО"');
    }
    public $signInExternalService(payload): angular.IPromise<void>  {
        return this.getResourceProvider().signInExternalService(payload).$promise.then(() => {
            this.setStatus(11);
        });
    }
    public eGarantSign(): angular.IPromise<void> {
        return this.getResourceProvider().signEGarant({Id: this.Id}).$promise.then(() => {
            this.setStatus(CONTRACT_STATUSES.PREPARED_FOR_SIGNING);
        });
    }
    public resetCacheKBM() {
        return this.getResourceProvider().resetCacheKBM(this).$promise;
    }
    public isInsurantANaturalPerson(): boolean {
        return this.InsuredContractorType === "физическое лицо";
    }
    public isOwnerANaturalPerson(): boolean {
        return this.OwnerContractorType === "физическое лицо";
    }
    public isOwnerALegalEntity(): boolean {
        return this.OwnerContractorType === "юридическое лицо";
    }
    public isInsurantALegalEntity(): boolean {
        return this.InsuredContractorType === "юридическое лицо";
    }
    public getInsurantFields(): string[] {
        if (this.isInsurantANaturalPerson()) {
            return [
                "InsuredPersonFirstName",
                "InsuredPersonLastName",
                "InsuredPersonBirthday",
            ];
        } else if (this.isInsurantALegalEntity()) {
            return [
                "InsuredOrgINN",
                "InsuredOrgName",
            ];
        }
        throw new Error("unknown insurant type");
    }
    public getOwnerFields(): string[] {
        if (this.isOwnerANaturalPerson()) {
            return [
                "OwnerPersonFirstName",
                "OwnerPersonLastName",
                "OwnerPersonINN",
            ];
        } else if (this.isOwnerALegalEntity()) {
            return [
                "OwnerOrgINN",
                "OwnerOrgName",
            ];
        }
        throw new Error("unknown owner type");
    }
    public getHash(): string {
        let fields = [
            this.OwnerContractorType,
            this.VehicleModelSpelt,
            this.DriverRestriction,
            this.VehicleVIN,
            this.VehicleLicensePlate,
            this.VehicleDocChassisNumber,
            this.VehicleDocBodyNumber,
        ];
        if (this.OwnerContractorType === "физическое лицо") {
            fields = [
                ...fields,
                this.OwnerPersonLastName,
                this.OwnerPersonFirstName,
                this.OwnerPersonMiddleName,
                this.OwnerPersonINN,
                moment(this.OwnerPersonBirthday, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD"),
            ];
        } else if (this.OwnerIsNotResident) {
            fields = [
                ...fields,
                this.OwnerOrgName,
            ];
        } else {
            fields = [
                ...fields,
                this.OwnerOrgINN,
            ];
        }
        fields = [
            ...fields,
            ...this.Drivers.map((driver) => driver.getHash()),
        ];
        return fields.join(":");
    }
    /**
     * @override
     */
    public getPolicySerial(): string {
        return this.PolicySerial || "";
    }
    /**
     * Костыль для совместимости со старым ОСАГО. Решает проблему с появлением/исчезновением
     * телефонных номеров в компоненте ugskPhones. На новом фронте {Insured,Owner}CommonPhone должен быть равен null,
     * но он всегда возвращается беком.
     * @todo убрать watcher после отказа от старого фронте
     */
    get InsuredCommonPhone() {
        return null;
    }
    set InsuredCommonPhone(val) {}
    get OwnerCommonPhone() {
        return null;
    }
    set OwnerCommonPhone(val) {}
    static get implementedTypes(): string[] {
        return [
            "#UGSK.K3.Product.OSAGO.Storage.Tools.OsagoContractJournalItem",
        ];
    }
    public async loadContractCategory()  {
        if (this.Id) {
            const data = await this.getResourceProvider().getOsagoContractCategory({
                id: this.Id,
            }).$promise;
            this.ContractCategory = data.category;
        }
    }
    // @override
    public getRepository(): OsagoRepository {
        return super.getRepository() as OsagoRepository;
    }
}
