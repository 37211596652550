import { IModalInstanceService } from "angular-ui-bootstrap";
import IPreviousContractRecord from "infrastructure/interfaces/IPreviousContractRecord";
import IProlongableContract from "infrastructure/interfaces/IProlongableContract";
import { Int } from "infrastructure/types";

export class UgskPreviousContractModalController {
    public selectedContract: IPreviousContractRecord<IProlongableContract>;
    constructor(
        private $uibModalInstance: IModalInstanceService,
        public previousPolicy: Array<IPreviousContractRecord<IProlongableContract>>,
    ) { }

    static get $inject() {
        return ["$uibModalInstance", "previousPolicyData"];
    }

    public close(): void {
        this.$uibModalInstance.dismiss();
    }

    public setClickedRow(item: IPreviousContractRecord): void {
        this.selectedContract = item;
    }

    public isSelected(id: Int): boolean {
        return (this.selectedContract && this.selectedContract.Contract.Id === id);
    }

    public copy(): void {
        this.$uibModalInstance.close(this.selectedContract);
    }
}
