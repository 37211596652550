import angular from "angular";
import { ProductsJournalController } from "application/journal/journal.controller";
import { Contract } from "domain/classes/contract.class";
import moment from "moment";
import { IAccidentInsuranceProgramResource } from "./journal.resource";

function DateZoneFormatter(field: string) {
    return (item: Contract): string => {
        const from = item[field];
        const parsedDate = moment(from).parseZone();
        return parsedDate.format("DD.MM.YYYY HH:mm Z");
    };
}

export class BoxAccidentJournalController extends ProductsJournalController {
    public $onInit(): void {
        const [currentProduct] = this.resolve(["currentProduct"]);
        const productCode = currentProduct.code;
        this.columns = [{
            class: () => "label label-plain",
            defaultFilterOperation: "eq",
            displayText: "Программа страхования",
            getVariants: () => {
                const [
                    boxAccidentJournalInsuranceProgramsService,
                ] = this.di([
                    "boxAccidentJournalInsuranceProgramsService",
                ]);
                const $q = this.di<angular.IQService>("$q");
                const journalResource: IAccidentInsuranceProgramResource =
                    boxAccidentJournalInsuranceProgramsService(productCode);

                return journalResource.programs().$promise.then((data) => {
                    const insurancePrograms = data.value.map((program) => ({
                        title: program.Name,
                        value: program.Name,
                    }));
                    return $q.resolve(insurancePrograms);
                });
            },
            inlineFilter: true,
            name: "InsuranceProgramName",
            type: "Edm.String",
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Серия-номер полиса",
            inlineFilter: true,
            name: "PolicyNumber",
            pinned: true,
            renderer: (item) => item.PolicyFullValue,
            tooltipText: "Поиск только<br/>по номеру",
            type: "Edm.String",
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Страхователь",
            inlineFilter: true,
            name: "InsuredName",
            pinned: true,
            type: "Edm.String",
        }, {
            defaultFilterOperation: "eq",
            displayText: "Дата заключения договора",
            inlineFilter: true,
            name: "SigningDate",
            pinned: true,
            renderer: DateZoneFormatter("SigningDate"),
            type: "Edm.DateTimeOffset",
        }, {
            displayText: "Начало срока страхования",
            inlineFilter: true,
            name: "ContractFrom",
            renderer: DateZoneFormatter("ContractFrom"),
            type: "Edm.DateTimeOffset",
        }, {
            displayText: "Окончание срока страхования",
            inlineFilter: true,
            name: "ContractTo",
            renderer: DateZoneFormatter("ContractTo"),
            type: "Edm.DateTimeOffset",
        }, {
            displayText: "Страховая премия",
            inlineFilter: true,
            name: "InsurancePremium",
            ngFilter: "thousand",
            type: "Edm.Int32",
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Филиал",
            inlineFilter: true,
            name: "EmployeeFilialName",
            pinned: true,
            type: "Edm.String",
        }, {
            displayText: "Пользователь",
            inlineFilter: true,
            name: "EmployeeName",
            type: "Edm.String",
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Канал продаж",
            inlineFilter: true,
            name: "SaleChannel",
            pinned: true,
            type: "Edm.String",
        }, {
            defaultFilterOperation: "eq",
            displayText: "Статус договора",
            getVariants: () => this.getStatusVariants(),
            inlineFilter: true,
            name: "StatusName",
            pinned: true,
            template: `
                <span
                    ng-bind="item.StatusName"
                    ng-class="vm.getContractStatusLabelClass(item)">
                </span>
            `,
            type: "Edm.String",
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Подразделение",
            inlineFilter: true,
            name: "EmployeeSubdivisionName",
            type: "Edm.String",
            visible: false,
        }, {
            displayText: "Id-договора",
            name: "Id",
            type: "Edm.Int32",
            visible: false,
        }, {
            displayText: "Серия и номер полиса",
            name: "PolicyFullValue",
            type: "Edm.String",
            visible: false,
        }];
        super.$onInit();
    }
}
