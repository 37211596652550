import { NgTableParams } from "ng-table";

import { NgControllerBase } from "infrastructure/NgController";
import { JournalOrderType } from "./types";

//  TODO: Навести порядок/распределить методы между `JournalController` и `ProductsJournalController`
export class BaseJournalController extends NgControllerBase {
    public tableParams: NgTableParams<any>;
    public order: JournalOrderType;
    public $onInit() {
        this.order = {
            by: "Id",
            direction: "desc",
        };
    }

    public applyFilter() {
        const $timeout = this.di<angular.ITimeoutService>("$timeout");
        $timeout(() => {
            this.tableParams.page(1).reload();
        });
    }
}
