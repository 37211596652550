import angular from "angular";
import { UAutoRepository } from "application/uAuto/uAuto.repository";
import { NgComponentController } from "infrastructure/NgController";
import { OptionalEquipment, UAutoContract } from "../../uAuto.factory";

export class UautoAdditionalRisksComponentController extends NgComponentController {
    public contract: UAutoContract;
    public Repository: UAutoRepository;

    public onInit(): void {
        this.Repository = this.contract.getRepository();
        this.watchForOptionalEquipments();
    }

    public watchForOptionalEquipments(): void {
        this.$scope.$watch(() => {
            if (this.contract.OptionalEquipments.length) {
                return this.contract.OptionalEquipments.map((item) => {
                    return item.getAllAmount();
                }).reduce((accumulator, currentValue) => accumulator + currentValue);
            }
        }, (newVal, oldVal) => {
            if (newVal !== oldVal) {
                this.contract.OptionalEquipmentAmount = newVal;
            }
        });
    }

    public addOptionalEquipment(): void {
        this.contract.OptionalEquipments.push(new OptionalEquipment());
    }

    public removeOptionalEquipment(index: number): void {
        this.contract.OptionalEquipments.splice(index, 1);
        if (!this.contract.OptionalEquipments.length) {
            this.clearOptionalEquipmentAmount();
        }
    }

    public isDisabledOptionalEquipmentAmount(): boolean {
        return Boolean(this.contract.OptionalEquipments.length);
    }

    public clearOptionalEquipmentAmount(): void {
        this.contract.OptionalEquipmentAmount = 0;
    }
}

export default angular.module("ugsk-uauto-additional-risks", [])
    .component("ugskUautoAdditionalRisks", {
        bindings: {
            contract: "<",
        },
        controller: UautoAdditionalRisksComponentController,
        controllerAs: "vm",
        template: require("./ugskUautoAdditionalRisks.component.html"),
    }).name;
