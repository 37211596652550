import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { NotifyService } from "infrastructure/services/notifyService";

export default {
    action(context) {
        const notifyService = context.di<NotifyService>("notifyService");
        return context.Contract.$unlock().then(() => {
            notifyService.successMessage("", "Договор успешно разблокирован");
        });
    },
    disabled(context) {
        const hasPermission = context.employee.hasPermission("Unlock", context.product.code);
        return !(context.Contract.isLocked() || context.Contract.isApproved() && hasPermission);
    },
    icon: "fa-unlock-alt",
    title: "Разблокировать",
} as IExtraOptionPrototype;
