import angular from "angular";
import ugskDriverComponent from "application/components/ugsk-driver/ugskDriver.component";
import { IDatePickerOptions } from "infrastructure/interfaces/IDatePickerOptions";
import { NgComponentController } from "infrastructure/NgController";
import moment from "moment";
import { GenderAgeExperiences } from "../../uAuto.factory";
import template from "./ugskUautoDriver.component.html";

class UgskUautoDriver extends NgComponentController {
  public datepickerConfig: Partial<IDatePickerOptions>;
  public ngModel: GenderAgeExperiences;
  public minBirthday: string;
  public licenseDocumentType: any;

  public onInit(): void {
    this.setLicenseDocumentType();
    this.minBirthday = moment().subtract(100, "years").format("YYYY-MM-DD");
    this.datepickerConfig = {
      endDate: moment().format("YYYY-MM-DD"),
      outFormat: "YYYY-MM-DDT00:00:00",
      startDate: moment()
        .subtract(100, "year")
        .format("YYYY-MM-DD"),
    };
  }

  public onChanges(): void {
    this.setLicenseDocumentType();
  }

  private setLicenseDocumentType(): void {
    if (this.ngModel.licenseDocumentType === null) {
      const type = this.licenseDocumentType.Collection.find(
        (type) =>
          type.LicenseDocumentTypeId === this.licenseDocumentType.DefaultValue,
      );
      if (type) {
        this.ngModel.licenseDocumentType = type.LicenseDocumentType;
      } else {
        this.ngModel.licenseDocumentType = "Водительское удостоверение РФ";
      }
    }
  }

  get hasPreviousDriverLicense() {
    if (
      this.ngModel.previousLicenseIssueDate &&
      this.ngModel.previousLicenseLastName &&
      this.ngModel.previousLicenseNumber &&
      this.ngModel.previousLicenseSerial
    ) {
      this.ngModel.hasPreviousDriverLicense = true;
    }
    return this.ngModel.hasPreviousDriverLicense;
  }

  set hasPreviousDriverLicense(value: boolean) {
    this.ngModel.hasPreviousDriverLicense = value;
    if (value === false) {
      this.ngModel.previousLicenseIssueDate = null;
      this.ngModel.previousLicenseLastName = null;
      this.ngModel.previousLicenseNumber = null;
      this.ngModel.previousLicenseSerial = null;
    }
  }
}

export default angular
  .module("app.uauto.components.driver", [ugskDriverComponent])
  .component("ugskUautoDriver", {
    bindings: {
      isEditable: "<",
      isLegal: "<",
      isReturnedForRevision: "<",
      licenseDocumentType: "<",
      ngDisabled: "<",
      ngModel: "<",
    },
    controller: UgskUautoDriver,
    controllerAs: "vm",
    template,
  }).name;
