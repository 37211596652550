import { RELIABLE_DRIVE_PRODUCT_NAME } from "application/constants";

/**
 * Пока сервис практически пуст. Но при появлении других связанных продуктов
 * на него будет сделана большая ставка.
 */
export default class RelatedProductService {
    public productsList: Array<{ name: string, value: string}> = [{
        name: "Добавить",
        value: null,
    }, {
        name: RELIABLE_DRIVE_PRODUCT_NAME,
        value: RELIABLE_DRIVE_PRODUCT_NAME,
    }];

    private selectedProduct: string = null;

    public selectProduct(productValue: string | null): void {
        this.selectedProduct = productValue;
    }

    public getSelectedProduct(): string {
        return this.selectedProduct;
    }
}
