import { BaseToolbarController } from "application/baseProduct.toolbar.controller";
import { BoxVHIToolbarController } from "application/boxVHI/boxVHI-toolbar.controller";
import { UAutoContract } from "application/uAuto/uAuto.factory";
import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";

export default {
  action(context): void {
    const product = context.product;
    const rulesUrl = product.getInsuranceRulesUrl(...this.getProductInfo(context));

    if (rulesUrl) {
      window.open(rulesUrl);
    }
  },
  disabled(context): boolean {
    const product = context.product;
    return !Boolean(product.getInsuranceRulesUrl(...this.getProductInfo(context)));
  },
  getProductInfo(context: BaseToolbarController | BoxVHIToolbarController) {
    // в базовом контракте этого поля нет - есть только в продуктвых.
    // TODO: если вдруг типизируем в базовом догвооре - исправить any на нужный тип
    const insuranceProgram = (context.Contract as any).InsuranceProgram;
    const subProduct = "getCurrentSubProduct" in context ? context.getCurrentSubProduct() : null;

    return [insuranceProgram, subProduct];
  },
  icon: "fa-download",
  title: "Сохранить Правила страхования",
  view: "form",
} as IExtraOptionPrototype;
