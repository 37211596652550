import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { OsagoToolbarController } from "application/osago/osago-toolbar.controller";
import { Helpers } from "infrastructure/app.helpers";
import { ISendOsagoPolicyService } from "application/osago/types";
import angular from "angular";

export default {
    action(context: OsagoToolbarController): void {
        const helpers = context.di<Helpers>("helpers");
        helpers
            .confirm({
                text: "Полис будет отправлен на электронный адрес страхователя, указанный в договоре. Отправить полис?",
                title: "Отправка полиса на email",
            })
            .then(() => {
                const sendService: ISendOsagoPolicyService = context.di("sendOsagoPolicyService");

                sendService.send({ id: context.Contract.Id }).$promise.then(angular.noop);
            });
    },
    disabled(context: OsagoToolbarController): boolean {
        return context.isDisabledPrint();
    },
    icon: "fa-send",
    title: "Отправить полис ОСАГО",
    // view: "form",
} as IExtraOptionPrototype;
