import angular from "angular";

export function InputMaskDirective(): ng.IDirective {
    "ngInject";

    return {
        link: (scope, element, attributes: IAttributes, controller) => {
            let hasMask = false;
            const initMask = () => {
                const inputMask = attributes.inputMask;
                if (inputMask) {
                    const placeHolderChar = attributes.inputMaskPlaceholderChar;
                    let pChar = "_";
                    if (angular.isString(placeHolderChar)) {
                        pChar = placeHolderChar;
                        if (placeHolderChar === "space") {
                            pChar = " ";
                        }
                    }
                    element.mask(inputMask);
                    element.attr("placeholder", inputMask.replace(/[0-9\,aA-zZ]/g, pChar));
                    hasMask = true;
                } else {
                    element.attr("placeholder", "");
                    hasMask = false;
                    return element.unmask();
                }
            };

            controller.$parsers.unshift((value: string) => (hasMask) ? element.masked(value) : value);
            controller.$formatters.unshift((value: string) => (hasMask) ? element.masked(value) : value);
            scope.$watch(() => attributes.inputMask, initMask);
            initMask();
        },
        require: "^ngModel",
        restrict: "A",
    };
}
