import { StateService, Transition } from "@uirouter/core";
import angular from "angular";
import { capitalizeFirstLetter, Helpers } from "infrastructure/app.helpers";
import IBlockUI from "infrastructure/interfaces/IBlockUI";
import IScope from "infrastructure/interfaces/IScope";
import Filial from "infrastructure/interfaces/WebApi/Filial";
import { NgController } from "infrastructure/NgController";
import { Employee } from "infrastructure/services/employee.service";
import { StatusService } from "infrastructure/services/status.service";
import { Day, Int } from "infrastructure/types";
import moment from "moment";
import "./dashboard.style.css";
import {
    ChartType, IChart, IChartLineConfig, IChartsList, IDashboardChartDataService,
    IDashboardContractsTableParams, IDashboardContractsTableParamsFactory,
    IEmployeeAllowedCases, ILastUserContractDTO, IPlot, IPosition,
} from "./interfaces";

/**
 * Внимание! График уровня пролонгации отключен во вьюшке `body.html` по причине временного отстуствия
 * возможности его корректно расчитать
 */

interface IProductItem {
    id: string[];
    title: string;
}

export class DashboardBodyController extends NgController {
    public blockUI: IBlockUI;
    public filials: Filial[];
    public availableLossCategories: string[] = ["1", "2", "3", "4", "A", "A1", null];
    public chartData: IDashboardChartDataService;
    public selectedLastContract: Int = 0;
    public selectedProlongContract: Int = 0;
    public canGetSaleLimits: boolean;
    public canSetSaleLimits: boolean;
    public charts: IChartsList = {
        boxAccident: {
            class: "label-box-accident",
            color: "#ff3333",
            label: "Продукты НС",
            /* tslint:disable-next-line:max-line-length */
            products: ["safeCarAccident", "childAccident", "adultAccident", "familyAccident", "shortTermAccident", "boxAccident"],
        },
        boxVHI: {
            class: "label-vhi",
            color: "#639",
            label: "Продукты МС",
            products: ["boxVHI"],
        },
        ifl: {
            class: "label-primary",
            color: "#1ab394",
            label: "Продукты ИФЛ",
            products: ["snugHome", "express", "myChoice", "constructorHome", "assuranceApartment", "assuranceHome"],
        },
        osago: {
            class: "label-warning",
            color: "#f8ac59",
            label: "ОСАГО",
            products: ["osago"],
        },
        personalAccident: {
            class: "label-personalAccident",
            color: "#CA5B77",
            label: "Личная защита",
            products: ["personalAccident"],
        },
        travelingAbroad: {
            class: "label-traveling-abroad",
            color: "#e6651d",
            label: "Путешествие",
            products: ["travelingAbroad"],
        },
        uAuto: {
            class: "label-success",
            color: "#1c84c6",
            label: "КАСКО",
            products: ["uAuto"],
        },
    };
    public flotOptions = {
        crosshair: {
            mode: "x",
        },
        grid: {
            autoHighlight: true,
            borderWidth: 0,
            hoverable: true,
        },
        legend: {
            labelBoxBorderColor: "#000000",
            position: "nw",
        },
        series: {
            lines: {
                show: true,
            },
            points: {
                radius: 2,
                show: true,
            },
        },
        xaxis: {
            color: "#d5d5d5",
            minTickSize: [],
            mode: "time",
            /* tslint:disable-next-line:max-line-length */
            monthNames: ["январь", "февраль", "март", "апрель", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"],
            tickLength: 0,
            timeformat: "",
        },
        yaxis: {
            min: 0,
            tickDecimals: 0,
        },
    };
    public prolongDraftProjects: IDashboardContractsTableParams;
    public prolongContracts: IDashboardContractsTableParams;
    public lastContracts: IDashboardContractsTableParams;
    public products: IProductItem[];
    public sellerChartType: ChartType = null;
    public plotInstance: IPlot;
    public helpers: Helpers;
    public statusService: StatusService = null;
    public flotData: IChartLineConfig[];
    public flotDatasetWidth = "825px";
    private $window: angular.IWindowService;

    constructor(
        $injector: angular.auto.IInjectorService,
        $transition$: Transition,
        params: any,
        $scope: IScope,
    ) {
        super($injector, $transition$, params, $scope);
        this.filials = this.resolve<Filial[]>("filials");
        this.chartData = this.di<IDashboardChartDataService>("dashboardChartDataService");
        this.helpers = this.di<Helpers>("helpers");
        this.statusService = this.di<StatusService>("statusService");
        this.$window = this.di("$window");
        this.blockUI = this.di<IBlockUI>("blockUI");
        const employeeAllowedCases = this.resolve<IEmployeeAllowedCases>("employeeAllowedCases");
        this.canGetSaleLimits = employeeAllowedCases.GetLimit;
        this.canSetSaleLimits = employeeAllowedCases.SetLimit;
        /* tslint:disable-next-line:max-line-length */
        const DashboardContractsTableParams = this.di<IDashboardContractsTableParamsFactory>("DashboardContractsTableParams");

        this.prolongDraftProjects = new DashboardContractsTableParams({
            baseFilter: this.getProlongDraftProjectsFilter(),
            blockName: "prolongDraftProjectsBlock",
            order: "ContractTo asc",
        });

        this.prolongContracts = new DashboardContractsTableParams({
            baseFilter: `
                ((ContractStatusId eq 2) or (ContractStatusId ge 7 and ContractStatusId le 10))
                and (NextContractStatusId ne 2)
            `,
            blockName: "prolongContractsBlock",
            order: "ContractTo asc",
        });

        // тайпинги обманывают, поэтому хак с any
        (this.prolongContracts.parameters() as any).filter().contractToFrom
            = this.getProlongContractsContractToMinDate();
        (this.prolongContracts.parameters() as any).filter().contractToTo
            = this.getProlongContractsContractToMaxDate();

        this.lastContracts = new DashboardContractsTableParams({
            blockName: "lastContractsBlock",
            order: "SigningDate desc",
        });
        this.products = this.getProductsFilterValues();
        this.plotCallback = this.plotCallback.bind(this);
    }

    public $onInit(): void {
        this.initChartData("today");

        this._$scope.$watch(() => {
            const element = this.$window.document.getElementById("flot-dataset");
            return element ? element.offsetWidth : null;
        }, (newVal, oldVal) => {
            if (newVal !== oldVal) {
                this.flotDatasetWidth = `${newVal - 30}px`;
            }

            if (newVal === null) {
                return "825px";
            }
        });
    }

    public getProlongContractsContractToMinDate(): string {
        return moment().subtract(60, "days").format("YYYY-MM-DD");
    }

    public getProlongContractsContractToMaxDate(): string {
        return moment().add(60, "days").format("YYYY-MM-DD");
    }

    public resetProlongContractFilter(): void {
        (this.prolongContracts.parameters() as any).filter().product = null;
        (this.prolongContracts.parameters() as any).filter().contractToFrom
            = this.getProlongContractsContractToMinDate();
        (this.prolongContracts.parameters() as any).filter().contractToTo
            = this.getProlongContractsContractToMaxDate();
        (this.prolongContracts.parameters() as any).clearItems();
    }

    public getProlongDraftProjectsFilter(): string {
        const employee = this.resolve<Employee>("employee");
        const filter = [
            `IsForProlongation eq true and (
                ContractStatusId eq 1 or ContractStatusId eq 3 or ContractStatusId eq 6
            )`,
        ];

        if (employee.info.Role === "Партнер") {
            filter.push(`(${this.availableLossCategories.map((category) => {
                if (category) {
                    return `LossCategoryName eq '${category}'`;
                } else {
                    return "LossCategoryName eq null";
                }
            }).join(" or ")})`);
        }

        return filter.join(" and ");
    }

    public getProductsFilterValues(): IProductItem[] {
        return [{
            id: null,
            title: "Все",
        }, ...Object.values(this.charts).map((item) => {
            return {
                id: item.products.map((product) => capitalizeFirstLetter(product)),
                title: item.label,
            };
        })];
    }

    public isStringANumber(val: string): boolean {
        return !isNaN(parseFloat(val));
    }

    public isAdjustmentRequired(contract: ILastUserContractDTO): boolean {
        return contract.ProductCode === "UAuto";
    }

    public calculateDaysToProlong(dateFrom: Day, dateTo: Day): number[] {
        const to = moment(dateTo);
        const totalDays = to.diff(moment(dateFrom), "days");
        const daysToProlong = to.diff(moment(new Date()), "days");

        return [totalDays - daysToProlong, daysToProlong];
    }

    public getProlongChartColor(daysToProlong: Int): string {
        if (daysToProlong > 10) {
            return "#008000"; // green
        }
        if (daysToProlong <= 10 && daysToProlong > 3) {
            return "#ffa500"; // orange
        }
        return "#FF0000"; // red
    }

    public getContractUrl(contractId: Int, productCode: string): string {
        const $state = this.di<StateService>("$state");
        return $state.href(`app.${productCode}.index`, { id: contractId });
    }
    /**
     * @description Получение данных по запрошенному типу графика
     * @param {String} chartType Тип графика (day, week, month, quarter, year, all)
     */
    public initChartData(chartType: ChartType): void {
        this.sellerChartType = chartType;

        const oDataParams = {
            $filter: `
                (
                    (ContractStatusId eq 2) or (ContractStatusId ge 7 and ContractStatusId le 10)
                )`,
            $orderby: "ProductCode asc, SigningDate asc",
            $select: `ProductCode, SigningDate, InsurancePremium, NextContractStatusId, NextContractId, ContractTo,
                Discriminator`,
        };

        let today = moment();
        let pastday = null;
        let timeFormat = "%H:%M";
        let minTickSize = [1, "hour"];

        switch (this.sellerChartType) {
            case "yesterday":
                today = moment().subtract(1, "days");
                break;
            case "week":
                pastday = moment().subtract(1, "weeks");
                timeFormat = "%d.%m";
                minTickSize = [1, "day"];
                break;
            case "month":
                pastday = moment().subtract(1, "months");
                timeFormat = "%d.%m";
                minTickSize = [1, "day"];
                break;
            case "quarter":
                pastday = moment().subtract(1, "quarter");
                timeFormat = "%d.%m";
                minTickSize = [1, "day"];
                break;
            case "year":
                pastday = moment().subtract(1, "years");
                timeFormat = "%d.%m";
                minTickSize = [1, "day"];
                break;
            case "all":
                today = null;
                pastday = null;
                timeFormat = "%d.%m.%Y";
                minTickSize = [1, "month"];
                break;
        }

        if (today && !pastday) {
            oDataParams.$filter += ` and (
                date(SigningDate) eq ${today.format("YYYY-MM-DD")} or
                date(ContractTo) eq ${today.format("YYYY-MM-DD")}
            )`;
        } else if (today && pastday) {
            oDataParams.$filter += ` and
                (
                    (
                        date(SigningDate) le ${today.format("YYYY-MM-DD")} and
                        date(SigningDate) ge ${pastday.format("YYYY-MM-DD")}
                    ) or (
                        date(ContractTo) le ${today.format("YYYY-MM-DD")} and
                        date(ContractTo) ge ${pastday.format("YYYY-MM-DD")}
                    )
                )`;
        }

        this.chartData.setCharts(this.charts, this.sellerChartType);

        const chartBlock = this.blockUI.instances.get("chartBlock");
        chartBlock.start(" ");
        this.chartData
            .populate(oDataParams, pastday, today)
            .finally(() => {
                this.updateChart(timeFormat, minTickSize);
                chartBlock.stop();
            });
    }
    /**
     * @description Callback для получения экземпляра plot
     * @see https://github.com/develersrl/angular-flot
     */
    public plotCallback(plot: IPlot): void {
        this.plotInstance = plot;
    }

    /**
     * @description Callback для события "hover" над графиком
     */
    public onPlotHover(event: any, pos: IPosition): void {
        const axes = this.plotInstance.getAxes();

        if (pos.x < axes.xaxis.min || pos.x > axes.xaxis.max ||
            pos.y < axes.yaxis.min || pos.y > axes.yaxis.max) {
            return;
        }

        const dataset = this.plotInstance.getData();
        const valueInterval = 0.003 * (axes.xaxis.max - axes.xaxis.min);

        for (const series of dataset) {
            const legend = (angular.element as any).find(`td.legendLabel:contains("${series.label}")`);

            for (const data of series.data) {
                if (pos.x >= (data[0]) - valueInterval && pos.x <= (data[0] + valueInterval)) {
                    angular.element(legend).text(series.label.concat(": ", data[1]));
                }
            }
        }
    }

    /**
     * @description Подготовка данных для графика
     */
    public updateChart(timeFormat: string, minTickSize: Array<string | number>): void {
        this.flotData = [];

        angular.forEach(this.chartData.getCharts(), (item) => {
            this.flotData.push(this.getChartLineConfig(item));
        });
        this.flotOptions.xaxis.minTickSize = minTickSize;
        this.flotOptions.xaxis.timeformat = timeFormat;
    }

    /**
     * Подготовка данных по продукту для графика
     * @see DashboardChartData
     * @param {Object} Объект с инфорацией о продукте
     * @return {Object}
     */
    public getChartLineConfig(product: IChart): IChartLineConfig {
        return {
            color: product.color,
            data: product.data.sort((a, b) => a[0] - b[0]),
            label: product.label,
            lines: {
                fill: true,
                lineWidth: 1,
                show: true,
            },
            splines: {
                fill: 0.1,
                lineWidth: 1,
                show: false,
                tension: 0.6,
            },
        };
    }
    public openContract(contractId: Int, productCode: string) {
        this.helpers.openContract(contractId, productCode);
    }

    public getContractStatusLabelClass(contract: ILastUserContractDTO): string {
        return this.statusService.getStatusLabelClass(contract.ContractStatusId);
    }
}
