import { Transition, TransitionHookFn } from "@uirouter/core";
export function PageTitleDirective($transitions: Transition): ng.IDirective {
    "ngInject";

    return {
        link: (
            scope: ng.IScope,
            element: ng.IAugmentedJQuery,
        ) => {
            const listener: TransitionHookFn = (event) => {
                const toState = event.to();
                let title = "Югория Pro";
                if (toState.data && toState.data.pageTitle) {
                    title += " | " + toState.data.pageTitle;
                }
                element.text(title);
            };
            $transitions.onSuccess({}, listener);
        },
    };
}
