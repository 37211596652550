import { OsagoContract } from "application/osago/classes";

export enum ProcessingResultCodeEnum {
    Ok = 0,
    Accepted = 2,
    RequestError = 3,
    InternalError = 4,
    NotFound = 5,
    Forbidden = 6,
}

export enum CommonErrorsErrorTypeEnum {
    Info = 1,
    Warning = 2,
    Error = 3,
    Critical = 4,
}

interface CalculateResponseHeaderDTO {
    ProcessingResultCode: ProcessingResultCodeEnum;
    WorkStage: number;
    Created: string;
}

interface ValidationError {
    [key: string]: string[];
}

export interface OsagoAsyncResponseCommonError {
    ErrorType: CommonErrorsErrorTypeEnum;
    Message: string;
    ExternalSystem: string;
}

interface CalculateResponseErrorsDTO {
    ValidationErrors: ValidationError;
    CommonErrors: OsagoAsyncResponseCommonError[];
}

export interface OsagoAsyncResponseDTO {
    Header: CalculateResponseHeaderDTO;
    Errors: CalculateResponseErrorsDTO;
    ProductName: string;
    Content: OsagoContract;
}
