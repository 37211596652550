import angular from "angular";
import moment from "moment";

export type IEdmOperationFunc = (property: string, value: string | number | number[] | string[]) => string;
export type IEdmOperation = IEdmOperationFunc | string | string[];
export interface IEdmOption {
    [key: string]: {
        operation: IEdmOperation | string | string[];
        title: string;
    };
}

class NumberLikeTypes {
    public static inputtype = "textfield";
    public static default = "eq";
    public static options: IEdmOption = {
        bw: {
            operation: ["ge", "le"],
            title: "Между",
        },
        eq: {
            operation: "eq",
            title: "Соответствует",
        },
        gt: {
            operation: "gt",
            title: "Больше",
        },
        lt: {
            operation: "lt",
            title: "Меньше",
        },
        ne: {
            operation: "ne",
            title: "Не соответствует",
        },
        in: {
            operation: (property: string, values: number[]) => {
                if (values && angular.isArray(values) && values.length > 0) {
                    return "(" + values.map((value) => `${property} eq ${value}`).join(" or ") + ")";
                } else {
                    return `(${property} eq null)`;
                }

            },
            title: "Включает",
        },
    };
    public static normalisedValue(num: string | number | number[]) {
        if (angular.isString(num)) {
            num = num.replace(/,/g, ".");
        }
        if (angular.isArray(num)) {
            return num.map((el) => parseFloat(String(el)));
        }
        return parseFloat(String(num));
    }
}

class StringLikeTypes {
    public static default = "startswith";
    public static options = {
        eq: {
            operation: "eq",
            title: "Соответствует",
        },
        /* indexof: {
            operation: (property: string, value: string | number) => `indexof(${property}, ${value}) gt -1`,
            title: "Содержит",
        }, */
        ne: {
            operation: "ne",
            title: "Не соответствует",
        },
        /* notIncludes: {
            operation: (property: string, value: string | number) =>  `indexof(${property}, ${value}) eq -1`,
            title: "Не содержит",
        }, */
        startswith: {
            operation: (property: string, value: string | number) =>  `startswith(${property}, ${value})`,
            title: "Начинается с",
        },
    };
    public static normalisedValue(str: string | number) {
        /*
        не очень хороший котстыль, он для того,
        что бы можно было оперировать булевыми значениями
        в выпадающих списках у которых тип input текстовой,
        и значения true/false не обрамлялись кавычками,
        а если установить тип input checkbox то на форме
        получится не совсем юзабельная и понятная штука
        в фильтрах, которая может отражать только 2 состояния,
        а нужно 3. (оплачено, не оплачено, обсалютно все)
        */
        if (typeof str === "boolean") {
            return str;
        }
        return (!str) ? undefined :  `'${String(str).replace(/'/g, "''")}'`;
    }
}

class DateTimeOffsetLikeTypes {
    public static default = "eq";
    public static inputtype = "date";
    public static options = {
        bw: {
            operation: ["gt", "lt"],
            title: "Между",
        },
        eq: {
            operation: (property: string, value: string | number) => {
                const fromStr = `${moment(value).format("YYYY-MM-DDT00:00:00")}Z`;
                const toStr = `${moment(value).format("YYYY-MM-DDT23:59:59")}Z`;
                return `${property} ge ${fromStr} and ${property} le ${toStr}`;
            },
            title: "Соответствует",
        },
        gt: {
            operation: "gt",
            title: "Больше",
        },
        lt: {
            operation: "lt",
            title: "Меньше",
        },
        ne: {
            operation: "ne",
            title: "Не соответствует",
        },
    };
    public static normalisedValue(strDate: string) {
        const date = moment(strDate);
        if (date.isValid()) {
            return `${date.format("YYYY-MM-DDT00:00:00")}Z`;
        }
    }
}

class BooleanLikeTypes {
    public static inputtype = "checkbox";
    public static default = "eq";
    public static options = {
        eq: {
            operation: "eq",
            title: "Соответствует",
        },
    };
    public static normalisedValue(val: string | number | boolean) {
        return Boolean(val);
    }
}

const TypesMap = {
    "Edm.Boolean": BooleanLikeTypes,
    "Edm.DateTimeOffset": DateTimeOffsetLikeTypes,
    "Edm.Decimal": NumberLikeTypes,
    "Edm.Guid": StringLikeTypes,
    "Edm.Int32": NumberLikeTypes,
    "Edm.String": StringLikeTypes,
};

export class UgskODataService {
    public static getType(type: string) {
        return (TypesMap[type] || { options: {} });
    }
}
