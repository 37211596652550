import "angular-resource";

/**
 * ugskVehicleService - сервис для получения данных каталога ТС
 */
function vehicleService($resource, envService) {
    return $resource(`${envService.read("apiUrl")}/Search/Vehicle`, {}, {
        get: {
            isArray: true,
        },
    });
}

export default angular.module("ugsk.service-factory.vehicles", [
    "ngResource",
]).service("vehicleService", vehicleService).name;
