import angular from "angular";
import { ValidationService } from "infrastructure/services/validation.service";

export function ErrorsCountDirective(
    $document: ng.IDocumentService,
    validationService: ValidationService,
): ng.IDirective {
    "ngInject";

    return {
        link: (
            scope: ng.IScope,
            element: ng.IAugmentedJQuery,
            attributes: ng.IAttributes,
        ) => {
            angular.element($document).ready(() => {
                const find = (node: HTMLElement | JQuery, elem: string) => angular.element(node).find(elem);
                const indexLI = element.prevAll().length;
                const tab = (element.parent().next("div.tab-content").children("div.tab-pane"))[indexLI];
                let listNodes = find(tab, "ugsk-validation");
                let validationKeys = [...listNodes].map((node) => {
                    return node.getAttribute("name");
                });

                const badge = "<span class='badge-error'></span>";
                find(element, "a.nav-link").append(badge);
                const span = find(element, "span");

                const listener = () => {
                    let countErrors = 0;
                    validationKeys.forEach((key: string) => {
                        if (validationService.hasError(key)) {
                            countErrors += 1;
                        }
                    });
                    if (countErrors) {
                        span.text(countErrors).show();
                    } else {
                        span.hide();
                    }
                };

                const listenerOnRegisterErrorKey = () => {
                    listNodes = find(tab, "ugsk-validation");
                    validationKeys = [...listNodes].map((node) => node.getAttribute("name"));
                    listener();
                };

                validationService.on("onRegisterErrorKey", listenerOnRegisterErrorKey);
                scope.$on("$destroy", () => {
                    validationService.removeListener("onRegisterErrorKey", listenerOnRegisterErrorKey);
                });

                scope.$watch(() => validationService.count(), listener);
            });
        },
        restrict: "A",
    };
}
