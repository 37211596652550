import "application/components/ugsk-multiple-selectpicker/ugskMultipleSelectpicker.component";
import { Product } from "domain/classes/product.class";
import { PersonalAccidentJournalToolbarController } from "./journal/personalAccidentJournal-toolbar.controller";
import { PersonalAccidentJournalController } from "./journal/personalAccidentJournal.controller";
import { PersonalAccidentToolbarController } from "./personalAccident-toolbar.controller";
import { PersonalAccidentController } from "./personalAccident.controller";

import template from "./body.html";

import { PersonalAccidentContract } from "./personalAccident.factory";

import angular from "angular";
import { ProductsService } from "infrastructure/services/products.service";
import manual from "./manual_personalAccident.pdf";

class PersonalAccident extends Product {
    constructor() {
        super();
        this.code = "personalAccident";
        this.groupName = "Страхование жизни и здоровья";
        this.productName = "Личная защита";
        this.description = "Добровольное страхование от несчастных случаев и болезней";
        this.insuranceRules = {
            base: "https://ugsk.ru/about/pravila/NS.pdf",
        };
        this.insurerCanBeLegalEntity = false;
        this.logo = "nav_personalAccident";
        this.manualUrl = manual;
        this.sort = 100;
        this.template = template;
        this.controller = PersonalAccidentController;
        this.toolbarController = PersonalAccidentToolbarController;
        this.journalController = PersonalAccidentJournalController;
        this.journalToolbarController = PersonalAccidentJournalToolbarController;
        this.extraViews = {
            "additionalConditions@app.personalAccident.index": {
                template: require("./block.additionalConditions.html"),
            },
            "beneficiary@app.personalAccident.index": {
                template: require("./block.beneficiary.html"),
            },
            "birthdayInsuredPerson@app.personalAccident.index": {
                template: require("./block.birthdayInsuredPerson.html"),
            },
            "contractParams@app.personalAccident.index": {
                template: require("./block.contractParams.html"),
            },
            "detailInsuredPerson@app.personalAccident.index": {
                template: require("./block.detailInsuredPerson.html"),
            },
            "insuranceConditions@app.personalAccident.index": {
                template: require("./block.insuranceConditions.html"),
            },
            "insurant@app.personalAccident.index": {
                template: require("./block.insurer.html"),
            },
            "insuredPerson@app.personalAccident.index": {
                template: require("./block.insuredPerson.html"),
            },
            "payment@app.personalAccident.index": {
                template: require("./block.payment.html"),
            },
            "purposeInsurance@app.personalAccident.index": {
                template: require("./block.purposeInsurance.html"),
            },
            "specialNotes@app.personalAccident.index": {
                template: require("./block.specialNotes.html"),
            },
        };
    }
    get contractClass() {
        return PersonalAccidentContract;
    }
}

angular.module("app.personalAccident", [
    "ugsk.components.multiple-selectpicker",
    "touchSpin",
]).run((productsService: ProductsService) => {
    "ngInject";

    productsService.register(new PersonalAccident());
});
