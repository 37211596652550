import { Contract } from "domain/classes/contract.class";
import { ContractorProxy } from "domain/proxies/insurantPersonProxy.class";
import { BeneficiaryDTO } from "infrastructure/interfaces/WebApi/BeneficiaryDTO";
import { IFactPaymentDTO } from "infrastructure/interfaces/WebApi/IFactPaymentDTO";
import PaymentDTO from "infrastructure/interfaces/WebApi/PaymentDTO";
import { PersonalAccidentContractDTO } from "infrastructure/interfaces/WebApi/PersonalAccidentContractDTO";
import { PhoneNumberDTO } from "infrastructure/interfaces/WebApi/PhoneNumberDTO";
import Repository, { IRepositoryItem } from "infrastructure/Repository.class";
import { Day, Decimal, Guid, Int } from "infrastructure/types";
import { Uuid } from "lib/uuid";
import moment from "moment";

export interface IInsuredObjectDocTypeItem {
    InsuredObjectDocType: string;
    InsuredObjectDocTypeId: Int;
}
export interface IPolicySerial {
    PolicySerial: string;
    PolicySerialId: Int;
}
export interface IPaymentDocumentSerial {
    PaymentDocumentSerial: string;
    PaymentDocumentSerialId: Int;
}
export interface IInsuranceTerm {
    PersonalAccidentInsuranceTerm: string;
    PersonalAccidentInsuranceTermId: Int;
}
export interface IPersonalAccidentDiscountRestriction {
    MinValue: Int;
    MaxValue: Int;
}
export class PersonalAccidentRepository extends Repository {
    public IsDeclarationRequired: IRepositoryItem<{}>;
    public InsuredDocType: IRepositoryItem<{
        InsuredDocType: string;
        InsuredDocTypeId: Int;
    }>;
    public Industry: IRepositoryItem<{
        Industry: string;
        IndustryId: Int;
    }>;
    public InsuredObjectDocType: IRepositoryItem<IInsuredObjectDocTypeItem>;
    public PolicySerial: IRepositoryItem<IPolicySerial>;
    public PaymentDocumentSerial: IRepositoryItem<IPaymentDocumentSerial>;
    public InsuranceTerm: IRepositoryItem<IInsuranceTerm>;
    public MaxMonthsForContractFrom: IRepositoryItem<{}>; // TODO Когда-нить найти что там должно быть
    public PersonalAccidentDiscountRestriction: IRepositoryItem<IPersonalAccidentDiscountRestriction>;
    public ChildrenEducationalInstitution: IRepositoryItem<{}>; // TODO Когда-нить найти что там должно быть
    public BeneficiaryContractorType: IRepositoryItem<{
        BeneficiaryContractorType: string;
        BeneficiaryContractorTypeId: Int;
    }>;
}

export class InsurantPersonalAccidentProxy extends ContractorProxy {
    constructor(source: PersonalAccidentContract) {
        super(source);
    }

    static get proxyRules() {
        return {
            birthPlace: "InsuredBirthPlace",
            birthday: "InsuredBirthday",
            contractorType: "InsuredContractorTypeId",
            docDateGiven: "InsuredDocDateGiven",
            docNumber: "InsuredDocNumber",
            docOrgCode: "InsuredDocOrgCode",
            docSerial: "InsuredDocSerial",
            docWhomGiven: "InsuredDocWhomGiven",
            documentType: "InsuredDocTypeId",
            firstName: "InsuredFirstName",
            lastName: "InsuredLastName",
            middleName: "InsuredMiddleName",
        };
    }
}

interface IBeneficiary {
    BeneficiaryBirthday?: Day;
    BeneficiaryContractorTypeId: Int;
    BeneficiaryFirstName?: string;
    BeneficiaryLastName?: string;
    BeneficiaryMiddleName?: string;
    BeneficiaryOGRN?: string;
    BeneficiaryOrgName?: string;
    ContractorId?: Int;
    Guid?: Guid;
}

interface ISport {
    SportId: Int;
    Sport: string;
}

export class PersonalAccidentContract extends Contract implements PersonalAccidentContractDTO {
    public Id: Int = null;
    public ActiveRest: boolean = false;
    public Beneficiaries: BeneficiaryDTO[] = [];
    public BeneficiaryContractorTypeId: Int = 1;
    public ChildrenEducationalInstitution = "";
    public ChronicDiseaseId: Int = 1;
    public PersonalAccidentInsuranceTermId: Int = 12;
    public ContractFrom: Day = "";
    public ContractTo: Day = "";
    public ContractStatusId: Int = 1;
    public CoverageTerritoryId: Int = 4;
    public DisabilityId: Int = 1;
    public Discount = 0;
    public FactPayments: IFactPaymentDTO[] = [];
    public InsuranceTargetId: Int = 1;
    public InsuredPersonGuid: Guid = Uuid.raw();
    public InsuredContractorTypeId: Int = 1;
    public InsuredObjectBirthday: Day = null;
    public InsuredObjectDocDateGiven: Day = null;
    public InsuredObjectPhones: PhoneNumberDTO[] = [];
    public InsuredObjectHasNotFiasInfo: boolean;
    public InsuredBirthday: Day = null;
    public InsuredDocDateGiven: Day = null;
    public InsuredDocTypeId: Int = 12;
    public InsuredPersonPhones: PhoneNumberDTO[] = [];
    public IsDeclarationRequired: boolean = false;
    public MaxMonthsForContractFrom: Int = 3;
    public MaxValue: Int = 30;
    public MinValue: Int = 0;
    public PolicyNumber: string = "";
    public PolicyPrefix: string = "";
    public PolicySerialId: Int = 38;
    public SigningDate: Day = "";
    public Sport: ISport[] = [];
    public SportId: Int[] = [];
    public UnderwriterCoefficient: Int = 1;
    public ValiditySpanId: Int = 1;
    public InsuredAddressUserDefinedAddress: string = null;
    public InsuredPersonAddressFiasId: string = null;
    public InsuredPersonAddressKladrId: string = null;
    public InsuredPersonHasNotFiasInfo: boolean = false;
    public IndustryId: Int;
    public IsNoNecessarySport: boolean;
    public ProfessionId: Int;
    public IsNoNecessaryProfession: boolean;
    public Guid: Guid;
    public ContractStatusName: string;
    public PreviousPolicySerial: string;
    public PreviousContractNumber: string;
    public PreviousContractId: Int;
    public FilialId: Int;
    public InsurancePremium: Decimal;
    public DeclaredAmount: Decimal;
    public InsuranceProductId: Int;
    public InsuredDocSerial: string;
    public InsuredDocNumber: string;
    public InsuredDocWhomGiven: string;
    public InsuredDocOrgCode: string;
    public InsuredBirthPlace: string;
    public InsuredLastName: string;
    public InsuredFirstName: string;
    public InsuredMiddleName: string;
    public InsuredPersonAddressUserDefinedAddress: string;
    public InsuredPhoneVerificationId: Guid;
    public InsuredId: Int;
    public InsuredPersonEmail: string;
    public InsuredObjectLastName: string;
    public InsuredObjectFirstName: string;
    public InsuredObjectMiddleName: string;
    public InsuredObjectContractorTypeId: Int;
    public InsuredObjectDocTypeId: Int;
    public InsuredObjectDocSerial: string;
    public InsuredObjectDocNumber: string;
    public InsuredObjectDocWhomGiven: string;
    public InsuredObjectAddressUserDefinedAddress: string;
    public InsuredObjectAddressFiasId: string;
    public InsuredObjectAddressKladrId: string;
    public InsuredObjectEmail: string;
    public InsuredObjectPhoneVerificationId: Guid;
    public InsuredObjectId: Int;
    public HasDiseaseFromDeclaration: boolean;
    public InsuredObjectGuid: Guid;
    public PaymentKindId: Int;
    public PaymentDocumentDatePay: Day;
    public IsReceiptRequired: boolean;
    public IsNumberRequired: boolean;
    public PlannedPayments: PaymentDTO[];
    public BeneficiaryIsHeirs: boolean;
    public HasAnotherPersonalAccidentInsuranceContract: boolean;
    public AnotherContractCompanyName: string;
    public AnotherContractAmount: Decimal;
    public AnotherContractProductRisks: string;
    public AnotherContractFrom: Day;
    public AnotherContractTo: Day;
    public HasInjury: boolean;
    public InjuryOrTrafficAccidentDescription: string;
    public Notes: string;

    constructor() {
        super();
        this.ContractFrom = moment().add(1, "d").format("YYYY-MM-DDT00:00:00");
        /* tslint:disable-next-line:max-line-length */
        this.ContractTo = moment(this.ContractFrom, "YYYY-MM-DD").add(this.PersonalAccidentInsuranceTermId, "M").add(-1, "d").format("YYYY-MM-DDT00:00:00");
        this.SigningDate = moment().format("YYYY-MM-DDT00:00:00");
    }
    //  @override
    public getInsurantPhones(): PhoneNumberDTO[] {
        return this.InsuredPersonPhones;
    }
    //  @override
    public setInsurantPhones(value: PhoneNumberDTO[]): void {
        this.InsuredPersonPhones = value;
    }
    // @override
    public getRepository(): PersonalAccidentRepository {
        return super.getRepository() as PersonalAccidentRepository;
    }
}
