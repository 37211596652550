import { resetable } from "domain/mixes/resetable.mix";
import { Uuid } from "lib/uuid";
import moment from "moment";

@resetable
export class UGSKDriver {
    public Id: number = 0;
    public guid: string = Uuid.raw();
    public lastName: string = "";
    public firstName: string = "";
    public middleName: string = null;
    public birthday: string = moment().subtract(18, "years").format("YYYY-MM-DDT00:00:00");
    public licenseSerial: string = "";
    public licenseNumber: string = "";
    public drivingExperience: string = moment().format("YYYY-MM-DDT00:00:00");
}
