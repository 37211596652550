import * as angular from "angular";
import { INgModelOptions } from "angular";
import moment from "moment";

import { BaseProductBodyController } from "application/baseProduct.body.controller";
import { InsurantPersonProxy } from "domain/proxies/insurantPersonProxy.class";
import { IDatePickerOptions } from "infrastructure/interfaces/IDatePickerOptions";
import { ISliderConfig } from "infrastructure/interfaces/ISliderConfig";
import { ITouchSpinConfig } from "infrastructure/interfaces/ITouchSpinConfig";
import { NotifyService } from "infrastructure/services/notifyService";
import { ExpressContract, ExpressRepository } from "./express.factory";

export class ExpressController extends BaseProductBodyController {
    public insurant: InsurantPersonProxy;
    public dateSlider: ISliderConfig;
    public sliderNgModelOptions: INgModelOptions;
    public dateTouchSpin: ITouchSpinConfig;
    public contractFromConfig: Partial<IDatePickerOptions>;

    protected Contract: ExpressContract;

    private PerDayInsurancePremium: number;
    private notifyService: NotifyService;

    public $onInit() {
        super.$onInit();
        this.phoneValidationService.setContractRequiredFields({
            individual: [
                { field: "InsuredLastName", description: "Фамилия страхователя" },
                { field: "InsuredFirstName", description: "Имя страхователя" },
                { field: "InsuredBirthday", description: "Дата рождения страхователя" },
            ],
            legalEntity: [
                { field: "InsuredOrgName", description: "Наименование организации страхователя" },
                { field: "InsuredOrgINN", description: "ИНН организации страхователя" },
            ],
        });

        this.PerDayInsurancePremium = this.Repository.InsuranceProgram.Collection[0].PerDayInsurancePremium;
        if (this.Contract.ContractStatusId === 0 && !this.Contract.InsurancePremium) {
            this.Contract.InsurancePremium = this.Repository.InsuranceProgram.Collection[0].InsurancePremiumMinValue;
        }

        if (!this.Contract.SigningDate) { this.Contract.SigningDate = `${moment().format("YYYY-MM-DD")}T00:00:00`; }

        const min = moment(this.Contract.SigningDate).startOf("day").add(10, "d");
        const max = moment(this.Contract.SigningDate).add(60, "d");

        this.contractFromConfig = {
            endDate: max.format("YYYY-MM-DD"),
            startDate: min.format("YYYY-MM-DD"),
        };

        if (!this.Contract.ContractStatusId) {
            if (!this.Contract.ContractFrom) {
                // tslint:disable-next-line: max-line-length
                this.Contract.ContractFrom = `${moment(this.Contract.SigningDate).add(10, "d").format("YYYY-MM-DD")}T00:00:00`;
            }
            this.Contract.ContractTo = this.getContractToDateByPremium(this.Contract.InsurancePremium);
            this.Contract.InsuranceTerm = this.getDaysByPremium(this.Contract.InsurancePremium);
        }

        // todo: отрефакторить, убрать 2
        if (this.Contract.ContractStatusId < 2) {
            if (this.Contract.InsurancePremium < this.Repository.InsuranceProgram.Collection[0].InsurancePremiumMinValue) {
                this.Contract.InsurancePremium = this.Repository.InsuranceProgram.Collection[0].InsurancePremiumMinValue;
                this.Contract.InsuranceTerm = this.getDaysByPremium(this.Contract.InsurancePremium);
                this.Contract.ContractTo = this.getContractToDateByPremium(this.Contract.InsurancePremium);
            }
        }

        const signingDate = moment(this.Contract.SigningDate, "YYYY-MM-DD").startOf("day");
        const current = moment().startOf("day");
        if (this.Contract.ContractStatusId === 1 && signingDate.isBefore(current)) {
            this.notifyService = this.di<NotifyService>("notifyService");
            // tslint:disable-next-line: max-line-length
            this.notifyService.warningMessage("Внимание", "По данному договору устарела дата заключения договора. " +
                "Рассчитайте договор заново для обновления даты заключения договора.");
        }

        if (!this.Contract.InsuredBirthday) {
            this.Contract.InsuredBirthday = `${moment().add(-18, "y").format("YYYY-MM-DD")}T00:00:00`;
        }

        this.insurant = new InsurantPersonProxy(this.Contract);
        this.dateSlider = {
            from: this.Contract.InsurancePremium,
            grid: true,
            hide_from_to: false,
            hide_min_max: true,
            max: this.Repository.InsuranceProgram.Collection[0].InsurancePremiumMaxValue,
            min: this.Repository.InsuranceProgram.Collection[0].InsurancePremiumMinValue,
            prettify: false,
            step: 1,
            type: "single",
        };

        this.sliderNgModelOptions = angular.copy(this.ngModelOptions);
        this.sliderNgModelOptions.debounce = {
            blur: 0,
            default: 1500,
        };

        this.dateTouchSpin = {
            boostat: 5,
            buttondown_class: "btn btn-success",
            buttonup_class: "btn btn-success",
            max: this.Repository.InsuranceProgram.Collection[0].InsurancePremiumMaxValue,
            maxboostedstep: 10,
            min: this.Repository.InsuranceProgram.Collection[0].InsurancePremiumMinValue,
            step: 1,
        };

        this._$scope.$watch(() => this.Contract.ContractFrom, (newDate, oldDate) => {
            if (newDate) {
                if (angular.equals(newDate, oldDate)) { return; }
                this.Contract.ContractTo = this.getContractToDateByPremium(this.Contract.InsurancePremium);
            }
        }, false);

        this._$scope.$watch(() => this.Contract.InsurancePremium, (value, oldValue) => {
            if (angular.equals(value, oldValue)) { return; }
            if (value) {
                this.Contract.ContractTo = this.getContractToDateByPremium(value);
                this.Contract.InsuranceTerm = this.getDaysByPremium(value);
            }
        });
    }

    public getPaymentDocumentSerial() {
        const $filter: angular.FilterFactory = this.di("$filter");
        const item = $filter("filterBy")(
            this.Repository.PaymentDocumentSerial.Collection,
            ["PaymentDocumentSerialId"],
            this.Contract.PaymentDocumentSerialId,
        );
        if (item[0]) {
            return item[0].PaymentDocumentSerial;
        }
        return "";
    }

    public get Repository(): ExpressRepository {
        return this.Contract.getRepository();
    }

    private getContractToDateByPremium(insurancePremium): string {
        return moment(this.Contract.ContractFrom)
            .add((this.getDaysByPremium(insurancePremium) - 1), "d")
            .format("YYYY-MM-DDTHH:mm:ss");
    }

    private getDaysByPremium(premium): number {
        return Math.round(premium / this.PerDayInsurancePremium);
    }
}
