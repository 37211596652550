import moment from "moment";
import template from "./ugskFilterField.component.html";
import "./ugskFilterField.component.css";
import { UgskODataService } from "infrastructure/services/ugskOData.service";

export const ugskFilterFieldComponent = {
    bindings: {
        filterObject: "<",
        model: "=ngModel",
        operationType: "<",
        onApplyFilterCalled: "&"
    },
    controller: class {
        constructor() {
            this.ugskODataService = UgskODataService;
        }
        onKeyPressed(event) {
            if (event.type === "keypress" && event.keyCode === 13) {
                this.onApplyFilterCalled();
            }
        }
        getInputType() {
            if (!this.filterObject) return "";
            return this.ugskODataService.getType(this.filterObject.type).inputtype || "textfield";
        }

        getMinDate() {
            if (this.filterObject && this.filterObject.values.length === 2) {
                if (this.operationType === 'lt' && this.filterObject.values[0] !== null) {
                    return moment(this.filterObject.values[0]).format("YYYY-MM-DD");
                }
            }
        }
        getMaxDate() {
            if (this.filterObject && this.filterObject.values.length === 2) {
                if (this.operationType === 'gt' && this.filterObject.values[1] !== null) {
                    return moment(this.filterObject.values[1]).format("YYYY-MM-DD");
                }
            }
        }        
    },
    controllerAs: "vm",
    template,
};
