import { BaseRepository } from "domain/classes/baseRepository.class";
import {
    AssuranceHomeAdditionalConstructionDTO,
} from "infrastructure/interfaces/WebApi/AssuranceHomeAdditionalConstructionDTO";
import { Decimal } from "infrastructure/types";
import { Uuid } from "lib/uuid";

export class AdditionalConstructionAssuranceHome extends BaseRepository implements AssuranceHomeAdditionalConstructionDTO {
    public Id = null;
    public Guid = Uuid.raw();
    public ConstructionTypeId = null; // Тип постройки
    public WallMaterialId = null; // Материал стен
    public OtherWallMaterial = null; // Другой материал стен
    public OtherWallMaterialIsCombustible = null; // Горючесть другого материала стен
    public InteriorCategoryId = null; // Категория внутренней отделки
    public ExteriorMaterialId = null; // Материал внешней отделки
    public OtherExteriorMaterial = null; // Другой материал внешней отделки
    public FoundationMaterialId = null; // Материал фундамента
    public OtherFoundationMaterial = null; // Другой материал фундамента
    public RoofMaterialId = null; // Материал кровли
    public OtherRoofMaterial = null; // Другой материал кровли
    public ConstructionYear = null; // Год постройки
    public OverhaulYear = null; // Год. кап. ремонта
    public InteriorRepairYear = null; // Год ремонта внутр. отделки
    public FloorCount = null; // Этажность
    public HasFireAlarm = false; // Наличие пожарной сигнализации
    public HasSecurityAlarm = false; // Наличие охранной сигнализации
    public Square = null; // Площадь
    public Length = null; // пог. метр
    public DeclaredAmount = null; // Заявленная сумма по постройке
    public Deterioration = null; // Износ
    public CalculatedAmount = null; // Рассчитанная сумма по постройке
    public Name: string;
    public AdditionalConstructionCostRestrictionMinValue: Decimal;
    public AdditionalConstructionCostRestrictionMaxValue: Decimal;

    constructor(id: number) {
        super();
        this.ConstructionTypeId = id;
    }
}
