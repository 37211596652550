import "./ugskThemeSwitcher.component.css";
import template from "./ugskThemeSwitcher.component.html";

class ugskThemeSwitcherController {
    constructor($localStorage) {
        this.localStorage = $localStorage;
    }
    toogleCompactMode() {
        this.localStorage.compactMode = !this.localStorage.compactMode;
    }
    getMessage() {
        return this.localStorage.compactMode ? "Выключить компактный режим" : "Включить компактный режим";
    }
    static get $inject() {
        return ["$localStorage"];
    }
}
export default angular.module("ugsk.components.themeSwitcher", [])
    .component("ugskThemeSwitcher", {
        controller: ugskThemeSwitcherController,
        controllerAs: "vm",
        template,
    }).name;
