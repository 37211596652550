import { ProductsJournalController } from "../../journal/journal.controller";

export class UAutoJournalController extends ProductsJournalController {
    public $onInit(): void {
        this.columns = [{
            defaultFilterOperation: "startswith",
            displayText: "Серия-номер полиса",
            inlineFilter: true,
            name: "PolicyNumber",
            pinned: true,
            renderer: (item) => item.PolicyFullValue,
            tooltipText: "Поиск только<br/>по номеру",
            type: "Edm.String",
        }, {
            defaultFilterOperation: "eq",
            displayText: "Статус договора",
            getVariants: () => this.getStatusVariants(),
            inlineFilter: true,
            name: "StatusName",
            pinned: true,
            requires: ["StatusName", "StatusId", "IsPaid"],
            template: `
                <span
                    ng-bind="item.StatusName"
                    ng-class="vm.getContractStatusLabelClass(item)">
                </span>
                <br/>
            `,
            type: "Edm.String",
        }, {
            defaultFilterOperation: "eq",
            displayText: "Статус платежа",
            getVariants: () => this.getStatusPaymentVariants(),
            inlineFilter: true,
            name: "IsPaid",
            pinned: true,
            requires: ["IsPaid"],
            template: `
                <span
                    ng-bind="item.IsPaid ? 'Оплачен' : 'Не оплачен'"
                    ng-class="{'label-primary': item.IsPaid, 'label-danger': !item.IsPaid}">
                </span>
                <br/>
            `,
            type: "Edm.String",
        }, {
            displayText: "Программа страхования",
            inlineFilter: true,
            name: "InsuranceProgram",
            type: "Edm.String",
        }, {
            defaultFilterOperation: "eq",
            displayText: "Дата заключения договора",
            inlineFilter: true,
            name: "SigningDate",
            ngFilter: this.dateFormatWithoutTimeZone,
            pinned: true,
            type: "Edm.DateTimeOffset",
        }, {
            displayText: "Начало срока страхования",
            name: "ContractFrom",
            ngFilter: this.dateFormatWithoutTimeZone,
            type: "Edm.DateTimeOffset",
            visible: false,
        }, {
            displayText: "Окончание срока страхования",
            name: "ContractTo",
            ngFilter: this.dateFormatWithoutTimeZone,
            type: "Edm.DateTimeOffset",
            visible: false,
        }, {
            displayText: "Начало, Окончание",
            requires: ["ContractFrom", "ContractTo"],
            template: `
                <span ng-bind="item.ContractFrom | ${this.dateFormatWithoutTimeZone}"></span>
                <br/>
                <span ng-bind="item.ContractTo | ${this.dateFormatWithoutTimeZone}"></span>
            `,
            type: "Edm.String",
        }, {
            displayText: "Страховая сумма",
            name: "InsuranceAmount",
            type: "Edm.Int32",
            visible: false,
        }, {
            displayText: "Тип франшизы",
            name: "FranchiseType",
            type: "Edm.String",
            visible: false,
        }, {
            displayText: "Сумма франшизы",
            name: "FranchiseAmount",
            type: "Edm.String",
            visible: false,
        }, {
            displayText: "Страховая сумма, франшиза",
            inlineFilter: "InsuranceAmount",
            requires: ["InsuranceAmount", "FranchiseType", "FranchiseAmount"],
            template: `
                <span ng-bind="item.InsuranceAmount | thousand"></span>
                <br/>
                <span ng-bind="item.FranchiseType"></span>
                <br/>
                <span ng-bind="item.FranchiseAmount"></span>
            `,
            type: "Edm.Int32",
        }, {
            displayText: "Страховая премия",
            inlineFilter: true,
            name: "SummaryPremium",
            ngFilter: "thousand",
            type: "Edm.Int32",
            visible: false,
        }, {
            displayText: "КБМ",
            name: "BonusMalus",
            type: "Edm.String",
            visible: false,
        }, {
            displayText: "Скидка",
            name: "Discount",
            type: "Edm.Int32",
            visible: false,
        }, {
            name: "DiscountIsNumeric",
            type: "Edm.Boolean",
            visible: false,
        }, {
            displayText: "Страховая премия (КБМ/Скидка)",
            inlineFilter: "SummaryPremium",
            requires: ["SummaryPremium", "BonusMalus", "Discount", "DiscountIsNumeric"],
            template: `
                <span ng-bind="item.SummaryPremium | thousand"></span>
                <br/>
                <span class="text-muted" ng-bind="item.BonusMalus"></span>
                <br/>
                <span class="text-muted">
                <span ng-bind="item.Discount">
                </span>
                <span class="fa fa-rub" ng-if="item.DiscountIsNumeric"></span>
                <span ng-if="!item.DiscountIsNumeric">
                %
                </span>
                </span>
            `,
            type: "Edm.String",
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Страхователь",
            inlineFilter: true,
            name: "InsuredName",
            pinned: true,
            type: "Edm.String",
        }, {
            displayText: "Марка/Модель ТС",
            name: "VehicleVehicleModelSpelt",
            type: "Edm.String",
            visible: false,
        }, {
            displayText: "Год выпуска ТС",
            name: "VehicleYearMade",
            type: "Edm.Int32",
            visible: false,
        }, {
            displayText: "VIN",
            name: "VehicleVIN",
            type: "Edm.String",
            visible: false,
        }, {
            displayText: "Гос.номер ТС",
            name: "VehicleLicensePlate",
            type: "Edm.String",
            visible: false,
        }, {
            displayText: "Модель, Год, VIN, Гос.Номер",
            inlineFilter: "VehicleLicensePlate",
            requires: ["VehicleVehicleModelSpelt", "VehicleYearMade", "VehicleVIN", "VehicleLicensePlate"],
            template: `
                <span ng-bind="item.VehicleVehicleModelSpelt"></span>
                <br/>
                <span ng-bind="item.VehicleYearMade"></span>
                <br/>
                <span ng-bind="item.VehicleVIN"></span>
                <br/>
                <span ng-bind="item.VehicleLicensePlate"></span>
            `,
            type: "Edm.String",
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Филиал",
            inlineFilter: true,
            name: "EmployeeFilialName",
            pinned: true,
            type: "Edm.String",
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Канал продаж",
            inlineFilter: true,
            name: "SaleChannel",
            pinned: true,
            type: "Edm.String",
        }, {
            defaultFilterOperation: "startswith",
            displayText: "Подразделение",
            inlineFilter: true,
            name: "EmployeeSubdivisionName",
            type: "Edm.String",
            visible: false,
        }, {
            displayText: "Пользователь",
            name: "EmployeeName",
            type: "Edm.String",
            visible: false,
        }, {
            displayText: "Продавец",
            name: "EmployeePointOfSaleName",
            type: "Edm.String",
            visible: false,
        }, {
            displayText: "Контрагентского договора",
            name: "EmployeeIntermediateSellerName",
            type: "Edm.String",
            visible: false,
        }, {
            displayText: "Пользователь, Продавец, Контрагент",
            inlineFilter: "EmployeeName",
            requires: ["EmployeeName", "EmployeeIntermediateSellerName", "EmployeePointOfSaleName"],
            template: `
                <span ng-bind="item.EmployeeName"></span>
                <br/>
                <span ng-bind="item.EmployeePointOfSaleName"></span>
                <br/>
                <span class="text-muted" ng-bind="item.EmployeeIntermediateSellerName"></span>
            `,
            type: "Edm.String",
        }, {
            displayText: "Комментарий продавца",
            name: "SellerComment",
            type: "Edm.String",
            visible: false,
        }, {
            displayText: "Вид оплаты",
            name: "PaymentKindName",
            type: "Edm.String",
            visible: false,
        }, {
            displayText: "Id-договора",
            name: "Id",
            type: "Edm.Int32",
            visible: false,
        }, {
            displayText: "Серия и номер полиса",
            name: "PolicyFullValue",
            type: "Edm.String",
            visible: false,
        }];
        super.$onInit();
    }
}
