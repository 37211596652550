// TODO перенести компонент в директорию компонент КАСКО
import moment from "moment";
import { NgComponentController } from "infrastructure/NgController";
import ugskContractorModule from "../ugsk-contractor/ugskContractor.component";
import template from "./ugskBeneficiary.component.html";

export default angular.module("ugsk.components.beneficiary", [
    ugskContractorModule,
]).component("ugskBeneficiary", {
    bindings: {
        beneficiary: "<",
        beneficiaryContractorTypes: "<", // TODO: fix
        beneficiaryCountries: "<", // TODO: fix
        beneficiaryDocumentTypes: "<",
        ngDisabled: "<",
        stateValueForDisabled: "<",
    },
    controller: class extends NgComponentController {
        onInit() {
            this.maxBirthDay = moment().subtract(18, "years").format();
            this.$watch(() => this.beneficiary.documentType, () => {
                if (!this.isPersonFieldsAvailable()) {
                    this.beneficiary.docOrgCode = null;
                    this.beneficiary.birthPlace = null;
                }
            });
        }
        isPersonFieldsAvailable() {
            return this.beneficiary.isIndividual() && this.beneficiary.documentType === "Паспорт гражданина РФ";
        }
    },
    controllerAs: "vm",
    template,
}).name;
