import angular from "angular";
import { Int } from "infrastructure/types";

export interface IODataResourceResult<T> extends Array<T> {
    $promise: angular.IPromise<IODataResourceResult<T>>;
    "@odata.context"?: string;
    "@odata.count"?: Int;
    value: T[];
}

export class ODataResourceResultClass<T> extends Array<T> {
    public $promise: angular.IPromise<IODataResourceResult<T>>;
    public "@odata.context": string;
    public "@odata.count": Int;
    public value: T[];
}
