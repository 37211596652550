import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";

export default {
    disabled(context) {
        return !context.Contract.isSigned();
    },
    href(context) {
        return context.$state.href(`app.${context.product.code}.payments`, {
            id: context.Contract.Id,
        });
    },
    visible(context) {
        return context.product.code !== "uAuto";
    },
    icon: "fa-dollar",
    title: "Взносы",
} as IExtraOptionPrototype;
