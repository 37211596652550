/* eslint-disable no-unused-expressions */
import { NgControllerBase } from "infrastructure/NgController";
import { ProductsService } from "infrastructure/services/products.service";
import "./desktopNotificationsList.css";


interface ILocalNotificationStorage {
    notes: LocalNotification[];
}

enum LocalNotificationType {
    "release_notes",
    "error",
}
class LocalNotification {
    public type: LocalNotificationType;
    public GUID: string;
    public detail_message_html: string;
    public title: string;
    public channel: string;
    public message: string;
}

class RemoteNotification {
    public MessageId: string;
    public InsuranceProductCode: string;
    public ContractId: number;
    public IsActive: boolean;
}

export class DesktopNotificationsListController extends NgControllerBase {
    private selectedNotification: RemoteNotification;
    private localNotifications: LocalNotification[] = [];
    private notifications: RemoteNotification[] = [];
    private $localStorage: any;
    private productsService: ProductsService;
    static get $inject() {
        return ["$injector", "$uibModalInstance"];
    }
    public constructor(
        $injector,
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    ) {
        super($injector);
        this.$localStorage = this.di("$localStorage");
        this.productsService = this.di("productsService");
        this.notifications = this.$localStorage.desktopNotifications || [];
        if (!this.$localStorage.hiddenSystemNotify) {
            this.$localStorage.hiddenSystemNotify = [];
        }
    }
    public $onInit() {
        const $http = this.di("$http") as angular.IHttpService;
        $http({
            method: "GET",
            url: "notifications.json",
        }).then(({ data: notifications }) => {
            this.localNotifications = (notifications as ILocalNotificationStorage).notes.filter((elem) =>
                // (location.href.includes(elem.channel) || elem.channel === "all") &&
                !this.$localStorage.hiddenSystemNotify.includes(elem.GUID));
                //  @TODO: Почистить устаревшие ключи скрытых уведомлений
        });
    }
    public close() {
        this.$uibModalInstance.close();
    }

    //  local notifications
    public async showMore(notificationIndex: number) {
        const notification = this.localNotifications[notificationIndex];
        if (!notification || !notification.detail_message_html) {
            return;
        }
        const $uibModal = this.di("$uibModal") as ng.ui.bootstrap.IModalService;
        return $uibModal.open({
            controller: class {
                private text: string;
                private title: string;
                constructor(private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance) {
                    "ngInject";

                    this.text = notification.detail_message_html;
                    this.title = notification.title || "";
                }
                public confirm() {
                    this.$uibModalInstance.close();
                }
                public cancel() {
                    this.$uibModalInstance.dismiss();
                }
            },
            controllerAs: "vm",
            size: "lg",
            template: `
                <div class="ibox">
                    <div class="ibox-title ">
                        <h2 ng-bind="vm.title"></h2>
                    </div>
                    <div style="min-height: 350px;" class="ibox-content"
                    ng-bind-html="vm.text">
                    </div>
                    <div class="ibox-footer">
                        <div class="push-left">
                            <button class="btn btn-success"
                            ng-click="vm.confirm()">Закрыть</button>
                        </div>
                    </div>
                </div>
            `,
            windowClass: "zindex",
        }).result;
    }
    public deleteNote(index) {
        const notification = this.localNotifications[index];
        if (!notification) {
            return;
        }
        this.$localStorage.hiddenSystemNotify.push(notification.GUID);
        this.localNotifications.splice(index, 1);
    }

    //  Remote notifications
    public selectNotification(notification: RemoteNotification) {
        this.selectedNotification = notification;
        this.selectedNotification.IsActive = false;
    }
    public isNotificationSelected(notification: RemoteNotification) {
        return this.selectedNotification && notification.MessageId === this.selectedNotification.MessageId;
    }

    public openNotification() {
        if (!this.selectedNotification) {
            return;
        }
        this.selectedNotification.IsActive = false;
        const productCode = this.lowerizeProductCode(this.selectedNotification.InsuranceProductCode);
        const $window = this.di("$window") as angular.IWindowService;
        const helpers = this.di("helpers") as any;
        const tab = $window.open(helpers.getContractUrl(
            this.selectedNotification.ContractId,
            productCode,
        ), "_blank");
        tab.focus();
    }
    public getProductNameByCode(code: string) {
        const product = this.productsService.getByCode(this.lowerizeProductCode(code));
        if (product) {
            return product.productName;
        }
        return "";
    }
    private lowerizeProductCode(productCode: string): string {
        return productCode[0].toLowerCase() + productCode.slice(1);
    }
}
