import {
    AssignmentFactPaymentOnASignedContractViewModel,
} from "infrastructure/interfaces/WebApi/AssignmentFactPaymentOnASignedContractViewModel";
import { IFactPaymentDTO } from "infrastructure/interfaces/WebApi/IFactPaymentDTO";
import { Int } from "infrastructure/types";
import { Uuid } from "lib/uuid";

interface IPaymentValidator {
    description: string;
    message: string;
    code: string;
    isValid: (payment: AssignmentFactPaymentOnASignedContractViewModel) => boolean;
}

export class Payment implements IFactPaymentDTO {

    public static fromObject(params: AssignmentFactPaymentOnASignedContractViewModel): Payment {
        if (!params.Guid) {
            delete params.Guid;
        }
        const payment = new Payment();
        Object.assign(payment, params);
        return payment;
    }

    public static fromContractDefaults(defaults): Payment {
        const payment = new Payment();
        payment.PaymentKindId = defaults.PaymentKindId;
        payment.Serial = defaults.PaymentDocumentSerial;
        payment.Number = defaults.PaymentDocumentNumber || defaults.PaymentDocument || payment.Number;
        return payment;
    }

    public static get validators(): IPaymentValidator[] {
        return [{
            code: "PaymentKindId",
            description: "Проверка наличия способа оплаты",
            isValid: (payment) => {
                return Boolean(payment.PaymentKindId);
            },
            message: "Не выбран способ оплаты",
        }, {
            code: "Amount",
            description: "Проверка наличия суммы платежного документа",
            isValid: (payment) => {
                if (typeof(payment.Amount) === "number") {
                    return payment.Amount > 0;
                } else {
                    return parseInt(payment.Amount, 10) > 0;
                }
            },
            message: "Не указана сумма взноса",
        }];
    }

    public Guid: string = undefined;
    public Number: string = "";
    public Amount: number = undefined;
    public ContractId: number = undefined;
    public DatePay: string = null;
    public Id: number = undefined;
    public PaymentKindId: number = undefined;
    public PaymentKindUniqueData: string = "";
    public PaymentRegistryId: number = null;
    public PaymentStatusId: Int = undefined;
    public Serial: string = "";

    public constructor(Guid?: string) {
        this.Guid = Guid || Uuid.raw();
    }
}
