import { NgComponentController } from "infrastructure/NgController";
import { UgskSet } from "domain/classes/ugsk-set.class";
import { Payment } from "domain/classes/payment.class";
import { PAYMENT_KINDS } from "application/constants";
import Repository from "infrastructure/Repository.class";

import template from "./ugskContractPaymentsPage.component.html";

export const ugskContractPaymentsPageComponent = {
    bindings: {
        contractId: "<",
        paymentKindsBlacklist: "<",
        productCode: "<",
    },
    controller: class PaymentsController extends NgComponentController {
        onInit() {
            this.inProgressText = "Загрузка взносов";
            this.removeIsDisabledHtml = `
                <ul>
                    <li>
                        первый в договоре
                    </li>
                    <li>
                        внесен в реестр
                    </lib>
                </ul>
            `;

            const [
                $q,
                factPaymentsService,
                RepositoryService,
                contractResourceFactory,
            ] = this.di([
                "$q",
                "factPaymentsService",
                "repositoryService",
                "contractResourceFactory",
            ]);

            this.allowedPaymentKinds = ["Банковская карта", "Интернет-банкинг"];

            const productRepository = new Repository(RepositoryService(this.productCode));

            const ContractResource = contractResourceFactory(this.productCode);
            this.selectedPayments = new UgskSet();
            this.payments = [];
            this.contract = new ContractResource();
            return $q.all([
                factPaymentsService.odata().filter("ContractId", this.contractId).query().$promise,
                this.contract.$editContract({ id: this.contractId }),
            ]).then(([payments]) => {
                this.payments = payments.map(payment => Payment.fromObject(payment));
            }).then(() => productRepository.load(this.contract)).then(() => {
                this.allowedPaymentKinds = productRepository.getAvailableValuesOf("PaymentKind").filter(item => {
                    return !this.paymentKindsBlacklist.includes(item);
                });
            });
        }
        isAddingDisabled() {
            return this.payments.length >= 20 || this.toPayAmount() <= 0;
        }
        isRemoveDisabled() {
            return !this.currentPayment || this.currentPayment === this.payments[0] || this.currentPayment.PaymentRegistryId;
        }
        isNeedValidateField() {
            const validationService = this.di("validationService");
            validationService.clear();
            const validationResult = validationService.validateObjects(this.payments);
            const errorsCount = validationResult.reduce((total, result) => total + result.length, 0);
            if (errorsCount > 0) {
                validationService.addObjectsErrors(validationResult);
                return true;
            }
            return false;
        }
        addPayment() {
            if (this.isAddingDisabled()) return;
            if (this.isNeedValidateField()) {
                const notifyService = this.di("notifyService");
                return notifyService.warningMessage("Внимание", `Не заполнены данные по предыдущему взносу`);
            }
            const newPayment = new Payment();
            newPayment.ContractId = this.contractId;
            //  TODO: `+ "Contract"` - небольшое подкостыливание, довести до ума
            newPayment.InsuranceProductCode = `${this.productCode}Contract`;
            this.currentPayment = newPayment;
            this.payments.push(newPayment);
        }
        set currentPayment(payment) {
            this.selectedPayments.clear();
            this.selectedPayments.add(payment);
        }
        get currentPayment() {
            return this.selectedPayments.first();
        }
        removePayment(payment) {
            const [helpers] = this.di(["helpers"]);
            helpers.confirm({
                text: `Взнос ${payment.Number} будет удален из списка взносов. Выполнить удаление?`,
            }).then(() => {
                this.payments = this.payments.filter(p => p !== payment);
                this.selectedPayments.delete(payment);
            });
        }
        isSaveDisabled() {
            return this.toPayAmount() < 0;
        }
        get contractPremium() {
            //  FIXME: CalculatedPremium - KASKO only
            return this.contract.InsurancePremium || this.contract.CalculatedPremium;
        }
        save() {
            const [
                notifyService,
                $state,
            ] = this.di([
                "notifyService",
                "$state",
            ]);
            if (this.isNeedValidateField()) return;
            this.mask("Сохранение платежей");
            this.contract.FactPayments = this.payments;
            this.contract.$updateFactPayments().then(() => {
                if (this.currentPayment && !this.currentPayment.Id && this.currentPayment.PaymentKindId === PAYMENT_KINDS.BANK_CARD) {
                    notifyService.warningMessage("Не забудьте вручную пробить чек из ККТ");
                }
                notifyService.successMessage("Взносы успешно сохранены");
                $state.reload();
            }).catch(() => {
                notifyService.errorMessage("Ошибка сохранения взносов");
            }).finally(() => {
                this.unmask();
            });
        }
        getPaymentInfoClass() {
            const premium = this.contractPremium;
            const toPayAmount = this.toPayAmount();
            if (toPayAmount === 0) {
                return "alert-success";
            } else if (toPayAmount > premium / 2 || toPayAmount < 0) {
                return "alert-danger";
            } else if (toPayAmount <= premium / 2) {
                return "alert-warning";
            }
            return "";
        }
        toPayAmount() {
            const result = (this.contractPremium || 0) - this.payments.reduce((total, payment) => { // eslint-disable-line arrow-body-style
                return (payment.Amount) ? total + payment.Amount : total;
            }, 0);
            return Math.max(result, 0);
        }
    },
    controllerAs: "vm",
    template,
};
