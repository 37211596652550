import angular from "angular";
import "angular-resource";

import PropertyPart from "domain/classes/propertyPart.class";
import { IRepositoryResource } from "infrastructure/Repository.class";
import { EnvService } from "infrastructure/services/env.service";
import { NotifyService } from "infrastructure/services/notifyService";
import { AdditionalConstructionAssuranceHome } from "./AdditionalConstructionAssuranceHome";

interface IAdditionalConstructionResourceActions extends ng.resource.IActionHash {
    query: ng.resource.IActionDescriptor;
    loadRepository: ng.resource.IActionDescriptor;
}

export interface IAssuranceHomeResource extends ng.resource.IResourceClass<any>, IRepositoryResource {}

export default angular
    .module("app.assuranceHome.resources", [])
    .factory("assuranceHomeAdditionalConstructionRepositoryService", (
        $resource: ng.resource.IResourceService,
        envService: EnvService,
        notifyService: NotifyService,
    ): IAssuranceHomeResource => {
        "ngInject";

        /* tslint:disable-next-line */
        const oDataUrl = `${envService.read("apiUrl")}Field/AssuranceHome/Nested/AdditionalConstructions/:fieldName`;
        const defaultParams = { fieldName: "@fieldName" };

        const actions: IAdditionalConstructionResourceActions = {
            loadRepository: {
                isArray: true,
                method: "POST",
                timeout: 60000,
                transformRequest: (data: { contract: AdditionalConstructionAssuranceHome, fieldName?: string }) => {
                    return angular.toJson(data.contract);
                },
                transformResponse: (data, fn, status) => {
                    if (status !== 200) {
                        notifyService.errorMessage("Ошибка", "Ошибка при запросе справочника постройки");
                        return [];
                    }

                    return angular.fromJson(data);
                },
            },
            query: {
                method: "GET",
                transformResponse: (data) => {
                    return data;
                },
            },
        };

        return $resource(oDataUrl, defaultParams, actions) as IAssuranceHomeResource;
    })
    .factory("assuranceHomeConstructionPartRepositoryService", (
        $resource: ng.resource.IResourceService,
        envService: EnvService,
        notifyService: NotifyService,
    ): IAssuranceHomeResource => {
        "ngInject";

        const oDataUrl = `${envService.read("apiUrl")}Field/AssuranceHome/Nested/PropertyParts/:fieldName`;
        const defaultParams = { fieldName: "@fieldName" };

        const actions: IAdditionalConstructionResourceActions = {
            loadRepository: {
                isArray: true,
                method: "POST",
                timeout: 60000,
                transformRequest: (data: { contract: PropertyPart, fieldName?: string }) => {
                    return angular.toJson(data.contract);
                },
                transformResponse: (data, fn, status) => {
                    if (status !== 200) {
                        notifyService.errorMessage("Ошибка", "Ошибка при запросе справочника постройки");
                        return [];
                    }
                    return angular.fromJson(data);
                },
            },
            query: {
                method: "GET",
            },
        };

        return $resource(oDataUrl, defaultParams, actions) as IAssuranceHomeResource;
    }).name;
