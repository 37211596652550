export function curatorServiceFactory($resource, envService) {
    "ngInject";

    return $resource(`${envService.read("apiUrl")}api/Curators`, {}, {
        query: {
            headers: { "X-Requested-With": "XMLHttpRequest" },
            method: "GET",
        },
    });
}
