import angular from "angular";
import { IPaymentRegistry } from "application/paymentsRegistries/interfaces";
import { Payment } from "domain/classes/payment.class";
import { UgskSet } from "domain/classes/ugsk-set.class";
import { IEmployeeResource } from "infrastructure/interfaces";
import { ILogic } from "infrastructure/interfaces/ILogic";
import { IProvider } from "infrastructure/interfaces/OData/IODataResource";
import { ODataResourceResultClass } from "infrastructure/interfaces/OData/IODataResourceResult";
import {
    AssignmentFactPaymentOnASignedContractViewModel,
} from "infrastructure/interfaces/WebApi/AssignmentFactPaymentOnASignedContractViewModel";
import { FactPayment } from "infrastructure/interfaces/WebApi/IFactPayment";
import { PaymentKind } from "infrastructure/interfaces/WebApi/PaymentKind";
import { NgComponentController } from "infrastructure/NgController";
import { DictionariesService } from "infrastructure/services/dictionaries.service";
import { EmployeeService } from "infrastructure/services/employee.service";
import { FactPaymentsService } from "infrastructure/services/factPayments.service";
import InfiniteScrollService, { ITableItem } from "infrastructure/services/infiniteScroll.service";
import { NotifyService } from "infrastructure/services/notifyService";
import { ValidationService } from "infrastructure/services/validation.service";
import { Int } from "infrastructure/types";
import moment from "moment";
import { NgTableParams as NgTableParamsType } from "ng-table";
import template from "./ugskPaymentsRegistry.component.html";

interface IPaymentEmployee {
    Employee: string;
    EmployeeId: Int;
}

type IFactPaymentODataResource = OData.IResource<AssignmentFactPaymentOnASignedContractViewModel>
    & AssignmentFactPaymentOnASignedContractViewModel;

class PaymentsRegistryController extends NgComponentController {
    public tableParams: NgTableParamsType<IFactPaymentODataResource>;
    public infiniteScroll: InfiniteScrollService<Payment>;
    public minDate: Date;
    public employeeService: EmployeeService;
    public PaymentKindId: Int;
    public paymentKinds: PaymentKind[];
    public foundPayments: Payment[];
    public selection: UgskSet<Payment>;
    public tableOrder: {
        direction?: string;
        by?: string;
    } = {};

    public employeeCollection: IPaymentEmployee[] = [];
    public cacheEmployeeCollection: IPaymentEmployee[] = [];
    public selectedEmployees: any[] = [];

    private virtualRegistry: IPaymentRegistry;
    private employeeResource: IEmployeeResource;

    get logic(): ILogic {
        return {
            /* tslint:disable:object-literal-sort-keys */
            rules: [{
                description: "Блокировка кнопки поиска, если не введены критерии поиска",
                properties: ["searchDisabled"],
                when: "registry.PeriodFrom and registry.PeriodTo and PaymentKindId",
                then: "searchDisabled = false;",
                else: "searchDisabled = true;",
            }],
            /* tslint:enable:object-literal-sort-keys */
        };
    }

    set registry(registry: IPaymentRegistry) {
        this.virtualRegistry = registry;
    }

    get registry(): IPaymentRegistry {
        if (this.isNew) {
            const factPayments: FactPayment[] = [];
            if (this.selection.size) {
                this.selection.forEach((item) => {
                    factPayments.push({
                        Amount: item.Amount,
                        ContractId: item.ContractId,
                        DatePay: item.DatePay,
                        Guid: item.Guid,
                        Id: item.Id,
                        IsAccounted: false,
                        Number: item.Number,
                        PaymentKindId: item.PaymentKindId,
                        PaymentKindUniqueData: item.PaymentKindUniqueData,
                        PaymentRegistryId: item.PaymentRegistryId,
                        PaymentStatusId: item.PaymentStatusId,
                        Serial: item.Serial,
                    });
                });
            }
            this.virtualRegistry.FactPayments = factPayments;
        }
        return this.virtualRegistry;
    }

    public onInit(): void {
        const notifyService = this.di<NotifyService>("notifyService");
        this.employeeService = this.di<EmployeeService>("employeeService");
        const dictionariesService = this.di<DictionariesService>("dictionariesService");
        this.infiniteScroll = this.di<InfiniteScrollService<Payment>>("infiniteScroll");
        const NgTableParamsClass = this.di<typeof NgTableParamsType>("NgTableParams");
        this.minDate = new Date(2010, 0);
        this.selection = new UgskSet<Payment>();
        this.employeeResource = this.di<IEmployeeResource>("employeeResource");

        if (!angular.isArray(this.registry.FactPayments)) {
            this.registry.FactPayments = [];
        }

        this.tableParams = new NgTableParamsClass({
            count: 20,
            page: 1,
        }, {
            counts: [],
            getData: (params) => {
                return this.getRequest()
                .skip((params.page() - 1) * params.count())
                .take(params.count())
                .query((payments) => {
                    const infiniteScrollData = new ODataResourceResultClass<Payment>();
                    infiniteScrollData.value = payments.map((payment) => Payment.fromObject(payment));
                    this.foundPayments = this.infiniteScroll.loadData(params, infiniteScrollData);
                    if (!this.foundPayments.length) {
                        notifyService.warningMessage("Платежи по заданным критериям не найдены.");
                    }
                    this.unmask();
                });
            },
        });

        dictionariesService.get("PaymentKinds")
            .then((paymentKinds) => {
                this.paymentKinds = paymentKinds;
                this.PaymentKindId = paymentKinds[0].Id;
            });

        this.updateActualEmployees();

        this.employeeService.event.on("impersonalizated", this.updateActualEmployees);
    }

    public updateActualEmployees() {
        this.employeeResource.getActualFactPaymentsEmployees().$promise.then((employees) => {
            this.cacheEmployeeCollection = angular.copy(employees);
            this.employeeCollection = employees.slice(0, 10);
            this.employeeService.getEmployee().then((currentEmployee) => {
                const currentEmployeeId = currentEmployee.info.Id;
                const [defaultEmployee] = this.cacheEmployeeCollection.filter((empl) => {
                    return empl.EmployeeId === currentEmployeeId;
                });
                if (defaultEmployee) {
                    this.selectedEmployees.push(defaultEmployee);
                }
            });
        });
    }

    public inputStr(str) {
        const strVal = str.toLocaleLowerCase();
        this.employeeCollection = this.cacheEmployeeCollection.filter((item) => {
            return item.Employee.toLocaleLowerCase().indexOf(strVal) >= 0;
        });
    }

    public updatedEmployeesCollection() {
        this.employeeCollection = this.cacheEmployeeCollection
        .filter((employee) => !this.selectedEmployees.includes(employee.EmployeeId))
        .slice(0, 10);
    }

    public getRequest(): IProvider<IFactPaymentODataResource> {
        const factPaymentsService = this.di<FactPaymentsService>("factPaymentsService");
        const [$odata] = this.di(["$odata"]);
        const request = factPaymentsService.odata();
        const fromVal = new Date(this.registry.PeriodFrom).toISOString();
        const toVal = moment(this.registry.PeriodTo).endOf("day").toDate().toISOString();
        let employeeFilterCombination = null;

        const employeeFilter = this.selectedEmployees.map((value) => {
            let id = value;
            if (angular.isObject(value)) {
                id = value.EmployeeId;
            }
            return new $odata.Predicate("EmployeeId", "eq", id);
        });

        if (employeeFilter.length > 0) {
            employeeFilterCombination = $odata.Predicate.or(employeeFilter);
        }

        const filter = [
            new $odata.Predicate("PaymentRegistryId", null),
            new $odata.Predicate("DatePay", "ge", new $odata.Property(fromVal)),
            new $odata.Predicate("DatePay", "le", new $odata.Property(toVal)),
            new $odata.Predicate("PaymentKindId", "eq", this.PaymentKindId),
        ];

        if (employeeFilterCombination) {
            filter.push(employeeFilterCombination);
        }

        if (this.registry.Employee) {
            filter.push(new $odata.Predicate("Employee", "eq", this.registry.Employee));
        }

        const filterCombination = $odata.Predicate.and(filter);

        if (this.tableOrder.by) {
            request.orderBy(this.tableOrder.by, this.tableOrder.direction);
        }

        return request.filter(filterCombination);
    }

    public getPaymentKindName(paymentKindId) {
        return this.paymentKinds.find((paymentKind) => paymentKind.Id === paymentKindId);
    }

    get isNew(): boolean {
        return !(this.virtualRegistry && this.virtualRegistry.Id);
    }

    public searchPayments(): void {
        if (!this.isNew) {
            return;
        }
        if (this.selection) {
            this.selection.clear();
        }
        const validationService = this.di<ValidationService>("validationService");
        validationService.clear();
        this.mask("Поиск платежей");
        this.tableParams.page(1).reload();
    }

    public isAllPaymentsSelected(): boolean {
        if (this.selection) {
            return this.selection.size === this.foundPayments.length;
        }
    }

    public selectAll(): void {
        if (this.isAllPaymentsSelected()) {
            this.selection.clear();
        } else {
            this.foundPayments.forEach((item) => {
                this.selection.add(item);
            });
        }
    }

    public getSelectedPaymentsAmount(): number | string {
        let total = 0;
        if (!this.selection) {
            return "0";
        }
        this.selection.forEach((payment) => {
            total += payment.Amount;
        });
        return total || "0";
    }

    public getFactPaymentsAmount(): number | string {
        return this.registry.FactPayments.reduce((total, current) => {
            return total + current.Amount;
        }, 0) || "0";
    }
}

export const ugskPaymentsRegistryComponent = {
    bindings: {
        employeeFilterEnabled: "<",
        registry: "=ngModel",
    },
    controller: PaymentsRegistryController,
    controllerAs: "vm",
    template,
};
