import { IModalService } from "angular-ui-bootstrap";
import { IContractOwner } from "infrastructure/interfaces";
import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { NotifyService } from "infrastructure/services/notifyService";
import { ChangeOwnerController } from "./changeOwner.controller";
import changeOwnerTemplate from "./changeOwner.html";

export default {
    action(context) {
        const $uibModal = context.di<IModalService>("$uibModal");
        const notifyService = context.di<NotifyService>("notifyService");
        $uibModal.open({
            controller: ChangeOwnerController,
            controllerAs: "vm",
            size: "lg",
            template: changeOwnerTemplate,
        }).result.then((data: {
            selectedEmployee: IContractOwner,
        }) => {
            if (data.selectedEmployee) {
                context.Contract.$reassign(data.selectedEmployee.Employee.Id).then(() => {
                    //  @TODO: move to product specific contract class
                    if (context.product.code === "osago" || context.product.code === "uAuto") {
                        context.Contract.setEmployee(data.selectedEmployee);
                    }
                    context.Contract.setMetadata({
                        OwnerIsChanged: true,
                    });
                    notifyService.successMessage(
                        "Смена владельца договора",
                        "Вы успешно сменили владельца договора",
                    );
                }).catch(() => {
                    notifyService.errorMessage(
                        "Смена владельца договора",
                        "Не удалось сменить владельца договора",
                    );
                });
            }
        });
    },
    disabled(context) {
        const hasPermission = context.employee.hasPermission("Reassign", context.product.code);
        return context.Contract.isBlank() || !context.Contract.isDraft() || !hasPermission;
    },
    icon: "fa-users",
    title: "Сменить владельца",
} as IExtraOptionPrototype;
