import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { UAutoContract } from "../uAuto.factory";
import { UAutoToolbarController } from "../uAutoToolbar.controller";

export default {
    icon: "fa fa-empire",
    title: "Разморозить расчет",
    action(context: UAutoToolbarController) {
        return context.Contract.$unFreeze().then(() => {
            context.$state.reload();
        });
    },
    disabled(context) {
        return !context.Contract.isFrozen();
    },
} as IExtraOptionPrototype;
