import moment from "moment";
import VehicleDocument from "domain/classes/vehicleDocument.class";
import { NgComponentController } from "infrastructure/NgController";
import template from "./ugskVehicledocPicker.component.html";

export default angular.module("ugsk.components.vehicledoc-picker", []).component("ugskVehicledocPicker", {
    bindings: {
        documents: "=ngModel",
        vehicleDocumentTypes: "<",
    },
    controller: class extends NgComponentController {
        onInit() {
            if (!angular.isArray(this.documents)) {
                this.documents = [];
            }
            this.issuedDateConfig = {
                endDate: moment().endOf("day"),
            };
        }
        getVehicleDocumentTypes() {
            return this.vehicleDocumentTypes.map(docType => {
                const isSelected = this.selectedDocument && this.selectedDocument.VehicleDocumentType === docType.VehicleDocumentType;
                docType.disabled = !isSelected && this.documents.some(selectedDocType => selectedDocType.VehicleDocumentType === docType.VehicleDocumentType); // eslint-disable-line
                return docType;
            });
        }

        addDocument() {
            const newDocument = new VehicleDocument();
            this.selectedDocument = newDocument;
            this.documents.push(newDocument);
        }

        removeDocument(document) {
            this.documents = this.documents.filter(item => item !== document);
            this.selectedDocument = undefined;
        }

        selectRow(document) {
            if (this.selectedDocument === document) {
                this.selectedDocument = undefined;
            } else {
                this.selectedDocument = document;
            }
        }

        isDocumentSerialDisabled() {
            const isDocumentTypeSelected = this.selectedDocument && this.selectedDocument.VehicleDocumentType;
            const isEPassportTypeSelected = this.selectedDocument && this.selectedDocument.VehicleDocumentType.toLowerCase() === "электронный паспорт тс";
            return !isDocumentTypeSelected || isEPassportTypeSelected;
        }

        resetSelectedDocumentSerial() {
            if (this.isDocumentSerialDisabled()) {
                this.selectedDocument.VehicleDocSerial = undefined;
            }
        }

        isDocumentEmpty(document) {
            return !document.VehicleDocumentType && !document.VehicleDocSerial && !document.VehicleDocNumber && !document.VehicleDocIssuredDate;
        }

        isDocumentsCompleted() {
            const prevDocRow = this.documents[this.documents.length - 1]
            if (this.documents.length === 0) {
                return true
            } else {
                if (prevDocRow.VehicleDocumentType.toLowerCase() === "электронный паспорт тс") {
                    return prevDocRow.VehicleDocNumber && prevDocRow.VehicleDocIssuredDate
                } else {
                    return prevDocRow.VehicleDocNumber && prevDocRow.VehicleDocIssuredDate && prevDocRow.VehicleDocSerial
                }
            }
        }
    },
    controllerAs: "vm",
    template,
}).name;
