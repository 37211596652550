/**
 * ugskOsagoContractParams - компонента параметров договора
 */
import angular, { ITimeoutService } from "angular";
import {
    CONTRACT_STATUSES,
    EOSAGO_POLICY_SERIAL,
    OSAGO_DRAFT_SERIAL,
    PAYMENT_KINDS,
    USAGE_PURPOSES_ENUM,
} from "application/constants";
import { OsagoContract } from "application/osago/classes";
import { Helpers } from "infrastructure/app.helpers";
import { NgComponentController } from "infrastructure/NgController";
import Repository from "infrastructure/Repository.class";
import { ValidationService } from "infrastructure/services/validation.service";
import { Day } from "infrastructure/types";
import moment, { Moment, MomentInput } from "moment";

import { ReliableDriveContract } from "application/osago/classes/ReliableDriveContract";
import RelatedProductService from "application/osago/relatedProduct.service";
import policySerialsDescriptionTemplate from "./policySerialsDescription.html";

export interface IDictionaryCountryItem {
    VehicleCountryId: number;
    VehicleCountryName: string;
}

const termDurationTextMap = {
    год: "years",
    года: "years",
    годов: "years",
    день: "days",
    дней: "days",
    дня: "days",
    лет: "years",
    месяц: "months",
    месяца: "months",
    месяцев: "months",
    недели: "weeks",
    недель: "weeks",
    неделя: "weeks",
};

interface IContractToRange {
    allowToPick: boolean;
    defaultValue: Moment;
    max: MomentInput | boolean;
    min: MomentInput | boolean;
}

interface IParseRule {
    example: string | string[];
    re: RegExp;
    fn: (parts: RegExpMatchArray) => IContractToRange;
}

interface ISerialItem {
    description: string;
    prefix: string;
}

// tslint:disable-next-line:max-line-length
export const parseContractToRange = (term: string, baseMomentDate: Day, vehicleRegistration?: string): IContractToRange | undefined => {
    const foreignRegistrationChoices = [
        "зарегистрированные не в РФ и не в Казахстане, временно используемые в РФ",
        "зарегистрированные в Казахстане, временно используемые в РФ",
    ];
    const base = moment(baseMomentDate).parseZone();
    const parseRules: IParseRule[] = [{
        example: "от 5 до 15 дней",
        fn: (parts: RegExpMatchArray): IContractToRange => {
            const min = moment(base).add(parts[1], termDurationTextMap[parts[3]]);
            const max = moment(base).add(parts[2], termDurationTextMap[parts[3]]);
            return {
                allowToPick: true,
                defaultValue: max,
                max,
                min,
            };
        },
        re: /от\s(\d{1,})\sдо\s(\d{1,})\s([^\s]{1,})/i,
    }, {
        example: "от 16 дней до 1 месяца",
        fn: (parts: RegExpMatchArray): IContractToRange => {
            const min = moment(base).add(parts[1], termDurationTextMap[parts[2]]);
            const max = moment(base).add(parts[3], termDurationTextMap[parts[4]]);
            return {
                allowToPick: true,
                defaultValue: max,
                max,
                min,
            };
        },
        re: /от\s(\d{1,})\s([^\s]{1,})\sдо\s(\d{1,})\s([^\s]{1,})/i,
    }, {
        example: ["до 3 месяцев включительно"],
        fn: (parts: RegExpMatchArray): IContractToRange => {
            const min = moment(base).add(1, "second").endOf("day");
            if ((vehicleRegistration === "зарегистрированные в РФ")) {
                const result = parseContractToRange(`до ${parts[1]} ${parts[2]}`, baseMomentDate);
                result.allowToPick = true;
                result.min = moment(base).add(1, "day").endOf("day");
                result.max = moment(min).add(parts[1], termDurationTextMap[parts[2]]).endOf("day");
                return result;
            }
            const max = false;
            return {
                allowToPick: true,
                defaultValue: moment(min),
                max,
                min,
            };
        },
        re: /^до\s(\d{1,})\s([^\s]{1,})\sвключительно/i,
    }, {
        example: "до 20 дней",
        fn: (parts: RegExpMatchArray): IContractToRange => ({
            allowToPick: false,
            defaultValue: moment(base).add(parts[1], termDurationTextMap[parts[2]]),
            max: false,
            min: false,
        }),
        re: /^до\s(\d{1,})\s([^\s]{1,})/i,
    }, {
        example: "10 месяцев и более",
        fn: (parts: RegExpMatchArray): IContractToRange => {
            const min = moment(base).add(1, "years").subtract(1, "day").endOf("day");
            if ((parts[0] === "10 месяцев и более") && foreignRegistrationChoices.includes(vehicleRegistration)) {
                const result = parseContractToRange("1 год", baseMomentDate);
                result.allowToPick = true;
                result.min = moment(base).add(10, "months");
                result.max = result.defaultValue;
                return result;
            }
            const max = false;
            return {
                allowToPick: true,
                defaultValue: moment(min),
                max,
                min,
            };
        },
        re: /^([\d]{1,})\s([^\s]{1,})\sи более/i,
    }, {
        example: ["2 месяца", "1 год"],
        fn: (parts: RegExpMatchArray): IContractToRange => ({
            allowToPick: false,
            defaultValue: moment(base).add(parseInt(parts[1], 10), termDurationTextMap[parts[2]]),
            max: false,
            min: false,
        }),
        re: /^([\d]{1,})\s([^\s]{1,})/i,
    }];
    let result: IContractToRange;
    parseRules.find((rule) => {
        const reResult = term.match(rule.re);
        if (reResult) {
            result = rule.fn(reResult);
            return true;
        }
        return false;
    });
    return result;
};

class OsagoContractParams extends NgComponentController {
    public formIsLocked: boolean;
    public contract: OsagoContract;
    public contractToAllowToPick: boolean;
    public contractToMinDate: MomentInput | boolean;
    public contractToMaxDate: Moment | boolean;
    public contractFromMinDate: Moment;
    public contractFromMaxDate: Moment | boolean;
    public editableCountry: boolean;
    public hideReliableRide = false;
    public maxContractDate = moment().format("YYYY-MM-DD");
    public onRelatedProductChanged: ({ relatedProductName }) => void;
    public relatedProductService: RelatedProductService = null;
    public serialPolicyInfo: ISerialItem[] = [
        {
            description: "оформление электронного ОСАГО",
            prefix: "ХХХ",
        },
        {
            description: `оформление бумажного ОСАГО,
                        когда номер БСО заполняется из типографского бланка`,
            prefix: "МММ, ККК, ННН, РРР",
        },
        {
            description: `оформление бумажного ОСАГО, когда номер БСО автоматически
                        заполняется РСА при успешном оформлении договора`,
            prefix: "ТТТ",
        }];
    // @callback
    public onVehicleRegistrationChanged: () => void;
    public repository: Repository;

    private cachedDictionaryCountry: IDictionaryCountryItem[];
    private relatedProductContract: ReliableDriveContract = null;
    private employeeFilial = "";
    private defaultPaymentVariant = "Иное";

    private VehicleCountryIdRF: number = 643;
    private VehicleCountryIdKZ: number = 398;
    private VehicleRegistrationNotInRFNotInKZUseInRf: string = "зарегистрированные не в РФ и не в Казахстане, временно используемые в РФ";
    private VehicleRegistrationInKZUseInRf: string = "зарегистрированные в Казахстане, временно используемые в РФ";

    public updateSigningDate(): void {
        const contractSigningDate = moment().add(10, "minute").startOf("minute");
        this.contract.SigningDate = contractSigningDate.format();
    }

    public showPolicySerialsDescriptionModal() {
        const helpers = this.di<Helpers>("helpers");
        helpers.showInformationWindow(policySerialsDescriptionTemplate, "Расшифровки серий полиса");
    }

    public getBaseDate() {
        return moment(this.contract.SigningDate).isBefore(this.contractFromMinDate)
            ? this.contractFromMinDate
            : this.contract.SigningDate;
    }

    public updateContractFromDate(): void {
        const baseDate = this.getBaseDate();
        if (!this.contract.Id) {
            const registrationTypes = [
                this.VehicleRegistrationNotInRFNotInKZUseInRf,
                this.VehicleRegistrationInKZUseInRf,
            ];
            if (registrationTypes.includes(this.contract.VehicleRegistration)) {
                this.contract.ContractFrom = moment(baseDate).add(15, "minutes").format();
            } else {
                this.contract.ContractFrom = moment(baseDate).add(1, "day").startOf("day").format();
            }
        }
    }

    public updateContractTo(): void {
        // tslint:disable-next-line:max-line-length
        if (this.contract.ContractFrom) {
            const contractToRange = parseContractToRange(this.contract.InsuranceTerm, this.contract.ContractFrom, this.contract.VehicleRegistration);
            this.contract.ContractTo = contractToRange.defaultValue.startOf("day").subtract(1, "seconds").format();
        }
    }

    public formatCountryLabel(model) {
        const country = this.repository.VehicleCountry.Collection.find((item) => model === item.VehicleCountryId);
        if (!country) {
            return undefined;
        }
        return country.VehicleCountryName;
    }

    public updateContractToWrapper(): void {
        if (this.contract.isLocked()) {
            return;
        }

        if (this.contract.ContractFrom) {
            // tslint:disable-next-line:max-line-length
            const contractToRange = parseContractToRange(this.contract.InsuranceTerm, this.contract.ContractFrom, this.contract.VehicleRegistration);
            this.contractToAllowToPick = contractToRange.allowToPick;
            this.contractToMinDate = contractToRange.min;
            if (contractToRange.allowToPick) {
                this.contractToMinDate = contractToRange.min
                    // tslint:disable-next-line:max-line-length
                    ? moment(contractToRange.min as MomentInput).startOf("day").subtract(1, "day").format("YYYY-MM-DDT00:00")
                    : this.contract.ContractFrom;
                this.contractToMaxDate = contractToRange.max
                    ? moment(contractToRange.max as MomentInput).startOf("day").subtract(1, "seconds")
                    : moment(this.contract.ContractFrom).add(12, "months").startOf("day").subtract(1, "second");
            } else {
                this.contractToMaxDate = false;
                this.contractToMinDate = false;
            }
        }

        this.updateContractTo();
    }

    public isContractFromOutdated(): boolean {
        const baseDate = this.getBaseDate();
        return (!this.contract.ContractFrom)
            ? true
            : moment(this.contract.ContractFrom).isSameOrBefore(moment(baseDate));
    }

    public vehicleRegistrationChanged(): void {
        this.filteredDictionaryCountry();
        this.updateContractFromDate();
        this.updateContractTo();
        this.onVehicleRegistrationChanged();
    }

    public onInit(): void {
        this.relatedProductService = this.di("relatedProductService");
        this.cachedDictionaryCountry = angular.copy(this.repository.VehicleCountry.Collection);
        if (!this.contract.isLocked() && !this.contract.isReturnedForRevision()) {
            const $interval = this.di<angular.IIntervalService>("$interval");
            $interval(() => {
                if (
                    !this.contract.isLocked()
                    && !this.contract.isReturnedForRevision()
                    && moment(this.contract.SigningDate).isBefore(moment().add(2, "m"))
                ) {
                    this.updateSigningDate();
                }
            }, 60 * 1000);

            this.$watch(() => this.contract.SigningDate, () => {
                if (this.isContractFromOutdated()) {
                    this.updateContractFromDate();
                }
            });

            const validationService = this.di<ValidationService>("validationService");
            const $timeout = this.di<ITimeoutService>("$timeout");
            this.$watch(() => {
                if (Array.isArray(this.contract.FactPayments) && this.contract.FactPayments.length > 0) {
                    return this.contract.FactPayments[0].DatePay;
                }
            }, (datePay) => {
                if (this.contract.isLocked()) {
                    return;
                }
            });

            this.$watch(() => this.contract.ContractFrom, () => this.updateContractToWrapper());
            this.$watch(() => this.contract.InsuranceTerm, () => this.updateContractToWrapper());

            this.$watch(() => this.contract.ContractTo, (value) => {
                this.resetContractToDate(value);
            });

            this.$watch(() => this.contract.FactPayments[0].PaymentKindId, (value) => {
                if (value === PAYMENT_KINDS.INTERNET_ACQUIRING) {
                    if (!this.isContractFromValid()) {
                        this.contractFromMinDate = moment(this.contract.SigningDate).add(1, "hour");
                        this.updateContractFromDate();
                    }
                }
            });

            this.updateSigningDate();

            this.contractFromMinDate = moment(this.contract.SigningDate);
            this.contractFromMaxDate = moment(this.contract.SigningDate).add(60, "days");
        }
        if (!this.contract.isLocked() && this.contract.isReturnedForRevision()) {
            this.$watch(() => this.contract.ContractFrom, () => this.updateContractToWrapper());
        }

        if (this.contract.ContractStatusId === 1) {
            this.contract.FactPayments[0].DatePay = this.contract.SigningDate;
        }

        this.filteredDictionaryCountry();

        this.setInitialPaymentVariant();

        this.contract.IsEOsago = this.contract.isEOSAGO();

        this.editableCountry = true;
    }

    private setInitialPaymentVariant(): void {
        if (this.defaultPaymentVariant && !this.contract.PaymentVariantId) {
            const variant = this.repository.PaymentVariant.Collection.find((item) => item.PaymentVariantName.trim().toLowerCase() === this.defaultPaymentVariant.trim().toLowerCase());
            if (variant) {
                this.contract.PaymentVariantId = variant.PaymentVariantId;
            }
        }
    }

    public resetContractFromTime(oldVal: Moment, newVal: Moment): void {
        if (newVal.isValid()) {
            const oldDate = oldVal.format("YYYYMMDD");
            const newDate = newVal.format("YYYYMMDD");
            if (oldDate !== newDate) {
                this.contract.ContractFrom = moment(newVal).hour(0).minute(0).second(0).format();
            }
        }
    }

    public resetContractToDate(newVal): void {
        // tslint:disable-next-line:max-line-length
        const contractToRange = parseContractToRange(this.contract.InsuranceTerm, this.contract.ContractFrom, this.contract.VehicleRegistration);
        const contractToMaxDate = contractToRange.defaultValue.startOf("day").subtract(1, "seconds");
        const nowValue = moment(newVal).hours(23).minutes(59).seconds(59);

        this.contract.ContractTo = (nowValue.isBefore(contractToMaxDate))
            ? nowValue.format()
            : contractToMaxDate.format();
    }

    public onPolicySerialChanged(newPolicySerial: string) {
        this.contract.PolicySerial = newPolicySerial;
        this.contract.IsEOsago = newPolicySerial === EOSAGO_POLICY_SERIAL;

        if (newPolicySerial === EOSAGO_POLICY_SERIAL) {
            this.contract.PolicyNumber = null;
        }
    }

    public setValidContractFrom(momentValue: Moment): Moment {
        const signingDateMoment = moment(this.contract.SigningDate);
        return signingDateMoment.isAfter(momentValue) ? signingDateMoment : momentValue;
    }

    public isContractFromValid() {
        if (this.contract.FactPayments[0].PaymentKindId === PAYMENT_KINDS.INTERNET_ACQUIRING) {
            return moment(this.contract.ContractFrom).isAfter(moment(this.contract.SigningDate).add(1, "hour"));
        }

        return moment(this.contract.ContractFrom).isAfter(moment(this.contract.SigningDate));
    }

    public isPolicyNumberDisabled() {
        return [OSAGO_DRAFT_SERIAL, EOSAGO_POLICY_SERIAL].includes(this.contract.PolicySerial);
    }

    public onIsEgarantChanged(): void {
        if (this.contract.IsEgarant) {
            this.contract.FactPayments[0].PaymentKindId = PAYMENT_KINDS.INTERNET_ACQUIRING;
        } else {
            this.contract.FactPayments[0].PaymentKindId = PAYMENT_KINDS.CASH;
        }
    }

    public isDisabledVehicleRegistrationCountry() {
        const rules = {
            "зарегистрированные в Казахстане, временно используемые в РФ": () => {
                this.contract.VehicleCountryId = this.VehicleCountryIdKZ;
                this.editableCountry = true;
                return true;
            },
            "зарегистрированные в РФ": () => {
                this.contract.VehicleCountryId = this.VehicleCountryIdRF;
                this.editableCountry = true;
                return true;
            },
            "зарегистрированные не в РФ и не в Казахстане, временно используемые в РФ": () => {
                this.editableCountry = true;
                return false;
            },
            "приобретенные в РФ, следующие к месту регистрации": () => {
                if (this.editableCountry === true) {
                    this.contract.VehicleCountryId = this.VehicleCountryIdRF;
                    this.editableCountry = false;
                }
                return false;
            },
        };
        return ((code) => rules[code]())(this.contract.VehicleRegistration);
    }

    public getFirstFactPaymentMinDate() {
        if ([PAYMENT_KINDS.PAYMENT_ORDER, PAYMENT_KINDS.OFFSET_AWARD, PAYMENT_KINDS.INVOICE]
            .includes(this.contract.FactPayments[0].PaymentKindId)
        ) {
            return undefined;
        }
        return moment(this.contract.SigningDate).startOf("day").format();
    }

    public getFirstFactPaymentMaxDate() {
        if (this.contract.FactPayments[0].PaymentKindId === PAYMENT_KINDS.INTERNET_ACQUIRING) {
            return moment(this.contract.ContractFrom).endOf("day").format();
        }
    }

    public getInsuredPersonFullName(): string {
        if (this.contract.isInsurantALegalEntity()) {
            return this.contract.InsuredOrgName;
        }

        return [
            this.contract.InsuredPersonLastName,
            this.contract.InsuredPersonFirstName,
            this.contract.InsuredPersonLastName,
        ].filter((item) => item).join(" ");
    }

    public getInsuredPhone(): string {
        if (this.contract.InsurantPhones && this.contract.InsurantPhones.length > 0) {
            return this.contract.InsurantPhones[0].Number;
        }

        return "";
    }

    get showEgarantControl() {
        return [CONTRACT_STATUSES.PREPARED_FOR_SIGNING, CONTRACT_STATUSES.DRAFT].includes(this.contract.getStatus()) && this.contract.IsEgarant;
    }

    public isEGFeatureDisabled(): boolean {
        return this.contract.UsagePurpose === USAGE_PURPOSES_ENUM.TAXI;
    }

    public isRelatedProductDisabled(): boolean {
        if (this.relatedProductContract.isManualCancel() || this.contract.isBlank()) {
            return true;
        }

        return this.relatedProductContract.isActive() && !this.relatedProductContract.isUserChoice();
    }

    public set relatedProduct(value: string) {
        this.relatedProductService.selectProduct(value);
    }

    public get relatedProduct(): string {
        return this.relatedProductService.getSelectedProduct();
    }

    private filteredDictionaryCountry() {
        if (this.contract.VehicleRegistration !== this.VehicleRegistrationNotInRFNotInKZUseInRf) {
            this.repository.VehicleCountry.Collection = this.cachedDictionaryCountry;
        } else {
            this.repository.VehicleCountry.Collection = this.cachedDictionaryCountry
                .filter((item) => ![this.VehicleCountryIdRF, this.VehicleCountryIdKZ].includes(item.VehicleCountryId));

            const countryInCollect = this.repository.VehicleCountry.Collection.some((country) => country.VehicleCountryId === this.contract.VehicleCountryId);
            if (!countryInCollect) {
                this.contract.VehicleCountryId = null;
            }
        }
    }
}

export default angular.module("app.osago.components.contract-params", []).component("ugskOsagoContractParams", {
    bindings: {
        contract: "<",
        employeeFilial: "<",
        formIsLocked: "<",
        hasEgSignPermission: "&",
        hideReliableRide: "<",
        onRelatedProductChanged: "&",
        onVehicleRegistrationChanged: "&",
        relatedProductContract: "<",
        repository: "<",
    },
    controller: OsagoContractParams,
    controllerAs: "vm",
    template: require("./ugskOsagoContractParams.component.html"),
}).name;
