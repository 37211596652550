import { INgModelController } from "angular";
import $ from "jquery";

type TThousandFilter = (val: string | number, postfix?: string, emptyPlaceholder?: string) => string;

export function ThousandDirective(
    thousandFilter: TThousandFilter,
    $timeout: ng.ITimeoutService,
): ng.IDirective {
    "ngInject";

    function toThousand(val, attributes, split): string | number {
        if (val && ("decimalFormat" in attributes)) {
            val = val.toString();
            if (!val.split(split)[1]) {
                val = `${thousandFilter(val)}.00`;
            } else {
                const splited = val.split(split);
                val = `${thousandFilter(splited[0])}.${splited[1]}`;
            }
        } else {
            val = thousandFilter(val);
        }
        return val;
    }

    return {
        link: (scope, element: JQuery<HTMLInputElement>, attributes, controller: INgModelController) => {
            const elementInput = (element[0]);
            element.bind("blur", () => {
                let value: string | number = elementInput.value.toString().replace(",", ".").replace(/[^-0-9\.]/g, "");
                value = toThousand(value, attributes, ".");
                element.val(value);
            });

            element.bind("focus", () => {
                const value: string = elementInput.value;
                const position = elementInput.selectionEnd;
                let clean = value.replace(".", ",");
                clean = clean.replace(/[^-0-9\,]/g, "");

                controller.$setViewValue(clean);
                controller.$render();
                // Установка курсора в ту позицию, на которую "ткнули"
                if (clean) {
                    elementInput.setSelectionRange(position, position);
                }
            });

            $timeout(() => {
                let value: string | number = elementInput.value.toString().replace(",", ".").replace(/[^-0-9\.]/g, "");
                value = toThousand(value, attributes, ".");
                element.val(value);
            });
            controller.$formatters.push((val: number | string) => {
                val = toThousand(val, attributes, ",");
                if ($(element).is(":active")) {
                    return val;
                }
                return val === 0 ? "" : val;
            });
        },
        require: "ngModel",
        restrict: "A",
        scope: false,
    };
}
