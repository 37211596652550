import { Contract } from "domain/classes/contract.class";
import IProlongableContract from "infrastructure/interfaces/IProlongableContract";
import { Int } from "infrastructure/types";
import constructable from "infrastructure/types/constructable";

export default function <T extends constructable<Contract>>(constructor: T) {
    return class extends constructor implements IProlongableContract {
        public CSIAPreviousContractId: Int;
        public PreviousContractId: number;
        public PreviousContractNumber: string;
        public PreviousPolicySerial: string;
        public removePreviousContract(): void {
            this.PreviousContractId = null;
            this.PreviousContractNumber = null;
            this.PreviousPolicySerial = null;
            this.CSIAPreviousContractId = null;
        }
    };
}
