import { NgComponentController } from "infrastructure/NgController";
import template from "./ugskPolicySerial.component.html";

angular.module("ugsk.components.ugskSerial", []).component("ugskPolicySerial", {
    bindings: {
        contract: "=",
        serialCollection: "<",
    },
    controller: class extends NgComponentController {
        getPolicySerial() {
            const item = this.serialCollection.find(value => value.PolicySerialId === this.contract.PolicySerialId);
            return item ? item.PolicySerial : "";
        }
    },
    controllerAs: "vm",
    template,
});
