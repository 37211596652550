import { NgComponentController } from "infrastructure/NgController";
import template from "./ugskMovableProperty.component.html";

export default angular.module("ugsk.components.movableProperty", []).component("ugskMovableProperty", {
    bindings: {
        minYear: "<",
        model: "=movableProperty",
        removeProperty: "&",
        title: "<",
        tooltipOptionsPopover: "<",
    },
    controller: class extends NgComponentController {
        onInit() {
            const [datePickerBaseYearOptions] = this.di(["datePickerBaseYearOptions"]);
            this.movablePropertyYearOptions = angular.copy(datePickerBaseYearOptions);
            this.movablePropertyYearOptions.startDate = this.minYear;
        }
        clearPropertyGroupValidation() {
            const guid = this.model.Guid;
            const isGroup = this.model.IsGroup;
            if (isGroup && guid) {
                const [validationService] = this.di(["validationService"]);
                const namePurchaseYear = `PurchaseYear${guid}`;
                const namePurchaseCost = `PurchaseCost${guid}`;
                validationService.removeError(namePurchaseYear);
                validationService.removeError(namePurchaseCost);
            }
            this.model.PurchaseCost = null;
            this.model.PurchaseYear = null;
        }
    },
    controllerAs: "vm",
    template,
}).name;
