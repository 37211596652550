import angular, { IHttpService } from "angular";
import { IModalService } from "angular-ui-bootstrap";
import { NgControllerBase } from "infrastructure/NgController";
import { EnvService } from "infrastructure/services/env.service";

import template from "./ugskPublicPayment.html";
import { PublicPaymentModalController } from "./ugskPublicPaymentModal.controller";
import modalTemplate from "./ugskPublicPaymentModal.html";

class UgskPublicPaymentModalController extends NgControllerBase {
    public isPayed: boolean;
    public isPaymentAvailable: boolean;
    public contractId: number;
    private productCode: string;

    public onPayButtonClicked(): void {
        const $uibModal = this.di<IModalService>("$uibModal");
        const $http = this.di<IHttpService>("$http");
        const envService = this.di<EnvService>("envService");

        $uibModal.open({
            controller: PublicPaymentModalController,
            controllerAs: "vm",
            resolve: {
                contractId: () => this.contractId,
                productCode: () => this.productCode,
                currentLink: () => $http({ _suppressErrors: true, url: `${envService.read("apiUrl")}/${this.productCode}/GetAcquiringLink/${this.contractId}` } as any).then(({ data }) => data).catch(() => {}),
            },
            size: "lg",
            template: modalTemplate,
        });
    }
}

export default angular
    .module("ugsk.components.public-payment", [])
    .component("ugskPublicPayment", {
        bindings: {
            contractId: "<",
            isPaymentAvailable: "<",
            productCode: "@",
        },
        controller: UgskPublicPaymentModalController,
        controllerAs: "vm",
        template,
    }).name;
