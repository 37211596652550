import { Contract } from "domain/classes/contract.class";
import UgskPhone from "domain/classes/ugsk-phone.class";
import { BoxAccidentContractDTO } from "infrastructure/interfaces/WebApi/BoxAccidentContractDTO";
import { BoxAccidentInsuredPersonDTO } from "infrastructure/interfaces/WebApi/BoxAccidentInsuredPersonDTO";
import { PhoneNumberDTO } from "infrastructure/interfaces/WebApi/PhoneNumberDTO";
import { Decimal, Guid, Int } from "infrastructure/types";
import { Uuid } from "lib/uuid";

export class BoxAccidentContractDTOBase extends Contract implements BoxAccidentContractDTO {
    public InsuredGuid: Guid = Uuid.raw();
    public InsuranceAmountVariantId: Int;
    public InsuranceProgramId: Int;
    public InsuranceTermId: Int;
    public InsuredDocTypeId: Int;
    public InsuranceProductId: Int;

    public InsuredDocSerial: string;
    public InsuredDocNumber: string; // Номер документа
    public InsuredDocWhomGiven: string; // Кем выдан
    public InsuredDocDateGiven: string; // Дата выдачи
    public InsuredLastName: string; // Фамилия
    public InsuredFirstName: string; // Имя
    public InsuredMiddleName: string; // Отчество
    public InsuredBirthday: string; // Дата рождения
    public InsuredAddressUserDefinedAddress: string; // Адрес страхователя
    public InsuredAddressFiasId: string; // ФИАС ID
    public InsuredAddressKladrId: string; // КЛАДР ID
    public InsuredEmail: string; // email
    public InsuredPersons: BoxAccidentInsuredPersonDTO[] = [];
    public InsuredAddressWithoutHomeNumber = false;

    // юридическое лицо
    public InsuredOrgINN: string; // ИНН
    public OGRN: string; // ОГРН
    public EGRYLRegistrationDate: string; // Дата регистрации
    public InsuredOrgName: string; // Наименование организации
    public InsuredOrgEGRYL: string; // ЕГРЮЛ
    // Индивидуальный предприниматель
    public EGRIPRegistrationDate: string; // Дата регистрации
    public EGRIP: string = null;
    // уполномоченный представитель
    public InsuredRepresentativeName: string; // ФИО
    public InsuredRepresentativeJobPosition: string; // Должность
    public InsuredRepresentativeDoc: string; // Документ на основании которого действует представитель

    // оплата
    public Discount: number; // Скидка по договору
    public UnderwriterCoefficient: Decimal; // Коэффициент андеррайтера
    public SpecialNote: string; // Особые отметки

    public ContractStatusName: string;
    public PolicySerialId: Int;
    public FilialId: Int;
    public InsuredId: Int;
    public InsuredDocOrgCode: string;
    public InsuredBirthPlace: string;
    public InsuredPhoneVerificationId: string;
    public InsuredINN: string;
    public OGRNIP: string;
    public InsuredRegistrationDate: string;
    public EGRYL: string;
    public IsReceiptRequired: boolean;
    public IsNumberRequired: boolean;
    public VehicleId: Int;
    public VehicleGuid: string;
    public ModelVehicle: string;
    public BrandVehicle: string;
    public VehicleVIN: string;
    public StateLicensePlate: string;
    public InsuredPhones: PhoneNumberDTO[];

    public getInsurantFields(): string[] {
        if (this.isInsurantANaturalPerson() || this.isInsurantASoleProprietor()) {
            return [
                "InsuredLastName",
                "InsuredFirstName",
                "InsuredMiddleName",
            ];
        }
        if (this.isInsurantALegalEntity()) {
            return ["InsuredOrgName"];
        }
        throw new Error("unknown insurant type");
    }
}

export class BoxAccidentPersonBase implements BoxAccidentInsuredPersonDTO {
    public Guid: Guid = Uuid.raw(); // Guid
    public InsuredPersonLastName: string = undefined; // Фамилия
    public InsuredPersonFirstName: string = undefined; // Имя
    public InsuredPersonMiddleName: string = undefined; // Отчество
    public InsuredPersonBirthday: string = undefined; // Дата рождения
    public InsuredPersonDocTypeId: Int = 12; // Тип документа
    public InsuredPersonDocSerial: string = undefined; // Серия документа
    public InsuredPersonDocNumber: string = undefined; // Номер документа
    public InsuredPersonDocDateGiven: string = undefined; // Дата выдачи
    public InsuredPersonDocWhomGiven: string = undefined; // Кем выдан
    public InsuredPersonEmail: string = undefined; // Email
    public InsuredPersonAddressUserDefinedAddress: string = undefined; // Адрес
    public InsuredPersonAddressFiasId: string = undefined; // ФИАС ID
    public InsuredPersonAddressKladrId: string = undefined; // КЛАДР ID
    public InsuredPersonPhones: PhoneNumberDTO[] = []; // Номер телефона застрахованного
    public ContractorId: Int;
    public ContractId: Int;
    public InsuredPersonPremium: Decimal;
    public SpecialNote: string;
    public InsuredPersonAddressWithoutHomeNumber: boolean;
}
