import { BaseRepository } from "domain/classes/baseRepository.class";
import { PropertyPartDTO } from "infrastructure/interfaces/WebApi/PropertyPartDTO";
import { Decimal, Int } from "infrastructure/types";
import { Uuid } from "lib/uuid";

export default class PropertyPart extends BaseRepository implements PropertyPartDTO {
    public NeedToInsureInteriorOfPropertyPart: boolean;
    public PropertyPartTypeId: number;
    public Guid: string = Uuid.raw();
    public WallMaterialId: number = null;
    public OtherWallMaterial: string = null;
    public InteriorCategoryId: number = null;
    public StructuralElementId: number = 0;
    public StructuralElementSquare: number = null;
    public StructuralElementCost: number = 0;
    public StructuralElementCalculatedAmount: number = 0;
    public StructuralElementDeclaredAmount: number = 0;
    public InteriorSquareEnabled: boolean = false;
    public InteriorId: number = 0;
    public InteriorSquare: number = null;
    public InteriorCost: number = 0;
    public InteriorCalculatedAmount: number = 0;
    public InteriorDeclaredAmount: number = 0;
    public NeedToInsureInterior: boolean = false;
    public PropertyIsFinished: boolean = false;
    public NeedToInsureStructuralElement: boolean = false;
    public ConstructionYear: number = null;
    public InteriorRepairYear: number = null;
    public Id: Int;
    public ConstructionTypeId: Int;
    public StructuralElementCalculatedMinAmount: Decimal;
    public StructuralElementCalculatedMaxAmount: Decimal;
    public InteriorCalculatedMinAmount: Decimal;
    public InteriorCalculatedMaxAmount: Decimal;

    public constructor(id: number) {
        super();
        this.PropertyPartTypeId = id; // Тип части ОС (1/2/3 этаж, мансарда, веранда)
    }
}
