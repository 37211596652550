import { Transition } from "@uirouter/core";
import { PersonalAccidentToolbarController } from "../personalAccident-toolbar.controller";

export class PersonalAccidentJournalToolbarController extends PersonalAccidentToolbarController {
    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
    ) {
        super($injector, $transition, {
            viewType: "journal",
        });
    }
}
