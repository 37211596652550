import template from "./ugskProgressBar.component.html";

export default angular.module("ugsk.components.progress-bar", []).component("ugskProgressBar", {
    bindings: {
        barClass: "<",
        value: "<",
    },
    controller: class {
        getValue() {
            if (this.value <= 1) {
                return Number(this.value * 100).toFixed(2);
            }
            return this.value;
        }

        getStyle() {
            return {
                width: `${this.getValue()}%`,
            };
        }

        getBarClass() {
            return (this.barClass) ? this.barClass : "progress-bar-info";
        }
    },
    controllerAs: "vm",
    template,
}).name;
