import { Ng1ViewDeclaration } from "@uirouter/angularjs";
import { BaseProductBodyController } from "application/baseProduct.body.controller";
import { BaseToolbarController } from "application/baseProduct.toolbar.controller";
import { ProductsJournalController } from "application/journal/journal.controller";
import { StaticValidationRules } from "application/osago/static.validation.rules";
import { Int } from "infrastructure/types";
import { IPromise } from "q";
import { Contract } from "./contract.class";
/** Product class definition */

interface IProductInfo {
    ProductName: string;
    TariffDate: string;
    TariffVersion: string;
}

export class Product {
    /**
     * Свойство, указывающее на тип договора, к которому необходимо привести
     * результаты ответа с Backend
     *
     * @readonly
     * @memberof Product
     */
    public version: IProductInfo;
    public staticValidationRules: StaticValidationRules;
    public code: string;
    public groupName: string;
    public productName: string;
    public originalProductName?: string;
    public description: string;
    public insurerCanBeLegalEntity: boolean;
    public logo: string;
    public manualUrl: string;
    public sort: Int;
    public template: string;
    public hasImageRepositoryAbility: boolean = false;
    public subLicenses: string[] = [];
    public controller: typeof BaseProductBodyController;
    public toolbarController: typeof BaseToolbarController;

    public journalController: typeof ProductsJournalController;
    public journalToolbarController: typeof BaseToolbarController;
    public hasInspectionConclusion: boolean;
    public extraViews: {
        [key: string]: Ng1ViewDeclaration;
    };
    public insuranceRules?: {
        base: string;
        insurancePrograms?: Array<{
            programName: string;
            insuranceRules: string;
        }>
    };
    public subProducts: Array<{
        code: string,
        productName: string,
        productId: number,
        sort: number,
        insuranceRules?: string;
    }>;
    public navigation: Array<{
        target: string;
        title: string;
    }>;
    public extraResolve: {
        [key: string]: (...args: any[]) => void | IPromise<any>;
    };
    // TODO TS2416: Property 'contractClass' in type 'UAuto' is not assignable to the same property in base type 'Product'
    // Подумать как убрать any так, чтобы не вылезала эта ошибка
    get contractClass(): typeof Contract {
        return Contract;
    }
    public setVersion(version: IProductInfo) {
        this.version = version;
    }
    public getInsuranceRulesUrl(insuranceProgramName?: string, subProductCode?: string): string {
        if (!insuranceProgramName && !subProductCode) {
            return this.insuranceRules ? this.insuranceRules.base : undefined;
        }

        if (insuranceProgramName) {
            const insuranceProgramRulesInfo = this.insuranceRules && this.insuranceRules.insurancePrograms
                ? this.insuranceRules.insurancePrograms.find((item) => item.programName === insuranceProgramName)
                : null;

            if (insuranceProgramRulesInfo) {
                return insuranceProgramRulesInfo.insuranceRules;
            } else if (this.insuranceRules && this.insuranceRules.base) {
                return this.insuranceRules.base;
            }

            return;
        }

        if (subProductCode) {
            const subProductInfo = this.subProducts
                ? this.subProducts.find((item) => item.code === subProductCode)
                : null;

            if (subProductInfo) {
                return subProductInfo.insuranceRules;
            }
            return;
        }

        return;
    }
}
