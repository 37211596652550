import angular from "angular";
import { Contract } from "domain/classes/contract.class";
import { resetable } from "domain/mixes/resetable.mix";
import { Guid } from "infrastructure/types";
import { Uuid } from "lib/uuid";
import { Proxy } from "./proxy.class";

export class ContractorProxy extends resetable(Proxy) {
    public guid: Guid;

    constructor(source: Contract) {
        super(source);

        const defaults = {};
        angular.forEach(this.getRules(), (sourceProp, selfProp) => {
            defaults[selfProp] = source[sourceProp];
        });
        this.setDefaultValues(defaults);
    }
    //  @override
    public reset(): void {
        angular.forEach(this.getRules(), (val, prop) => {
            this[prop] = null;
        });
        super.reset();
        this.guid = Uuid.raw();
    }
    public isIndividual(): boolean {
        return this.contractorType === 1;
    }
    public isLegalEntity(): boolean {
        return this.contractorType === 2;
    }
    public isSoleProprietor(): boolean {
        return this.contractorType === 3;
    }
}

export class InsurantPersonProxy extends ContractorProxy {
    static get proxyRules() {
        return {
            EGRIPRegistrationDate: "EGRIPRegistrationDate",
            contractorType: "InsuredContractorTypeId",
            documentType: "InsuredDocTypeId",
            docSerial: "InsuredDocSerial",
            docNumber: "InsuredDocNumber",
            docWhomGiven: "InsuredDocWhomGiven",
            docDateGiven: "InsuredDocDateGiven",
            lastName: "InsuredLastName",
            firstName: "InsuredFirstName",
            middleName: "InsuredMiddleName",
            birthday: "InsuredBirthday",
            phones: "InsuredPersonPhones",
            birthPlace: "InsuredBirthPlace",
            docOrgCode: "InsuredDocOrgCode",
            commonRealAddress: "InsuredPersonAddressUserDefinedAddress",
            fiasId: "InsuredPersonAddressFiasId",
            kladrId: "InsuredPersonAddressKladrId",
            orgName: "InsuredOrgName",
            orgInn: "InsuredOrgINN",
            inn: "InsuredINN",
        };
    }
}
