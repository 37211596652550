import angular from "angular";
import { getRepositoryDefaults } from "domain/classes/baseRepository.class";
import { Payment } from "domain/classes/payment.class";
import { ISetFromRepositoryFlags } from "infrastructure/interfaces/IContractResource";
import IDTO from "infrastructure/interfaces/IDTO";
import { IAPIRepository } from "infrastructure/Repository.class";
import { cast } from "infrastructure/services/autoCast.service";
import automapper from "infrastructure/services/automapper";
import { Day, Int } from "infrastructure/types";
import { AbstractContract } from "./AbstractContract";

export class Contract extends AbstractContract {
    public PolicyPrefix: string = "";
    public PolicyNumber: string | null;

    public InsurancePremium: number;
    public SigningDate: string;
    public ContractFrom: string;
    public ContractTo: string;

    public static fromDTO(dto: IDTO): Contract {
        const TargetType = this;
        try {
            if (!dto) {
                throw new Error();
            }
            return automapper.map(Object, TargetType, dto);
        } catch (e) {
            console.warn(`${Object.name} -> ${TargetType.name} mapping not found with error ${e}`);
            //  @fallback
            //  @todo если когда-нибудь появятся automapper правила для всех продуктов,
            //  то можно будет этот вариант выпилить
            if (typeof dto === "object") {
                // cast(dto, TargetType);
                // return dto;
                // @see http://jr.ugsk.ru/jr/browse/UPRO-1907
                // Просто кастануть объект нельзя - нужно проигнорить `null` значения свойств,
                // а затем типизировать дочерние свойства
                const typedObject = new TargetType(null);
                typedObject.init(dto);
                cast(typedObject, TargetType);
                return typedObject;
            }
            return new TargetType(null);
        }
    }

    /**
     * Creates an instance of BaseContract.
     * @param {Object} prototype - прототип для договора, например DTO или экземпляр ресурса
     */
    public constructor(prototype?: IDTO | Contract) {
        super();
        if (angular.isObject(prototype)) {
            for (const key in prototype) {
                if (!prototype.hasOwnProperty(key)) {
                    continue;
                }
                this[key] = prototype[key];
            }
        } else {
            this.ContractStatusId = 0;
            this.PolicyNumber = "";
        }
    }

    public async $load(id: number, cross?: string): Promise<Contract> {
        if (this.Id) {
            throw new Error("contract already loaded");
        }
        let dto;
        if (cross) {
            dto = await this.getResourceProvider().getCrossContract({ id, from: cross }).$promise;
        } else {
            dto = await this.getResourceProvider().editContract({ id }).$promise;
        }
        this.init((this.constructor as any).fromDTO(dto));
        return this;
    }

    public setFromRepository(data: IAPIRepository[], flags: ISetFromRepositoryFlags = {}): void {
        const $this = this;
        angular.forEach(data, (element) => {
            if (element.Value) {
                angular.forEach(element.Value, (collectionElement) => {
                    $this[collectionElement.FieldName] = collectionElement.Value;
                });
            } else if (element.Value === null) {
                /*
                * Для случая, когда сбрасываем значение в дефолтное состояние, которое
                */
                if ($this.hasOwnProperty(element.FieldName + "Id")) {
                    $this[element.FieldName + "Id"] = null;
                }
            }
        });

        if (flags.isBlank || flags.cross) {
            const contractDefaults = getRepositoryDefaults(data);
            /**
             * В ОСАГО/КАСКО справочники приходят без ID
             * в том числе справочник видов оплаты
             */
            try {
                if (contractDefaults.PaymentKind) {
                    contractDefaults.PaymentKindId = ({
                        "Наличные по квитанции А7": 1,
                        "Банковская карта": 2,
                        "Платежное поручение": 3,
                        "Зачет премии": 4,
                        Счет: 5,
                        "Интернет-банкинг": 6,
                        "Наличные": 7,
                    })[contractDefaults.PaymentKind];
                }
            } catch (e) {}

            /**
             * Превращаем Id Серии квитанции A7 в её текстовое представление из FR
             */
            try {
                const SerialDefault = contractDefaults.PaymentDocumentSerialId;
                const FieldObject = data.find((item) => item.FieldName === "PaymentDocumentSerial");
                const CollectionItem = FieldObject.CollectionSource.find(({
                    CollectionSource,
                }) => {
                    return CollectionSource[1].Value === SerialDefault;
                });
                const SerialDefaultText = CollectionItem.CollectionSource[0].Value;
                contractDefaults.PaymentDocumentSerial = SerialDefaultText;
            } catch (e) {}

            this.FactPayments = [
                Payment.fromContractDefaults(contractDefaults)
            ];
        }
    }
    public getPolicySerial(): string {
        const policySerial = this.getRepository().PolicySerial.Collection.find(serial => serial.PolicySerialId === this["PolicySerialId"]); // PolicySerialId
        if (policySerial) {
            return policySerial.PolicySerial;
        }
        return "";
    }


    /**
     * Свойство, перечисляющее типы Backend, которые реализует данный тип
     *
     * @readonly
     * @memberof Product
     */
    static get implementedTypes(): string[] {
        return [
            "#UGSK.K3.Product.UAuto.Storage.UAutoContract",
            "#UGSK.K3.Product.UAuto.Storage.Tools.UAutoContractJournalItem"
        ];
    }
    get primaryPayment() {
        return this.FactPayments[0];
    }

    public setStatus(newStatus: number) {
        this.ContractStatusId = newStatus;
    }

    public canBePaid(): boolean {
        return this.isSigned();
    }
    public getPlannedPaymentsCount(): number {
        if (angular.isArray(this.PlannedPayments)) {
            return this.PlannedPayments.length;
        }
        return 1;
    }
    public getInsurantTypeId(): number {
        return this.InsuredContractorTypeId;
    }
    public isInsurantANaturalPerson(): boolean {
        return this.getInsurantTypeId() === 1;
    }
    public isInsurantALegalEntity(): boolean {
        return this.getInsurantTypeId() === 2;
    }
    public isInsurantASoleProprietor(): boolean {
        return this.getInsurantTypeId() === 3;
    }
    public getInsurantFields(): string[] {
        return [
            "InsuredLastName",
            "InsuredFirstName",
            "InsuredMiddleName"
        ];
    }
    public getPremium(): number {
        return this.InsurancePremium;
    }

    // Ignore fields
    //  TODO: Future remove
    set PaymentKindId(val) {}
    get PaymentKindId(): Int { return; }
    set PaymentDocumentNumber(val) {}
    get PaymentDocumentNumber() { return }
    set PaymentDocument(val) {}
    get PaymentDocument() { return }
    set PaymentDocumentSerialId(val: number) {}
    get PaymentDocumentSerialId(): number | undefined { return }
    set PaymentDocumentDatePay(val) {}
    get PaymentDocumentDatePay(): Day | undefined { return }
    set IsA7Required(val) {}
    get IsA7Required() { return }
    [key: string]: number | string | boolean | any;
}
