import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { NotifyService } from "infrastructure/services/notifyService";

export default {
    action(context) {
        const notifyService = context.di<NotifyService>("notifyService");
        return context.Contract.$returnToRevision().then(() => {
            context.Contract.StatusName = "Возвращён на корректировку";
        }).then(() => {
            notifyService.successMessage("", "Договор успешно возвращен на корректировку");
        });
    },
    disabled(context) {
        const hasSuitableStatus = context.Contract.isSigned();
        const hasRigths = context.employee.hasPermission("ReturnForRevision", context.product.code);
        return !hasSuitableStatus || !hasRigths;
    },
    icon: "fa-pencil",
    title: "Вернуть на корректировку",
} as IExtraOptionPrototype;
