import { IModalServiceInstance } from "angular-ui-bootstrap";
import { NgControllerBase } from "infrastructure/NgController";

import "./confirm.css";

export class ConfirmController extends NgControllerBase {
  public isConfirmed = false;

  static get $inject() {
    return ["$injector", "$uibModalInstance", "amount", "userName"];
  }

  public constructor(
      $injector, private $uibModalInstance: IModalServiceInstance,
      public amount: number, public userName: string,
  ) {
    super($injector);
  }

  public onAccept(): void {
    this.$uibModalInstance.close(true);
  }

  public onReject(): void {
    this.$uibModalInstance.dismiss();
  }
}
