import angular from "angular";
import { NgComponentController } from "infrastructure/NgController";
import { UAutoController } from "application/uAuto/uAuto.controller";
import { UAutoContract } from "application/uAuto/uAuto.factory";
import { UAutoRepository } from "application/uAuto/uAuto.repository";
import { IInsuranceAmountType, IInsuranceProgram } from "application/uAuto/uAuto.repository";
import { UAUTO_PROGRAM_DEALER_REPAIR } from "application/constants";
import { IRepositoryItem } from "infrastructure/Repository.class";
import './ugskUautoInsuranceProgram.component.css'

export class UgskUautoInsuranceProgramComponentController extends NgComponentController {
    public parent: UAutoController;
    public contract: UAutoContract;
    public Repository: UAutoRepository;
    private virtualInsuranceAmountType: IInsuranceAmountType;

    public onInit(): void {
        this.contract = this.parent.Contract;
        this.Repository = this.contract.getRepository();
    }

    get insuranceAmountType(): IInsuranceAmountType {
        return this.virtualInsuranceAmountType;
    }

    set insuranceAmountType(val: IInsuranceAmountType) {
        if (!val) {
            return;
        }
        this.contract.InsuranceAmountType = val.InsuranceAmountType;
        this.contract.InsuranceAmountNumber = val.InsuranceAmountNumber;
        this.contract.Gap = val.Gap;
        this.virtualInsuranceAmountType = val;
    }

    get insuranceProgram() {
        return this.contract.InsuranceProgram;
    }

    set insuranceProgram(newVal: string) {
        this.contract.InsuranceProgramPrevious = this.contract.InsuranceProgram;
        this.contract.InsuranceProgram = newVal;

        // сохраняем ранее выбранные параменты ПУС
        // ATSAnother не затирается, при выборе программы. Кроме того, это текстовое поле.
        const atsKeys  = [ "AtsEAS", "AtsImmo", "AtsMBDHood", "AtsMBDWheel",
            "AtsMBDTransmission", "AtsSatellite", "AtsRadio" ];

        const tmpAtsValues: { [key: string]: string | number } = {};
        atsKeys.forEach((key) => {
            tmpAtsValues[key] = this.contract[key];
        });

        this.onRepositoryFieldChanged("InsuranceProgram").then(() => {
            // если программа = РУД, то оставляем все как есть и на всякий случай затираем ATSAnother
            if (newVal === UAUTO_PROGRAM_DEALER_REPAIR) {
                this.contract.ATSAnother = null;
                return;
            }
            // const repository = this.contract.getRepository();
            atsKeys.forEach((atsFieldName) => {
                if (atsFieldName in this.Repository) {
                    // если в справочнике есть значение, указанное ранее - оставляем его
                    if ((this.Repository[atsFieldName] as IRepositoryItem<unknown>).Collection.some(
                        (item) => item[atsFieldName] === tmpAtsValues[atsFieldName])
                    ) {
                        this.contract[atsFieldName] = tmpAtsValues[atsFieldName];
                    } else {
                        // если такого значения нет - ставим по умолчанию
                        this.contract[atsFieldName] =
                            (this.Repository[atsFieldName] as IRepositoryItem<unknown>).DefaultValue;
                    }
                } else {
                    console.info(`ATS with key ${atsFieldName} not found in repository. Something goes wrong.`);
                }
            });
            // чтобы актуализировать значение "Выполнены требования ПУС"
            this.onRepositoryFieldChanged(atsKeys[0]);
        });
    }

    public onRepositoryFieldChanged(name: string): angular.IPromise<void> {
        return this.parent.updateFieldRepository(name).then(() => {
            this.updateInsuranceAmountType(name);
        });
    }
    public updateInsuranceAmountType(name: string): void {
        if (name === "InsuranceProgram" || name === "PaymentForm") {
            this.insuranceAmountType = this.Repository.InsuranceAmountType.Collection.find((type) =>
                type.InsuranceAmountType === this.contract.InsuranceAmountType &&
                type.InsuranceAmountNumber === this.contract.InsuranceAmountNumber &&
                type.Gap === this.contract.Gap);
        }
    }
    public clearPolicyNumber() {
        this.contract.PolicyNumber = null;
    }

}

export default angular.module("ugsk-uauto-insurance-program", [])
    .component("ugskUautoInsuranceProgram", {
        bindings: {
            parent: "<",
        },
        controller: UgskUautoInsuranceProgramComponentController,
        controllerAs: "vm",
        template: require("./ugskUautoInsuranceProgram.component.html"),
    }).name;
