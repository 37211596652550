import { NgComponentController } from "infrastructure/NgController";
import uiMask from "angular-ui-mask";
import UgskPhone from "domain/classes/ugsk-phone.class";
import "./ugskPhones.css";
import template from "./ugskPhones.component.html";
import tooltipPhoneStatusesHtml from "./tooltip.phone.statuses.html";

export default angular.module("ugskPhones", [
    uiMask,
    "angular-ladda",
]).component("ugskPhones", {
    bindings: {
        locked: "<",
        phones: "=ngModel",
        phoneValidationService: "<?",
        useMobilePhone: "<",
        fieldsValidation: "<",
    },
    controller: class extends NgComponentController {
        onInit() {
            if (this.fieldsValidation === undefined) {
                this.fieldsValidation = ""
            }
            this.phone = "";
            this.focused = false;
            this.useMagicCode = (this.phoneValidationService && this.phoneValidationService.canUseMagicVerificationCode());
            this.tooltipPhoneStatuses = tooltipPhoneStatusesHtml;
            this.uiOptions = {
                maskDefinitions: {
                    "?": /\d/,
                    9: undefined,
                },
            };
            [this.notifyService] = this.di(["notifyService"]);
            if (!this.phones) {
                this.phones = [];
            }
            if (angular.isUndefined(this.verificationDisabled)) {
                this.verificationDisabled = false;
            }
            const DOM_VK_RETURN = 13;
            const DOM_VK_BACKSPACE = 8;
            this.$element.find("input.phone-input").on("keydown", e => {
                const keyCode = e.keyCode || e.which || e.code;
                if (keyCode === DOM_VK_RETURN) {
                    this.addPhone();
                }
                if (keyCode === DOM_VK_BACKSPACE) {
                    if (angular.isDefined(this.phone) && this.phone.length === 0 && this.phones.length > 0) {
                        this.removePhone(this.phones.length - 1);
                    }
                }
            });
        }
        get uiMask() {
            return (this.useMobilePhone) ? "+7 (9??) ???-??-??" : "+7 (???) ???-??-??";
        }
        get isServiceValidationAvailable() {
            return (this.phoneValidationService && this.phoneValidationService.isServiceEnabled());
        }
        get verificationEnabled() {
            return angular.isDefined(this.phoneValidationService);
        }
        focusToPhoneInput() {
            if (!this.locked) {
                this.$element.find("input.phone-input").focus();
                this.focused = true;
            }
        }
        addPhone() {
            if (!this.phone) {
                return false;
            }
            if (this.phone.length !== this.uiMask.length) {
                return false;
            }
            const item = this.phones.find(phone => phone.Number === this.phone);
            if (item) {
                return this.notifyService.errorMessage("Номер уже добавлен!");
            }
            this.phones.push(new UgskPhone({
                Number: this.phone,
                Status: 0,
            }));
            this.phone = "";
            return true;
        }
        removePhone(index) {
            this.phones.splice(index, 1);
        }
        showMagicCode() {
            if (this.phoneValidationService) {
                const phone = (this.phones.length > 0) ? this.phones[0] : null;
                if (!phone || !phone.Number || !phone.Number.length) {
                    this.notifyService.errorMessage("Не указан телефон страхователя!");
                } else {
                    this.magicCodeLoading = true;
                    this.phoneValidationService.showMagicVerificationCode(phone.Number).then(() => {
                        this.magicCodeLoading = false;
                    });
                }
            }
        }
        confirmPhone() {
            if (this.phoneValidationService) {
                if (this.phones.length === 0) {
                    this.notifyService.errorMessage("Не указан телефон страхователя!");
                } else if (this.phoneValidationService.isExistsVerifiedPhone(this.phones)) {
                    this.notifyService.successMessage("", "Один из указанных номеров уже подтверждён");
                } else {
                    this.isConfirmPhoneLoading = true;
                    this.phoneValidationService.checkAndConfirmPhones({
                        phones: this.phones,
                    }).then(phones => {
                        if (angular.isArray(phones)) {
                            this.phones = phones;
                        }
                    }).catch(phones => { // Даже при ошибке надо подстветить телефоны с новым статусом
                        if (angular.isArray(phones)) {
                            this.phones = phones;
                        }
                    }).finally(() => {
                        this.isConfirmPhoneLoading = false;
                    });
                }
            }
        }
    },
    controllerAs: "vm",
    template,
}).name;
