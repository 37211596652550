import { Transition } from "@uirouter/core";
import angular from "angular";
import { InsurantPersonProxy } from "domain/proxies/insurantPersonProxy.class";
import IScope from "infrastructure/interfaces/IScope";
import IPreviousContractRecord from "infrastructure/interfaces/IPreviousContractRecord";
import IProlongableProductBodyController from "infrastructure/interfaces/IProlongableProductBodyController";
import { NotifyService } from "infrastructure/services/notifyService";
import { Day, Int } from "infrastructure/types";
import moment from "moment";
import { BaseIFLBodyController } from "../baseIFLProduct.body.controller";
import { SnugHomeContract } from "./snugHome.factory";

export class SnugHomeController extends BaseIFLBodyController implements IProlongableProductBodyController {
    public defaultContractFrom: Day;
    public defaultContractTo: Day;
    public insurant: InsurantPersonProxy;
    public defaultPolicySerial: string;
    public $filter: angular.IFilterService;

    protected Contract: SnugHomeContract;

    constructor(
        $injector: angular.auto.IInjectorService,
        $transition$: Transition,
        params: any,
        $scope: IScope
    ) {
        super($injector, $transition$, params, $scope);
        this.defaultContractFrom = `${moment().add(10, "d").format("YYYY-MM-DD")}T00:00:00`;
        this.defaultContractTo = `${moment().add(1, "years").add(9, "d").format("YYYY-MM-DD")}T23:59:59`;
        this.insurant = new InsurantPersonProxy(this.Contract);
        this.$filter = this.di<angular.IFilterService>("$filter");
        /* tslint:disable-next-line:max-line-length */
        this.defaultPolicySerial = this.Repository.PolicySerial ? this.Repository.PolicySerial.Collection[0].PolicySerial : undefined;

        if (!this.Contract.ContractStatusId) {
            this.Contract.SigningDate = `${moment().format("YYYY-MM-DD")}T00:00:00`;
            this.Contract.DatePay = `${moment().format("YYYY-MM-DD")}T00:00:00`;
            this.Contract.ContractFrom = this.defaultContractFrom;
            this.Contract.ContractTo = this.defaultContractTo;
        }

        if (!this.Contract.InsuredBirthday) {
            this.Contract.InsuredBirthday = moment().add(-18, "y").toString();
        }
        if (this.Contract.ContractStatusId === 1) {
            const signingDate = moment(this.Contract.SigningDate, "YYYY-MM-DD").startOf("day");
            const current = moment().startOf("day");
            if (signingDate.isBefore(current)) {
                const notifyService = this.di<NotifyService>("notifyService");
                /* tslint:disable-next-line:max-line-length */
                notifyService.warningMessage("Внимание", "По данному договору устарела дата заключения договора. Рассчитайте договор заново для обновления даты заключения договора.");
            }
        }
        this.initWatchList();
    }

    public $onInit(): void {
        super.$onInit();
        this.phoneValidationService.setContractRequiredFields({
            individual: [
                { field: "InsuredLastName", description: "Фамилия страхователя" },
                { field: "InsuredFirstName", description: "Имя страхователя" },
                { field: "InsuredBirthday", description: "Дата рождения страхователя" }
            ],
            legalEntity: [
                { field: "InsuredOrgName", description: "Наименование организации страхователя" },
                { field: "InsuredOrgINN", description: "ИНН организации страхователя" }
            ],
        });
    }

    public getPaymentDocumentSerial(): string {
        const item = this.$filter<any>("filterBy")(
            this.Repository.PaymentDocumentSerial.Collection,
            ["PaymentDocumentSerialId"],
            this.Contract.PaymentDocumentSerialId,
        );
        return (item[0]) ? item[0].PaymentDocumentSerial : "";
    }

    //  @override
    public importPreviousContract(previousContractData: IPreviousContractRecord): angular.IPromise<void> {
        const contract = this.Contract;

        const getPolicySerialByPolicySerialId = (id: Int): string => {
            try {
                return contract.getRepository().PolicySerial.Collection
                    .find((PolicySerial) => PolicySerial.PolicySerialId === id).PolicySerial;
            } catch (e) {
                return "";
            }
        };

        const storedSerial = previousContractData.PreviousPolicySerial;
        try {
            /* tslint:disable-next-line:max-line-length */
            previousContractData.PreviousPolicySerial = getPolicySerialByPolicySerialId(previousContractData.PreviousPolicySerialId);
        } catch (e) {
            previousContractData.PreviousPolicySerial = storedSerial;
        }

        // TODO: Эта штука не работает, исправить видимо
        if (contract.CSIAPreviousContractId) {
            contract.CSIAPreviousContractId = previousContractData.CSIAPreviousContractId;
        }
        /* tslint:disable-next-line:max-line-length */
        if (previousContractData.PropertyAddressUserDefinedAddress && previousContractData.PropertyAddressUserDefinedAddress.length > 0) {
            contract.PropertyAddressUserDefinedAddress = previousContractData.PropertyAddressUserDefinedAddress;
            contract.PropertyAddressFiasId = previousContractData.PropertyAddressFiasId;
        }

        contract.InsuredLastName = previousContractData.InsuredLastName;
        contract.InsuredFirstName = previousContractData.InsuredFirstName;
        contract.InsuredMiddleName = previousContractData.InsuredMiddleName;
        /* tslint:disable-next-line:max-line-length */
        contract.InsuredBirthday = previousContractData.InsuredBirthday ? moment(previousContractData.InsuredBirthday).format("YYYY-MM-DDT00:00:00") + moment().format("Z") : contract.InsuredBirthday;
        contract.PreviousContractId = previousContractData.PreviousContractId;
        contract.PreviousContractNumber = String(previousContractData.PreviousContractNumber);
        contract.PreviousPolicySerial = previousContractData.PreviousPolicySerial;

        contract.ContractFrom = null;
        contract.ContractTo = null;

        const notifyService = this.di<NotifyService>("notifyService");
        notifyService.successMessage("Копирование данных", "Данные успешно скопированы из предыдущего договора.");
        return;
    }

    public setForm(form: angular.IFormController): void {
        this.pageSharedData.Form = form;
    }

    public removePreviousContract(): void {
        this.Contract.removePreviousContract();
    }

    private initWatchList(): void {
        this._$scope.$watch("vm.Contract.PreviousContractId", (newVal, oldVal) => {
            if (!newVal && oldVal) {
                this.Contract.ContractFrom = this.defaultContractFrom;
                this.Contract.ContractTo = this.defaultContractTo;
            }
        }, false);
    }
}
