import angular from "angular";
import { activateParentTab } from "infrastructure/app.helpers";
import { NotifyService } from "infrastructure/services/notifyService";
import { BaseToolbarController } from "./baseProduct.toolbar.controller";
import { OsagoContract } from "./osago/classes";
import { UAutoContract } from "./uAuto/uAuto.factory";

export interface IBaseAutoSaveContractParams {
    validationToBeClear?: boolean;
    noKBM?: boolean;
}
export class BaseAutoToolbarController extends BaseToolbarController {
    public Contract: UAutoContract | OsagoContract;
    /**
     * @description Определение возможности работы текущего пользователя с Актами ПСО
     * @return {Boolean}
     */
    public hasInspectionPermission() {
        return Boolean(this.employee.hasPermission("CreateInspectionConclusion", this.pageSharedData.currentProduct));
    }
    /**
     * @override
     */
    public saveContract(param: IBaseAutoSaveContractParams = {
        validationToBeClear: true,
    }) {
        const validationToBeClear = param.validationToBeClear;
        const notifyService = this.di<NotifyService>("notifyService");
        const [
            imageRepositoryResource,
        ] = this.di([
            "imageRepositoryResource",
        ]);
        const contract = this.Contract;
        return super.saveContract({ validationToBeClear }).then(() => {
            // ХИ
            if (contract.PolicyNumber && contract.Guid && contract.FilialGuid && contract.SigningDate) {
                return imageRepositoryResource.openOrCreateContainer({
                    ContractGuid: contract.Guid,
                    ContractPolicyNumber: contract.PolicyNumber,
                    ContractSigningDate: contract.SigningDate,
                    ContractTerritoryGuid: contract.FilialGuid,
                }).$promise.catch(() => {
                    notifyService.warningMessage("Хранилище изображений", "Не удалось создать контейнер в хранилище изображений");
                });
            }
        }).then(() => {
            const apiUrl: string = this.envService.read("apiUrl");
            const $http = this.di<angular.IHttpService>("$http");
            $http.get(`${apiUrl}UAuto/GetContractScoringState/${this.Contract.Id}`)
                .then((checkedResult) => {
                    this.Contract.checkedResult = checkedResult.data;
                });
            if (this.hasInspectionPermission()) {
                return contract.$saveInspectionConclusion().then(() => {
                    this.pageSharedData.Form.$setPristine();
                });
            }
        });
    }

    public focusToPayments(): void {
        activateParentTab(angular.element(".contract-container ugsk-payments-service"));
    }
}
