import { IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { NotifyService } from "infrastructure/services/notifyService";

export default {
    title: "Согласовать",
    action(context) {
        const notifyService = context.di<NotifyService>("notifyService");
        return context.Contract.$approve().then(() => {
            notifyService.successMessage("Договор успешно согласован");
        });
    },
    disabled(context) {
        return !((context.Contract.isDraft() && !context.Contract.isBlank() || context.Contract.isFrozen()) && context.employee.hasPermission("Approve", context.product.code));
    },
    icon: "fa-thumbs-up",
} as IExtraOptionPrototype;
