import * as angular from "angular";
import moment from "moment";


import { BoxAccidentBodyControllerBase, IDiscountListItem } from "../boxAccidentBodyControllerBase";
import { SafeCarAccidentInsuredPerson } from "./safeCarAccident.factory";

export class SafeCarAccidentController extends BoxAccidentBodyControllerBase {
    public $onInit() {
        super.$onInit();

        if (!this.Contract.ContractStatusId) {
            this.Contract.SigningDate = moment().format("YYYY-MM-DD");
            this.Contract.ContractFrom = this.getContractFromMinDate();
            this.Contract.UnderwriterCoefficient = this.Repository.UnderwriterCoefficientRestriction.Collection[0].MinValue;
            this.insuranceTermChanged();
        }
        /**
         * Отвратительно мерзкий костыль =(
         * Выпилить как будут нормальные требования от бизнеса...
        */
        if (this.Contract.InsuredPersons.length > 0 && !this.Contract.isLocked()) {
            this.Contract.InsuredPersons = [];
            this.Contract.InsuredPersons.push(new SafeCarAccidentInsuredPerson());
        }

        this.registrationDateConfig = {
            endDate: moment().format("YYYY-MM-DD"),
            startDate: moment().subtract(100, "years").format("YYYY-MM-DD"),
        };

        this.contractMinDate = this.getContractFromMinDate();
        this.contractMaxDate = moment(this.Contract.SigningDate, "YYYY-MM-DD").add(6, "months").format("YYYY-MM-DD");

        this.discountCollection = this.getDiscountCollection();

        this.maxCountInsuredPersons = this.Repository.InsuredPersonCountRestriction.Collection[0].MaxValue;
        this.defaultNumberOfInsuredPersons = this.Repository.InsuredPersonCountRestriction.Collection[0].MinValue;

        if (!angular.isArray(this.Contract.InsuredPersons)) {
            this.Contract.InsuedPersons = [];
        }

        if (angular.equals(this.Contract.InsuredPersons.length, 0)) {
            for (let i = 0; i < this.defaultNumberOfInsuredPersons; i++) {
                this.Contract.InsuredPersons.push(new SafeCarAccidentInsuredPerson());
            }
        }
    }

    public areInsuredPersonsVisible() {
        return this.Contract.InsuredPersons[0].InsuredPersonFirstName && this.Contract.InsuredPersons[0].InsuredPersonLastName && this.Contract.isLocked();
    }

    public isDisabledAddInsuredPerson() {
        return this.Contract.InsuredPersons.length >= this.maxCountInsuredPersons;
    }

    public addPerson() {
        this.Contract.InsuredPersons.push(new SafeCarAccidentInsuredPerson());
        this.pageSharedData.Form.modified = true;
    }

    public insuredPersonIsInsuredChanged() {
        if (this.isInsuredPersonIsInsured()) {
            if (this.insuredWatcher) {
                this.insuredWatcher();
            }

            this.removeInsuredPerson(0);

            if (this.Contract.InsuredPersons.length === 0) {
                this.addPerson();
            }
        } else {
            if ((this.Contract.InsuredPersons.length > 0) && (this.Contract.InsuredPersons[0].Guid !== this.Contract.InsuredGuid)) {
                const insuredPerson = new SafeCarAccidentInsuredPerson();
                insuredPerson.Guid = this.Contract.InsuredGuid;

                if ((this.Contract.InsuredPersons.length === 1) && this.isEmptyPerson(this.Contract.InsuredPersons[0])) {
                    this.Contract.InsuredPersons = [insuredPerson];
                } else {
                    Object.keys(this.Contract.InsuredPersons[0]).forEach(prop => {
                        if (!["Guid", "SpecialInsuredNote"].includes(prop)) {
                            const targetProp = prop.replace("Person", "");

                            if (this.Contract.hasOwnProperty(targetProp)) {
                                this.Contract.InsuredPersons[0][prop] = this.Contract[targetProp];
                            }
                        }
                    });
                    this.Contract.InsuredPersons[0].Guid = this.Contract.InsuredGuid;
                }
            }

            this.watchForInsuredPerson();
        }
    }

    public getContractFromMinDate() {
        return moment(this.Contract.SigningDate, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
    }

    public insuranceTermChanged() {
        if (!this.Contract.ContractFrom) {
            this.Contract.ContractTo = null;
        } else {
            this.Contract.ContractTo = moment(this.Contract.ContractFrom, "YYYY-MM-DD")
                .add(12, "months")
                .subtract(1, "days")
                .format("YYYY-MM-DD");
        }

        if (this.Validation.hasError("ContractTo")) {
            this.Validation.removeError("ContractTo");
        }
    }

    public getDiscountCollection(): IDiscountListItem[] {
        const collection = [];
        let labelDiscount = "";
        const fromValue = this.Repository.DiscountRestriction.Value[0].Value;
        const toValue = this.Repository.DiscountRestriction.Value[1].Value;

        for (let i = fromValue; i <= toValue; i++) {
            if (i > 0) {
                labelDiscount = `${i} %`;
            } else {
                labelDiscount = "Без скидки";
            }

            collection.push({ Id: i, Value: labelDiscount });
        }
        return collection;
    }
}
