import { Transition } from "@uirouter/core";
import angular from "angular";
import { InsurantPersonProxy } from "domain/proxies/insurantPersonProxy.class";
import { IDatePickerOptions } from "infrastructure/interfaces/IDatePickerOptions";
import IDTO from "infrastructure/interfaces/IDTO";
import IScope from "infrastructure/interfaces/IScope";
import IPreviousContractRecord from "infrastructure/interfaces/IPreviousContractRecord";
import IProlongableProductBodyController from "infrastructure/interfaces/IProlongableProductBodyController";
import { ITouchSpinConfig } from "infrastructure/interfaces/ITouchSpinConfig";
import { NotifyService } from "infrastructure/services/notifyService";
import { Day, Guid, Int } from "infrastructure/types";
import moment from "moment";
import { BaseIFLBodyController } from "../baseIFLProduct.body.controller";
import bonusMalusTemplate from "../modalDialog/showBonusMalus.html";
/* tslint:disable-next-line:max-line-length */
import { AdditionalConstruction, ContructorHomeContract, ContructorHomeRepository, IInsuranceTerm } from "./constructorHome.factory";

interface IPropertyFloorCounts {
    Count: Int;
}

type ListAdditionalConstruction = AdditionalConstruction[];

export class ConstructorHomeController extends BaseIFLBodyController implements IProlongableProductBodyController {
    public contractPrototype = {};
    public insurant: InsurantPersonProxy;
    public insuredAddressEqualPropertyAddress: boolean = true;
    public PropertyFloorCounts: IPropertyFloorCounts[];
    public $filter: angular.IFilterService;
    public maskdefinitions = {
        "?": /\d/,
        9: undefined,
    };
    public yearTouchSpin: ITouchSpinConfig = {
        boostat: 5,
        buttondown_class: "btn btn-white",
        buttonup_class: "btn btn-white",
        max: moment().year(),
        maxboostedstep: 10,
        min: moment().year() - 90,
        step: 1,
    };
    public squareTouchSpin: ITouchSpinConfig = {
        boostat: 5,
        buttondown_class: "btn btn-white",
        buttonup_class: "btn btn-white",
        decimals: 2,
        delimiter: ",",
        max: 1000,
        maxboostedstep: 10,
        min: 0,
        step: 0.01,
    };
    public maxAdditionalConstruction: Int = 3;
    public AdditionalConstructionWallMaterials: ListAdditionalConstruction[] = [];
    public AdditionalConstructionGuids: Guid[] = [];
    public constructionYearOptions: IDatePickerOptions;
    public tooltipTextWallMaterial: string;
    protected Contract: ContructorHomeContract;

    constructor(
        $injector: angular.auto.IInjectorService,
        $transition$: Transition,
        params: any,
        $scope: IScope,
    ) {
        super($injector, $transition$, params, $scope);
        const datePickerBaseYearOptions = this.di<IDatePickerOptions>("datePickerBaseYearOptions");
        this.constructionYearOptions = angular.copy(datePickerBaseYearOptions);
        this.insurant = new InsurantPersonProxy(this.Contract);
        this.$filter = this.di<angular.IFilterService>("$filter");
    }

    public formModified(): boolean {
        return this.pageSharedData.Form.modified;
    }

    public yearComparator(model: object, master: object): boolean {
        return (String(model) === String(master));
    }

    public initView(): void {
        this.PropertyFloorCounts = [{
            Count: 1,
        }, {
            Count: 2,
        }, {
            Count: 3,
        }];

        if (!this.Contract.AdditionalConstructions) {
            this.Contract.AdditionalConstructions = [];
        }

        if (!this.Contract.SigningDate) {
            this.Contract.SigningDate = `${moment().format("YYYY-MM-DD")}T00:00:00`;
        }

        if (!this.Contract.ContractStatusId) {
            /* tslint:disable-next-line:max-line-length */
            this.Contract.ContractFrom = `${moment(this.Contract.SigningDate).add(10, "d").format("YYYY-MM-DD")}T00:00:00`;
            this.updateContractTo();
        }

        if (!this.Contract.PaymentDocumentDatePay) {
            this.Contract.PaymentDocumentDatePay = `${moment().format("YYYY-MM-DD")}T00:00:00`;
        }

        if (!this.Contract.InsuredBirthday) {
            this.Contract.InsuredBirthday = moment().add(-18, "y").format("YYYY-MM-DD") + "T00:00:00";
        }

        const signingDate = moment(this.Contract.SigningDate, "YYYY-MM-DD").startOf("day");
        const current = moment().startOf("day");
        if (this.Contract.ContractStatusId === 1 && signingDate.isBefore(current)) {
            const notifyService = this.di<NotifyService>("notifyService");
            /* tslint:disable-next-line:max-line-length */
            notifyService.warningMessage("Внимание", "По данному договору устарела дата заключения договора. Рассчитайте договор заново для обновления даты заключения договора.");
        }

        if (
            this.Contract.InsuredAddressFiasId !== this.Contract.PropertyAddressFiasId
            || this.Contract.PropertyAddressUserDefinedAddress != this.Contract.InsuredAddressUserDefinedAddress
            || this.Contract.InsuredAddressFiasId === null
        ) {
            this.insuredAddressEqualPropertyAddress = false;
        }

        angular.forEach(this.Contract.AdditionalConstructions, (item, index) => {
            this.AdditionalConstructionWallMaterials.push([new AdditionalConstruction(
                undefined,
                this.Contract.getRepository(),
            )]);
            this.AdditionalConstructionGuids.push("");
            this.changedAdditionalConstructionYear(item, index);
        });
        this.initWatchList();
    }

    public updateContractTo(): void {
        const item = this.getInsuranceTerm();
        if (item) {
            /* tslint:disable-next-line:max-line-length */
            this.Contract.ContractTo = `${moment(this.Contract.ContractFrom).add(item.InsuranceTermValue, "M").add(-1, "d").format("YYYY-MM-DD")}T00:00:00`;
        }
    }

    public getInsuranceTerm(): IInsuranceTerm | null {
        /* tslint:disable-next-line:max-line-length */
        const item = this.$filter<any>("filterBy")(this.Repository.InsuranceTerm.Collection, ["InsuranceTermId"], this.Contract.InsuranceTermId);
        if (item[0]) {
            return item[0];
        }
        return null;
    }

    public getPaymentDocumentSerial(): string {
        /* tslint:disable-next-line:max-line-length */
        const item = this.$filter<any>("filterBy")(this.Repository.PaymentDocumentSerial.Collection, ["PaymentDocumentSerialId"], this.Contract.PaymentDocumentSerialId);
        if (item[0]) {
            return item[0].PaymentDocumentSerial;
        }
        return "";
    }

    public isContractProlonged(): boolean {
        return Boolean(this.Contract.CSIAPreviousContractId || this.Contract.PreviousContractId);
    }

    public getPreviousContractNumber(): number {
        if (this.isContractProlonged()) {
            return this.Contract.CSIAPreviousContractId || this.Contract.PreviousContractId;
        }

        return undefined;
    }

    public getContractFromMinDate(): Day | null {
        if (this.isContractProlonged()) {
            return null;
        }
        return moment(this.Contract.SigningDate).startOf("day").add(10, "d").format("YYYY-MM-DD");
    }

    public getContractFromMaxDate(): Day | null {
        if (this.isContractProlonged()) {
            return null;
        }
        return moment(this.Contract.SigningDate).add(60, "d").format("YYYY-MM-DD");
    }

    /**
     * Подписка на события
     */
    public initWatchList(): void {

        this._$scope.$watch("vm.Contract.ContractFrom", (newDate, oldDate) => {
            if (newDate) {
                if (!angular.equals(newDate, oldDate)) {
                    this.updateContractTo();
                }
            }
        }, false);

        this._$scope.$watch("vm.Contract.PreviousContractId + vm.Contract.CSIAPreviousContractId", (newVal, oldVal) => {
            if (!newVal && oldVal) {
                this.Contract.ContractFrom = this.getContractFromMinDate();
            }

            if ((newVal && !oldVal) || (!newVal && oldVal)) {
                this.Contract.InsuranceTermId = this.Repository.InsuranceTerm.Value[0].Value;
            }
        }, false);
        /* tslint:disable-next-line:max-line-length */
        this._$scope.$watch("[vm.Contract.PropertyAddressUserDefinedAddress, vm.Contract.PropertyAddressFiasId]", (newAddress) => {
            this.updateInsuredAddressByPropertyAddress();
        }, true);
    }

    public clearFieldsByResidenceType(): void {
        if (this.Contract.ResidenceTypeId === 1) {
            this.Contract.StructuralElementsAndInteriorAndEngineeringEquipmentProductRiskAmountId = null;
            this.Contract.StructuralElementsAndInteriorAndEngineeringEquipmentProductRiskPremium = null;
        } else {
            this.Contract.MovablePropertyProductRiskPremium = null;
            this.Contract.MovablePropertyProductRiskAmountId = null;
            this.Contract.StructuralElementsProductRiskAmountId = null;
            this.Contract.StructuralElementsProductRiskPremium = null;
            this.Contract.InteriorAndEngineeringEquipmentProductRiskAmountId = null;
            this.Contract.InteriorAndEngineeringEquipmentProductRiskPremium = null;
        }
    }

    public clearFields(clearingField: string): void {
        if (
            (
                this.Contract.StructuralElementsProductRiskAmountId === null
                && this.Contract.InteriorAndEngineeringEquipmentProductRiskAmountId === null
                && this.Contract.ResidenceTypeId === 1
            ) || (
                this.Contract.StructuralElementsAndInteriorAndEngineeringEquipmentProductRiskAmountId === null
                && this.Contract.ResidenceTypeId === 2
            )
        ) {
            this.Contract.MovablePropertyProductRiskAmountId = null;
            this.Contract.MovablePropertyProductRiskPremium = null;
        }

        if (clearingField) {
            this.Contract[clearingField] = null;
        }
    }

    public addAdditionalConstruction(): void {
        const item = new AdditionalConstruction(
            undefined,
            this.Contract.getRepository(),
        );
        item.ConstructionTypeId = this.Repository.AdditionalConstructionType.DefaultValue;
        item.WallMaterialId = this.Repository.AdditionalConstructionWallMaterial.DefaultValue;
        let amountId = null;
        if (this.Repository.AdditionalConstructionProductRiskAmount.DefaultValue) {
            amountId = this.Repository.AdditionalConstructionProductRiskAmount.DefaultValue;
        }
        item.AdditionalConstructionProductRiskAmountId = amountId;
        this.changedAdditionalConstructionYear(item);
        this.Contract.AdditionalConstructions.push(item);
    }

    public changedAdditionalConstructionYear(data: AdditionalConstruction, index?: number): void {
        const value = parseInt(data.ConstructionYear, 10);
        if (Number.isNaN(value) || (value >= moment().year() - 90 && value <= moment().year())) {
            this.blockUI.start("Обновляются справочные данные");

            const contract: IDTO = {};
            contract.AdditionalConstructionTypeId = data.ConstructionTypeId;
            contract.AdditionalConstructionBuildingYear = data.ConstructionYear;

            this.Repository.update("AdditionalConstructionBuildingYear", contract, true)
                .then(() => {
                    if (index === undefined) {
                        /* tslint:disable-next-line:max-line-length */
                        this.AdditionalConstructionWallMaterials.push(angular.copy(this.Repository.AdditionalConstructionWallMaterial.Collection));
                        this.AdditionalConstructionGuids.push(data.Guid);
                    } else {
                        /* tslint:disable-next-line:max-line-length */
                        this.AdditionalConstructionWallMaterials[index] = angular.copy(this.Repository.AdditionalConstructionWallMaterial.Collection);
                        this.AdditionalConstructionGuids[index] = data.Guid;
                    }
                    this.blockUI.stop();
                });
        }
    }

    public changedAdditionalConstructionProductRiskAmount(data: AdditionalConstruction): void {
        data.AdditionalConstructionProductRiskPremium = null;
    }

    public removeAdditionalConstruction(elements: ListAdditionalConstruction[], index: number): void {
        elements.splice(index, 1);
        this.AdditionalConstructionWallMaterials.splice(index, 1);
        this.AdditionalConstructionGuids.splice(index, 1);
    }

    public updateInsuredAddressByPropertyAddress(): void {
        if (this.insuredAddressEqualPropertyAddress) {
            this.Contract.InsuredAddressUserDefinedAddress = this.Contract.PropertyAddressUserDefinedAddress;
            this.Contract.InsuredAddressFiasId = this.Contract.PropertyAddressFiasId;
            this.Contract.InsuredAddressKladrId = this.Contract.PropertyAddressKladrId;
            this.Contract.InsuredPersonAddressHasNotFiasInfo = this.Contract.InsuredObjectAddressHasNotFiasInfo;
        }
    }

    public changeInsuredObjectAddressHasNotFiasInfo(): void {
        if (this.insuredAddressEqualPropertyAddress) {
            this.Contract.InsuredPersonAddressHasNotFiasInfo = this.Contract.InsuredObjectAddressHasNotFiasInfo;
        }
        this.Validation.removeError("PropertyAddressUserDefinedAddress");
    }

    public changeInsuredPersonAddressHasNotFiasInfo(): void {
        this.Validation.removeError("InsuredAddressUserDefinedAddress");
    }

    public getConstructionTypeMeasureUnit(constructionTypeId: number) {
        let result = "";
        angular.forEach(this.Repository.AdditionalConstructionType.Collection, (item) => {
            if (item.ConstructionTypeId === constructionTypeId) {
                result = item.ConstructionTypeMeasureUnit;
            }
        });
        return result;
    }

    public changeInsuranceTerm(): void {
        this.getFieldRepository("InsuranceTerm");
        this.updateContractTo();
    }

    public isInvalid(name: string): boolean {
        if (name) {
            const item = this.pageSharedData.Form[name];
            if (item) {
                return item.$invalid;
            }
        }
        return false;
    }
    /**
     * @returns {} Открываем модальное окно для показа информации о б истории страхования
     */
    public showBonusMalusModal(): void {
        const [helpers] = this.di(["helpers"]);
        const data = this.Repository.ConstructorHomeBonusMalus.Collection.map((item) => ({
            content: item.ConstructorHomeBonusMalusDescription,
            title: item.ConstructorHomeBonusMalusName,
        }));
        helpers.showInformationWindow(bonusMalusTemplate, "История страхования", "", data);
    }

    public getValidationMessage(name: string): string[] {
        if (name && this.isInvalid(name)) {
            return this.pageSharedData.Form[name].$messages;
        }
        return [];
    }

    public bonusMalusUpdateFieldRepository(): void {
        this.updateFieldRepository(this.Repository.ConstructorHomeBonusMalus.FieldName).then(() => {
            if (this.Repository.PreviousPolicyNumber.State) {
                this.Contract.PreviousContractId = null;
                this.Contract.PreviousContractNumber = null;
                this.Contract.PreviousPolicySerial = null;
                this.Contract.CSIAPreviousContractId = null;
            }
        });
    }
    /**
     * Запрос справочников при изменении поля
     * @param {string} fieldName - имя изменившегося поля
     * @returns {}
     */
    public updateFieldRepository(fieldName: string): angular.IPromise<void> {
        this.blockUI.start("Обновляются справочные данные");
        return this.Repository.update(fieldName, this.Contract).then((res) => {
            this.blockUI.stop();
        });
    }

    public changeConstructionYear(fieldName: string): void {
        const value = parseInt(this.Contract[fieldName], 10);
        if (Number.isNaN(value) || (value >= moment().year() - 90 && value <= moment().year())) {
            this.getFieldRepository(fieldName);
        }
    }

    public setPaymentDocumentSerial(value: number) : void{
        this.Contract.PaymentDocumentSerialId = value;
    }

    public showTooltipWallMaterial(canShowTooltip: boolean, materials, isMain: boolean): boolean {
        /* tslint:disable-next-line:max-line-length */
        this.tooltipTextWallMaterial = (isMain) ? "Укажите год постройки основного строения" : "Укажите год постройки дополнительного строения";
        if (!canShowTooltip) {
            /* tslint:disable-next-line:prefer-for-of */
            for (let i = 0; i < materials.length; i++) {
                if (materials[i].State === 0) {
                    return canShowTooltip;
                }
            }
            this.tooltipTextWallMaterial = "Для указанного года постройки нет доступных материалов стен";
            canShowTooltip = !canShowTooltip;
        }
        return canShowTooltip;
    }

    /**
     * Копирование данных при пролонгации
     * @param previousContractRecord  - данные для пролонгации.
     * @returns {}
     */
    public importPreviousContract(previousContractRecord: IPreviousContractRecord): angular.IPromise<void> {
        const $q = this.di<angular.IQService>("$q");
        const vm = this;
        const customMappingRules = {
            AdditionalConstructions: (value, object) => {
                object.AdditionalConstructions = value.map((element) => angular.extend(new AdditionalConstruction(
                    element.ConstructionTypeId,
                    vm.Contract.getRepository(),
                ), element));
            },
        };
        /* tslint:disable-next-line:max-line-length */
        const syncRepositoryCallback = (contractPrototype) => $q.all(contractPrototype.AdditionalConstructions.map((element) => {
            const queryData = {
                AdditionalConstructionBuildingYear: element.ConstructionYear,
                AdditionalConstructionTypeId: element.ConstructionTypeId,
            };
            return vm.Repository.update("AdditionalConstructionBuildingYear", queryData, true).then(() => ({
                guid: element.Guid,
                material: angular.copy(vm.Repository.AdditionalConstructionWallMaterial.Collection),
            }));
        })).then((responses: any) => {
            vm.AdditionalConstructionWallMaterials = responses.map((item) => item.material);
            vm.AdditionalConstructionGuids = responses.map((item) => item.guid);
        });
        return super.importPreviousContract(previousContractRecord, customMappingRules, syncRepositoryCallback);
    }

    public $onInit(): void {
        super.$onInit();
        this.phoneValidationService.setContractRequiredFields({
            individual: [
                { field: "InsuredLastName", description: "Фамилия страхователя" },
                { field: "InsuredFirstName", description: "Имя страхователя" },
                { field: "InsuredBirthday", description: "Дата рождения страхователя" }
            ],
            legalEntity: [
                { field: "InsuredOrgName", description: "Наименование организации страхователя" },
                { field: "InsuredOrgINN", description: "ИНН организации страхователя" }
            ],
        });
        this.initView();
    }

    protected get Repository(): ContructorHomeRepository {
        return this.Contract.getRepository();
    }
    public removePreviousContract(): void {
        this.Contract.removePreviousContract();
    }
}
