import angular from "angular";
import ugskInsurantModule from "application/components/ugsk-insurant/ugskInsurant.component";
import { OsagoContract } from "application/osago/classes";
import { NgComponentController } from "infrastructure/NgController";
import moment from "moment";
import { OsagoInsurantProxy } from "../../osago.factory";
import "./ugsk-osago-insurant.css";

/**
 * ugskOsagoInsurant - Страхователь в "Осаго"
 */

class UgskOsagoInsurant extends NgComponentController {
    public insurant: OsagoInsurantProxy;
    public contract: OsagoContract;
    public formIsLocked: boolean;
    public insurantPhoneValidationFields: string[];
    public isNotResidentDisable: boolean;
    public minDate: string;
    public maxDate: string;
    public minBirthDay: string;
    public onInsurantTypeChanged: () => void;

    public onInit(): void {
        this.minBirthDay = moment().subtract(120, "years").format("YYYY-MM-DD");
        this.insurant = new OsagoInsurantProxy(this.contract);
        this.$watch(() => this.insurant.contractorType, (oldVal, newVal) => {
            if (!this.formIsLocked && oldVal !== newVal) {
                this.insurantPhoneValidationFields = [...this.contract.getInsurantFields(), "VehicleDocuments"];
                this.onInsurantTypeChanged();
            }
        });
        this.$watch(() => this.insurant.documentType, () => {
            if (!["Паспорт иностранного гражданина", "Водительское удостоверение иностранного государства"]
                .includes(this.insurant.documentType)
            && this.insurant.contractorType === "физическое лицо") {
                this.isNotResidentDisable = true;
                this.insurant.country = "Россия";
                this.insurant.isNotResident = false;
            } else {
                this.isNotResidentDisable = false;
            }
        });
        this.minDate = moment().subtract(100, "years").format("YYYY-MM-DD");
        this.maxDate = moment().format("YYYY-MM-DD");
    }

    public isBadgeResidentShow(): boolean {
        return this.insurant.isNotResident && this.insurant.contractorType.toLowerCase() === "юридическое лицо";
    }

    public isAccountingDateVisible(): boolean {
        return this.insurant.isLegalEntity()
            && this.insurant.documentType === "Свидетельство о постановке на учет в налоговом органе";
    }

    public onDocumentTypeChanged(): void {
        if (this.insurant.documentType !== "Свидетельство о постановке на учет в налоговом органе") {
            this.insurant.accountingDate = null;
        }
    }
}
export default angular.module("app.osago.components.insurant", [
    ugskInsurantModule,
]).component("ugskOsagoInsurant", {
    bindings: {
        contract: "<",
        formIsLocked: "<",
        onInsurantTypeChanged: "&",
        phoneValidationService: "<",
        repository: "<",
        addressMaxLevel: "<",
    },
    controller: UgskOsagoInsurant,
    controllerAs: "vm",
    template: require("./ugskOsagoInsurant.component.html"),
})
.name;
