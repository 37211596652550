import moment from "moment";
import { StateService } from "@uirouter/angularjs";
import { SALE_CHANNELS } from "application/constants";
import { ICashboxItem, ICashboxResource, IEmployeeResource } from "infrastructure/interfaces";
import { NgController } from "infrastructure/NgController";
import { AuthenticationService } from "infrastructure/services/authentication.service";
import { Employee } from "infrastructure/services/employee.service";
import { NotifyService } from "infrastructure/services/notifyService";
import { NotifyStaticProvider } from "infrastructure/services/notifyStaticProvider";
import { Guid } from "infrastructure/types";
import { ITimeoutService } from "angular";


interface IUserData {
    name: string;
    filial: string;
    role: string;
    email: string;
    phone: string;
    IsEmailConfirmed: boolean;
    IsPhoneConfirmed?: boolean;
    IsImpersonated: boolean;
    SaleChannel: string;
    ClientUri?: string;
    cashboxGuid?: Guid;
}

export class UserProfileController extends NgController {
    public showPasswordManagementButton: boolean;
    public showingEmailConfirmedDialog = false;
    public showingPhoneConfirmedDialog = false;
    public isSavingData = false;
    public isPhoneConfirmed: boolean;
    public isPhoneInvalid = false;
    public isPhoneCodeActive = false;
    public login: string;
    public isImpersonated = false;
    public isPhoneCodeSent = false;
    public isSavingCashbox = false;
    public code = "";
    public options = {
        maskOptions: {
            maskDefinitions: {
                "?": /\d/,
                "9": undefined,
            },
        },
    };
    public cashboxList: ICashboxItem[];
    public visibleCashboxes: boolean;
    public subordinates: Employee[] = [];
    protected ngModelOptions: angular.INgModelOptions;
    protected userData: IUserData;
    public phoneData: {oldData: null | string, newData: null | string} = {
        oldData: null,
        newData: null,
    }

    protected employee: Employee = null;
    protected $state: StateService = null;
    private employeeResource: IEmployeeResource;
    private notifyService = this.di<NotifyService>("notifyService");
    private authenticationService: AuthenticationService;

    public $onInit(): void {
        this.userData = this.resolve<IUserData>("userData");
        this.employee = this.resolve<Employee>("employee");
        this.cashboxList = this.resolve<ICashboxItem[]>("cashboxList");
        this.$state = this.di<StateService>("$state");
        this.ngModelOptions = this.di<angular.INgModelOptions>("ngModelOptions");
        this.employeeResource = this.di<IEmployeeResource>("employeeResource");
        this.isPhoneConfirmed = this.employee.info.IsPhoneConfirmed;
        this.login = this.employee.info.Login;
        this.isImpersonated = this.employee.isImpersonated();
        this.authenticationService = this.di<AuthenticationService>("authenticationService");

        this.phoneData.oldData = this.userData.phone;

        if (!this.isPhoneConfirmed) {
            const notifyService = this.di<NotifyService>("notifyService");
            notifyService.warningMessage("Подтвердите номер телефона в профиле пользователя");
        }

        const authenticationService = this.di<AuthenticationService>("authenticationService");
        this.showPasswordManagementButton = authenticationService.canUserManagePassword();

        if (!this.userData.IsEmailConfirmed && this.userData.email && this.userData.email.length > 0) {
            this.showingEmailConfirmedDialog = true;
        }
        this.visibleCashboxes = this.userData.SaleChannel !== SALE_CHANNELS.PARTNER;
        this.getSubordinatesEmployee();
    }

    public changeNumber() {
        const employeeResource = this.di<any>("employeeResource");
        this.phoneData.newData = this.userData.phone;
        this.isPhoneConfirmed = (this.phoneData.newData !== this.phoneData.oldData || !this.userData.phone || this.userData.phone.length !== 18) ? false : true;

        if (!this.userData.phone || this.phoneData.newData === this.phoneData.oldData || (this.userData.phone && this.userData.phone.length !== 18)) {
            this._$scope.$watchGroup(["codeForm", "phoneForm"], (newValue, oldValue) => {
                this.isPhoneInvalid = true;
                this._$scope.model = {
                    isDisabled: true,
                };
            });
        } else {
            this._$scope.$watchGroup(["codeForm", "phoneForm"], (newValue, oldValue) => {
                this.isPhoneInvalid = false;
                this._$scope.model = {
                    isDisabled: false,
                };
            });
        }
    }

    public savePersonalData() {
        const employeeResource = this.di<any>("employeeResource");
        const notifyStaticProvider = this.di<NotifyStaticProvider>("notifyStaticProvider");
        const notifyService = this.di<NotifyService>("notifyService");
        this.isSavingData = true;
        this.userData.ClientUri = location.origin + location.pathname;
        this.userData.phone = this.phoneData.newData || this.userData.phone;
        employeeResource
            .saveUserData(this.userData)
            .$promise.then(() => {
                if (this.userData.email !== this.employee.info.Email) {
                    this.saveEmailConfirmation(false);
                }
                
                if (this._$scope.phoneForm.phone.$dirty || !this.isPhoneConfirmed) {
                    this.requestConfirmationCode();
                }

                this.employee.deleteEmployeeInfo();
                // todo не совсем правильно зачищать тут, надо будет переделать
                notifyStaticProvider.clearStaticNotifications();
                notifyService.successMessage("Персональные данные успешно сохранены.");
            })
            .catch((err) => {
                console.error("error", err);
            })
            .finally(() => {
                this.isSavingData = false;
            });
    }

    public clearOldData() {
        localStorage.clear();
        sessionStorage.clear();
        this.logout();
        window.parent.postMessage('login', window.parent.origin);
        window.parent.postMessage({ type: "LOGIN" }, "*");
    }

    public logout() {
        this.authenticationService.logout();
        this.$state.go("login");
        this.authenticationService.reLogin();
    }

    public requestConfirmationCode(): void {
        const employeeResource = this.di<any>("employeeResource");
        employeeResource
            .PhoneSendRequestCode({ login: this.login })
            .$promise.then(() => {
                this.isPhoneCodeSent = true;
                this.isPhoneCodeActive = false;

                const $timeout = this.di<ITimeoutService>("$timeout");
                const minute = 1000 * 60;
                $timeout(() => {
                    if (!this.isPhoneConfirmed) {
                        this.isPhoneCodeActive = true;
                    }
                }, 5 * minute);
            })
            .catch((err) => {
                console.log("error", err);
                this.notifyService.errorMessage("Возникла проблема с отправкой кода. Попробуйте повторить запрос");
            })
            .finally(() => {});
    }

    public confirmPhone(): void {
        const employeeResource = this.di<any>("employeeResource");
        employeeResource
            .SendCodeVerification({ code: this.code, login: this.login })
            .$promise.then(() => {
                this.isPhoneCodeSent = false;
                this.notifyService.successMessage("Номер успешно подтверждён!");

                this._$scope.$watchGroup(["codeForm", "phoneForm", "personalDataForm"], (newValue, oldValue) => {
                    this.isPhoneCodeSent = false;
                    this.isPhoneConfirmed = true;
                });
            })
            .catch((err) => {
                this.notifyService.errorMessage("Возникла ошибка при подтверждении, попробуйте ещё раз");
                console.log("ERR!", err);
            })
            .finally(() => {
                this.isPhoneCodeSent = false;
            });
    }

    public getCurrentTime(): string {
        return moment().format("DD.MM.YYYY HH:mm:00 Z");
    }

    public getStateEmailClasses() {
        return this.userData.IsEmailConfirmed ? ["fa", "fa-check"] : ["fa", "fa-times"];
    }

    public getStateEmail() {
        return this.userData.IsEmailConfirmed ? "success" : "error";
    }

    public getStatePhoneClasses() {
        return this.isPhoneConfirmed ? ["fa", "fa-check"] : ["fa", "fa-times"];
    }

    public getStatePhone() {
        return this.isPhoneConfirmed ? "success" : "error";
    }

    public getSubordinatesEmployee() {
        this.employeeResource.getSubordinates().$promise.then((subordinates) => {
            this.subordinates = subordinates;
        });
    }

    public saveEmailConfirmation(val: boolean) {
        this.userData.IsEmailConfirmed = val;
        this.employee.saveUserData(this.userData);
        this.showingEmailConfirmedDialog = !val;
    }

    public isCanEditUserInfo() {
        return !this.employee.isImpersonated();
    }

    public saveCashbox() {
        this.isSavingCashbox = true;
        const cashboxResource = this.di<ICashboxResource>("cashboxResource");
        const notifyService = this.di<NotifyService>("notifyService");
        cashboxResource
            .saveEmployeeCashbox({
                guid: this.userData.cashboxGuid,
            })
            .$promise.then(() => {
                const cashbox = this.cashboxList.find((item: ICashboxItem) => item.Guid === this.userData.cashboxGuid);
                const cashboxName = cashbox ? cashbox.Name : "";
                notifyService.warningMessage(`Вы установили кассу: "${cashboxName}" по умолчанию`);
            })
            .catch(() => {
                //
            })
            .finally(() => {
                this.isSavingCashbox = false;
            });
    }

    public onSubordinatesTitle(item: any) {
        return `${item.Name} ${!item.IsActive ? "(не активен)" : ""}`;
    }
}