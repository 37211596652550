import { Helpers, toggleIBox } from "infrastructure/app.helpers";

interface IBoxScope extends ng.IScope {
    isFullScreen: boolean;
    isHide: () => boolean;
    isShowInfo: boolean;
    showInfo: () => void;
    showhide: () => void;
    closebox: () => void;
    fullscreen: () => void;
}

export function IBoxDirective(
    $parse: ng.IParseService,
    helpers: Helpers,
): ng.IDirective {
    "ngInject";

    return {
        link: (
            scope: IBoxScope,
            element: ng.IAugmentedJQuery,
            attributes: ng.IAttributes,
        ) => {
            scope.isFullScreen = false;
            scope.isHide = () => element.closest("div.ibox").hasClass("collapsed");
            scope.isShowInfo = false;
            if (attributes.infoData) {
                scope.isShowInfo = true;
                const item = $parse(attributes.infoData)(scope);
                scope.showInfo = () => helpers.showInformationWindow(item.content, item.title);
            }
            scope.showhide = () => {
                if (!scope.isFullScreen) {
                    toggleIBox(element.closest("div.ibox"));
                }
            };
            scope.closebox = () => {
                const ibox = element.closest("div.ibox");
                ibox.remove();
            };
            scope.fullscreen = () => {
                if (scope.isHide()) {
                    return;
                }
                scope.isFullScreen = !scope.isFullScreen;
                const ibox = element.closest("div.ibox");
                const button = element.find("i.fa-expand");
                $("body").toggleClass("fullscreen-ibox-mode");
                button.toggleClass("fa-expand").toggleClass("fa-compress");
                ibox.toggleClass("fullscreen");
                setTimeout(() => {
                    $(window).trigger("resize");
                }, 100);
            };
        },
        restrict: "A",
        template: `
            <div class="ibox-tools" uib-dropdown>
                <a ng-click="showhide()" ng-disabled="isFullScreen">
                    <i class="fa fa-chevron-up"></i>
                </a>
                <a ng-click="fullscreen()" ng-disabled="isHide()">
                    <i class="fa fa-expand"></i>
                </a>
                <a ng-click="showInfo()" ng-if="isShowInfo">
                    <i class="fa fa-info-circle"></i>
                </a>
            </div>
        `,
    };
}
