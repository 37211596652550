import angular from "angular";
import { TAnchorToElementFn } from "infrastructure/app.helpers";

export function FocusToDirective(anchorToElement: TAnchorToElementFn): ng.IDirective {
    "ngInject";

    return {
        link: (
            scope: ng.IScope,
            element: ng.IAugmentedJQuery,
            attributes: ng.IAttributes,
        ) => {
            const target = attributes.focusTo;
            element.on("click", () => {
                const targetElement = angular.element([
                    `[name='${target}']`,
                    `[digest-anchor="${target}"]`,
                    `${target}`,
                ].join(", "));
                anchorToElement(targetElement);
            });
        },
        restrict: "A",
    };
}
