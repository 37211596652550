/**
 * @description Валидация ИНН
 * @see https://goo.gl/9TqmHO
 */

interface IInnScope extends ng.IScope {
    model: string;
    name: string;
    maxLength: number;
    innValidationDisabled: boolean,
    onInnValidationError: (e: { error: {
        errors: string[],
        name: string,
    }}) => void;
}

export function InnValidationDirective(): ng.IDirective {
    "ngInject";

    return {
        link: (
            scope: IInnScope,
        ) => {
            if (!scope.innValidationDisabled) {
                scope.$watch("model", () => {
                    const isValid = (input: string) => {
                        const multipliers = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8];
                        let result = 0;
    
                        for (let i = 1; i < input.length; i++) {
                            result += multipliers[multipliers.length - input.length + i] * parseInt(input[i - 1], 10);
                        }
    
                        return (result % 11) % 10 === parseInt(input[input.length - 1], 10);
                    };

                    if (scope.model && scope.model.length > Number(scope.maxLength)) {
                        const error = {
                            errors: [
                                `Количество символов должно быть не больше ${Number(scope.maxLength)} символов`
                            ],
                            name: scope.name,
                        };
                        scope.onInnValidationError({ error });
                    }

                    if (scope.model && scope.model.length === Number(scope.maxLength)) {
                        if ((scope.model.length === 10 && !isValid(scope.model))
                            || (scope.model.length === 12 && (!isValid(scope.model.slice(0, -1))
                            || !isValid(scope.model)))
                        ) {
                            const error = {
                                errors: [
                                    "Неверное значение контрольного числа. Проверьте корректность введенного номера ИНН."
                                ],
                                name: scope.name,
                            };
                            scope.onInnValidationError({ error });
                        }
                    }
                });
            }
        },
        require: "ngModel",
        restrict: "A",
        scope: {
            maxLength: "@maxlength",
            model: "=ngModel",
            name: "@ugskValidation",
            onInnValidationError: "&",
            innValidationDisabled: "<",
        },
    };
}
