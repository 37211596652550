import angular from "angular";
import ugskVehiclePickerModule from "application/components/ugsk-vehicle-picker/ugskVehiclePicker.component";
import ugskVehicledocPickerModule from "application/components/ugsk-vehicledoc-picker/ugskVehicledocPicker.component";
import { OsagoContract } from "application/osago/classes";
import { ITOResponseDTO } from "application/osago/interfaces/ITOResponseDTO";
import { ITOService } from "application/osago/interfaces/ITOService";
import { IDatePickerOptions } from "infrastructure/interfaces/IDatePickerOptions";
import { ITouchSpinConfig } from "infrastructure/interfaces/ITouchSpinConfig";
import { NgComponentController } from "infrastructure/NgController";
import { IRepositoryItem } from "infrastructure/Repository.class";
import { EmployeeService } from "infrastructure/services/employee.service";
import { Int } from "infrastructure/types";

import { OsagoController } from "application/osago/osago.controller";
import { NotifyService } from "infrastructure/services/notifyService";
import { ValidationService } from "infrastructure/services/validation.service";
import "./ugskOsagoVehicleParams.component.css";
import transdekraResourceModule, { IGetCategoriesResponseItem, ITransdekraResource } from "../ugsk-osago-vehicle-transdekra-picker/ugskOsagoVehicleTransdekraPicker.service";
import { USAGE_PURPOSES_ENUM } from "application/constants";
import { IVehicleInfoService } from "application/osago/types";

/**
 * ugskOsagoVehicleParams - вкладка "Параметры договора" (ОСАГО)
 */

const RuMap = {
    А: "F",
    В: "D",
    Г: "U",
    Д: "L",
    Е: "T",
    З: "P",
    И: "B",
    К: "R",
    Л: "K",
    М: "V",
    Н: "Y",
    О: "J",
    П: "G",
    Р: "H",
    С: "C",
    Т: "N",
    У: "E",
    Ф: "A",
    Ц: "W",
    Ч: "X",
    Ы: "S",
    Ь: "M",
    Я: "Z",
};

// tslint:disable-next-line:max-line-length
const LicensePlateValidationRegExp = /^((?![ЬЪЁьъё])[А-Яа-я])(\d{3})((?![ЬЪЁьъё])[А-Яа-я]{2})([0-9]{2,3})$|[ABEKMHOPCTYXabekmhopctyx]\d{3}[ABEKMHOPCTYXabekmhopctyx]{2}\d{2,3}$/;

const DEFAULT_VEHICLE_DOCUMENT_TYPE = "Свидетельство о регистрации ТС";

class OsagoVehicleParamsComponentController extends NgComponentController {
    public baseConfigForTouchSpin: ITouchSpinConfig;
    public writtenEnginePowerTouchSpinConfig: ITouchSpinConfig;
    public vehicleCheckupDateConfig: Partial<IDatePickerOptions>;
    public thisYear: Int;
    public contract: OsagoContract;
    public isFormLocked: boolean;
    public onUpdateFieldRepository: ({ fieldName }) => {};
    public clearVehicleFields = [
        "CaseType",
        "Kpp",
        "Privod",
        "Fuel",
        "EngineVolume",
        "VehicleDocGrossVehicleWeight",
        "VehicleDocCountPassengerSeat",
        "VehicleDocEnginePowerHP",
        "VehicleDocEnginePowerKW",
        "VehicleModificationRSAId",
    ];
    public hasSetVehicleIsNotInTransdekraPermission: boolean;
    public validationService: ValidationService;
    public WrittenManufacturerType: IRepositoryItem<{ManufacturerType: string; ManufacturerTypeId: Int}>;
    public isTORequestInProgress = false;
    public toServiceResponse: ITOResponseDTO = null;
    public transdekraCategories: Array<{ Name: string }>;
    public carInfo: string;
    public hasAutoSearchPermission = false;
    public onUsagePurposeChangedExternal: () => void;
    private rawTransdekraCategories: IGetCategoriesResponseItem[] = [];
    private toService: ITOService;
    private toEGarantService: ITOService;
    private parent: OsagoController;

    public onInit(): void {
        this.WrittenManufacturerType = {
            Collection: [{
                ManufacturerType: "Отечественный",
                ManufacturerTypeId: 1,
            }, {
                ManufacturerType: "Иностранный",
                ManufacturerTypeId: 2,
            }],
        };
        this.baseConfigForTouchSpin = {
            boostat: 5,
            buttondown_class: "btn btn-white",
            buttonup_class: "btn btn-white",
            max: 1000000,
            maxboostedstep: 10,
            min: 0,
            step: 1,
        };

        const transdekraResource = this.di<ITransdekraResource>("transdekraResource");
        this.blockUIInstance.start("Получение данных");
        transdekraResource.getCategories().$promise.then((data) => {
            this.rawTransdekraCategories = data;
            this.prefilterTransdekraVehicleCategory();
        }).finally(() => {
            this.blockUIInstance.stop();
        });
        this.writtenEnginePowerTouchSpinConfig = Object.assign({}, this.baseConfigForTouchSpin);
        this.writtenEnginePowerTouchSpinConfig.decimals = 2;
        this.writtenEnginePowerTouchSpinConfig.step = 0.01;
        this.vehicleCheckupDateConfig = {
            format: "dd.mm.yyyy",
            outFormat: "YYYY-MM-DDTHH:mm:ssZ",
        };

        this.thisYear = (new Date()).getFullYear();
        this.$scope.$watch(
            () => this.contract ? this.contract.VehicleDocEnginePowerHP : null,
            () => this.onEnginePowerHPChanged(),
            false,
        );
        this.$scope.$watch(
            () => this.contract ? this.contract.WrittenEnginePowerHp : null,
            () => {
                if (this.contract.WrittenEnginePowerHp) {
                    this.contract.VehicleDocEnginePowerHP = this.contract.WrittenEnginePowerHp;
                }
            },
            false,
        );

        this.$scope.$watch(() => `${this.contract.VehicleTypeRSA || ""}${this.contract.VehicleType || ""}`, (newVal, oldVal) => {
            if (newVal !== oldVal) {
                this.prefilterTransdekraVehicleCategory();
            }
        });

        this.$scope.$watch(() => this.contract ? this.contract.VehicleIsNotInTransdekra : false, (newVal, oldVal) => {
            if (newVal !== oldVal) {
                if (newVal === true) {
                    this.contract.WrittenEnginePowerHp = this.contract.VehicleDocEnginePowerHP;
                    this.contract.WrittenCountPassengerSeat = this.contract.VehicleDocCountPassengerSeat;
                    this.contract.WrittenGrossVehicleWeight = this.contract.VehicleDocGrossVehicleWeight;
                } else {
                    this.contract.WrittenEnginePowerHp = null;
                    this.contract.WrittenCountPassengerSeat = null;
                    this.contract.WrittenGrossVehicleWeight = null;
                    this.contract.WrittenManufacturerType = null;
                    this.contract.WrittenMark = null;
                    this.contract.WrittenModel = null;
                    this.contract.VehicleTypeRSA = null;
                    this.contract.VehicleCategoryRSA = null;
                }
            }
        });

        this.di<EmployeeService>("employeeService").getEmployee().then((employee) => {
            this.hasSetVehicleIsNotInTransdekraPermission =
                employee.hasPermission("SetVehicleIsNotInTransdekra", "osago");
        });
        this.validationService = this.di<ValidationService>("validationService");
        this.toService = this.di<ITOService>("toService");
        this.toEGarantService = this.di<ITOService>("toEGarantService");
    }

    get vin(): string {
        return this.contract.VehicleVIN;
    }

    set vin(val) {
        const VINSymbols = this.isOldVINFormat() ? /[A-ZА-ЯЁ\d]/i : /[A-HJ-NPR-Z\d]/;
        const newVal = val
            .split("")
            .map((char) => this.isOldVINFormat() ? char : String(RuMap[char.toUpperCase()] || char).toUpperCase())
            .filter((char) => VINSymbols.test(char))
            .join("");

        this.contract.VehicleVIN = newVal;
    }

    get vehicleWeight(): number {
        return this.isNotInTransdekra() ? this.contract.WrittenGrossVehicleWeight : this.contract.VehicleDocGrossVehicleWeight;
    }

    set vehicleWeight(value) {
        this.contract.WrittenGrossVehicleWeight = value;
        this.contract.VehicleDocGrossVehicleWeight = value;
    }

    get passengerSeats(): number {
        return this.isNotInTransdekra()
            ? this.contract.WrittenCountPassengerSeat
            : this.contract.VehicleDocCountPassengerSeat;
    }

    set passengerSeats(value: number) {
        this.contract.WrittenCountPassengerSeat = value;
        this.contract.VehicleDocCountPassengerSeat = value;
    }

    public isNotInTransdekra(): boolean {
        return this.contract.VehicleIsNotInTransdekra;
    }

    public isExtendedFieldsAvailable(): boolean {
        return this.isNotInTransdekra();
    }

    public isVINCorrect(): boolean {
        const VINRe = this.isOldVINFormat() ? /^([A-ZА-ЯЁ\d]){13}(\d{4})$/i : /^([A-HJ-NPR-Z\d]{13})(\d{4})$/;
        return VINRe.test(this.vin);
    }

    public isOldVINFormat(): boolean {
        return this.contract.VehicleYearMade < 2004;
    }

    public onEnginePowerHPChanged(): void {
        if (!this.contract.VehicleDocEnginePowerHP) {
            return;
        }
        this.contract.VehicleDocEnginePowerKW = Math.round(this.contract.VehicleDocEnginePowerHP * 0.7355);
    }

    public onEnginePowerKWTChanged(): void {
        if (!this.contract.VehicleDocEnginePowerKW) {
            return;
        }
        this.contract.VehicleDocEnginePowerHP = Math.round(this.contract.VehicleDocEnginePowerKW * 1.3596);
    }

    public onVehicleTypeChanged() {
        this.clearFields(this.clearVehicleFields);
        this.clearFields([
            "VehicleModelSpelt",
            "VehicleModificationRSAId",
            "VehicleMarkRSAId",
            "VehicleModelRSAId",
            "VehicleType",
            "VehicleYearMade",
            "WrittenModel",
            "WrittenModel",
            "UserDefinedMarkModel",
            "PreviousModelSpelt",
        ]);

        if (this.contract.VehicleIsNotInTransdekra) {
            this.clearNotInTransdekraFields();
            this.contract.VehicleIsNotInTransdekra = false;
        }
    }

    public clearVehicleCheckupDocumentFields(): void {
        ["VehicleCheckupDocumentSerial", "VehicleCheckupDocumentNumber", "VehicleCheckupDate"].map((fieldName) => {
            this.contract[fieldName] = null;
            if (this.validationService.hasError(fieldName)) {
                this.validationService.removeError(fieldName);
            }
        });
    }

    public getRepository(field) {
        const items = this.contract.getRepository()[field] as IRepositoryItem<{}>;
        const collection = items && items.Collection;
        return collection;
    }

    public clearFields(fields) {
        for (const field of fields) {
            this.contract[field] = null;
        }
        return true;
    }

    public async updateFieldRepository({ fieldName }) {
        if (fieldName) {
            if (this.clearVehicleFields.includes(fieldName)) {
                const index = this.clearVehicleFields.indexOf(fieldName) + 1;
                if (index < this.clearVehicleFields.length - 1) {
                    this.clearFields( this.clearVehicleFields.slice(this.clearVehicleFields.indexOf(fieldName) + 1) );
                }
            }
            return await this.onUpdateFieldRepository({ fieldName });
        }
    }

    public vehicleParamMustBeHighlighted(fieldName) {
        return !this.contract.VehicleModificationRSAId && this.contract.VehicleMarkRSAId
            && (this.contract[fieldName] === null || this.contract[fieldName] === undefined)
            && !this.isContractFinalyzed()
            && !this.contract.VehicleIsNotInTransdekra;
    }

    public onModifyClear() {
        this.clearFields([
            "CaseType",
            "Kpp",
            "Privod",
            "Fuel",
            "EngineVolume",
            "VehicleModificationRSAId",
        ]);
    }

    public isContractFinalyzed(): boolean {
        return this.contract.isSigned()
            || this.contract.isAnnuled()
            || this.contract.isSentToRSA()
            || this.contract.isAcceptedByRSA()
            || this.contract.isSentToAccount()
            || this.contract.isAccounted()
            || this.contract.isSentExternalSystem();
    }

    public onVehicleIsNotInTransdekraChanged() {
        if (this.contract.VehicleIsNotInTransdekra) {
            if (this.validationService.hasError("VehicleYearMade")) {
                this.validationService.removeError("VehicleYearMade");
            }
            this.onVehicleTypeChanged();
            const [ focusToElement ] = this.di(["focusToElement"]);
            focusToElement("WrittenMark");
        } else {
            this.clearNotInTransdekraFields();
        }
    }

    public clearNotInTransdekraFields() {
        this.clearFields([
            "WrittenMark",
            "WrittenModel",
            "VehicleTypeRSA",
            "VehicleCategoryRSA",
            "WrittenEnginePowerHp",
            "WrittenGrossVehicleWeight",
            "WrittenCountPassengerSeat",
            "WrittenManufacturerType",
            "UserDefinedMarkModel",
            "VehicleDocEnginePowerHPMinMax",
            "VehicleDocEnginePowerHPMin",
            "VehicleDocEnginePowerHPMax",
        ]);
    }

    public onWrittenMarkModelChanged() {
        this.contract.UserDefinedMarkModel = [this.contract.WrittenMark, this.contract.WrittenModel].join(" ").trim();
    }

    public getTOInfo(): void {
        const notifyService = this.di<NotifyService>("notifyService");
        if (!this.contract.VehicleLicensePlate && !this.contract.VehicleVIN
            && !this.contract.VehicleDocBodyNumber && !this.contract.VehicleDocChassisNumber
            && !this.contract.VehicleCheckupDocumentNumber) {
                notifyService.warningMessage("Внимание",
                    "Не указан ни один из следующих параметров: <ul>"
                        + "<li>Государственный рег. знак</li>"
                        + "<li>VIN</li>"
                        + "<li>Кузов №</li>"
                        + "<li>Шасси (рама)№</li>"
                        + "<li>Номер диагностической карты</li></ul>",
                    { timeout: 5000, type: "warning" },
                );
            } else {
                const toService = this.contract.IsEgarant ? this.toEGarantService : this.toService;
                if (this.isTORequestInProgress) {
                    toService.sendRequest().$cancelRequest();
                    this.isTORequestInProgress = false;
                    return;
                }

                this.isTORequestInProgress = true;
                notifyService.removeToasts();
                this.toServiceResponse = null;
                toService.sendRequest({
                    BodyNumber: this.contract.VehicleDocBodyNumber || "",
                    ChassisNumber: this.contract.VehicleDocChassisNumber || "",
                    LicensePlate: this.contract.VehicleLicensePlate || "",
                    MaintenanceCardNumber: this.contract.VehicleCheckupDocumentNumber || "",
                    Vin: this.contract.VehicleVIN || "",
                }).$promise.then((data) => {
                    setTimeout(() => {
                        this.toServiceResponse = data;
                        this.contract.VehicleCheckupDocumentSerial = null;
                        this.contract.VehicleCheckupDocumentNumber = data.MaintenanceCardNumber;
                        this.contract.VehicleCheckupDate = data.UpcomingMaintenanceDate;
                        this.contract.VehicleCheckupDocumentType = data.MaintenanceCardTypeName;
                    }, 0);

                    if (Array.isArray(data.Errors) && data.Errors.length > 0) {
                        const notCriticalErrors = data.Errors
                            .filter((item) => !item.IsCritical).map((item) => item.Message);
                        const criticalErrors = data.Errors
                            .filter((item) => item.IsCritical).map((item) => item.Message);

                        if (notCriticalErrors.length > 0) {
                            notifyService.warningMessage("Данные ТО", notCriticalErrors.join("<br/>"));
                        }

                        if (criticalErrors.length > 0) {
                            notifyService.errorMessage("Данные ТО", criticalErrors.join("<br/>"));
                        }
                    }
                }).catch(() => {
                    notifyService.errorMessage("Ошибка", "Ошибка получения данных ТО");
                }).finally(() => {
                    notifyService.progressStop();
                    this.isTORequestInProgress = false;
                });
            }
    }

    public getTOResponseStatusClass(): string[] {
        if (this.toServiceResponse
            && this.toServiceResponse.MaintenanceCardExisted
            && this.isMaintenanceCardChecked()) {
            return ["fa", "fa-check"];
        }

        return ["fa", "fa-times"];
    }

    public isMaintenanceCardChecked(): boolean {
        return this.contract.VehicleCheckupDocumentNumber === this.toServiceResponse.MaintenanceCardNumber;
    }

    public prefilterTransdekraVehicleCategory(): void {
        this.transdekraCategories = this.rawTransdekraCategories
            .filter((item) => item.TypeCar.Name === this.contract.VehicleTypeRSA && item.VehicleType.Name === this.contract.VehicleType)
            .map((item) => ({ Name: item.Vorbenko.Name }));
    }

    public getVehicleTypeRSAList(): string[] {
        const arr = this.rawTransdekraCategories
            .filter((item) => item.VehicleType.Name === this.contract.VehicleType && !item.VehicleType.IsDisabled)
            .map((el) => el.TypeCar.Name);

        return Array.from(new Set(arr));
    }

    public isVehicleCategoryRSADisabled(): boolean {
        return !this.hasSetVehicleIsNotInTransdekraPermission || !this.contract.VehicleTypeRSA
            || !Array.isArray(this.transdekraCategories) || this.transdekraCategories.length === 0;
    }

    public isPreviousModelSpeltVisible(): boolean {
        return !Boolean(this.contract.VehicleModelRSAId) && Boolean(this.contract.PreviousModelSpelt);
    }

    public onUsagePurposeChanged(): void {
        if (this.contract.UsagePurpose === USAGE_PURPOSES_ENUM.TAXI && this.contract.IsEgarant) {
            this.contract.IsEgarant = false;
        }

        this.onUsagePurposeChangedExternal();
    }
    public isAutoSearchDisabled(): boolean {
        const hasData = [
            this.contract.VehicleLicensePlate,
            this.contract.VehicleVIN,
            this.contract.VehicleDocBodyNumber,
            this.contract.VehicleDocChassisNumber,
        ].some((elem) => {
            return typeof elem === "string" && elem.trim().length > 0;
        });

        return !hasData || !this.isLicensePlateValidForAutoSearch() || !this.isVinValidForAutoSearch();
    }

    public isLicensePlateValidForAutoSearch(): boolean {
        if (!this.contract.VehicleLicensePlate) {
            return true;
        }

        return LicensePlateValidationRegExp.test(this.contract.VehicleLicensePlate);
    }

    public isVinValidForAutoSearch(): boolean {
        if (!this.contract.VehicleVIN) {
            return true;
        }

        return this.isVINCorrect();
    }

    public getAutoSearchValidationMessage(): string {
        if (!this.isLicensePlateValidForAutoSearch() || !this.isVinValidForAutoSearch()) {
            return "Для запуска автопоиска проверьте значение Государственного рег.знака или введите VIN";
        }
    }

    public doAutoSearch(): void {
        this.onVehicleTypeChanged();
        this.carInfo = "";
        this.contract.VehicleDocuments
            = this.contract.VehicleDocuments.filter((doc) => doc.VehicleDocumentType !== DEFAULT_VEHICLE_DOCUMENT_TYPE);
        const vehicleInfoService = this.di<IVehicleInfoService>("vehicleInfo");
        const notifyService = this.di<NotifyService>("notifyService");

        const licensePlate = this.contract.VehicleLicensePlate && this.contract.VehicleLicensePlate.trim().length > 0
            ? this.contract.VehicleLicensePlate : null;
        const vin = this.contract.VehicleVIN && this.contract.VehicleVIN.trim().length > 0
            ? this.contract.VehicleVIN : null;
        const bodyNumber = this.contract.VehicleDocBodyNumber && this.contract.VehicleDocBodyNumber.trim().length > 0
            ? this.contract.VehicleDocBodyNumber : null;
        const chassisNumber =
            this.contract.VehicleDocChassisNumber && this.contract.VehicleDocChassisNumber.trim().length > 0
            ? this.contract.VehicleDocChassisNumber : null;

        this.blockUIInstance.start("Поиск данных ТС");
        vehicleInfoService.getVehicleInfoByAll({
            bodyNumber,
            chassisNumber,
            licensePlate,
            vin,
        }).$promise.then((result) => {
            const getStringValue = (value: string | null): string => {
                if (value === null || (typeof value === "string" && value === "NotDefined" || value === "")) {
                    return "";
                }

                return value ;
            };

            this.contract.VehicleVIN = getStringValue(result.vin);
            this.contract.VehicleDocChassisNumber = getStringValue(result.chassisNumber);
            this.contract.VehicleDocBodyNumber = getStringValue(result.bodyNumber);
            this.contract.VehicleLicensePlate = getStringValue(result.licensePlate);
            this.$scope.$broadcast("onAutoSearchComplete", result);

            const carInfoMap = new Map<string, string>([
                ["brand", "марка"],
                ["model", "модель"],
                ["yearMade", "год выпуска"],
                ["enginePowerHp", "мощность двигателя, л.с."],
                ["maxWeight", "максимальная масса, кг."],
                ["seatsCount", "кол-во пассажирских мест"],
                ["vehicleCategory", "категория ТС"],
                ["vehicleType", "тип ТС"],
            ]);

            const carInfoArray: string[] = [];
            Object.getOwnPropertyNames(result).forEach((resultKey) => {
                const value = getStringValue(result[resultKey]);

                if (value !== "" && carInfoMap.get(resultKey)) {
                    carInfoArray.push(`${carInfoMap.get(resultKey)}: ${value}`);
                }
            });

            if (carInfoArray.length > 0) {
                this.carInfo = carInfoArray.join("; ");
            }

            if (result.registrationCertificateNumber || result.registrationCertificateSerial) {
                const vehicleDoc = {
                    Id: 0,
                    VehicleDocIssuredDate: null,
                    VehicleDocNumber: result.registrationCertificateNumber && result.registrationCertificateNumber.trim().length > 0 ?
                        result.registrationCertificateNumber : "",
                    VehicleDocSerial: result.registrationCertificateSerial && result.registrationCertificateSerial.trim().length > 0 ?
                        result.registrationCertificateSerial : "",
                    VehicleDocumentType: DEFAULT_VEHICLE_DOCUMENT_TYPE,
                };

                const isDocumentExists = this.contract.VehicleDocuments.some((doc) =>
                    doc.VehicleDocNumber === vehicleDoc.VehicleDocNumber && doc.VehicleDocSerial === vehicleDoc.VehicleDocSerial
                );

                if (!isDocumentExists) {
                    this.contract.VehicleDocuments.push(vehicleDoc);
                    notifyService.message("Документы ТС", "Необходимо проверить и дозаполнить данные документа ТС", {
                        timeout: 10000,
                        type: "warning",
                    });
                }
            }
        }).catch((reason) => {
            const title = "Поиск данных ТС";
            let message = "В процессе поиска данных ТС произошла ошибка";

            if (reason && reason.data && reason.data.Message) {
                message = reason.data.Message;
            }

            notifyService.errorMessage(title, message);
        }).finally(() => {
            this.blockUIInstance.stop();
        });
    }
}

export default angular.module("app.osago.components.vehicle-params", [
    ugskVehiclePickerModule,
    ugskVehicledocPickerModule,
    transdekraResourceModule,
]).component("ugskOsagoVehicleParams", {
    bindings: {
        contract: "=",
        isFormLocked: "<",

        //  vehicledoc-picker
        documents: "=",
        onUpdateFieldRepository: "&",
        onUsagePurposeChangedExternal: "&onUsagePurposeChanged",
        onVehicleChanged: "&",
        onVehicleYearChanged: "&",
        vehicleDocumentTypes: "<",

        //  self
        hasAutoSearchPermission: "<",
        hasInspectionPermission: "<",
        isNewState: "<",
        usagePurposes: "<",
        vehicleCheckupDocumentTypes: "<",

        onIsNewChanged: "&",
        onIsSeasonChanged: "&",
        onStaticValidation: "&",
        parent: "<",
    },
    controller: OsagoVehicleParamsComponentController,
    controllerAs: "vm",
    template: require("./ugskOsagoVehicleParams.component.html"),
})
.name;
