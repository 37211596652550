import angular, { ITimeoutService } from "angular";
import { IVehicleInfoResult } from "application/osago/types";
import IBlockUI from "infrastructure/interfaces/IBlockUI";
import { NgComponentController } from "infrastructure/NgController";
import { ValidationService } from "infrastructure/services/validation.service";
import moment from "moment";
import "./ugskOsagoVehicleTransdekraPicker.css";
import transdekraResourceModule,
{
    IGetModelsResponseItem,
    IGetModificationsResponseItem, ITransdekraResource,
} from "./ugskOsagoVehicleTransdekraPicker.service";

const VehicleTypeRequiredFieldMap = new Map<string, string[]>([
    ["Легковые автомобили", ["enginePowerHp", "enginePowerKw"]],
    ["Грузовые автомобили", ["maxWeight"]],
    ["Автобусы", ["passengerSeat"]],
    ["Тракторы, самоходные дорожно-строительные и иные машины", ["maxWeight", "enginePowerHp", "enginePowerKw"]],
    ["Мотоциклы и мотороллеры", ["enginePowerHp", "enginePowerKw"]],
    ["Трамваи", ["enginePowerHp", "enginePowerKw"]],
    ["Троллейбусы", ["enginePowerHp", "enginePowerKw", "passengerSeat"]],
]);

class UgskOsagoVehicleTransdekraPickerController extends NgComponentController {
    public ngDisabled: boolean;
    public modelSpelt: string;
    public vehicleType: string;
    public model: number;
    public marka: number;
    public transdekraResource: ITransdekraResource;
    public modificationsList: IGetModificationsResponseItem[] = [];
    public ngModelOptions: angular.INgModelOptions;
    public year: number;
    public maxWeight: number;
    public passengerSeat: number;
    public enginePowerHp: number;
    public enginePowerKw: number;
    public modify: number;
    public enginePowerType: "hp" | "kw";
    public vehicleIsNotInTransdekra: boolean;
    public previousModelSpelt: string;
    public loadingModels: boolean;
    public yearHintsList: string[] = [];
    public maxWeightHintsList: string[] = [];
    public passengerSeatHintsList: string[] = [];
    public powerEngineHintsList: string[] = [];
    private validationService: ValidationService;
    private onModelClear: () => void;
    private modelSpeltWatcher: () => void;
    private blockUI: IBlockUI;

    public onInit() {
        this.enginePowerType = "hp";

        this.transdekraResource = this.di("transdekraResource");
        this.ngModelOptions = this.di<angular.INgModelOptions>("ngModelOptions");
        this.validationService = this.di<ValidationService>("validationService");
        this.blockUI = this.di<IBlockUI>("blockUI");

        if (this.model && this.modelSpelt) {
            this.initModelSpeltWatcher();
        }

        if (this.modify) {
            this.blockUIInstance.start("Получение данных");
            this.transdekraResource
                .getModifications({ markId: this.marka, modelId: this.model, vehicleTypeName: this.vehicleType })
                .$promise
                .then((data) => {
                    this.modificationsList = this.prepareModificationsList(data);
                    this.getYearHints();
                    this.getHints();
                }).finally(() => {
                this.blockUIInstance.stop();
            });
        }

        this.$scope.$watch(() => this.modelSpelt, () => {
            const modelSelection = "Выберите Марку Модель ТС";
            const notFound = "Совпадений не найдено";
            const hasModelSelectionValidationMessage = modelSelection in (this.validationService.getMessages("VehicleModelSpelt") || []);
            const hasNotFoundValidationMessage = notFound in (this.validationService.getMessages("VehicleModelSpelt") || []);

            const removeValidationMessage = (message: string) => {
                const currentMessages = this.validationService.getMessages("VehicleModelSpelt") || [];
                this.validationService.removeError("VehicleModelSpelt");
                const newMessages = currentMessages.filter((msg) => msg !== message);

                if (newMessages.length > 0) {
                    this.validationService.addError("VehicleModelSpelt", newMessages);
                }
            };

            if (!this.modelSpelt && !hasModelSelectionValidationMessage && !this.ngDisabled) {
                this.validationService.addError("VehicleModelSpelt", [modelSelection]);
            } else {
                removeValidationMessage(modelSelection);
            }

            if (this.modelSpelt && !hasNotFoundValidationMessage && !this.model && !this.ngDisabled) {
                this.validationService.addError("VehicleModelSpelt", [notFound]);
            } else {
                removeValidationMessage(notFound);
            }
        });

        this.$scope.$watch(() => this.vehicleType, (newVal, oldVal) => {
            if (newVal !== oldVal) {
                const currentVehicleTypeProperties = VehicleTypeRequiredFieldMap.get(newVal) || [];
                const allProperties = Array.from(VehicleTypeRequiredFieldMap.values())
                    .reduce((a, c) => {
                        a.push(...c);
                        return a;
                    }, []);
                const propertiesToBeCleared = allProperties
                    .filter((property) => !currentVehicleTypeProperties.includes(property));

                if (propertiesToBeCleared.length > 0) {
                    propertiesToBeCleared.forEach((property) => this[property] === null);
                }
            }
        });

        const $timeout = this.di<ITimeoutService>("$timeout");
        this.$scope.$on("onAutoSearchComplete", (event, data: IVehicleInfoResult) => {
            $timeout(() => {
                this.onClearModelButtonClicked();
            });
            $timeout(() => {
                const modelSpelt = [data.brand, data.model].filter((item) => item).join(" ");
                this.getModels(modelSpelt).then((result) => {
                    const exactModel = result.find((item) => String(item.Result).trim() === String(modelSpelt).trim());

                    this.modelSpelt = modelSpelt;
                    if (exactModel) {
                        this.onSelect(exactModel).then(() => {
                            $timeout(() => {
                                this.year = data.yearMade;

                                const vehicleInfoToContractParamsMap = new Map<string, string>([
                                    ["enginePowerHp", "enginePowerHp"],
                                    ["maxWeight", "maxWeight"],
                                    ["passengerSeat", "seatsCount"],
                                ]);

                                const requiredProperties = VehicleTypeRequiredFieldMap.get(this.vehicleType);

                                for (const property of requiredProperties) {
                                    const propertyKey = vehicleInfoToContractParamsMap.get(property);

                                    this[propertyKey] = data[property];
                                }

                                if (requiredProperties.length > 0) {
                                    this.autoselectModification();
                                }
                            });
                        });
                    }
                });
            });
        });

    }

    public getModels(text: string): angular.IPromise<IGetModelsResponseItem[]> {
        return this.transdekraResource.getModels({
            category: this.vehicleType,
            query: text,
        }).$promise;
    }

    public getSelectionStateClasses(): string[] {
        if (this.modelSpelt && this.model) {
            return ["fa", "fa-check"];
        }
        return ["fa", "fa-times"];
    }

    public onSelect($item): Promise<void> {
        this.vehicleType = $item.VehicleType;
        this.model = $item.ModelId;
        this.marka = $item.MarkaId;
        this.modificationsList = [];
        this.year = null;

        this.initModelSpeltWatcher();

        return new Promise((resolve) => {
            const $timeout = this.di<ITimeoutService>("$timeout");
            $timeout(() => {
                this.blockUI.start("Получение данных");
                this.transdekraResource
                    .getModifications({
                        modelId: this.model,
                        markId: this.marka,
                        vehicleTypeName: this.vehicleType,
                    }).$promise
                    .then((data) => {
                        this.modificationsList = this.prepareModificationsList(data);
                        this.getYearHints();
                    }).finally(() => {
                    this.blockUI.stop();
                    resolve();
                });
            }, 0);
        });
    }

    public isModelSelected(): boolean {
        if (this.ngDisabled && this.year) {
            return true;
        }
        return Boolean(this.model && this.marka);
    }

    public getModificationListFiltered(): IGetModificationsResponseItem[] {
        let prefilteredList: IGetModificationsResponseItem[] = [];
        let filtered = false;

        const sourceList = this.modificationsList;
        const hasValue = (value: number): boolean => {
            return value !== null && value !== undefined && String(value).length > 0;
        };

        if (Array.isArray(sourceList) && sourceList.length > 0) {
            if (this.year) {
                filtered = true;
                const yearPredicate = (item: IGetModificationsResponseItem): boolean => {
                    const yearStart = moment(item.ProdStart, "YYYY-MM-DDTHH:mm:ss").year();
                    const yearEnd = moment(item.ProdEnd, "YYYY-MM-DDTHH:mm:ss").year();

                    return this.year >= yearStart && this.year <= yearEnd;
                };
                prefilteredList.push(...sourceList.filter(yearPredicate));
            }

            if (this.getRequiredFieldName().includes("enginePowerHp") && hasValue(this.enginePowerHp)) {
                filtered = true;
                const enginePredicate = (item: IGetModificationsResponseItem): boolean => {
                    return (item.IsTransdekra === true && item.EngPwr === this.enginePowerHp)
                        || (item.IsTransdekra === false && this.enginePowerHp >= item.EngPwrMin
                            && this.enginePowerHp <= item.EngPwrMax);
                };

                prefilteredList = prefilteredList.filter(enginePredicate);
            }

            if (this.getRequiredFieldName().includes("maxWeight") && hasValue(this.maxWeight)) {
                filtered = true;
                const weightPredicate = (item: IGetModificationsResponseItem): boolean => {
                    return (item.IsTransdekra === true && item.MaxWeight === this.maxWeight)
                        || (item.IsTransdekra === false && this.maxWeight >= item.MaxWeightMin
                            && this.maxWeight <= item.MaxWeightMax && (item.MaxWeightMax > 0 && item.MaxWeightMin >= 0))
                        || (item.IsTransdekra === false && item.MaxWeightMax === 0 && item.MaxWeightMin === 0
                            && this.maxWeight >= 1 && this.maxWeight <= 160000);
                };

                prefilteredList = prefilteredList.filter(weightPredicate);
            }

            if (this.getRequiredFieldName().includes("passengerSeat") && hasValue(this.passengerSeat)) {
                filtered = true;
                const passengerSeatPredicate = (item: IGetModificationsResponseItem): boolean => {
                    return (item.IsTransdekra === true && item.CarSeats === this.passengerSeat)
                        || (item.IsTransdekra === false && this.passengerSeat >= item.CarSeatsMin
                            && this.passengerSeat <= item.CarSeatsMax && (item.CarSeatsMax > 0 && item.CarSeatsMin >= 0))
                        || (item.IsTransdekra === false && item.CarSeatsMax === 0 && item.CarSeatsMin === 0
                            && this.passengerSeat >= 1 && this.passengerSeat <= 100);
                };

                prefilteredList = prefilteredList.filter(passengerSeatPredicate);
            }

            if (prefilteredList.length === 0 && filtered) {
                this.validationService.addError(
                    "hasNoModifications",
                    ["По указанным параметрам информация в справочнике отсутствует"],
                );
                this.onModifyClearButtonClicked();
            } else {
                this.validationService.removeError("hasNoModifications");
            }

            if (prefilteredList.length > 0 && this.modify) {
                // Сбрасываем значение идентификатора модификации, если оно было установлено, но отсутствует в справочнике.
                const modificationIsValid = prefilteredList.some((modification) => modification.Id === this.modify);
                if (!modificationIsValid) {
                    this.modify = null;
                    this.onModificationSelect();
                }
            }

            return prefilteredList;
        }

        this.validationService.removeError("hasNoModifications");
        return this.modificationsList;
    }

    public set enginePower(value: number) {
        if (value === null) {
            this.enginePowerKw = null;
            this.enginePowerHp = null;
            return;
        }

        if (this.enginePowerType === "hp") {
            this.enginePowerHp = value;
            this.enginePowerKw = Math.round(value * 0.7355);
        } else {
            this.enginePowerKw = value;
            this.enginePowerHp = Math.round(value * 1.3596);
        }
    }

    public get enginePower(): number {
        return this.enginePowerType === "hp" ? this.enginePowerHp : this.enginePowerKw;
    }

    public getRequiredFieldName(): string[] {
        if (this.vehicleType) {
            const fields = VehicleTypeRequiredFieldMap.get(this.vehicleType) || [];

            if (!this.modelSpelt.startsWith("Другая марка Другая модель ТС")) {
                if (this.vehicleType === "Тракторы, самоходные дорожно-строительные и иные машины") {
                    return fields.filter((item) => item !== "maxWeight");
                }

                if (this.vehicleType === "Троллейбусы") {
                    return fields.filter((item) => item !== "passengerSeat");
                }
            }

            return fields;
        }

        return [];
    }

    public isYearMadeValid(): boolean {
        return this.year <= moment().year() && this.year >= 1930;
    }

    public onClearModelButtonClicked(): void {
        this.modelSpelt = null;
        this.marka = null;
        this.model = null;
        this.year = null;
        this.vehicleType = null;
        this.vehicleIsNotInTransdekra = false;
        this.modificationsList = [];

        this.onModelClear();

        if (this.validationService.hasError("hasNoModifications")) {
            this.validationService.removeError("hasNoModifications");
        }

        Array.from(VehicleTypeRequiredFieldMap.values())
            .reduce((a, c) => {
                a.push(...c);
                return a;
            }, [])
            .forEach((property) => this[property] = null);
    }

    public onEnginePowerClearButtonClicked(): void {
        this.enginePower = null;
        this.modify = null;
    }

    public isShowModification(): boolean {
        let result = true;

        const requiredFields = VehicleTypeRequiredFieldMap.get(this.vehicleType) || [];

        if (requiredFields.length > 0) {
            requiredFields.forEach((fieldName) => {
                if (this[fieldName] === undefined || this[fieldName] === null) {
                    result = false;
                }
            });
        }

        return this.isModelSelected() && this.isYearMadeValid() && result;
    }

    public onYearChange(): void {
        const allProperties = Array.from(VehicleTypeRequiredFieldMap.values())
            .reduce((a, c) => {
                a.push(...c);
                return a;
            }, []);

        allProperties.forEach((propName) => this[propName] = null);
        this.modify = null;

        const requiredFields = this.getRequiredFieldName();

        this.getModificationListFiltered();

        this.getHints();

        if (requiredFields.length === 0) {
            this.autoselectModification();
        }
    }

    public onCustomAttributeChanged(): void {
        if (this.enginePower || this.maxWeight || this.passengerSeat) {
            this.getModificationListFiltered();
            this.autoselectModification();
        } else {
            this.onModifyClearButtonClicked();
        }
    }

    public onModificationSelect(): void {
        if (this.modify) {
            const currentModification = this.modificationsList.find((item) => item.Id === this.modify);

            if (currentModification && currentModification.ExtendedFields) {
                this.vehicleIsNotInTransdekra = true;
            } else {
                this.vehicleIsNotInTransdekra = false;
            }

        }
    }

    public onModifyClearButtonClicked(): void {
        this.modify = null;
    }

    public unique(arr: any[]): any[] {
        return Array.from(new Set(arr)).sort();
    }

    public getHints(): void {
        let sourceList = this.modificationsList;
        if (this.model) {
            sourceList = sourceList.filter((el) => el.ModelId === this.model);
        }
        if (this.year) {
            sourceList = sourceList.filter((el) => Number(el.ProdStart.slice(0, 4)) <= this.year && Number(el.ProdEnd.slice(0, 4)) >= this.year);
        }

        if (this.getRequiredFieldName().includes("maxWeight")) {
            this.maxWeightHintsList = this.unique(sourceList.map((el) => el.MaxWeight));
        }
        if (this.getRequiredFieldName().includes("passengerSeat")) {
            this.passengerSeatHintsList = this.unique(sourceList.map((el) => el.CarSeats));
        }
        if (this.getRequiredFieldName().includes("enginePowerHp")) {
            this.powerEngineHintsList = this.unique(sourceList.map((el) => el.EngPwr));
        }
    }

    public getYearHints() {
        if (this.modificationsList.length === 0) {
            return [];
        }

        const arr = [];
        let ProdStart = null;
        let ProdEnd = null;
        this.modificationsList.forEach((el) => {
            const startYear = Number(el.ProdStart.slice(0, 4));
            const endYear = Number(el.ProdEnd.slice(0, 4));
            if (!ProdStart) {
                ProdStart = startYear;
            }
            if (ProdStart > startYear) {
                ProdStart = startYear;
            }

            if (!ProdEnd) {
                ProdEnd = endYear;
            }
            if (ProdEnd < endYear) {
                ProdEnd = endYear;
            }
        });

        for (let i = ProdStart; i <= ProdEnd; i++) {
            arr.push(i);
        }

        this.yearHintsList = arr;
    }

    private autoselectModification(): void {
        const $timeout = this.di<ITimeoutService>("$timeout");
        $timeout(() => {
            const modifications = this.getModificationListFiltered();
            if (Array.isArray(modifications) && modifications.length > 0) {
                this.modify = modifications[0].Id;
                this.onModificationSelect();
            } else {
                this.onModifyClearButtonClicked();
            }
        }, 500);
    }

    private initModelSpeltWatcher(): void {
        if (typeof this.modelSpeltWatcher === "function") {
            this.modelSpeltWatcher();
        }

        this.modelSpeltWatcher = this.$scope.$watch(
            () => this.modelSpelt,
            (newVal, oldVal) => {
                if (String(newVal).trim() !== String(oldVal).trim()) {
                    this.model = null;
                    this.onClearModelButtonClicked();
                    this.modelSpeltWatcher();
                }
            },
        );
    }

    private prepareModificationsList(sourceList: IGetModificationsResponseItem[]): IGetModificationsResponseItem[] {
        return sourceList.map((item) => {
            if (item.ExtendedFields === true && item.CarModif === "") {
                item.CarModif = "Другая модификация";
            }

            return item;
        });
    }
}

export default angular
    .module("app.osago.components.vehicle-transdekra-picker-new", [transdekraResourceModule])
    .component("ugskOsagoVehicleTransdekraPicker", {
        bindings: {
            enginePowerHp: "=",
            enginePowerKw: "=",
            marka: "=",
            maxWeight: "=",
            model: "=",
            modelSpelt: "=ngModel",
            modify: "=",
            ngDisabled: "<",
            onModelClear: "&",
            passengerSeat: "=",
            previousModelSpelt: "=",
            vehicleIsNotInTransdekra: "=",
            vehicleType: "=",
            year: "=",
        },
        controller: UgskOsagoVehicleTransdekraPickerController,
        controllerAs: "vm",
        template: require("./ugskOsagoVehicleTransdekraPicker.component.html"),
        transclude: true,
    }).name;
