import { EnvService } from "infrastructure/services/env.service";
import { IResourceResult } from "../../interfaces/IResourceResult";
import { IUproResourceActionHash } from "../../interfaces/IUproResourceActionHash";
/**
* imageRepositoryResource - сервис для работы с ХИ
*/
export interface IParamsOpenImageRespository {
    ContractGuid: string;
    ContractPolicyNumber: string;
    ContractSigningDate: string;
    ContractTerritoryGuid: string;
}

export interface IImageRepositoryResource extends ng.resource.IResourceClass<any> {
    canUploadFilesToImageRepository: () => IResourceResult<any>;
    getImageRepositoryUploaderUrl: (params: IParamsOpenImageRespository) => IResourceResult<{ urlProperty: string }>;
    openOrCreateContainer: (params: IParamsOpenImageRespository) => IResourceResult<any>;
}

export function imageRepositoryResource($resource: ng.resource.IResourceService , envService: EnvService): IImageRepositoryResource {
    "ngInject";

    const apiUrl = `${envService.read("irProxy")}Main/`;
    const actions: IUproResourceActionHash = {
        canUploadFilesToImageRepository: {
            _suppressErrors: true,
            method: "GET",
            url: `${apiUrl}CanUploadFilesToImageRepository`,
        },
        getImageRepositoryUploaderUrl: {
            _suppressErrors: true,
            method: "POST",
            url: `${apiUrl}OpenImageRepositoryUploader`,
        },
        openOrCreateContainer: {
            _suppressErrors: true, // в случае 401 на ХИ - сбрасывает токен ЮПро, что не корректно.
            method: "POST",
            url: `${apiUrl}OpenOrCreateContainer`,
        },
    };
    return $resource("", {}, actions) as IImageRepositoryResource;
}
