import { Product } from "domain/classes/product.class";
import { IFLJournalController } from "../journal/iflJournal.controller";
import { ConstructorHomeToolbarController } from "./constructorHome-toolbar.controller";
import { ConstructorHomeController } from "./constructorHome.controller";
import { ConstructorHomeJournalToolbarController } from "./journal/constructorHomeJournal-toolbar.controller";

import template from "./body.html";

import angular from "angular";
import { ProductsService } from "infrastructure/services/products.service";
import manual from "./manual_constructorHome.pdf";
import { ContructorHomeContract } from "./constructorHome.factory";

class ConstructorHome extends Product {
    constructor() {
        super();
        this.code = "constructorHome";
        this.groupName = "Страхование имущества";
        this.productName = "Комфорт (дом)";
        this.description = "Страхование домов с выбором объектов, страховых сумм и периода страхования";
        this.insuranceRules = {
            base: "https://ugsk.ru/about/pravila/Imushestvo.pdf",
        };
        this.insurerCanBeLegalEntity = false;
        this.logo = "nav_constructorHome";
        this.manualUrl = manual;
        this.hasImageRepositoryAbility = true;
        this.sort = 60;
        this.template = template;
        this.controller = ConstructorHomeController;
        this.toolbarController = ConstructorHomeToolbarController;
        this.journalController = IFLJournalController;
        this.journalToolbarController = ConstructorHomeJournalToolbarController;
        this.extraViews = {
            "additionalConstructions@app.constructorHome.index": {
                template: require("./view.additionalConstructions.html"),
            },
            "contractParams@app.constructorHome.index": {
                template: require("./view.contractParams.html"),
            },
            "insuranceCategory@app.constructorHome.index": {
                template: require("./view.insuranceCategory.html"),
            },
            "insurant@app.constructorHome.index": {
                template: require("./view.insurant.html"),
            },
            "mainBuilding@app.constructorHome.index": {
                template: require("./view.mainBuilding.html"),
            },
            "payment@app.constructorHome.index": {
                template: require("./view.payment.html"),
            },
        };
    }

    get contractClass(): typeof ContructorHomeContract {
        return ContructorHomeContract;
    }
}

angular.module("app.constructorHome", [
])
.run((productsService: ProductsService) => {
    "ngInject";

    productsService.register(new ConstructorHome());
});
