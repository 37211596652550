import angular, { IPromise, IQService } from "angular";
import { NgComponentController } from "infrastructure/NgController";
import moment from "moment";
import { from, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import "./ugskAdditionalPhones.component.css";
import template from "./ugskAdditionalPhones.component.html";

interface IAdditionalPhone {
  ContractorName: string;
  ContractorPhone: string;
  ActualDate: string;
}

interface IAdditionalPhonesResource {
  getAdditionalPhones(params: { Id: number }): ng.resource.IResource<IAdditionalPhone[]>;
}

class AdditionalPhonesComponentController extends NgComponentController {
  public contractId: number;
  public phones: IAdditionalPhone[] = [];

  public onInit(): IPromise<void> {
    const $q = this.di<IQService>("$q");
    const additionalPhonesResource: IAdditionalPhonesResource = this.di("additionalPhonesResource");
    return $q((resolve) => {
      from(additionalPhonesResource.getAdditionalPhones({ Id: this.contractId }).$promise)
        .pipe(
          map((phones) => {
            return phones.map((item) => {
              const momentDate = moment(item.ActualDate);
              return {
                ActualDate: momentDate.isValid() ? momentDate.format("DD.MM.YYYY") : "",
                ContractorName: item.ContractorName,
                ContractorPhone: item.ContractorPhone,
              };
            });
          }),
          catchError(() => {
            return of([]);
          }),
        )
        .subscribe({
          // tslint:disable-next-line: no-empty
          error: () => {},
          next: (response) => {
            this.phones = response;
            resolve();
          },
        });
    });
  }
}

export default angular.module("ugsk.components.additional-phones", []).component("ugskAdditionalPhones", {
  bindings: {
    contractId: "<",
  },
  controller: AdditionalPhonesComponentController,
  controllerAs: "vm",
  template,
}).name;
