import angular from "angular";
import {
    AutocompleteOffDirective, BindDomDirective, CarouselSlideDirective, DatepickerDirective,
    DecimalFormatDirective, DropdownAutoPositionDirective, DropdownMinTopDirective, EmailUgskValidationDirective,
    EmailValidationDirective, ErrorsCountDirective, ExcelFileSelectDirective, FocusToDirective, IBoxDirective,
    IcheckDirective, InfiniteScrollExtraLoaderDirective, InnValidationDirective, InputMaskDirective, InputRegExpDirective,
    KppValidationDirective, LabelTitleDirective, LazyLoadDirective, LicensePlateValidation, PageTitleDirective,
    SelectpickerDirective, SnilsValidationDirective, StaticValidationDirective, ThousandDirective, TrimValueDirective, UibTabDockingDirective,
    ValidationDirective,
} from "./directives";

export default angular
    .module("ugsk.directives", [])
    .directive("autocomplete", AutocompleteOffDirective)
    .directive("thousand", ThousandDirective)
    .directive("decimalFormat", DecimalFormatDirective)
    .directive("trimValue", TrimValueDirective)
    .directive("labelTitle", LabelTitleDirective)
    .directive("pageTitle", PageTitleDirective)
    .directive("inputMask", InputMaskDirective)
    .directive("excelFileSelect", ExcelFileSelectDirective)
    .directive("icheck", IcheckDirective)
    .directive("ugskValidation", ValidationDirective)
    .directive("emailValidation", EmailValidationDirective)
    .directive("emailUgskValidation", EmailUgskValidationDirective)
    .directive("innValidation", InnValidationDirective)
    .directive("snilsValidation", SnilsValidationDirective)
    .directive("focusTo", FocusToDirective)
    .directive("lazyLoad", LazyLoadDirective)
    .directive("onCarouselSlide", CarouselSlideDirective)
    .directive("ugskDropdownAutoPosition", DropdownAutoPositionDirective) // TODO Не нашел, но Вася попросил ее оставить
    .directive("ugskBindDom", BindDomDirective)
    .directive("dropdownMinTop", DropdownMinTopDirective)
    .directive("infiniteScrollExtraLoader", InfiniteScrollExtraLoaderDirective)
    .directive("errorsCount", ErrorsCountDirective)
    .directive("uibTabDocking", UibTabDockingDirective)
    .directive("iboxTools", IBoxDirective)
    .directive("ugskDatepicker", DatepickerDirective)
    .directive("selectpicker", SelectpickerDirective)
    .directive("staticValidation", StaticValidationDirective)
    .directive("licensePlateValidation", LicensePlateValidation)
    .directive("inputRegExp", InputRegExpDirective)
    .directive("kppValidation", KppValidationDirective)
    .name;
