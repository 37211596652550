import angular from "angular";
import moment from "moment";

export default angular.module("ugsk.filters", [])
    .filter("thousand", thousand)
    .filter("sum", sum)
    .filter("firstOrDefault", firstOrDefault)
    .filter("datetimeFormat", datetimeFormat)
    .filter("replace", replace)
    .filter("filterPicker", filterPicker)
    .filter("range", range)
    .filter("dateNoTz", dateNoTz)
    .filter("localDateTimeFormat", localDateTimeFormat)
    .name;

function range() {
    return (input: number[], total: string): number[] => {
        const totalInt = parseInt(total, 10);
        for (let i = 0; i < totalInt; i++) {
            input.push(i);
        }
        return input;
    };
}

function filterPicker($interpolate: ng.IInterpolateService) {
    "ngInject";

    return (value: any, name: string): string => {
        if (!name) {
            return value;
        }
        const result = $interpolate(`{{value | ${name}}}`);
        return result({ value });
    };
}

/**
 * @description Currency filter
 */
const thousandRe = /(\d)(?=(\d\d\d)+(?!\d))/g;
function thousand() {
    return (val: string | number, postfix: string = "", emptyPlaceholder: string = ""): string => {
        let numberValue: number;
        if (angular.isString(val)) {
            const trimmedVal = val.replace(/\s{1,}/g, "");
            numberValue = parseFloat(trimmedVal);
        } else if (angular.isNumber(val)) {
            numberValue = val;
        } else {
            return (!val) ? emptyPlaceholder : val;
        }
        if (isNaN(numberValue)) {
            return String(val);
        }
        let stringValue: string;
        if (Number.isInteger(numberValue)) {
            stringValue = numberValue.toFixed(0);
        } else {
            stringValue = numberValue.toFixed(2);
        }
        return stringValue.replace(thousandRe, "$1 ") + (postfix ? " " + postfix : "");
    };
}

function datetimeFormat() {
    return (text): string => {
        if (text && moment(text, "YYYY-MM-DD").isValid()) {
            return moment(text, "YYYY-MM-DD").format("DD.MM.YYYY");
        }
        return "";
    };
}

function localDateTimeFormat() {
    return (text): string => {
        if (text && moment(text).isValid()) {
            return moment(text).format("DD.MM.YYYY HH:mm");
        }
        return "";
    };
}

/**
 * Фильтр преобразования даты без учета часового пояса
 * @param {String} date Входное значение
 * @param {String} format Формат даты на выходе для Moment.js (опционально)
 * @return {String} Строковое представление даты
 * @see https://momentjs.com/docs/#/parsing/string-format/
 */
function dateNoTz() {
    return (date: string, format: string): string => {
        const dateMoment = moment(date);

        if (!dateMoment.isValid()) {
            return "Неверная дата";
        }
        if (!format) {
            format = "DD.MM.YYYY HH:mm";
        }
        return moment(dateMoment).parseZone().format(format);
    };
}

function sum() {
    return (items: any, propertyName: string, showString: string): number | string => {
        let total = 0;
        if (items) {
            angular.forEach(items, (property) => {
                if (property[propertyName]) {
                    total += parseFloat(property[propertyName]);
                }
            });
        }

        return total !== 0 ? total : showString ? showString : "";
    };
}

/**
 * Фильтр поиска первого элемента массива,
 * имеющего поле propertyName со значением value
 * @param {Array} items коллекция в которой ищем
 * @param {String} propertyName поле по которому ищем
 * @param {Any} value значение для сравнения
 * @return {Object} Найденный элемент
 */
function firstOrDefault() {
    return (items: any, propertyName: string, value: any) => {
        return items.find((item) => {
            return item[propertyName] === value;
        });
    };
}

/**
 * @description Фильтр-обёртка для String.prototype.replace(). Важным моментом является использование флага "g"
 * @param {String|Object} text Исходная строка для операции replace
 * @param {String} sub Строковое значение для поиска (которое необходимо заменить в исходной строке),
 * которое затем оборачивается в new RegExp()
 * @param {String} newSub Строковое значение, которм будут заменены все вхождения sub в исходной строке
 * @returns {String|Object}
 */
function replace() {
    return (text: any, sub: string, newSub: string) => {
        if (typeof text === "string") {
            return text.replace(new RegExp(sub, "g"), newSub);
        }
        return text;
    };
}
