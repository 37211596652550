import angular from "angular";
import { ExcelReaderService } from "infrastructure/services/excelReader.service";
import { NotifyService } from "infrastructure/services/notifyService";

export interface IExcelUploadData {
    [key: string]: string;
}
export class InsuredPersonUploadComponentController {
    public static get $inject() {
        return ["$uibModalInstance", "notifyService", "excelReaderService"];
    }

    constructor(
        private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
        private notifyService: NotifyService,
        private excelReaderService: ExcelReaderService,
    ) {
        this.fileChanged = this.fileChanged.bind(this);
    }

    public isValidBrowser(): boolean {
        return this.excelReaderService.isValidBrowser();
    }

    public close(data?: IExcelUploadData[]): void {
        if (data) {
            this.$uibModalInstance.close({
                $data: data,
            });
        } else {
            this.$uibModalInstance.dismiss();
        }
    }

    public isValidFile(item: IExcelUploadData) {
        const requiredFields = ["Фамилия", "Имя", "Отчество"];
        for (const field of requiredFields) {
            if (item && !item.hasOwnProperty(field)) {
                return false;
            }
        }
        return true;
    }

    public fileChanged(file: File) {
        const toasterTitle = "Импорт списка застрахованных";
        this.excelReaderService.readFile(file).then((data: IExcelUploadData[]) => {
            if (data) {
                if (angular.isArray(data) && data.length === 0) {
                    this.notifyService.warningMessage(toasterTitle, "Данных не найдено");
                    this.close();
                    return;
                }

                if (!angular.isArray(data) || !this.isValidFile(data[0])) {
                    this.notifyService.errorMessage(toasterTitle, "Неверная структура файла");
                    this.close();
                    return;
                }

                this.close(data);
            }
        }).catch(() => {
            this.notifyService.errorMessage(toasterTitle, "Ошибка чтения Excel-файла");
            this.close();
        });
    }
}
