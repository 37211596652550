import { Transition } from "@uirouter/core";
import { BoxAccidentToolbarController } from "../boxAccident-toolbar.controller";

export class BoxAccidentJournalToolbarController extends BoxAccidentToolbarController {
    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
    ) {
        super($injector, $transition, {
            viewType: "journal",
        });
    }
}
