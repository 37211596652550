import { StateParams, StateService, Transition } from "@uirouter/core";
import angular from "angular";
import { Contract } from "domain/classes/contract.class";
import { Product } from "domain/classes/product.class";
import UgskPhone from "domain/classes/ugsk-phone.class";
import { UgskSet } from "domain/classes/ugsk-set.class";
import { capitalizeFirstLetter, Helpers } from "infrastructure/app.helpers";
import IBaseViewParams from "infrastructure/interfaces/IBaseViewParams";
import IContractStatus from "infrastructure/interfaces/IContractStatus";
import ICrossMenuOption from "infrastructure/interfaces/ICrossMenuOption";
import { ExtraMenuOption, IExtraOptionPrototype } from "infrastructure/interfaces/IExtraMenuOption";
import { IGetPrintableResourceParams } from "infrastructure/interfaces/IGetPrintableParams";
import IPageSharedData from "infrastructure/interfaces/IPageSharedData";
import IPrintMenuOption from "infrastructure/interfaces/IPrintMenuOption";
import IPrintOptionsPreset from "infrastructure/interfaces/IPrintOptionsPreset";
import IScope from "infrastructure/interfaces/IScope";
import ISignParams from "infrastructure/interfaces/ISignParams";
import { Logger } from "infrastructure/logToServer";
import {
    ImageRepositoryService,
} from "infrastructure/modules/imageRepository/imageRepository.service";
import { NgController } from "infrastructure/NgController";
import { AbstractEmployee, Employee } from "infrastructure/services/employee.service";
import { EnvService } from "infrastructure/services/env.service";
import { NotifyService } from "infrastructure/services/notifyService";
import { ProductsService } from "infrastructure/services/products.service";
import { ValidationService } from "infrastructure/services/validation.service";
import { PAYMENT_KINDS, SALE_CHANNELS } from "./constants";

import annul from "infrastructure/extraMenuOptionsPresets/annul";
import changeOwner from "infrastructure/extraMenuOptionsPresets/changeOwner";
import findInJournal from "infrastructure/extraMenuOptionsPresets/findInJournal";
import integrationInfo from "infrastructure/extraMenuOptionsPresets/integrationInfo";
import invalidateFactPayments from "infrastructure/extraMenuOptionsPresets/invalidateFactPayments";
import manual from "infrastructure/extraMenuOptionsPresets/manual";
import openInsuranceRules from "infrastructure/extraMenuOptionsPresets/openInsuranceRules";
import registerPayments from "infrastructure/extraMenuOptionsPresets/registerPayments";
import returnForRevision from "infrastructure/extraMenuOptionsPresets/returnForRevision";
import sendInsuranceRules from "infrastructure/extraMenuOptionsPresets/sendInsuranceRules";
import unlockContract from "infrastructure/extraMenuOptionsPresets/unlockContract";
import { IAbstractContract } from "infrastructure/interfaces/IAbstractContract";
import { CapabilitiesService } from "infrastructure/services/capabilities.service";
import rawEditorExtraOption from "./rawEditorExtraOption";
import { IUGSKLocalStorage } from "infrastructure/interfaces";

class BaseToolbarController extends NgController {
    public showNewBtn: boolean = false;
    public showOpenBtn: boolean = false;
    public showSaveBtn: boolean = false;
    public showOsagoSaveBtn: boolean = false;
    public showOsagoSignBtn: boolean = false;
    public showSignBtn: boolean = false;
    public messageChatbotEnable: string;
    public chatbotIsEnabled: boolean;
    public printOptions: IPrintMenuOption[];
    public crossOptions: ICrossMenuOption[];
    public extraOptions: ExtraMenuOption[];
    public extraSignOptions: IExtraOptionPrototype[];
    public product: Product;
    public employee: Employee;
    public originalEmployee: AbstractEmployee;
    public $state: StateService;
    public capabilitiesService: CapabilitiesService;

    protected notifyService: NotifyService;
    protected viewType: string;
    protected pageSharedData: IPageSharedData;
    protected $stateParams: StateParams;
    protected $logToServer: Logger;
    protected productsService: ProductsService;
    protected $uibModal: ng.ui.bootstrap.IModalService;
    protected helpers: Helpers;
    protected imageRepositoryService: ImageRepositoryService;

    protected contractStatuses: IContractStatus[];
    protected selection: UgskSet<Contract>;
    protected formContract: Contract;
    protected isImageRepositoryButtonVisible: boolean;
    protected envService: EnvService;

    private isProductLocked = false;

    constructor(
        $injector: angular.auto.IInjectorService,
        $transition: Transition,
        {
            printOptions = [],
            crossOptions = [],
            extraOptions = [],
            extraSignOptions = [],
            viewType = "form",
        }: IBaseViewParams,
        $scope = {} as IScope,
    ) {
        super($injector, $transition, undefined, $scope);
        [
            this.$state,
            this.$stateParams,
            this.$uibModal,
            this.notifyService,
            this.$logToServer,
            this.productsService,
            this.helpers,
            this.envService,
        ] = this.di([
            "$state",
            "$stateParams",
            "$uibModal",
            "notifyService",
            "$logToServer",
            "productsService",
            "helpers",
            "envService",
        ]);

        [
            this.employee,
            this.contractStatuses,
            this.selection,
            this.product,
            this.pageSharedData,
            this.imageRepositoryService,
            this.capabilitiesService,
        ] = this.resolve([
            "employee",
            "contractStatuses",
            "selection",
            "currentProduct",
            "pageSharedData",
            "imageRepositoryService",
            "capabilitiesService",
        ]);

        if (viewType === "form") {
            this.formContract = this.resolve<Contract>("Contract");
        }

        this.isProductLocked = this.resolve<boolean>("isProductLocked");

        this.viewType = viewType;
        this.isImageRepositoryButtonVisible = false;
        if (this.product.hasImageRepositoryAbility && viewType === "form") {
            this.imageRepositoryService.canUploadFilesToImageRepository().then((isButtonVisible) => {
                this.isImageRepositoryButtonVisible = isButtonVisible;
            });
        }

        let showOsagoButton = false;
        if (this.product.code === "osago") {
            let asyncOsago = this.envService.read("asyncOsago");
            if (asyncOsago === undefined) {
                asyncOsago = true;
            }
            showOsagoButton = asyncOsago;
        }

        this.showNewBtn = viewType === "journal" && !this.isProductLocked;
        this.showOpenBtn = viewType === "journal";
        this.showSaveBtn = viewType === "form"  && !showOsagoButton;
        this.showOsagoSaveBtn = viewType === "form" && showOsagoButton;
        this.showSignBtn = viewType === "form"  && !showOsagoButton;
        this.showOsagoSignBtn = viewType === "form" && showOsagoButton;

        this.printOptions = this.getPrintOptions(printOptions);
        this.crossOptions = this.getCrossOptions(crossOptions);
        const allProductsExtraOptions = [
            "findInJournal",
            "registerPayments",
            invalidateFactPayments,
            openInsuranceRules,
            sendInsuranceRules,
        ];
        const debug = this.envService.read("debug");

        this.extraSignOptions = this.getExtraOptions(extraSignOptions);
        this.extraOptions = this.getExtraOptions([
            ...allProductsExtraOptions,
            ...extraOptions,
            ...(debug ? [rawEditorExtraOption] : []),
        ]).filter((item) => !item.view || item.view === this.viewType);

        this.chatbotIsEnabled = this.resolve<boolean>("chatbotIsEnabled");
    }

    public getViewType() {
        return this.viewType;
    }

    public getPolicyPrintFormName(printFormName = "Печать полиса") {
        return `${printFormName} с А7`;
    }
    /* Печать и сохранение бланков и документов */
    public openPdf(params: IGetPrintableResourceParams): angular.IPromise<any> {
        const helpers = this.di<Helpers>("helpers");
        this.notifyService.progressStart("Подготовка печатной формы");
        return this.Contract.$loadPrintable({
            ...params,
            contentType: "application/pdf",
        }).then((blob) => {
            this.notifyService.progressStop();
            const $q = this.di<angular.IQService>("$q");
            return $q((resolve) => {
                return helpers
                    .openUrlInIframe(blob.url)
                    .then(resolve, resolve);
            });
        }).finally(() => {
            this.notifyService.progressStop();
        });
    }
    public downloadRtf(params: IGetPrintableResourceParams) {
        const helpers = this.di<Helpers>("helpers");
        this.notifyService.progressStart("Подготовка печатной формы");
        return this.Contract.$loadPrintable({
            ...params,
            contentType: "text/rtf",
        }).then((blob) => helpers.downloadBlob(blob)).finally(() => {
            this.notifyService.progressStop();
        });
    }
    public isDisabledPrint() {
        const vm = this;
        if (this.viewType === "form") {
            return vm.Contract.isBlank() || (vm.Contract.isDraft() && Boolean(vm.pageSharedData.Form && vm.pageSharedData.Form.modified));
        } else if (this.viewType === "journal") {
            return !this.Contract;
        }
        return true;
    }
    public isEnabledPrintInvoice() {
        const contract = this.Contract;
        if (contract.InsuredContractorType === "юридическое лицо") {
            return true;
        }
        const LegalEntityInsuredContractorTypeId = 2;
        if (contract.InsuredContractorTypeId === LegalEntityInsuredContractorTypeId) {
            return true;
        }
        const InvoicePaymentKindId = 5;
        if (contract.getFirstFactPaymentKindId() === InvoicePaymentKindId) {
            return true;
        }
        return false;
    }
    /* Печать типовых печатных форм */
    public printContract() {
        return this.openPdf({ id: this.Contract.Id }).then(
            () => {},
            () => {
                this.showNoticeNeedPrintReceiptForm();
            },
        );
    }
    public printPolicyWithoutA7() {
        return this.openPdf({ docType: "PolicyWithoutA7", id: this.Contract.Id });
    }
    public printEmptyContract() {
        return this.openPdf({ id: 0 });
    }
    public printApplication() {
        return this.openPdf({
            docType: "Application",
            fileNamePrefix: "Заявление",
            id: this.Contract.Id,
        });
    }
    public printEmptyApplication() {
        return this.openPdf({
            docType: "Application",
            fileNamePrefix: "Заявление",
            id: 0,
        });
    }
    public printWorkSheet() {
        return this.downloadRtf({
            docType: `Contractors${capitalizeFirstLetter(this.product.code)}WorkSheet`,
            fileNamePrefix: "Анкета",
            id: this.Contract.Id,
        });
    }
    public printPaymentAccountAll() {
        return this.openPdf({
            docType: "PaymentAccount",
            fileNamePrefix: "Счет на оплату полный взнос",
            id: this.Contract.Id,
        });
    }
    public printPaymentAccountFirst() {
        return this.openPdf({
            docType: "PaymentAccount; k3.parameter.PaymentNumber=1",
            fileNamePrefix: "Счет на оплату первый взнос",
            id: this.Contract.Id,
        });
    }

    public printPaymentAccountSecond() {
        return this.openPdf({
            docType: "PaymentAccount; k3.parameter.PaymentNumber=2",
            fileNamePrefix: "Счет на оплату второй взнос",
            id: this.Contract.Id,
        });
    }
    public printPaymentAccountThird() {
        return this.openPdf({
            docType: "PaymentAccount; k3.parameter.PaymentNumber=3",
            fileNamePrefix: "Счет на оплату третий взнос",
            id: this.Contract.Id,
        });
    }
    public printPaymentAccountFourth() {
        return this.openPdf({
            docType: "PaymentAccount; k3.parameter.PaymentNumber=4",
            fileNamePrefix: "Счет на оплату четвертый взнос",
            id: this.Contract.Id,
        });
    }
    public async printFreeFormReceipt() {
        const apiUrl = this.envService.read("apiUrl");
        const employee = this.resolve<Employee>("employee");
        if (employee.info.SaleChannel === SALE_CHANNELS.PARTNER) {
            const notifyService = this.di<NotifyService>("notifyService");
            await notifyService.warningMessage("Внимание", "Внимание! В соответствии с законодательством РФ агенты-юридические лица и агенты-индивидуальные предприниматели при расчетах со страхователями должны применять свою контрольно-кассовую технику.", {
                provider: "popup",
            });
        }
        return this.openPdf({
            docType: "FreeFormReceipt",
            fileNamePrefix: "Печать квитанции предзаполненная",
            headers: {
                "X-ProductTitle": this.pageSharedData.currentProduct,
            },
            id: this.Contract.Id,
            url: (id) => `${apiUrl}FreeFormReceipt/getfilledform?id=${id}`,
        });
    }
    /**
     * Расчет и сохранение договора
     */

    public saveContract({
        validationToBeClear = true,
    } = {}) {
        this.notifyService.removeToasts();

        const [
            $state,
            $logToServer,
            vm,
        ] = [
            this.$state,
            this.$logToServer,
            this,
        ];
        const validationService = this.di<ValidationService>("validationService");
        const $q = this.di<angular.IQService>("$q");
        if (validationToBeClear) {
            validationService.clear();
        }
        const ret = $q((resolve, reject) => {
            const validationErrors = validationService.validateObject(this.Contract);

            /**
             * Force Ignore payment validations
             * @description У черновика договора не должно быть фактического платежа,
             * но он, увы, есть. Ошибки его валидации приходится игнорировать
             * @todo Когда у черновика пропадет фактический платеж - удалить этот костыль
             */
            const ErrorsIgnoreList = [
                "Number",
                "Amount",
            ];

            const filteredValidationErrors = validationErrors
                .filter((error) => !ErrorsIgnoreList
                        .some((ignoredCode) => error.code.startsWith(ignoredCode)));

            if (filteredValidationErrors.length) {
                validationService.addObjectErrors(filteredValidationErrors);
                this.notifyService.errorMessage("Скорректируйте введенные данные", validationService.getAllMessages());
                return reject();
            }
            return resolve();
        }).then(() => {
            this.notifyService.progressStart("Сохранение договора");
            return vm.Contract.$save();
        }).then(() => {
            this.pageSharedData.Form.$setPristine();
            this.notifyService.successMessage("", "Договор успешно сохранен");
            $logToServer.info({ Action: "Расчитать", Product: vm.pageSharedData.currentProduct, Status: 200 });
            if (!vm.$stateParams.id || vm.$stateParams.cross) {
                $state.go($state.current.name, {
                    contract: this.Contract,
                    id: this.Contract.Id,
                }, { inherit: false });
            }
        }).finally(() => {
            this.notifyService.progressStop();
        });
        ret.catch((e) => {
            let errorMessage;
            if (angular.isObject(e)) {
                errorMessage = e.status;
            } else {
                errorMessage = 400;
            }
            $logToServer.error({
                Action: "Расчитать",
                Product: vm.pageSharedData.currentProduct,
                Status: errorMessage,
            });
            return $q.reject(e);
        });
        return ret;
    }

    public isDisabledCalculate() {
        return this.Contract.isLocked();
    }

    public isExtraSignOptionsAvailable() {
        return false;
    }

    /**
     * Продажа договора
     */

    public signContract(signParamsObj: ISignParams = {
        notShowToBeVerifiedText: false,
        hasCheckScoringRate: false,
    }) {
        const [
            $logToServer,
            vm,
        ] = [
            this.$logToServer,
            this,
        ];
        const [validationService] = this.di(["validationService"]);
        this.notifyService.progressStart("Оформление договора");
        const ret = this.Contract.$sign();
        ret.then(() => {
            this.pageSharedData.Form.$setPristine();
            validationService.clear();
            this.notifyService.successMessage("", "Договор успешно продан");
            $logToServer.info({ Action: "Продать", Product: vm.pageSharedData.currentProduct, Status: 200 });
        }).catch((e) => {
            $logToServer.error({ Action: "Продать", Product: vm.pageSharedData.currentProduct, Status: e.status });
        }).finally(() => {
            this.notifyService.progressStop();
        });
        return ret;
    }

    public signContractWithPhoneCheck(signParamsObj: ISignParams = {
        notShowToBeVerifiedText: false,
    }) {
        this.notifyService.removeToasts();

        const [
            phoneValidationService,
        ] = this.resolve([
            "phoneValidationService",
        ]);
        const titleText = "Подтверждение продажи";
        let bodyText = "<p>Перевести договор в статус &laquo;Продан&raquo;?" +
            "<br>Проданные договоры нельзя редактировать.</p>" +
            '<p class="text-accent">Оплатить договор необходимо не&nbsp;позднее даты, указанной на&nbsp;вкладке ' +
            "&laquo;Оплата&raquo;, оплата после указанной даты невозможна.</p>";
        let phones: UgskPhone[] = null;
        let needConfirmPhone = false;

        if (!signParamsObj.notShowToBeVerifiedText) {
            phones = this.Contract.getInsurantPhones();
            const firstUnverifiedNumber = phoneValidationService.getFirstUnverifiedPhoneNumber(phones);
            if (firstUnverifiedNumber) {
                bodyText +=
                    `<br />На номер клиента (${firstUnverifiedNumber}) будет выслано СМС с кодом подтверждения.`;
                needConfirmPhone = true;
            }
        }
        return this.helpers.confirm({ text: bodyText, title: titleText }).then(() => this.signContract(signParamsObj)).then(() => {
            const formModified = Boolean(this.pageSharedData.Form.modified);
            if (needConfirmPhone && phones) {
                return phoneValidationService.checkAndConfirmPhones({
                    autoSendCode: true,
                    phones,
                }).then((confirmedPhones: UgskPhone[]) => {
                    this.Contract.setInsurantPhones(confirmedPhones);
                    this.pageSharedData.Form.modified = formModified;
                });
            }
            return true;
        });
    }

    public isDisabledSell() {
        return this.Contract.isBlank() || this.Contract.isFrozen() ||
            (this.Contract.isLocked() && !this.Contract.isApproved()) ||
            Boolean(this.pageSharedData.Form && this.pageSharedData.Form.modified);
    }

    public openImageRepository() {
        if (!this.Contract.PolicyNumber) {
            const [validationService] = this.di(["validationService"]);
            this.notifyService.warningMessage("Предупреждение", "Укажите номер полиса");
            validationService.addError("PolicyNumber", ["Укажите номер полиса"]);
            return;
        }
        const filialService = this.di<any>("filialService");
        const params = {
            ContractGuid: this.Contract.Guid,
            ContractPolicyNumber: this.Contract.PolicyNumber,
            ContractSigningDate: this.Contract.SigningDate,
            ContractTerritoryGuid: this.Contract.FilialGuid,
        };
        if (!this.Contract.FilialGuid) {
            this.notifyService.progressStart();
            filialService.getFilialById({ id: this.Contract.FilialId }).$promise
                .then((data) => {
                    if (data.value.length === 1) {
                        params.ContractTerritoryGuid = data.value[0].Guid;
                        this.imageRepositoryService.openImageRepositoryContainer(params);
                    }
                }).finally(() => {
                    this.notifyService.progressStop();
                });
        } else {
            this.imageRepositoryService.openImageRepositoryContainer(params);
        }
    }

    public getSelectedContractUrl() {
        if (!this.isSelectedRow()) {
            return "";
        }
        return this.$state.href(`app.${this.product.code}.index`, {
            id: this.Contract.Id,
        }, { inherit: false });
    }

    public $onInit() {
        this.originalEmployee = this.resolve("originalEmployee");
        const envService = this.di<EnvService>("envService");
        this.messageChatbotEnable = (() => {
            let msg = "";

            const chatbot = envService.read("chatbot");
            if (chatbot && !chatbot.enabled) {
                return "Поддержка временно отключена";
            }
            if (chatbot.enabled.toString() === "false") {
                msg = "Поддержка временно отключена";
            }
            if (Array.isArray(chatbot.enabled) && !this.resolve(["chatbotIsEnabled"])[0]) {
                chatbot.enabled.find((cfgItem) => {
                    if (cfgItem.hasOwnProperty("Filial") && !cfgItem.Filial.includes(this.employee.info.Filial) ||
                        /* tslint:disable-next-line:max-line-length */
                        cfgItem.hasOwnProperty("SaleChannel") && !cfgItem.SaleChannel.includes(this.employee.info.SaleChannel)) {
                        msg = "Поддержка временно отключена";
                        return true;
                    }
                    if (cfgItem.hasOwnProperty("Products") && !cfgItem.Products.includes(this.product.code)) {
                        msg = "Поддержка доступна только в КАСКО";
                    }
                });
            }
            return msg;
        })();
    }

    public toggleChatbot() {
        if (this.chatbotIsEnabled) {
            if (this.helpers.isEmbedMode()) {
                window.parent.postMessage({
                    type: "chatbot",
                    value: {
                        src: this.envService.read("chatbot").url,
                    },
                }, "*");
            } else {
                (window as any).chatbot.toggle();
            }
        }
    }

    public isChatbotShow() {
        // todo убрать, когда запустят в офисно прямом канале, а это будет через год!
        if (!this.employee.info) {
            return false;
        }
        return !(["Офисно-прямой"] as any).includes(this.employee.info.SaleChannel);
    }
    protected showNoticeNeedPrintReceiptForm(): void {
        if (this.viewType === "form") {
            const permGetFreeFormReceipt = this.employee.hasPermission("GetFreeFormReceipt", this.product.code);
            const permGetPolicyWithoutA7 = this.employee.hasPermission("FrontGetPolicyWithoutA7", this.product.code);
            const insurantANaturalPerson = this.Contract.isInsurantANaturalPerson();
            const validChannel = ([
                SALE_CHANNELS.AGENT, SALE_CHANNELS.OFFICE,
            ] as any).includes(this.employee.info.SaleChannel);
            // вид оплаты = только наличные;
            const validPaymentKind = this.Contract.getFirstFactPaymentKindId() === PAYMENT_KINDS.CASH;
            if (
                permGetFreeFormReceipt
                && !permGetPolicyWithoutA7
                && insurantANaturalPerson
                && validChannel
                && validPaymentKind
            ) {
                this.notifyService.message(undefined, "Не забудьте распечатать Печатную форму Квитанции", {
                    provider: "popup",
                    type: "warning",
                });
            }
        }
    }

    // Journal Specific
    private isSelectedRow() {
        return Boolean(this.Contract);
    }

    // Print options
    private getPrintOptions(printOptions: any[]) {
        const vm = this;
        const canPrintPolicyWithoutA7 = vm.employee.hasPermission("GetFreeFormReceipt", vm.product.code);
        const policyWithA7IsAvailable = !vm.employee.hasPermission("FrontGetPolicyWithoutA7", vm.product.code);
        const presets: IPrintOptionsPreset = {
            application: {
                action: vm.printApplication,
                disabled() {
                    return vm.isDisabledPrint();
                },
                icon: "fa-print",
                title: "Заявление",
            },
            emptyApplication: {
                action: vm.printEmptyApplication,
                icon: "fa-download",
                title: "Пустой бланк заявления",
            },
            emptyPolicy: {
                action: vm.printEmptyContract,
                icon: "fa-print",
                title: "Печать пустого бланка",
            },
            freeFormReceipt: {
                action: () => vm.printFreeFormReceipt().then((result) => result, angular.noop),
                disabled() {
                    if (!(vm.Contract && vm.employee && vm.employee.info)) {
                        return true;
                    }
                    const canGetFreeFormReceipt = vm.employee.hasPermission("GetFreeFormReceipt", vm.product.code);
                    const contractIsAllowable = !(this.Contract.isAnnuled() || this.Contract.isTerminated() || this.Contract.isFrozen());
                    const paymentKindsAllowable = vm.Contract.getFirstFactPaymentKindId() === PAYMENT_KINDS.CASH;
                    const insurantANaturalPerson = (vm.Contract.isInsurantANaturalPerson() || vm.Contract.InsuredContractorType === "физическое лицо");

                    return vm.Contract.isBlank() ||
                            !(contractIsAllowable
                                && paymentKindsAllowable
                                && insurantANaturalPerson
                                && canGetFreeFormReceipt);
                },
                icon: "fa-print",
                isVisible: () => {
                    return !["osago", "uAuto"].includes(vm.product.code);
                },
                title: "Печать квитанции",
            },
            paymentAccountAll: {
                action: vm.printPaymentAccountAll,
                disabled() {
                    return vm.isDisabledPrint()
                        || vm.Contract.getPlannedPaymentsCount() > 1
                        || !vm.isEnabledPrintInvoice();
                },
                icon: "fa-print",
                title: "Счет на оплату (полный взнос)",
            },
            paymentAccountFirst: {
                action: vm.printPaymentAccountFirst,
                disabled() {
                    return vm.isDisabledPrint()
                        || vm.Contract.getPlannedPaymentsCount() === 1
                        || !vm.isEnabledPrintInvoice();
                },
                icon: "fa-print",
                title: "Счет на оплату (первый взнос)",
            },
            paymentAccountFourth: {
                action: vm.printPaymentAccountFourth,
                disabled() {
                    return vm.isDisabledPrint()
                        || vm.Contract.getPlannedPaymentsCount() < 4
                        || !vm.isEnabledPrintInvoice();
                },
                icon: "fa-print",
                title: "Счет на оплату (четвертый взнос)",
            },
            paymentAccountSecond: {
                action: vm.printPaymentAccountSecond,
                disabled() {
                    return vm.isDisabledPrint()
                        || vm.Contract.getPlannedPaymentsCount() < 2
                        || !vm.isEnabledPrintInvoice();
                },
                icon: "fa-print",
                title: "Счет на оплату (второй взнос)",
            },
            paymentAccountThird: {
                action: vm.printPaymentAccountThird,
                disabled() {
                    return vm.isDisabledPrint()
                        || vm.Contract.getPlannedPaymentsCount() < 3
                        || !vm.isEnabledPrintInvoice();
                },
                icon: "fa-print",
                title: "Счет на оплату (третий взнос)",
            },
            policy: {
                action: vm.printContract,
                disabled() {
                    return vm.isDisabledPrint();
                },
                icon: "fa-print",
                title: vm.getPolicyPrintFormName(),
                isVisible() {
                    return policyWithA7IsAvailable;
                },
            },
            policyWithoutA7: {
                action: vm.printPolicyWithoutA7,
                disabled() {
                    return vm.isDisabledPrint() || !canPrintPolicyWithoutA7;
                },
                icon: "fa-print",
                title: "Печать полиса без А7",
            },
            worksheet: {
                action: vm.printWorkSheet,
                disabled() {
                    return vm.isDisabledPrint();
                },
                icon: "fa-print",
                title: "Анкета контрагента",
            },
        };
        return printOptions.map((option) => {
            let ret = option;
            if (angular.isString(option)) {
                const preset = presets[option];
                if (angular.isObject(preset)) {
                    ret = preset;
                }
            }
            const isDisabled = ret.disabled || (() => false);
            const isVisible = ret.isVisible || (() => true);
            if (angular.isFunction(ret.title)) {
                ret.title = ret.title.call(vm);
            }
            ret.isDisabled = () => isDisabled.call(vm);
            ret.isVisible = () => isVisible.call(vm);
            ret.handler = () => {
                if (!ret.isDisabled()) {
                    ret.action.call(vm);
                }
            };
            return ret;
        });
    }
    public hasVisibleCrossOptions(): boolean {
        if (!this.crossOptions) {
            return false;
        }

        return this.crossOptions.some(item => item.hidden === false);
    }
    /* Cross Options */
    private getCrossOptions(crossProductCodes: string[]) {
        const [
            $state,
            productsService,
            vm,
        ] = [
            this.$state,
            this.productsService,
            this,
        ];
        /**
         * @description блокирует кросс функцию, если признак insurerCanBeLegalEntity:false
         * @param {string} crossProductCode
         * @returns {Boolean}
         */
        function isCrossingForLegalEntityBlocked(crossProductCode) {
            const product = productsService.getByCode(crossProductCode);
            if (product) { return !product.insurerCanBeLegalEntity; }
            return false;
        }

        const options = crossProductCodes.map((crossProductCode) => {
            const crossProduct = productsService.getByCode(crossProductCode);
            return {
                isDisabled() {
                    if (!vm.Contract || !vm.Contract.Id) {
                        return true;
                    }
                    if (vm.pageSharedData.Form && vm.pageSharedData.Form.modified) {
                        return true;
                    }
                    if (vm.Contract.InsuredContractorType === "юридическое лицо") {
                        return isCrossingForLegalEntityBlocked(crossProductCode);
                    }
                    return !vm.Contract.Id;
                },
                name: `crossTo${capitalizeFirstLetter(crossProduct.code)}`,
                title: `Кроссировать в ${crossProduct.productName}`,
                url() {
                    if (this.isDisabled()) {
                        return "";
                    }
                    const { code } = vm.product;
                    return $state.href(`app.${crossProductCode}.index`, {
                        cross: code,
                        id: vm.Contract.Id,
                    });
                },
                hidden: this.helpers.isProductDisabled(crossProduct.code),
            };
        });
        if (this.helpers.isEmbedMode()) {
            const $localStorage = this.di<IUGSKLocalStorage>("$localStorage");
            const kiasCrossOptions = ( $localStorage.KiasCrossProducts || [] ).map((crossKiasProduct) => {
                return {
                    isDisabled() {
                        if (!vm.Contract || !vm.Contract.Id || (vm.Contract.InsuredContractorType === "юридическое лицо")) {
                            return true;
                        }
                        if (vm.pageSharedData.Form && vm.pageSharedData.Form.modified) {
                            return true;
                        }
                        return !vm.Contract.Id;
                    },
                    name: `crossTo${crossKiasProduct.code}`,
                    title: `Кроссировать в ${crossKiasProduct.name} (КИАС)`,
                    url() {
                        return "";
                    },
                    onclick() {
                        if (this.isDisabled()) {
                            return;
                        }
                        window.parent.postMessage({
                            type: "CROSS",
                            value: { crossKiasProduct, product: vm.product.code, contractId: vm.Contract.Id },
                        }, "*");
                    },
                    hidden: this.helpers.isProductDisabled(crossKiasProduct.code),
                };
            });
            const npCrossProducts = ( $localStorage.NPCrossProducts || [] ).map((crossNpProduct) => {
                return {
                    isDisabled() {
                        if (!vm.Contract || !vm.Contract.Id) {
                            return true;
                        }
                        if (vm.pageSharedData.Form && vm.pageSharedData.Form.modified) {
                            return true;
                        }
                        return !vm.Contract.Id;
                    },
                    name: `crossTo${crossNpProduct.code}`,
                    title: `Кроссировать в НП ${crossNpProduct.name}`,
                    url() {
                        return "";
                    },
                    onclick() {
                        if (this.isDisabled()) {
                            return;
                        }
                        window.parent.postMessage({
                            type: "CROSS",
                            value: { crossNpProduct, product: vm.product.code, contractId: vm.Contract.Id },
                        }, "*");
                    },
                    hidden: this.helpers.isProductDisabled(crossNpProduct.code),
                };
            });
            return [ ...options, ...npCrossProducts, ...kiasCrossOptions ];
        }
        return options;
    }
    // Extra options
    private getExtraOptions(extraOptions: Array<IExtraOptionPrototype | string>): ExtraMenuOption[] {
        const vm = this;

        const presets: {
            [key: string]: IExtraOptionPrototype,
        } = {
            annul,
            changeOwner,
            findInJournal,
            integrationInfo,
            manual,
            openInsuranceRules,
            registerPayments,
            returnForRevision,
            sendInsuranceRules,
            unlockContract,
        };
        return extraOptions.map((protoOrKey) => {
            const menuOptionPrototype = angular.isString(protoOrKey) ? presets[protoOrKey] : protoOrKey;
            return new ExtraMenuOption(menuOptionPrototype, vm);

        }).filter((item) => item);
    }

    get Contract(): IAbstractContract {
        if (this.viewType === "form") {
            return this.formContract;
        } else if (this.viewType === "journal") {
            return this.selection.first();
        }
        throw new Error();
    }
    get ContractStatusId() {
        if (this.viewType === "form") {
            return this.Contract.ContractStatusId;
        } else if (this.viewType === "journal") {
            return this.Contract.StatusId;
        }
        throw new Error();
    }
}

export {
    NgController,
    BaseToolbarController,
};
