import { NgComponentController } from "infrastructure/NgController";
import { Payment } from "domain/classes/payment.class";

import template from "./ugskPaymentsRegistryPage.component.html";

export const ugskPaymentsRegistryPageComponent = {
    bindings: {
        registryId: "<?",
        registry: "=?ngModel",
        employeeFilterEnabled: "<"
    },
    template,
    controllerAs: "vm",
    controller: class extends NgComponentController {
        onInit() {
            const [
                paymentRegistriesResource,
                factPaymentsService,
                $q
            ] = [
                this.paymentRegistriesResource
            ] = this.di([
                "paymentRegistriesResource",
                "factPaymentsService",
                "$q"
            ]);
            this.registry = {};
            if (this.registryId) {
                return $q.all([
                    paymentRegistriesResource.odata().get(this.registryId).$promise,
                    factPaymentsService.odata().filter("PaymentRegistryId", this.registryId).query().$promise
                ]).then(([registry, factPayments]) => {
                    this.registry = registry;
                    registry.FactPayments = factPayments.map(payment => Payment.fromObject(payment));
                });
            }
            this.registry = new paymentRegistriesResource();
            return $q.resolve();
        }
        get isNew() {
            return Boolean(!this.registryId);
        }
    }
};
