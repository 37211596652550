import angular from "angular";
import "angular-flot";
import ugskProgressBarModule from "application/components/ugsk-progress-bar/ugskProgressBar.component";
import { IFilialResource } from "infrastructure/app.resource";
import { IEmployeeResource, IStateProvider } from "infrastructure/interfaces";
import "jquery-flot";
import "lib/angular-peity";
import "node_modules/jquery-flot/jquery.flot.crosshair";
import "node_modules/jquery-flot/jquery.flot.time";
import "node_modules/peity/jquery.peity";
import ugskSaleLimitsTable from "./components/ugsk-sale-limits-table/ugskSaleLimitsTable.component";
import { DashboardBodyController } from "./dashboard.controller";
import "./dashboard.factory";
import "./dashboard.service";

angular
    .module("app.dashboard", [
        "angular-peity",
        "angular-flot",
        "app.dashboard.service",
        "app.dashboard.factory",
        ugskProgressBarModule,
        ugskSaleLimitsTable,
    ])
    .config(config);

function config($stateProvider: IStateProvider) {
    "ngInject";

    /* tslint:disable:object-literal-sort-keys */
    $stateProvider
        .state("app.dashboard", {
            abstract: true,
            url: "/Dashboard",
            title: "Личный кабинет",
            resolve: {},
        })
        .state("app.dashboard.index", {
            url: "/",
            data: { pageTitle: "Личный кабинет" },
            views: {
                "body@": {
                    template: require("./body.html"),
                    controller: DashboardBodyController,
                    controllerAs: "vm",
                },
            },
            resolve: {
                employeeAllowedCases: (employeeResource: IEmployeeResource, $q: angular.IQService) => {
                    "ngInject";

                    return $q.all({
                        GetLimit: employeeResource
                            .hasAllowedCase({ allowedCase: "OsagoSaleLimit.GetLimit" })
                            .$promise.then((data) => data.hasAllowedCase),
                        SetLimit: employeeResource
                            .hasAllowedCase({ allowedCase: "OsagoSaleLimit.SetLimit" })
                            .$promise.then((data) => data.hasAllowedCase),
                    });
                },
                filials: (filialService: IFilialResource) => {
                    "ngInject";

                    return filialService.get().$promise.then((data) => data.value);
                },
            },
        });
    /* tslint:enable:object-literal-sort-keys */
}
