interface ICarouselSlideNgModelController extends ng.INgModelController {
    select(nextSlide: object, direction: string): void;
}
export function CarouselSlideDirective($parse: ng.IParseService): ng.IDirective {
    "ngInject";

    return {
        link: (
            scope: ng.IScope,
            element: ng.IAugmentedJQuery,
            attributes: IAttributes,
            controller: ICarouselSlideNgModelController,
        ) => {
            const callBack = $parse(attributes.onCarouselSlide);
            const origSelect = controller.select;
            controller.select = function(nextSlide) {
                callBack(scope, { nextSlide });
                return origSelect.apply(this, arguments);
            };
        },
        require: "^uibCarousel",
        restrict: "A",
    };
}
