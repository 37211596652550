import angular from "angular";
import ugskInsurantModule from "application/components/ugsk-insurant/ugskInsurant.component";
import { UAutoRepository } from "application/uAuto/uAuto.repository";
import { NgComponentController } from "infrastructure/NgController";
import { Uuid } from "lib/uuid";
import { UautoBeneficiaryProxy, UAutoContract, UautoInsurantProxy } from "../../uAuto.factory";

/**
 * ugskUautoInsurant - страхователь в "КАСКО"
 */

class UAutoInsurant extends NgComponentController {
    public contract: UAutoContract;
    public repository: UAutoRepository;
    public insurant: UautoInsurantProxy;
    public beneficiary: UautoBeneficiaryProxy;
    public onInsurantTypeChanged: () => void;

    public onInit(): void {
        this.repository = this.contract.getRepository();
        this.insurant = new UautoInsurantProxy(this.contract);
        this.beneficiary = new UautoBeneficiaryProxy(this.contract);
        this.$watch(() => this.insurant.contractorType, (newVal, oldVal) => {
            if (this.contract.isLocked()) {
                return;
            }
            if (newVal !== oldVal) {
                this.onInsurantTypeChanged();
            }

            if (!this.isPerson()) {
                this.insurant.firstName = undefined;
                this.insurant.lastName = undefined;
                this.insurant.middleName = undefined;
                this.insurant.docWhomGiven = undefined;
                this.insurant.birthday = null;
                this.insurant.birthPlace = undefined;
            } else {
                this.insurant.orgName = undefined;
                this.insurant.orgInn = undefined;
                this.insurant.orgKpp = undefined;
                this.insurant.isNotResident = undefined;
            }
        });

        this.$watch(() => this.insurant.toString(), () => {
            if (this.contract.isLocked()) {
                return;
            }
            if (this.insurerIsBenificiary) {
                this.copyInsurantToBeneficiary();
            }
        });
    }

    public isPerson(): boolean {
        return (this.insurant.isIndividual() || this.insurant.isSoleProprietor());
    }

    public copyInsurantToBeneficiary(): void {
        this.beneficiary.copyFrom(this.insurant);
    }

    get insurerIsBenificiary() {
        return this.beneficiary.guid === this.insurant.guid;
    }
    set insurerIsBenificiary(val) {
        if (val) {
            this.copyInsurantToBeneficiary();
        } else {
            this.beneficiary.guid = Uuid.raw();
        }
    }
}
export default angular.module("app.uauto.components.insurant", [
    ugskInsurantModule,
]).component("ugskUautoInsurant", {
    bindings: {
        contract: "<",
        onInsurantTypeChanged: "&",
        phoneValidationService: "<",
    },
    controller: UAutoInsurant,
    controllerAs: "vm",
    template: require("./ugskUautoInsurant.component.html"),
}).name;
