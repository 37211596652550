interface IScope extends ng.IScope {
    docType: string;
    onValidationLicensePlate: () => void;
}

export function LicensePlateValidation($timeout: ng.ITimeoutService) {
    "ngInject";
    return {
        restrict: "A",
        scope: {
            docType: "<licensePlateDocType",
            onValidationLicensePlate: "&onValidationLicensePlate",
        },
        link(scope: IScope, element: ng.IAugmentedJQuery, attr: ng.IAttributes, ctrl: ng.IController) {
            scope.$watch("docType", () => {
                scope.onValidationLicensePlate();
            });
            element.on({
                focusout() {
                    $timeout(scope.onValidationLicensePlate);
                },
            });
        },
    };
}
