import angular from "angular";
import { IToastInstance } from "infrastructure/interfaces/index.js";
import { INotificationParams, notificationType } from "infrastructure/interfaces/INotificationParams.js";
import INotificationProvider from "infrastructure/interfaces/INotificationProvider";
import INotificationToasterProvider from "infrastructure/interfaces/INotificationToasterProvider.js";
import IBlockUI from "../interfaces/IBlockUI.js";
import { NotifyDesktopProvider } from "./notifyDesktopProvider";
import { NotifyModalProvider } from "./notifyModalProvider";
import { NotifyStaticProvider  } from "./notifyStaticProvider";

export class NotifyService {
    private notifyProviders: {
        [key: string]: INotificationProvider,
    };
    constructor(
        private toaster: INotificationToasterProvider,
        private blockUI: IBlockUI,
        private notifyStaticProvider: NotifyStaticProvider,
        private notifyDesktopProvider: NotifyDesktopProvider,
        private notifyModalProvider: NotifyModalProvider,
    ) {
        "ngInject";

        this.notifyProviders = {
            desktop: this.notifyDesktopProvider,
            popup: this.notifyModalProvider,
            static: this.notifyStaticProvider,
            toaster: this.toaster,
        };
    }

    public message(titleParam: string, bodyParam?: string, params?: INotificationParams) {
        return this.messangerFactory()(titleParam, bodyParam, params);
    }

    public successMessage(titleParam: string, bodyParam?: string, params?: INotificationParams) {
        return this.messangerFactory("success")(titleParam, bodyParam, params);
    }

    public warningMessage(titleParam: string, bodyParam?: string, params?: INotificationParams) {
        return this.messangerFactory("warning", "Предупреждение")(titleParam, bodyParam, params);
    }

    public errorMessage(titleParam: string, bodyParam?: string, params?: INotificationParams) {
        return this.messangerFactory(
            "error",
            "Ошибка",
            "При выполнении запроса произошла ошибка",
        )(titleParam, bodyParam, params);
    }

    public infoMessage(titleParam: string, bodyParam?: string, params?: INotificationParams) {
        return this.messangerFactory("info")(titleParam, bodyParam, params);
    }

    public removeToasts(instance?: IToastInstance) {
        if (!instance) {
            this.toaster.clear();
        } else {
            this.toaster.clear(instance);
        }
    }

    public progressStart(text: string = "") {
        this.blockUI.start(text);
    }

    public progressMessage(text: string): void {
        if (this.blockUI.isBlocking()) {
            this.blockUI.message(text);
        }
    }

    public progressStop() {
        this.blockUI.stop();
    }
    private getNotifyProvider(type = "toaster") {
        return this.notifyProviders[type];
    }
    private messangerFactory(type: notificationType = "info", defaultTitle?: string, defaultBody?: string) {
        return (titleParam?: string, bodyParam?: string, params: INotificationParams = {
            provider: "toaster",
            type,
        }) => {

            let title: string;
            let body: string;
            if (titleParam && bodyParam) {  //  successMessage("Title", "body");
                title = titleParam;
                body = bodyParam;
            } else if (titleParam) {   //  successMessage("all OK");
                title = defaultTitle;
                body = titleParam;
            } else if (bodyParam) {  //  successMessage(null, "all OK");
                title = defaultTitle;
                body = bodyParam;
            } else { //  errorMessage();
                title = defaultTitle;
                body = defaultBody;
            }
            return this.getNotifyProvider(params.provider).pop({
                ...params,
                body,
                title,
            });
        };
    }
}

import uibModalModules from "angular-ui-bootstrap/src/modal";

export default angular
    .module("ugsk.services.notifications", [
        uibModalModules,
    ])
    .service("notifyStaticProvider", NotifyStaticProvider)
    .service("notifyDesktopProvider", NotifyDesktopProvider)
    .service("notifyModalProvider", NotifyModalProvider)
    .service("notifyService", NotifyService)
    .name;
