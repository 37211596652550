import template from "./ugskCollapsible.component.html";

export default angular.module("ugsk.components.collapsible", []).component("ugskCollapsible", {
    bindings: {
        separator: "<",
        value: "<",
    },
    controller: class {
        $onInit() {
            if (!this.separator) this.separator = ";";
            this.isCollapsed = true;
            this.valuesCount = this.getRecordsCount(this.value, this.separator || ";");
        }
        getRecords() {
            return this.value.split(this.separator)
                .map(item => item.trim())
                .filter(item => item);
        }
        getRecordsCount() {
            return this.getRecords().length;
        }
        getLimit() {
            const names = this.getRecords();
            if (names.length === 1) return names.length;
            if (names.length > 1) return names[0].length;
            return 0;
        }
    },
    controllerAs: "vm",
    template,
}).name;
