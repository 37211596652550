import { StateService, Transition } from "@uirouter/angularjs";
import {
    CONTRACT_STATUSES,
    EOSAGO_STAGES_INFO,
    RELIABLE_DRIVE_PRODUCT_NAME,
    SALE_CHANNELS,
    USAGE_PURPOSES_ENUM,
    USER_ROLES,
    VEHICLE_REGISTRATIONS,
} from "application/constants";
import { Helpers, unbreakWord } from "infrastructure/app.helpers";
import IScope from "infrastructure/interfaces/IScope";
import { CommonErrorsErrorTypeEnum } from "infrastructure/interfaces/WebApi/OsagoAsyncResponseDTO";
import { Employee } from "infrastructure/services/employee.service";
import { EnvService } from "infrastructure/services/env.service";
import { NotifyService } from "infrastructure/services/notifyService";
import { RSAMessages } from "infrastructure/services/rsaMessages";
import { ValidationService } from "infrastructure/services/validation.service";
import { Int } from "infrastructure/types";
import moment from "moment";
import { leaveOnlyNumbers } from "../../infrastructure/app.helpers";
import { BaseAutoBodyController } from "../baseAuto.body.controller";
import { IEOsagoStatus } from "./interfaces/IEOsagoStatus";
import { IOSAGOPageSharedObject } from "./interfaces/IOSAGOPageSharedObject";
import { OsagoContract } from "./osago.factory.js";
import { StaticValidationRules } from "./static.validation.rules";
import angular, { ITimeoutService } from "angular";
import { StatusService } from "infrastructure/services/status.service";
import { ReliableDriveContract } from "application/osago/classes/ReliableDriveContract";
import RelatedProductService from "application/osago/relatedProduct.service";
import { PaymentKindsEnum } from "@ugsk/payments";

interface IValidationLicansePlate {
    nameRule: string;
    docType: string;
    license: string;
}

export class OsagoController extends BaseAutoBodyController {
    public Contract: OsagoContract;
    public vehicleModification = [];
    public stateId: Int;
    public employee: Employee;
    public osagoService: any;
    public validationService: ValidationService;
    public staticValidationRules: StaticValidationRules;
    public EOsagoStatus: IEOsagoStatus;
    public unbreakWord = unbreakWord;
    public pageSharedData: IOSAGOPageSharedObject;
    public reliableDriveContract: ReliableDriveContract = new ReliableDriveContract();
    public isReliableDrivePayed: boolean;
    public visibleAdditionalPhones = false;
    public relatedProduct: string | null = null;
    public hideReliableRide = false;
    private notifyService: NotifyService;
    private rsaMessagesService: RSAMessages;
    private undoContract: any;
    private statusService: StatusService;
    private helpers: Helpers;
    private relatedProductService: RelatedProductService;

    constructor(
        $injector: angular.auto.IInjectorService,
        $transition$: Transition,
        params: object,
        $scope: IScope,
    ) {
        super($injector, $transition$, params, $scope);
        this.stateId = $transition$.params().id;
        this.employee = this.resolve("employee");
        this.staticValidationRules = this.resolve("staticValidationRules");
        this.notifyService = this.di<NotifyService>("notifyService");
        this.statusService = this.di("statusService");
        this.rsaMessagesService = this.di<RSAMessages>("rsaMessagesService");
        const [OsagoService] = this.di(["osagoService"]);
        this.osagoService = OsagoService();
        this.reliableDriveContract = this.resolve("reliableDriveContract");
        this.helpers = this.di("helpers");

        if (this.Contract.isEOSAGO()) {
            this.reloadEOsagoStatus();
        }
    }

    public validationLicensePlate(cfg: IValidationLicansePlate) {
        const errors = this.staticValidationRules[cfg.nameRule](cfg.docType, cfg.license);
        if (errors.length > 0) {
            this.validationService.addError("VehicleLicensePlate", errors);
        }
    }

    public userCanEditRSAResult() {
        /**
         * @todo Второй этап. Имеет смысл доработать бек (FR или Permissions пользователя) для решения данной задачи.
         * @see general\UGSK.K3.Front\Web.config:162 (EditRSAResults)
         * @see general\UGSK.K3.Front\Controllers\OSAGOController.cs:225
         */
        const employeeInfo = this.employee.info;
        return (!employeeInfo) ? false : ["Андеррайтер", "Куратор ГО"].includes(employeeInfo.Role);
    }

    /**
     * Определение "вчерашнего" черновика. Используется в компонентах:
     * - Параметры договора (ugskOsagoContractParams)
     * - Периоды использования (ugskOsagoUsagePeriods)
     * - Оплата (ugskOsagoPayment)
     * @return {Boolean}
     */
    public isDraftOutdated(): boolean {
        const contract = this.Contract;
        if (contract.isDraft()) {
            const signingDate = moment(contract.SigningDate, "YYYY-MM-DD").startOf("day");
            const current = moment().startOf("day");

            return signingDate.isBefore(current);
        }

        return false;
    }

    public isFormLocked(): boolean {
        return this.Contract.isLocked();
    }

    public removeValidationMessage(fieldName) {
        if (this.validationService.hasError(fieldName)) {
            this.validationService.removeError(fieldName);
        }
    }

    public $onInit() {
        super.$onInit();
        this.visibleAdditionalPhones = Boolean(
            this.Contract && this.Contract.Id
            && this.employee && this.employee.hasPermission("GetAdditionalPhones", "Osago")
        );
        this.relatedProductService = this.di("relatedProductService");
        this.validationService = this.di("validationService");

        const impersonateLogin = this.employee.impersonateLogin;
        this.hideReliableRide = [
        ].includes(impersonateLogin);

        if (this.isDraftOutdated()) {
            this.notifyService.warningMessage("Внимание",
                "По данному договору устарела дата заключения договора."
                + " Рассчитайте договор заново для обновления даты заключения договора.");
        }

        if (!this.Contract.EmployeeName) {
            this.Contract.EmployeeName = this.employee.info.Name;
        }

        this._$scope.$watch(() => this.Contract.getHash(), (newVal, oldVal) => {
            if (!this.Contract.HasBeenRSACalculated || this.Contract.isLocked()) {
                return;
            }
            if (newVal !== oldVal) {
                this.notifyService.warningMessage(
                    "Расчёт КБМ устарел, т.к. значимые атрибуты договора изменились."
                    + "<br/>"
                    + "Для осуществления продажи, пожалуйста, пересчитайте КБМ.");
                this.Contract.HasBeenRSACalculated = false;
            }
        });

        if (!this.Contract.PolicyPrefix) {
            try {
                const policyPrefixValue = this.Contract.getRepository()
                    .PolicyPrefix
                    .Value
                    .find((item) => item.FieldName === "PolicyPrefix").Value;
                this.Contract.PolicyPrefix = policyPrefixValue;
            } catch (e) {
                //
            }
        }

        this._$scope.$watch(
            () => this.rsaMessagesService.getMessages().map((item) => item.Message).join("|"),
            (newVal, oldVal) => {
                if (newVal === oldVal) {
                    return;
                }

                const messages = this.rsaMessagesService.getMessages();
                if (messages.length > 0) {
                    const cntWarn = messages.filter(
                        (msg) => msg.ErrorType === CommonErrorsErrorTypeEnum.Info
                            || msg.ErrorType === CommonErrorsErrorTypeEnum.Warning,
                    ).length;

                    const cntCritical = messages.filter(
                        (msg) => msg.ErrorType === CommonErrorsErrorTypeEnum.Error
                            || msg.ErrorType === CommonErrorsErrorTypeEnum.Critical,
                    ).length;

                    const message = "См. подробности при нажатии на кнопку Меню, Ошибки РСА";

                    if (cntWarn > 0) {
                        this.notifyService.warningMessage(
                            "Некритичные ошибки РСА",
                            message,
                        );
                    }

                    if (cntCritical > 0) {
                        this.notifyService.errorMessage(
                            "Критичные ошибки РСА",
                            message,
                        );
                    }
                }
        });

        this._$scope.$watch(() => this.reliableDriveContract.getErrors().length, ((newValue, oldValue) => {
            if (newValue > 0) {
                const rawErrors = this.reliableDriveContract.getErrors();
                const criticalErrors = rawErrors.filter((rawError) => rawError.IsCritical).map((rawError) => rawError.Message);
                const errors = rawErrors.filter((error) => !error.IsCritical).map((error) => error.Message);
                if (!this.hideReliableRide) {
                    if (criticalErrors.length > 0) {
                        this.notifyService.errorMessage("Надежная поездка", criticalErrors.join("<br/>"));
                    }

                    if (errors.length > 0) {
                        this.notifyService.warningMessage("Надежная поездка", errors.join("<br/>"));
                    }
                }
            }
        }));

        this._$scope.$on("inputModified.formChanged", (event, modified, formCtrl) => {
            if (formCtrl.modifiedModels && formCtrl.modifiedModels.length > 0) {
                formCtrl.modifiedModels.forEach((ctrlModel) => {
                    if (ctrlModel.$name === "relatedProduct" && ctrlModel.modified && ctrlModel.$modelValue === null) {
                        ctrlModel.$setPristine();
                    }
                });
            }
        });

        this.Contract.loadContractCategory().then(() => {
            angular.noop();
        });

        if (!this.isFormLocked()) {
            this._$scope.$watch(() => this.Contract.IsEgarant, (newVal) => {
                if (newVal) {
                    const { PolicySerial, PolicyNumber } = this.Contract;
                    this.undoContract = { PolicySerial, PolicyNumber };
                    this.Contract.PolicySerial = "";
                    this.Contract.PolicyNumber = "";
                } else if (this.undoContract) {
                    this.Contract.PolicySerial = this.undoContract.PolicySerial;
                    this.Contract.PolicyNumber = this.undoContract.PolicyNumber;
                }
            });
        }
    }

    //  @override
    public updateFieldRepository(fieldName) {
        const [$timeout] = this.di(["$timeout"]);
        return $timeout().then(() => super.updateFieldRepository(fieldName));
    }

    public manualReloadEOsagoStatus() {
        this.reloadEOsagoStatus().then(() => {
            if (this.EOsagoStatus && this.EOsagoStatus.Stage && this.Contract.isReadyToSign()) {
                const stageInfo = EOSAGO_STAGES_INFO.get(this.EOsagoStatus.Stage);

                if (stageInfo && stageInfo.isCritical) {
                    const $state = this.di<StateService>("$state");
                    $state.reload();
                }
            }
        });
    }

    public reloadEOsagoStatus(): angular.IPromise<void> {
        const rsaMessagesService = this.di<RSAMessages>("rsaMessagesService");
        this.notifyService.removeToasts();
        rsaMessagesService.reset();
        this.blockUI.start("Получение статуса УЦС");
        const contractGuid = this.Contract.Guid;
        return this.osagoService.EOsagoStatus({
            contractGuid,
        }).$promise.then((result: IEOsagoStatus) => {
            this.EOsagoStatus = result;
            const stageInfo = EOSAGO_STAGES_INFO.get(this.EOsagoStatus.Stage);
            this.EOsagoStatus.StageAsText = stageInfo.title;

            if (stageInfo.isCritical) {
                rsaMessagesService.setMessages(this.EOsagoStatus.Message.map((item) => {
                    return {
                        ErrorType: item.Level === "Info" ? 1 :
                            item.Level === "Warning" ? 2 :
                            item.Level === "Error" ? 3 : 1,
                        ExternalSystem: "РСА",
                        Message: item.Message,
                    };
                }));
            }
        }).catch(() => {
            this.EOsagoStatus = {
                Message: [ { Message: "Договор не найден" } ],
                StageAsText: undefined,
            };
        }).finally(() => {
            this.blockUI.stop();
        });
    }

    public openEOSAGOPersonalAccount() {
        const postfixPhone = ({
            0: "",
            1: "#V",
            2: "#X",
        });
        // TODO Возможно телефон должен передаваться только один, а не несколько через запятую
        // tslint:disable-next-line: max-line-length
        const insuredPhonesLegacyFormat = this.Contract.InsuredPhones.map((phoneObject) => `${leaveOnlyNumbers(phoneObject.Number)}#${postfixPhone[phoneObject.Status]}`).join(",");
        this.osagoService.EOsagoInsuredAccountLink({
            login: insuredPhonesLegacyFormat,
        }).$promise.then(({
            data: url,
        }) => {
            const regex = /^.*USER_LOGIN=(\w*)&USER_PASSWORD=(\w*)$/g;
            const [, login, password] = regex.exec(url);
            const [postUrl] = url.split("&USER_LOGIN");

            const form = document.createElement("form");
            (form as any).style = "display: none";
            form.method = "POST";
            form.action = postUrl;
            form.target = "_blank";

            const loginInput = document.createElement("input");
            loginInput.name = "USER_LOGIN";
            loginInput.value = login;

            const passwordInput = document.createElement("input");
            passwordInput.name = "USER_PASSWORD";
            passwordInput.value = password;

            form.appendChild(loginInput);
            form.appendChild(passwordInput);

            const body = document.getElementsByTagName("body")[0];
            body.appendChild(form);
            form.submit();
        });
    }

    public async onVehicleChanged() {
        this.removeValidationMessage("VehicleVehicleDocuments");
        this.removeValidationMessage("Vehicle");
        this.removeValidationMessage("VehicleModelSpelt");
        await this.updateFieldRepository("VehicleModelRSA");
    }

    public getLabelInspection(codeLabel) {
        if (codeLabel === "Inspection") {
            return (this.Contract.InspectionIsNotRequired) ? "Акт осмотра не требуется" : "Требуется акт осмотра";
        }
        if (codeLabel === "TowardInspection") {
            return (this.Contract.IsTowardInspection) ? "Акт осмотра будет составлен дополнительно" : "";
        }
        return "";
    }

    public hasInspectionPermission() {
        return Boolean(this.originalEmployee.hasPermission("CreateInspectionConclusion", this.Product));
    }

    public hasEgSignPermission() {
        const envService = this.di<EnvService>("envService");
        if (envService.read("signEGarant")) {
            return true;
        }
        return Boolean(this.originalEmployee.hasPermission("SignEGarant", "Osago"));
    }

    public getMiddleDate() {
        if (this.Contract.VehicleRegistration === VEHICLE_REGISTRATIONS.RF) {
            if (String(this.Contract.InsuranceTerm).startsWith("до ")) {
                return false;
            } else if (this.Contract.InsuredContractorType === "физическое лицо") {
                return 3;
            } else if (this.Contract.InsuredContractorType === "юридическое лицо") {
                return 3;
            }
        }
        return false;
    }
    public getAddressMaxLevel() {
        if (this.Contract.IsEgarant) {
            return 8;
        }
    }

    public isTenderBlockVisible(): boolean {
        return (this.employee.info && this.employee.info.SaleChannel === SALE_CHANNELS.OFFICE)
            && this.Contract.isInsurantALegalEntity()
            && this.Contract.DriverRestriction === "Нет";
    }

    public getInsuredPhone(): string {
        if (this.Contract.InsuredPhones && this.Contract.InsuredPhones.length > 0) {
            return this.Contract.InsuredPhones[0].Number;
        }

        return "";
    }

    public disabledPaymenKindsList(): string {
        const disabled = [];

        if (!this.Contract.ContractNum) {
            disabled.push(PaymentKindsEnum.PAYMENT_ORDER);
        }

        return disabled.join("|");
    }

    public isPaymentDisabled(): boolean {
        const isReliableDriveActive = this.reliableDriveContract.isActive() || this.reliableDriveContract.isUserChoice();
        if (isReliableDriveActive && String(this.Contract.InsuredContractorType).toLowerCase() !== "юридическое лицо") {
            if (this.hideReliableRide) {
                return this.Contract.ContractStatusId === CONTRACT_STATUSES.DRAFT;
            }

            return this.Contract.ContractStatusId === CONTRACT_STATUSES.DRAFT || !this.isReliableDrivePayed || !isReliableDriveActive;
        }

        return this.Contract.ContractStatusId === CONTRACT_STATUSES.DRAFT;
    }

    public getContractStatusLabelClass(status: number): string {
        return this.statusService.getStatusLabelClass(status);
    }

    public getStatusStringById(id: number): string {
        const statusItem = this.statusService.getStatusById(id);

        return statusItem ? statusItem.Name : "";
    }

    public getReliableDrivePolicyNumber(): string {
        if (this.reliableDriveContract.Serial || this.reliableDriveContract.Number) {
            return `${this.reliableDriveContract.Serial || ""}-${this.reliableDriveContract.Number || ""}`;
        }

        return "";
    }

    public isReliableDriveDigestVisible(): boolean {
        const isRelatedProductActive = this.reliableDriveContract.isActive() || this.reliableDriveContract.isUserChoice();

        return isRelatedProductActive && !this.hideReliableRide;
    }

    public getReliableDriveContractPremium(): string | number {
        return this.reliableDriveContract.Premium || "-";
    }

    public onPrintReliableDriveFreeFormReceipt(): angular.IPromise<void> {
        const helpers = this.di<Helpers>("helpers");
        const notifyService = this.di<NotifyService>("notifyService");
        const envService = this.di<EnvService>("envService");

        return this.Contract.$loadPrintable({
            contentType: "application/pdf",
            url: `${envService.read("apiUrl")}reliable-drive/print/FreeFormReceipt/${this.reliableDriveContract.Id}`,
        }).then((blob) => {
            notifyService.progressStop();
            const $q = this.di<angular.IQService>("$q");
            return $q((resolve) => {
                return helpers
                    .openUrlInIframe(blob.url)
                    .then(resolve, resolve);
            });
        });
    }

    public onPrintReliableDriveReceipt(): angular.IPromise<void> {
        const helpers = this.di<Helpers>("helpers");
        const notifyService = this.di<NotifyService>("notifyService");
        const envService = this.di<EnvService>("envService");

        return this.Contract.$loadPrintable({
            contentType: "application/pdf",
            url: `${envService.read("apiUrl")}reliable-drive/print/InvoiceForPaymentReliableDrive/${this.reliableDriveContract.Id}`,
        }).then((blob) => {
            const $q = this.di<angular.IQService>("$q");
            return $q((resolve) => {
                return helpers
                    .openUrlInIframe(blob.url)
                    .then(resolve, resolve);
            });
        }).finally(() => {
            notifyService.progressStop();
        });
    }

    public onRelatedProductChanged() {
        const hasRelatedContract = this.reliableDriveContract.isActive()
            || this.reliableDriveContract.isUserChoice()
            && this.reliableDriveContract.Id > 0;

        if (this.relatedProductService.getSelectedProduct() === null && hasRelatedContract) {
            this.notifyService.progressStart("Получение данных связанного договора");
            this.reliableDriveContract.$deactivateContract()
                .then(() => {
                    this.Contract.InsurancePremium = null;
                })
                .catch(() => {
                    this.notifyService.errorMessage("Ошибка!", "Ошибка отмены связанного договора");
                })
                .finally(() => {
                    const $timeout = this.di<ITimeoutService>("$timeout");
                    $timeout(() => {
                        this.notifyService.progressStop();
                    });
                });
        } else if (this.relatedProductService.getSelectedProduct() === RELIABLE_DRIVE_PRODUCT_NAME && !hasRelatedContract) {
            this.notifyService.progressStart("Получение данных связанного договора");
            this.reliableDriveContract.$activateContract()
                .then(() => {
                    this.Contract.InsurancePremium = null;
                })
                .catch(() => {
                    this.notifyService.errorMessage("Ошибка", "Произошла ошибка при получении данных связанного договора");
                })
                .finally(() => {
                    const $timeout = this.di<ITimeoutService>("$timeout");
                    $timeout(() => {
                        this.notifyService.progressStop();
                    });
                });
        }
    }

    /*
     * @todo удалить, если нет необходимости
     */
    public onUsagePurposeChanged() {
        // скорее всего не нужно
        angular.noop();
    }

    public isRelatedProductCancelable(): boolean {
        const hasContract = (this.reliableDriveContract.isActive() || this.reliableDriveContract.isUserChoice()) && this.reliableDriveContract.Id > 0;
        if (!hasContract) {
            return false;
        }

        const cancellationCondition = !this.reliableDriveContract.IsPaid;
        const hasPermission = this.employee.hasPermission("KASKO.ReliableDrive.Cancel", this.Product);
        return hasContract && hasPermission && cancellationCondition;
    }

    public async cancelRelatedProduct(): Promise<void> {
        if (this.isRelatedProductCancelable()) {
            this.blockUI.start("Отмена связанного договора");

            try {
                await this.reliableDriveContract.$cancelContract();
                this.relatedProductService.selectProduct(null);
                this.notifyService.successMessage("Отмена договора", "Связанный договор успешно отменён");
            } catch (e) {
                this.notifyService.errorMessage(
                    "Отмена договора",
                    "Произошла ошибка при отмене связанного договора"
                );
            } finally {
                const $timeout = this.di<angular.ITimeoutService>("$timeout");
                $timeout(() => {
                    this.blockUI.stop();
                });
            }
        }
    }

    public reloadReliableDrivePaymentStatus(): void  {
        this.notifyService.progressStart(" ");
        this.reliableDriveContract.$refreshPaymentStatus(this.Contract.SigningDate).finally(() => {
            const $timeout = this.di<angular.ITimeoutService>("$timeout");
            $timeout(() => {
                this.notifyService.progressStop();
            });
        }).catch((error) => {
            this.notifyService.errorMessage(
                "Обновление статуса оплаты договора",
                "В процессе обновления статуса оплаты договора произошла ошибка"
            );
            // tslint:disable-next-line:no-console
            console.error("Обновление статуса оплаты договора НП",  error);
        });
    }
}
