import { Contract } from "domain/classes/contract.class";
import EngineeringEquipment from "domain/classes/engineeringEquipment.class";
import MovableProperty from "domain/classes/movableProperty.class";
import { Payment } from "domain/classes/payment.class";
import PropertyPart from "domain/classes/propertyPart.class";
import ProlongableIflContractMix from "domain/mixes/ProlongableIflContract.mix";
import { IFLRepository } from "infrastructure/app.factory-ts";
import { ISetFromRepositoryFlags } from "infrastructure/interfaces/IContractResource";
import IProlongableContract from "infrastructure/interfaces/IProlongableContract";
import { AssuranceHomeContractDTO } from "infrastructure/interfaces/WebApi/AssuranceHomeContractDTO";
import { PhoneNumberDTO } from "infrastructure/interfaces/WebApi/PhoneNumberDTO";
import { ProductRiskDTO } from "infrastructure/interfaces/WebApi/ProductRiskDTO";
import Repository, { IAPIRepository, IRepositoryItem } from "infrastructure/Repository.class";
import { Day, Decimal, Guid, Int } from "infrastructure/types";
import moment from "moment";
import { AdditionalConstructionAssuranceHome } from "./AdditionalConstructionAssuranceHome";
import IConstructionType from "./interfaces/IConstructionType";
import { IWallMaterial } from "./interfaces/IWallMaterial";
import IPVSContractorDTO from "infrastructure/interfaces/IPVSContractorDTO";
import UgskPhone from "domain/classes/ugsk-phone.class";

export class PropertyPartsRepository extends Repository {
    public StructuralElementCostRestriction: IRepositoryItem<{
        MaxValue: number;
        MinValue: number;
    }>;
    public InteriorCategoryCostRestriction: IRepositoryItem<{}>;
    public WallMaterial: IRepositoryItem<IWallMaterial>;
}
export class AssuranceHomeRepository extends IFLRepository {
    public DiscountRestrictions: IRepositoryItem<{

    }>;
    public PropertyDeterioration: IRepositoryItem<{}>;
    public PropertyFloorCount: IRepositoryItem<{}>;
    public PropertyPartType: IRepositoryItem<{
        PropertyPartType: string;
    }>;
    public ConstructionType: IRepositoryItem<IConstructionType>;
    public BonusMalusAssuranceHome: IRepositoryItem<{
        BonusMalusAssuranceHomeDescription: string;
        BonusMalusAssuranceHomeName: string;
    }>;
    public PolicyNumberOfAnotherCompany: IRepositoryItem<{}>;
    public PropertyWallMaterial: IRepositoryItem<{
        PropertyWallMaterialMaxConstructionAgeValue: Int;
    }>;
    public NeedToInsureEngineeringEquipment: IRepositoryItem<{}>;
    public WallMaterial: IRepositoryItem<IWallMaterial>;
    public InsuranceTermAssuranceHome: IRepositoryItem<{}>;
    public SublimitForMainConstruction: IRepositoryItem<{}>;
    public SublimitForAdditionalConstruction: IRepositoryItem<{}>;
    public SpecialOffer: IRepositoryItem<{
        SpecialOfferId: Int;
    }>;
    public AssuranceHomeDiscountRestriction: IRepositoryItem<{
        MinValue: string;
        MaxValue: string;
    }>;
}
export class AssuranceHomeContract extends ProlongableIflContractMix(Contract) implements IProlongableContract, AssuranceHomeContractDTO {
    public AdditionalConstructions: AdditionalConstructionAssuranceHome[] = [];
    public PropertyParts: PropertyPart[] = [];
    public InsuredCommonEmail: string;
    public MovableProperties: MovableProperty[] = [];
    public EngineeringEquipments: EngineeringEquipment[] = [];
    public MovablePropertyDeterioration: boolean = false;
    public EngineeringEquipmentDeterioration: boolean = false;
    public PropertyConstructionYear: number = null;
    public ContractFrom: string = null;
    public InsuredBirthday: string = null;
    public InspectionInspectionDate: string = null;
    public UnderwriterCoefficient: number = 1;
    public Payments: Payment[];
    public PreviousPolicyNumber: string;
    public PropertyWallMaterialId: number;
    public InsuredPersonAddressHasNotFiasInfo: any;
    public InsuredObjectAddressHasNotFiasInfo: any;
    public NeedToInsureStructuralElement: boolean;
    public NeedToInsureInterior: boolean;
    public StructuralElementDeclaredAmount: number;
    public InteriorDeclaredAmount: number;
    public PropertyInteriorRepairYear: number;
    public WallMaterialId: number;
    public InsuranceTermAssuranceHomeId: number;
    public ContractTo: string;
    public PropertyIsFinished: boolean;
    public BonusMalusAssuranceHomeId: number;
    public MainConstructionSublimits: any;
    public AdditionalConstructionSublimits: any;
    public SpecialOfferId: number;
    public PropertyForRent: boolean;
    public PropertyOtherWallMaterial: string;
    public PropertyExteriorMaterialId: Int;
    public PropertyOtherExteriorMaterial: string;
    public PropertyFoundationMaterialId: Int;
    public PropertyOtherFoundationMaterial: string;
    public PropertyRoofMaterialId: Int;
    public PropertyOtherRoofMaterial: string;
    public PropertyOverhaulYear: Int;
    public PropertyHasFireAlarm: boolean;
    public PropertyHasSecurityAlarm: boolean;
    public PropertyHasFireplace: boolean;
    public PropertyAddressUserDefinedAddress: string;
    public PropertyAddressFiasId: string;
    public PropertyAddressKladrId: string;
    public ContractStatusName: string;
    public PolicySerialId: Int;
    public FilialId: Int;
    public FranchiseTypeId: Int;
    public FranchiseAmountId: Int;
    public ResidenceTypeId: Int;
    public AssumedRisksOnTheContract: ProductRiskDTO[] = [];
    public Discount: Decimal;
    public StructuralElementDeterioration: boolean;
    public InteriorDeterioration: boolean;
    public InspectionInspector: string;
    public HasNotFiasInfo: boolean;
    public InsuranceCompanyOfAnotherPolicyId: Int;
    public PolicyNumberOfAnotherCompany: string;
    public InsuredDocTypeId: Int;
    public InsuredDocDateGiven: Day;
    public InsuredDocWhomGiven: string;
    public InsuredDocOrgCode: string;
    public InsuredBirthPlace: string;
    public InsuredDocSerial: string;
    public InsuredDocNumber: string;
    public InsuredLastName: string;
    public InsuredFirstName: string;
    public InsuredMiddleName: string;
    public InsuredAddressUserDefinedAddress: string;
    public InsuredAddressFiasId: string;
    public InsuredAddressKladrId: string;
    public InsuredPhoneVerificationId: Guid;
    public InsuredPhones: PhoneNumberDTO[];
    public InsuredId: Int;
    public LapsedPaymentId: Int;
    public IsReceiptRequired: boolean;
    public IsNumberRequired: boolean;

    private _PaymentDocumentDatePay: string;

    constructor(prototype: any) {
        super(prototype);
        this.SigningDate = moment().format("YYYY-MM-DD") + "T00:00:00";
        this.ContractFrom = moment().add(1, "d").format("YYYY-MM-DD") + "T00:00:00";
        this.InsuredBirthday = moment().add(-18, "y").format("YYYY-MM-DD") + "T00:00:00";
        this.InspectionInspectionDate = moment().format("YYYY-MM-DD") + "T00:00:00";
        this.UnderwriterCoefficient = 1;
    }
    public getRepository() {
        return super.getRepository() as AssuranceHomeRepository;
    }

    public setFromRepository(data: IAPIRepository[], flags: ISetFromRepositoryFlags): void {
        super.setFromRepository(data, flags);
        let collection = data.find((item: any) => {
            return item.FieldName === "SublimitForMainConstruction";
        });
        if (collection) {
            this.MainConstructionSublimits = this.getSublimits(collection);
        }
        collection = data.find((item: any) => {
            return item.FieldName === "SublimitForAdditionalConstruction";
        });
        if (collection) {
            this.AdditionalConstructionSublimits = this.getSublimits(collection);
        }
    }
    static get castMap() {
        return {
            AdditionalConstructions: [AdditionalConstructionAssuranceHome],
            EngineeringEquipments: [EngineeringEquipment],
            MovableProperties: [MovableProperty],
            PropertyParts: [PropertyPart],
        };
    }

    /**
     * @override
     */
    public toPVSDTO(): IPVSContractorDTO {
        const toPVSDTO = {
            ContractorId: this.InsuredId,
            ContractorTypeCode: this.InsuredContractorTypeId,
            FirstName: this.InsuredFirstName,
            LastName: this.InsuredLastName,
            MiddleName: this.InsuredMiddleName,
            ContractorBirthday: this.InsuredBirthday,
            ContractorPhone: UgskPhone.phoneArrayToPhoneString(this.InsuredPhones),
            PhoneVerificationId: this.InsuredPhoneVerificationId,
        };
        for (const propName in toPVSDTO) {
            if (toPVSDTO[propName] === null) {
                delete toPVSDTO[propName];
            }
        }

        return toPVSDTO;
    }

    private getSublimits(collection: any) {
        return collection.CollectionSource.map((collectionElement) => {
            const idElement = collectionElement.CollectionSource.find((item: any) => item.FieldName === "Id");
            const valueElement = collectionElement.CollectionSource.find((item: any) => item.FieldName === "DefaultValue");

            if (idElement.Value && valueElement.Value) {
                return {
                    SublimitOfConstructionPartId: idElement.Value,
                    Value: valueElement.Value,
                };
            }
        }).filter((sl) => sl);
    }
}
